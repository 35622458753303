(function () {
	
	//----------------------------------------------------------------
	//Benachrichtigungs-Typen für Benutzer
	//----------------------------------------------------------------
	
	'use strict';
	
	var UserNotificationType = {
		
		//notification types
		UN_TYPE_CREATED: 1,
		UN_TYPE_UPDATED: 2,
		UN_TYPE_STATUS_CHANGED: 3,
		UN_TYPE_COMPLETED: 4,
		UN_TYPE_DUE: 5,
		UN_TYPE_OVER_DUE: 6,
		UN_TYPE_COMMENT: 7,
		UN_TYPE_INCIDENT: 8,

		//-------------------------------------------------------------------
		
		userNotificationPrototype: {
			
			fromObj: function (t) {
				
				var i;
				
				/*this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				this.clientId = parseInt(t.clientId, 10);
				this.userId = parseInt(t.userId, 10);*/
				this.notificationType = parseInt(t.notificationType, 10);

				return this;
			},

			getName: function(){
				var nt = UserNotificationType.getNotificationType(this.notificationType);
				return nt ? nt.name : "";
			}
			
		},
		createUserNotificationType: function () {
			return Object.create(UserNotificationType.userNotificationPrototype);
		},
		
		//-------------------------------------------------------------------

		getAllNotificationTypes: function () {
			var a = [{
				type: UserNotificationType.UN_TYPE_CREATED,
				name: "Erstellung"
			},{
				type: UserNotificationType.UN_TYPE_UPDATED,
				name: "Aktualisierung"
			},{
				type: UserNotificationType.UN_TYPE_STATUS_CHANGED,
				name: "Statusänderung"
			},{
				type: UserNotificationType.UN_TYPE_COMPLETED,
				name: "Erledigung"
			},{
				type: UserNotificationType.UN_TYPE_DUE,
				name: "Fälligkeit"
			},{
				type: UserNotificationType.UN_TYPE_OVER_DUE,
				name: "Überfälligkeit"
			},{
				type: UserNotificationType.UN_TYPE_COMMENT,
				name: "Kommentar"
			},{
				type: UserNotificationType.UN_TYPE_INCIDENT,
				name: "Vorkommnis"
			}];

			return a;
		},

		getNotificationType: function(type){
			return UserNotificationType.getAllNotificationTypes().find(function(o){
				return o.type === type;
			});
		}

	};
	
	window.UserNotificationType = UserNotificationType;
}());