(function () {
	
	//----------------------------------------------------------------
	//Auftrag
	//----------------------------------------------------------------
	
	/*global
	constants:true,
	Location:true,
	Protocol:true,
	TaskState:true,
	UserGroup:true,
	LocationType:true,
	Attachment:true,
	TaskType:true,
	Incident:true,
	Signal:true,
	User:true,
	ProtocolForm:true,
	TaskTypeField:true,
	HistoryItem:true,
	JsDiff:true*/
	
	'use strict';
	
	var Task = {

		PROTOCOL_REMOVED_NO_MATCHING_LOCATION: 0,
		PROTOCOL_REMOVED_DUPLICATE: 1,

		VISIBILITY_TYPE_NORMAL: 0,
		VISIBILITY_TYPE_HIDDEN: 1,

		taskPrototype: {
			
			prevVersion: null,
			nextVersion: null,
			isCurrentVersion: true,
			
			comments: [],
			
			fromObj: function (t, includeInactive) {
				
				var mil;
				
				if (includeInactive === undefined)
					includeInactive = true;

				this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				if (t.clientId)
					this.clientId = parseInt(t.clientId, 10);
				else
					this.clientId = model.curClientId;

				//Typ: normal, versteckt
				if (t.visibilityType)
					this.visibilityType = +t.visibilityType;
				else
					this.visibilityType = Task.VISIBILITY_TYPE_NORMAL;

				//Umbenennung
				this.intervalId = 0;
				if (t.intervalId)
					this.intervalId = parseInt(t.intervalId, 10);
				else if (t.parentId)
					this.intervalId = parseInt(t.parentId, 10);
				
				this.description = pg.restoreDbString(t.description);
				this.intervalType = t.intervalType;
				this.generationId = parseInt(t.generationId || -1);
				this.locations = t.locations || "";
				
				//ja, sowohl inactive + deleted!
				this.locationList = Location.buildLocationList(this.locations, includeInactive, includeInactive, true);
				this.updateLocationObjects();
				this.ownerId = parseInt(t.ownerId, 10);
				this.intervalStatus = parseInt(t.intervalStatus, 10);
				this.taskId = parseInt(t.taskId, 10);
				this.taskTypeId = parseInt(t.taskType, 10);
				this.version = parseInt(t.version, 10);
				this.dueDate = null;
				if (t.dueDate) {
					mil = pg.parseDate(t.dueDate);
					this.dueDate = new Date();
					this.dueDate.setTime(mil);
				}
				if (t.endDate) {
					mil = pg.parseDate(t.endDate);
					this.endDate = new Date();
					this.endDate.setTime(mil);
				}
				else
					this.endDate = null;
				
				this.isNew = (t.isNew) ? t.isNew : 0;
				this.reminders = (t.reminders) ? t.reminders : "";
				
				this.attachments = (t.attachments) ? t.attachments : "";
				
				this.dynData = (t.dynData) ? t.dynData : "";
				this.updateDynData();
				
				this.states = [];
				var i;
				if (t.states)
					for(i = 0; i < t.states.length; i++) {
						var o = TaskState.createTaskState().fromObj(t.states[i]);
						this.states.push(o);
					}
				
				this.protocols = [];
				if (t.protocols) {
					for (i = 0; i < t.protocols.length; i++) {
						var op = Protocol.createProtocol().fromObj(t.protocols[i]);
						this.protocols.push(op);
					}
				}

				this.history = [];
				if (t.history) {
					for (i = 0; i < t.history.length; i++) {
						var h = HistoryItem.createHistoryItem().fromObj(t.history[i]);
						this.history.push(h);
					}
				}
				
				this.locationType = parseInt(t.locationType || -1);
				this.title = t.title;
				
				this.requestId = parseInt(t.requestId || -1);
				
				this.comments = [];
				if (t.comments)
					for(i = 0; i < t.comments.length; i++) {
						var c = Comment.createComment().fromObj(t.comments[i]);
						this.addComment(c);
					}
				
				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.clientId = this.clientId;
				o.isNew = this.isNew;
				o.intervalId = this.intervalId;
				o.description = this.description;
				o.intervalType = this.intervalType;
				o.generationId = this.generationId;
				o.locations = this.locations;
				o.ownerId = this.ownerId;
				o.intervalStatus = this.intervalStatus;
				o.taskId = this.taskId;
				o.taskType = this.taskTypeId;
				o.version = this.version;
				if (this.dueDate)
					o.dueDate = pg.buildDate(this.dueDate);
				else
					o.dueDate = null;
				o.endDate = pg.buildDate(this.endDate);
				o.attachments = this.attachments;
				o.reminders = this.reminders;
				o.locationType = this.locationType;
				o.title = this.title;
				o.requestId = this.requestId;
				o.dynData = this.dynData;
				o.visibilityType = this.visibilityType;
				
				o.states = [];
				for(var i = 0; i < this.states.length; i++)
					o.states.push(this.states[i].serialize());
				
				o.protocols = [];
				for(i = 0; i < this.protocols.length; i++)
					o.protocols.push(this.protocols[i].serialize());

				o.history = [];
				for(i = 0; i < this.history.length; i++)
					o.history.push(this.history[i].serialize());
				
				o.comments = [];
				for(i = 0; i < this.comments.length; i++)
					o.comments.push(this.comments[i].serialize());
				
				return o;
			},

			serializeCompact: function(){
				var o = {};

				o.id = this.id;
				o.isNew = this.isNew;
				o.taskId = this.taskId;

				o.states = [];
				for(var i = 0; i < this.states.length; i++)
					o.states.push(this.states[i].serializeCompact());

				o.protocols = [];
				for(i = 0; i < this.protocols.length; i++)
					o.protocols.push(this.protocols[i].serializeCompact());

				o.locations = this.locations;
				o.locationObjectsLength = this.locationObjects.length;

				return o;
			},
			
			//---------------------------------------------------------------------
			
			//logging: Nur aktuelle Datensätze berücksichtigen
			isRecent: function(dateThreshold){
				if (this.changedOn > dateThreshold)
					return true;
				
				for(var i = 0; i < this.states.length; i++)
					if (this.states[i].changedOn > dateThreshold)
						return true;
				
				for(i = 0; i < this.protocols.length; i++)
					if (this.protocols[i].changedOn > dateThreshold)
						return true;
				
				for(i = 0; i < this.comments.length; i++)
					if (this.comments[i].changedOn > dateThreshold)
						return true;
				
				return false;
			},
			
			//---------------------------------------------------------------------
			
			//war mal Task.activeStatus, nun in TastState.status
			getMasterStatus: function(){
				var ts = this.getCurrentState();
				if (ts)
					return ts.status;
				return constants.TASK_STATUS_UNDEFINED;
			},

			//---------------------------------------------------------------------

			clearHistory: function(){
				this.history = [];
			},
			
			//---------------------------------------------------------------------
			
			isArchived: function(){
				var ts = this.getCurrentState();
				if (ts)
					return ts.groupId === constants.GROUP_ARCHIVE;
				return false;
			},
			
			//nur, wenn erledigt
			canBeArchived: function(){
				if (this.getMasterStatus() !== constants.TASK_STATUS_ACTIVE)
					return false;
				this.updateStatus();
				if (this.status === constants.STATUS_COMPLETED)
					return true;
				return false;
			},
			
			addTaskState: function(){
				var prevTs = this.getCurrentState();
				return TaskState.createTaskState().fromObj({
					clientId: model.curClientId,
					id: -1,
					status: prevTs.status,
					taskId: this.taskId,
					taskStateId: prevTs.taskStateId,
					userGroupIds: prevTs.userGroupIds,
					createdOn: new Date(),
					createdBy: model.curUserId,
					changedOn: null,
					changedBy: -1,
					version: prevTs.version + 1,
					responsibleId: prevTs.responsibleId,
					labelId: prevTs.labelId,
					comment: "",
					taskStatus: this.status,
					groupId: prevTs.groupId
				});
			},
			
			//---------------------------------------------------------------------
			
			canBeSeenByUser: function(checkOwner, taskTypeId, groupId, taskState){
				
				var userId = model.curUserId;
				var ts = taskState || this.getCurrentState();
				groupId = groupId || -1;
				
				if (!ts)
					return false;
				
				if (groupId >= 0)
					if (ts.groupId !== groupId)
						return false;
				
				if (taskTypeId > 0)
					if (model.curUser.hasTaskType(taskTypeId))
						return true;
				
				//Ownership o.ä. prüfen?
				if (checkOwner) {
					
					//Bringschuld: mind. ein Kriterium muss passen
					var isValidViewer = false;
					if (this.ownerId === userId)
						isValidViewer = true;
					if (ts.responsibleId === userId)
						isValidViewer = true;
					
					//normaler Check über Assignment
					if (!isValidViewer) {
						if (ts.isAssignedToUser(userId))
							isValidViewer = true;
					}
					if (!isValidViewer)
						return false;
				}
				
				return true;
			},
			
			//---------------------------------------------------------------------
			
			//Protokolle zugeordnet?
			hasProtocols: function () {
				var tt = TaskType.getTaskType(this.taskTypeId);
				if (tt)
					return (tt.formIds.length);
				return false;
			},

			mergeProtocol: function(newProt){

				//NEIN. Es gibt nur noch die ID (nicht mehr protocolId). Negativ = temporär,
				//temporäre Protokolle haben eine zufällige ID, können aber anhand der protocolId zugeordnet werden

				var guidFloor = 100000;

				//ist das neue ein "echtes" Protokoll?
				var hasMatch = false;
				//if (newProt.id === newProt.protocolId) {
				if (newProt.id > 0) {
					for (var i = 0; i < this.protocols.length; i++) {

						//ist das existierende ein temporäres Protokoll?
						var exProt = this.protocols[i];

						//..und gleichzeitig das neue ein Server-gespeichertes?
						if (((exProt.id < 0) || (exProt.id >= guidFloor)) && (newProt.id < guidFloor)){
						/*if (exProt.id !== exProt.protocolId) {

							//einander zuordenbar?
							if (exProt.protocolId === newProt.protocolId) {*/
							//inhaltliche Prüfung
							if (exProt.matchesProtocol(newProt)){

								//dann ersetzen!
								this.protocols[i] = newProt;
								hasMatch = true;
								break;
							}
						}
					}
				}

				if (!hasMatch) {
					pg.replaceOrPushObj(this.protocols, newProt);
				}
			},
			
			//----------------------------------------------
			
			//ausführbar?
			isExecutable: function (validateFormIsExecutable) {

				if (validateFormIsExecutable === undefined)
					validateFormIsExecutable = true;

				var formCount = 0;
				var tt = TaskType.getTaskType(this.taskTypeId);
				if (tt)
					formCount = tt.formIds.length;

				//auch für ein (in Ergänzung zu: kein) Objekt ermöglichen
				if (this.status === constants.STATUS_COMPLETED)
					return false;
				if (formCount !== 1)
					return false;
				if (this.getLocationCount() > 1)
					return false;
				//das Formular muss ein entsprechendes Flag aufweisen
				if (validateFormIsExecutable) {
					var form = ProtocolForm.getProtocolForm(tt.formIds[0]);
					if (!form.hasOption("executable"))
						return false;
				}
				return true;
			},
			
			//---------------------------------------------------------------------
			
			addComment: function (o) {
				
				if (this.comments === undefined)
					this.comments = [];
				
				//shallow copy!
				var a = [];
				for(var i = 0; i < this.comments.length; i++) {
					
					//bekannt?
					if (o.id === this.comments[i].id)
						return;
					
					a.push($.extend({}, this.comments[i]));
				}
				a.push(o);
				this.comments = a;
			},
			
			removeComment: function (id) {
				id = parseInt(id);
				for(var i = 0; i < this.comments.length; i++) {
					if (this.comments[i].id === id) {
						this.comments.splice(i, 1);
						return;
					}
				}
			},
			
			//---------------------------------------------------------------------
			//dynamische Felder
			
			updateDynData: function () {
				
				this.dynDataObj = [];
				var a = this.dynData.split(";;;");
				for(var i = 0; i < a.length; i++) {
					if (a[i].length) {
						var raw = a[i].split("$$$");
						this.dynDataObj.push({
							id: parseInt(raw[0]),
							value: raw[1]
						});
					}
				}
			},
			getDynDataObj: function (fieldId) {
				for(var i = 0; i < this.dynDataObj.length; i++) {
					if (this.dynDataObj[i].id === fieldId)
						return this.dynDataObj[i];
				}
				return null;
			},
			getDynDataObjByRole: function (role, toBeShownInApp) {
				
				if (toBeShownInApp === undefined)
					toBeShownInApp = true;
				
				var ttFields = TaskTypeField.getTaskTypeFields(this.taskTypeId);
				for(var i = 0; i < ttFields.length; i++) {
					var ttf = ttFields[i];
					/*if (toBeShownInApp)
						if (ttf.visibility === TaskTypeField.VISIBILITY_BACKEND_ONLY)
							continue;*/
					
					if (ttf.role === role){
						for(var j = 0; j < this.dynDataObj.length; j++) {
							if (this.dynDataObj[j].id === ttf.id)
								return {
									data: this.dynDataObj[j],
									taskTypeField: ttf
								};
						}
					}
				}
				return null;
			},
			clearDynData: function () {
				this.dynData = "";
				this.dynDataObj = [];
			},
			addDynData: function (field, value) {
				var s = field.id + "$$$" + value;
				this.dynData += s + ";;;";
				this.updateDynData();
			},
			hasDynData: function (toBeShownInApp) {
				if (toBeShownInApp === undefined)
					toBeShownInApp = true;
				
				//var tt = TaskType.getTaskType(this.taskTypeId);
				var ttFields = TaskTypeField.getTaskTypeFields(this.taskTypeId);
				var count = 0;
				for(var i = 0; i < ttFields.length; i++) {
					
					/*var ttf = ttFields[i];
					if (toBeShownInApp)
						if (ttf.visibility === TaskTypeField.VISIBILITY_BACKEND_ONLY)
							continue;*/
					count++;
				}
				return (count > 0);
				//return (this.dynDataObj.length > 0);
			},
			
			//---------------------------------------------------------------------

			//inhaltliche Gleichheit prüfen
			isTaskEqual: function (o, ots) {
				return this.getDiff(o, ots).length === 0;
			},

			//Unterschiede zu anderer Version ermitteln (ggf. unter Angabe eines expliziten State "ots")
			getDiff: function(o, ots){

				var diff = [];

				//einfache Eigenschaften
				if (o.description !== this.description)
					diff.push({
						name: "Beschreibung",
						isText: true,
						oldVal: o.description,
						newVal: this.description
					});
				if (o.intervalType !== this.intervalType) {

					var iv1Name = null;
					if (this.intervalType) {
						var iv1 = Task.getIntervalValues(this.intervalType);
						iv1Name = Task.createIntervalName(iv1);
					}
					var iv2Name = null;
					if (o.intervalType) {
						var iv2 = Task.getIntervalValues(o.intervalType);
						iv2Name = Task.createIntervalName(iv2);
					}

					diff.push({
						name: "Intervalltyp",
						oldVal: iv2Name,
						newVal: iv1Name
					});
				}
				if (o.locationType !== this.locationType) {

					if ((o.locationType && o.locationType>0) || (this.locationType && this.locationType>0)) {

						var nameA = null;
						var nameB = null;

						if (o.locationType)
							nameA = LocationType.getLocationType(o.locationType).name;
						if (this.locationType)
							nameB = LocationType.getLocationType(this.locationType).name;

						diff.push({
							name: "Objekttyp",
							oldVal: nameA,
							newVal: nameB
						});
					}
				}
				if (o.title !== this.title)
					diff.push({
						name: "Titel",
						oldVal: o.title,
						newVal: this.title
					});
				if (o.intervalStatus !== this.intervalStatus)
					diff.push({
						name: "Intervallstatus",
						oldVal: Task.getIntervalStatusName(o.intervalStatus),
						newVal: Task.getIntervalStatusName(this.intervalStatus)
					});
				if (o.taskId !== this.taskId)
					diff.push({
						name: "ID",
						oldVal: "#" + o.taskId,
						newVal: "#" + this.taskId
					});
				if (o.taskTypeId !== this.taskTypeId)
					diff.push({
						name: "Auftragstyp",
						oldVal: TaskType.getTaskType(o.taskTypeId).name,
						newVal: TaskType.getTaskType(this.taskTypeId).name
					});

				var d;
				if (this.dueDate) {
					d = o.dueDate;
					if (d)
						if (!$.isNumeric(d))
							d = d.getTime();
					if (d !== this.dueDate.getTime())
						diff.push({
							name: "Fälligkeitsdatum",
							oldVal: d ? pg.formatDate(pg.parseDate(d)) : null,
							newVal: this.dueDate ? pg.formatDate(this.dueDate) : null
						});
				}
				else if (o.dueDate !== null) {
					d = o.dueDate;
					if (!$.isNumeric(d))
						d = d.getTime();
					diff.push({
						name: "Fälligkeitsdatum",
						oldVal: o.dueDate ? pg.formatDate(pg.parseDate(d)) : null,
						newVal: this.dueDate ? pg.formatDate(this.dueDate) : null
					});
				}
				if (this.endDate) {

					d = o.endDate;
					if (d)
						if (!$.isNumeric(d))
							d = d.getTime();

					if (d !== this.endDate.getTime())
						diff.push({
							name: "Enddatum",
							oldVal: o.endDate ? pg.formatDate(pg.parseDate(d)) : null,
							newVal: this.endDate ? pg.formatDate(this.endDate) : null
						});
				}
				else if (o.endDate !== null) {

					d = o.endDate;
					if (!$.isNumeric(d))
						d = d.getTime();

					diff.push({
						name: "Enddatum",
						oldVal: o.endDate ? pg.formatDate(pg.parseDate(d)) : null,
						newVal: this.endDate ? pg.formatDate(this.endDate) : null
					});
				}

				if (o.requestId !== this.requestId)
					diff.push({
						name: "Zugehöriger Antrag",
						oldVal: "#" + o.requestId,
						newVal: "#" + this.requestId
					});

				//kann sich nicht ändern (Stand 2018/04)
				/*if (parseInt(o.ownerId) !== this.ownerId)
					diff.push({
						name: "Ersteller",
						oldVal: User.getUserName(o.ownerId),
						newVal: User.getUserName(this.ownerId)
					});*/

				var a, b, onlyInA, onlyInB, namesA, namesB;

				if (ots) {
					/*if (!ots) {
						//den zeitlich zugehörigen Zustand holen
						ots = o.getState();
					}*/
					//var ts = this.getCurrentState();
					var ts = this.getState();

					if (parseInt(ots.responsibleId) !== ts.responsibleId)
						diff.push({
							name: "Verantwortlich",
							oldVal: User.getUserName(ots.responsibleId),
							newVal: User.getUserName(ts.responsibleId)
						});

					//---------------------------------
					//Objekte/Arrays

					//Bearbeiter
					if (ots.userGroupIds !== ts.userGroupIds) {

						a = ots.userGroupIds.split("#").map(Number);
						b = ts.userGroupIds.split("#").map(Number);

						//keine Leerwerte
						a = _.filter(a, function (o2) {
							return o2 > 0;
						});
						b = _.filter(b, function (o2) {
							return o2 > 0;
						});

						onlyInA = _.filter(a, function (o2) {
							return b.indexOf(o2) === -1;
						});
						onlyInB = _.filter(b, function (o2) {
							return a.indexOf(o2) === -1;
						});

						if ((onlyInA.length > 0) || (onlyInB.length > 0)) {

							namesA = onlyInA.map(function (id) {
								return UserGroup.getUserGroup(id).name;
							});
							namesB = onlyInB.map(function (id) {
								return UserGroup.getUserGroup(id).name;
							});

							diff.push({
								name: "Bearbeiter",
								isArray: true,
								oldVal: namesA,
								newVal: namesB
							});
						}
					}
				}

				//Locations
				if (o.locations !== this.locations) {
					a = o.locations.split("#").map(Number);
					b = this.locations.split("#").map(Number);

					onlyInA = _.filter(a, function (o2) {
						return b.indexOf(o2) === -1;
					});
					onlyInB = _.filter(b, function (o2) {
						return a.indexOf(o2) === -1;
					});

					if ((onlyInA.length > 0) || (onlyInB.length > 0)) {

						namesA = onlyInA.map(function (id) {
							return Location.getLocation(id).name;
						});
						namesB = onlyInB.map(function (id) {
							return Location.getLocation(id).name;
						});

						diff.push({
							name: "Objekte",
							isArray: true,
							oldVal: namesA,
							newVal: namesB
						});
					}
				}

				//anhänge
				var i;
				if (o.attachments !== this.attachments) {

					o.updateAttachments();
					this.updateAttachments();

					a = o.attObj;
					b = this.attObj;

					onlyInA = _.filter(a, function (o2) {
						for (i=0; i<b.length; i++){
							if (b[i].id === o2.id)
								return false;
						}
						return true;
					});
					onlyInB = _.filter(b, function (o2) {
						for (i=0; i<a.length; i++){
							if (a[i].id === o2.id)
								return false;
						}
						return true;
					});

					if ((onlyInA.length > 0) || (onlyInB.length > 0)) {

						namesA = onlyInA.map(function (o2) {
							return o2.name;
						});
						namesB = onlyInB.map(function (o2) {
							return o2.name;
						});

						diff.push({
							name: "Anhänge",
							isArray: true,
							oldVal: namesA,
							newVal: namesB
						});
					}
				}

				//Dynamische Felder
				if ((o.dynDataObj.length > 0) || (this.dynDataObj.length > 0)){

					var ttFields = TaskTypeField.getTaskTypeFields(this.taskTypeId);
					for(i = 0; i < ttFields.length; i++) {

						var ttf = ttFields[i];

						var dd1 = o.getDynDataObj(ttf.id);
						var ddValue1 = (dd1) ? ttf.getFormattedValue(dd1.value) : "";

						var dd2 = this.getDynDataObj(ttf.id);
						var ddValue2 = (dd2) ? ttf.getFormattedValue(dd2.value) : "";

						if (ddValue1 !== ddValue2){
							diff.push({
								name: ttf.name,
								oldVal: ddValue1,
								newVal: ddValue2
							});
						}
					}
				}

				return diff;
			},
			
			//----------------------------------------------
			
			//zugeordnete Locations holen (IDs)
			getLocationIds: function () {
				var a = pg.splitNonEmpty(this.locations, "#");
				for(var i = 0; i < a.length; i++)
					a[i] = parseInt(a[i]);
				return a;
			},
			
			//----------------------------------------------
			
			//immer die aktuellsten Location-Infos heranziehen
			updateLocationObjects: function () {
				this.locationObjects = [];
				
				for(var i = 0; i < this.locationList.length; i++) {
					var l = Location.getLocation(this.locationList[i], undefined, true, true, false);
					if (l)
						this.locationObjects.push(l);
				}
			},
			
			//----------------------------------------------
			
			//Sortierung anwenden
			updateSortValue: function (sortField, sortDirection) {
				
				if (sortDirection === constants.SORTING_NONE) {
					this.sortVal = 0;
					return;
				}
				
				this.sortVal = "";
				switch (sortField) {
					case "taskId":
						this.sortVal = this.taskId;
						break;
					case "status":
						if (this.getMasterStatus() === constants.TASK_STATUS_ACTIVE)
							this.sortVal = this.getStatus();
						else
							this.sortVal = this.getMasterStatus();
						break;
					case "type":
						this.sortVal = this.taskTypeId;
						break;
					case "intervalId":
						this.sortVal = this.intervalId;
						break;
					case "routing":
						var ug = this.getCurrentState().getUserGroups()[0];
						this.sortVal = ug.name;
						break;
					case "locationType":
						var loc = Location.getLocation(this.locationList[0], undefined, false, false);
						if (loc)
							if (loc.isAbstract !== 1)
								this.sortVal = LocationType.getLocationType(loc.type).name;
						break;
					case "dueDate":
						this.sortVal = this.dueDate || "";
						break;
					/*
					case "protocolsComplete":
						this.sortVal = this.protocolCount[constants.STATUS_COMPLETED];
						break;
					case "protocolsUncomplete":
						this.sortVal = (this.totalProtocolCount - this.protocolCount[constants.STATUS_COMPLETED]);
					 	break;
					case "protocolsTotal":
						this.sortVal = this.totalProtocolCount;
						break;
					case "incidentsCount":
						this.sortVal = this.incCount;
						break;
					case "incidentsDefects":
						this.sortVal = this.defectCount;
						break;
					*/
					case "incidentsNotification":
						this.sortVal = this.notificationCount;
						break;
                    case "protocolFotos":
                        this.sortVal = this.protocolFotosCount;
                        break;

                }
			},
			
			//----------------------------------------------
			
			//Vorkommnisse aktualisieren
			updateIncidents: function () {
				//Vorkommnisse
				var allProt = this.getAllProtocols(false);
				this.incCount = 0;
				this.defectCount = 0;
				this.notificationCount = 0;
				for(var l = 0; l < allProt.length; l++) {
					//jedes Protokoll
					var prot = allProt[l];
					var inc = prot.getIncidentCount();
					this.incCount += inc.incidentCount;
					this.defectCount += inc.defectCount;
					this.notificationCount += inc.notificationCount;
				}
			},
			
			//---------------------------------------------------
			//Anhänge
			
			updateAttachments: function () {
				this.attObj = Attachment.getAttachments(constants.ENTITY_TYPE_TASK, this.taskId);
				if (this.attObj.length === 0 && this.attachments){
					//legacy
					this.attObj = Attachment.deserializeAttachments(constants.ENTITY_TYPE_PROTOCOL, this.taskId, this.attachments);
				}
				return this.attObj;
			},
			getAttachments: function () {
				if (!this.attObj)
					this.updateAttachments();
				return this.attObj;
			},
			getAttachment: function (attId) {
				for(var i = 0; i < this.attObj.length; i++)
					if (this.attObj[i].id === attId)
						return this.attObj[i];
				return null;
			},
			getAttachmentByIndex: function (index) {
				if ((index >= 0) && (index <= this.attObj.length - 1))
					return this.attObj[index];
				return null;
			},
			/*getDeletedAttachments: function(oldTaskVersion){

				var a = oldTaskVersion.updateAttachments();
				var b = this.updateAttachments() || [];

				return _.filter(a, function (o) {
					for (var i=0; i<b.length; i++){
						if (b[i].id === o.id)
							return false;
					}
					return true;
				});
			},*/
			
			//----------------------------------------------------
			
			//erledigt?
			isCompleted: function () {
				return (this.status === constants.STATUS_COMPLETED);
			},
			
			//----------------------------------------------
			
			//Prüfung der Zuordnung
			isAssignedTo: function (userId) {
				var ts = this.getCurrentState();
				if (!ts)
					return false;
				return ts.isAssignedToUser(userId);
			},
			
			//----------------------------------------------------
			
			//Fälligkeit prüfen
			//Annahme: DateDue-Zuordnungen sind immer auf 00:00
			updateDateStatus: function () {
				
				if (!this.dueDate) {
					this.dateStatus = constants.DATE_UNDEFINED;
					return;
				}
				
				//vgl mit heute Nacht 00:00
				var dd = this.getDueDate();
				var now = new Date();
				now = new Date(now.getFullYear(), now.getMonth(), now.getDate());
				var d = new Date(dd.getFullYear(), dd.getMonth(), dd.getDate());
				var delta = ((now.getTime()) - d.getTime());
				
				//vor heute Nacht 00:00
				if (delta > 0) {
					this.dateStatus = constants.DATE_OVERDUE;
				}
				else {
					//days, please
					delta /= -(1000 * 60 * 60 * 24);
					if (delta < 1) {
						this.dateStatus = constants.DATE_TODAY;
					}
					else {

						//morgen?
						if (delta < 2) {
							this.dateStatus = constants.DATE_TOMORROW;
						}
						else {

							//bis inkl. kommenden Sonntag?
							//Sonntag == 0, Samstag == 6
							var dow = now.getDay();
							var daysUntilSunday = 7 - dow;
							if (delta <= daysUntilSunday) {
								this.dateStatus = constants.DATE_THIS_WEEK;
							}
							else {
								var daysUntilSundayNextWeek = 14 - dow;
								if (delta <= daysUntilSundayNextWeek)
									this.dateStatus = constants.DATE_NEXT_WEEK;
								else {

									//selber Monat?
									if ((now.getMonth() === d.getMonth()) && (now.getFullYear() === d.getFullYear())) {
										this.dateStatus = constants.DATE_THIS_MONTH;
									}
									else{
										if (((now.getFullYear() === d.getFullYear()) && (now.getMonth()+1 === d.getMonth())) || ((now.getFullYear()+1 === d.getFullYear()) && (now.getMonth() === 11) && (d.getMonth() === 0))) {
											this.dateStatus = constants.DATE_NEXT_MONTH;
										}
										else{
											this.dateStatus = constants.DATE_LATER;
										}
									}
								}
							}
						}
					}
				}
			},
			
			//----------------------------------------------
			
			//Filter anwenden
			matchesFilter: function (filterObj) {

				/*jshint -W089 */
				for(var field in filterObj) {
					
					var val = filterObj[field],
						isValid,
						i,
						j,
						arr,
						loc,
						d,
						dd,
						now,
						locResult,
						l,
						lv,
						valInt;

					if ((val === undefined) || (val === null))
						continue;

					switch (field) {
						case "taskId":
							var tId = "" + this.taskId;
							if (tId.indexOf(val) < 0)
								return false;
							break;
						case "title":
							if (this.title)
								if (this.title.toLowerCase().indexOf(val.toLowerCase()) < 0)
									return false;
							break;
						case "status":
							//multiple!
							if ($.isArray(val)) {
								isValid = false;
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									switch (valInt) {
										case -1:
											//alle
											isValid = true;
											break;
										default:
											//aktiv
											if (this.getMasterStatus() === constants.TASK_STATUS_ACTIVE) {
												if (this.getStatus() === valInt)
													isValid = true;
											}
											else{
												if (this.getMasterStatus() === valInt)
													isValid = true;
											}
											break;
									}
									if (isValid)
										break;
								}

								if (!isValid)
									return false;
							}
							break;
						case "taskTypeId":
							//multiple!
							if ($.isArray(val)) {
								isValid = false;
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									switch (valInt) {
										case -1:
											//alle
											isValid = true;
											break;
										default:
											if (this.taskTypeId === valInt)
												isValid = true;
											break;
									}
									if (isValid)
										break;
								}

								if (!isValid)
									return false;
							}
							else{
								valInt = parseInt(val);
								if (valInt < 0)
									continue;
								if (this.taskTypeId !== valInt)
									return false;
							}
							break;

						case "interval":
							valInt = parseInt(val);
							if (valInt < 0)
								continue;
							//ja
							if (valInt === 1) {
								if (!this.intervalId)
									return false;
							}
							else{
								if (this.intervalId)
									return false;
							}
							break;
						case "locationType":
							//multiple!
							if ($.isArray(val)) {
								isValid = false;
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									switch (valInt) {
										case -1:
											//alle
											isValid = true;
											break;
										default:
											loc = Location.getLocation(this.locationList[0], undefined, false, false);
											if ((loc) && (loc.type === valInt))
												isValid = true;
											break;
									}
									if (isValid)
										break;
								}

								if (!isValid)
									return false;
							}
							else {
								valInt = parseInt(val);
								if (valInt < 0)
									continue;
								loc = Location.getLocation(this.locationList[0], undefined, false, false);
								if (loc) {
									if (loc.type !== valInt)
										return false;
								} else {
									return false;
								}
							}
							break;
						case "locationId":
							if ($.isArray(val)) {
								isValid = false;

								//id, nicht name!
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									if (valInt < 0) {
										isValid = true;
										break;
									}

									if (this.locationList) {
										for (j=0; j<this.locationList.length; j++) {
											if (this.locationList[j] === valInt){
												isValid = true;
												break;
											}
										}
									}
									if (isValid)
										break;
								}

								if (!isValid)
									return false;
							}
							break;

						case "locations":
							locResult = true;
							//Kurzbezeichnung durchsuchen (auch gelöschte etc.)
							l = Location.getLocationList(this.locationList, undefined, undefined, true, undefined, true);
							if (l) {
								lv = l.toLowerCase();
								locResult = (lv.indexOf(val.toLowerCase()) >= 0);
							}
							if (!locResult || !l) {

								//Langbezeichnung durchsuchen (auch gelöschte etc.)
								l = Location.getLocationList(this.locationList, undefined, undefined, false, undefined, true);
								if (l) {
									lv = l.toLowerCase();
									locResult = (lv.indexOf(val.toLowerCase()) >= 0);
								} else {
									dd = this.getDynDataObjByRole(TaskTypeField.ROLE_LOCATION);
									if (dd) {
										l = (dd.data) ? dd.taskTypeField.getFormattedValue(dd.data.value) : "";
										lv = l.toLowerCase();
										if (lv.indexOf(val.toLowerCase()) < 0)
											return false;
									} else {
										return false;
									}
								}

								if (!locResult)
									return false;
							}
							break;

						case "routing":
							//multiple!
							if ($.isArray(val)) {
								isValid = false;
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									if (valInt < 0) {
										isValid = true;
										break;
									}
									var ugs = this.getCurrentState().getUserGroups();
									for (j=0; j<ugs.length; j++) {
										if (ugs[j].id === valInt) {
											isValid = true;
											break;
										}
									}
									if (isValid)
										break;
								}

								if (!isValid)
									return false;
							}
							break;

						case "dueDateFrom":
							arr = val.split(".");
							if (arr.length === 3)
								if (parseInt(arr[2]) < 100)
									arr[2] = 2000 + parseInt(arr[2]);
							d = new Date(arr[2], arr[1] - 1, arr[0]);
							//schon vorher?
							dd = this.getDueDate();
							if (dd) {
								//nur ohne Fälligkeit
								if (val === "-")
									return false;
								if (dd < d)
									return false;
							}
							else {
								//ohne Fälligkeit bei Filterung ausblenden (es sei denn, genau das wäre gewünscht -> "-")
								if (val !== "-")
									return false;
							}
							break;

						case "dueDateTo":
							arr = val.split(".");
							if (arr.length === 3)
								if (parseInt(arr[2]) < 100)
									arr[2] = 2000 + parseInt(arr[2]);
							d = new Date(arr[2], arr[1] - 1, arr[0]);
							//erst danach?
							dd = this.getDueDate();
							if (dd) {
								//nur ohne Fälligkeit
								if (val === "-")
									return false;
								if (d < dd)
									return false;
							}
							else {
								//ohne Fälligkeit bei Filterung ausblenden (es sei denn, genau das wäre gewünscht -> "-")
								if (val !== "-")
									return false;
							}
							break;

						case "dueDateType":
							if ($.isArray(val)) {
								isValid = false;
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									switch (valInt) {
										case -1:
											//alle
											isValid = true;
											break;
										default:
											if (this.dateStatus === undefined)
												this.updateDateStatus();
											if (this.dateStatus !== valInt) {

												//Sonderfälle (Obermengen)
												if ((valInt === constants.DATE_THIS_WEEK) && (this.dateStatus === constants.DATE_TODAY))
													isValid = true;
												if ((valInt === constants.DATE_THIS_WEEK) && (this.dateStatus === constants.DATE_TOMORROW))
													isValid = true;
												if ((valInt === constants.DATE_THIS_MONTH) && (this.dateStatus === constants.DATE_TODAY))
													isValid = true;
												if ((valInt === constants.DATE_THIS_MONTH) && (this.dateStatus === constants.DATE_TOMORROW))
													isValid = true;
												if ((valInt === constants.DATE_THIS_MONTH) && (this.dateStatus === constants.DATE_THIS_WEEK))
													isValid = true;
												//auch in bestimmten Situationen
												if ((valInt === constants.DATE_THIS_MONTH) && (this.dateStatus === constants.DATE_NEXT_WEEK)) {
													dd = this.getDueDate();
													now = new Date();
													d = new Date(dd.getFullYear(), dd.getMonth(), dd.getDate());
													if ((now.getMonth() === d.getMonth()) && (now.getFullYear() === d.getFullYear()))
														isValid = true;
												}

											}
											else{
												isValid = true;
											}
											break;
									}
									if (isValid)
										break;
								}

								if (!isValid)
									return false;
							}
							break;

						case "incidentNotification":
							valInt = parseInt(val);
							switch (valInt) {
								case -1:
									continue;
								case 1:
									if (this.notificationCount === 0)
										return false;
									break;
								case 0:
									if (this.notificationCount > 0)
										return false;
									break;
							}
							break;

						case "taskIncidentType":
							valInt = parseInt(val);
							switch (valInt) {
								case -1:
									continue;
								default:
									if (!this.hasIncidentType(valInt))
										return false;
									break;
							}
							break;
					}
				}
				
				return true;
			},

			//mind. ein bestimmtes Vorkommnis im Auftrag enthalten?
			hasIncidentType: function(type){

				var count = 0;
				var incidents = this.getIncidents();
				//var combinedValue = (incidents.length > 0);
				//var hasMatchingIncType = false;
				for (var i=0; i<incidents.length; i++){

					var inc = incidents[i];

					switch (type){

						case Incident.INCIDENT_TYPE_ANY:
							count++;
							break;

						case Incident.INCIDENT_TYPE_DEFECT:
							if (inc.type === Incident.INCIDENT_DEFECT)
								count++;
							break;

						case Incident.INCIDENT_TYPE_OBSERVATION:
							if (inc.type === Incident.INCIDENT_OBSERVATION)
								count++;
							break;

						case Incident.INCIDENT_TYPE_UNFIXED_ANY:
							if (inc.hasSignal(Signal.SIGNAL_DEFECT, Signal.VALUE_NOT_OK))
								count++;
							break;

						case Incident.INCIDENT_TYPE_UNFIXED_DEFECT:
							if (inc.type === Incident.INCIDENT_DEFECT){
								if (inc.hasSignal(Signal.SIGNAL_DEFECT, Signal.VALUE_NOT_OK))
									count++;
							}
							break;

						case Incident.INCIDENT_TYPE_UNFIXED_OBSERVATION:
							if (inc.type === Incident.INCIDENT_OBSERVATION){
								if (inc.hasSignal(Signal.SIGNAL_DEFECT, Signal.VALUE_NOT_OK))
									count++;
							}
							break;

						//--------------------
						//ALLE müssen gefixt sein
						case Incident.INCIDENT_TYPE_FIXED_ANY:
							//hasMatchingIncType = true;
							//if (inc.hasOnlySignalsOfType(Signal.SIGNAL_DEFECT, Signal.VALUE_OK_DEFAULT))
							if (!inc.hasSignal(Signal.SIGNAL_DEFECT, Signal.VALUE_NOT_OK))
								//combinedValue = combinedValue && true;
								count++;
							/*else
								return false;*/
							break;

						//ALLE müssen gefixt sein
						case Incident.INCIDENT_TYPE_FIXED_DEFECT:
							if (inc.type === Incident.INCIDENT_DEFECT){
								//hasMatchingIncType = true;
								//if (inc.hasOnlySignalsOfType(Signal.SIGNAL_DEFECT, Signal.VALUE_OK_DEFAULT))
								if (!inc.hasSignal(Signal.SIGNAL_DEFECT, Signal.VALUE_NOT_OK))
									//combinedValue = combinedValue && true;
									count++;
								/*else
									return false;*/
							}
							break;

						//ALLE müssen gefixt sein
						case Incident.INCIDENT_TYPE_FIXED_OBSERVATION:
							if (inc.type === Incident.INCIDENT_OBSERVATION){
								//hasMatchingIncType = true;
								//if (inc.hasOnlySignalsOfType(Signal.SIGNAL_DEFECT, Signal.VALUE_OK_DEFAULT))
								if (!inc.hasSignal(Signal.SIGNAL_DEFECT, Signal.VALUE_NOT_OK))
									//combinedValue = combinedValue && true;
									count++;
								/*else
									return false;*/
							}
							break;
					}
				}

				//Auswertung
				/*switch (type){
					case Incident.INCIDENT_TYPE_FIXED_ANY:
					case Incident.INCIDENT_TYPE_FIXED_DEFECT:
					case Incident.INCIDENT_TYPE_FIXED_OBSERVATION:
						if (hasMatchingIncType)
							return combinedValue;
						break;
				}

				return false;*/
				return (count>0);
			},
			
			//----------------------------------------------
			
			//gibt es ein neueres Intervall?
			isNewestChild: function () {
				//kein Intervall?
				if (this.intervalId <= 0)
					return true;
				return (this.nextGenerationTask === null);
			},
			
			//----------------------------------------------

			getLatestProtocol: function(){
				if (this.protocols.length === 0)
					return null;
				return this.protocols[this.protocols.length - 1];
			},

			//Status frisch ermitteln
			updateStatus: function () {
				//if (this.hasProtocols()) {
				if (this.hasLocations()) {
					this.status = Task.computeStatus(this.totalProtocolCount, this.protocolCount, this.getLocationCount());
				}
				else {
					if (this.protocols.length > 0) {
						if (this.protocols[0].id < 0)
							this.status = constants.STATUS_PROGRESS;
						else
							this.status = constants.STATUS_COMPLETED;
					}
					else
						this.status = constants.STATUS_NEW;
				}
			},
			
			//-------------------------------------------------------------------------
			
			//Label und errechneten Status zusammenführen
			getStatus: function () {
				var ts = this.getCurrentState();
				if (ts)
					return ts.labelId || this.status;
				return this.status;
			},
			
			//-----------------------------------------------------------------
			//Intervalle und Fälligkeit
			
			//Intervall-Aufträge haben von...bis-Zeiträume
			getIntervalDates: function () {
				
				var iv = Task.getIntervalValues(this.intervalType);
				
				return {
					from: Task.getIntervalDate(this.dueDate, iv.intervalType, iv.factor, iv.weekdays, false),
					to: this.dueDate
				};
			},
			
			getDueDate: function () {
				return this.dueDate;
			},
			
			isInterval: function () {
				if (!this.intervalType)
					return false;
				return (this.intervalType.length > 0);
			},
			
			getIntervalName: function () {
				
				if ((this.intervalType === null) || (this.intervalType === ""))
					return "Ohne";
				
				//custom?
				var intervalValues = Task.getIntervalValues(this.intervalType);
				return Task.createIntervalName(intervalValues);
			},

			//der neueste nicht-gelöschte Auftrag der Serie?
			isLatestActiveIntervalVersion: function(){

				var nonDeletedTasks = Task.getTasksByIntervalId(this.intervalId);
				if (nonDeletedTasks.length > 0)
					return nonDeletedTasks[0] === this;

				return false;
			},

			//-------------------------------------------------
			
			getDueDateColor: function () {
				
				var dd = this.getDueDate();
				if (dd) {
					//immer morgens 00:00 vergleichen
					var now = new Date();
					now.setHours(0);
					now.setMinutes(0);
					now.setSeconds(0);
					now.setSeconds(0);
					now.setMilliseconds(0);
					
					//schon erledigt?
					if (this.isCompleted())
						return "#29333a";
					//überfällig?
					if (((now.getTime()) > dd.getTime()))
						return "#e31d2d";
				}
				return "#29333a";
			},
			
			//-----------------------------------------------------------------
			
			//aktuellen Zustand holen
			getCurrentState: function () {
				if (this.states.length > 0)
					return this.states[this.states.length - 1];
				return null;
			},

			//createdOn >= threshold (epoch = seconds since 1970)
			getState: function(){
				var tsThresholdEpoch = this.createdOn;
				for (var i=0; i<this.states.length; i++){
					var ts = this.states[i];
					if (ts.createdOn.getTime() >= tsThresholdEpoch)
						return ts;
				}
				return this.getCurrentState();
			},

			//einen Fake-State mit echter Server-Antwort überschreiben
			updateState: function(tsOld, tsNew){

				var hasMatch = false;
				for (var i=0; i<this.states.length; i++){
					var ts = this.states[i];
					if (ts.id === tsOld.id){
						this.states[i] = tsNew;
						hasMatch = true;
						break;
					}
				}

				if (!hasMatch)
					this.states.push(tsNew);
			},
			
			//----------------------------------------
			
			//neues Protokoll hinzufügen
			addProtocol: function (status, formId, locationId, protocolData, attachments, incidents) {
				
				//schon da?
				var p = this.getProtocol(model.curUserId,
					formId,
					locationId);
				
				if (p === null) {
					
					//neues Protokoll erzeugen
					p = Protocol.createProtocol();
					
					//noinspection JSUnresolvedVariable
					var guid = Math.floor(Math.random() * constants.MAX_SAFE_INTEGER_MYSQL);
					p.id = guid * -1;
					p.version = 1;
					p.createdOn = new Date();
					p.createdBy = -1;
					p.submittedOn = new Date();
					p.changedOn = new Date();
					p.changedBy = -1;
					p.userId = model.curUserId;
					p.taskId = this.taskId;
					p.formId = formId;
					p.locationId = locationId;
					p.isCurrentVersion = true;
					
					//und anhängen
					//vorheriges überschreiben
					var isNewProtocol = true;
					for(var i = 0; i < this.protocols.length; i++) {
						var pOld = this.protocols[i];
						if ((pOld.locationId === p.locationId) && (pOld.formId === p.formId)) {
							this.protocols[i] = p;
							isNewProtocol = false;
							break;
						}
					}
					
					//ansonsten anhängen
					if (isNewProtocol) {
						this.protocols.push(p);
					}
				}
				
				//update
				p.status = status;
				
				p.protocolData = protocolData;
				p.attObj = attachments;
				p.attachments = Attachment.serializeAttachments(attachments);
				p.incidents = incidents;

				//Fehlerhafte Protokolle aussortieren
				this.updateProtocols();

				//neuen Status auf Task anwenden
				this.updateProtocolCount();
				this.updateStatus();

				return p;
			},

			hasProtocol: function(locationId, formId){
				for(var i = 0; i < this.protocols.length; i++) {
					var p = this.protocols[i];
					if ((p.locationId === locationId) && (p.formId === formId))
						return true;
				}
				return false;
			},
			
			//----------------------------------------------
			
			//neueste Protokolle markieren
			updateProtocols: function () {

				var p,
					j;
				var removedProtocols = [];

				for(var i = this.protocols.length-1; i>=0; i--) {

					p = this.protocols[i];

					//Verbindung untereinander vorbereiten
					p.isCurrentVersion = true;

					//und diejenigen Protokolle aussortieren, die sich auf ein nicht (mehr) zugeordnetes Objekt beziehen
					var hasLoc = false;
					if (p.locationId > 0){
						var loc = this.getLocationIds();
						for (j=0; j<loc.length; j++){
							if (loc[j] === p.locationId){
								hasLoc = true;
								break;
							}
						}
						if (!hasLoc){
							//Protokoll entfernen
							removedProtocols.push({
								protocol: p,
								reason: Task.PROTOCOL_REMOVED_NO_MATCHING_LOCATION
							});
							this.protocols.splice(i, 1);
						}
					}
					else{
						//virtual
						hasLoc = true;
					}

					//Duplikat? Erster gewinnt.
					if (hasLoc){
						for (j=0; j<i; j++){
							var p2 = this.protocols[j];
							if ((p.locationId > 0) && (p.locationId === p2.locationId)){
								//Protokoll entfernen
								removedProtocols.push({
									protocol: p,
									reason: Task.PROTOCOL_REMOVED_DUPLICATE
								});
								this.protocols.splice(i, 1);
								break;
							}
						}
					}
				}

				//neu verbinden
				for(i = 0; i < this.protocols.length; i++) {
					p = this.protocols[i];

					//Versionierung
					if (p.version > 1) {
						//direkten Vorgänger suchen
						for (var k=p.version-1; k>0; k--) {
							var pPrev = this.getProtocolById(p.id, k);
							if (pPrev) {
								p.prevVersion = pPrev;
								pPrev.nextVersion = p;
								pPrev.isCurrentVersion = false;
								break;
							}
						}
					}
				}

				return removedProtocols;
			},
			
			//----------------------------------------------
			//ein bestimmtes Protokoll finden
			getProtocol: function (userId, formId, locationId) {
				for(var k = 0; k < this.protocols.length; k++) {
					var p = this.protocols[k];
					if ((p.locationId === locationId) && (p.formId === formId) && (p.userId === userId))
						if (p.isCurrentVersion)
							return p;
				}
				
				return null;
			},

			//alles unternehmen, um Protokoll zu finden!
			getProtocolByPendingObj: function(p){

				var prot = null;
				var pEx;
				for (var j = 0; j < this.protocols.length; j++) {
					pEx = this.protocols[j];
					//legacy
					if ((pEx.id === p.id) || (pEx.id === p.data.id)) {
						prot = pEx;
						break;
					}
				}

				//bereits übermittelt, aber ggf. abgestürzt und daher nicht mehr zuordenbar?
				if (!prot) {

					//anhand anderer Kriterien suchen
					for (j = 0; j < this.protocols.length; j++) {
						pEx = this.protocols[j];
						if (pEx.matchesProtocol(p.data)) {
							prot = pEx;
							break;
						}
					}
				}

				return prot;
			},
			
			//ein bestimmtes Protokoll eines Objektes finden
			getProtocolsByLocation: function (locationId) {
				var a = [];
				for(var k = 0; k < this.protocols.length; k++) {
					var p = this.protocols[k];
					if (p.locationId === locationId)
						if (p.isCurrentVersion)
							a.push(p);
				}
				
				return a;
			},
			//dto anhand der ID
			getProtocolById: function (protocolId, version) {
				for(var k = 0; k < this.protocols.length; k++) {
					var p = this.protocols[k];
					if (p.id === protocolId) {
						
						if (version === undefined) {
							if (!p.isCurrentVersion)
								continue;
						}
						else if (p.version !== version)
							continue;
						
						return p;
					}
				}
				
				return null;
			},
			
			//----------------------------------------------
			
			getLocationCount: function () {
				var validLocationCount = 0;
				for(var i = 0; i < this.locationObjects.length; i++)
					if (this.locationObjects[i].isAbstract === 0)
						validLocationCount++;
				return validLocationCount;
			},
			hasLocations: function(){
				return (this.getLocationCount() > 0);
			},
			
			//auch DynData berücksichtigen
			hasAnyLocations: function(){
				if (this.hasLocations())
					return true;
				
				var dd = this.getDynDataObjByRole(TaskTypeField.ROLE_LOCATION);
				if (dd)
					if (dd.data)
						if (dd.data.value)
							return true;
				
				return false;
			},
			
			//zählen der zugeordnete Protokolle (erledigt, in Bearb., ...)
			updateProtocolCount: function () {
				
				var tt = TaskType.getTaskType(this.taskTypeId);
				if (!tt)
					return;
				var validLocationCount = this.getLocationCount();
				this.totalLocationCount = validLocationCount;
				if (validLocationCount > 0)
					this.totalProtocolCount = validLocationCount * tt.formIds.length;
				else
					this.totalProtocolCount = 1 * tt.formIds.length;
				
				//count protocols
				this.protocolCount = [0, 0, 0, 0, 0];
				this.protocolFotosCount = 0;
				if (this.hasProtocols()) {
					for(var k = 0; k < this.protocols.length; k++) {
						var p = this.protocols[k];
						var countProtocol = false;
						if (validLocationCount > 0) {
							//valide Location?
							var loc = Location.getLocation(p.locationId, undefined, true, true, true);
							if (loc)
								countProtocol = true;
						}
						else
							countProtocol = true;
						if (countProtocol)
							if (p.isCurrentVersion)
								this.protocolCount[p.status]++;
						
						//#Fotos
						p.updateAttachments();
						this.protocolFotosCount += p.attObj.length;
					}
				}
				
				//dto for locations
				this.locationCount = [0, 0, 0, 0, 0];
				if (this.hasProtocols()) {
					for(var i = 0; i < this.locationObjects.length; i++) {
						var status = this.getLocationStatus(this.locationObjects[i].locationId);
						this.locationCount[status]++;
					}
				}
			},
			
			//dto, aber für bestimmtes Objekt und darunter
			getProtocolCountFor: function (locationRootId) {
				
				var tt = TaskType.getTaskType(this.taskTypeId);
				var validLocationCount = 0;
				for(var i = 0; i < this.locationObjects.length; i++)
					if (this.locationObjects[i].isAbstract === 0)
						if (this.locationObjects[i].isEqualOrBelow(locationRootId))
							validLocationCount++;
				var totalLocationCount = validLocationCount;
				var totalProtocolCount = validLocationCount * tt.formIds.length;
				
				//count protocols
				var protocolCount = [0, 0, 0, 0, 0];
				for(var k = 0; k < this.protocols.length; k++) {
					var p = this.protocols[k];
					if (p.isCurrentVersion) {
						var loc = Location.getLocation(p.locationId, undefined, true, true, true);
						if (loc)
							if (loc.isEqualOrBelow(locationRootId))
								protocolCount[p.status]++;
					}
				}
				
				//dto for locations
				var locationCount = [0, 0, 0, 0, 0];
				//if (this.locationObjects)
				for(i = 0; i < this.locationObjects.length; i++)
					if (this.locationObjects[i].isEqualOrBelow(locationRootId)) {
						var status = this.getLocationStatus(this.locationObjects[i].locationId);
						locationCount[status]++;
					}
				
				return {
					locationCount: locationCount,
					totalLocationCount: totalLocationCount,
					protocolCount: protocolCount,
					totalProtocolCount: totalProtocolCount
				};
			},
			
			//----------------------------------------------
			
			//Protokolle holen (ggf. auch Einträge für noch nicht existierende erzeugen)
			getAllProtocols: function (createVirtual) {
				
				if (createVirtual === undefined)
					createVirtual = true;
				
				var prot = [];

				//Forms ermitteln
				var tt = TaskType.getTaskType(this.taskTypeId);
				
				var j,
					formId,
					hasProtocol,
					p,
					k;
				if (this.hasLocations()) {
					//alle virtuell erzeugen
					for(var i = 0; i < this.locationObjects.length; i++) {
						var loc = this.locationObjects[i];
						if (loc.isAbstract === 0) {
							
							//alle erforderlichen Protokolle
							for(j = 0; j < tt.formIds.length; j++) {
								
								formId = parseInt(tt.formIds[j], 10);
								
								//...es sei denn ein Protokoll existiert -> dann dieses
								hasProtocol = false;
								for(k = 0; k < this.protocols.length; k++) {
									p = this.protocols[k];
									if ((p.locationId === loc.locationId) && (p.formId === formId))
										if (p.isCurrentVersion) {
											prot.push(p);
											hasProtocol = true;
										}
								}
								
								if (createVirtual)
									if (!hasProtocol) {
										p = Protocol.createProtocol();
										p.isVirtual = true;
										p.taskId = this.taskId;
										p.dueDate = this.dueDate;
										p.status = constants.STATUS_NEW;
										p.locationId = loc.locationId;
										p.formId = formId;
										prot.push(p);
									}
							}
						}
					}
				}
				else{
					//alle erforderlichen Protokolle
					for(j = 0; j < tt.formIds.length; j++) {
						
						formId = parseInt(tt.formIds[j], 10);
						
						//...es sei denn ein Protokoll existiert -> dann dieses
						hasProtocol = false;
						for(k = 0; k < this.protocols.length; k++) {
							p = this.protocols[k];
							if (p.isCurrentVersion) {
								prot.push(p);
								hasProtocol = true;
							}
						}
						
						if (createVirtual)
							if (!hasProtocol) {
								p = Protocol.createProtocol();
								p.isVirtual = true;
								p.taskId = this.taskId;
								p.dueDate = this.dueDate;
								p.status = constants.STATUS_NEW;
								p.locationId = -1;
								p.formId = formId;
								prot.push(p);
							}
					}
				}
				
				return prot;
			},
			
			//-------------------------------------------------
			
			//location überhaupt relevant für Task?
			isLocationContained: function (location) {
				if (location.isAbstract === 1) {
					//irgendjemand drin?
					for(var i = 0; i < location.children.length; i++) {
						if (this.isLocationContained(location.children[i]))
							return true;
					}
				}
				else {
					for(var j = 0; j < this.locationObjects.length; j++) {
						var loc = this.locationObjects[j];
						if (loc === location)
							return true;
					}
				}
				
				return false;
			},
			
			//Protokoll-Zustand eines Objektes holen
			getLocationStatus: function (locationId) {
				
				var status = constants.STATUS_UNDEFINED;
				
				var l = Location.getLocation(locationId, undefined, true, true, true);
				if (l.isAbstract === 1) {
					
					var a = Location.getLocationChildren(locationId);
					for(var i = 0; i < a.length; i++) {
						if (this.isLocationContained(a[i])) {
							var otherStatus = this.getLocationStatus(a[i].locationId);
							status = Task.mergeStatus(status, otherStatus);
						}
					}
					
					if (status === constants.STATUS_UNDEFINED)
						status = constants.STATUS_NEW;
					return status;
				}
				
				//für Knoten immer NEW als Default
				status = constants.STATUS_NEW;
				
				//Forms ermitteln
				var tt = TaskType.getTaskType(this.taskTypeId);
				var protocols = this.getLocationProtocols(locationId);
				
				var totalCount = tt.formIds.length;
				var protocolCount = [0, 0, 0, 0, 0];
				
				//alle erforderlichen Protokolle
				for(var j = 0; j < tt.formIds.length; j++) {
					
					var formId = parseInt(tt.formIds[j], 10);
					
					//existiert Protokoll?
					for(var k = 0; k < protocols.length; k++) {
						var p = protocols[k];
						if (p.formId === formId) {
							protocolCount[p.status]++;
							
							break;
						}
					}
				}
				
				status = Task.computeStatus(totalCount, protocolCount, 1);
				
				return status;
			},
			
			//alle Protokolle eines Objektes holen
			getLocationProtocols: function (locationId) {
				
				var protocols = [];
				for(var i = 0; i < this.locationObjects.length; i++) {
					var loc = this.locationObjects[i];
					if (loc.locationId === locationId) {
						for(var k = 0; k < this.protocols.length; k++) {
							var p = this.protocols[k];
							if (p.locationId === loc.locationId)
								protocols.push(p);
						}
						break;
					}
				}
				return protocols;
			},
			
			//----------------------------------------------
			
			//Baum-Funktion:
			//get deepest common parent of all those task locations located below an ID
			//ohne Argument: alle! -> Top-Parent ALLER
			getCommonParentLocation: function (parentLocationId) {
				
				//zuerst mal alle extrahieren, die unterhalb des parameter-Roots sind
				var lo = [];
				var l;
				//dabei auf flachste Tiefe untersuchen
				var minDepth = 1000;
				var minDepthLocation = null;
				for(var i = 0; i < this.locationObjects.length; i++) {
					l = this.locationObjects[i];

					//aber keine Berechtigungen prüfen!
					//if (!l.isVisibleForCurrentUser())
					//	continue;

					var addLoc = false;
					if (parentLocationId){
						if (l.locationId !== parentLocationId)
							if (l.isEqualOrBelow(parentLocationId))
								addLoc = true;
					}
					else{
						addLoc = true;
					}

					if (addLoc) {
						lo.push(l);

						if (l.depth < minDepth) {
							minDepth = l.depth;
							minDepthLocation = l;
						}
					}
				}
				
				if (lo.length === 0)
					return null;
				
				//die flachste Location ist ausschlaggebend
				//alle mit ihrer hierarchischen Parent-Liste vergleichen
				var bestLocation = Location.getLocation(minDepthLocation.parentLocationId);
				if (bestLocation)
					for(i = 0; i < lo.length; i++) {
						l = lo[i];
						if (l !== minDepthLocation) {
							
							//Kind des Vergleichs-Vaters?
							if (l.isEqualOrBelow(bestLocation.locationId))
								continue;
							
							//nein, wir müssen sukzessiv eins weiter nach oben
							while (true) {
								if (bestLocation.parentLocationId === 0)
									return null;
								bestLocation = Location.getLocation(bestLocation.parentLocationId);
								if (l.isEqualOrBelow(bestLocation.locationId))
									break;
							}
						}
					}
				
				return bestLocation;
			},
			
			//----------------------------------------------
			
			//zugeordneten Teil-Baum aufbauen
			buildLocationObjects: function (parentLocationId) {
				
				//zuerst mal alle extrahieren, die unterhalb des parameter-Roots sind
				var lo = [];
				var l;
				for(var i = 0; i < this.locationObjects.length; i++) {
					l = this.locationObjects[i];

					//aber keine Berechtigungen prüfen!
					//if (!l.isVisibleForCurrentUser())
					//	continue;
					
					if (l.isEqualOrBelow(parentLocationId)) {
						//markieren
						l.hasBeenHandled = false;
						lo.push(l);
					}
				}
				
				if (lo.length === 0)
					return lo;
				
				//special: alle im selben Ordner? dann selbst rausreichen!
				var pId = lo[0].parentLocationId;
				for(i = 1; i < lo.length; i++)
					if (lo[i].parentLocationId !== pId) {
						//markieren
						pId = -1;
						break;
					}
				if (pId !== -1) {

					//sortieren
					lo.sort(function (a, b) {

						var aN = a.getName(true).toLowerCase();
						var bN = b.getName(true).toLowerCase();

						return ((aN < bN) ? -1 : ((aN > bN) ? 1 : 0));
					});

					return lo;
				}
				
				//gruppieren: falls mehrere in demselben Ordner liegen, stattdessen diese verwenden
				var lGroup = [];
				var k;
				var lParent;
				
				//alle nicht zuordenbaren
				for(i = 0; i < lo.length; i++) {
					l = lo[i];
					if (!l.hasBeenHandled) {
						
						//Alle in Foldern!
						var hierarchy = l.getHierarchy();
						for(k = 0; k < hierarchy.length; k++)
							if (hierarchy[k].parentLocationId === parentLocationId) {
								
								var newEntry = hierarchy[k];
								
								//Gruppe schon enthalten?
								lParent = Location.getLocation(l.parentLocationId);
								for(var kk = 0; kk < lGroup.length; kk++)
									if (lGroup[kk] === newEntry) {
										newEntry = null;
										break;
									}
								
								if (newEntry)
									lGroup.push(newEntry);
								break;
							}
					}
					
				}

				//sortieren
				lGroup.sort(function (a, b) {

					var aN = a.getName(true).toLowerCase();
					var bN = b.getName(true).toLowerCase();

					return ((aN < bN) ? -1 : ((aN > bN) ? 1 : 0));
				});

				return lGroup;
			},

			//gibt es in dieser Objektgruppe mind. ein Objekt?
			hasLocationsInGroup: function(parentLocationId){

				for(var i = 0; i < this.locationObjects.length; i++) {
					var l = this.locationObjects[i];

					if (l.parentLocationId === parentLocationId) {

						//alle Kinder darin
						var a = Location.getLocationChildren(l.parentLocationId);
						for(var j = 0; j < a.length; j++) {

							//Teil der Objektliste?
							for(var k = 0; k < this.locationObjects.length; k++) {

								var l2 = this.locationObjects[k];
								if (l2.locationId === a[j].locationId){
									return true;
								}
							}
						}
					}
				}

				return false;
			},
			
			//gibt es in dieser Objektgruppe mind. ein Objekt mit mind. einem nicht komplett ausgefüllten Protokoll?
			hasUncompletedLocationsInGroup: function(parentLocationId){
				
				for(var i = 0; i < this.locationObjects.length; i++) {
					var l = this.locationObjects[i];

					//aber keine Berechtigungen prüfen!
					//if (!l.isVisibleForCurrentUser())
					//	continue;
					
					if (l.parentLocationId === parentLocationId) {
						
						//alle Kinder darin
						var a = Location.getLocationChildren(l.parentLocationId);
						for(var j = 0; j < a.length; j++) {
							
							//Teil der Objektliste?
							for(var k = 0; k < this.locationObjects.length; k++) {
								
								var l2 = this.locationObjects[k];
								//aber keine Berechtigungen prüfen!
								//if (!l2.isVisibleForCurrentUser())
								//	continue;
								
								if (l2.locationId === a[j].locationId){
									//unausgefüllte Protokolle?
									var status = this.getLocationStatus(l2.locationId);
									if (status !== constants.STATUS_COMPLETED)
										return true;
									break;
								}
							}
						}
					}
				}
				
				return false;
			},
			
			//----------------------------------------------
			
			//Task aktualisieren
			update: function () {

				var result = {};

				this.isCurrentVersion = true;
				
				//locations
				this.updateLocationObjects();
				
				//Versionierung
				if (this.version > 1) {
					//direkten Vorgänger suchen
					for (var k=this.version-1; k>0; k--) {
						var tPrev = Task.getTask(this.taskId, k);
						if (tPrev) {
							this.prevVersion = tPrev;
							tPrev.nextVersion = this;
							tPrev.isCurrentVersion = false;
							break;
						}
					}
				}
				
				//NOPE: Nachfolger/Vorgänger
				//Kinder
				this.prevGenerationTask = null;
				this.nextGenerationTask = null;
				if (this.intervalId > 0) {
					for(var j = 0; j < model.tasks.length; j++) {
						/*var tParent = model.tasks[j];
						 if (tParent.taskId == this.parentId){
						 tParent.addChild(this);
						 this.parentTask = tParent;
						 }*/
						var t = model.tasks[j];
						if (t.intervalId === this.intervalId) {
							//Vorgänger
							if (t.generationId === this.generationId - 1) {
								if (this.prevGenerationTask === null)
									this.prevGenerationTask = t;
								else
								//neueste Version verwenden
								if (t.version > this.prevGenerationTask.version)
									this.prevGenerationTask = t;
							}
							//Nachfolger
							if (t.generationId === this.generationId + 1) {
								if (this.nextGenerationTask === null)
									this.nextGenerationTask = t;
								else
								//neueste Version verwenden
								if (t.version > this.nextGenerationTask.version)
									this.nextGenerationTask = t;
							}
						}
					}
				}
				
				//Kommentare
				this.updateComments();
				
				//Protokolle
				result.removedProtocols = this.updateProtocols();
				
				//Datums-Einordnung
				this.updateDateStatus();
				
				//außerdem Protokollinfos sammeln
				this.updateProtocolCount();
				this.updateStatus();

				return result;
			},
			
			//----------------------------------------------
			//Kommentare
			
			updateComments: function () {
				if (this.prevVersion) {
					for(var i = 0; i < this.prevVersion.comments.length; i++)
						this.addComment(this.prevVersion.comments[i]);
				}
			},
			
			//----------------------------------------------
			//Vorkommnisse
			
			getIncidents: function () {
				var a = [];
				
				for(var i = 0; i < this.protocols.length; i++) {
					var p = this.protocols[i];
					if (p.isCurrentVersion)
						for(var j = 0; j < p.incidents.length; j++)
							a.push(p.incidents[j]);
				}
				
				return a;
			},
			getIncident: function (incidentId) {
				incidentId = parseInt(incidentId);
				
				for(var i = 0; i < this.protocols.length; i++) {
					var p = this.protocols[i];
					var cont = true;
					if (p.isCurrentVersion !== undefined)
						cont = p.isCurrentVersion;
					if (cont) {
						for(var j = 0; j < p.incidents.length; j++)
							if (p.incidents[j].id === incidentId)
								return p.incidents[j];
					}
				}
				
				return null;
			},
			getSignal: function (signalId) {
				signalId = parseInt(signalId);
				
				for(var i = 0; i < this.protocols.length; i++) {
					var p = this.protocols[i];
					var cont = true;
					if (p.isCurrentVersion !== undefined)
						cont = p.isCurrentVersion;
					if (cont) {
						for(var j = 0; j < p.incidents.length; j++) {
							var inci = p.incidents[j];
							for(var k = 0; k < inci.signals.length; k++) {
								if (inci.signals[k].id === signalId)
									return inci.signals[k];
							}
						}
					}
				}
				
				return null;
			},
			
			//---------------------------------------------------------------
			
			//Verlauf
			getHistory: function () {
				return this.history;
			},

			//---------------------------------------------------------------

			getOriginalTask: function(){
				var taskOriginal = this;
				while (taskOriginal.prevVersion)
					taskOriginal = taskOriginal.prevVersion;
				return taskOriginal;
			},
			
			//---------------------------------------------------------------

			//Status-Namen ermitteln
			getStatusName: function (overrideStatus) {
				var status = this.getStatus();
				if (overrideStatus !== undefined)
					status = overrideStatus;

				return Task.getStatusName(status);
			},
			
			getTaskLabel: function (overrideStatus) {
				
				var status = this.getStatus();
				if (overrideStatus !== undefined)
					status = overrideStatus;

				return Task.getTaskLabel(status);
			}
		},
		
		//----------------------------------------------------------------------------------

		//Status-Namen ermitteln
		getStatusName: function (status) {

			switch (status) {
				case constants.STATUS_UNDEFINED:
				case constants.STATUS_NEW:
					return "Offen";
				case constants.STATUS_PROGRESS:
					return "In Bearbeitung";
				case constants.STATUS_COMPLETED:
					return "Erledigt";
				default:
					//Label
					var tl = Task.getTaskLabel(status);
					if (tl)
						return tl.name;
			}

			return "";
		},

		getTaskLabel: function (status) {

			for(var i = 0; i < model.taskLabels.length; i++) {
				var tl = model.taskLabels[i];
				if (tl.id === status)
					return tl;
			}
			return null;
		},

		//Datensichten harmonisieren
		mergeStatus: function (s1, s2) {
			//merge
			switch (s1) {
				case constants.STATUS_UNDEFINED:
					return s2;
				case constants.STATUS_NEW:
					switch (s2) {
						case constants.STATUS_NEW:
						case constants.STATUS_PROGRESS:
							return s2;
						case constants.STATUS_COMPLETED:
							return constants.STATUS_PROGRESS;
					}
					break;
				
				case constants.STATUS_COMPLETED:
					switch (s2) {
						case constants.STATUS_NEW:
						case constants.STATUS_PROGRESS:
							return constants.STATUS_PROGRESS;
						case constants.STATUS_COMPLETED:
							return s2;
					}
					break;
				
				case constants.STATUS_PROGRESS:
					switch (s2) {
						case constants.STATUS_NEW:
						case constants.STATUS_PROGRESS:
						case constants.STATUS_COMPLETED:
							return s1;
					}
					break;
			}
		},
		
		//----------------------------------------------
		
		//Gesamtstatus bilden
		computeStatus: function (totalProtocolCount, protocolCount, locationCount) {
			
			var completedCount = protocolCount[constants.STATUS_COMPLETED];
			var progressCount = protocolCount[constants.STATUS_PROGRESS];
			
			//alles ok?
			//keine Objekte zugeordnet? -> trotzdem existiert bei Erledigung ein Protokoll!
			if (locationCount === 0) {
				if (completedCount > 0)
					return constants.STATUS_COMPLETED;
			}
			else {
				//es gab schon Fälle, in denen durch unglückliches Pipelining ein Protokoll mehrfach hinzugefügt wurde
				if (completedCount >= totalProtocolCount)
					return constants.STATUS_COMPLETED;
			}
			
			//noch gar nix
			if (completedCount + progressCount === 0)
				return constants.STATUS_NEW;
			
			//irgendwo dazwischen, nur OK
			if ((progressCount > 0) || (completedCount > 0))
				return constants.STATUS_PROGRESS;
			
			//nix von all dem
			return constants.STATUS_NEW;
		},
		
		//----------------------------------------------
		
		//einzelnen Auftrag suchen
		getTask: function (taskId, version, mustBeAssignedToUser) {

			if (mustBeAssignedToUser === undefined)
				mustBeAssignedToUser = false;

			if (!version)
				version = undefined;
			
			taskId = parseInt(taskId);
			for(var i = 0; i < model.tasks.length; i++) {
				
				var t = model.tasks[i];
				
				//keine gelöschten!
				if (t.getMasterStatus() === constants.TASK_STATUS_DELETED)
					continue;

				if (mustBeAssignedToUser){
					if (!t.isAssignedTo(model.curUserId))
						continue;
				}

				if (t.taskId === taskId) {
					
					//version?
					if (version !== undefined) {
						if (t.version !== parseInt(version, 10))
							continue;
					}
					else if (!t.isCurrentVersion)
						continue;
					
					return t;
				}
			}
			return null;
		},
		
		//----------------------------------------------
		
		//erstellten Auftrag eines Requests holen (neueste Fassung)
		getTaskByRequest: function (requestId) {

			if (!requestId || requestId<0)
				return null;

			var bestMatch = null;
			for(var i = 0; i < model.tasks.length; i++) {
				
				var t = model.tasks[i];
				
				//keine gelöschten!
				if (t.getMasterStatus() === constants.TASK_STATUS_DELETED)
					continue;
				
				if (t.requestId === requestId) {
					
					if (!t.isCurrentVersion)
						continue;
					
					if (!bestMatch)
						bestMatch = t;
					else {
						if (t.generationId > bestMatch.generationId)
							bestMatch = t;
					}
					
					return t;
				}
			}
			return bestMatch;
		},
		
		//----------------------------------------------
		
		//Aufträge gemäß Kriterien holen
		//getTasks: function (userId, exceptTaskId, allowOlderChildren, includeInactive, includeCompleted, getOwnersTasksToo, groupId) {
		 getTasks: function (args) {
			
			if (args === undefined)
				args = {};
			
			var userId = args.userId ? args.userId : -1,
				exceptTaskId = (args.exceptTaskId !== undefined) ? args.exceptTaskId : -1,
				allowOlderChildren = (args.allowOlderChildren !== undefined) ? args.allowOlderChildren : false,
				includeCompleted = (args.includeCompleted !== undefined) ? args.includeCompleted : true,
				getOwnersTasksToo = (args.getOwnersTasksToo !== undefined) ? args.getOwnersTasksToo : false,
				mustBeAssignedToUser = (args.mustBeAssignedToUser !== undefined) ? args.mustBeAssignedToUser : false,
				allowInvisibleTasks = (args.allowInvisibleTasks !== undefined) ? args.allowInvisibleTasks : false,
				groupId = (args.groupId !== undefined) ? args.groupId : -1;
			
			var a = [];
			//alle Tasks durchlaufen
			for(var i = 0; i < model.tasks.length; i++) {
				var t = model.tasks[i];
				
				//keine gelöschten!
				if (t.getMasterStatus() === constants.TASK_STATUS_DELETED)
					continue;

				//keine versteckten
				if (!allowInvisibleTasks)
					if (t.visibilityType === Task.VISIBILITY_TYPE_HIDDEN)
						continue;

				if (mustBeAssignedToUser){
					if (!t.isAssignedTo(model.curUserId))
						continue;
				}
				
				//alte erzeugte Tasks nicht anzeigen (sondern immer nur neuestes Erzeugnis davon)
				if (!allowOlderChildren)
					if (!t.isNewestChild())
						if (t.isCompleted())
							continue;
				
				//nur aktuelle
				if (!t.isCurrentVersion)
					continue;
			
				var ts = t.getCurrentState();
				if (!ts)
					continue;
				
				//Archivierung etc.
				if (groupId >= 0){
					if (ts.groupId !== groupId)
						continue;
				}
				
				//filter others
				if (userId >= 0) {
					
					//auch via Creator?
					var mayPush = false;
					if (getOwnersTasksToo) {
						if (t.ownerId === userId)
							mayPush = true;
						if (ts.responsibleId === userId)
							mayPush = true;
					}
					
					//normaler Check über Assignment
					if (ts.isAssignedToUser(userId))
						mayPush = true;
					if (!mayPush)
						continue;
				}
				
				//falls nicht exceptTaskId
				if (t.taskId === exceptTaskId)
					continue;
				
				//Status?
				if (!includeCompleted) {
					if (t.isCompleted()) {
						continue;
					}
				}
				
				a.push(t);
			}
			
			return a;
		},
		
		//----------------------------------------------
		
		//Aufträge eines Intervall-Stranges holen
		getTasksByIntervalId: function (intervalId) {
			
			var a = [];
			//alle Tasks durchlaufen
			for(var i = 0; i < model.tasks.length; i++) {
				var t = model.tasks[i];
				
				//passt die intervalID?
				if (t.intervalId !== intervalId)
					continue;
				
				//keine gelöschten!
				if (t.getMasterStatus() === constants.TASK_STATUS_DELETED)
					continue;
				
				//nur aktuelle
				if (!t.isCurrentVersion)
					continue;
				
				a.push(t);
			}
			
			a.sort(function (a2, b) {
				var at = a2.id;
				var bt = b.id;
				//umgekehrt (DESC)
				return -1 * (((at < bt) ? -1 : ((at > bt) ? 1 : 0)));
			});
			
			return a;
		},
		
		//----------------------------------------------
		
		//u.a. Versionierung aktualisieren
		updateTasks: function () {

			var removedProtocols = [];

			for(var i = 0; i < model.tasks.length; i++) {

				var result = model.tasks[i].update();
				for (var j=0; j<result.removedProtocols.length; j++)
					removedProtocols.push(result.removedProtocols[j]);
			}

			//Info anwenden? Nicht als Debug versenden, Issue dazu folgt
			/*for (i=0; i<removedProtocols.length; i++){
				var r = removedProtocols[i];
				console.log("removed: " + r.protocol.taskId + " / " + r.protocol.id + " / " + r.reason);
			}*/
		},
		
		//----------------------------------------------
		
		//Datensichten harmonisieren
		mergeTasks: function (oOld, oNew) {
			
			var i,
				oldLength;
			if (oOld.states) {
				if (!oNew.states)
					oNew.states = [];
				//alte ins neue übernehmen
				oldLength = oOld.states.length;
				for(i = 0; i < oldLength; i++)
					pg.replaceOrPushObj(oNew.states, oOld.states[i]);
			}
			if (oOld.protocols) {
				if (!oNew.protocols)
					oNew.protocols = [];
				//alte ins neue übernehmen
				oldLength = oOld.protocols.length;
				for(i = 0; i < oldLength; i++) {
					oNew.mergeProtocol(oOld.protocols[i]);
					//pg.replaceOrPushObj(oNew.protocols, oOld.protocols[i]);
				}
			}
			if (oOld.history) {
				if (!oNew.history)
					oNew.history = [];
				//alte ins neue übernehmen
				oldLength = oOld.history.length;
				for(i = 0; i < oldLength; i++)
					pg.replaceOrPushObj(oNew.history, oOld.history[i]);
			}
			if (oOld.comments) {
				if (!oNew.comments)
					oNew.comments = [];
				//alte ins neue übernehmen
				oldLength = oOld.comments.length;
				for(i = 0; i < oldLength; i++)
					pg.replaceOrPushObj(oNew.comments, oOld.comments[i]);
			}
			
			return oNew;
		},
		
		//----------------------------------------------
		
		//welche Aufträge beinhalten eine bestimmte Location?
		getActiveTasksContainingLocation: function (location) {
			var a = [];
			for(var i = 0; i < model.tasks.length; i++) {
				var t = model.tasks[i];

				if (!t.isCurrentVersion)
					continue;
				if (t.getMasterStatus() !== constants.TASK_STATUS_ACTIVE)
					continue;
				if (t.isCompleted())
					continue;
				
				for(var j = 0; j < t.locationList.length; j++) {
					if (t.locationList[j] === location.locationId) {
						a.push(t);
						break;
					}
				}
			}
			return a;
		},
		
		//----------------------------------------------

		//alle aktiven Aufträge holen
		getActiveTasks: function () {
			var a = [];
			for(var i = 0; i < model.tasks.length; i++) {
				var t = model.tasks[i];
			
				if (!t.isCurrentVersion)
					continue;
				if (t.getMasterStatus() !== constants.TASK_STATUS_ACTIVE)
					continue;
				if (t.isCompleted())
					continue;
				
				a.push(t);
			}
			return a;
		},
		
		//hole Aufträge, die (u.a.) diesem User zugeordnet sind (indirekt via SingleUserGroup oder als Gruppe, in der er enthalten ist)
		getUserTasks: function (userId) {
			
			return Task.getTasks({
				userId: userId,
				includeCompleted: false
			});
		},

		//hole Aufträge, die bisher dem User/Usergroup zugeordnet waren und die ohne ihn/sie nicht mehr auführbar sind
		getExclusiveUserGroupTasks: function(userGroupId) {

			//(u.a.) zugeordnete Aufträge
			var ugTasks = Task.getUserGroupTasks(userGroupId);
			//filtern auf diejenigen, die nur vom dieser Gruppe ausgeführt werden (können)
			return _.filter(ugTasks, function (t) {
				var ts = t.getCurrentState();
				var ugs = ts.getUserGroups();
				//gibt es sonst noch jemanden?
				var hasOther = _.some(ugs, function (taskUg) {

					if (taskUg.id === userGroupId)
						return false;
					//ist jemand anderes, aber darf dieser andere den Auftrag bearbeiten?
					var users = taskUg.getUsers();
					var ugCanExecute = _.some(users, function(taskUser){
						return (taskUser.canExecuteTask(t));
					});
					if (!ugCanExecute)
						return false;
					return true;
				});

				return !hasOther;
			});
		},
		//und das Gegenstück dazu: Aufträge, die auch ohne UserGroup ausführbar sind
		getNonExclusiveUserGroupTasks: function(userGroupId) {

			var exclusiveTasks = Task.getExclusiveUserGroupTasks(userGroupId);

			//(u.a.) zugeordnete Aufträge
			var ugTasks = Task.getUserGroupTasks(userGroupId);
			return _.filter(ugTasks, function(t){
				//ist in exklusiven dabei? -> dann nicht
				return (!(_.some(exclusiveTasks, function(tEx){
					return tEx.taskId === t.taskId;
				})));
			});

		},
		
		//dto, für eine UserGroup
		getUserGroupTasks: function (userGroupId) {
			
			var a = [];
			var tasks = Task.getActiveTasks();
			for(var i = 0; i < tasks.length; i++) {
				
				var t = tasks[i];

				if (t.isCompleted())
					continue;
				
				var ts = t.getCurrentState();
				if (ts.isAssignedToUserGroup(userGroupId))
					a.push(t);
			}
			return a;
		},
		
		//hole Aufträge, für die der User als Verantwortlicher eingetragen ist
		getResponsibleTasks: function (userId) {
			var a = [];
			var tasks = Task.getActiveTasks();
			for(var i = 0; i < tasks.length; i++) {
				
				var t = tasks[i];
				
				if (t.isCompleted())
					continue;
				
				var ts = t.getCurrentState();
				if (ts.responsibleId === userId)
					a.push(t);
			}
			return a;
		},
		
		//-------------------------------------------------
		//komplexe Intervalle
		
		//Intervall-Datum aus Kurzform bilden
		getIntervalDate: function (dateDue, intervalType, factor, weekdays, goForwardInTime) {
			
			if ((intervalType === null) || (intervalType === ""))
				return null;
			if (dateDue === null)
				return null;

			var trgDate = null;

			//Special: W und Weekdays und Forward
			var hasWeekDays = ((weekdays !== null) && (weekdays.length > 0));
			var isSpecialCase = ((intervalType === "W") && (goForwardInTime) && (hasWeekDays));

			//der normale Weg...
			if (!isSpecialCase) {
				switch (intervalType) {
					case "D":
						if (goForwardInTime)
							trgDate = new Date(new Date(dateDue).setDate(dateDue.getDate() + 1 * factor));
						else
							trgDate = new Date(new Date(dateDue).setDate(dateDue.getDate() - 1 * factor));
						break;
					case "W":
						if (goForwardInTime)
							trgDate = new Date(new Date(dateDue).setDate(dateDue.getDate() + 7 * factor));
						else
							trgDate = new Date(new Date(dateDue).setDate(dateDue.getMonth() - 7 * factor));
						break;
					case "M":
						if (goForwardInTime)
							trgDate = new Date(new Date(dateDue).setMonth(dateDue.getMonth() + 1 * factor));
						else
							trgDate = new Date(new Date(dateDue).setMonth(dateDue.getMonth() - 1 * factor));
						break;
					case "Q":
						if (goForwardInTime)
							trgDate = new Date(new Date(dateDue).setMonth(dateDue.getMonth() + 3 * factor));
						else
							trgDate = new Date(new Date(dateDue).setMonth(dateDue.getMonth() - 3 * factor));
						break;
					case "Y":
						if (goForwardInTime)
							trgDate = new Date(new Date(dateDue).setFullYear(dateDue.getFullYear() + 1 * factor));
						else
							trgDate = new Date(new Date(dateDue).setFullYear(dateDue.getFullYear() - 1 * factor));
						break;
				}
			}
			else {

				//dateDue ist Startdatum

				//Möglichkeit 1: falls dueDate nicht in Zukunft, noch in dieser Woche
				var today = new Date();
				var additionalDays = -1;
				if (today.getTime() >= dateDue.getTime()) {

					var weekDayToday = Task.getWeekDayDe(today);

					for (var i = weekDayToday; i < 7; i++) {
						//gibt es einen Match?
						for (var j = 0; j < weekdays.length; j++) {
							var wd = parseInt(weekdays[j]);
							if (wd === i) {
								additionalDays = (i - weekDayToday);
								break;
							}
						}
						if (additionalDays >= 0) {
							break;
						}
					}

					if (additionalDays >= 0){
						trgDate = new Date(new Date(today).setDate(today.getDate() + additionalDays + 7 * (factor-1)));
					}
					else {

						//Möglichkeit 2: erst nach n Wochen

						//Montag der Start-Woche
						var weekDayDueDate = Task.getWeekDayDe(dateDue);
						var monday = new Date(new Date(dateDue).setDate(dateDue.getDate() - weekDayDueDate));

						//Intervall ergänzen
						trgDate = new Date(new Date(monday).setDate(monday.getDate() + 7 * factor));

						//nun den ersten passenden Wochentag
						additionalDays = parseInt(weekdays[0]);
						trgDate = new Date(new Date(trgDate).setDate(trgDate.getDate() + additionalDays));
					}

				}
				else{
					//alles in der Zukunft
					trgDate = Task.getMatchingDueDate(dateDue, model.curIntervalValues);
				}
			}

			return trgDate;
		},

		getWeekDayDe: function(d){
			if (!d)
				return -1;
			var weekDay = d.getDay()-1;
			//locale DE
			if (weekDay < 0)
				weekDay = 6;
			return weekDay;
		},

		createIntervalName: function (intervalValues) {

			if (!intervalValues)
				return "Ohne";

			if(!intervalValues.intervalType)
				return "Ohne";

			var result = "";
			switch (intervalValues.intervalType) {
				case "D":
					if (intervalValues.factor === 1)
						result = "Jeden Tag";
					else
						result = "Alle " + intervalValues.factor + " Tage";
					break;
				case "W":
					if (intervalValues.factor === 1)
						result = "Jede Woche";
					else
						result = "Alle " + intervalValues.factor + " Wochen";
					break;
				case "M":
					if (intervalValues.factor === 1)
						result = "Jeden Monat";
					else
						result = "Alle " + intervalValues.factor + " Monate";
					break;
				case "Q":
					if (intervalValues.factor === 1)
						result = "Jedes Quartal";
					else
						result = "Alle " + intervalValues.factor + " Quartale";
					break;
				case "Y":
					if (intervalValues.factor === 1)
						result = "Jedes Jahr";
					else
						result = "Alle " + intervalValues.factor + " Jahre";
					break;
			}
			if (intervalValues.weekdays){
				result += " am ";
				var weekdayCount = 0;
				for (var i=0; i<7; i++){
					var hasWeekday = (intervalValues.weekdays.indexOf(""+i) >= 0);
					if (hasWeekday){
						if (weekdayCount > 0)
							result += ", ";
						result += Task.getWeekdayName(i);
						weekdayCount++;
					}
				}
			}

			return result;
		},

		getWeekdayName: function(index){
			switch (index){
				case 0: return "Montag";
				case 1: return "Dienstag";
				case 2: return "Mittwoch";
				case 3: return "Donnerstag";
				case 4: return "Freitag";
				case 5: return "Samstag";
				case 6: return "Sonntag";
			}
			return "";
		},
		
		getIntervalValues: function (intervalType) {

			//Intervall-Format (in DB / Transfer-Objekt)
			//A1-xyz
			//A: Intervall-Typ (Y Q M W D = Jahr,Quartal, ...)
			//1: Faktor (also "alle n")
			//xyz: Wochentage (beginnend mit Montag=0, Dienstag=1, ...)
			//Beispiel: W3-12 (alle drei Wochen, je am Di u Mi)

			if (!intervalType || intervalType==='null')
				intervalType = "W2";

			//custom?
			var factor = 1;
			var weekdays = "";
			var pos = intervalType.indexOf("-");
			if (pos > 0){
				weekdays = intervalType.substring(pos+1);
				intervalType = intervalType.substr(0, pos);
			}
			if (intervalType.length > 1) {
				factor = parseInt(intervalType.substring(1, 2));
				intervalType = intervalType.substring(0, 1);
			}
			
			return {
				intervalType: intervalType,
				factor: factor,
				weekdays: weekdays
			};
		},

		//nächstpassendes DueDate finden
		getMatchingDueDate: function(dueDate, iv){

			//var iv = Task.getIntervalValues(intervalType);
			if (dueDate && (iv.intervalType==="W") && iv.weekdays){

				var dd = dueDate;
				var weekDay = Task.getWeekDayDe(dd);

				//passt das? Tag für Tag schauen
				while (iv.weekdays.indexOf(weekDay) < 0){
					dd = new Date(new Date(dd).setDate(dd.getDate() + 1));
					weekDay++;
					if (weekDay > 6)
						weekDay = 0;
				}

				//Wochenfaktor berücksichtigen
				dd = new Date(new Date(dd).setDate(dd.getDate() + 7*iv.factor));

				dueDate = dd;
			}
			return dueDate;
		},

		getIntervalStatusName: function(intervalStatus){
			switch (intervalStatus){
				case constants.STATUS_OBJECT_ACTIVE:
					return "aktiv";
				case constants.STATUS_OBJECT_INACTIVE:
					return "deaktiviert";
			}
			return "";
		},
		
		//-------------------------------------------------
		
		//Namen für Aktivität finden
		getTaskHistoryDescription: function (h, hideComment) {

			var s = "";
			var skipComment = false;

			switch (h.type) {
				case constants.HISTORY_CREATED:
					s = "Der Auftrag wurde erstellt.";
					break;
				case constants.HISTORY_COMPLETED:
					s = "Der Auftrag wurde erledigt.";
					break;
				case constants.HISTORY_CHANGED:
					s = "Der Auftrag wurde ge&auml;ndert:<br/>";
					s += "<ul>";

					var changeCount = 0;
					h.getChanges().forEach(function(o){

						//Filtern bestimmter Infos
						var cont = false;
						switch (o.name){
							case "Bearbeiter":
								cont = true;
								break;
						}
						if (cont)
							return;

						changeCount++;

						if (o.isArray){
							s += "<li>" + o.name + ": ";
							var items = [];
							items = items.concat(o.oldVal.map(function(u){
								return "<i class=\"fa fa-minus-circle font-red\" aria-hidden=\"true\"></i> " + u;
							}));
							items = items.concat(o.newVal.map(function(u){
								return "<i class=\"fa fa-plus-circle font-green-jungle\" aria-hidden=\"true\"></i> " + u;
							}));
							s += items.join(", ");
							s += "</li>";
						}
						else {

							if (o.isText && o.oldVal && o.newVal){

								//noinspection JSUnresolvedFunction
								var diff = JsDiff.diffChars(o.oldVal, o.newVal);

								s += "<li>" + o.name + ": ";
								diff.forEach(function(part){
									var color = part.added ? 'font-green-jungle' :
												part.removed ? 'font-red' : '';
									s += "<span class='" + color + "'>" + pg.nl2br(part.value) + "</span>";
								});
								s += "</li>";

							}
							else {

								if (!o.oldVal) {
									s += "<li>" + o.name + ": <i class=\"fa fa-plus-circle font-green-jungle\" aria-hidden=\"true\"></i> " + pg.nl2br(o.newVal) + "</li>";
								}
								else if (!o.newVal) {
									s += "<li>" + o.name + ": <i class=\"fa fa-minus-circle font-red\" aria-hidden=\"true\"></i> " + pg.nl2br(o.oldVal) + "</li>";
								}
								else {

									//Sonderfall: Objekte (UPK-1098)
									if ((o.name === "Objekte") && (o.newVal && (o.newVal.length === 0))){
										//oldVal hält alle Namen, manche sind ergänzt
										s += "<li>" + o.name + ": ";
										for (var i=0; i<o.oldVal.length; i++){
											var locEntry = o.oldVal[i];
											if (i > 0)
												s += ", ";
											if (locEntry.indexOf("(-)") === 0){
												s += "<i class=\"fa fa-minus-circle font-red\" aria-hidden=\"true\"></i> <strike>" + locEntry.substr(3) + "</strike>";
											}
											else{
												if (locEntry.indexOf("(+)") === 0){
													s += "<i class=\"fa fa-plus-circle font-green-jungle\" aria-hidden=\"true\"></i> " + locEntry.substr(3);
												}
												else{
													s += locEntry;
												}
											}
										}
										s += "</li>";
									}
									else {
										s += "<li>" + o.name + ": " + o.oldVal + " → " + o.newVal + "</li>";
									}
								}
							}
						}
					});
					s += "</ul>";

					if (changeCount === 0)
						s = "";

					break;
				case constants.HISTORY_ASSIGNED:
					s = "Der Auftrag wurde <b>" + UserGroup.getUserGroupNames(h.string1) + "</b> zur Bearbeitung zugewiesen.";
					break;
				case constants.HISTORY_ROUTED:
					s = "Der Auftrag wurde an <b>" + UserGroup.getUserGroupNames(h.string1) + "</b> zur Bearbeitung weitergeleitet.";

					if (!skipComment && !hideComment)
						if (h.string2)
							s += "<br/><i>" + pg.nl2br(h.string2) + "</i>";

					break;
				case constants.HISTORY_RESPONSIBILITY:
					s = "Die Verantwortung f&uuml;r den Auftrag wurde <b>" + User.getUserName(h.int1) + "</b> &uuml;bertragen.";
					break;
				case constants.HISTORY_PROTOCOL_SUBMITTED:
					var formName = ProtocolForm.getProtocolForm(h.int2).name;
					if (h.int1 > 0) {
						var locName = Location.getLocation(h.int1, undefined, true, true, true).getName(true);
						s = "Das Formular <b>" + formName + "</b> für Objekt <b>" + locName + "</b> wurde ausgefüllt.";
					}
					else
						s = "Das Formular <b>" + formName + "</b> wurde ausgefüllt.";
					break;
				case constants.HISTORY_LABEL_CHANGED:
					s = "Der Status des Auftrags wurde ge&auml;ndert: <b>" + Task.getStatusName(h.int1) + "</b>";

					if (!skipComment && !hideComment)
						if (h.string1)
							s += "<br/><i>" + pg.nl2br(h.string1) + "</i>";

					break;
				case constants.HISTORY_COMMENT:
					s = "<i>" + pg.nl2br(h.string1) + "</i>";
					skipComment = true;
					break;
				case constants.HISTORY_GROUPING:
					switch (h.int1){
						case constants.GROUP_ARCHIVE:
							s = "Der Auftrag wurde archiviert.";
							break;
						default:
							s = "Der Auftrag wurde wiederhergestellt.";
							break;
					}
					break;
			}
			
			return {
				text: s,
				skipComment: skipComment
			};
		},
		
		//-------------------------------------------------
		
		getIncident: function (incidentId) {
			
			for(var i = 0; i < model.tasks.length; i++) {
				var t = model.tasks[i];
				var inc = t.getIncident(incidentId);
				if (inc)
					return inc;
			}
			
			return null;
		},
		getSignal: function (signalId) {
			for(var i = 0; i < model.tasks.length; i++) {
				var t = model.tasks[i];
				var sig = t.getSignal(signalId);
				if (sig)
					return sig;
			}
			
			return null;
		},
		/*getProtocol: function (userId, formId, locationId) {
			for(var i = 0; i < model.tasks.length; i++) {
				var t = model.tasks[i];
				var prot = t.getProtocol(userId, formId, locationId);
				if (prot)
					return prot;
			}
			
			return null;
		},*/
		
		//-------------------------------------------------
		
		createTask: function () {
			return Object.create(Task.taskPrototype);
		}
		
	};
	
	window.Task = Task;
}());
