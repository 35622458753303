(function () {
	
	//----------------------------------------------------------------
	//Protokoll
	//----------------------------------------------------------------
	
	/*global
	constants:true,
	Incident:true,
	Attachment:true,
	Signal:true,
	Task:true,
	UserGroup:true,
	TaskType:true,
	authManager:true,
	UserRole:true,
	ProtocolDataItem*/
	
	'use strict';
	
	var Protocol = {
		
		protocolPrototype: {
			
			fromObj: function (t) {
				this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);
				this.submittedOn = t.submittedOn ? pg.parseDate(t.submittedOn) : null;

				this.version = parseInt(t.version, 10);
				this.userId = parseInt(t.userId, 10);
				this.taskId = parseInt(t.taskId, 10);
				this.formId = parseInt(t.formId, 10);
				this.locationId = parseInt(t.locationId, 10);
				this.status = parseInt(t.status, 10);

				var i;

				this.protocolData = [];
				if (t.protocolData) {
					if ($.isArray(t.protocolData)) {
						for (i = 0; i < t.protocolData.length; i++) {
							var pdi = ProtocolDataItem.createProtocolDataItem().fromObj(t.protocolData[i]);
							this.protocolData.push(pdi);
						}
					}
					else{
						this.protocolData = t.protocolData;
					}
				}

				this.attachments = t.attachments ? t.attachments : "";
				
				this.incidents = [];
				if (t.incidents)
					for (i = 0; i < t.incidents.length; i++) {
						var op = Incident.createIncidentRaw().fromObj(t.incidents[i]);
						this.addIncident(op);
					}
				
				this.isTransferredCompletely = parseInt(t.isTransferredCompletely || 0);
				
				
				return this;
			},
			
			serialize: function () {
				
				var o = {};

				if ($.type(this.changedOn) !== "date")
					this.changedOn = pg.parseDate(this.changedOn);

				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;
				o.submittedOn = this.submittedOn ? pg.buildDate(this.submittedOn) : null;

				o.userId = this.userId;
				o.taskId = this.taskId;
				o.formId = this.formId;
				o.locationId = this.locationId;
				o.status = this.status;

				//o.protocolData = this.protocolData;
				o.protocolData = [];
				for(var i = 0; i < this.protocolData.length; i++)
					o.protocolData.push(this.protocolData[i].serialize());

				o.attachments = this.attachments;
				o.isTransferredCompletely = this.isTransferredCompletely;
				
				o.incidents = [];
				for (i = 0; i < this.incidents.length; i++)
					o.incidents.push(this.incidents[i].serialize());
				
				return o;
			},

			serializeCompact: function(){
				var o = {};

				o.id = this.id;
				o.submittedOn = this.submittedOn;

				o.taskId = this.taskId;
				o.formId = this.formId;
				o.locationId = this.locationId;
				o.status = this.status;

				o.protocolData = [];
				for(var i = 0; i < this.protocolData.length; i++)
					o.protocolData.push(this.protocolData[i].serializeCompact());

				o.attachments = this.attachments;
				o.isTransferredCompletely = this.isTransferredCompletely;

				return o;
			},
			
			//----------------------------------------------------------------
			
			isCompleted: function () {
				return (this.status === constants.STATUS_COMPLETED);
			},

			//----------------------------------------------------------------

			//inhaltliche Prüfung, ob identisch
			matchesProtocol: function(p){
				if (this.taskId !== parseInt(p.taskId))
					return false;
				if (this.formId !== parseInt(p.formId))
					return false;
				if (this.locationId !== parseInt(p.locationId))
					return false;
				if (this.protocolData.length !== p.protocolData.length)
					return false;
				return true;
			},

			//----------------------------------------------------------------
			//Anhänge
			
			//deserialisieren
			updateAttachments: function () {
				this.attObj = Attachment.getAttachments(constants.ENTITY_TYPE_PROTOCOL, this.id);
				if (this.attObj.length === 0 && this.attachments){
					//legacy
					this.attObj = Attachment.deserializeAttachments(constants.ENTITY_TYPE_PROTOCOL, this.taskId, this.attachments);
				}
				return this.attObj;
			},
			
			//alle holen
			getAttachments: function () {
				if ((this.attachments) && (!this.attObj))
					this.updateAttachments();
				return this.attObj;
			},
			
			//einzelnen Anhang holen
			getAttachment: function (attId) {
				for (var i = 0; i < this.attObj.length; i++)
					if (this.attObj[i].id === attId)
						return this.attObj[i];
				return null;
			},

			getAttachmentByIndex: function (index) {
				if ((index >= 0) && (index <= this.attObj.length - 1))
					return this.attObj[index];
				return null;
			},

			//noch nicht abgeschlossene Anhänge vorhanden? (weil offline)
			hasPendingAttachmentUploads: function () {
				var att = this.getAttachments();
				for (var i = 0; i < att.length; i++) {
					if (att[i].pendingUploadFile)
						return true;
				}
				return false;
			},
			
			//----------------------------------------------------------------
			//Vorkommnisse
			
			//Vorkommnis ergänzen
			addIncident: function (o) {
				
				if (this.incidents === undefined)
					this.incidents = [];
				
				var a = [];
				for (var i = 0; i < this.incidents.length; i++) {
					if (o.id > 0)
						if (this.incidents[i].id === o.id)
							continue;
					a.push($.extend({}, this.incidents[i]));
				}
				a.push(o);
				this.incidents = a;
				
				return o;
			},
			
			//Anzahl liefern
			getIncidentCount: function () {
				var incCount = this.incidents.length;
				var defectCount = 0;
				var notificationCount = 0;
				
				//Vorkommnisse durchlaufen (aber keine alten Werte berücksichtigen!)
				for (var k = 0; k < this.incidents.length; k++) {
					var inc = this.incidents[k];
					var signals = inc.signals;

					for (var j = 0; j < signals.length; j++) {
						var sig = signals[j];

						if (!sig.isNewestSignal(signals, j))
							continue;

						switch (sig.type) {
							case Signal.SIGNAL_DEFECT:
								if (sig.value === 1)
									defectCount++;
								break;
							case Signal.SIGNAL_NOTIFY:
								if (sig.value === 1)
									notificationCount++;
								break;
						}
					}
				}
				
				return {
					incidentCount: incCount,
					defectCount: Math.min(incCount, defectCount),
					notificationCount: notificationCount
				};
			},
			
			//überhaupt welche vorhanden?
			hasIncidents: function () {
				return (this.incidents.length > 0);
			},
			
			//lesbare Zusammenfassung liefern
			getIncidentSummary: function () {
				
				//var count = this.getIncidentCount();
				var count = this.incidents.length;
				if (count === 0)
					return "";
				if (count === 1)
					return "1 Vorkommnis";
				return count + " Vorkommnisse";
			},
			getIncidentByField: function (fieldId) {
				for (var k = 0; k < this.incidents.length; k++) {
					var inc = this.incidents[k];
					if (inc.fieldId === fieldId)
						return inc;
				}
				return null;
			},
			getIncident: function (id) {
				id = parseInt(id);
				for (var k = 0; k < this.incidents.length; k++) {
					var inc = this.incidents[k];
					if (inc.id === id)
						return inc;
				}
				return null;
			},
			getSignal: function (id) {
				id = parseInt(id);
				for (var k = 0; k < this.incidents.length; k++) {
					var inc = this.incidents[k];
					
					for (var i = 0; i < inc.signals.length; i++) {
						var sig = inc.signals[i];
						if (sig.id === id)
							return sig;
					}
				}
				return null;
			},

			//-------------------------------------------------------------------

			//Filter anwenden
			matchesFilter: function (filterObj) {

				/*jshint -W089 */
				for(var field in filterObj) {

					var val = filterObj[field],
						isValid,
						i,
						j,
						arr,
						valInt;

					if ((val === undefined) || (val === null))
						continue;

					switch (field) {
						case "taskId":
							var tId = "" + this.taskId;
							if (tId.indexOf(val) < 0)
								return false;
							break;

						case "incidentType":
							valInt = parseInt(val);
							switch (valInt) {
								case -1:
									continue;
								default:
									if (!this.hasIncidentType(valInt))
										return false;
									break;
							}
							break;
					}
				}

				return true;
			},

			//----------------------------------------

			hasIncidentType: function(type){
				return (this.getIncidentTypeCount(type) > 0);
			},

			//mind. ein bestimmtes Vorkommnis im Auftrag enthalten?
			getIncidentTypeCount: function(type){

				var count = 0;
				var incidents = this.incidents;
				//var combinedValue = (incidents.length > 0);
				var hasMatchingIncType = false;
				for (var i=0; i<incidents.length; i++){

					var inc = incidents[i];

					switch (type){

						case Incident.INCIDENT_TYPE_ANY:
							count++;
							break;

						case Incident.INCIDENT_TYPE_DEFECT:
							if (inc.type === Incident.INCIDENT_DEFECT)
								count++;
							break;

						case Incident.INCIDENT_TYPE_OBSERVATION:
							if (inc.type === Incident.INCIDENT_OBSERVATION)
								count++;
							break;

						case Incident.INCIDENT_TYPE_UNFIXED_ANY:
							if (inc.hasSignal(Signal.SIGNAL_DEFECT, Signal.VALUE_NOT_OK))
								count++;
							break;

						case Incident.INCIDENT_TYPE_UNFIXED_DEFECT:
							if (inc.type === Incident.INCIDENT_DEFECT){
								if (inc.hasSignal(Signal.SIGNAL_DEFECT, Signal.VALUE_NOT_OK))
									count++;
							}
							break;

						case Incident.INCIDENT_TYPE_UNFIXED_OBSERVATION:
							if (inc.type === Incident.INCIDENT_OBSERVATION){
								if (inc.hasSignal(Signal.SIGNAL_DEFECT, Signal.VALUE_NOT_OK))
									count++;
							}
							break;

						//--------------------
						//ALLE müssen gefixt sein
						case Incident.INCIDENT_TYPE_FIXED_ANY:
							//hasMatchingIncType = true;
							//if (inc.hasOnlySignalsOfType(Signal.SIGNAL_DEFECT, Signal.VALUE_OK_DEFAULT))
							if (!inc.hasSignal(Signal.SIGNAL_DEFECT, Signal.VALUE_NOT_OK))
								//combinedValue = combinedValue && true;
								count++;
							/*else
								return 0;*/
							break;

						//ALLE müssen gefixt sein
						case Incident.INCIDENT_TYPE_FIXED_DEFECT:
							if (inc.type === Incident.INCIDENT_DEFECT){
								//hasMatchingIncType = true;
								//if (inc.hasOnlySignalsOfType(Signal.SIGNAL_DEFECT, Signal.VALUE_OK_DEFAULT))
								if (!inc.hasSignal(Signal.SIGNAL_DEFECT, Signal.VALUE_NOT_OK))
									//combinedValue = combinedValue && true;
									count++;
								/*else
									return 0;*/
							}
							break;

						//ALLE müssen gefixt sein
						case Incident.INCIDENT_TYPE_FIXED_OBSERVATION:
							if (inc.type === Incident.INCIDENT_OBSERVATION){
								//hasMatchingIncType = true;
								//if (inc.hasOnlySignalsOfType(Signal.SIGNAL_DEFECT, Signal.VALUE_OK_DEFAULT))
								if (!inc.hasSignal(Signal.SIGNAL_DEFECT, Signal.VALUE_NOT_OK))
									//combinedValue = combinedValue && true;
									count++;
								/*else
									return 0;*/
							}
							break;
					}
				}

				//Auswertung
				/*switch (type){
					case Incident.INCIDENT_TYPE_FIXED_ANY:
					case Incident.INCIDENT_TYPE_FIXED_DEFECT:
					case Incident.INCIDENT_TYPE_FIXED_OBSERVATION:
						if (hasMatchingIncType)
							return combinedValue ? 1 : 0;
						break;
					default:
						return count;
				}

				return 0;*/
				return count;
			}
		},
		
		//------------------------------------------------------------------
		
		//Protokoll finden (in welchem Auftrag auch immer)
		getProtocol: function(protocolId){
			
			protocolId = parseInt(protocolId);
			
			var tasks = Task.getTasks({
				allowOlderChildren: true,
				allowInvisibleTasks: true
			});
			for (var i = 0; i < tasks.length; i++) {
				var t = tasks[i];
				
				if (t.isCurrentVersion) {
					for(var j = 0; j < t.protocols.length; j++) {
						var p = t.protocols[j];
						if (p.id === protocolId)
							return p;
					}
				}
			}
			
			return null;
		},
		
		//------------------------------------------------------------------
		//zugeordnete Protokolle holen
		
		getUncompletedProtocolsByLocation: function (locationId, exceptTaskId, showHidden) {
			
			var protocols = [];
			
			var tasks = Task.getTasks({
				allowOlderChildren: true,
				exceptTaskId: exceptTaskId,
				allowInvisibleTasks: showHidden
			});
			
			for (var i = 0; i < tasks.length; i++) {
				var t = tasks[i];
				
				if (!t)
					continue;
				
				//diesem User zugeordnet?
				if (!t.getCurrentState().isAssignedToUser(model.curUserId))
					continue;
				
				//alle Locations prüfen
				for (var j = 0; j < t.locationObjects.length; j++) {
					var l = t.locationObjects[j];
					
					//falls locationId
					if (l.locationId === locationId) {
						
						//alle zugehörigen Protokolle
						var tt = TaskType.getTaskType(t.taskTypeId);
						for (var k = 0; k < tt.formIds.length; k++) {
							var formId = parseInt(tt.formIds[k], 10);
							
							//ausgefüllt?
							var hasProtocol = false;
							var p;
							for (var m = 0; m < t.protocols.length; m++) {
								p = t.protocols[m];
								//zwischengespeichert?
								if (p.id < 0)
									continue;
								if ((p.locationId === locationId) && (p.formId === formId)) {
									if (p.isCurrentVersion) {
										
										//vollständig?
										if (p.status === constants.STATUS_PROGRESS)
											protocols.push(p);
										
										//aber kein neues erzeugen!
										hasProtocol = true;
										break;
									}
								}
							}
							//sonst merken
							if (!hasProtocol) {
								p = Protocol.createProtocol();
								p.isVirtual = true;
								p.taskId = t.taskId;
								p.dueDate = t.dueDate;
								p.status = constants.STATUS_NEW;
								p.locationId = locationId;
								p.formId = formId;
								protocols.push(p);
							}
						}
					}
				}
			}
			
			return protocols;
		},
		getCompletedProtocolsByLocation: function (locationId, checkPermissions, showHidden) {

			//nur anzeigen was der Benutzer sehen darf?
			if (checkPermissions === undefined)
				checkPermissions = true;

			var protocols = [];

			var tasks = Task.getTasks({
				allowOlderChildren: true,
				allowInvisibleTasks: showHidden
			});
			for (var i = 0; i < tasks.length; i++) {
				var t = tasks[i];

				//alle Locations prüfen
				for (var j = 0; j < t.locationObjects.length; j++) {
					var l = t.locationObjects[j];
					
					//falls locationId
					if (l.locationId === locationId) {
						
						//--------------------------------------------------------------------------
						//Permissions?

						if (checkPermissions) {

							var mayShowProtocol = false;
							if (authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_LIST_ALL)) {
								mayShowProtocol = true;
							} else {
								if (authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_LIST_OWN)) {
									if (t.ownerId === model.curUserId) {
										mayShowProtocol = true;
									}
									var ts = t.getCurrentState();
									if (ts.responsibleId === model.curUserId) {
										mayShowProtocol = true;
									}
									if (ts.isAssignedToUser(model.curUserId))
										mayShowProtocol = true;
								} else {
									if (authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_LIST_BY_TASK_TYPE)) {
										if (model.curUser.hasTaskType(t.taskTypeId))
											mayShowProtocol = true;
									}
								}
							}
							if (!mayShowProtocol)
								continue;

						}

						//--------------------------------------------------------------------------
						
						//alle zugehörigen Protokolle
						var tt = TaskType.getTaskType(t.taskTypeId);
						for (var k = 0; k < tt.formIds.length; k++) {
							var formId = parseInt(tt.formIds[k], 10);
							
							//ausgefüllt?
							var p;
							for (var m = 0; m < t.protocols.length; m++) {
								p = t.protocols[m];
								//zwischengespeichert?
								if (p.id < 0)
									continue;
								if ((p.locationId === locationId) && (p.formId === formId))
									if (p.isCurrentVersion) {
										p.task = t;
										protocols.push(p);
										break;
									}
							}
						}
					}
				}
			}
			
			return protocols;
		},
		
		//----------------------------------------------------------
		
		//Datensichten harmonisieren
		mergeProtocols: function (oOld, oNew) {
			
			var i,
				oldLength;
			if (oOld.incidents) {
				if (!oNew.incidents)
					oNew.incidents = [];
				//alte ins neue übernehmen
				oldLength = oOld.incidents.length;
				for(i = 0; i < oldLength; i++)
					pg.replaceOrPushObj(oNew.incidents, oOld.incidents[i]);
			}
			if (oOld.protocolData) {
				if (!oNew.protocolData)
					oNew.protocolData = [];
				//alte ins neue übernehmen
				oldLength = oOld.protocolData.length;
				for(i = 0; i < oldLength; i++)
					pg.replaceOrPushObj(oNew.protocolData, oOld.protocolData[i]);
			}
			
			return oNew;
		},
		
		//----------------------------------------------------------
		
		createProtocol: function () {
			var p = Object.create(Protocol.protocolPrototype);
			p.id = -1;
			p.incidents = [];
			return p;
		}
		
	};
	
	window.Protocol = Protocol;
}());
