(function () {
	
	//-------------------------------------------------------------
	//Liste der Vorkommnisse verwalten
	//-------------------------------------------------------------
	
	/*global
	constants:true,
	viewManager:true,
	Incident:true,
	Signal:true,
	Location:true,
	LocationType:true,
	Task:true,
	authManager:true,
	UserRole:true,
	ProtocolForm:true,
	 dataManager:true
	*/
	
	'use strict';
	
	var incidentListManager = {
		
		//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
		checkModelChanged: function(data, oldModel){
			
			var that = incidentListManager;
			
			var i,
				j,
				k,
				t,
				ts,
				tNew,
				tt,
				tsOld,
				tsNew,
				hasMatch;

			//welche Daten prüfen?
			var matchTT = false;
			var matchOwner = false;
			if (!authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_ALL)) {
				if (authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_OWN)) {
					matchOwner = true;
				}
				else{
					if (authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_BY_TASK_TYPE)){
						matchTT = true;
					}
				}
			}
			
			if (data.incidents) {
				for(i = 0; i < data.incidents.length; i++) {
					
					tNew = Task.getTask(data.incidents[i].taskId);
					if (!tNew)
						continue;
					//würde dieses Objekt überhaupt gesehen?
					tt = -1;
					if (matchTT)
						tt = tNew.taskType;
					if (!tNew.canBeSeenByUser(matchOwner, tt, that.curGroupId))
						continue;
					
					hasMatch = false;
					
					var incId = parseInt(data.incidents[i].id);
					var oldInc = tNew.getIncident(incId);
					if (oldInc){
						hasMatch = true;
						tsOld = oldInc.changedOn.getTime();
						tsNew = pg.parseDate(data.incidents[i].changedOn).getTime();
						if (tsNew !== tsOld)
							return true;
					}
					
					//neues Objekt
					if (!hasMatch)
						return true;
				}
			}
			if (data.signals) {
				for(i = 0; i < data.signals.length; i++) {
					
					tNew = Task.getTask(data.signals[i].taskId);
					if (!tNew)
						continue;
					//würde dieses Objekt überhaupt gesehen?
					tt = -1;
					if (matchTT)
						tt = tNew.taskType;
					if (!tNew.canBeSeenByUser(matchOwner, tt, that.curGroupId))
						continue;
					
					hasMatch = false;
					
					//not my business
					if (!t.canBeSeenByUser(matchOwner, tt, that.curGroupId))
						continue;
					
					var sigId = parseInt(data.signals[i].id);
					var oldSig = tNew.getSignal(sigId);
					if (oldSig){
						hasMatch = true;
						tsOld = oldSig.changedOn.getTime();
						tsNew = pg.parseDate(data.signals[i].changedOn).getTime();
						if (tsNew !== tsOld)
							return true;
					}
					
					//neues Objekt
					if (!hasMatch)
						return true;
				}
			}
			
			return false;
			
		},
		
		//Incidents zeigen
		showIncidents: function(callbackComplete){

			var that = this;
			
			//falsch hier?
			if (!authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_ALL,
					UserRole.PERMISSION_INCIDENT_LIST_OWN,
					UserRole.PERMISSION_INCIDENT_LIST_BY_TASK_TYPE)){
				viewManager.execShowPage(constants.PAGE_OVERVIEW, null, null, null, callbackComplete);
				return false;
			}
			
			viewManager.resetStateDetails();
			
			$("#content").html(window.templates['incidentList.html']);
			setTimeout(function(){
				
				viewManager.setSelectedMenuItem('menuIncidents');
				
				//welche Daten anzeigen?
				if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_ALL)) {
					that.curIncidents = Incident.getIncidentsOf(Task.getTasks({
						allowOlderChildren: true,
						getOwnersTasksToo: true,
						allowInvisibleTasks: true
					}));
				}
				else{
					if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_OWN)) {
						
						var ownTasks = Task.getTasks({
							userId: model.curUserId,
							allowOlderChildren: true,
							getOwnersTasksToo: true,
							allowInvisibleTasks: true
						});
						that.curIncidents = Incident.getIncidentsOf(ownTasks);
					}
					else{
						if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_BY_TASK_TYPE)) {
							//alle holen, filtern!
							var allTasks = Task.getTasks({
								allowOlderChildren: true,
								getOwnersTasksToo: true,
								allowInvisibleTasks: true
							});
							var curTasks = _.filter(allTasks, function(o){
								return (model.curUser.hasTaskType(o.taskTypeId));
							});
							that.curIncidents = Incident.getIncidentsOf(curTasks);
						}
					}
				}
				
				//sortieren nach changedOn, ID DESC
				that.curIncidents.sort(function(a, b){
					var ta = a.changedOn.getTime();
					var tb = b.changedOn.getTime();
					if (tb === ta) {
						return b.id - a.id;
					}
					return tb - ta;
				});

				//Location prüfen
				var arr = [];
				for (var i = 0; i < that.curIncidents.length; i++) {

					var inc = that.curIncidents[i];
					if (inc.locationId > 0) {

						var l = Location.getLocation(inc.locationId, undefined, true, true, false);
						if (l) {
							//darf?
							if (l.isAbstract === 0) {
								if (model.curUser.hasLocationType(LocationType.LOCATION_TYPE_ALL) || model.curUser.hasLocationType(l.type)) {
									arr.push(inc);
								}
							}
							else{
								arr.push(inc);
							}
						}
					}
					else{
						arr.push(inc);
					}
				}
				that.curIncidents = arr;

				var restoreScrollPos = 0;
				if (model.curIncidentListView.preserveCurPage) {
					model.curIncidentListView.preserveCurPage = false;
					restoreScrollPos = model.curIncidentListView.scrollPos || 0;
				}
				else{
					model.curIncidentListView.curPage = 0;
					//model.curIncidentListView.entriesPerPage = 25;
				}
				
				$("#incidentPaginationBox .viewsPerPage").val(model.curIncidentListView.entriesPerPage);
				
				that.showIncidentItems(that.curIncidents, model.curIncidentListView);
				
				//--------------------------------------------------------
				//filter
				
				var s;

				//protocol name
				s = '<option value="-1">Alle</option>';
				model.protocolForms.sort(function (a, b) {
					return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
				});
				for(i = 0; i < model.protocolForms.length; i++) {
					var pf = model.protocolForms[i];
					s += "<option value='" + pf.formId + "'>" + pf.name + "</option>";
				}
				$("#filterProtocolFormId").html(s);
				
				//-------------
    
				//locationType
				s = '<option value="-1">Alle</option>';
				var lTypes = model.locationTypes;
				lTypes.sort(function (a, b) {
					return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
				});
				for (i=0; i<lTypes.length; i++){
					var lt = lTypes[i];

					//darf?
					if (model.curUser.hasLocationType(LocationType.LOCATION_TYPE_ALL) || model.curUser.hasLocationType(lt.id)) {
						s += '<option value=' + lt.id + '>' + lt.name + '</option>';
					}
				}
				$("#filterIncidentLocationType").html(s).select2();
				
				//Filter: Locations gemäß aktuellem Typ als Liste anbieten
				$("#filterIncidentLocationType").on("change", function(){
					//reset LocationIds
					model.curIncidentListView.filter.locationId = [-1];
					that.updateLocationFilter();
				});
				that.updateLocationFilter();
				
				//-------------
				
				$('.date-picker').datepicker({
					language: "de",
					format: 'dd.mm.yy',
					todayHighlight: true,
					autoclose: true
				});

				$("#incidentTable th.canSort").on("click", that.onIncidentListSort);
				
				//--------------------------------------------------------
				
				//handler
                $("#btnBackIncident").off("click").on("click", function(){
					window.history.back();
				});
				
				viewManager.updateButtons();
				
				//---------------------------
				
				setTimeout(function(){

					viewManager.setScrollTop(restoreScrollPos);
					
					//restore filter
					that.restoreIncidentListFilter(model.curIncidentListView);
					
					//----------------------------------------------------------
					//Top-Scroller
					$("#incidentTableScroller").scroll(function(){
						$("#incidentTableBox")
							.scrollLeft($("#incidentTableScroller").scrollLeft());
					});
					$("#incidentTableBox").scroll(function(){
						$("#incidentTableScroller")
							.scrollLeft($("#incidentTableBox").scrollLeft());
					});
					that.updateTopScroller();

					//-----------------------------------------------------------
					
					//warum auch immer mit Timeout?!
					setTimeout(function(){
						$("#incidentTable .form-filter").on("change", that.onIncidentListFilter);
					}, 500);

					if (callbackComplete)
						callbackComplete();

				}, 100);
			}, constants.TEMPLATE_DELAY);
		},

		//anhand der derzeitigen Location-Filter-Einstellung alle Location-Namen ermitteln
		//und per Select2 anbieten
		updateLocationFilter: function(){

			//Typ holen
			var locationType = parseInt($("#filterIncidentLocationType").val());

			//danach filtern
			var locations = Location.getLocations();
			var filteredLocations = [];
			var i;

			//unberechtigte Locations entfernen
			var arr = [];
			for(i=0; i<locations.length; i++) {
				var l = locations[i];
				if (l.isAbstract === 1)
					arr.push(l);
				else{
					if (model.curUser.hasLocationType(LocationType.LOCATION_TYPE_ALL) || model.curUser.hasLocationType(l.type)) {
						arr.push(l);
					}
				}
			}
			locations = arr;

			if (locationType === -1){
				//alle
				for(i=0; i<locations.length; i++) {
					if (locations[i].isAbstract === 0)
						filteredLocations.push(locations[i]);
				}
			}
			else {
				for(i=0; i<locations.length; i++) {
					if ((locations[i].type === locationType) && (locations[i].isAbstract === 0))
						filteredLocations.push(locations[i]);
				}
			}

			//sortieren
			filteredLocations.sort(function (a, b) {
				var na = a.getName(true);
				var nb = b.getName(true);
				return ((na < nb) ? -1 : ((na > nb) ? 1 : 0));
			});

			//zusammensetzen
			//var s = '<option value="-1">Alle</option>';
			var s = "";
			for (i=0; i<filteredLocations.length; i++){
				var loc = filteredLocations[i];
				s += '<option value=' + loc.locationId + '>' + loc.getName(true) + '</option>';
			}
			$("#filterIncidentLocation").html(s).select2();

			setTimeout(function(){
				$("#filterIncidentLocation.isFiltered").removeClass("isFiltered");
			}, 100);
		},

		//------------------------------------------------------------------------------------------------------
		
		//Filter wiederherstellen
		restoreIncidentListFilter: function(filterDef){
			
			//filter
			/*jshint -W089 */
			for (var key in filterDef.filter) {
				$("#incidentTable .filter [data-filter-id='" + key + "']").val(filterDef.filter[key]);
				try {
					$("#incidentTable .filter .select2[data-filter-id='" + key + "']").select2();
				}
				catch(e){}
			}
			
			//sorting
			switch (filterDef.sortDirection){
				case constants.SORTING_ASC:
					$("[data-sort-id='" + filterDef.sortField + "']").addClass("constants.SORTING_ASC");
					break;
				case constants.SORTING_DESC:
					$("[data-sort-id='" + filterDef.sortField + "']").addClass("constants.SORTING_DESC");
					break;
			}
			
			//Filter anzeigen
			this.showIncidentListFilters(filterDef);
		},
		
		//Filter wurde geändert
		onIncidentListFilter: function(){

			var that = incidentListManager;
			
			var fieldId = $(this).attr("data-filter-id");
			
			//merken
			model.curIncidentListView.filter[fieldId] = $(this).val();
			
			//anwenden
			model.curIncidentListView.curPage = 0;
			that.showIncidentItems(that.curIncidents, model.curIncidentListView);
		},
		
		//Filter-Anzeige aktualisieren
		showIncidentListFilters: function(filterDef){

			var filterActive = false;

			//zurücksetzen
            $("#btnRemoveIncidentListFilters").addClass("disabled");
            $("#incidentTableBox .filter .isFiltered").removeClass("isFiltered");

			//einzeln aktivieren
			//jshint -W089
			for (var key in filterDef.filter){

				var val = filterDef.filter[key];
				if (val){

					if ($.isArray(val)){
						//Sonderfall -1
						var isSettingAll = false;
						for (var i=0; i<val.length; i++)
							if (val[i] === "-1"){
								isSettingAll = true;
								break;
							}
						if (isSettingAll)
							continue;
					}
					else
					//Sonderfall -1
					if (val === "-1")
						continue;

					filterActive = true;

					$("#incidentTableBox .filter *[data-filter-id='" + key + "']").addClass("isFiltered");
				}
			}

			if (filterActive)
                $("#btnRemoveIncidentListFilters").removeClass("disabled");

		},

		//Filter zurücksetzen
		resetFilters: function(showItems){

			if (showItems === undefined)
				showItems = true;

			//Filter leeren
			model.curIncidentListView.filter = {};
			this.restoreIncidentListFilter(model.curIncidentListView);
			
			//Anzeige zurücksetzen
			$("#incidentTableBox .filter input").val("");
			$("#incidentTableBox .filter select").val("-1");

			//Select2 zurücksetzen
            $("#incidentTableBox .filter .select2").val("-1").trigger("change");
            $("#incidentTableBox .filter .select2-multiple").val("").trigger("change");

            if (showItems)
				incidentListManager.showIncidentItems(this.curIncidents, model.curIncidentListView);
		},
		
		//------------------------------------------------------------------------------------------------------
		
		//Sortierung wurde geändert
		onIncidentListSort: function(e){
			
			var that = incidentListManager;
			
			//wer?
			var col = $(e.currentTarget);
			var sortField = col.attr("data-sort-id");
			var isSortingAsc = col.hasClass("sorting_asc");
			var isSortingDesc = col.hasClass("sorting_desc");
			
			$("#incidentTable th.canSort").removeClass("sorting_asc sorting_desc");
			var sortDir = constants.SORTING_NONE;
			if (isSortingAsc){
				sortDir = constants.SORTING_DESC;
				$(this).addClass("sorting_desc");
			}
			else
			if (!isSortingDesc){
				sortDir = constants.SORTING_ASC;
				$(this).addClass("sorting_asc");
			}
			
			//merken
			model.curIncidentListView.sortDirection = sortDir;
			if (model.curIncidentListView.sortDirection === constants.SORTING_NONE)
				model.curIncidentListView.sortField = null;
			else
				model.curIncidentListView.sortField = sortField;
			
			//anwenden
			that.showIncidentItems(that.curIncidents, model.curIncidentListView);
		},
		
		//----------------------------------------------------------------------------
		
		//Listen-Einträge rendern
		showIncidentItems: function(incidents, filterObj){
			
			var that = this;

			//Filter anzeigen
			this.showIncidentListFilters(filterObj);
			
			//filter
			var trgIncidents = [];
			var inc;
			for (var i=0; i<incidents.length; i++){
				inc = incidents[i];
				
				if (inc.matchesFilter(filterObj.filter)){

					//tt.updateIncidents();
					inc.updateSortValue(filterObj.sortField, filterObj.sortDirection);
					trgIncidents.push(inc);
				}
			}
			
			//sort
			if (filterObj.sortDirection !== constants.SORTING_NONE){
				trgIncidents.sort(function(a, b){
					return ((a.sortVal < b.sortVal) ? -1 : ((a.sortVal > b.sortVal) ? 1 : 0));
				});
				//desc
				if (filterObj.sortDirection === constants.SORTING_DESC)
					trgIncidents.reverse();
			}
			
			//--------------
			
			//pagination
			var startIndex, endIndex;
			model.curIncidentListView.entriesPerPage = parseInt($("#incidentPaginationBox .viewsPerPage").val());
			if (model.curIncidentListView.entriesPerPage < 0){
				model.curIncidentListView.pageCount = 1;
				startIndex = 0;
				endIndex = trgIncidents.length;
			}
			else{
				model.curIncidentListView.pageCount = Math.ceil(trgIncidents.length / model.curIncidentListView.entriesPerPage);
				startIndex = model.curIncidentListView.curPage*model.curIncidentListView.entriesPerPage;
				endIndex = Math.min((model.curIncidentListView.curPage+1)*model.curIncidentListView.entriesPerPage, trgIncidents.length);
			}
			
			if (model.curIncidentListView.curPage === 0)
				$("#incidentPaginationBox .btn.prev").addClass("disabled");
			else
				$("#incidentPaginationBox .btn.prev").removeClass("disabled");
			if (model.curIncidentListView.curPage >= model.curIncidentListView.pageCount-1)
				$("#incidentPaginationBox .btn.next").addClass("disabled");
			else
				$("#incidentPaginationBox .btn.next").removeClass("disabled");
			
			//--------------
			
			//Inhalte
			var s = '';
			var index = 0;
			var signals = [],
				guid;
			for (i=startIndex; i<endIndex; i++){

				inc = trgIncidents[i];
				var task = Task.getTask(inc.taskId);
				var ts = task.getCurrentState();
				var form = ProtocolForm.getProtocolForm(inc.formId);
				var formField = form.getFormField(inc.fieldId);

				if (!formField)
					continue;

				//----------------------------------
				//permission?
				var mayShowIncident = false;
				if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_ALL)) {
					mayShowIncident = true;
				}
				else {
					if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_OWN)) {
						if (task.ownerId === model.curUserId) {
							mayShowIncident = true;
						}
						if (ts.responsibleId === model.curUserId) {
							mayShowIncident = true;
						}
						if (ts.isAssignedToUser(model.curUserId))
							mayShowIncident = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_BY_TASK_TYPE)) {
							if (model.curUser.hasTaskType(task.taskTypeId))
								mayShowIncident = true;
						}
					}
				}
				if (!mayShowIncident)
					continue;

				//Rechte
				var mayEditIncident = false;
				if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_ALL)) {
					mayEditIncident = true;
				}
				else {
					if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_OWN)) {
						if (task.ownerId === model.curUserId) {
							mayEditIncident = true;
						}
						if (ts.responsibleId === model.curUserId) {
							mayEditIncident = true;
						}
						if (ts.isAssignedToUser(model.curUserId))
							mayEditIncident = true;
					}
					if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_BY_TASK_TYPE)) {
						if (model.curUser.hasTaskType(task.taskTypeId))
							mayEditIncident = true;
					}
				}

				index++;

				//----------------------------------

				if (index % 2 === 0)
					s += '<tr data-incidentId="' + inc.id + '" class="incidentListItem gradeX even" role="row">';
				else
					s += '<tr data-incidentId="' + inc.id + '" class="incidentListItem gradeX odd" role="row">';

				//----------------------------------

				//Datum [Datepicker von/bis]
				s += "<td>";
				var d = new Date();
				d.setTime(inc.changedOn);
				s += pg.formatDate(d);
				s += "</td>";

				//-------------------

				//Objekt [Select2 Mehrfachauswahl] / Objekttyp
				s += "<td><span class='locationTitle'><span class='text-nowrap'>";
				var loc = Location.getLocation(inc.locationId, undefined, true, true, true);
				s += loc.getName(true);
				s += "</span></span>";

				var lType = LocationType.getLocationType(loc.type).name;
				if(lType)
					s += '<br/><span class="locationType text-muted">' + lType + '</span>';

				s += "</td>";

				//-------------------

				//Feld [Textsuche]
				s += "<td>";
				s += formField.name;
				s += "</td>";

				//Wert [Textsuche]
				//Wert/Farbe
				//neue Fassung? -> via DB
				s += "<td>";
				var color = form.getColorData2(inc.fieldId, inc.fieldValue) || "bgWhite";
				s += '<div class="label labelBox ' + color + '">' + inc.fieldValue + '</div>';
				s += "</td>";

				//Vorkommnis [Select2 Einzelauswahl]
				s += "<td>";
				s += inc.getType();
				s += "</td>";

				//Kommentar [Textsuche]
				s += '<td>';
				s += pg.nl2br(inc.description || "");
				s += '</td>';

				//-------------------------------------
				//Verlinkung zu Protokoll
				//Protokoll-ID (mit Deeplink) [Textsuche]

				//permission?
				s += "<td>";
				//s += inc.protocolId;
				var maySeeProtocolDetails = authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_DETAILS);
				if (maySeeProtocolDetails) {
					s += '&nbsp;&nbsp;&nbsp;';
					s += '<a data-taskId="' + inc.taskId + '" data-protocolId="' + inc.protocolId + '" class="btnIncidentProtocol">' + inc.protocolId + '</a>';
				}
				s += "</td>";

				//Protokollname [Select2 Einzelauswahl]
				s += "<td>";
				s += form.name;
				s += "</td>";

				//------------------------------------
				//Signal: Defect - Status [Select2 Einzelauswahl]

				s += "<td>";
				var sig = null;
				var hasSignal = false;
				for(var j = 0; j < inc.signals.length; j++) {
					sig = inc.signals[j];

					if (!sig.isNewestSignal(inc.signals, j))
						continue;

					if (sig.type === Signal.SIGNAL_DEFECT) {
						hasSignal = true;
						break;
					}
				}

				if (!hasSignal) {
					sig = Signal.createSignalRaw();
					guid = Math.floor(Math.random() * constants.MAX_SAFE_INTEGER_MYSQL);
					sig.id = guid * -1;
					sig.taskId = inc.taskId;
					sig.incidentId = inc.id;
					sig.type = Signal.SIGNAL_DEFECT;
					sig.value = Signal.VALUE_OK_DEFAULT;
				}
				signals.push(sig);

				//Anzeige via Switch
				s += '<input type="checkbox" data-signal-id="' + sig.id + '" data-task-id="' + inc.taskId + '" data-incident-id="' + inc.id + '" data-signal-type="' + sig.type + '" class="incidentSignal signalDefect" ';
				if (!mayEditIncident)
					s += ' disabled ';
				s += sig.getCheckboxValue();
				s += '/>';
				s += '</td><td>';

				//------------
				//Meldung [Select2 Einzelauswahl]
				//Signal: Meldung

				hasSignal = false;
				for(j = 0; j < inc.signals.length; j++) {
					sig = inc.signals[j];

					if (!sig.isNewestSignal(inc.signals, j))
						continue;

					if (sig.type === Signal.SIGNAL_NOTIFY) {
						hasSignal = true;
						break;
					}
				}
				if (!hasSignal) {
					sig = Signal.createSignalRaw();
					guid = Math.floor(Math.random() * constants.MAX_SAFE_INTEGER_MYSQL);
					sig.id = guid * -1;
					sig.taskId = inc.taskId;
					sig.incidentId = inc.id;
					sig.type = Signal.SIGNAL_NOTIFY;
					sig.value = 0;
				}
				signals.push(sig);

				//Anzeige via Switch
				s += '<input type="checkbox" data-signal-id="' + sig.id + '" data-task-id="' + inc.taskId + '" data-incident-id="' + inc.id + '" data-signal-type="' + sig.type + '" class="incidentSignal signalNotification" ';
				if (!mayEditIncident)
					s += ' disabled ';
				s += sig.getCheckboxValue();
				s += '/>';
				s += "</td>";

				//----------------------------------

				s += '</tr>';
			}
			
			//leer
			if (trgIncidents.length === 0){
				s = "<tr><td colspan='14'>Zur Anfrage wurden keine Vorkommnisse gefunden.</td></tr>";
				
				$("#incidentPaginationBox").hide();
			}
			else{
				$("#incidentPaginationBox").show();
				
				if (trgIncidents.length === 1)
					$("#incidentPaginationBox .filterMatchCount").html("1 Vorkommnis");
				else
					$("#incidentPaginationBox .filterMatchCount").html(trgIncidents.length + " Vorkommnisse");
				
				$("#incidentPaginationBox .pagination-panel-input").val((model.curIncidentListView.curPage+1));
				$("#incidentPaginationBox .pagination-panel-total").html(""+model.curIncidentListView.pageCount);
			}
			
			//anzeigen
			$("#incidentTable tbody").html(s);

			//-----------------------------------------
			//handlers

			//Switches setzen und behandeln
			$(".incidentSignal.signalDefect").bootstrapSwitch({
				onText: '<i class="fa fa-fw fa-wrench"></i>',
				onColor: 'green',
				offText: '<i class="fa fa-fw fa-wrench"></i>',
				offColor: 'red',
				size: "mini",
				width: 50,
				onSwitchChange: function (event, state) {
					that.toggleSignal(this);
				}
			});
			$(".incidentSignal.signalNotification").bootstrapSwitch({
				onText: '<i class="fa fa-fw fa-flag"></i>',
				onColor: 'gray',
				offText: '<i class="fa fa-fw fa-flag"></i>',
				offColor: 'red',
				size: "mini",
				width: 50,
				onSwitchChange: function (event, state) {
					that.toggleSignal(this);
				}
			});

			//warum auch immer erst mit Timeout?!
			setTimeout(function(){

				$(".select2, .select2-multiple").select2();

				//Protokolldetails
				$(".btnIncidentProtocol").off("click").on("click", function (e) {

					//scroll-Position merken
					model.curIncidentListView = model.curIncidentListView || {};
					model.curIncidentListView.scrollPos = viewManager.getScrollTop();

					var link = $(e.currentTarget);
					if (link.attr("data-taskId") && link.attr("data-protocolId")) {

						viewManager.showPage(constants.PAGE_TASK_PROTOCOL, {
							taskId: parseInt(link.attr("data-taskId"), 10),
							protocolId: parseInt(link.attr("data-protocolId"), 10)
						});
					}
				});

				//-------------------------------------
				//Tabellensteuerung
				$(".viewsPerPage").change(function(){
					that.showIncidentItems(that.curIncidents, model.curIncidentListView);
				});
				$("#incidentPaginationBox .pagination-panel-input").change(function(){
					var val = $("#incidentPaginationBox .pagination-panel-input").val();
					if ($.isNumeric(val)) {
						val = parseInt(val);
						val = Math.max(val, 1);
						val = Math.min(val, model.curIncidentListView.pageCount);
						model.curIncidentListView.curPage = val-1;
						that.showIncidentItems(that.curIncidents, model.curIncidentListView);
					}
				});
				$("#incidentPaginationBox .btn.prev").off("click").on("click", function(){
					if (model.curIncidentListView.curPage > 0)
						model.curIncidentListView.curPage--;
					that.showIncidentItems(that.curIncidents, model.curIncidentListView);
				});
				$("#incidentPaginationBox .btn.next").off("click").on("click", function(){
					if (model.curIncidentListView.curPage < model.curIncidentListView.pageCount-1)
						model.curIncidentListView.curPage++;
					that.showIncidentItems(that.curIncidents, model.curIncidentListView);
				});
			}, 100);
		},

		//Anzeige eines Vorkommnisses aktualisieren
		updateTaskIncidents: function (t) {

			//update model
			t.updateIncidents();
		},
		
		//-------------------------------------------------------------------------------

		//Umschalten eines Signals behandeln
		toggleSignal: function(trgEl){

			var that = incidentListManager;

			var taskId = parseInt($(trgEl).attr("data-task-id"));
			var task = Task.getTask(taskId);

			var sigId = parseInt($(trgEl).attr("data-signal-id"));
			var sig = task.getSignal(sigId);

			//noch kein Signal vorhanden?
			if (!sig) {

				var incident = task.getIncident(parseInt($(trgEl).attr("data-incident-id")));
				var type = parseInt($(trgEl).attr("data-signal-type"));
				var val = Signal.VALUE_NOT_OK;

				sig = Signal.createSignal(incident.clientId, incident.userId, incident.taskId, incident.locationId, incident.protocolId, incident.formId, incident.id, incident.fieldId, type, val);
			}
			else {
				//umkehren
				sig.toggle();
			}

			//in DB merken
			dataManager.storeSignal(sig, function (data) {

				sig.id = parseInt(data.id);
				sig.changedOn = pg.parseDate(data.changedOn);

				//Anzeige aktualisieren:
				//neue ID übernehmen
				$("tbody .incidentSignal[data-incident-id='" + sig.incidentId + "'][data-signal-id='" + sigId + "'][data-signal-type='" + sig.type + "']").attr("data-signal-id", sig.id);
				//Datum
				$("tbody .timestamp[data-incident-id='" + sig.incidentId + "']").html(pg.formatDate(sig.changedOn));

				//neu
				if (parseInt(data.isNew) === 1) {
					var incident2 = task.getIncident(sig.incidentId);
					incident2.addSignal(sig);
				}

				//Ansicht aktualisieren
				incidentListManager.updateTaskIncidents(task);

				viewManager.showNotification("Vorkommnis erfolgreich aktualisiert.", constants.NOTIFICATION_SUCCESS);
			});

		},

		//-------------------------------------------------------------------------------

		onResize: function(){
			var that = incidentListManager;
			that.updateTopScroller();
		},
		
		updateTopScroller: function(){
			var contentWidth = $("#incidentTableBox > table").width();
			$("#incidentTableScroller div").width(contentWidth);
		}
		
	};
	
	window.incidentListManager = incidentListManager;
}());
