(function () {
	
	//----------------------------------------------------------------
	//dynamisches Feld für Aufträge / Anträge
	//----------------------------------------------------------------
	
	/*global LocationField:true*/
	
    'use strict';

    var TaskTypeField = {

    	//VISIBILITY_BACKEND_AND_APP: 0,
	    //VISIBILITY_BACKEND_ONLY: 1,
        
        //Felder können verschiedene Rollen ausfüllen
        ROLE_LOCATION: 1,
    	
        taskTypeFieldPrototype: {
	
	        fromObj: function (t) {

		        this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

		        this.taskTypeId = parseInt(t.taskTypeId, 10);
		        this.clientId = parseInt(t.clientId, 10);
		
		        this.hint = t.hint;
		        this.placeholder = t.placeholder;
		        this.defaultValue = t.defaultValue;
		        this.minVal = parseFloat(t.minVal);
		        this.maxVal = parseFloat(t.maxVal);
		        this.step = parseFloat(t.step);
		        
		        this.orderIndex = parseInt(t.orderIndex, 10);
		        this.category = t.category;
		        this.name = t.name || t.fieldName;
		        this.options = t.options;
		        this.isRequired = parseInt(t.isRequired, 10);
		        this.visibility = parseInt(t.visibility, 10);
		        if (t.type) {
			        this.type = parseInt(t.type);
			        this.suffix = t.suffix;
		        }
		        
		        this.role = parseInt(t.role || 0, 10);
		
		        return this;
	        },
	
	        serialize: function () {
		
		        var o = {};
		
		        o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

		        o.taskTypeId = this.taskTypeId;
		        o.clientId = this.clientId;
		
		        o.hint = this.hint;
		        o.placeholder = this.placeholder;
		        o.defaultValue = this.defaultValue;
		        
		        o.orderIndex = this.orderIndex;
		        o.category = this.category;
		        o.name = this.name;
		        o.options = this.options;
		        o.isRequired = this.isRequired;
		        o.visibility = this.visibility;
		
		        o.type = this.type;
		        o.suffix = this.suffix;
		        
		        o.role = this.role;
		
		        o.minVal = this.minVal;
		        o.maxVal = this.maxVal;
		        o.step = this.step;
		
		        return o;
	        },
	
	        //----------------------------------------------
	        
	        //ID für Einordnung im Editor-DOM
	        getDomId: function(){
	        	return "dynData" + this.id;
	        },
	
	        //----------------------------------------------
	
	        //Wert formatieren
	        getFormattedValue: function(value){
	        	switch (this.type){
					default:
			        	return value;
		        }
	        },
	
	        //Validierung
	        isValidValue: function(value){
		        switch (this.type){
					default:
				        if (!value)
				        	return false;
				        break;
		        }
		        
		        return true;
	        }
        },
	
	    //----------------------------------------------
	    
	    getTaskTypeField: function (id) {
		    for (var i = 0; i < model.taskTypeFields.length; i++)
			    if (model.taskTypeFields[i].id === id) {
				    return model.taskTypeFields[i];
			    }
		    return null;
	    },
	    
        getTaskTypeFields: function (taskTypeId) {
            var a = [];
            for (var i = 0; i < model.taskTypeFields.length; i++)
                if (model.taskTypeFields[i].taskTypeId === taskTypeId) {
                    a.push(model.taskTypeFields[i]);
                }
            return a;
        },
	
	    //----------------------------------------------
	    
        createTaskTypeField: function () {
            return Object.create(TaskTypeField.taskTypeFieldPrototype);
        }
    };

    window.TaskTypeField = TaskTypeField;
}());

