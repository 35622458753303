(function () {
	
	//----------------------------------------------------------------
	//Auftragstyp
	//----------------------------------------------------------------
	
	/*global LocationType:true*/
	
	'use strict';
	
	var TaskType = {
		
		taskTypePrototype: {
			
			formIds: [],
			
			fromObj: function (t) {
				
				var i;
				
				this.clientId = parseInt(t.clientId, 10);
				
				//shallow copy!
				if (t.formIds) {
					var f = t.formIds.split("#");
					for(i = 0; i < f.length; i++) {
						if (f[i] !== "")
							this.addFormId(f[i]);
					}
				}
				
				this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				this.name = t.name;
				this.version = parseInt(t.version, 10);
				this.typeId = parseInt(t.typeId, 10);
				
				this.locationTypes = "";
				if (t.locationTypes)
					this.locationTypes = pg.restoreDbString(t.locationTypes);
				this.locationTypeIds = [];
				var tt = pg.splitNonEmpty(this.locationTypes, "#");
				for(i = 0; i < tt.length; i++)
					this.locationTypeIds.push(parseInt(tt[i]));
				
				this.requiredModuleId = parseInt(t.requiredModuleId || 0);
				this.isRequestable = parseInt(t.isRequestable || 0);
				
				//editor settings
				this.editTaskType = parseInt(t.editTaskType || 0);
				this.editLocationType = parseInt(t.editLocationType || 0);
				this.editTitle = parseInt(t.editTitle || 0);
				this.editDescription = parseInt(t.editDescription || 0);
				this.editDueDate = parseInt(t.editDueDate || 0);
				this.editInterval = parseInt(t.editInterval || 0);
				this.editIntervalEnd = parseInt(t.editIntervalEnd || 0);
				this.editLocations = parseInt(t.editLocations || 0);
				this.editAssignee = parseInt(t.editAssignee || 0);
				this.editOwner = parseInt(t.editOwner || 0);
				this.editAttachments = parseInt(t.editAttachments || 0);
				
				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.clientId = this.clientId;
				o.formIds = this.formIds.join("#");
				o.name = this.name;
				o.version = this.version;
				o.typeId = this.typeId;
				//o.defaultInterval = this.defaultInterval;
				o.locationTypes = this.locationTypes;
				
				o.requiredModuleId = this.requiredModuleId;
				o.isRequestable = this.isRequestable;
				
				//editor settings
				o.editTaskType = this.editTaskType;
				o.editLocationType = this.editLocationType;
				o.editTitle = this.editTitle;
				o.editDescription = this.editDescription;
				o.editDueDate = this.editDueDate;
				o.editInterval = this.editInterval;
				o.editIntervalEnd = this.editIntervalEnd;
				o.editLocations = this.editLocations;
				o.editAssignee = this.editAssignee;
				o.editOwner = this.editOwner;
				o.editAttachments = this.editAttachments;
				
				return o;
			},
			
			//----------------------------------------------
			
			//ist diesem Auftragstyp ein bestimmter Objekttyp zugeordnet?
			hasLocationType: function (locationType) {
				for(var i = 0; i < this.locationTypeIds.length; i++) {
					if (this.locationTypeIds[i] === locationType)
						return true;
				}
				return false;
			},
			
			//alle Objekttypen holen
			getLocationTypeIds: function () {
				return this.locationTypeIds;
			},
			
			//----------------------------------------------
			//Formulare zu diesem Auftragstyp
			
			addFormId: function (o) {
				//shallow copy!
				var a = [];
				for(var i = 0; i < this.formIds.length; i++)
					a.push(this.formIds[i]);
				a.push(parseInt(o));
				this.formIds = a;
			},
			
			hasFormId: function (protocolFormId) {
				protocolFormId = parseInt(protocolFormId);
				for(var i = 0; i < this.formIds.length; i++) {
					if (this.formIds[i] === protocolFormId)
						return true;
				}
				return false;
			}
		},
		
		//---------------------------------------------------------------
		
		//Sichten aktualisieren
		updateTaskTypes: function () {
			for(var i = 0; i < model.taskTypes.length; i++)
				model.taskTypes[i].isCurrentVersion = true;
			for(i = 0; i < model.taskTypes.length; i++) {
				var p = model.taskTypes[i];
				
				//Versionierung
				if (p.version > 1) {
					//direkten Vorgänger suchen
					for (var k=p.version-1; k>0; k--) {
						var pPrev = TaskType.getTaskType(p.typeId, k);
						if (pPrev) {
							p.prevVersion = pPrev;
							pPrev.nextVersion = p;
							pPrev.isCurrentVersion = false;
							break;
						}
					}
				}
				
				//dynamische Felder hinzufügen
				p.fields = [];
				for (var j=0; j<model.taskTypeFields.length; j++){
					var ttf = model.taskTypeFields[j];
					if (ttf.taskTypeId === p.typeId){
						p.fields.push(ttf);
					}
				}
			}
		},
		
		//----------------------------------------------
		
		//bestimmten Auftragstyp holen
		getTaskType: function (taskTypeId, version) {
			for(var i = 0; i < model.taskTypes.length; i++) {
				var tt = model.taskTypes[i];
				if (tt.typeId === taskTypeId) {
					
					if (version === undefined) {
						if (!tt.isCurrentVersion)
							continue;
					}
					else if (tt.version !== version)
						continue;
					
					return tt;
				}
			}
			return null;
		},
		
		//----------------------------------------------
		
		//alle
		getAllTaskTypes: function () {
			
			var a = [];
			for(var i = 0; i < model.taskTypes.length; i++) {
				var tt = model.taskTypes[i];
				if (!tt.isCurrentVersion)
					continue;
				a.push(tt);
			}
			return a;
		},
		
		//----------------------------------------------
		
		//zu einem Objekttyp alle passenden Auftragstypen holen
		getTaskTypesByLocationType: function (locationTypeId) {
			
			locationTypeId = parseInt(locationTypeId);
			
			var tt = this.getAllTaskTypes();
			var a = [];
			for(var i = 0; i < tt.length; i++) {
				for(var j = 0; j < tt[i].locationTypeIds.length; j++) {
					
					var lt = tt[i].locationTypeIds[j];
					
					//all?
					if ((lt === LocationType.LOCATION_TYPE_ALL) || (lt === locationTypeId)) {
						a.push(tt[i].typeId);
						break;
					}
				}
			}
			return a;
		},
		
		//----------------------------------------------
		
		createTaskType: function () {
			return Object.create(TaskType.taskTypePrototype);
		}
	};
	
	window.TaskType = TaskType;
}());