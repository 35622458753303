(function () {
	
	//----------------------------------------------------------------
	//History-Element (versch. Entities)
	//----------------------------------------------------------------
	
	/*global constants:true*/
	
	'use strict';
	
	var HistoryItem = {
		
		historyItemPrototype: {
			
			fromObj: function (t) {

				/*this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);*/

				this.entityId = parseInt(t.entityId, 10);
				this.type = parseInt(t.type, 10);
				this.timestamp = pg.parseDate(t.timestamp);
				this.actorId = parseInt(t.actorId, 10);
				this.int1 = parseInt(t.int1, 10);
				if (isNaN(this.int1))
					this.int1 = null;
				this.int2 = parseInt(t.int2, 10);
				if (isNaN(this.int2))
					this.int2 = null;
				this.int3 = parseInt(t.int3, 10);
				if (isNaN(this.int3))
					this.int3 = null;
				this.string1 = pg.restoreDbString(t.string1);
				this.string2 = pg.restoreDbString(t.string2);
				this.long1 = pg.restoreDbString(t.long1);

				return this;
			},
			
			serialize: function () {
				
				var o = {};

				/*if ($.type(this.changedOn) !== "date")
					this.changedOn = pg.parseDate(this.changedOn);*/

				/*o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;*/

				o.entityId = this.entityId;
				o.type = this.type;
				o.timestamp = pg.buildDate(this.timestamp);
				o.actorId = this.actorId;
				o.int1 = this.int1;
				o.int2 = this.int2;
				o.int3 = this.int3;
				o.string1 = this.string1;
				o.string2 = this.string2;
				o.long1 = this.long1;

				return o;
			},

			getChanges: function(){
				var a = $.parseJSON(this.long1);
				if (!$.isArray(a)){
					var b = [];
					b.push(a);
					a = b;
				}
				//return  pg.getJson(this.long1);
				return a;
			}
			
		},
		
		//----------------------------------------------------------
		
		createHistoryItem: function () {
			var h = Object.create(HistoryItem.historyItemPrototype);
			return h;
		}
		
	};
	
	window.HistoryItem = HistoryItem;
}());