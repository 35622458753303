(function () {
	
	//----------------------------------------------------------------
	//Aktivität (User oder System)
	//----------------------------------------------------------------
	
	'use strict';
	
	var Activity = {

		TYPE_INIT: 0,
		TYPE_NETWORK_ONLINE: 1,
		TYPE_NETWORK_OFFLINE: 2,
		TYPE_MODEL_RESET: 3,
		TYPE_MODEL_RESET_LOGIN: 4,
		TYPE_APP_START: 5,
		TYPE_DB_OPEN: 6,
		TYPE_DB_OPEN_ERROR: 7,
		TYPE_LOAD_DATA_ERROR: 8,
		TYPE_LOAD_DATA_SUCCESS: 9,
		TYPE_LOAD_DATA_SUCCESS_PROCEED: 10,
		TYPE_LOAD_DATA_FAILURE: 11,
		TYPE_LOGOUT_INIT: 12,
		TYPE_LOGOUT_COMPLETE: 13,
		TYPE_CHANGE_ACCOUNT_INIT: 14,
		TYPE_CHANGE_ACCOUNT_COMPLETE: 15,
		TYPE_LOGIN_INIT: 16,
		TYPE_CREATE_FOLDER_ERROR: 17,
		TYPE_APP_LEAVE: 18,
		TYPE_APP_ENTER: 19,
		TYPE_BACKGROUND_FETCH_INIT: 20,
		TYPE_BACKGROUND_FETCH_START: 21,
		TYPE_BACKGROUND_FETCH_COMPLETE: 22,
		TYPE_BACKGROUND_FETCH_ERROR: 23,
		TYPE_PROCESS_VERSION: 24,
		TYPE_PN_RECEIVED: 25,
		TYPE_PN_RECEIVED_ERROR: 26,
		TYPE_PN_ERROR: 27,
		TYPE_PN_LOAD_DATA_COMPLETE: 28,
		TYPE_PN_SET_TOKEN_FAILURE: 29,
		TYPE_PN_SET_TOKEN_SUCCESS: 30,
		TYPE_SESSION_INVALID: 31,
		TYPE_ADD_PENDING_OBJECT: 32,
		TYPE_LOAD_OFFLINE_DATA_COMPLETE: 33,
		TYPE_LOAD_OFFLINE_DATA_ERROR: 34,
		TYPE_RESET_OFFLINE_DATA: 35,
		TYPE_PROTOCOL_INIT: 36,
		TYPE_PROTOCOL_SAVE: 37,
		TYPE_PROTOCOL_ERROR_FORMFIELDS_EMPTY: 38,
		TYPE_PROTOCOL_ERROR_FORMFIELDS_MISSING: 39,
		TYPE_PROTOCOL_SEND_INIT: 40,
		TYPE_PROTOCOL_SEND_TASK_COMPLETE: 41,
		TYPE_PROTOCOL_SEND_TASK_NOT_COMPLETE: 42,
		TYPE_PROTOCOL_SEND_SAVE_COMPLETE: 43,
		TYPE_PROTOCOL_SEND_ATTACHMENT_INIT: 44,
		TYPE_PROTOCOL_SEND_ATTACHMENT_COMPLETE: 45,
		TYPE_PROTOCOL_SEND_ATTACHMENT_ERROR: 46,
		//TYPE_PROTOCOL_CANCEL: 47,
		TYPE_PIPELINE_START: 48,
		TYPE_PIPELINE_SUCCESS: 49,
		TYPE_PIPELINE_FAILURE: 50,
		TYPE_PIPELINE_ATTACHMENT_INIT: 51,
		TYPE_PIPELINE_ATTACHMENT_SUCCESS: 52,
		TYPE_PIPELINE_PROTOCOL_INIT: 53,
		TYPE_PIPELINE_PROTOCOL_SUCCESS: 54,
		TYPE_PIPELINE_REMOVE: 55,
		TYPE_PIPELINE_PROTOCOL_SEND_INIT: 56,
		TYPE_PIPELINE_PROTOCOL_SEND_SUCCESS: 57,
		TYPE_PIPELINE_PROTOCOL_SEND_SUCCESS_UPDATE: 58,
		TYPE_PIPELINE_PROTOCOL_SEND_FAILURE: 59,
		TYPE_PIPELINE_PROTOCOL_UPDATE_TRANSMITTED: 60,
		TYPE_SHOW_PATH: 61,
		TYPE_LOAD_DATA_FROM_SERVER_INIT: 62,
		TYPE_LOAD_DATA_FROM_SERVER_ERROR: 63,
		TYPE_LOAD_DATA_FROM_SERVER_COMPLETE: 64,
		TYPE_PROTOCOL_SET_TRANSMITTED_COMPLETE: 65,
		TYPE_PROTOCOL_SET_TRANSMITTED_ERROR: 66,
		TYPE_PROTOCOL_SEND_COMPLETE: 67,
		TYPE_PROTOCOL_SEND_ERROR: 68,
		TYPE_TASK_STATE_SEND_COMPLETE: 69,
		TYPE_TASK_STATE_SEND_ERROR: 70,
		TYPE_UPLOAD_FILE_COMPLETE: 71,
		TYPE_UPLOAD_FILE_ERROR: 72,
		TYPE_UPLOAD_FILE_PROGRESS: 73,
		TYPE_DELETE_FILE_COMPLETE: 74,
		TYPE_DELETE_FILE_ERROR: 75,
		TYPE_COMMENT_SEND_COMPLETE: 76,
		TYPE_COMMENT_SEND_ERROR: 77,
		TYPE_RESET_PASSWORD_COMPLETE: 78,
		TYPE_RESET_PASSWORD_ERROR: 79,
		TYPE_VERIFY_CREDENTIALS_COMPLETE: 80,
		TYPE_VERIFY_CREDENTIALS_ERROR: 81,
		TYPE_LOGOUT_SEND_COMPLETE: 82,
		TYPE_LOGOUT_SEND_ERROR: 83,
		TYPE_PATH_CHANGED: 84,
		TYPE_PIPELINE_RUNNING: 85,
		TYPE_COMMAND_SEND_ATTACHMENT_INIT: 86,
		TYPE_COMMAND_SEND_ATTACHMENT_COMPLETE: 87,
		TYPE_COMMAND_SEND_ATTACHMENT_ERROR: 88,
		TYPE_UPLOAD_FILE_INIT: 89,
		TYPE_UPLOAD_FILE_START: 90,
		TYPE_PROTOCOL_SET_TRANSMITTED_COMPLETE_PIPELINE: 91,
		TYPE_UPLOAD_FILE_PIPELINE: 92,
		TYPE_COMMENT_SEND_PIPELINE: 93,
		TYPE_COMMENT_VERIFY_PIPELINE_START: 94,
		TYPE_CLEAR_CACHE: 95,
		TYPE_PROTOCOL_SEND_DUPLICATE: 96,
		TYPE_TASK_STATE_SEND_DUPLICATE: 97,
		TYPE_COMMENT_SEND_DUPLICATE: 98,
		TYPE_TASK_SEND_COMPLETE: 99,
		TYPE_TASK_SEND_ERROR: 100,


		//----------------------------------------------------------------

		createActivity: function (type, args, isOnline) {
			return {
				t: type,
				args: args || {},
				isOnline: isOnline,
				timestamp: new Date()
			};
		}
	};
	
	window.Activity = Activity;
}());