(function () {

		//------------------------------------------------------------
		//Kombinierter Editor für Anträge und Aufträge
		//------------------------------------------------------------

		/*global
		 constants:true,
		 viewManager:true,
		 dataManager:true,
		 setupFileUploader:true,
		 TaskType:true,
		 UserGroup:true,
		 Location:true,
		 Task:true,
		 Request:true,
		 RequestState:true,
		 requestDetailManager:true,
		 Attachment:true,
		 TaskState:true,
		 authManager:true,
		 User:true,
		 UserRole:true,
		 LocationType:true,
		 autosize:true,
		 TaskTypeField:true,
		 bootbox:true*/

		'use strict';

		var taskRequestEditorManager = {

			//==========================================================
			//==========================================================
			//Editor/Klasse ist in Request-Mode
			isRequest: false,
			//==========================================================
			//==========================================================

			hasIntervalEditorBeenShown: false,
			ignoreDatePicker: false,

			//------------------------------------------------------------------------------------------------------------------------------------------------

			//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
			checkModelChanged: function (data, oldModel) {

				var that = taskRequestEditorManager;

				var i,
					j,
					tsOld,
					tsNew,
					hasMatch;

				if (that.isRequest) {

					//aktueller Request verändert?
					if (data.requests) {
						for (i = 0; i < data.requests.length; i++) {

							if (parseInt(data.requests[i].requestId) === oldModel.curTaskOrRequest.requestId) {
								tsOld = oldModel.curTaskOrRequest.changedOn.getTime();
								tsNew = pg.parseDate(data.requests[i].changedOn).getTime();
								if (tsNew !== tsOld)
									return true;
							}
						}
					}
					//noinspection JSUnresolvedVariable
					if (data.requestStates) {
						//noinspection JSUnresolvedVariable
						for (i = 0; i < data.requestStates.length; i++) {

							//noinspection JSUnresolvedVariable
							if (parseInt(data.requestStates[i].requestId) === oldModel.curTaskOrRequest.requestId) {

								hasMatch = false;
								for (j = 0; j < oldModel.curTaskOrRequest.states.length; j++) {

									var rs = oldModel.curTaskOrRequest.states[j];
									//noinspection JSUnresolvedVariable
									if (rs.id === parseInt(data.requestStates[i].id)) {
										hasMatch = true;
										tsOld = rs.changedOn.getTime();
										//noinspection JSUnresolvedVariable
										tsNew = pg.parseDate(data.requestStates[i].changedOn).getTime();
										if (tsNew !== tsOld)
											return true;
									}
								}
								//neues Objekt
								if (!hasMatch)
									return true;
							}
						}
					}
				}
				else {
					//aktueller Task verändert?
					if (data.tasks) {
						for (i = 0; i < data.tasks.length; i++) {

							if (parseInt(data.tasks[i].taskId) === oldModel.curTaskOrRequest.taskId) {
								tsOld = oldModel.curTaskOrRequest.changedOn.getTime();
								tsNew = pg.parseDate(data.tasks[i].changedOn).getTime();
								if (tsNew !== tsOld)
									return true;
							}
						}
					}
					if (data.taskStates) {
						for (i = 0; i < data.taskStates.length; i++) {

							if (parseInt(data.taskStates[i].taskId) === oldModel.curTaskOrRequest.taskId) {

								hasMatch = false;
								for (j = 0; j < oldModel.curTaskOrRequest.states.length; j++) {

									if (oldModel.curTaskOrRequest.states[j].id === parseInt(data.taskStates[i].id)) {
										hasMatch = true;
										tsOld = oldModel.curTaskOrRequest.states[j].changedOn.getTime();
										tsNew = pg.parseDate(data.taskStates[i].changedOn).getTime();
										if (tsNew !== tsOld)
											return true;
									}
								}
								//neues Objekt
								if (!hasMatch)
									return true;
							}
						}
					}
					if (data.protocols) {
						for (i = 0; i < data.protocols.length; i++) {

							if (parseInt(data.protocols[i].taskId) === oldModel.curTaskOrRequest.taskId) {

								hasMatch = false;
								for (j = 0; j < oldModel.curTaskOrRequest.protocols.length; j++) {

									if (oldModel.curTaskOrRequest.protocols[j].id === parseInt(data.protocols[i].id)) {
										hasMatch = true;
										tsOld = oldModel.curTaskOrRequest.protocols[j].changedOn.getTime();
										tsNew = pg.parseDate(data.protocols[i].changedOn).getTime();
										if (tsNew !== tsOld)
											return true;
									}
								}
								//neues Objekt
								if (!hasMatch)
									return true;
							}
						}
					}
					if (data.incidents) {
						for (i = 0; i < data.incidents.length; i++) {

							if (parseInt(data.incidents[i].taskId) === oldModel.curTaskOrRequest.taskId) {

								hasMatch = false;
								for (j = 0; j < oldModel.curTaskOrRequest.incidents.length; j++) {

									if (oldModel.curTaskOrRequest.incidents[j].id === parseInt(data.incidents[i].id)) {
										hasMatch = true;
										tsOld = oldModel.curTaskOrRequest.incidents[j].changedOn.getTime();
										tsNew = pg.parseDate(data.incidents[i].changedOn).getTime();
										if (tsNew !== tsOld)
											return true;
									}
								}
								//neues Objekt
								if (!hasMatch)
									return true;
							}
						}
					}
					if (data.signals) {
						for (i = 0; i < data.signals.length; i++) {

							if (parseInt(data.signals[i].taskId) === oldModel.curTaskOrRequest.taskId) {

								hasMatch = false;
								for (j = 0; j < oldModel.curTaskOrRequest.incidents.length; j++) {

									if (oldModel.curTaskOrRequest.signals[j].id === parseInt(data.signals[i].id)) {
										hasMatch = true;
										tsOld = oldModel.curTaskOrRequest.signals[j].changedOn.getTime();
										tsNew = pg.parseDate(data.signals[i].changedOn).getTime();
										if (tsNew !== tsOld)
											return true;
									}
								}
								//neues Objekt
								if (!hasMatch)
									return true;
							}
						}
					}
				}

				return false;
			},

			//------------------------------------------------------------------------------------------------------------------------------------------------

			//Request editieren
			editRequest: function (requestId, callbackComplete) {

				if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_ALL,
						UserRole.PERMISSION_REQUEST_EDIT_OWN,
						UserRole.PERMISSION_REQUEST_EDIT_BY_TASK_TYPE)) {
					viewManager.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
					return false;
				}
				if (!model.client.hasModule(constants.MODULE_ID_REQUESTS)) {
					viewManager.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
					return false;
				}

				this.isRequest = true;
				var request = Request.getRequest(requestId);
				this.showTaskEditor(request, callbackComplete);

				return false;
			},

			//neuen Request anlegen
			addRequest: function (callbackComplete) {

				if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_CREATE, UserRole.PERMISSION_REQUEST_CREATE_BY_TASK_TYPE)) {
					viewManager.showPage(constants.PAGE_OVERVIEW, null, null, null, null, null, callbackComplete);
					return false;
				}

				this.isRequest = true;
				this.showTaskEditor(null, callbackComplete);

				return false;
			},

			//Antrag duplizieren
			cloneRequest: function (callbackComplete) {

				if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_CREATE,
						UserRole.PERMISSION_REQUEST_CREATE_BY_TASK_TYPE)) {
					viewManager.execShowPage(constants.PAGE_OVERVIEW, null, null, null, null, null, callbackComplete);
					return false;
				}

				this.isRequest = true;
				model.curCloneTaskOrRequest = model.curRequest || model.curTaskOrRequest;
				model.curCloneTaskOrRequest.ownerId = model.curUserId;
				taskRequestEditorManager.addRequest(callbackComplete);
				setTimeout(function () {
					viewManager.setFormDirty();
				}, 1000);

				return false;
			},

			//------------------------------------------------------------------------------------------------------------------------------------------------

			//Task editieren
			editTask: function (taskId, callbackComplete) {

				if (!authManager.hasPermission(UserRole.PERMISSION_TASK_EDIT_ALL,
						UserRole.PERMISSION_TASK_EDIT_OWN,
						UserRole.PERMISSION_TASK_EDIT_BY_TASK_TYPE)) {
					viewManager.showPage(constants.PAGE_OVERVIEW, null, null, null, null, null, callbackComplete);
					return false;
				}

				this.isRequest = false;
				var task = Task.getTask(taskId);
				this.showTaskEditor(task, callbackComplete);

				return false;
			},
			//Task editieren
			editTaskObject: function (task) {

				if (!authManager.hasPermission(UserRole.PERMISSION_TASK_EDIT_ALL,
						UserRole.PERMISSION_TASK_EDIT_OWN,
						UserRole.PERMISSION_TASK_EDIT_BY_TASK_TYPE)) {
					viewManager.showPage(constants.PAGE_OVERVIEW);
					return false;
				}

				this.isRequest = false;
				this.showTaskEditor(task);

				return false;
			},

			//neuen Task anlegen
			addTask: function (callbackComplete) {

				if (!authManager.hasPermission(UserRole.PERMISSION_TASK_CREATE_ALL, UserRole.PERMISSION_TASK_CREATE_BY_TASK_TYPE)) {
					viewManager.showPage(constants.PAGE_OVERVIEW, null, null, null, null, null, callbackComplete);
					return false;
				}

				this.isRequest = false;
				this.showTaskEditor(null, callbackComplete);

				return false;
			},

			//Auftrag duplizieren
			cloneTask: function (callbackComplete) {

				if (!authManager.hasPermission(UserRole.PERMISSION_TASK_CREATE_ALL,
						UserRole.PERMISSION_TASK_CREATE_BY_TASK_TYPE)) {
					viewManager.execShowPage(constants.PAGE_OVERVIEW, null, null, null, callbackComplete);
					return false;
				}

				this.isRequest = false;
				model.curCloneTaskOrRequest = model.curTask || model.curTaskOrRequest;
				model.curCloneTaskOrRequest.ownerId = model.curUserId;
				this.addTask(callbackComplete);
				setTimeout(function () {
					viewManager.setFormDirty();
				}, 1000);

				return false;
			},

			//------------------------------------------------------------------------------------------------------------------------------------------------

			//Inhalte in Editor einfüllen
			fillTaskEditor: function (task) {

				var i;

				var that = this;
				var ts = task.getCurrentState();

				that.updateLocationTypes(task.taskTypeId);
				$("#selectLocationType").val(task.locationType).trigger("change");
				$('#selectLocationType').prop('disabled', 'disabled');

				//desc
				$("#taskDesc").val(task.description);

				//title
				$("#taskTitle").val(task.title);

				//due date (immer "fällig bis"!)
				$("#taskDueDate").val(pg.formatDate(task.getDueDate(), true));

				//end date
				var endDate = task.endDate;
				if (endDate)
					$("#taskEndDate").val(pg.formatDate(endDate, true));

				//locations
				that.updateLocationTree(task.locationType, function () {

					//alte anzeigen
					for (i = 0; i < task.locationList.length; i++) {
						$('#treeLocation').jstree("select_node", task.locationList[i]);
						//$('#treeLocation').jstree("open_node", task.locationList[i]);
					}
					setTimeout(function () {
						//noinspection JSUnresolvedFunction
						$("#treeLocation").jstree(true).close_all();
						that.updateObjectCount();
					}, 20);


					$('#treeLocation').on('activate_node.jstree', function () {
						viewManager.setFormDirty();
					});
				});

				//Responsible
				that.updateResponsible(task.taskTypeId, task.locationType);
				if (ts)
					$("#selectResponsible").val(ts.responsibleId).trigger("change");

				if (that.isRequest) {

					that.updateDecider();
					if (ts)
						$("#selectDecider").val(ts.deciderId).trigger("change");

				}
				else {

					//state: userGroups
					that.updateUserGroups(task.taskTypeId, task.locationType);
					if (ts) {
						//$("#selectUserGroup").val(ts.userGroupId).trigger("change");
						var ugIds = _.map(ts.getUserGroups(), function (ug) {
							return ug.id;
						});
						$("#selectUserGroup").val(ugIds).trigger("change");
					}


					var mayReAssign = false;
					if (authManager.hasPermission(UserRole.PERMISSION_TASK_ASSIGN_ALL)) {
						mayReAssign = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_TASK_ASSIGN_OWN)) {
							if (task.ownerId === model.curUserId) {
								mayReAssign = true;
							}
							var tsx = task.getCurrentState();
							if (tsx.responsibleId === model.curUserId) {
								mayReAssign = true;
							}
							//auch als Bearbeiter erlaubt
							if (tsx.isAssignedToUser(model.curUserId))
								mayReAssign = true;
						}
					}
					if (!mayReAssign)
						$("#selectUserGroup").prop("disabled", true);

					//Namen dazu
					that.updateUserNames();
				}

			},

			clearDynamicDataFields: function () {
				$("#formTaskEdit .form-body .dynDataBox").remove();
			},

			//Dynamische Felder anzeigen
			addDynamicDataField: function (field, value) {

				$("#formTaskEdit .form-body>div").each(function (index) {

					var el = $(this);
					var sortOrder = parseInt(el.attr("data-order-index"));
					if (field.orderIndex < sortOrder) {

						var placeholder = field.placeholder || "";

						var s = '';
						s += '<div id="' + field.getDomId() + 'Box" class="form-group dynDataBox" data-order-index="' + field.orderIndex + '">';
						s += '	<label class="col-md-3 control-label">' + field.name + ' <span class="required">';

						if (field.isRequired === 1)
							s += '*';

						s += '</span></label>';
						s += '	<div class="col-md-9">';
						s += '	    <input id="' + field.getDomId() + '" type="text" maxlength="255" class="form-control" placeholder="' + placeholder + '"/>';

						//Help Block
						if (field.hint)
							s += '<div class="help-block">' + field.hint + '</div>';

						if (field.isRequired === 1)
							s += '<span id="' + field.getDomId() + '-error" style="display:none;" class="help-block help-block-custom">Dieses Feld ist ein Pflichtfeld.</span>';

						s += '	</div>';
						s += '</div>';

						el.before(s);

						if (value)
							$("#formTaskEdit #" + field.getDomId()).val(value);

						return false;
					}

				});

			},

			//high-level: Editor mit Antrag o Auftrag anzeigen
			showTaskEditor: function (obj, callbackComplete) {

				var that = this;

				that.hasAttachmentsChanged = false;
				model.curTaskOrRequest = obj;

				//reset
				this.resetInterval(true);
				this.prevIntervalType = "";

				var t = model.curTaskOrRequest || model.curCloneTaskOrRequest;
				if (this.isRequest) {
					if (t && t.isArchived())
						viewManager.setSelectedMenuItem('menuRequests', 'menuRequestsArchive');
					else
						viewManager.setSelectedMenuItem('menuRequests', 'menuRequestsActive');
				}
				else {
					if (t && t.isArchived())
						viewManager.setSelectedMenuItem('menuTasks', 'menuTasksArchive');
					else
						viewManager.setSelectedMenuItem('menuTasks', 'menuTasksActive');
				}

				//neu hochgeladene Dateien
				model.curSessionUploads = [];
				//alle Attachments
				model.curAttachments = [];

				$("#content").html(window.templates['taskEdit.html']);
				setTimeout(function () {

					if (that.isRequest)
						$("#lblTaskType").html('Antragstyp <span class="required">* </span>');
					else
						$("#lblTaskType").html('Auftragstyp <span class="required">* </span>');

					if (that.isRequest) {
						$("#breadcrumbTaskEdit1").html("Antr&auml;ge");
					}
					else {
						$("#breadcrumbTaskEdit1").html("Auftr&auml;ge");
					}
                    $("#breadcrumbTaskEdit2").html("Bearbeiten");


                    //default: nicht speicherbar
					$(".btnSaveTask").hide();

					$("#chkTaskIntervalStatus").bootstrapSwitch({
						onText: '<i class="fa fa-fw fa-check"></i>',
						offText: '<i class="fa fa-fw fa-times"></i>',
						size: "mini",
						onSwitchChange: function (event, newState) {
							viewManager.setFormDirty();
						}
					});

					//neu
					if (model.curTaskOrRequest === null) {

						//title
						if (that.isRequest)
							$("#taskHeader").html("Neuer Antrag");
						else
							$("#taskHeader").html("Neuer Auftrag");
						that.showIntervalStatusBox(false);
						$("#btnTaskDelete").hide();

						//clone task/request
						if (model.curCloneTaskOrRequest) {
							setTimeout(function () {

								that.ignoreTreeUpdate = true;
								$("#selectTaskType").val(model.curCloneTaskOrRequest.taskTypeId).trigger("change");
								that.ignoreTreeUpdate = false;
								$('#selectTaskType').prop('disabled', 'disabled');

								that.fillTaskEditor(model.curCloneTaskOrRequest);
								that.initIntervalDisplay(model.curCloneTaskOrRequest);

								$(".btnSaveTask").show();
								model.curCloneTaskOrRequest = null;
								viewManager.setFormDirty();
								that.updateEditor(false, false, true);

							}, 50);
						}

					}
					else {

						//aus Request übernehmen
						if (!that.isRequest && (model.curTaskOrRequest.requestId > 0) && (model.curTaskOrRequest.id < 0)) {

							$("#taskHeader").html("Neuen Auftrag aus Antrag erzeugen");
							that.showIntervalStatusBox(false);
							$("#btnTaskDelete").hide();

							model.curTaskOrRequest.updateAttachments();
							model.curAttachments = model.curTaskOrRequest.getAttachments() || [];

							setTimeout(function () {

								that.ignoreTreeUpdate = true;
								$("#selectTaskType").val(model.curTaskOrRequest.taskTypeId).trigger("change");
								that.ignoreTreeUpdate = false;
								$('#selectTaskType').prop('disabled', 'disabled');

								that.fillTaskEditor(model.curTaskOrRequest);
								that.initIntervalDisplay(model.curTaskOrRequest);

								//responsible
								var tt = TaskType.getTaskType(parseInt(model.curTaskOrRequest.taskTypeId, 10));
								that.updateResponsible(tt.typeId, model.curTaskOrRequest.locationType);
								$("#selectResponsible").val(model.curUserId).trigger("change");

								$(".btnSaveTask").show();
								viewManager.setFormDirty();
								that.updateEditor(false, false, true);

							}, 50);
						}
						else {

							//existing Task/Request

							$(".btnSaveTask").show();

							model.curTaskOrRequest.updateAttachments();
							model.curAttachments = model.curTaskOrRequest.getAttachments() || [];

							//title
							if (that.isRequest)
								$("#taskHeader").html("Antrag #" + model.curTaskOrRequest.requestId + " bearbeiten");
							else
								$("#taskHeader").html("Auftrag #" + model.curTaskOrRequest.taskId + " bearbeiten");

							if (!that.isRequest) {

								//status
								that.updateTaskEditIntervalStatus(model.curTaskOrRequest);

								//interval hint
								if (model.curTaskOrRequest.isInterval() && (model.curTaskOrRequest.taskId > 0))
									$("#hintInterval").show();
								else
									$("#hintInterval").hide();
							}

							that.fillTaskEditor(model.curTaskOrRequest);
						}

					}

					//---------

					that.updateIntervalDueDate();

					if (that.isRequest)
						$("#btnTaskDelete").hide();

					//task types
					var options = that.updateTaskTypes();
					if (model.curTaskOrRequest) {
						if (model.curTaskOrRequest.id > 0) {
							$("#selectTaskType").val(model.curTaskOrRequest.taskTypeId).trigger("change");
							$('#selectTaskType').prop('disabled', 'disabled');
						}
					}
					else {
						if (options.length === 1) {
							$("#selectTaskType").val(options[0]).trigger("change");
						}
					}

					//intervals
					var sel = "";

					sel += "<option value=''>Ohne</option>";
					sel += "<option value='D'>Jeden Tag</option>";
					sel += "<option value='W'>Jede Woche</option>";
					sel += "<option value='M'>Jeden Monat</option>";
					//sel += "<option value='Q'>Jedes Quartal</option>";
					sel += "<option value='Y'>Jedes Jahr</option>";
					sel += "<option value='X'>Eigenes ...</option>";
					$("#selectInterval").html(sel).select2();
					$('#selectInterval').prop('disabled', false);
					if (model.curTaskOrRequest)
						if (model.curTaskOrRequest.id > 0) {
							that.initIntervalDisplay(model.curTaskOrRequest);
						}

					//init datepickers
					//noinspection JSUnresolvedFunction
					$('.date-picker').datepicker({
						autoclose: true,
						format: "dd.mm.yyyy",
						weekStart: 1,
						maxViewMode: 2,
						todayBtn: "linked",
						language: "de",
						daysOfWeekHighlighted: "0,6",
						calendarWeeks: true,
						todayHighlight: true
					})
						.on("change", that.onDueDateChanged)
						.on("hide", that.onDueDateLeave);

					autosize($('textarea'));

					//attachments
					setupFileUploader($("#taskEditAttachmentBox .fileuploadBox"), that.onTaskAttachmentUploaded, constants.MEDIA_TYPE_IMAGE_PDF);
					that.showTaskAttachments("#taskAttachmentTable", model.curAttachments, true);

					//warum auch immer erst mit Timeout?!
					setTimeout(function () {
						$("#selectInterval").on("change", that.onChangeInterval);
						//$("#selectUserGroup").on("change", that.updateUserNames);
						$("#selectUserGroup").on("select2:close", that.updateUserNames);
						$(".btnSaveTask").on("click", that.saveTask);
						$("#btnBackTask").on("click", function () {
							model.curTaskListView.preserveCurPage = true;
							window.history.back();
						});
						$(".btnCancelTask").off("click").on("click", function () {
							that.removeUnsavedUploads();
							if (model.curTaskOrRequest)
								if ((model.curTaskOrRequest.id < 0) && (model.curTaskOrRequest.requestId > 0)) {
									viewManager.showPage(constants.PAGE_REQUEST_DETAILS, {
										requestId: model.curTaskOrRequest.requestId
									}, true, true, true);
									return false;
								}

							//default
							model.curTaskListView.preserveCurPage = true;
							window.history.back();

						});
						$('#treeLocation').on('activate_node.jstree', function (e, data) {
							viewManager.setFormDirty();

							that.updateObjectCount();

							//wenn ausgewählt, alles darunter ausklappen
							if (data.node.state.selected){
								var locationId = parseInt(data.node.id);
								var location = Location.getLocation(locationId, null, false, false, true);

								//Gruppe?
								if (location.isAbstract === 1){

									//selbst
									$('#treeLocation').jstree("open_node", locationId);

									//alle darunter befindlichen Objekte holen
									var descendants = Location.getDescendants(location);

									//alle Gruppen aufklappen
									for(var i=0; i < descendants.length; i++) {
										if (descendants[i].isAbstract === 1) {
											$('#treeLocation').jstree("open_node", descendants[i].locationId);
										}
									}
								}
							}
						});

						if (callbackComplete)
							callbackComplete();
					}, 500);

					//update components
					that.updateEditor(false, false, false);
					that.updateObjectCount();
					viewManager.updateLayout();
					viewManager.updateButtons();

				}, constants.TEMPLATE_DELAY);
			},

			//--------------------------------------------------------------

			//Anzahl ausgewählter Objekte aktualisieren
			updateObjectCount: function(){
				var selectedElements = $('#treeLocation').jstree("get_selected", true);

				//keine Folder speichern
				var count = 0;
				var firstLocationName = "";
				for (var i=0; i<selectedElements.length; i++) {
					var loc = Location.getLocation(selectedElements[i].data, undefined, undefined, undefined, true);
					if (loc) {
						if (loc.isAbstract === 0) {
							count++;
							if (firstLocationName === "")
								firstLocationName = loc.getName(true);
						}
					}
				}

				var el = $(".treeInfoBox");
				if (count === 0)
					el.html('<span class="text-muted">Kein Objekt ausgewählt</span>');
				else{

					var s = '<span class="font-semibold">' + pg.getStemmedName(count, "Objekt", "Objekte") + ' ausgewählt</span><br/>';
					var details = firstLocationName;
					if (count > 1) {
						var remaining = count-1;
						details += " <em>+ " + pg.getStemmedName(remaining, "weiteres", "weitere") + "</em>";
					}
					s += '<span class="text-muted small">' + details + '</span>';
					el.html(s);
				}
			},

			//--------------------------------------------------------------

			//Auftragstyp wurde geändert
			onTaskTypeSelected: function () {
				taskRequestEditorManager.updateEditor(true, false, false);
			},

			//Objekttyp wurde geändert
			onLocationTypeSelected: function () {

				var that = taskRequestEditorManager;

				if (that.ignoreLocationTypeChangeEvent) {
					that.ignoreLocationTypeChangeEvent = false;
					return;
				}

				that.updateEditor(false, true, false);
			},

			//Anzeige der Auftragstypen aktualisieren
			updateTaskTypes: function () {

				var sel = "";
				sel += '<option value="" disabled="disabled" selected>Bitte w&auml;hlen...</option>';
				model.taskTypes.sort(function (a, b) {
					return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
				});
				var options = [];

				for (var i = 0; i < model.taskTypes.length; i++) {

					var tt = model.taskTypes[i];

					if (this.isRequest)
						if (tt.isRequestable !== 1)
							continue;

					//nur diejenigen anzeigen, die User verwenden darf (direkt + mind. ein O-Typ davon)
					if (!model.curUser.hasTaskType(tt.typeId))
						continue;

					//Objekt-Eingabe erforderlich?
					if (tt.editLocationType === constants.TASK_EDITOR_MANDATORY) {

						//gibt es einen passenden Objekttyp?
						var locationTypes = model.locationTypes;
						var hasLocationType = false;
						for (var j = 0; j < locationTypes.length; j++) {
							var l = locationTypes[j];

							//passt zu taskType?
							if (!tt.hasLocationType(l.id))
								continue;

							//darf sehen?
							if (!(model.curUser.hasLocationType(LocationType.LOCATION_TYPE_ALL) || model.curUser.hasLocationType(l.id)))
								continue;

							//existiert überhaupt ein Objekt?
							var locationTypeIds = [l.id];
							var tree = model.locationTree.getJstreeData(true, false, locationTypeIds, true, false);
							if (!tree.data)
								continue;

							hasLocationType = true;
							break;
						}

						//es müsste, kann aber kein Objekt angegeben werden
						if (!hasLocationType)
							continue;
					}

					options.push(tt.typeId);
					sel += "<option value='" + tt.typeId + "'>" + tt.name + "</option>";
				}
				$("#selectTaskType").html(sel).select2();
				$('#selectTaskType').prop('disabled', false);

				return options;
			},

			//Anzeige der Objekttypen aktualisieren
			updateLocationTypes: function (taskTypeId) {

				taskTypeId = parseInt(taskTypeId);

				//mögliche LocationTypes ermitteln und setzen
				var tt = TaskType.getTaskType(taskTypeId);
				var locationTypes = this.getValidLocationTypes(tt);

				var sel = "";
				sel += "<option value='-1000' disabled selected style='display:none;'>Bitte w&auml;hlen...</option>";
				if (tt.editLocationType === constants.TASK_EDITOR_OPTIONAL)
					sel += "<option value='-1'>Ohne</option>";
				for (var i = 0; i < locationTypes.length; i++) {
					var l = locationTypes[i];

					//gibt es mind. ein Objekt diesen Typs?
					if (Location.hasLocationsOfType(l.id))
						sel += "<option value='" + l.id + "'>" + l.name + " (" + l.abbrev + ")</option>";
				}
				$("#selectLocationType").html(sel).select2();
			},

			//gültige Objekttypen ermitteln
			getValidLocationTypes: function(tt){

				var lTypes = [];

				//mögliche LocationTypes ermitteln und setzen
				var locationTypes = model.locationTypes;
				//sortieren nach name
				locationTypes.sort(function (a, b) {
					return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
				});
				for (var i = 0; i < locationTypes.length; i++) {
					var l = locationTypes[i];

					//passt zu taskType?
					if (!tt.hasLocationType(l.id))
						continue;

					//darf sehen?
					if (!(model.curUser.hasLocationType(LocationType.LOCATION_TYPE_ALL) || model.curUser.hasLocationType(l.id)))
						continue;

					//Objekt-Eingabe erforderlich?
					if (tt.editLocationType === constants.TASK_EDITOR_MANDATORY) {
						//existiert überhaupt ein Objekt?
						var locationTypeIds = [l.id];
						var tree = model.locationTree.getJstreeData(true, false, locationTypeIds, true, false);
						if (!tree.data)
							continue;
					}

					lTypes.push(l);
				}

				return lTypes;
			},

			//--------------------------------------------------------------

			getSelectedLocationType: function(){
				var sel = $("#selectLocationType").val();
				if (sel)
					return parseInt(sel);
				return -1;
			},

			//versch. Eingabefelder je nach A-/O-Typ anzeigen
			//taskTypeHasChanged: Auftragstyp wurde ausgewählt
			//locationTypeHasChanged: Objekttyp wurde ausgewählt
			updateEditor: function (taskTypeHasChanged, locationTypeHasChanged, isClone) {

				if (taskTypeHasChanged === undefined)
					taskTypeHasChanged = false;
				if (locationTypeHasChanged === undefined)
					locationTypeHasChanged = false;
				if (isClone === undefined)
					isClone = false;

				//aufräumen
				this.removeErrors();

				var sel = $("#selectTaskType").val();
				var tt = TaskType.getTaskType(parseInt(sel, 10));
				if (!tt) {
					//alles verstecken
					$(".dynamicTaskEditorComponent").addClass("isHidden");
					return;
				}

				//jetzt schon DynData etc. anzeigen?
				var mayShowEverything = false;
				var validLocationTypes = this.getValidLocationTypes(tt);
				var validLocationTypeCount = validLocationTypes.length;
				if (tt.editLocationType > constants.TASK_EDITOR_HIDDEN) {

					//"Ohne"
					if (tt.editLocationType === constants.TASK_EDITOR_OPTIONAL)
						validLocationTypeCount++;

					if (locationTypeHasChanged)
						mayShowEverything = (this.getSelectedLocationType() > 0);
					else{
						//nur ein Objekttyp?
						mayShowEverything = (validLocationTypeCount <= 1);
					}
				}
				else
					mayShowEverything = taskTypeHasChanged;

				//falls bereits existierend, dann bestehende Daten anzeigen
				if (model.curTaskOrRequest)
					mayShowEverything = true;

				//----------------------------------
				//dynamische Felder

				if (mayShowEverything) {
					this.clearDynamicDataFields();
					var ttFields = TaskTypeField.getTaskTypeFields(tt.typeId);
					for (var i = 0; i < ttFields.length; i++) {

						var ttf = ttFields[i];
						var dd, ddValue = null;

						if (model.curTaskOrRequest) {
							dd = model.curTaskOrRequest.getDynDataObj(ttf.id);
							ddValue = (dd) ? ttf.getFormattedValue(dd.value) : "";
						}
						else {
							if (model.curCloneTaskOrRequest) {
								dd = model.curCloneTaskOrRequest.getDynDataObj(ttf.id);
								ddValue = (dd) ? ttf.getFormattedValue(dd.value) : "";
							}
						}
						this.addDynamicDataField(ttf, ddValue);
					}
				}
				else{
					if (taskTypeHasChanged)
						this.clearDynamicDataFields();
				}

				//LocationType
				var hasLt = false;
				var optionalLocationTypeCount = 0;
				if (tt.editLocationType > constants.TASK_EDITOR_HIDDEN) {

					//manuell geändert?
					if (taskTypeHasChanged) {
						//Wert hat sich geändert -> reset
						$(".dynamicTaskEditorComponent").addClass("isHidden");

						this.updateLocationTypes(tt.typeId);
						$("#locationTypeBox").removeClass("isHidden");

						var firstLocationType = -1;
						//for (var k=0; k<tt.locationTypeIds.length; k++){
						for (var k=0; k<validLocationTypes.length; k++){
							//if (model.curUser.hasLocationType(tt.locationTypeIds[k])) {
								//firstLocationType = tt.locationTypeIds[k];
							firstLocationType = validLocationTypes[k].id;
								optionalLocationTypeCount++;
							//}
						}
						//Bearbeiten von Entities mit gewähltem Objekttyp
						if (model.curTaskOrRequest)
							hasLt = model.curTaskOrRequest.locationType > 0;
						else{
							//gibt es nur einen gültigen? dann anzeigen
							if (validLocationTypeCount === 1) {
								hasLt = true;
							}
						}
						if (hasLt || (optionalLocationTypeCount === 1 && (tt.editLocationType !== constants.TASK_EDITOR_OPTIONAL))) {
							//Vorauswahl
							//this.ignoreLocationTypeChangeEvent = true;
							$("#selectLocationType").val(firstLocationType).trigger("change");
						}
						else {
							//User muss selber wählen
							$("#selectLocationType").val(-1000).trigger("change");
							this.showLocationBox(false);
							return;
						}
					}
					else {
						if (model.curTaskOrRequest) {
							this.ignoreLocationTypeChangeEvent = true;
							$("#selectLocationType").val(model.curTaskOrRequest.locationType).trigger("change");

							//bestehende Objektwahl wieder anzeigen
							if (this.isRequest) {
								if (model.curTaskOrRequest.locations.length > 0)
									this.showLocationBox(true);
							}
						}
						else {
							if (!$("#selectLocationType").val()) {
								this.ignoreLocationTypeChangeEvent = true;
								$("#requestDeciderBox").hide();
								$("#selectLocationType").val(-1000).trigger("change");
								return;
							}
						}
						$("#locationTypeBox").removeClass("isHidden");
					}

					if (locationTypeHasChanged)
						if (!model.curTaskOrRequest)
							if (validLocationTypeCount >= 1)
								this.showLocationBox(true);
				}
				else {
					$("#locationTypeBox").addClass("isHidden");
					this.showLocationBox(false);
				}

				//------------------------------------------
				//update properties (erst jetzt, wenn TaskType + LocationType bekannt sind)

				$(".btnSaveTask").show();

				//new Task
				if ((model.curTaskOrRequest === null) && (!isClone)) {

					//Standard-Intervall
					this.resetInterval(true);

					//Baum
					var locationType = this.getSelectedLocationType();
					if (locationType < 0)
						locationType = -1;
					//nur aktualisieren, wenn nicht versteckt
					if (tt.editLocationType > constants.TASK_EDITOR_HIDDEN) {
						if (!this.ignoreTreeUpdate)
							this.updateLocationTree(locationType);
					}

					//decider
					if (this.isRequest) {
						this.updateDecider();
					}
					else {
						//Bearbeiter
						this.updateUserGroups(tt.typeId, locationType);
					}

					//responsible
					this.updateResponsible(tt.typeId, locationType);
					$("#selectResponsible").val(model.curUserId).trigger("change");
					if (!$("#selectResponsible").val())
						$("#selectResponsible").val(-1).trigger("change");

					//title
					if (tt.editTitle === constants.TASK_EDITOR_MANDATORY)
						$("#taskTitle").val("");
					if (tt.editTitle === constants.TASK_EDITOR_OPTIONAL)
						$("#taskTitle").val(tt.name);
				}

				//------------------------------------------
				//alle anderen Elemente nun anzeigen

				//title
				this.updateInputDisplay(tt.editTitle, $("#titleBox"));
				//desc
				this.updateInputDisplay(tt.editDescription, $("#descBox"));
				//locations
				this.updateInputDisplay(tt.editLocations, $("#locationBox"));
				//due date
				this.updateInputDisplay(tt.editDueDate, $("#dateBox"));
				//interval
				this.updateInputDisplay(tt.editInterval, $("#intervalBox"));
				//end date
				this.updateInputDisplay(tt.editIntervalEnd, $("#endDateBox"));
				//Bearbeiter
				this.updateInputDisplay(tt.editAssignee, $("#taskAssignmentBox"));
				//owner
				this.updateInputDisplay(tt.editOwner, $("#taskResponsibleBox"));
				//attachments
				this.updateInputDisplay(tt.editAttachments, $("#taskAttachmentBox"));

				//Verantw. nicht verpflichtend (es sei denn... s.u.)
				$("#iconRequestResponsibleRequired").hide();

				//abh. vom Typ
				if (this.isRequest) {
					this.showIntervalStatusBox(false);
					$("#taskAssignmentBox").hide();
					$("#requestDeciderBox").show();

					if (model.client.autoReleaseRequests === 1) {
						$("#iconRequestResponsibleRequired").html("*").show();
					}
				}
				else {
					$("#requestDeciderBox").hide();
				}

				this.updateIntervalDueDate();
				this.updateUserNames();
			},

			showLocationBox: function(vis){

				if (vis)
					$("#locationBox").show();
				else
					$("#locationBox").hide();
			},

			showIntervalStatusBox: function(vis){
				if (vis)
					$("#lblIntervalStatus").show();
				else
					$("#lblIntervalStatus").hide();
			},

			//einzelne Felder dynamisch umschalten, je nach Typ Pflicht, Normal oder Versteckt
			updateInputDisplay: function (editValue, inputBox) {
				if (editValue === constants.TASK_EDITOR_HIDDEN) {
					inputBox.addClass("isHidden");
				}
				else {
					inputBox.removeClass("isHidden");

					//required?
					if (editValue === constants.TASK_EDITOR_MANDATORY)
						$(".required", inputBox).html("*");
					else
						$(".required", inputBox).html("");
				}
			},

			//Location-Ordner neu anzeigen
			updateLocationTree: function (locationType, callback) {
				var treeData = [];
				var locationTypeIds = [locationType];
				var tree = {
					status: -1000,
					data: null
				};

				if (locationType > -1)
					tree = model.locationTree.getJstreeData(true, false, locationTypeIds, true, false);
				if (tree.status >= 0) {
					if (tree.data)
						treeData = tree.data.children;
					this.showLocationBox(true);
				}
				else {
					this.showLocationBox(false);
				}

				//update
				$('#treeLocation').jstree({
					'plugins': ["checkbox", "types"],
					'core': {
						"themes": {
							"responsive": false
						}
					},
					"types": {
						"default": {
							"icon": "fa fa-folder icon-state-darkgrey"
						},
						"file": {
							"icon": "fa fa-map-marker icon-state-primary"
						}
					}
				});

				//noinspection JSUnresolvedVariable
				$('#treeLocation').jstree(true).settings.core.data = treeData;
				$('#treeLocation').jstree(true).refresh();

				//normale JsTree-Events funktionieren nicht(?!)
				if (treeData)
					if (treeData.length) {
						var anyId = treeData[0].id;
						var interval_id = setInterval(function () {
							// $("li#"+id).length will be zero until the node is loaded
							if ($("#treeLocation li#" + anyId).length !== 0) {
								// "exit" the interval loop with clearInterval command
								clearInterval(interval_id);
								if (callback)
									callback();
							}
						}, 5);
					}
			},

			//Liste mögl. Benutzergruppen aktualisieren (Bearbeiter)
			updateUserGroups: function (taskTypeId, locationType) {

				if (taskTypeId > 0) {
					var sel = "";
					sel += '<option value="">Bitte ausw&auml;hlen...</option>';
					var uGroups = UserGroup.getActiveUserGroups(null, null, true, taskTypeId, [], null, locationType);

					//zunächst User
					var hasOptGroup = false;
					for (var i = 0; i < uGroups.length; i++) {
						if (uGroups[i].isSingleUser) {
							if (!hasOptGroup) {
								hasOptGroup = true;
								sel += '<optgroup label="Benutzer">';
							}
							sel += "<option value='" + uGroups[i].id + "'>" + uGroups[i].name + "</option>";
						}
					}
					if (hasOptGroup)
						sel += '</optgroup>';

					hasOptGroup = false;
					for (i = 0; i < uGroups.length; i++) {
						if (!uGroups[i].isSingleUser) {
							if (!hasOptGroup) {
								hasOptGroup = true;
								sel += '<optgroup label="Teams">';
							}
							sel += "<option value='" + uGroups[i].id + "'>" + uGroups[i].name + "</option>";
						}
					}
					if (hasOptGroup)
						sel += '</optgroup>';

					$("#selectUserGroup").html(sel).select2({
						placeholder: 'Bitte auswählen ...'
					});
				}
			},

			//Liste mögl. Verantwortlicher aktualisieren
			updateResponsible: function (taskTypeId, locationType) {
				var sel = "";
				sel += '<option value="-1" disabled="disabled">Bitte w&auml;hlen...</option>';
				var uGroups = UserGroup.getActiveUserGroups(null, null, true, taskTypeId, [], null, locationType, true);
				for (var i = 0; i < uGroups.length; i++) {
					if (uGroups[i].isSingleUser) {
						var u = uGroups[i].getSingleUser();

						//muss überführen dürfen
						var mayContinue = true;

						if (this.isRequest)
							if (!authManager.hasUserPermission(u, UserRole.PERMISSION_REQUEST_CONVERT_RELEASED_TO_TASK_ALL, UserRole.PERMISSION_REQUEST_CONVERT_RELEASED_TO_TASK_ASSIGNED))
								mayContinue = false;
						if (mayContinue)
							sel += "<option value='" + u.id + "'>" + u.getName() + "</option>";
					}
				}

				$("#selectResponsible").html(sel).select2();

			},

			//Liste mögl. Entscheider aktualisieren
			updateDecider: function () {

				if (model.client.autoReleaseRequests === 1) {
					$("#selectDecider").hide();
					$("#iconRequestRequired").hide();
					$("#lblAutoRelease").show();
				}
				else {
					$("#selectDecider").show();
					$("#iconRequestRequired").show();
					$("#lblAutoRelease").hide();

					var curSelectedLocationType = this.getSelectedLocationType();

					var sel = "";
					sel += '<option value="">Bitte w&auml;hlen...</option>';
					var users = User.getActiveUsers();
					users.sort(function (a, b) {
						return ((a.getName() < b.getName()) ? -1 : ((a.getName() > b.getName()) ? 1 : 0));
					});
					for (var i = 0; i < users.length; i++) {
						var u = users[i];
						//if (u.id !== model.curUserId)
							if (authManager.hasUserPermission(u, UserRole.PERMISSION_REQUEST_EXECUTE_ALL, UserRole.PERMISSION_REQUEST_EXECUTE_ASSIGNED)) {

								//passen die Objektberechtigungen?
								var isValidDecider = false;
								if (curSelectedLocationType > 0) {
									if (u.hasLocationType(curSelectedLocationType))
										isValidDecider = true;
								}
								else{
									isValidDecider = true;
								}
								if (isValidDecider)
									sel += "<option value='" + u.id + "'>" + u.getName() + "</option>";
							}
					}

					$("#selectDecider").html(sel).select2();
				}
			},

			//----------------------------------------------------------------------------------------

			//Anhang eines Auftrages wurde hochgeladen
			onTaskAttachmentUploaded: function (file) {

				var that = taskRequestEditorManager;

				//anhängen an Task
				//var now = new Date();
				/*var att = Attachment.createAttachmentRaw();
				att.type = constants.ENTITY_TYPE_TASK;
				if (that.isRequest)
					att.objId = model.curTaskOrRequest ? model.curTaskOrRequest.taskId : -1;
				else
					att.objId = model.curTaskOrRequest ? model.curTaskOrRequest.requestId : -1;
				att.id = file.id;
				att.name = file.name;
				att.setSize(file.size);
				att.url = file.url;
				att.width = file.width;
				att.height = file.height;*/

				var objId;
				if (that.isRequest)
					objId = model.curTaskOrRequest ? model.curTaskOrRequest.requestId : -1;
				else
					objId = model.curTaskOrRequest ? model.curTaskOrRequest.taskId : -1;

				var att = Attachment.createAttachmentRaw().fromObj({
					entityType: constants.ENTITY_TYPE_TASK,
					entityId: objId,

					name: file.name,
					size: file.size,
					file: file.url,
					width: file.width,
					height: file.height,

					clientId: model.curClientId,
					status: constants.ATTACHMENT_STATUS_ON_SERVER,
					fileType: pg.getFiletype(file.url)
				});
				Attachment.replaceOrPushObj(model.curAttachments, att);
				Attachment.replaceOrPushObj(model.attachments, att);

				model.curSessionUploads.push(att);

				that.hasAttachmentsChanged = true;
				viewManager.setFormDirty();

				viewManager.setFormDirty();

				that.showTaskAttachments("#taskAttachmentTable", model.curAttachments, true);
			},

			//Auftrags-Anhänge anzeigen
			showTaskAttachments: function (trgTableId, attachments, allowEditing) {

				var s = "";
				var proceed = true;
				if (!attachments) {
					proceed = false;
				}
				else if (attachments.length === 0) {
					proceed = false;
				}

				if (proceed) {

					//Inhalte
					for (var i = 0; i < attachments.length; i++) {
						var att = attachments[i];

						s += "<div class='vCenterJustifyBox w100'>";

						//Image/Icon
						s += '<div class="vCenterLeftBox">';
						s += '<a href="' + att.getUrl(false) + '"';
						if (att.isImage())
							s += ' class="fancybox fancybox-type-image noUnderline" rel="group1">';
						else
							s += ' data-fancybox-type="iframe" class="fancybox fancybox-type-iframe noUnderline">';

						s += '<div class="mr10">';
						if (att.isImage()) {
							s += '<div class="previewImage" style="background-image:url(' + att.getPreviewImage(model.curTaskOrRequest) + ');" />';
						}
						else {
							s += '<div class="previewImage vCenterBox bgLightestGray"><i class="icon fa fa-file-pdf-o bigIcon"></i></div>';
						}
						s += '</div>';
						s += '</a>';

						//Name
						s += '<div>';
						s += '<span class="fgDarkGray bold">' + att.name + '</span><br/><span class="fgLightGray noUnderline">' + pg.getAbbrevFileSize(att.size) + '</span></a>';
						s += '</div>';
						s += '</div>';

						s += '<div class="vCenterRightBox">';

						//download
						//Zugriff nur noch mit Session-Prüfung
						s += '<div class="mr30"><a target="_blank" href="' + att.getUrl(true) + '" class="fgDarkGray"><i class="fa fa-download" aria-hidden="true"></i> Download</a></div>';

						//delete
						s += '<div class="">';
						if (allowEditing) {

							var mayEdit = false;
							if (this.isRequest) {

								if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_ALL)) {
									mayEdit = true;
								}
								else {

									if (model.curTaskOrRequest) {
										if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_OWN)) {
											if (model.curTaskOrRequest.ownerId === model.curUserId)
												mayEdit = true;
										}
										else {
											if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_BY_TASK_TYPE)) {
												if (model.curUser.hasTaskType(model.curTaskOrRequest.taskTypeId))
													mayEdit = true;
											}
										}
									}
									else {
										//gerade neu erzeugt
										mayEdit = true;
									}
								}

							}
							else {

								if (authManager.hasPermission(UserRole.PERMISSION_TASK_EDIT_ALL)) {
									mayEdit = true;
								}
								else {

									if (model.curTaskOrRequest) {
										if (authManager.hasPermission(UserRole.PERMISSION_TASK_EDIT_OWN)) {

											if (model.curTaskOrRequest.ownerId === model.curUserId)
												mayEdit = true;
											var ts = model.curTaskOrRequest.getCurrentState();
											if (ts.responsibleId === model.curUserId)
												mayEdit = true;
										}
										if (authManager.hasPermission(UserRole.PERMISSION_TASK_EDIT_BY_TASK_TYPE)) {
											if (model.curUser.hasTaskType(model.curTaskOrRequest.taskTypeId))
												mayEdit = true;
										}
									}
									else {
										//gerade neu erzeugt
										mayEdit = true;
									}
								}
							}

							if (mayEdit)
								s += '<a data-attId="' + att.id + '" class="fgDarkGray  btnDeleteAttachment"><i class="fa fa-remove" aria-hidden="true"></i> L&ouml;schen</a>';
						}
						else
							s += '&nbsp;';
						s += '</div>';

						s += '</div>';

						s += '</div>';
					}

					$(trgTableId).html(s);

					//Typ für Bilder und PDFs verschieden setzen
					//http://www.fancyapps.com/fancybox/
					$(trgTableId + " .fancybox-type-image").fancybox({
						openEffect: 'none',
						closeEffect: 'none',
						type: 'image'
					});
					$(trgTableId + " .fancybox-type-iframe").fancybox({
						openEffect: 'none',
						closeEffect: 'none',
						type: 'iframe',
						iframe: {
							preload: false
						}
					});

					$("#taskAttachmentTableBox").show();

					$(".btnDeleteAttachment").off("click").on("click", this.deleteTaskAttachment);
				}
				else {
					$("#taskAttachmentTableBox").hide();
				}


			},

			//nicht gespeicherte Anhänge wieder vom Server entfernen
			removeUnsavedUploads: function () {
				for (var i = 0; i < model.curSessionUploads.length; i++) {
					var att = model.curSessionUploads[i];
					dataManager.deleteAttachment(att.url);
				}
			},

			//Anhang vom Server entfernen
			deleteTaskAttachment: function (e) {

				var that = taskRequestEditorManager;

				if (e) {

					pg.confirmMsg("L&ouml;schen", "Möchten Sie dieses Dokument wirklich l&ouml;schen?", function (result) {

						if (result) {

							var link = $(e.currentTarget);
							if (link.attr("data-attId")) {
								var attId = parseInt(link.attr("data-attId"), 10);
								for (var i = 0; i < model.curAttachments.length; i++) {
									var att = model.curAttachments[i];
									if (Number(att.id) === attId) {
										model.curAttachments.splice(i, 1);
										that.hasAttachmentsChanged = true;
										viewManager.setFormDirty();
										break;
									}
								}
							}
							taskRequestEditorManager.showTaskAttachments("#taskAttachmentTable", model.curAttachments, true);
						}
					});
				}
				e.preventDefault();
			},

			//-----------------------------------------------------

			setTaskIntervalStatus: function(status){
				var state = (status === constants.STATUS_OBJECT_ACTIVE);
				$("#chkTaskIntervalStatus").bootstrapSwitch('state', state, false);
			},
			getTaskIntervalStatus: function(){
				var val = $('#chkTaskIntervalStatus').bootstrapSwitch('state');
				if (val)
					return constants.STATUS_OBJECT_ACTIVE;
				else
					return constants.STATUS_OBJECT_INACTIVE;
			},

			//IntervalStatus (aktiv/...) aktualisieren
			updateTaskEditIntervalStatus: function (task, forceStatus) {

				if (task && task.isInterval()) {

					var ivStatus = task.intervalStatus;
					if (forceStatus !== undefined)
						ivStatus = forceStatus;

					this.setTaskIntervalStatus(ivStatus);
					/*var st = "";
					if (ivStatus === constants.STATUS_OBJECT_ACTIVE) {
						st += '<span class="label label-success pull-left mr20 currentTaskIntervalStatus" data-status="' + constants.STATUS_OBJECT_ACTIVE + '">aktiv</span>';
						st += '<button id="btnTaskDeactivate" type="button" class="btn btn-sm default btnHasSpinner ladda-button" data-style="zoom-in"> Deaktivieren</button>';
					}
					else {
						st += '<span class="label label-danger pull-left mr20 currentTaskIntervalStatus" data-status="' + constants.STATUS_OBJECT_INACTIVE + '">deaktiviert</span>';
						st += '<button id="btnTaskActivate" type="button" class="btn btn-sm default btnHasSpinner ladda-button " data-style="zoom-in"> Aktivieren</button>';
					}
					$("#intervalStatusBox").html(st);*/


					viewManager.updateButtons();
					/*$("#btnTaskDeactivate").off("click").on("click", this.deactivateTaskInterval);
					$("#btnTaskActivate").off("click").on("click", this.activateTaskInterval);*/

					this.showIntervalStatusBox(true);
				}
				else {
					this.showIntervalStatusBox(false);
				}

				var mayDelete = false;
				if (task) {
					if (task.id > 0) {
						if (authManager.hasPermission(UserRole.PERMISSION_TASK_DELETE_ALL)) {
							mayDelete = true;
						}
						else {
							if (authManager.hasPermission(UserRole.PERMISSION_TASK_DELETE_OWN)) {
								if (task.ownerId === model.curUserId)
									mayDelete = true;
								var ts = task.getCurrentState();
								if (ts.responsibleId === model.curUserId)
									mayDelete = true;
							}
						}
					}
				}

				if (!mayDelete)
					$("#btnTaskDelete").hide();
				else
					$("#btnTaskDelete").off("click").on("click", this.deleteTask);

			},

			//Auftrag deaktivieren
			/*deactivateTaskInterval: function () {
				taskRequestEditorManager.updateTaskEditIntervalStatus(model.curTaskOrRequest, constants.STATUS_OBJECT_INACTIVE);
				viewManager.setFormDirty();
				viewManager.updateButtons();
				return false;
			},

			//Auftrag aktivieren
			activateTaskInterval: function () {
				taskRequestEditorManager.updateTaskEditIntervalStatus(model.curTaskOrRequest, constants.STATUS_OBJECT_ACTIVE);
				viewManager.setFormDirty();
				viewManager.updateButtons();
				return false;
			},*/

			//Auftrag löschen
			deleteTask: function () {
				pg.confirmMsg("L&ouml;schen", "Möchten Sie diesen Auftrag wirklich l&ouml;schen?", function (e) {
					if (e) {
						taskRequestEditorManager.updateTaskMasterStatus(model.curTaskOrRequest, constants.TASK_STATUS_DELETED);
						return false;
					}
					else {
						viewManager.updateButtons();
					}
				});

				return false;
			},

			updateTaskMasterStatus: function (task, newMasterStatus) {

				var that = this;

				var ts = task.addTaskState();
				ts.status = newMasterStatus;

				dataManager.saveTaskState(task, ts, function (t, tsNew) {

					viewManager.updateButtons();
					if (tsNew.status === constants.TASK_STATUS_DELETED) {

						//Attachments löschen
						var attachments = task.getAttachments();
						for (var i = 0; i < attachments.length; i++) {
							dataManager.deleteAttachment(attachments[i].file, model.curClientId, attachments[i].fileToken);
						}

						viewManager.showNotification("Der Auftrag wurde erfolgreich gel&ouml;scht.", constants.NOTIFICATION_SUCCESS);
						viewManager.showPage(constants.PAGE_TASK_LIST, {status: -1}, true, false);
					}
					//else{
					//neue Stati (tbd., z.B. geschlossen)
					//}


				});
			},

			//-----------------------------------

			//liefert Array
			getSelectedUserGroupIds: function () {
				var ugs = [];
				$('#selectUserGroup :selected').each(function (i, selected) {
					ugs.push(parseInt(selected.value));
				});
				return ugs;
			},

			//Benutzernamen aktualisieren (dynamische Anzeige unter Select)
			updateUserNames: function () {
				var that = taskRequestEditorManager;
				var selectedUserGroups = that.getSelectedUserGroupIds();
				var group = "";
				$.each(selectedUserGroups, function (index, ugId) {
					var ug = UserGroup.getUserGroup(ugId);
					if (ug)
						if (!ug.isSingleUser) {

							var groupInfo = ug.name + ": ";
							var validUserCount = 0;
							for (var i = 0; i < ug.users.length; i++) {

								var u = ug.users[i];

								if (u.status === constants.STATUS_OBJECT_ACTIVE) {
									validUserCount++;

									if (validUserCount > 1)
										groupInfo += ", ";

									//Rechte?
									var hasPermissions = u.canExecuteTask(model.curTaskOrRequest);
									if (!hasPermissions)
										groupInfo += '<span class="text-muted">';
									groupInfo += u.getName();
									if (!hasPermissions)
										groupInfo += '</span>';
								}
							}

							group += groupInfo;
						}
				});
				$("#userNames").html(group);
			},

			//------------------------------------------------------------------------------------------------
			//Intervall-Einstellungen

			//Intervall-Anzeige initialisieren
			initIntervalDisplay: function (task) {

				if (!task.intervalType)
					return;

				//custom?
				model.curIntervalValues = Task.getIntervalValues(task.intervalType);
				if ((model.curIntervalValues.factor > 1) || (model.curIntervalValues.weekdays))
					$("#selectInterval").val("X");
				else
					$("#selectInterval").val(model.curIntervalValues.intervalType);
				$('#selectInterval').trigger("change")/*.prop('disabled', 'disabled')*/;

				this.updateIntervalDueDate();
			},

			//Intervall zurücksetzen
			resetInterval: function (updateDisplay) {
				model.curIntervalValues = {
					intervalType: null,
					factor: 1,
					weekdays: ""
				};
				if (updateDisplay) {
					$("#selectInterval").val('').trigger("change");
					this.updateIntervalDueDate();
				}
			},

			//Intervall wurde geändert
			prevIntervalType: "",
			onChangeInterval: function () {
				var that = taskRequestEditorManager;

				viewManager.setFormDirty();
				that.updateIntervalDueDate();

				//Anzeige Button
				var updateIntervalValue = false;
				var curIntervalValue = $("#selectInterval").val();
				if (curIntervalValue === "X") {

					if (that.prevIntervalType !== curIntervalValue) {
						that.resetInterval(false);
						that.updateIntervalDueDate();
					}

					//direkt Popup anzeigen?
					if (!that.hasIntervalEditorBeenShown) {
						that.hasIntervalEditorBeenShown = true;
						that.showIntervalEditor();
					}
					else{
						updateIntervalValue = true;
					}
				}
				else {
					that.hasIntervalEditorBeenShown = false;
					updateIntervalValue = true;
				}

				if (updateIntervalValue) {

					that.resetInterval(false);

					//übernehmen
					model.curIntervalValues.intervalType = curIntervalValue;
					if (curIntervalValue){
						that.showIntervalStatusBox(true);
					}
					else {
						that.showIntervalStatusBox(false);
					}
					//automatisch aktivieren
					if (!that.prevIntervalType){
						that.setTaskIntervalStatus(constants.STATUS_OBJECT_ACTIVE);
					}

				}

				that.prevIntervalType = curIntervalValue;
			},

			//serialisieren
			getIntervalType: function(iv){

				var intervalType = iv.intervalType;
				if (iv.factor > 1)
					intervalType += iv.factor;
				if (iv.weekdays)
					intervalType += "-" + iv.weekdays;
				return intervalType;
			},

			//Fälligkeitsdatum wurde geändert
			onDueDateChanged: function () {

				var that = taskRequestEditorManager;

				if (that.ignoreDatePicker)
					return;
				viewManager.setFormDirty();
				that.updateIntervalDueDate();

				$('#dateBox').removeClass('has-error');
				that.hasWeekdayError();
			},
			onDueDateLeave: function(){

				var that = taskRequestEditorManager;
				setTimeout(function(){
					$('#dateBox').removeClass('has-error');
					that.hasWeekdayError();
				}, 100);
			},

			//Intervall-Editor anzeigen
			showIntervalEditor: function (e) {

				var that = this;

				setTimeout(function () {

					var msgBody = '';
					msgBody += '' +
						'<div class="form">' +
						'<div class="form-rightAligned form-horizontal">' +
						'	<div class="form-body">' +

						'		<div id="intervalBox" class="form-group">' +
						'			<label class="col-md-3 control-label">H&auml;ufigkeit <span class="required">*</span></label>' +
						'			<div class="col-md-9">' +
						'				<select id="selectInterval2" class="select2 form-control input-medium" name="taskInterval" onchange="taskRequestEditorManager.onChangeIntervalEditor(event)"></select>' +
						'			</div>' +
						'		</div>' +

						'		<div id="factorBox" class="form-group isHidden">' +
						'			<label class="col-md-3 control-label">Alle</label>' +
						'			<div class="col-md-9">' +
						'				<div class="btn-group mr10">' +
						'					<button type="button" class="btn btn-default" onclick="taskRequestEditorManager.decIntervalFactor();"><i class="fa fa-minus" aria-hidden="true"></i></button>' +
						'					<button id="lblFactor" type="button" class="btn btn-default" disabled></button>' +
						'					<button type="button" class="btn btn-default" onclick="taskRequestEditorManager.incIntervalFactor();"><i class="fa fa-plus" aria-hidden="true"></i></button>' +
						'				</div>' +
						'				<span id="lblIntervalEditor" class="bold">Wochen</span>' +
						'			</div>' +
						'		</div>' +

						'		<div id="weekDayBox" class="form-group isHidden">' +
						'			<label class="col-md-3 control-label"></label>' +
						'			<div class="col-md-9">' +
						'				<label class="mt-checkbox mt-checkbox-single mt-checkbox-outline">' +
						'					<input type="checkbox" id="chkInterval0" class="form-control group-checkable" />Montag<span></span>' +
						'				</label><br/>' +
						'				<label class="mt-checkbox mt-checkbox-single mt-checkbox-outline">' +
						'					<input type="checkbox" id="chkInterval1" class="form-control group-checkable" />Dienstag<span></span>' +
						'				</label><br/>' +
						'				<label class="mt-checkbox mt-checkbox-single mt-checkbox-outline">' +
						'					<input type="checkbox" id="chkInterval2" class="form-control group-checkable" />Mittwoch<span></span>' +
						'				</label><br/>' +
						'				<label class="mt-checkbox mt-checkbox-single mt-checkbox-outline">' +
						'					<input type="checkbox" id="chkInterval3" class="form-control group-checkable" />Donnerstag<span></span>' +
						'				</label><br/>' +
						'				<label class="mt-checkbox mt-checkbox-single mt-checkbox-outline">' +
						'					<input type="checkbox" id="chkInterval4" class="form-control group-checkable" />Freitag<span></span>' +
						'				</label><br/>' +
						'				<label class="mt-checkbox mt-checkbox-single mt-checkbox-outline">' +
						'					<input type="checkbox" id="chkInterval5" class="form-control group-checkable" />Samstag<span></span>' +
						'				</label><br/>' +
						'				<label class="mt-checkbox mt-checkbox-single mt-checkbox-outline">' +
						'					<input type="checkbox" id="chkInterval6" class="form-control group-checkable" />Sonntag<span></span>' +
						'				</label><br/>' +
						'			</div>' +
						'		</div>' +

						'	</div>' +
						'</div>' +
						'</div>';

					var dialog = bootbox.dialog({
						className: '',
						title: 'Intervall Einstellungen',
						message: msgBody,
						buttons: {
							success: {
								label: "&Uuml;bernehmen",
								className: "btn btn-primary",
								callback: function () {

									//validation
									var val = $("#selectInterval2").val();
									if (val && val.length) {

										//übernehmen
										model.curIntervalValues.intervalType = val;
										model.curIntervalValues.factor = parseInt($("#lblFactor").html());
										//Wochentag (falls Typ Woche)
										model.curIntervalValues.weekdays = "";
										if (model.curIntervalValues.intervalType === "W") {
											for (var i = 0; i < 7; i++) {
												if ($("#chkInterval" + i).prop("checked")) {
													model.curIntervalValues.weekdays += i;
												}
											}
										}

										viewManager.setFormDirty();
										that.updateIntervalDueDate();

									}
									else
										return false;
								}
							},
							danger: {
								label: "Abbrechen",
								className: "btn default",
								callback: function () {
									if (!model.curIntervalValues.intervalType)
										that.resetInterval(true);
								}
							}
						},
						onEscape: function () {
							if (!model.curIntervalValues.intervalType)
								that.resetInterval(true);
						}
					});
					dialog.init(function () {

						var sel = "";
						sel += '<option value="" disabled selected style="display:none;">Bitte w&auml;hlen...</option>';
						sel += "<option value='D'>t&auml;glich</option>";
						sel += "<option value='W'>w&ouml;chentlich</option>";
						sel += "<option value='M'>monatlich</option>";
						sel += "<option value='Y'>j&auml;hrlich</option>";
						$("#selectInterval2").html(sel);

						//Werte
						$("#lblFactor").html("" + model.curIntervalValues.factor);
						if (model.curIntervalValues.intervalType) {
							$("#selectInterval2").val(model.curIntervalValues.intervalType);
							$("#factorBox").removeClass("isHidden");
							if (model.curIntervalValues.intervalType === "W")
								$("#weekDayBox").removeClass("isHidden");
						}

						//Wochentage
						for (var i=0; i<7; i++){
							var hasWeekday = (model.curIntervalValues.weekdays.indexOf(""+i) >= 0);
							$("#chkInterval" + i).prop("checked", hasWeekday);
						}

						that.updateIntervalEditor();

					});

				}, 20);


				if (e)
					e.preventDefault();
				return false;
			},

			//Intervall-Editor wurde geändert
			onChangeIntervalEditor: function (e) {

				var that = taskRequestEditorManager;
				var val = $("#selectInterval2").val();
				if (val && val.length) {
					$("#factorBox").removeClass("isHidden");

					if (val === "W") {
						$("#weekDayBox").removeClass("isHidden");
					}
					else{
						$("#weekDayBox").addClass("isHidden");
					}
				}
				that.updateIntervalEditor();

				e.preventDefault();
				return false;
			},

			//Intervall-Änderungen
			decIntervalFactor: function () {
				var that = taskRequestEditorManager;
				var factor = parseInt($("#lblFactor").html());
				if (factor > 1)
					factor--;
				$("#lblFactor").html(""+factor);
				that.updateIntervalEditor();
			},
			incIntervalFactor: function () {
				var that = taskRequestEditorManager;
				var factor = parseInt($("#lblFactor").html());
				factor++;
				$("#lblFactor").html(""+factor);
				that.updateIntervalEditor();
			},

			//Anzeige des Intervall-Editors aktualisieren
			updateIntervalEditor: function () {

				var val = $("#selectInterval2").val();
				var factor = parseInt($("#lblFactor").html());

				var weekdays = "";
				for (var i=0; i<7; i++){
					if ($("#chkInterval" + i).prop("checked")){
						weekdays += i;
					}
				}

				if (val && val.length) {

					var name = "";
					switch (val) {
						case "D":
							name = (factor === 1) ? "Tag" : "Tage";
							break;
						case "W":
							name = (factor === 1) ? "Woche" : "Wochen";
							name += " am:";
							break;
						case "M":
							name = (factor === 1) ? "Monat" : "Monate";
							break;
						case "Q":
							name = (factor === 1) ? "Quartal" : "Quartale";
							break;
						case "Y":
							name = (factor === 1) ? "Jahr" : "Jahre";
							break;
					}

					$("#lblIntervalEditor").html(name);
				}
			},

			getCurrentIntervalDueDate: function(){

				var curVal = $("#taskDueDate").val();
				if (!curVal)
					return null;

				var dRaw = curVal.split(".");
				var year = parseInt(dRaw[2], 10);
				if (year < 2000)
					year += 2000;
				var d = new Date(year, parseInt(dRaw[1], 10) - 1, parseInt(dRaw[0], 10));

				if (!(d instanceof Date) || isNaN(d))
					d = null;

				return d;
			},

			//Anzeige des Fälligkeitsdatums aktualisieren
			updateIntervalDueDate: function () {

				//nur wenn überhaupt sichtbar
				var sel = $("#selectTaskType").val();
				var tt = TaskType.getTaskType(parseInt(sel, 10));
				if (!tt)
					return;
				if (tt.editIntervalEnd === constants.TASK_EDITOR_HIDDEN)
					return;
				if ($("#intervalBox").hasClass("isHidden"))
					return;

				var isInterval = false;

				$("#btnEditInterval").addClass("isHidden");
				var curIntervalValue = $("#selectInterval").val();
				if (curIntervalValue) {
					if (curIntervalValue.length > 0) {

						if (curIntervalValue === "X") {
							//Werte beibehalten (werden in/nach Popup-Dialog geändert)

							//ggf. DueDate anpassen
							/*if (model.curIntervalValues.weekdays) {
								var dd = this.getCurrentIntervalDueDate();
								if (dd) {
									dd = Task.getMatchingDueDate(dd, model.curIntervalValues);
									if (model.curTaskOrRequest)
										model.curTaskOrRequest.dueDate = dd;
									this.ignoreDatePicker = true;
									//dieser Aufruf scheint den internen Zustand des Pickers trotzdem nicht richtig zu setzen (nächster Dialogaufruf highlighted etwas anderes...)
									$("#taskDueDate").val(pg.formatDate(dd, true)).datepicker("update");
									this.ignoreDatePicker = false;

								}
							}*/

							//Button anzeigen
							$("#btnEditInterval").removeClass("isHidden");
						}
						else {
							model.curIntervalValues.intervalType = curIntervalValue;
							model.curIntervalValues.factor = 1;
							model.curIntervalValues.weekdays = "";
						}
						isInterval = true;
					}
				}
				else {
					//ohne
					model.curIntervalValues.intervalType = "";
					model.curIntervalValues.factor = 1;
					model.curIntervalValues.weekdays = "";
				}

				if (isInterval) {

					$("#endDateBox").show();

					if (model.curTaskOrRequest)
						$("#hintInterval").show();
					else
						$("#hintInterval").hide();

					//Anzeige Intervall-Details
					$("#lblIntervalInfo").html("");

					var intervalInfo = "<b>" + Task.createIntervalName(model.curIntervalValues) + "</b>";
					if ($("#taskDueDate").val()) {

						var dDue = this.getCurrentIntervalDueDate();
						var dDueNext = Task.getIntervalDate(dDue, model.curIntervalValues.intervalType, model.curIntervalValues.factor, model.curIntervalValues.weekdays, true);

						intervalInfo += " - N&auml;chste F&auml;lligkeit " + pg.formatDate(dDueNext, true);
					}
					else {
						if ((curIntervalValue !== "X") || (!model.curIntervalValues.intervalType))
							intervalInfo = "";
					}

					$("#lblIntervalInfo").html(intervalInfo);

					$("#lblDueDate").html("Startet am");
				}
				else {
					$("#endDateBox").hide();
					$("#hintInterval").hide();
					$("#lblIntervalInfo").html("");
					$("#lblDueDate").html("Fällig bis");
				}

				this.updateTaskEditIntervalStatus(model.curTaskOrRequest);

				//neuer Auftrag: anhand der derzeitigen Auswahl entscheiden, ob IV-Status angezeigt wird
				if (!model.curTaskOrRequest){

					var intervalType = this.getIntervalType(model.curIntervalValues);
					var showBox = (intervalType);
					this.showIntervalStatusBox(showBox);
				}
			},

			//------------------------------------------------------------------------

			//Validierungsfehler entfernen
			removeErrors: function () {
				var formEl = $('#formTaskEdit');
				$(".errMsg", formEl).hide();
				$('.form-group', formEl).removeClass('has-error has-error-custom');
			},

			hasWeekdayError: function(){

				$("#errorDateWeekdays").hide();

				var intervalType = $("#selectInterval").val();
				if (intervalType) {
					if (intervalType.length) {

						var dDue = this.getCurrentIntervalDueDate();

						//DueDate muss zu Intervallmuster passen
						if (model.curIntervalValues.weekdays) {
							var weekday = Task.getWeekDayDe(dDue);
							//gibt es einen Match?
							var hasMatch = false;
							for (var j = 0; j < model.curIntervalValues.weekdays.length; j++) {
								var wd = parseInt(model.curIntervalValues.weekdays[j]);
								if (wd === weekday) {
									hasMatch = true;
									break;
								}
							}
							if (!hasMatch) {
								$('#dateBox').addClass('has-error');
								$("#errorDateWeekdays").show();
								viewManager.updateButtons();
								return true;
							}
						}
					}
				}

				return false;
			},

			//dueDate immer gleich oder vor "Endet am" (falls verwendet)
			hasEndDateError: function(){

				$("#errorDateEnd").hide();

				var intervalType = $("#selectInterval").val();
				if (intervalType) {
					if (intervalType.length) {

						var dDue = this.getCurrentIntervalDueDate();
						if (!dDue){
							$('#dateBox').addClass('has-error');
							$("#errorDateEnd").show();
							viewManager.updateButtons();
							return true;
						}

						if ($("#taskEndDate").val().length) {
							var dRaw = $("#taskEndDate").val().split(".");
							var year = parseInt(dRaw[2], 10);
							if (year < 2000)
								year += 2000;
							var dEnd = new Date(year, parseInt(dRaw[1], 10) - 1, parseInt(dRaw[0], 10));

							if (dDue.getTime() > dEnd.getTime()) {
								$('#dateBox').addClass('has-error');
								$("#errorDateEnd").show();
								viewManager.updateButtons();
								return true;
							}
						}
					}
				}

				return false;
			},

			//sonstige Validierungsschritte
			hasCustomErrors: function (tt) {

				var i;

				var hasCustomError = false;

				//Fehler nur dann löschen, wenn nicht gewollt!
				var clearDateError = true;
				var intervalType = $("#selectInterval").val();
				var hasInterval = (intervalType && (intervalType.length > 0));
				if ((tt.editDueDate === constants.TASK_EDITOR_MANDATORY) || hasInterval){
					//
					var dDue = this.getCurrentIntervalDueDate();
					if (!dDue){
						clearDateError = false;
					}
				}

				if (clearDateError)
					$('#dateBox').removeClass('has-error');

				if (this.hasWeekdayError()){
					hasCustomError = true;
				}
				if (this.hasEndDateError()){
					hasCustomError = true;
				}

				//mind. eine Location ausgewählt?
				var mustContainLocation = (tt.editLocations === constants.TASK_EDITOR_MANDATORY);
				//evtl. optional, aber einen konkreten (also nicht "ohne") Objekttypen ausgewählt?
				if (!mustContainLocation && (tt.editLocations === constants.TASK_EDITOR_OPTIONAL)) {
					var locationType = this.getSelectedLocationType();
					if (locationType < 0)
						locationType = -1;
					mustContainLocation = (locationType > 0);
				}
				if (mustContainLocation) {
					var selLocIds = [];
					var selectedElms = $('#treeLocation').jstree("get_selected", true);
					for (i = selectedElms.length - 1; i >= 0; i--) {

						//keine Folder speichern
						var loc = Location.getLocation(selectedElms[i].data, undefined, undefined, undefined, true);
						if (loc) {
							if (loc.isAbstract === 1)
								selectedElms.splice(i, 1);
							else
								selLocIds.push(selectedElms[i].data);
						}
					}
					if (selLocIds.length === 0) {
						$('#locationBox').addClass('has-error');
						$("#errorLocation").show();
						viewManager.updateButtons();
						hasCustomError = true;
					}
					else {
						$("#errorLocation").hide();
						$('#locationBox').removeClass('has-error');
					}
				}

				//attachments
				if (tt.editAttachments === constants.TASK_EDITOR_MANDATORY) {
					if (model.curAttachments.length === 0) {
						$('#taskAttachmentBox').addClass('has-error');
						$("#errorAttachments").show();
						viewManager.updateButtons();
						hasCustomError = true;
					}
					else {
						$("#errorAttachments").hide();
						$('#taskAttachmentBox').removeClass('has-error');
					}
				}

				//dynamische Felder
				var ttFields = TaskTypeField.getTaskTypeFields(tt.typeId);
				for (i = 0; i < ttFields.length; i++) {
					var field = ttFields[i];
					if (field.isRequired === 1) {

						var rawValue = $("#formTaskEdit #" + field.getDomId()).val();
						if (!field.isValidValue(rawValue)) {
							$("#formTaskEdit #" + field.getDomId() + "Box").addClass('has-error has-error-custom');
							$("#formTaskEdit #" + field.getDomId() + "-error").show();
							viewManager.updateButtons();
							hasCustomError = true;
						}
					}
				}

				return hasCustomError;
			},

			//------------------------------------------------

			//Speichern beginnen
			saveTask: function () {
				var that = taskRequestEditorManager;

				//etwas geändert?
				if (!model.isCurFormDirty) {
					if (model.curTaskOrRequest) {
						if (model.curTaskOrRequest.id < 0)
							pg.showMsg("Bitte füllen Sie das Formular vollständig aus.");
						else
							viewManager.showNotification("Es wurden keine Änderungen festgestellt.");
					}
					viewManager.updateButtons();
					return false;
				}

				var formEl = $('#formTaskEdit');
				var error1 = $('.alert-danger', formEl);

				//aufräumen
				that.removeErrors();

				//-----------------------
				//required-Rules dynamisch setzen
				var rules = {};

				var sel = $("#selectTaskType").val();
				var tt = TaskType.getTaskType(parseInt(sel, 10));

				if (!tt) {
					return;
				}

				if (tt.editTitle === constants.TASK_EDITOR_MANDATORY) {
					rules.taskTitle = {required: true};
				}
				if (tt.editDescription === constants.TASK_EDITOR_MANDATORY) {
					rules.taskDesc = {required: true};
				}
				//Falls Intervall, muss DueDate angegeben werden
				var intervalType = $("#selectInterval").val();
				var hasInterval = (intervalType && (intervalType.length > 0));
				if ((tt.editDueDate === constants.TASK_EDITOR_MANDATORY) || hasInterval) {
					rules.taskDueDate = {required: true};
				}
				if (tt.editInterval === constants.TASK_EDITOR_MANDATORY) {
					rules.taskInterval = {required: true};
				}
				if (tt.editIntervalEnd === constants.TASK_EDITOR_MANDATORY) {
					rules.taskEndDate = {required: true};
				}
				if ((tt.editOwner === constants.TASK_EDITOR_MANDATORY) || (that.isRequest && (model.client.autoReleaseRequests === 1))) {
					rules.taskResponsible = {
						required: true,
						valueNotEquals: "-1"
					};
				}

				if (that.isRequest) {

					if (model.client.autoReleaseRequests === 0)
						rules.requestDecider = {
							required: true,
							valueNotEquals: "-1"
						};
				}
				else {
					if (tt.editAssignee === constants.TASK_EDITOR_MANDATORY) {
						rules.userGroup = {required: true};
					}
				}


				//Regeln aktualisieren! (werden sonst vom vorherigen Lauf beibehalten!)
				if (that.validator) {
					//noinspection JSUnresolvedVariable
					that.validator.settings.rules = rules;
				}

				$(".help-block-custom").hide();

				//los!
				that.validator = formEl.validate({
					errorElement: 'span', //default input error message container
					errorClass: 'help-block help-block-error', // default input error message class
					focusInvalid: true, // do not focus the last invalid input
					ignore: "",  // validate all fields including form hidden input
					messages: {},
					rules: rules,

					invalidHandler: function (event, validator) { //display error alert on form submit

						//falls Fehler bei Date-Picker, diesen umbewegen
						setTimeout(function () {
							$("#taskDueDate-error").prependTo($("#dueDateInputBox"));
							$('.date-picker').datepicker("hide");
						}, 20);

						setTimeout(function(){
							that.hasCustomErrors(tt);
						}, 100);

						error1.show();
						viewManager.scrollToElement(error1, -200);
						viewManager.updateButtons();
					},

					highlight: function (element) { // hightlight error inputs
						$(element)
							.closest('.form-group').addClass('has-error'); // set error class to the control group
					},

					unhighlight: function (element) { // revert the change done by hightlight
						$(element)
							.closest('.form-group').removeClass('has-error'); // set error class to the control group
					},

					success: function (label) {
						label
							.closest('.form-group').removeClass('has-error'); // set success class to the control group
					}
				});

				if (formEl.valid())
					that.saveTask2(tt, formEl, error1);
			},

			saveTask2: function (tt, formEl, errorEl) {

				var that = taskRequestEditorManager;

				//blocking
				if (!viewManager.setButtonBlocking($(".btnSaveTask"), true))
					return false;

				if (that.hasCustomErrors(tt))
					errorEl.show();
				else {
					errorEl.hide();

					$("#taskDueDate-error").remove();

					that.saveTaskInit();
				}
			},

			//Validierung ok, Speichern weiterführen
			saveTaskInit: function () {

				var that = this;

				var dRaw,
					year;

				//Daten sammeln & erforderliche Protokolle zählen
				var selLocIds = "";
				var selectedElms = $('#treeLocation').jstree("get_selected", true);
				//var locations = [];
				var totalProtocolCount = 0;
				for (var i = 0; i < selectedElms.length; i++) {

					//keine Folder speichern
					var loc = Location.getLocation(selectedElms[i].data, undefined, undefined, undefined, true);
					if (loc) {
						if (loc.isAbstract === 0) {
							selLocIds += selectedElms[i].data + "#";

							//protocols
							totalProtocolCount++;
						}
					}
				}
				//Anzahl Protokolle gemäß taskType
				var tt = parseInt($("#selectTaskType").val(), 10);
				var ttObj = TaskType.getTaskType(tt);
				totalProtocolCount *= ttObj.formIds.length;

				//----------------------------------
				//build base object

				//common obj (for task + request)
				var t = null;

				//add vs edit: bei edit wird die bestehende IDs mitgesendet -> trotzdem neue DB-Einträge (History!)
				var taskId = -1;
				var taskStateId = -1;
				var requestId = -1;
				var curTaskState = null;
				var curStatus = constants.TASK_STATUS_ACTIVE;

				if (that.isRequest) {

					t = Request.createRequest();

					if (model.curTaskOrRequest) {
						requestId = model.curTaskOrRequest.requestId;
						t.ownerId = model.curTaskOrRequest.ownerId;
					}
					else{
						t.ownerId = model.curUserId;
					}

					t.requestId = requestId;

				}
				else {

					t = Task.createTask();

					if (model.curTaskOrRequest) {
						taskId = model.curTaskOrRequest.taskId;
						t.ownerId = model.curTaskOrRequest.ownerId;
						if (model.curTaskOrRequest.getCurrentState()) {
							curTaskState = model.curTaskOrRequest.getCurrentState();
							taskStateId = curTaskState.taskStateId;
							curStatus = curTaskState.status;
						}
					}
					else{
						t.ownerId = model.curUserId;
					}

					t.taskId = taskId;

					//Standard: Intervall ist aktiv
					t.intervalStatus = constants.STATUS_OBJECT_ACTIVE;
				}

				//common properties
				t.taskTypeId = tt;
				t.locationType = this.getSelectedLocationType();
				if (t.locationType < 0)
					t.locationType = null;
				t.title = $("#taskTitle").val();
				if ((ttObj.editTitle === constants.TASK_EDITOR_OPTIONAL) && (!t.title))
					t.title = ttObj.name;
				t.description = pg.validateInput($("#taskDesc").val(), 4096);

				//Intervall
				t.intervalType = that.getIntervalType(model.curIntervalValues);

				//Intervall-ID immer neu erzeugen, wird ggf. nachfolgend durch vorherige überschrieben
				if (t.isInterval()){
					t.intervalId = Math.floor(Math.random() * 1000000000);
				}
				t.generationId = 0;

				//ggf. vorliegende Daten aus Antrag oder Klon übernehmen
				if (!that.isRequest) {
					//übernehmen
					if (model.curTaskOrRequest) {
						if (t.isInterval()) {
							if (model.curTaskOrRequest.intervalId > 0)
								t.intervalId = model.curTaskOrRequest.intervalId;
							if (model.curTaskOrRequest.generationId > 0)
								t.generationId = model.curTaskOrRequest.generationId;
						}
						t.requestId = model.curTaskOrRequest.requestId;
					}
					else{
						t.requestId = -1;
					}
				}

				//Intervallstatus
				if ((that.isRequest) || (t.taskId > 0))
					t.intervalStatus = that.getTaskIntervalStatus();
				//kein Intervall? Dann ggf. Status erhalten
				if ((t.intervalId <= 0) && (!that.isRequest) && (model.curTaskOrRequest))
					t.intervalStatus = model.curTaskOrRequest.intervalStatus;

				//falls neu: default aktiv!
				if ((t.taskId <= 0) && t.isInterval() && !t.intervalStatus) {
					t.intervalStatus = constants.STATUS_OBJECT_ACTIVE;
				}
				else {
					//Umwandlung eines bestehenden?
					if (model.curTaskOrRequest && !model.curTaskOrRequest.isInterval()){
						t.intervalStatus = constants.STATUS_OBJECT_ACTIVE;
					}
				}

				//#1090: Falls kein Intervall, alle Intervall-Eigenschaften zurücksetzen
				if(!t.isInterval()){
					t.endDate = null;
					t.endDateReminderSent = 0;
					t.generationId = 0;
					t.intervalId = 0;
					t.intervalType = null;
					t.intervalStatus = constants.STATUS_OBJECT_ACTIVE;

					//falls zuvor Intervall, nun auf inaktiv setzen (UPK-1318)
					if (model.curTaskOrRequest && model.curTaskOrRequest.isInterval()) {
						t.intervalStatus = constants.STATUS_OBJECT_INACTIVE;
					}
				}

				//dueDate
				var d = null;
				if ($("#taskDueDate").val()) {
					dRaw = $("#taskDueDate").val().split(".");
					year = parseInt(dRaw[2], 10);
					if (year < 2000)
						year += 2000;
					d = new Date(year, parseInt(dRaw[1], 10) - 1, parseInt(dRaw[0], 10));
				}
				t.dueDate = (d) ? d.getTime() : null;
				
				//-------------------------------
				
				if (!that.isRequest) {
					
					//falls Fälligkeit verändert, Reminders zurücksetzen
					if (t.taskId > 0) {
						var prevDueDate = model.curTaskOrRequest.dueDate ? model.curTaskOrRequest.dueDate.getTime() : null;
						var newDueDate = t.dueDate;
						if (prevDueDate !== newDueDate)
							t.reminders = "";
						else
							t.reminders = model.curTaskOrRequest.reminders;
					}
					else{
						t.reminders = "";
					}
				}
				
				//-------------------------------

				var dEnd = null;
				if ($("#taskEndDate").val()) {
					dRaw = $("#taskEndDate").val().split(".");
					year = parseInt(dRaw[2], 10);
					if (year < 2000)
						year += 2000;
					dEnd = new Date(year, parseInt(dRaw[1], 10) - 1, parseInt(dRaw[0], 10));
					t.endDate = dEnd.getTime();
				}
				else
					t.endDate = null;
				t.locations = selLocIds;

				if (!model.curAttachments)
					model.curAttachments = [];
				t.attachments = Attachment.serializeAttachments(model.curAttachments);
				t.attachmentsArray = model.curAttachments;

				//dynamische Felder
				t.clearDynData();
				var ttFields = TaskTypeField.getTaskTypeFields(ttObj.typeId);
				for (i = 0; i < ttFields.length; i++) {

					var field = ttFields[i];
					var rawValue = $("#formTaskEdit #" + field.getDomId()).val();
					t.addDynData(field, rawValue);
				}

				//--------------------------------
				//add state + action!

				if (that.isRequest) {

					var deciderId = parseInt($("#selectDecider").val());
					if (model.client.autoReleaseRequests === 1)
						deciderId = -1;
					var responsibleId = parseInt($("#selectResponsible").val());

					//nur bei Erstanlage Status setzen
					var rs = null;
					var setState = false;
					if (t.requestId < 0) {
						setState = true;
					}
					else {
						//Änderungen ggü- vorher?
						var rs2 = model.curTaskOrRequest.getCurrentState();
						if ((rs2.deciderId !== deciderId) || (rs2.responsibleId !== responsibleId)) {
							setState = true;
						}
					}

					//initialen State erzeugen
					if (setState) {
						rs = RequestState.createRequestState();
						rs.requestId = requestId;
						if (t.requestId < 0)
							rs.status = RequestState.STATUS_NEW;
						else
							rs.status = model.curTaskOrRequest.getCurrentState().status;
						rs.deciderId = deciderId;
						rs.responsibleId = responsibleId;
						rs.comment = "";
					}

					//t.deletedAttachments = [];

					//gibt es Änderungen ggü. der vorherigen Fassung?
					if (t.requestId > 0) {
						if (model.curTaskOrRequest) {

							var rsCheck = rs;
							if (!rsCheck)
								rsCheck = model.curTaskOrRequest.getCurrentState();

							if (model.curTaskOrRequest.isRequestEqual(t, rsCheck)) {
								//Attachments laufen asynchron
								if (!that.hasAttachmentsChanged) {
									viewManager.showNotification("Es wurden keine Änderungen festgestellt.");
									viewManager.updateButtons();
									return false;
								}
							}

							//wurden Attachments entfernt?
							//t.deletedAttachments = t.getDeletedAttachments(model.curTaskOrRequest);
						}

						dataManager.saveRequest(t, rs, that.onRequestSaved);
					}
					else
						dataManager.saveRequest(t, rs, that.onRequestSaved);
				}
				else {

					var ts = TaskState.createTaskState();
					ts.taskStateId = taskStateId;

					//ts.userGroupId = parseInt($("#selectUserGroup").val());
					var ugs = that.getSelectedUserGroupIds();
					ts.userGroupIds = "";
					for (i = 0; i < ugs.length; i++)
						ts.userGroupIds += ugs[i] + "#";

					ts.labelId = (curTaskState) ? curTaskState.labelId : null;
					ts.responsibleId = parseInt($("#selectResponsible").val());
					if (model.curTaskOrRequest)
						ts.taskStatus = model.curTaskOrRequest.status;
					else
						ts.taskStatus = null;
					ts.status = curStatus;
					ts.groupId = (curTaskState) ? curTaskState.groupId : 0;

					//t.deletedAttachments = [];
					t.attachmentsArray = model.curAttachments;

					//gibt es Änderungen ggü. der vorherigen Fassung?
					if (t.taskId > 0) {
						if (model.curTaskOrRequest) {
							if (model.curTaskOrRequest.isTaskEqual(t, ts)) {
								if (!that.hasAttachmentsChanged) {
									viewManager.showNotification("Es wurden keine Änderungen festgestellt.");
									viewManager.updateButtons();
									return false;
								}
							}

							//wurden Attachments entfernt?
							//t.deletedAttachments = t.getDeletedAttachments(model.curTaskOrRequest);

						}

						//sonst: PN senden
						pg.confirmMsg("Benachrichtigung", "Soll eine Benachrichtigung an den/die derzeitige(n) Bearbeiter versendet werden?", function (result) {
							dataManager.saveTask(t, ts, result, that.onTaskSaved);
						}, "Ja", "Nein");
					}
					else
						dataManager.saveTask(t, ts, true, that.onTaskSaved);

				}
			},

			//---------------------------------------

			//Auftrag wurde gespeichert (Schritt 1)
			onTaskSaved: function (isNew, t) {

				var that = taskRequestEditorManager;

				//Überführung?
				if (isNew && (t.requestId > 0)) {

					//auch noch neuen RequestState erzeugen
					requestDetailManager.addRequestState(RequestState.STATUS_COMPLETED, "", null, null, function () {
						that.onTaskSaved2(isNew, t);
					});

				}
				else
					that.onTaskSaved2(isNew, t);
			},

			//Auftrag wurde gespeichert (Schritt 2)
			onTaskSaved2: function (isNew, t) {
				model.curTaskOrRequest = t;

				viewManager.updateButtons();
				viewManager.showPage(constants.PAGE_TASK_DETAILS, {
					taskId: model.curTaskOrRequest.taskId,
					isNew: isNew
				}, true, false);
				if (isNew)
					viewManager.showNotification("Neuer Auftrag erfolgreich angelegt.", constants.NOTIFICATION_SUCCESS);
				else
					viewManager.showNotification("Änderungen übernommen.", constants.NOTIFICATION_SUCCESS);
			},

			//Request wurde gespeichert
			onRequestSaved: function (isNew, t) {

				model.curTaskOrRequest = t;

				viewManager.updateButtons();
				viewManager.showPage(constants.PAGE_REQUEST_DETAILS, {
					requestId: model.curTaskOrRequest.requestId,
					isNew: isNew
				}, true, false);
				if (isNew)
					viewManager.showNotification("Neuer Antrag erfolgreich angelegt.", constants.NOTIFICATION_SUCCESS);
				else
					viewManager.showNotification("Änderungen übernommen.", constants.NOTIFICATION_SUCCESS);
			},

			//------------------------------------------

			//Zurücksetzen von Datumsfeldern
			resetTaskEndDate: function (e) {

				viewManager.setFormDirty();
				/*if (model.curTaskOrRequest)
					model.curTaskOrRequest.endDate = null;*/
				$("#taskEndDate").val(null);

				e.preventDefault();
				return false;
			},
			resetTaskDueDate: function (e) {

				viewManager.setFormDirty();
				$("#taskDueDate").val(null);

				e.preventDefault();
				return false;
			}

			//------------------------------------------

		};

		window.taskRequestEditorManager = taskRequestEditorManager;
	}()
);
