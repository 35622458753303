(function () {
	
	//------------------------------------------------------------------------
	//Alle Datenbank-Zugriffe verwalten
	//------------------------------------------------------------------------
	
	/*global
	 logManager:true,
	 constants:true,
	 Location:true,
	 Client:true,
	 Task:true,
	 User:true,
	 UserGroup:true,
	 Attachment:true,
	 TaskType:true,
	 LocationType:true,
	 ProtocolForm:true,
	 Protocol:true,
	 Incident:true,
	 Account:true,
	 LocationField:true,
	 LocationFormField:true,
	 LocationData:true,
	 ProtocolFormField:true,
	 LocationData:true,
	 TaskState:true,
	 Incident:true,
	 Signal:true,
	 UserRole:true,
	 authManager:true,
	 Request:true,
	 RequestState:true,
	 TaskTypeField:true,
	 TaskLabel:true,
	 ClientModule:true,
	 HistoryItem:true,
	 ProtocolDataItem:true,
	 connectionManager:true,
	 UserNotificationType:true,
	 viewManager:true
	 */
	
	'use strict';
	
	//------------------------------------------------------------------------
	//Data Manager (Cache, Server)
	//------------------------------------------------------------------------
	
	var dataManager = {
		
		//Shortcut für nachfolgende Methoden
		onInvalidSession: function () {
			authManager.onInvalidSession();
		},
		
		//----------------------------------------------
		
		//Basisdaten laden
		loadData: function (verbose, callbackComplete, callbackCheckNewData) {
//console.log("o " + new Date().getTime());
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				clientId: model.curClientId,
				userId: model.curUserId,
				since: model.prevFetchTimestamp,
				width: $(window).width(),
				height: $(window).height()
			};
			data.model = navigator.userAgent;
			//noinspection JSUnresolvedVariable
			data.manufacturer = navigator.vendor;
			data.os = navigator.platform;
			//noinspection JSUnresolvedVariable
			data.osVersion = navigator.oscpu || "";

			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/data/client',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {

						//remove BOM + parse json
						var response = pg.getJson(dataResp);

						if (parseInt(response.success) === 1) {
							
							var i, j, k, tt, s;
							
							//new entities
							var newCount = 0;

							var tsInit = new Date().getTime();
//console.log("a " + new Date().getTime());
							//--------------------------------

							//alte mit neuen Daten vergleichen
							if (callbackCheckNewData) {
								try {
									callbackCheckNewData(response, model);
								}
								catch(e){
								}
							}

							//--------------------------------
							//Server-Zeit!

							//noinspection JSUnresolvedVariable
							if (response.serverTime) {
								//noinspection JSUnresolvedVariable
								model.prevFetchTimestamp = parseInt(response.serverTime) * 1000;
							}
							else
								model.prevFetchTimestamp = new Date().getTime();

							//--------------------------------
							if (response.client)
								model.client = Client.createClient().fromObj(response.client);
							
							//Modules
							//model.clientModules = [];
							if (response.clientModules) {
								for(i = 0; i < response.clientModules.length; i++)
									pg.replaceOrPushObj(model.clientModules, ClientModule.createClientModule().fromObj(response.clientModules[i]));
							}
							
							//Accounts
							//model.accounts = [];
							if (response.accounts) {
								for(i = 0; i < response.accounts.length; i++) {
									pg.replaceOrPushObj(model.accounts, Account.createAccount().fromObj(response.accounts[i]));
								}
								//sortieren nach Name
								model.accounts.sort(function (a, b) {
									return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
								});
							}
							
							//--------------------------------

							//Attachments
							if (response.attachments) {
								for(i = 0; i < response.attachments.length; i++) {
									Attachment.replaceOrPushObj(model.attachments, Attachment.createAttachmentRaw().fromObj(response.attachments[i]));
								}
							}

							//--------------------------------
							//Users

							if (response.users) {
								for (i = 0; i < response.users.length; i++) {

									var u = User.createUser().fromObj(response.users[i]);

									//Benachrichtigungen erhalten
									var uEx = User.getUser(u.id);
									if (uEx) {
										u.notificationTypes = uEx.notificationTypes;
									}

									pg.replaceOrPushObj(model.users, u);
								}
							}
							if (response.userGroups) {
								for (i = 0; i < response.userGroups.length; i++)
									pg.replaceOrPushObj(model.userGroups, UserGroup.createUserGroup().fromObj(response.userGroups[i]));
							}
							if (response.userRoles) {
								for (i = 0; i < response.userRoles.length; i++)
									pg.replaceOrPushObj(model.userRoles, UserRole.createUserRole().fromObj(response.userRoles[i]));
							}
							model.curUser = User.getUser(model.curUserId);

							//notifications
							if (response.userNotifications) {
								for (i = 0; i < response.userNotifications.length; i++) {
									var rNt = UserNotificationType.createUserNotificationType().fromObj(response.userNotifications[i]);
									model.curUser.addNotificationType(rNt.notificationType);
								}
							}

							//--------------------------------
							//Task types
							if (response.taskTypes) {
								for (i = 0; i < response.taskTypes.length; i++) {
									tt = TaskType.createTaskType().fromObj(response.taskTypes[i]);

									//Modul-Check
									if (tt.requiredModuleId) {
										if (!model.client.hasModule(tt.requiredModuleId))
											continue;
									}

									pg.replaceOrPushObj(model.taskTypes, tt);
								}
							}
							if (response.taskTypeFields) {
								for (i = 0; i < response.taskTypeFields.length; i++)
									pg.replaceOrPushObj(model.taskTypeFields, TaskTypeField.createTaskTypeField().fromObj(response.taskTypeFields[i]));
							}
							TaskType.updateTaskTypes();

							//--------------------------------
							//Task Labels
							if (response.taskLabels) {
								for(i = 0; i < response.taskLabels.length; i++)
									pg.replaceOrPushObj(model.taskLabels, TaskLabel.createTaskLabel().fromObj(response.taskLabels[i]));
							}

							//--------------------------------
							//Locations
							var updatedLocationsIds = [];
							var l;
							if (response.locations) {
								for (i = 0; i < response.locations.length; i++) {
									l = Location.createLocation().fromObj(response.locations[i]);
									pg.replaceOrPushObj(model.locations, l, Location.mergeLocations);
									updatedLocationsIds.push(l.locationId);
								}
							}
							if (response.locationTypes) {
								for (i = 0; i < response.locationTypes.length; i++)
									pg.replaceOrPushObj(model.locationTypes, LocationType.createLocationType().fromObj(response.locationTypes[i]));
							}

							var lf;
							if (response.locationFields) {
								for (i = 0; i < response.locationFields.length; i++) {
									lf = LocationField.createLocationField().fromObj(response.locationFields[i]);
									pg.replaceOrPushObj(model.locationFields, lf);
									//updatedLocationsIds.push(lf.locationId);
								}
							}

							//LocFormFields
							//noinspection JSUnresolvedVariable
							if (response.locationFormFieldsCompact) {

								//alte Fassungen löschen
								var loc;
								var locIds = [],
									lff;

								//übernehmen
								for (i = 0; i < response.locationFormFieldsCompact.length; i++) {
									lff = response.locationFormFieldsCompact[i];

									//Optimierung
									var hasLoc = false;
									var locId = parseInt(lff.locationId);
									if ((loc) && (loc.locationId === locId))
										hasLoc = true;
									if (!hasLoc)
										loc = Location.getLocation(locId, null, true, true, true);

									if (loc) {
										loc.clearFormFields(lff.formId);

										//noinspection JSUnresolvedVariable
										for (j = 0; j < lff.formFieldIds.length; j++) {
											//noinspection JSUnresolvedVariable
											var lff2 = LocationFormField.createLocationFormField().fromObj({
												locationId: lff.locationId,
												formId: lff.formId,
												formFieldId: lff.formFieldIds[j]
											});
											loc.addFormField(lff2);
										}
									}
								}
							}

							var ld = [];
							//noinspection JSUnresolvedVariable
							if (response.locationData) {
								//noinspection JSUnresolvedVariable
								for (i = 0; i < response.locationData.length; i++) {
									//noinspection JSUnresolvedVariable
									ld.push(LocationData.createLocationData().fromObj(response.locationData[i]));
								}
							}

							//nur genau diejenigen Locations aktualisieren, zu denen es Änderungen gab
							updatedLocationsIds = pg.getUnique(updatedLocationsIds);
							Location.updateLocations(ld, updatedLocationsIds);
							Location.buildLocationTree();

							//--------------------------------
							//Forms

							if (response.protocolForms) {
								for (i = 0; i < response.protocolForms.length; i++)
									pg.replaceOrPushObj(model.protocolForms, ProtocolForm.createProtocolForm().fromObj(response.protocolForms[i])/*, ProtocolForm.mergeProtocolForms*/);
							}
							if (response.protocolFormFields) {
								for (i = 0; i < response.protocolFormFields.length; i++)
									pg.replaceOrPushObj(model.protocolFormFields, ProtocolFormField.createProtocolFormField().fromObj(response.protocolFormFields[i]));
							}
							ProtocolForm.updateProtocolForms();
							
							//--------------------------------
							//Tasks

							var p;
//console.log("d " + new Date().getTime() + " -> " + response.tasks.length);
							if (response.tasks) {
								newCount += response.tasks.length;
								for (i = 0; i < response.tasks.length; i++) {

									//Modul zu diesem Auftragstyp vorhanden?
									var t = Task.createTask().fromObj(response.tasks[i]);
									tt = TaskType.getTaskType(t.taskTypeId);
									if (tt.requiredModuleId) {
										if (!model.client.hasModule(tt.requiredModuleId))
											continue;
									}

									//ok (oder kein Modul erforderlich)
									//console.log("add task");
									pg.replaceOrPushObj(model.tasks, t, Task.mergeTasks);
								}
							}
//console.log("z " + new Date().getTime());
							//erst jetzt die separaten States und Protokolle ergänzen
							//noinspection JSUnresolvedVariable
							if (response.taskStates) {
								for (i = 0; i < response.taskStates.length; i++) {
									s = TaskState.createTaskState().fromObj(response.taskStates[i]);
									//allen geeigneten Tasks zuordnen (ja, allen!)
									for (j = 0; j < model.tasks.length; j++)
										if (model.tasks[j].taskId === s.taskId) {
											//noinspection JSUnresolvedVariable
											pg.replaceOrPushObj(model.tasks[j].states, s);
										}
								}
							}
//console.log("z " + new Date().getTime());
							//Protokolle
							if (response.protocols) {
								newCount += response.protocols.length;
								for (i = 0; i < response.protocols.length; i++) {
									p = Protocol.createProtocol().fromObj(response.protocols[i]);

									//allen geeigneten Tasks zuordnen (ja, allen!)
									for (j = 0; j < model.tasks.length; j++)
										if (model.tasks[j].taskId === p.taskId)
											pg.replaceOrPushObj(model.tasks[j].protocols, p, Protocol.mergeProtocols);
								}
							}
							//Daten dazu
							if (response.protocolData) {
								for (i = 0; i < response.protocolData.length; i++) {
									var pdi = ProtocolDataItem.createProtocolDataItem().fromObj(response.protocolData[i]);

									//allen geeigneten Tasks zuordnen (ja, allen!)
									for (j = 0; j < model.tasks.length; j++) {
										if (model.tasks[j].taskId === pdi.taskId) {
											for (k = 0; k < model.tasks[j].protocols.length; k++) {
												if (model.tasks[j].protocols[k].id === pdi.protocolId) {
													if (!model.tasks[j].protocols[k].protocolData)
														model.tasks[j].protocols[k].protocolData = [];
													pg.replaceOrPushObj(model.tasks[j].protocols[k].protocolData, pdi);
												}
											}
										}
									}
								}
							}
//console.log("x " + new Date().getTime() + " -> " + response.taskHistory.length);
							//es wird stets die komplette Historie übertragen, daher:
							//wenn für einen Auftrag Inhalte vorliegen, vor dem Einfügen die bestehenden Werte entfernen
							var hist = [],
								th,
								taskIds = [];
							if (response.taskHistory) {

								//entpacken
								for (i = 0; i < response.taskHistory.length; i++) {
									th = HistoryItem.createHistoryItem().fromObj(response.taskHistory[i]);
									hist.push(th);
									taskIds.push(th.entityId);
								}
								taskIds = pg.getUnique(taskIds);

								//aufräumen
								for (i = 0; i < taskIds.length; i++) {
									var taskId = taskIds[i];
									for (j = 0; j < model.tasks.length; j++) {
										if (model.tasks[j].taskId === taskId) {
											model.tasks[j].clearHistory();
										}
									}
								}

								//allen geeigneten Tasks zuordnen (ja, allen!)
								for (i = 0; i < hist.length; i++) {
									th = hist[i];
									for (j = 0; j < model.tasks.length; j++)
										if (model.tasks[j].taskId === th.entityId)
											model.tasks[j].history.push(th);
								}
							}
//console.log("g " + new Date().getTime());
							//------------------------
							//incidents zuordnen

							var inc;

							if (response.incidents) {
								for (j = 0; j < response.incidents.length; j++) {

									//Protokoll finden
									var protocolId = parseInt(response.incidents[j].protocolId);
									p = Protocol.getProtocol(protocolId);

									inc = Incident.createIncidentRaw().fromObj(response.incidents[j]);

									if (p)
										p.addIncident(inc);
								}
							}
							
							//signals zuordnen
							if (response.signals) {
								for (k = 0; k < response.signals.length; k++) {

									//Incident finden
									var incidentId = parseInt(response.signals[k].incidentId);
									inc = Task.getIncident(incidentId);

									var sig = Signal.createSignalRaw().fromObj(response.signals[k]);

									if (inc)
										inc.addSignal(sig);
								}
							}
//console.log("y " + new Date().getTime());
							//jetzt alles zusammenführen
							Task.updateTasks();
//console.log("e " + new Date().getTime());
							//--------------------------------
							//Requests
							
							//Modul zu diesem Auftragstyp vorhanden?
							if (model.client.hasModule(constants.MODULE_ID_REQUESTS)) {

								if (response.requests) {
									newCount += response.requests.length;
									for (i = 0; i < response.requests.length; i++) {

										//Modul zu diesem Auftragstyp vorhanden?
										var r = Request.createRequest().fromObj(response.requests[i]);
										tt = TaskType.getTaskType(r.taskTypeId);
										if (tt.requiredModuleId) {
											if (!model.client.hasModule(tt.requiredModuleId))
												continue;
										}

										//ok (oder kein Modul erforderlich)
										pg.replaceOrPushObj(model.requests, r, Request.mergeRequests);
									}
								}
								
								//erst jetzt die separaten States und Protokolle ergänzen
								//noinspection JSUnresolvedVariable
								if (response.requestStates) {
									//noinspection JSUnresolvedVariable
									for (i = 0; i < response.requestStates.length; i++) {
										//noinspection JSUnresolvedVariable
										s = RequestState.createRequestState().fromObj(response.requestStates[i]);
										//allen geeigneten Requests zuordnen (ja, allen!)
										for (j = 0; j < model.requests.length; j++)
											if (model.requests[j].requestId === s.requestId)
												pg.replaceOrPushObj(model.requests[j].states, s);
									}
								}

								//es wird stets die komplette Historie übertragen, daher:
								//wenn für einen Auftrag Inhalte vorliegen, vor dem Einfügen die bestehenden Werte entfernen
								//noinspection JSUnresolvedVariable
								if (response.requestHistory) {

									//entpacken
									hist = [];
									var requestIds = [];
									for (i = 0; i < response.requestHistory.length; i++) {
										th = HistoryItem.createHistoryItem().fromObj(response.requestHistory[i]);
										hist.push(th);
										requestIds.push(th.entityId);
									}
									requestIds = pg.getUnique(requestIds);

									//aufräumen
									for (i = 0; i < requestIds.length; i++) {
										var requestId = requestIds[i];
										for (j = 0; j < model.requests.length; j++) {
											if (model.requests[j].requestId === requestId) {
												model.requests[j].clearHistory();
											}
										}
									}

									//allen geeigneten Request zuordnen (ja, allen!)
									for (i = 0; i < hist.length; i++) {
										th = hist[i];
										for (j = 0; j < model.requests.length; j++)
											if (model.requests[j].requestId === th.entityId)
												model.requests[j].history.push(th);
									}
								}

								Request.updateRequests();
							}
							
							//--------------------------------
							//Comments
							if (response.comments) {
								for(i = 0; i < response.comments.length; i++) {
									var c = Comment.createComment().fromObj(response.comments[i]);
									c.assign();
								}
							}
							
							//--------------------------------

							var tsParsingComplete = new Date().getTime();
							var tsParsingTime = tsParsingComplete - tsInit;
							//console.log("done parsing " + tsParsingTime);

							//--------------------------------
//console.log("b " + new Date().getTime());
							if (callbackComplete)
								callbackComplete(newCount);
						}
						else {
							
							//Session nicht mehr gültig?
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								//sonstiger Serverfehler
								if (callbackComplete)
									callbackComplete(null);
							}
						}
					}
					catch (err) {
						if (verbose)
							logManager.logError(err);
						if (callbackComplete)
							callbackComplete(null);
					}
				})
				.fail(function (xhr) {
					if (verbose) {
						//noinspection JSUnresolvedVariable
						logManager.logError("Fehler beim Laden der Basis-Daten: " + xhr.responseText);
					}
					if (callbackComplete)
						callbackComplete(null);
				});
		},
		
		//----------------------------------------------------------------------
		
		//Auftrag speichern
		prevSaveTaskTs: 0,
		saveTask: function (t, ts, sendPn, callback) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevSaveTaskTs;
			if (delta < 1000)
				return;
			this.prevSaveTaskTs = now;

			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				taskType: t.taskTypeId,
				locationType: t.locationType,
				intervalType: t.intervalType,
				taskId: t.taskId,
				title: t.title,
				taskStateId: ts.taskStateId,
				clientId: model.curClientId,
				ownerId: t.ownerId,
				description: pg.validateInput(t.description, 4096),
				userGroupIds: ts.userGroupIds,
				responsibleId: ts.responsibleId,
				dueDate: t.dueDate,
				endDate: t.endDate,
				locations: t.locations,
				intervalId: t.intervalId,
				generationId: t.generationId,
				intervalStatus: t.intervalStatus,
				attachments: t.attachments,
				attachmentsArray: t.attachmentsArray,
				reminders: t.reminders,
				requestId: t.requestId,
				sendPn: sendPn,
				dynData: t.dynData,
				labelId: ts.labelId,
				comment: ts.comment,
				taskStatus: t.status,
				status: ts.status,
				groupId: ts.groupId,
				//deletedAttachments: t.deletedAttachments
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/tasks/add',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							
							var isNew = (t.taskId <= 0);

							//attachments
							Attachment.deleteAttachments(constants.ENTITY_TYPE_TASK, t.taskId);
							if (response.attachments) {
								for(var j = 0; j < response.attachments.length; j++) {
									var att = Attachment.createAttachmentRaw().fromObj(response.attachments[j]);
									Attachment.replaceOrPushObj(model.attachments, att);
								}
							}

							var tResult = Task.createTask().fromObj(response.task);
							tResult.updateDynData();
							tResult.update();
							tResult.updateAttachments();
							model.tasks.push(tResult);
							Task.updateTasks();

							//history aktualisieren
							if (response.history) {
								tResult.clearHistory();
								for (var i=0; i<response.history.length; i++) {
									var th = HistoryItem.createHistoryItem().fromObj(response.history[i]);
									tResult.history.push(th);
								}
							}
							
							if (callback)
								callback(isNew, tResult);
						}
						else {
							
							//Session nicht mehr gültig
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.saveTask): " + xhr.responseText);
				});
		},
		
		//-------------------------------------------------

		//Zeitstempel wegen UPK-1193
		prevSaveTaskStateTs: {},

		//speichere TaskState
		saveTaskState: function (t, ts, callback) {

			//suche: letzter Call zu dieser Id?
			var now = new Date().getTime();
			var prevTs = this.prevSaveTaskStateTs[t.taskId];
			if (prevTs){
				var delta = now - prevTs;
				//max alle 2s
				if (delta < 1000*2){
					console.log("zu schnell: " + delta + " // " + t.taskId);
					return;
				}
			}
			//merken
			this.prevSaveTaskStateTs[t.taskId] = now;

			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				taskId: t.taskId,
				taskStateId: ts.taskStateId,
				taskStateVersion: ts.version,
				clientId: model.curClientId,
				userGroupIds: ts.userGroupIds,
				responsibleId: ts.responsibleId,
				labelId: ts.labelId,
				comment: ts.comment,
				taskStatus: t.status,
				status: ts.status,
				groupId: ts.groupId
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/tasks/route',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							
							//übernehmen
							//noinspection JSUnresolvedVariable
							var tsNew = TaskState.createTaskState().fromObj(response.taskState);
							t.states.push(tsNew);
							t.update();
							
							//ggf. Änderung an Antrag markieren
							if (response.requestState){
								var rs = RequestState.createRequestState().fromObj(response.requestState);
								var r = Request.getRequest(rs.requestId);
								pg.replaceOrPushObj(r.states, rs);
							}

							//history aktualisieren
							if (response.history) {
								t.clearHistory();
								for (var i=0; i<response.history.length; i++) {
									var th = HistoryItem.createHistoryItem().fromObj(response.history[i]);
									t.history.push(th);
								}
							}
							
							if (callback)
								callback(t, ts);
						}
						else {
							
							//Session nicht mehr gültig
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.saveTask): " + xhr.responseText);
				});
			
		},
		
		
		//-------------------------------------------------
		
		//Request speichern
		prevSaveRequestTs: 0,
		saveRequest: function (t, ts, callback) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevSaveRequestTs;
			if (delta < 1000)
				return;
			this.prevSaveRequestTs = now;
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				taskType: t.taskTypeId,
				locationType: t.locationType,
				intervalType: t.intervalType,
				title: t.title,
				clientId: model.curClientId,
				ownerId: t.ownerId,
				description: pg.validateInput(t.description, 4096),
				dueDate: t.dueDate,
				endDate: t.endDate,
				locations: t.locations,
				attachments: t.attachments,
				attachmentsArray: t.attachmentsArray,
				requestId: t.requestId,
				status: ts ? ts.status : -1,
				deciderId: ts ? ts.deciderId : -1,
				responsibleId: ts ? ts.responsibleId : -1,
				comment: ts ? ts.comment : null,
				dynData: t.dynData,
				groupId: ts ? ts.groupId : -1,
				//deletedAttachments: t.deletedAttachments
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/requests/add',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							
							var isNew = (t.requestId <= 0);

							//attachments
							Attachment.deleteAttachments(constants.ENTITY_TYPE_REQUEST, t.requestId);
							if (response.attachments) {
								for(var j = 0; j < response.attachments.length; j++) {
									var att = Attachment.createAttachmentRaw().fromObj(response.attachments[j]);
									Attachment.replaceOrPushObj(model.attachments, att);
								}
							}

							var tResult = Request.createRequest().fromObj(response.request);
							tResult.updateDynData();
							tResult.update();
							tResult.updateAttachments();
							model.requests.push(tResult);
							Request.updateRequests();

							//history aktualisieren
							if (response.history) {
								tResult.clearHistory();
								for (var i=0; i<response.history.length; i++) {
									var th = HistoryItem.createHistoryItem().fromObj(response.history[i]);
									tResult.history.push(th);
								}
							}

							if (callback)
								callback(isNew, tResult);
						}
						else {
							
							//Session nicht mehr gültig
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.saveRequest): " + xhr.responseText);
				});
		},
		
		//-------------------------------------------------
		
		//Auftrags-Staus (aktiv/...) speichern
		/*prevExecUpdateTaskIntervalStatusTs: 0,
		updateTaskIntervalStatus: function (t, callback) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevExecUpdateTaskIntervalStatusTs;
			if (delta < 1000)
				return;
			this.prevExecUpdateTaskIntervalStatusTs = now;
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				clientId: model.curClientId,
				id: t.id,
				intervalStatus: t.intervalStatus
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/tasks/setIntervalStatus',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							
							//update changedOn
							t.changedOn = pg.parseDate(response.changedOn);

							//history aktualisieren
							if (response.history) {
								t.clearHistory();
								for (var i=0; i<response.history.length; i++) {
									var th = HistoryItem.createHistoryItem().fromObj(response.history[i]);
									t.history.push(th);
								}
							}

							if (callback)
								callback(t);
						}
						else {
							
							//Session nicht mehr gültig
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.updateTaskIntervalStatus): " + xhr.responseText);
				});
			
		},*/
		
		//------------------------------------------------------------------------------------------
		
		//Benutzer speichern
		prevSaveUserTs: 0,
		saveUser: function (u, callback) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevSaveUserTs;
			if (delta < 1000)
				return;
			this.prevSaveUserTs = now;
			
			if (!u.successorUserGroupId)
				u.successorUserGroupId = -1;
			if (!u.abandonedTasks)
				u.abandonedTasks = [];
			if (!u.cleanedUpTasks)
				u.cleanedUpTasks = [];
			if (!u.groups)
				u.groups = "";
			if (!u.successorResponsibleUserId)
				u.successorResponsibleUserId = -1;
			if (!u.abandonedResponsibleTasks)
				u.abandonedResponsibleTasks = [];

			//nur für sich selbst Benachrichtigungen änderbar - oder bei Erstanlage!
			var nTypes = null;
			if ((u.id < 0) || (authManager.hasPermission(UserRole.PERMISSION_USER_EDIT_SELF) && (u.id === model.curUserId))) {
				nTypes = u.getNotificationTypes();
			}

			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				userId: u.id,
				clientId: model.curClientId,
				firstName: pg.validateInput(u.firstName, 255),
				lastName: pg.validateInput(u.lastName, 255),
				status: u.status,
				singleUserGroupId: u.singleUserGroupId,
				email: pg.validateInput(u.email, 255),
				role: u.role,
				groups: u.groups,
				locationTypes: u.locationTypes,
				fon: u.fon,
				department: u.department,
				notificationTypes: nTypes,
				newClientOwnerId: u.newClientOwnerId,

				//Passwort ändern
				pwdHashOld: u.pwdHashOld ? u.pwdHashOld : null,
				pwdHashNew: u.pwdHashNew ? u.pwdHashNew : null,
				
				//Nachfolger fur verschiedene Operationen
				successorUserGroupId: u.successorUserGroupId,
				abandonedTasks: u.abandonedTasks,
				cleanedUpTasks: u.cleanedUpTasks,
				successorResponsibleUserId: u.successorResponsibleUserId,
				abandonedResponsibleTasks: u.abandonedResponsibleTasks,
				successorRequestOwnerUserId: u.successorRequestOwnerUserId,
				abandonedOwnerRequests: u.abandonedOwnerRequests,
				successorRequestDeciderUserId: u.successorRequestDeciderUserId,
				abandonedDeciderRequests: u.abandonedDeciderRequests,
				successorRequestResponsibleUserId: u.successorRequestResponsibleUserId,
				abandonedResponsibleRequests: u.abandonedResponsibleRequests
			};

			var i;
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/users/save',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							
							var newUser = User.createUser().fromObj(response.user);
							
							var isNew = (u.id < 0);
							if (isNew) {
								
								//neuer Benutzer
								model.users.push(newUser);
								
								//neue UserGroup merken
								var ug = UserGroup.createUserGroup().fromObj(response.userGroup);
								model.userGroups.push(ug);
							}
							else {
								
								pg.replaceOrPushObj(model.users, newUser);
								
								//aktualisierte Teams übernehmen
								model.userGroups = [];
								for(i = 0; i < response.userGroups.length; i++)
									model.userGroups.push(UserGroup.createUserGroup().fromObj(response.userGroups[i]));
							}

							//geänderte TaskStates übernehmen
							//noinspection JSUnresolvedVariable
							if (response.updatedTaskStates){
								//noinspection JSUnresolvedVariable
								for(i = 0; i < response.updatedTaskStates.length; i++) {
									//noinspection JSUnresolvedVariable
									var ts = TaskState.createTaskState().fromObj(response.updatedTaskStates[i]);
									//allen geeigneten Tasks zuordnen (ja, allen!)
									for(var j = 0; j < model.tasks.length; j++)
										if (model.tasks[j].taskId === ts.taskId)
											pg.replaceOrPushObj(model.tasks[j].states, ts);
								}
							}

							//Benachrichtigungen erhalten
							newUser.notificationTypes = u.notificationTypes;

							if (callback)
								callback(newUser, isNew, u);
							
						}
						else {
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.saveUser): " + xhr.responseText);
				});
		},
		
		//-------------------------------------------------
		
		//Usergroup speichern
		prevSaveUserGroupTs: 0,
		saveUserGroup: function (ug, callback) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevSaveUserGroupTs;
			if (delta < 1000)
				return;
			this.prevSaveUserGroupTs = now;
			
			if (!ug.successorUserGroupId)
				ug.successorUserGroupId = -1;
			if (!ug.abandonedTasks)
				ug.abandonedTasks = [];
			if (!ug.cleanedUpTasks)
				ug.cleanedUpTasks = [];
			
			var users = "";
			for(var i = 0; i < ug.users.length; i++)
				users += ug.users[i].id + "#";
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				userGroupId: ug.id,
				clientId: model.curClientId,
				name: pg.validateInput(ug.name, 255),
				status: ug.status,
				users: users,
				successorUserGroupId: ug.successorUserGroupId,
				abandonedTasks: ug.abandonedTasks,
				cleanedUpTasks: ug.cleanedUpTasks,
				abandonedUpTasks: ug.abandonedUpTasks
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/usergroups/save',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							
							var ugResult = UserGroup.createUserGroup().fromObj(response.userGroup);
							var isNew = (ug.id < 0);
							if (isNew) {
								
								//neues Team
								model.userGroups.push(ugResult);
							}
							else {
								pg.replaceOrPushObj(model.userGroups, ugResult);
							}

							//geänderte TaskStates übernehmen
							//noinspection JSUnresolvedVariable
							if (response.updatedTaskStates){
								//noinspection JSUnresolvedVariable
								for(i = 0; i < response.updatedTaskStates.length; i++) {
									//noinspection JSUnresolvedVariable
									var ts = TaskState.createTaskState().fromObj(response.updatedTaskStates[i]);
									//allen geeigneten Tasks zuordnen (ja, allen!)
									for(var j = 0; j < model.tasks.length; j++)
										if (model.tasks[j].taskId === ts.taskId)
											pg.replaceOrPushObj(model.tasks[j].states, ts);
								}
							}
							
							if (callback)
								callback(ugResult, isNew);
							
						}
						else {
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.saveUserGroup): " + xhr.responseText);
				});
		},
		
		//------------------------------------------------------------------------------------------
		
		//Credentials überprüfen
		prevVerifyCredentialsTs: 0,
		verifyCredentials: function (email, password, clientId, callback, resetSession) {

			var that = this;

			if (resetSession === undefined)
				resetSession = true;

			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevVerifyCredentialsTs;
			if (delta < 1000)
				return;
			this.prevVerifyCredentialsTs = now;

			var data = {
				appVersion: constants.RELEASE_VERSION,
				email: pg.validateInput(email.toLowerCase(), 255),
				password: pg.validateInput(password, 255),
				clientId: clientId,
				width: $(window).width(),
				height: $(window).height()
			};
			data.model = navigator.userAgent;
			//noinspection JSUnresolvedVariable
			data.manufacturer = navigator.vendor;
			data.os = navigator.platform;
			//noinspection JSUnresolvedVariable
			data.osVersion = navigator.oscpu || "";

			//Klick bevor das Modell initial aufgebaut
			if (resetSession) {
				if (!model.resetLogin)
					return;
				//clear
				model.resetLogin();
			}
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/users/verify',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {

						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							
							//Multi-Client
							if (!response.user) {
								
								model.accounts = [];
								for (var i=0; i<response.accounts.length; i++){
									model.accounts.push(Account.createAccount().fromObj(response.accounts[i]));
								}
								//sortieren nach Name
								model.accounts.sort(function (a, b) {
									return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
								});

								//reset
								that.prevVerifyCredentialsTs = 0;

								callback({
									success: 1,
									accounts: model.accounts,
									firstName: response.firstName,
									lastName: response.lastName
								});
							}
							else {
								
								//Client
								model.curClientId = parseInt(response.user.clientId);
								
								//ok
								model.curUserId = parseInt(response.user.id, 10);
								model.curSessionToken = response.sessionToken;
								
								if (callback)
									callback({
										success: 1,
										loginId: response.loginId,
										loginToken: response.loginToken,
										email: email,
										password: password
									});
							}
							
						}
						else {
							if (callback)
								callback({
									success: 0,
									error: response.error,
									errorId: response.errorId || 0,
									clientName: response.clientName,
									clientOwner: response.clientOwner
								});
						}
					}
					catch (err) {
						logManager.logError(err);
						if (callback)
							callback();
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.verifyCredentials): " + xhr.responseText);
					if (callback)
						callback();
				});
		},
		
		//Token überprüfen
		verifyToken: function (loginId, loginToken, callback) {
			
			var data = {
				appVersion: constants.RELEASE_VERSION,
				loginId: loginId,
				loginToken: loginToken
			};
			
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/verify/token',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					
					var response = pg.getJson(dataResp);
					if (parseInt(response.success) === 1) {
						
						//apply results
						model.curClientId = parseInt(response.clientId);
						model.curUserId = parseInt(response.userId);
						model.curSessionToken = response.sessionToken;

						//Accounts holen
						if (response.accounts) {
							model.accounts = [];
							for (var i = 0; i < response.accounts.length; i++) {
								model.accounts.push(Account.createAccount().fromObj(response.accounts[i]));
							}
							//sortieren nach Name
							model.accounts.sort(function (a, b) {
								return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
							});
						}

						callback({
							loginId: loginId,
							loginToken: response.loginToken
						});
						
					}
					else {
						model.resetLogin();
						callback({
							error: response.error,
							errorId: response.errorId || 0
						});
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.verifyToken): " + xhr.responseText);
					model.resetLogin();
					callback({
						error: "Es ist ein Fehler aufgetreten: " + xhr.responseText
					});
				});
		},
		
		//Check: Session noch gültig?
		verifyValidSession: function (sessionToken, callback) {
			
			//nicht online?
			if (!navigator.onLine)
				return;
			
			var data = {
				appVersion: constants.RELEASE_VERSION,
				sessionToken: sessionToken
			};

			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/verify/session',
					data: JSON.stringify(data)
				})
				.done(function (dataResp, statusText, xhr) {

					if (xhr.status !== 200) {
						//sonst würden die User wegen einer fehlerhaften Verbindung aus der App geworfen!
						callback(true);
					}
					else {
						var response = pg.getJson(dataResp);
						callback(parseInt(response.success) === 1);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					//logManager.logError("Fehler (dataManager.verifySession): " + xhr.responseText);
					callback(true);//sonst würden die User wegen einer fehlerhaften Verbindung aus dem Backend geworfen!
				});
		},
		
		//Token zurücksetzen
		logout: function (callback) {
			
			var data = {
				appVersion: constants.RELEASE_VERSION,
				sessionToken: model.curSessionToken,
				clientId: model.curClientId
			};
			
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/users/logout',
					data: JSON.stringify(data)
				})
				.done(function (dataResp, statusText, xhr) {

					if (xhr.status !== 200) {
						//sonst würden die User wegen einer fehlerhaften Verbindung aus der App geworfen!
						callback(true);
					}
					else {

						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							callback(true);
						} else {
							model.resetLogin();
							callback(false);
						}
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.logout): " + xhr.responseText);
					callback(false);
				});
		},
		
		//------------------------------------------------------------------------------------------
		
		//Location wurde geändert
		prevAddLocationDataTs: 0,
		addLocationData: function (l, hasDataBeenChanged, callback) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevAddLocationDataTs;
			if (delta < 1000)
				return;
			this.prevAddLocationDataTs = now;
			
			if (!hasDataBeenChanged)
				hasDataBeenChanged = 0;
			else
				hasDataBeenChanged = hasDataBeenChanged ? 1 : 0;
			
			var loc = {
				id: l.id,
				locationId: l.locationId,
				parentLocationId: l.parentLocationId,
				clientId: l.clientId,
				version: l.version,
				hasDataBeenChanged: hasDataBeenChanged,
				name: pg.validateInput(l.name, 255),
				nameShort: pg.validateInput(l.nameShort, 255),
				isAbstract: l.isAbstract,
				depth: l.depth,
				path: l.path,
				type: l.type,
				data: l.data,
				formFields: l.formFields,
				ownerId: l.ownerId,
				status: l.status,
				attachments: l.attachments,
				attachmentsArray: l.attachmentsArray
			};

			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				location: loc
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/locations/add',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							
							var l2 = Location.createLocation().fromObj(response.location);
							var ld = [];
							//noinspection JSUnresolvedVariable
							for(var i = 0; i < response.location.data.length; i++) {
								//noinspection JSUnresolvedVariable
								ld.push(LocationData.createLocationData().fromObj(response.location.data[i]));
							}
							l2.data = ld;

							//auch FormFields
							l2.resetFormFields();
							for(i = 0; i < l.formFields.length; i++) {
								l2.addFormField(l.formFields[i]);
							}

							callback(l2);
						}
						else {
							//Session nicht mehr gültig
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.addLocationData): " + xhr.responseText);
				});
		},
		
		//-------------------------------------------------
		
		//Prüfungskonfiguration wurde geändert
		prevSetLocationFormFieldsTs: 0,
		setLocationFormFields: function (l, callback) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevSetLocationFormFieldsTs;
			if (delta < 1000)
				return;
			this.prevSetLocationFormFieldsTs = now;
			
			//doppelte Einträge entfernen (#496)
			var ff = (l.formFields || []);
			ff.sort(function (a, b) {
				var va = a.formId*100000 + a.formFieldId;
				var vb = b.formId*100000 + b.formFieldId;
				return ((va < vb) ? -1 : ((va > vb) ? 1 : 0));
			});
			for (var i=ff.length-1; i>1; i--){
				var f1 = ff[i];
				var f2 = ff[i-1];
				if ((f1.formId === f2.formId) && (f1.formFieldId === f2.formFieldId))
					ff.splice(i, 1);
			}
			
			var loc = {
				locationId: l.locationId,
				clientId: l.clientId,
				formId: l.formId,
				formFields: ff
			};
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				location: loc
			};

			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/locations/setFormFields',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							
							if (callback)
								callback(response.success === 1);
							
						}
						else {
							
							//Session nicht mehr gültig
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.setLocationFormFields): " + xhr.responseText);
				});
		},
		
		//-------------------------------------------------
		
		//Status (aktiv/..) einer Location wurde geändert
		prevSetLocationStatusTs: 0,
		setLocationStatus: function (l, descendents, status, callback) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevSetLocationStatusTs;
			if (delta < 1000)
				return;
			this.prevSetLocationStatusTs = now;
			
			//sollte Array sein
			var descendentIds = [];
			for(var i = 0; i < descendents.length; i++) {
				descendentIds.push(descendents[i].id);
			}
			
			var loc = {
				id: l.id,
				descendents: descendentIds,
				status: status
			};
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				clientId: model.curClientId,
				location: loc
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/locations/setStatus',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							
							if (callback)
								callback(l, response.results);
						}
						else {
							
							//Session nicht mehr gültig
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.setLocationStatus): " + xhr.responseText);
				});
		},
		
		//-------------------------------------------------
		
		//Anhang auf Server löschen
		deleteAttachment: function (url, clientId, fileToken) {
			
			if (url === "")
				return;
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				url: url,
				clientId: clientId || -1,
				fileToken: fileToken || null
			};
			
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/attachments/delete',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 0) {
							
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.setLocationAttachments): " + xhr.responseText);
				});
		},
		
		//Anhänge einer Location speichern (Link, nicht Daten)
		prevSetLocationAttachmentsTs: 0,
		setLocationAttachments: function (l) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevSetLocationAttachmentsTs;
			if (delta < 1000)
				return;
			this.prevSetLocationAttachmentsTs = now;
			
			var loc = {
				id: l.id,
				attachments: l.attachments,
				attachmentsArray: l.attachmentsArray
			};
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				clientId: model.curClientId,
				location: loc
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/locations/setAttachments',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 0) {
							
							//Session nicht mehr gültig
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
						else{
							//Zeitstempel übernehmen
							l.changedOn = pg.parseDate(response.changedOn);
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.setLocationAttachments): " + xhr.responseText);
				});
		},
		
		//---------------------------------------------------------------------------------------------
		
		//Datenbank für diesen Kunden zurücksetzen
		prevResetDbTs: 0,
		resetProject: function (callback) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevResetDbTs;
			if (delta < 1000)
				return;
			this.prevResetDbTs = now;
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				clientId: model.curClientId
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/projects/reset',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							if (callback)
								callback();
						}
						else {
							
							//Session nicht mehr gültig
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.resetProject): " + xhr.responseText);
				});
		},
		
		//Projekt duplizieren
		prevCloneDbTs: 0,
		cloneProject: function (name, clientType, callback) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevCloneDbTs;
			if (delta < 1000)
				return;
			this.prevCloneDbTs = now;
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				clientId: model.curClientId,
				name: name,
				clientType: clientType
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/projects/clone',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							
							//merken
							var acc = Account.createAccount().fromObj({
								id: response.clientId,
								name: name
							});
							model.accounts.push(acc);
							
							if (callback)
								callback(acc);
						}
						else {
							
							//Session nicht mehr gültig
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.cloneProject): " + xhr.responseText);
				});
		},
		
		//-----------------------------------------------------------------
		
		//Kundendaten speichern
		prevSaveClientTs: 0,
		saveClient: function (c, callback) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevSaveClientTs;
			if (delta < 1000)
				return;
			this.prevSaveClientTs = now;
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				client: c
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/client/save',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							
							c = c.fromObj(response.client);
							if (callback)
								callback(c);
						}
						else {
							
							//Session nicht mehr gültig
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler: " + xhr.responseText);
				});
		},
		
		//-----------------------------------------------------------------
		
		//geändertes Signal speichern
		prevStoreSignalTs: 0,
		storeSignal: function (signal, callback) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevStoreSignalTs;
			if (delta < 150)
				return;
			this.prevStoreSignalTs = now;
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				signal: signal
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/signals/save',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							
							if (callback)
								callback(response);
							
						}
						else {
							
							//Session nicht mehr gültig
							//noinspection JSUnresolvedVariable
							if (parseInt(response.sessionValid) === 0) {
								dataManager.onInvalidSession();
							}
							else {
								pg.showMsg(response.error);
								viewManager.updateButtons();
							}
						}
					}
					catch (err) {
						logManager.logError(err);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler: " + xhr.responseText);
				});
		},
		
		//------------------------------------------

		//Token überprüfen
		validateInvitationToken: function (token, callback) {

			var data = {
				appVersion: constants.RELEASE_VERSION,
				token: token
			};

			//query server
			$.ajax({
				type: 'post',
				cache: false,
				url: constants.API_BASE + '/users/validateInvitationToken',
				data: JSON.stringify(data)
			})
				.done(function (dataResp) {
					try {

						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {

							if (callback)
								callback({
									isValidToken: true,
									firstName: response.firstName,
									lastName: response.lastName,
									email: response.email,
									clientName: response.clientName
								});
						}
						else if (callback)
							callback({
								isValidToken: false
							});
					}
					catch (err) {
						logManager.logError(err);
						if (callback)
							callback({
								isValidToken: false
							});
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler: " + xhr.responseText);
					if (callback)
						callback({
							isValidToken: false
						});
				});

		},

		//Einladungsemail zusenden
		prevResendCredentialsTs: 0,
		sendInvitation: function (email, callback) {
			
			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevResendCredentialsTs;
			if (delta < 1000)
				return;
			this.prevResendCredentialsTs = now;
			
			var data = {
				appVersion: constants.RELEASE_VERSION,
				actorId: model.curUserId,
				email: email.toLowerCase(),
				clientId: model.curClientId
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/users/sendInvitation',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {
						
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							if (callback)
								callback(true);
						}
						else if (callback)
							callback(false);
					}
					catch (err) {
						logManager.logError(err);
						if (callback)
							callback(false);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler: " + xhr.responseText);
					if (callback)
						callback(false);
				});
			
		},

		//Einladung annehmen
		acceptInvitation: function (invitationToken, pwdHash, firstName, lastName, callback) {

			var data = {
				appVersion: constants.RELEASE_VERSION,
				invitationToken: invitationToken,
				newPasswordHash: pwdHash,
				firstName: firstName,
				lastName: lastName
			};

			//query server
			$.ajax({
				type: 'post',
				cache: false,
				url: constants.API_BASE + '/users/acceptInvitation',
				data: JSON.stringify(data)
			})
				.done(function (dataResp) {
					try {

						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							if (callback)
								callback(true);
						}
						else if (callback)
							callback(false);
					}
					catch (err) {
						logManager.logError(err);
						if (callback)
							callback(false);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler: " + xhr.responseText);
					if (callback)
						callback(false);
				});

		},
		
		//------------------------------------------

		//Token überprüfen
		validatePwdResetToken: function (token, callback) {

			var data = {
				appVersion: constants.RELEASE_VERSION,
				token: token
			};

			//query server
			$.ajax({
				type: 'post',
				cache: false,
				url: constants.API_BASE + '/users/validatePwdResetToken',
				data: JSON.stringify(data)
			})
				.done(function (dataResp) {
					try {

						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							if (callback)
								callback(true);
						}
						else if (callback)
							callback(false);
					}
					catch (err) {
						logManager.logError(err);
						if (callback)
							callback(false);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler: " + xhr.responseText);
					if (callback)
						callback(false);
				});

		},

		//Angaben zu Passwort-Vergessen überprüfen
		prevVerifyPasswordForgottenTs: 0,
		verifyPasswordForgotten: function (email, callback) {

			//Dopplungen vermeiden
			var now = new Date().getTime();
			var delta = now - this.prevVerifyPasswordForgottenTs;
			if (delta < 1000)
				return;
			this.prevVerifyPasswordForgottenTs = now;

			var data = {
				appVersion: constants.RELEASE_VERSION,
				email: pg.validateInput(email.toLowerCase(), 255)
			};

			//query server
			$.ajax({
				type: 'post',
				cache: false,
				url: constants.API_BASE + '/users/pwdForgotten',
				data: JSON.stringify(data)
			})
				.done(function (dataResp) {
					try {

						//remove BOM + parse json
						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {

							callback(true);
						}
						else {
							if (response.error)
								pg.showMsg(response.error);

							if (callback)
								callback(false);
						}
					}
					catch (err) {
						logManager.logError(err);
						if (callback)
							callback(false);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler (dataManager.verifyCredentials): " + xhr.responseText);
					if (callback)
						callback(false);
				});
		},

		//Passwort-zurücksetzen ausführen
		execUpdatePwd: function (resetPwdToken, newPwdHash, callback) {
			
			var data = {
				appVersion: constants.RELEASE_VERSION,
				resetPwdToken: resetPwdToken,
				newPasswordHash: newPwdHash
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/users/updatePassword',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					try {

						var response = pg.getJson(dataResp);
						if (parseInt(response.success) === 1) {
							if (callback)
								callback(true);
						}
						else if (callback)
							callback(false);
					}
					catch (err) {
						logManager.logError(err);
						if (callback)
							callback(false);
					}
				})
				.fail(function (xhr) {
					//noinspection JSUnresolvedVariable
					logManager.logError("Fehler: " + xhr.responseText);
					if (callback)
						callback(false);
				});
			
		},
		
		//---------------------------------------------------------------------------------
		
		//Protokoll speichern (z.B. Auftrag)
		saveProtocol: function (task, protocol, callback) {
			
			var that = this;

			var protChangedOn = pg.parseDate(protocol.submittedOn || protocol.changedOn);
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				id: protocol.id,
				submittedOn: protChangedOn.getTime(),
				userId: protocol.userId,
				clientId: model.curClientId,
				taskId: protocol.taskId,
				formId: protocol.formId,
				locationId: protocol.locationId,
				status: protocol.status,
				protocolData: protocol.protocolData,
				taskStatus: task.status,
				attachments: protocol.attachments,
				attachmentsArray: protocol.attachmentsArray,
				incidents: protocol.incidents,
				isTransferredCompletely: protocol.isTransferredCompletely || 0,
				renameUploads: protocol.renameUploads ? 1 : 0
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/protocols/add',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					
					var response = pg.getJson(dataResp);
					if (parseInt(response.success) === 1) {
						
						//Daten gemäß Server aktualisieren
						var p = response.protocol[response.protocol.length - 1];
						protocol = protocol.fromObj(p);
						
						var isDuplicate = false;
						if (response.isDuplicate)
							isDuplicate = (parseInt(response.isDuplicate) === 1);
						
						if (!isDuplicate) {

							//attachments
							if (response.attachments) {
								for(var j = 0; j < response.attachments.length; j++) {
									var att = Attachment.createAttachmentRaw().fromObj(response.attachments[j]);
									Attachment.replaceOrPushObj(model.attachments, att);
								}
							}
							protocol.updateAttachments();

							//erzeugte Incidents/Signals anhängen
							protocol.incidents = [];
							for(var i = 0; i < response.incidents.length; i++) {
								var inc = Incident.createIncidentRaw().fromObj(response.incidents[i]);
								protocol.incidents.push(inc);
							}
							
							//neuer TaskState? (wgn. Label-Reset)
							//noinspection JSUnresolvedVariable
							if (response.taskState) {
								//noinspection JSUnresolvedVariable
								task.states.push(TaskState.createTaskState().fromObj(response.taskState));
								task.update();
							}
							
							//history aktualisieren
							if (response.history) {
								task.clearHistory();
								for(i = 0; i < response.history.length; i++) {
									var th = HistoryItem.createHistoryItem().fromObj(response.history[i]);
									task.history.push(th);
								}
							}
							
							//Daten dazu
							if (!protocol.protocolData)
								protocol.protocolData = [];
							if (response.protocolData) {
								for(i = 0; i < response.protocolData.length; i++) {
									var pdi = ProtocolDataItem.createProtocolDataItem().fromObj(response.protocolData[i]);
									pg.replaceOrPushObj(protocol.protocolData, pdi);
								}
							}
							
							//generated?
							if (response.generatedTask) {
								//übernehmen
								model.tasks.push(Task.createTask().fromObj(response.generatedTask/*, constants.DATA_SOURCE_SERVER*/));
								Task.updateTasks();
							}
							
							if (callback)
								callback(protocol);
						}
						else{
							//var dataObj.isDuplicate = true;
							if (callback)
								callback(protocol);
						}
					}
					else {
						//Session nicht mehr gültig
						//noinspection JSUnresolvedVariable
						if (parseInt(response.sessionValid) === 0) {
							dataManager.onInvalidSession();
						}
						else {
							pg.showMsg(response.error);
							viewManager.updateButtons();
						}
					}
				});
		},
		
		//---------------------------------------------------------------------------------
		
		//Übertragung vollständig melden
		//Aufruf kommt aus der Pipeline, daher kein Einfügen in die Pipeline erforderlich!
		setProtocolTransmitted: function (protocol, callback) {
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				appBuild: constants.RELEASE_BUILD,
				id: protocol.id,
				clientId: model.curClientId,
				isTransferredCompletely: 1
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/protocols/transmitted',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					
					var response = pg.getJson(dataResp);
					
					if (parseInt(response.success) === 1) {
						
						protocol = Protocol.createProtocol().fromObj(response.protocol);
						callback(protocol);
						
					} else {
						//Session nicht mehr gültig
						//noinspection JSUnresolvedVariable
						if (parseInt(response.sessionValid) === 0) {
							dataManager.onInvalidSession();
						} else {
							pg.showMsg(response.error);
							viewManager.updateButtons();
						}
						callback();
					}
				});
		},
			
		//----------------------------------------------------------------------------------
		
		//neuer/geänderter Status eines Requests
		addRequestState: function (request, requestState, callback) {
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				requestState: requestState
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/requests/addStatus',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					
					var response = pg.getJson(dataResp);
					if (parseInt(response.success) === 1) {
						
						requestState = requestState.fromObj(response.requestState);
						//Fix
						if (requestState.status < RequestState.STATUS_ADDON)
							requestState.status += RequestState.STATUS_ADDON;

						//zu request hinzufügen
						var r = Request.getRequest(request.requestId);
						r.states.push(requestState);
						r.update();

						//history aktualisieren
						if (response.history) {
							r.clearHistory();
							for (var i=0; i<response.history.length; i++) {
								var th = HistoryItem.createHistoryItem().fromObj(response.history[i]);
								r.history.push(th);
							}
						}

						//noinspection JSUnresolvedVariable
						if (callback)
							callback(requestState);
					}
					else {
						//Session nicht mehr gültig
						//noinspection JSUnresolvedVariable
						if (parseInt(response.sessionValid) === 0) {
							dataManager.onInvalidSession();
						}
						else {
							pg.showMsg(response.error);
							viewManager.updateButtons();
						}
						callback();
					}
				});
			
		},
		
		//--------------------------------------------------------------------------------------
		
		//neuer Kommentar
		saveComment: function(entity, comment, callback){

			//HTML-Tags entfernen - sonst wird es direkt ausgeführt
			comment.comment = comment.comment.replace(/<\/?[^>]+(>|$)/g, "");

			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				comment: comment
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/comments/add',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					
					var response = pg.getJson(dataResp);
					if (parseInt(response.success) === 1) {
						
						//aktualisiertes Objekt übernehmen
						comment = comment.fromObj(response.comment);
						
						//merken
						entity.addComment(comment);

						//history aktualisieren
						if (response.history) {
							entity.clearHistory();
							for (var i=0; i<response.history.length; i++) {
								var th = HistoryItem.createHistoryItem().fromObj(response.history[i]);
								entity.history.push(th);
							}
						}
						
						//noinspection JSUnresolvedVariable
						if (callback)
							callback(comment);
					}
					else {
						//Session nicht mehr gültig
						//noinspection JSUnresolvedVariable
						if (parseInt(response.sessionValid) === 0) {
							dataManager.onInvalidSession();
						}
						else {
							pg.showMsg(response.error);
							viewManager.updateButtons();
						}
						callback();
					}
				});
		},
		
		//--------------------------------------------------------------------------------------
		
		//AutoArchive-Settings
		setAutoArchive: function(client, callback){
			
			var c = _.cloneDeep(client);
			c.logoData = "";
			
			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				client: c
			};
			
			//query server
			$.ajax({
					type: 'post',
					cache: false,
					url: constants.API_BASE + '/client/setAutoArchive',
					data: JSON.stringify(data)
				})
				.done(function (dataResp) {
					
					var response = pg.getJson(dataResp);
					if (parseInt(response.success) === 1) {
						
						model.client = Client.createClient().fromObj(response.client);
						
						//noinspection JSUnresolvedVariable
						if (callback)
							callback(model.client);
					}
					else {
						//Session nicht mehr gültig
						//noinspection JSUnresolvedVariable
						if (parseInt(response.sessionValid) === 0) {
							dataManager.onInvalidSession();
						}
						else {
							pg.showMsg(response.error);
							viewManager.updateButtons();
						}
					}
				});
		},

		//Auto-Release Requests
		setAutoReleaseRequests: function(client, callback){

			var c = _.cloneDeep(client);
			c.logoData = "";

			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				client: c
			};

			//query server
			$.ajax({
				type: 'post',
				cache: false,
				url: constants.API_BASE + '/client/setAutoRelease',
				data: JSON.stringify(data)
			})
				.done(function (dataResp) {

					var response = pg.getJson(dataResp);
					if (parseInt(response.success) === 1) {

						model.client = Client.createClient().fromObj(response.client);

						//noinspection JSUnresolvedVariable
						if (callback)
							callback(model.client);
					}
					else {
						//Session nicht mehr gültig
						//noinspection JSUnresolvedVariable
						if (parseInt(response.sessionValid) === 0) {
							dataManager.onInvalidSession();
						}
						else {
							pg.showMsg(response.error);
							viewManager.updateButtons();
						}
					}
				});
		},

		//Email Reports
		setClientEmailReportSettings: function(client, callback){

			var c = _.cloneDeep(client);
			c.logoData = "";

			var data = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				client: c
			};

			//query server
			$.ajax({
				type: 'post',
				cache: false,
				url: constants.API_BASE + '/client/setEmailReports',
				data: JSON.stringify(data)
			})
				.done(function (dataResp) {

					var response = pg.getJson(dataResp);
					if (parseInt(response.success) === 1) {

						model.client = Client.createClient().fromObj(response.client);

						//noinspection JSUnresolvedVariable
						if (callback)
							callback(model.client);
					}
					else {
						//Session nicht mehr gültig
						//noinspection JSUnresolvedVariable
						if (parseInt(response.sessionValid) === 0) {
							dataManager.onInvalidSession();
						}
						else {
							pg.showMsg(response.error);
							viewManager.updateButtons();
						}
					}
				});
		},

		//-------------------------------------------------------------------------
		//PDF Reports

		getLocationPdfUrl: function(locationName, locationId){

			var name = encodeURI(connectionManager.hardenUrl(locationName + " - Stammdatenblatt.pdf"));

			return [
				constants.API_BASE,
				'/reports/location/',
				name,
				"?sessionToken=" + model.curSessionToken,
				"&clientId=" + model.curClientId,
				"&locationId=" + locationId
			].join("");

		},

		getProtocolPdfUrl: function(locationName, locationId, protocolName, protocolId, protocolDate, includeLocationData){

			var name = "";
			if (locationId > 0){
				name = encodeURI(connectionManager.hardenUrl(locationName + " - " + protocolName + " ID" + protocolId + " - " + protocolDate.getFullYear() + "-" + pg.forceLength((protocolDate.getMonth()+1), 2) + "-" + pg.forceLength((protocolDate.getDate()), 2) + ".pdf"));
			}
			else{
				name = encodeURI(connectionManager.hardenUrl(protocolName + " ID" + protocolId + " - " + protocolDate.getFullYear() + "-" + pg.forceLength((protocolDate.getMonth()+1), 2) + "-" + pg.forceLength((protocolDate.getDate()), 2) + ".pdf"));
				includeLocationData = 0;
			}

			return [
				constants.API_BASE,
				'/reports/protocol/',
				name,
				"?sessionToken=" + model.curSessionToken,
				"&clientId=" + model.curClientId,
				"&protocolId=" + protocolId,
				"&locationId=" + locationId,
				"&includeLocationData=" + includeLocationData
			].join("");

		}

	};
	
	window.dataManager = dataManager;
}());
