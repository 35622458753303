(function () {
	
	//-------------------------------------------------------------
	//Liste der Aufträge verwalten
	//-------------------------------------------------------------
	
	/*global
	constants:true,
	viewManager:true,
	Task:true,
	TaskState:true,
	TaskType:true,
	UserGroup:true,
	Location:true,
	LocationType:true,
	authManager:true,
	UserRole:true,
	TaskTypeField:true,
	 dataManager:true
	*/
	
	'use strict';
	
	var taskListManager = {
		
		//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
		checkModelChanged: function(data, oldModel){
			
			var that = taskListManager;
			
			var i,
				j,
				k,
				t,
				ts,
				tNew,
				tt,
				tsOld,
				tsNew,
				hasMatch;

			//welche Daten prüfen?
			var matchTT = false;
			var matchOwner = false;
			if (!authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_ALL)) {
				if (authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_OWN)) {
					matchOwner = true;
				}
				else{
					if (authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_BY_TASK_TYPE)){
						matchTT = true;
					}
				}
			}
			
			if (data.tasks) {
				for(i = 0; i < data.tasks.length; i++) {
					
					//würde dieses Objekt überhaupt gesehen?
					tNew = Task.createTask().fromObj(data.tasks[i]);
					tt = -1;
					if (matchTT)
						tt = tNew.taskType;
					//State ergänzen
					if (tNew.isNew === 1){
						if (data.taskStates) {
							for (j = 0; j < data.taskStates.length; j++) {
								if (parseInt(data.taskStates[j].taskId) === tNew.taskId)
									ts = TaskState.createTaskState().fromObj(data.taskStates[j]);
								pg.replaceOrPushObj(tNew.states, ts);
							}
						}
					}
					if (!tNew.canBeSeenByUser(matchOwner, tt, that.curGroupId))
						continue;
					//passen die aktuellen Filter?
					if (!tNew.matchesFilter(model.curTaskListView.filter))
						continue;
					
					hasMatch = false;
					
					for (k=0; k<oldModel.tasks.length; k++) {
						t = oldModel.tasks[k];
						
						if (!t.isCurrentVersion)
							continue;
						
						if (parseInt(tNew.taskId) === t.taskId) {
							hasMatch = true;
							
							//nur, wenn nicht selbst gelöscht
							if ((t.getMasterStatus() !== constants.TASK_STATUS_DELETED) || (parseInt(tNew.getMasterStatus()) !== constants.TASK_STATUS_DELETED)){
								tsOld = t.changedOn.getTime();
								tsNew = tNew.changedOn.getTime();
								if (tsNew !== tsOld)
									return true;
							}
						}
					}
					//neues Objekt
					if (!hasMatch)
						return true;
				}
			}
			if (data.taskStates) {
				for(i = 0; i < data.taskStates.length; i++) {
					
					//immer das alte Objekt nehmen - oben wurde schon das neue getestet! (wir brauchen die alten States)
					//noinspection JSUnresolvedVariable
					tNew = Task.getTask(data.taskStates[i].taskId);
					if (!tNew)
						continue;
					//würde dieses Objekt überhaupt gesehen?
					tt = -1;
					if (matchTT)
						tt = tNew.taskType;
					ts = TaskState.createTaskState().fromObj(data.taskStates[i]);
					if (!tNew.canBeSeenByUser(matchOwner, tt, that.curGroupId, ts)) {
						//konnte vorher gesehen werden? (wurde archiviert/wiederhergestellt)
						if (tNew.canBeSeenByUser(matchOwner, tt, that.curGroupId)) {
							return true;
						}
						continue;
					}
					//passen die aktuellen Filter?
					if (!tNew.matchesFilter(model.curTaskListView.filter))
						continue;
					
					hasMatch = false;
					
					for (k=0; k<oldModel.tasks.length; k++) {
						t = oldModel.tasks[k];
						
						if (parseInt(data.taskStates[i].taskId) === t.taskId) {
							
							for(j = 0; j < t.states.length; j++) {
								
								if (t.states[j].id === parseInt(data.taskStates[i].id)) {
									hasMatch = true;
									tsOld = t.states[j].changedOn.getTime();
									tsNew = pg.parseDate(data.taskStates[i].changedOn).getTime();
									if (tsNew !== tsOld)
										return true;
								}
							}
						}
					}
					//neues Objekt
					if (!hasMatch)
						return true;
				}
			}
			if (data.protocols) {
				for(i = 0; i < data.protocols.length; i++) {
					
					tNew = Task.getTask(data.protocols[i].taskId);
					if (!tNew)
						continue;
					//würde dieses Objekt überhaupt gesehen?
					tt = -1;
					if (matchTT)
						tt = tNew.taskType;
					if (!tNew.canBeSeenByUser(matchOwner, tt, that.curGroupId))
						continue;
					
					hasMatch = false;
					
					for(j = 0; j < tNew.protocols.length; j++) {
						
						if (tNew.protocols[j].id === parseInt(data.protocols[i].id)) {
							hasMatch = true;
							tsOld = tNew.protocols[j].changedOn.getTime();
							tsNew = pg.parseDate(data.protocols[i].changedOn).getTime();
							if (tsNew !== tsOld)
								return true;
						}
					}
					
					//neues Objekt
					if (!hasMatch)
						return true;
				}
			}
			if (data.incidents) {
				for(i = 0; i < data.incidents.length; i++) {
					
					tNew = Task.getTask(data.incidents[i].taskId);
					if (!tNew)
						continue;
					//würde dieses Objekt überhaupt gesehen?
					tt = -1;
					if (matchTT)
						tt = tNew.taskType;
					if (!tNew.canBeSeenByUser(matchOwner, tt, that.curGroupId))
						continue;
					
					hasMatch = false;
					
					var incId = parseInt(data.incidents[i].id);
					var oldInc = tNew.getIncident(incId);
					if (oldInc){
						hasMatch = true;
						tsOld = oldInc.changedOn.getTime();
						tsNew = pg.parseDate(data.incidents[i].changedOn).getTime();
						if (tsNew !== tsOld)
							return true;
					}
					
					//neues Objekt
					if (!hasMatch)
						return true;
				}
			}
			if (data.signals) {
				for(i = 0; i < data.signals.length; i++) {
					
					tNew = Task.getTask(data.signals[i].taskId);
					if (!tNew)
						continue;
					//würde dieses Objekt überhaupt gesehen?
					tt = -1;
					if (matchTT)
						tt = tNew.taskType;
					if (!tNew.canBeSeenByUser(matchOwner, tt, that.curGroupId))
						continue;
					
					hasMatch = false;
					
					//not my business
					if (!t.canBeSeenByUser(matchOwner, tt, that.curGroupId))
						continue;
					
					var sigId = parseInt(data.signals[i].id);
					var oldSig = tNew.getSignal(sigId);
					if (oldSig){
						hasMatch = true;
						tsOld = oldSig.changedOn.getTime();
						tsNew = pg.parseDate(data.signals[i].changedOn).getTime();
						if (tsNew !== tsOld)
							return true;
					}
					
					//neues Objekt
					if (!hasMatch)
						return true;
				}
			}
			
			return false;
			
		},
		
		//Tasks mit bestimmtem Status/User zeigen (-1 = alle)
		showTasks: function(userGroupIds, userGroup, groupId, callbackComplete){
			
			var that = this;
			
			if (!authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_ALL,
					UserRole.PERMISSION_TASK_LIST_OWN,
					UserRole.PERMISSION_TASK_LIST_BY_TASK_TYPE)){
				viewManager.execShowPage(constants.PAGE_OVERVIEW, null, null, null, callbackComplete);
				return false;
			}
			
			if (groupId === undefined)
				groupId = 0;
			else
				groupId = parseInt(groupId);
			this.curGroupId = groupId;
			viewManager.resetStateDetails();
			
			$("#content").html(window.templates['taskList.html']);
			setTimeout(function(){
				
				if (groupId === constants.GROUP_ARCHIVE) {
					$("#taskTitle").html("Archiv");
					viewManager.setSelectedMenuItem('menuTasks', 'menuTasksArchive');
					$(".breadcrumbArchive").removeClass("hidden");
				}
				else {
					$("#taskTitle").html("Auftr&auml;ge");
					viewManager.setSelectedMenuItem('menuTasks', 'menuTasksActive');
					$(".breadcrumbActive").removeClass("hidden");
				}
				
				//welche Daten anzeigen?
				if (authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_ALL)) {
					model.curTasks = Task.getTasks({
						allowOlderChildren: true,
						groupId: groupId
					});
				}
				else{
					if (authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_OWN)) {
						model.curTasks = Task.getTasks({
							userId: model.curUserId,
							allowOlderChildren: true,
							getOwnersTasksToo: true,
							groupId: groupId
						});
					}
					else{
						if (authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_BY_TASK_TYPE)) {
							//alle holen, filtern!
							var allTasks = Task.getTasks({
								allowOlderChildren: true,
								groupId: groupId
							});
							model.curTasks = _.filter(allTasks, function(o){
								return (model.curUser.hasTaskType(o.taskTypeId));
							});
						}
					}
				}

				//nur diejenigen gemäß LocationTypes
				var j;
				if (!model.curUser.hasAllLocationTypes()) {
					var arr = [];
					for (j = 0; j < model.curTasks.length; j++) {
						var t = model.curTasks[j];

						//UPK-1299 (ggf. temporär) Aufträge ohne Locations trotzdem anzeigen
						if (t.locationType > 0){

							//passt zu taskType?
							if (!model.curUser.hasLocationType(t.locationType))
								continue;
						}

						arr.push(t);
					}
					model.curTasks = arr;
				}

				//sortieren nach taskId, DESC
				model.curTasks.sort(function(a, b){
					return b.taskId - a.taskId;
				});

				var restoreScrollPos = 0;
				if (model.curTaskListView.preserveCurPage) {
					model.curTaskListView.preserveCurPage = false;
					restoreScrollPos = model.curTaskListView.scrollPos || 0;
				}
				else{
					model.curTaskListView.curPage = 0;
					//model.curTaskListView.entriesPerPage = 25;
				}
				
				$("#taskPaginationBox .viewsPerPage").val(model.curTaskListView.entriesPerPage);
				
				taskListManager.showTaskItems(model.curTasks, model.curTaskListView);
				
				//--------------------------------------------------------
				//filter
				
				//taskType
				var s = '<option value="-1">Alle</option>';
				var tt = TaskType.getAllTaskTypes();
				//nur zugeordnete Typen anbieten
				tt = _.filter(tt, function(o){
					return (model.curUser.hasTaskType(o.typeId));
				});
				tt.sort(function (a, b) {
					return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
				});
				for (var i=0; i<tt.length; i++)
					s += '<option value=' + tt[i].typeId + '>' + tt[i].name + '</option>';
				$("#filterTaskType").html(s);
				
				//Status
				var statusOptions = [];
				statusOptions.push({value: -1, name: 'Alle'});
				statusOptions.push({value: 0, name: 'Offen'});
				statusOptions.push({value: 2, name: 'In Bearbeitung'});
				statusOptions.push({value: 1, name: 'Erledigt'});
				for (i=0; i<model.taskLabels.length; i++)
					statusOptions.push({value: model.taskLabels[i].id, name: model.taskLabels[i].name});
				//sortieren
				statusOptions.sort(function(a, b){
					return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
				});
				s = '';
				for (i=0; i<statusOptions.length; i++)
					s += '<option value="' + statusOptions[i].value + '">' + statusOptions[i].name + '</option>';
				$("#filterTaskStatus").html(s);

				//userGroup
                s = '<option value="-1">Alle</option>';
                //doch doch, auch gelöschte anzeigen!
                var uGroups = UserGroup.getActiveUserGroups(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
                //zunächst User
                var hasOptGroup = false;
                for (i = 0; i < uGroups.length; i++) {
                    if (uGroups[i].isSingleUser) {
                        if (!hasOptGroup) {
                            hasOptGroup = true;
                            s += '<optgroup label="Benutzer">';
                        }
                        s += "<option value='" + uGroups[i].id + "'>" + uGroups[i].name + "</option>";
                    }
                }
                if (hasOptGroup)
                    s += '</optgroup>';
                hasOptGroup = false;
                for (i = 0; i < uGroups.length; i++) {
                    if (!uGroups[i].isSingleUser) {
                        if (!hasOptGroup) {
                            hasOptGroup = true;
                            s += '<optgroup label="Teams">';
                        }
                        s += "<option value='" + uGroups[i].id + "'>" + uGroups[i].name + "</option>";
                    }
                }
                if (hasOptGroup)
                    s += '</optgroup>';
                $("#filterTaskRouting").html(s);
                //select2
                //noinspection JSUnresolvedVariable
                $.fn.select2.defaults.set("theme", "bootstrap");
                $(".select2, .select2-multiple").select2();
				
				//-------------
    
				//locationType
				s = '<option value="-1">Alle</option>';
				var lTypes = model.locationTypes;
				//nur zugeordnete Typen anbieten
				lTypes = _.filter(lTypes, function(o){
					return (model.curUser.hasLocationType(o.id));
				});
				lTypes.sort(function (a, b) {
					return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
				});
				for (i=0; i<lTypes.length; i++){
					var lt = lTypes[i];
					s += '<option value=' + lt.id + '>' + lt.name + '</option>';
				}
				$("#filterTaskLocationType").html(s).select2();
				
				//Filter: Locations gemäß aktuellem Typ als Liste anbieten
				$("#filterTaskLocationType").on("change", function(){
					//reset LocationIds
					model.curTaskListView.filter.locationId = [-1];
					that.updateLocationFilter();
				});
				that.updateLocationFilter();
				
				//-------------
				
				$('.date-picker').datepicker({
					language: "de",
					format: 'dd.mm.yy',
					todayHighlight: true,
					autoclose: true
				});

				$("#taskTable th.canSort").on("click", that.onTaskListSort);
				
				//--------------------------------------------------------
				
				//Auswahl-Operationen
				
				var hasAction = false;
				
				var mayArchive = authManager.hasPermission(UserRole.PERMISSION_TASK_ARCHIVE_ALL, UserRole.PERMISSION_TASK_ARCHIVE_OWN, UserRole.PERMISSION_TASK_ARCHIVE_BY_TASK_TYPE);
				if (mayArchive){
					hasAction = true;
					
					var o;
					if (that.curGroupId === constants.GROUP_DEFAULT) {
						o = new Option("Archivieren", "archive");
						$(o).html("Archivieren");
						$("#taskListSelectionBox .selectionActions").append(o);
					}
					if (that.curGroupId === constants.GROUP_ARCHIVE) {
						o = new Option("Wiederherstellen", "restore");
						$(o).html("Wiederherstellen");
						$("#taskListSelectionBox .selectionActions").append(o);
					}
				}
				
				if (hasAction) {
					$("#taskListSelectionBox").show();
					$("#taskListSelectionBox .btnExecSelectionAction").click(function(){
						switch($("#taskListSelectionBox .selectionActions").val()){
							case "archive":
								that.archiveSelection();
								break;
							case "restore":
								that.restoreSelection();
								break;
							default:
								viewManager.updateButtons();
								break;
						}
					});
				}
				else
					$("#taskListSelectionBox").hide();
				
				//Auswahl
				$("#chkTaskMaster").off("change").on("change", function(){
					
					that = taskListManager;
					
					if (this.checked)
						$(".chkSelectTask").prop("checked", true);
					else
						$(".chkSelectTask").prop("checked", false);
					
					that.updateSelectionDisplay();
				});
				$(".chkSelectTask").off("change").on("change", function(){
					that = taskListManager;
					that.updateSelectionDisplay();
				});
				
				//--------------------------------------------------------
				
				//handler
                if (!authManager.hasPermission(UserRole.PERMISSION_TASK_CREATE_ALL, UserRole.PERMISSION_TASK_CREATE_BY_TASK_TYPE)) {
                    $("#btnAddTask").hide();
                }
                else {
                    $("#btnAddTask").on("click", function () {
                        viewManager.showPage(constants.PAGE_TASK_ADD);
                    });
                }

                $("#btnBackTask").off("click").on("click", function(){
					window.history.back();
				});
				
				viewManager.updateButtons();
				
				//---------------------------
				
				setTimeout(function(){

					viewManager.setScrollTop(restoreScrollPos);
					
					//nur bestimmter User
					if (userGroupIds){
						
						model.curTaskListView = {
							filter: {
								status: [0, 2]
							},
							sortDirection: constants.SORTING_NONE,
							sortField: null,
							pageCount: 0,
							entriesPerPage: 10,
							curPage: 0,
							scrollPos: 0,
							preserveCurPage: false
						};
						for (i=0; i<model.taskLabels.length; i++)
							model.curTaskListView.filter.status.push(model.taskLabels[i].id);
						
						//userGroupIds sind mehrere Gruppen, nämlich alle des Users!
						var a = [];
						for (j=0; j<userGroupIds.length; j++)
							a.push(userGroupIds[j].id);
						model.curTaskListView.filter.routing = a;
					}
					
					//restore filter
					that.restoreTaskListFilter(model.curTaskListView);
					
					if (userGroupIds)
						taskListManager.showTaskItems(model.curTasks, model.curTaskListView);
					
					//----------------------------------------------------------
					//Top-Scroller
					$("#taskTableScroller").scroll(function(){
						$("#taskTableBox")
							.scrollLeft($("#taskTableScroller").scrollLeft());
					});
					$("#taskTableBox").scroll(function(){
						$("#taskTableScroller")
							.scrollLeft($("#taskTableBox").scrollLeft());
					});
					that.updateTopScroller();

					//-----------------------------------------------------------
					
					//warum auch immer mit Timeout?!
					setTimeout(function(){
						$("#taskTable .form-filter").on("change", that.onTaskListFilter);
					}, 500);

					if (callbackComplete)
						callbackComplete();

				}, 100);
			}, constants.TEMPLATE_DELAY);
		},
		
		//anhand der derzeitigen Location-Filter-Einstellung alle Location-Namen ermitteln
		//und per Select2 anbieten
		updateLocationFilter: function(){
			
			//Typ holen
			var locationType = parseInt($("#filterTaskLocationType").val());
			
			//danach filtern
			var locations = Location.getLocations();
			var filteredLocations = [];
			var i;
			if (locationType === -1){
				//alle
				for(i=0; i<locations.length; i++) {
					if (locations[i].isAbstract === 0)
						filteredLocations.push(locations[i]);
				}
			}
			else {
				for(i=0; i<locations.length; i++) {
					if ((locations[i].type === locationType) && (locations[i].isAbstract === 0))
						filteredLocations.push(locations[i]);
				}
			}
			
			//sortieren
			filteredLocations.sort(function (a, b) {
				var na = a.getName(true);
				var nb = b.getName(true);
				return ((na < nb) ? -1 : ((na > nb) ? 1 : 0));
			});
			
			//zusammensetzen
			//var s = '<option value="-1">Alle</option>';
			var s = "";
			for (i=0; i<filteredLocations.length; i++){
				var loc = filteredLocations[i];
				s += '<option value=' + loc.locationId + '>' + loc.getName(true) + '</option>';
			}
			$("#filterTaskLocation").html(s).select2();

			setTimeout(function(){
				$("#filterTaskLocation.isFiltered").removeClass("isFiltered");
			}, 100);

			
		},
		
		//------------------------------------------------------------------------------------------------------
		//Archivieren / Wiederherstellen
		
		archiveSelection: function(){
			this.setSelectionGroup(this.getSelectedTaskIds(), constants.GROUP_ARCHIVE);
			
		},
		restoreSelection: function(){
			this.setSelectionGroup(this.getSelectedTaskIds(), constants.GROUP_DEFAULT);
		},
		
		setSelectionGroup: function(taskIds, groupId){
			
			var that = this;
			var count = 0;
			var promises = [];
			
			for (var i=0; i<taskIds.length; i++) {
				
				var t = Task.getTask(taskIds[i]);
				
				//schon gesetzt?
				if (groupId === constants.GROUP_DEFAULT)
					if (!t.isArchived())
						continue;
				if (groupId === constants.GROUP_ARCHIVE)
					if (t.isArchived())
						continue;
				
				if (t.canBeArchived()){
					
					//direkt zählen, aber erst nach Abschluss aller Aktionen weitermachen
					promises.push(this.setTaskGroup(t, groupId));
					count++;
				}
			}
			
			if (count > 0){
				
				Q.all(promises).then(function(){
					
					that.resetSelection();
					that.updateSelectionDisplay();
					viewManager.updateButtons();
					
					var msg = pg.getStemmedName(count, "Auftrag", "Aufträge") + " wurde(n) erfolgreich aktualisiert.";
					viewManager.showNotification(msg, constants.NOTIFICATION_SUCCESS);
				});
			}
			else{
				viewManager.showNotification("In der Auswahl befindet sich kein Auftrag, der die Kriterien für diese Operation besitzt.", constants.NOTIFICATION_INFO);
				viewManager.updateButtons();
			}
			
		},
		
		setTaskGroup: function(t, groupId){
			
			var that = this;
			
			var def = Q.defer();
			
			var ts = t.addTaskState();
			ts.groupId = groupId;
			
			//hier kein Update aus Model erforderlich, weil Objekt sowieso nur aus Ansicht entfernt werden kann
			
			dataManager.saveTaskState(t, ts, function(){
				def.resolve();
			});
			
			//weg damit
			this.removeTaskFromView(t.taskId, function(){
				that.updateSelectionDisplay();
			});
			
			return def.promise;
		},
		
		//selektierte Aufträge holen
		getSelectedTaskIds: function() {
			var taskIds = [];
			$(".chkSelectTask:checked").each(function(index, o){
				var taskId = parseInt($(o).attr("data-taskId"));
				taskIds.push(taskId);
			});
			return taskIds;
		},
		
		//Auswahl zurücksetzen
		resetSelection: function(){
			$(".chkSelectTask").prop("checked", false);
			$("#chkTaskMaster").prop("checked", false);
		},
		
		//selektierte Aufträge anzeigen
		updateSelectionDisplay: function(){
			
			var count = 0;
			$(".chkSelectTask:checked").each(function() {
				if ($(this).is(':visible'))
					count++;
			});
			
			var msg;
			if (count > 0)
				msg = "<strong>" + pg.getStemmedName(count, "Auftrag", "Aufträge") + "</strong> ausgewählt.";
			else
				msg = "Kein Auftrag ausgewählt.";
			$("#taskListSelectionBox .selectionDescription").html(msg);
		},
		
		//------------------------------------------------------------------------------------------------------
		
		removeTaskFromView: function(taskId, callback){
			
			taskId = parseInt(taskId);
			for (var i=0; i<model.curTasks.length; i++){
				if (model.curTasks[i].taskId === taskId){
					model.curTasks.splice(i, 1);
					break;
				}
			}
			
			$("#taskTable tr.taskListItem[data-taskId='" + taskId + "']").fadeOut(400, callback);
		},
		
		//------------------------------------------------------------------------------------------------------
		
		//Filter wiederherstellen
		restoreTaskListFilter: function(filterDef){
			
			//filter
			/*jshint -W089 */
			for (var key in filterDef.filter) {
				$("#taskTable .filter [data-filter-id='" + key + "']").val(filterDef.filter[key]);
				try {
					$("#taskTable .filter .select2[data-filter-id='" + key + "']").select2();
				}
				catch(e){}
			}
			
			//sorting
			switch (filterDef.sortDirection){
				case constants.SORTING_ASC:
					$("[data-sort-id='" + filterDef.sortField + "']").addClass("constants.SORTING_ASC");
					break;
				case constants.SORTING_DESC:
					$("[data-sort-id='" + filterDef.sortField + "']").addClass("constants.SORTING_DESC");
					break;
			}
			
			//Filter anzeigen
			this.showTaskListFilters(filterDef);
		},
		
		//Filter wurde geändert
		onTaskListFilter: function(){

			var fieldId = $(this).attr("data-filter-id");
			
			//merken
			model.curTaskListView.filter[fieldId] = $(this).val();
			
			//anwenden
			model.curTaskListView.curPage = 0;
			taskListManager.showTaskItems(model.curTasks, model.curTaskListView);
		},
		
		//Filter-Anzeige aktualisieren
		showTaskListFilters: function(filterDef){

			var filterActive = false;

			//zurücksetzen
            $("#btnRemoveTaskListFilters").addClass("disabled");
            $("#taskTableBox .filter .isFiltered").removeClass("isFiltered");

			//einzeln aktivieren
			//jshint -W089
			for (var key in filterDef.filter){

				var val = filterDef.filter[key];
				if (val){

					if ($.isArray(val)){
						//Sonderfall -1
						var isSettingAll = false;
						for (var i=0; i<val.length; i++)
							if (val[i] === "-1"){
								isSettingAll = true;
								break;
							}
						if (isSettingAll)
							continue;
					}
					else
					//Sonderfall -1
					if (val === "-1")
						continue;

					filterActive = true;

					$("#taskTableBox .filter *[data-filter-id='" + key + "']").addClass("isFiltered");
				}
			}

			if (filterActive)
                $("#btnRemoveTaskListFilters").removeClass("disabled");

		},

		//Filter zurücksetzen
		resetFilters: function(showItems){

			if (showItems === undefined)
				showItems = true;

			//Filter leeren
			model.curTaskListView.filter = {};
			this.restoreTaskListFilter(model.curTaskListView);
			
			//Anzeige zurücksetzen
			$("#taskTableBox .filter input").val("");
			$("#taskTableBox .filter select").val("-1");

			//Select2 zurücksetzen
            $("#taskTableBox .filter .select2").val("-1").trigger("change");
            $("#taskTableBox .filter .select2-multiple").val("").trigger("change");

            if (showItems)
				taskListManager.showTaskItems(model.curTasks, model.curTaskListView);
		},
		
		//------------------------------------------------------------------------------------------------------
		
		//Sortierung wurde geändert
		onTaskListSort: function(e){
			
			var that = taskListManager;
			
			//wer?
			var col = $(e.currentTarget);
			var sortField = col.attr("data-sort-id");
			var isSortingAsc = col.hasClass("sorting_asc");
			var isSortingDesc = col.hasClass("sorting_desc");
			
			$("#taskTable th.canSort").removeClass("sorting_asc sorting_desc");
			var sortDir = constants.SORTING_NONE;
			if (isSortingAsc){
				sortDir = constants.SORTING_DESC;
				$(this).addClass("sorting_desc");
			}
			else
			if (!isSortingDesc){
				sortDir = constants.SORTING_ASC;
				$(this).addClass("sorting_asc");
			}
			
			//merken
			model.curTaskListView.sortDirection = sortDir;
			if (model.curTaskListView.sortDirection === constants.SORTING_NONE)
				model.curTaskListView.sortField = null;
			else
				model.curTaskListView.sortField = sortField;
			
			//anwenden
			that.showTaskItems(model.curTasks, model.curTaskListView);
		},
		
		//----------------------------------------------------------------------------
		
		//Listen-Einträge rendern
		showTaskItems: function(tasks, filterObj){
			
			var that = this;
			
			//Filter anzeigen
			this.showTaskListFilters(filterObj);
			
			//filter
			var trgTasks = [];
			for (var i=0; i<tasks.length; i++){
				var tt = tasks[i];
				
				if (tt.isCurrentVersion){
					
					if (tt.matchesFilter(filterObj.filter)){
						
						tt.updateIncidents();
						
						tt.updateSortValue(filterObj.sortField, filterObj.sortDirection);
						trgTasks.push(tt);
					}
				}
			}
			
			//sort
			if (filterObj.sortDirection !== constants.SORTING_NONE){
				trgTasks.sort(function(a, b){
					return ((a.sortVal < b.sortVal) ? -1 : ((a.sortVal > b.sortVal) ? 1 : 0));
				});
				//desc
				if (filterObj.sortDirection === constants.SORTING_DESC)
					trgTasks.reverse();
			}
			
			//--------------
			
			//pagination
			var startIndex, endIndex;
			model.curTaskListView.entriesPerPage = parseInt($("#taskPaginationBox .viewsPerPage").val());
			if (model.curTaskListView.entriesPerPage < 0){
				model.curTaskListView.pageCount = 1;
				startIndex = 0;
				endIndex = trgTasks.length;
			}
			else{
				model.curTaskListView.pageCount = Math.ceil(trgTasks.length / model.curTaskListView.entriesPerPage);
				startIndex = model.curTaskListView.curPage*model.curTaskListView.entriesPerPage;
				endIndex = Math.min((model.curTaskListView.curPage+1)*model.curTaskListView.entriesPerPage, trgTasks.length);
			}
			
			if (model.curTaskListView.curPage === 0)
				$("#taskPaginationBox .btn.prev").addClass("disabled");
			else
				$("#taskPaginationBox .btn.prev").removeClass("disabled");
			if (model.curTaskListView.curPage >= model.curTaskListView.pageCount-1)
				$("#taskPaginationBox .btn.next").addClass("disabled");
			else
				$("#taskPaginationBox .btn.next").removeClass("disabled");
			
			//--------------
			
			//Inhalte
			var s = '';
			for (i=startIndex; i<endIndex; i++){
				var t = trgTasks[i];
				
				if (t.isCurrentVersion){
					
					var ts = t.getCurrentState();
					if (i % 2 === 0)
						s += '<tr data-taskId="' + t.taskId + '" class="taskListItem gradeX even" role="row">';
					else
						s += '<tr data-taskId="' + t.taskId + '" class="taskListItem gradeX odd" role="row">';

                    //-------------------------
					//Batch Action Checkbox
					s += '<td style="height: 56px;">';
					s += '<label class="mt-checkbox mt-checkbox-single mt-checkbox-outline">';
					s += '	<input data-taskId="' + t.taskId + '" type="checkbox" class="group-checkable chkSelectTask">';
					s += '<span></span>';
					s += '</label>';
					s += '</td>';

					//-------------------------
					//Aktionen

					s += '<td>';
					s += '  <div class="btn-group">';
					if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
						s += '      <button data-taskId="' + t.taskId + '" type="button" class="btn grey-mint btn-xs btnTaskDetails">Details</i></button>';
					else
						s += '      <button type="button" class="btn grey-mint btn-xs disabled">Details</i></button>';
					s += '      <button type="button" class="btn grey-mint btn-xs dropdown-toggle" data-toggle="dropdown"><i class="fa fa-angle-down"></i></button>';
					s += '	    <ul class="dropdown-menu">';

					var mayArchive = false;
					if (authManager.hasPermission(UserRole.PERMISSION_TASK_ARCHIVE_ALL)) {
						mayArchive = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_TASK_ARCHIVE_OWN)) {
							if (t.ownerId === model.curUserId)
								mayArchive = true;
							if (ts.responsibleId === model.curUserId)
								mayArchive = true;
						}
						else {
							if (authManager.hasPermission(UserRole.PERMISSION_TASK_ARCHIVE_BY_TASK_TYPE)) {
								if (model.curUser.hasTaskType(t.taskTypeId))
									mayArchive = true;
							}
						}
					}

					//kann Auftrag überhaupt archiviert werden?
					if (mayArchive)
						mayArchive = t.canBeArchived();

					if (that.curGroupId === constants.GROUP_DEFAULT) {
						if (mayArchive)
							s += '<li><a data-taskId="' + t.taskId + '" class="btnTaskArchive"> Archivieren </a></li>';
						else
							s += '<li><a class="disabled"> Archivieren </a></li>';
					}
					if (that.curGroupId === constants.GROUP_ARCHIVE) {
						if (mayArchive)
							s += '<li><a data-taskId="' + t.taskId + '" class="btnTaskRestore"> Wiederherstellen </a></li>';
						else
							s += '<li><a class="disabled"> Wiederherstellen </a></li>';
					}

					s += '      </ul>';
					s += '  </div>';
					s += '</td>';

                    //----------------------------------
					//ID

                    if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
                        s += '<td class="btnTaskDetails cell-clickable font-semibold" data-taskId="' + t.taskId + '">#' + t.taskId + '</td>';
                    else
                        s += '<td class="font-semibold">#' + t.taskId + '</td>';

                    //----------------------------------
					//Status

                    if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
                        s += '<td class="btnTaskDetails cell-clickable" data-taskId="' + t.taskId + '">';
                    else
                        s += '<td>';

					switch(t.getMasterStatus()){
						/*case constants.STATUS_OBJECT_INACTIVE:
							s += '<td><span class="badge statusColorInactive">Inaktiv</span></td>';
							break;*/
						
						case constants.TASK_STATUS_ACTIVE:
							switch (t.getStatus()){
								case constants.STATUS_NEW:
									s += '<span class="badge badge-status statusColorNew">Offen</span>';
									break;
								case constants.STATUS_COMPLETED:
									s += '<span class="badge badge-status statusColorCompleted">Erledigt</span>';
									break;
								case constants.STATUS_PROGRESS:
									s += '<span class="badge badge-status statusColorProgress">In Bearbeitung</span>';
									break;
								default:
									//Label
									var tl = t.getTaskLabel();
									s += '<span class="badge badge-status" style="background-color: ' + tl.colorBg + '; color: ' + tl.colorFg + ';">' + tl.name + '</span>';
									break;
							}
							break;
					}
                    s += '</td>';
					
					//----------------------------------
					//Titel/Typ

                    if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
                        s += '<td class="btnTaskDetails cell-clickable" data-taskId="' + t.taskId + '">';
                    else
                        s += '<td>';

					var tType = TaskType.getTaskType(t.taskTypeId).name;
					var tTitle = t.title;
					if (tTitle)
						s += '<span class="taskTitle">' + tTitle + '</span><br/>';
					s += '<span class="taskType text-muted">' + tType + '</span>';
					s += '</td>';

                    //----------------------------------
					//Intervall

                    if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
                        s += '<td class="btnTaskDetails cell-clickable text-center" data-taskId="' + t.taskId + '">';
                    else
                        s += '<td>';

					if (t.isInterval()){
					//if (t.intervalId > 0){
						s += '<i class="fa fa-repeat text-muted ml5"></i>';
					}
					s += '</td>';

                    //----------------------------------
					//Objekte

					var loc = Location.getLocation(t.locationList[0], undefined, true, true, false);
					var lType = "";
					var hasLoc = false;
					if (loc) {
						if (loc.isAbstract !== 1) {
							lType = LocationType.getLocationType(loc.type).name;
							hasLoc = true;
						}
					}

					var ll = Location.getLocationList(t.locationList, false, false, false, 2, true, true);
					if (!ll) {
						//Fallback: DynData
						var dd = t.getDynDataObjByRole(TaskTypeField.ROLE_LOCATION);
						if (dd){
							ll = (dd.data) ? dd.taskTypeField.getFormattedValue(dd.data.value) : "";
						}
					}

                    if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
                        s += '<td class="btnTaskDetails cell-clickable" data-taskId="' + t.taskId + '">';
                    else
                        s += '<td>';

                    s += '<span class="locationTitle">' + ll + '</span>';
                    if(lType)
                    	s += '<br/><span class="locationType text-muted">' + lType + '</span>';
                    s += '</td>';

                    //----------------------------------
					//Routing

                    if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
                        s += '<td class="btnTaskDetails cell-clickable" data-taskId="' + t.taskId + '">';
                    else
                        s += '<td>';

					if (ts.isAssignedToSingleUser()){
						var ug = ts.getUserGroups()[0];
						var u = ug.getSingleUser();
						s += '<span class="text-nowrap">' + u.getName(true) + '</span>';
					}
					else{
						s += ts.getUserGroupNames(true);
					}
					s += '</td>';

                    //----------------------------------
                    //Due date

                    if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
                        s += '<td class="btnTaskDetails cell-clickable" data-taskId="' + t.taskId + '">';
                    else
                        s += '<td>';

					s += '<span style="color:' + t.getDueDateColor() + '">' + pg.formatDate(t.getDueDate()) + '</span>';
                    s += '</td>';

                    //----------------------------------
					//Protokolle

                    if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
                        s += '<td class="btnTaskDetails cell-clickable text-center" data-taskId="' + t.taskId + '">';
                    else
                        s += '<td>';

					if (t.hasProtocols()) {
						var proTotalCount = t.totalProtocolCount;
						var proCompletedCount = t.protocolCount[constants.STATUS_COMPLETED];
                        s += '<span class="badge"><i class="fa fa-fw fa-file-text"></i> ' + proCompletedCount + '/' + proTotalCount + '</span>';
					}
					else
						s += '&nbsp;';

                    s += '</td>';

                    //----------------------------------
					//Vorkommnisse

                    if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
                        s += '<td class="btnTaskDetails cell-clickable text-center" data-taskId="' + t.taskId + '">';
                    else
                        s += '<td>';

                    if (t.incCount > 0) {
                    	var incTotalCount = t.incCount;
                    	var incCompletedCount = t.incCount - t.defectCount;
                        if (t.defectCount === 0)
                            s += '<span class="badge"><i class="fa fa-fw fa-bolt"></i> ' + incCompletedCount + '/' + incTotalCount + '</span>';
                        else
                            s += '<span class="badge badge-danger"><i class="fa fa-fw fa-bolt"></i> ' + incCompletedCount + '/' + incTotalCount + '</span>';
                    }
					else
                        s += '&nbsp;';

                    s += '</td>';

                    //----------------------------------
                    //Meldungen

                    if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
                        s += '<td class="btnTaskDetails cell-clickable text-center" data-taskId="' + t.taskId + '">';
                    else
                        s += '<td>';

					if (t.notificationCount > 0)
                        s += '<span class="badge badge-danger"><i class="fa fa-fw fa-flag"></i></span>';
					else
                        s += '&nbsp;';

                    s += '</td>';

                    //----------------------------------
                    //Fotos


                    if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
                        s += '<td class="btnTaskDetails cell-clickable text-center" data-taskId="' + t.taskId + '">';
                    else
                        s += '<td>';

					if (t.protocolFotosCount > 0)
                        s += '<span class="badge default"><i class="fa fa-fw fa-camera"></i></span>';
                    else
                        s += '&nbsp;';

                    s += '</td>';

                    //----------------------------------
					
					s += '</tr>';
				}
			}
			
			//leer
			if (trgTasks.length === 0){
				s = "<tr><td colspan='14'>Zur Anfrage wurden keine Auftr&auml;ge gefunden.</td></tr>";
				
				$("#taskPaginationBox").hide();
			}
			else{
				$("#taskPaginationBox").show();
				
				if (trgTasks.length === 1)
					$("#taskPaginationBox .filterMatchCount").html("1 Auftrag");
				else
					$("#taskPaginationBox .filterMatchCount").html(trgTasks.length + " Auftr&auml;ge");
				
				$("#taskPaginationBox .pagination-panel-input").val((model.curTaskListView.curPage+1));
				$("#taskPaginationBox .pagination-panel-total").html(""+model.curTaskListView.pageCount);
			}
			
			//anzeigen
			$("#taskTable tbody").html(s);
			
			//einmal so ausführen
			this.resetSelection();
			this.updateSelectionDisplay();
			
			//handlers
			//warum auch immer erst mit Timeout?!
			setTimeout(function(){
				
				$(".btnTaskDetails").off("click").on("click", function(e){

					//scroll-Position merken
					model.curTaskListView = model.curTaskListView || {};
					model.curTaskListView.scrollPos = viewManager.getScrollTop();

					var link = $(e.currentTarget);
					if (link.attr("data-taskId")){
						var taskId = parseInt(link.attr("data-taskId"), 10);
						viewManager.showPage(constants.PAGE_TASK_DETAILS, {
							taskId: taskId
						});
					}
				});
				
				//Archivieren/Wiederherstellen
				$(".btnTaskArchive").off("click").on("click", function(e){
					
					var link = $(e.currentTarget);
					if (link.attr("data-taskId")){
						var taskId = parseInt(link.attr("data-taskId"), 10);
						that.setTaskGroup(Task.getTask(taskId), constants.GROUP_ARCHIVE).then(function(){
							viewManager.showNotification("Auftrag #" + taskId + " wurde erfolgreich archiviert.", constants.NOTIFICATION_SUCCESS);
						});
					}
				});
				$(".btnTaskRestore").off("click").on("click", function(e){
					var link = $(e.currentTarget);
					if (link.attr("data-taskId")){
						var taskId = parseInt(link.attr("data-taskId"), 10);
						that.setTaskGroup(Task.getTask(taskId), constants.GROUP_DEFAULT).then(function(){
							viewManager.showNotification("Auftrag #" + taskId + " wurde erfolgreich wiederhergestellt.", constants.NOTIFICATION_SUCCESS);
						});
					}
				});
				
				$(".viewsPerPage").change(function(){
					taskListManager.showTaskItems(model.curTasks, model.curTaskListView);
				});
				$("#taskPaginationBox .pagination-panel-input").change(function(){
					var val = $("#taskPaginationBox .pagination-panel-input").val();
					if ($.isNumeric(val)) {
						val = parseInt(val);
						val = Math.max(val, 1);
						val = Math.min(val, model.curTaskListView.pageCount);
						model.curTaskListView.curPage = val-1;
						taskListManager.showTaskItems(model.curTasks, model.curTaskListView);
					}
				});
				$("#taskPaginationBox .btn.prev").off("click").on("click", function(){
					if (model.curTaskListView.curPage > 0)
						model.curTaskListView.curPage--;
					taskListManager.showTaskItems(model.curTasks, model.curTaskListView);
				});
				$("#taskPaginationBox .btn.next").off("click").on("click", function(){
					if (model.curTaskListView.curPage < model.curTaskListView.pageCount-1)
						model.curTaskListView.curPage++;
					taskListManager.showTaskItems(model.curTasks, model.curTaskListView);
				});
			}, 500);
		},
		
		//-------------------------------------------------------------------------------
		
		//Filter presets
		setFilterStatusUncompleted: function(){
			
			//Status
			var statusOptions = [];
			statusOptions.push(0); //offen
			statusOptions.push(2);//'In Bearbeitung'});
			for (var i=0; i<model.taskLabels.length; i++)
				statusOptions.push(model.taskLabels[i].id);
			var multiSelect = $("#filterTaskStatus").select2();
			multiSelect.val(statusOptions).trigger("change");
		},
		
		setFilterDueDate: function(pattern){
			
			var dFrom,
				dTo,
				DAY_MSEC = 1000*60*60*24;
			
			var now = new Date();
			now = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			
			switch (pattern){
				case "overdue":
					dTo = new Date();
					dTo.setTime(now.getTime() - DAY_MSEC);

					//Status setzen (#540)
					var statusOptions = [];
					statusOptions.push(0); //offen
					statusOptions.push(2);//'In Bearbeitung'});
					for (var i=0; i<model.taskLabels.length; i++)
						statusOptions.push(model.taskLabels[i].id);
					var multiSelect = $("#filterTaskStatus").select2();
					multiSelect.val(statusOptions).trigger("change");

					break;
				case "d0":
					dFrom = dTo = now;
					break;
				case "d1":
					dFrom = new Date();
					dFrom.setTime(now.getTime() + DAY_MSEC);
					dTo = dFrom;
					break;
				case "w0":
					dFrom = pg.getMonday(now);
					dTo = new Date();
					dTo.setTime(dFrom.getTime() + 6*DAY_MSEC);
					break;
				case "w1":
					dFrom = pg.getMonday(now);
					dFrom.setTime(dFrom.getTime() + 7*DAY_MSEC);
					dTo = new Date();
					dTo.setTime(dFrom.getTime() + 6*DAY_MSEC);
					break;
				case "m0":
					dFrom = new Date(now.getFullYear(), now.getMonth(), 1);
					dTo = new Date(now.getFullYear(), now.getMonth()+1, 0);
					break;
				case "m1":
					dFrom = new Date(now.getFullYear(), now.getMonth()+1, 1);
					dTo = new Date(now.getFullYear(), now.getMonth()+2, 0);
					break;
				case "y0":
					dFrom = new Date(now.getFullYear(), 0, 1);
					dTo = new Date(now.getFullYear()+1, 0, 0);
					break;
			}
			
			//umrechnen
			var sFrom = (dFrom) ? pg.formatDate(dFrom, true) : "",
				sTo = (dTo) ? pg.formatDate(dTo, true) : "";
			
			//ohne Fälligkeit
			if (pattern === "without")
				sFrom = sTo = "-";
			
			//anwenden
			$("#filterTaskDueDateFrom").val(sFrom);
			$("#filterTaskDueDateTo").val(sTo);
			model.curTaskListView.filter.dueDateFrom = sFrom;
			model.curTaskListView.filter.dueDateTo = sTo;
			model.curTaskListView.curPage = 0;
			taskListManager.showTaskItems(model.curTasks, model.curTaskListView);
		},
		
		//-------------------------------------------------
		
		onResize: function(){
			var that = taskListManager;
			that.updateTopScroller();
		},
		
		updateTopScroller: function(){
			var contentWidth = $("#taskTableBox > table").width();
			$("#taskTableScroller div").width(contentWidth);
		}
		
	};
	
	window.taskListManager = taskListManager;
}());
