(function () {
	
	//-----------------------------------------
	//Manager für LocalStorage-Operationen
	//-----------------------------------------
	
    'use strict';

    var localStorageManager = {

        //Literale
        get: function (key) {
            try {
                return localStorage.getItem(key);
            }
            catch (e) {
            }
            return null;
        },
        set: function (key, val) {
            try {
                localStorage.setItem(key, val);
            }
            catch (e) {
            }
        },

        //Objekte, nicht Literale!
        getObject: function (key) {
            try {
                var o = localStorage.getItem(key);
                if (o)
                    if (o !== "undefined")
                        return JSON.parse(o);
            }
            catch (e) {
            }
            return null;
        },
        setObject: function (key, dataObj) {
            try {
                localStorage.setItem(key, JSON.stringify(dataObj));
            }
            catch (e) {
            }
        }

    };

    window.localStorageManager = localStorageManager;
}());