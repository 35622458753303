(function () {

/*global
	viewManager:true,
	LocationField:true,
	constants:true
*/
	
	'use strict';
	
	//------------------------------------------------------------------------
	//typisierte Formatierungen, z.B. für Werteeingaben resp. das Laden bestehender Daten
	//------------------------------------------------------------------------
	
	var formatManager = {
	
		getFormattedValue: function(type, val, options){
			
			var digits;

			switch (type){
				
				/*case LocationField.FIELD_TYPE_STRING:
				case LocationField.FIELD_TYPE_LONG_TEXT:*/
				default:
					return pg.handleNullString(val);
				
				case LocationField.FIELD_TYPE_INT:
					if (val !== null){
						
						val = "" + val;
						val = pg.replace(val, ",", "");
						val = pg.replace(val, ".", "");
						//remove everything not numeric or minus
						val = val.replace(/[^\d\-]/g, '');
						
						if ($.isNumeric(val)){
							val = Math.round(val);
							
							//forceLength
							if (options) {
								if (options.length === 1) {
									digits = parseInt(options[0]);
									val = val % (Math.pow(10, digits));
									val = pg.forceLength(val, digits);
								}
							}
						}
					}
					break;
				
				case LocationField.FIELD_TYPE_FLOAT:
					if (val !== null){
						
						val = "" + val;
						val = pg.replace(val, ",", ".");
						//remove everything not numeric, minus or dot
						val = val.replace(/[^\d\.\-]/g, '');
						
						if ($.isNumeric(val)){
							//certain number of digits?
							if (options) {
								if (options.length === 1) {
									digits = parseInt(options[0]);
									var a = Math.floor(val);
									var p;
									var s = val + "";
									if (s.indexOf(".") > 0) {
										s = pg.replace(s, ",", ".");
										p = s.substring(s.indexOf(".") + 1);
										if (p.length > digits)
											p = p.substr(0, digits);
										if (p.length < digits)
											p = p + pg.forceLength("", digits - p.length);
									}
									else {
										p = pg.forceLength("", digits);
									}
									val = a + "." + p;
								}
							}
						}
					}
					break;
			}
			
			return pg.handleNullString(val);
			
		},
		
		//----------------------------------------------------------------------
		//Echtzeit-Behandlung von Benutzereingaben
		//that: Input-Control (TextFeld etc.)

		//Zustand für copy/paste
		isCtrlDown: false,
		ctrlKey: 17,
		cmdKey: 91,
		vKey: 86,
		cKey: 67,

		handleKeyUp: function (e, that, definitionObj, thatFormatManager) {

			var value = $(that).val();
			var key = e.which ? e.which : e.keyCode;
			var char = String.fromCharCode(key);

			if (key === thatFormatManager.ctrlKey || key === thatFormatManager.cmdKey)
				thatFormatManager.isCtrlDown = false;
		},

		handleKeyDown: function (e, that, definitionObj, thatFormatManager) {

			var value = $(that).val();
			var key = e.which ? e.which : e.keyCode;
			var char = String.fromCharCode(key);
			//console.log(key);

			switch (key) {
				//bestimmte Tasten regulär behandeln
				case 9:
					return;
			}

			if (key === thatFormatManager.ctrlKey || key === thatFormatManager.cmdKey) {
				thatFormatManager.isCtrlDown = true;
				return;
			}

			//copy/paste vom Browsern handeln lassen
			if (thatFormatManager.isCtrlDown && (key === thatFormatManager.cKey)){
				//copy
				return;
			}
			if (thatFormatManager.isCtrlDown && (key === thatFormatManager.vKey)){
				//paste
				return;
			}
			if (thatFormatManager.isCtrlDown && (key === 88)){
				//cut
				return;
			}
			if (thatFormatManager.isCtrlDown && (key === 65)){
				//CTRL-A
				return;
			}

			e.stopImmediatePropagation();
			e.preventDefault();
			viewManager.setFormDirty();
			
			var valueBefore = that.selectionStart ? value.substring(0, that.selectionStart) : value;
			var valueAfter = that.selectionEnd ? value.substring(that.selectionEnd) : "";
			var caretPosStart = that.selectionStart;
			var caretPosEnd = that.selectionEnd;

			//Auswahl entfernen
			var removeSelection = false;
			if (that.selectionStart === 0 && (that.selectionEnd === value.length) && (value)){
				removeSelection = true;
			}

			switch (key) {
				
				//backspace
				case 8:
					if (removeSelection){
						value = "";
						valueBefore = "";
						valueAfter = "";
						caretPosStart = 0;
						caretPosEnd = 0;
					}
					else {
						if (valueBefore) {
							value = valueBefore.substring(0, valueBefore.length - 1) + valueAfter;
							caretPosStart--;
						}
					}
					break;
				//del
				case 46:
					if (removeSelection){
						value = "";
						valueBefore = "";
						valueAfter = "";
						caretPosStart = 0;
						caretPosEnd = 0;
					}
					else {
						if (caretPosEnd > caretPosStart) {
							//remove selection
							value = value.substring(0, caretPosStart) + value.substring(caretPosEnd);
						} else {
							if (valueAfter)
								value = valueBefore + valueAfter.substring(1);
						}
					}
					break;
				
				//end
				case 35:
					caretPosStart = 100000;
					break;
				//pos1
				case 36:
					caretPosStart = 0;
					break;
				//left
				case 37:
					caretPosStart--;
					break;
				//right
				case 39:
					if (e.shiftKey)
						caretPosEnd++;
					else
						caretPosStart++;
					break;
				
				//comma + dot
				case 110:
				case 188:
				case 190:
					//Nachkommazahlen überhaupt erlaubt?
					if (definitionObj.type === LocationField.FIELD_TYPE_INT)
						return false;
					
					//schon ein Dot?
					if (value.indexOf(".") >= 0)
						return false;

					if (removeSelection){
						value = ".";
						valueBefore = "";
						valueAfter = "";
						caretPosStart = 1;
						caretPosEnd = 1;
					}
					else {

						if (valueBefore.length === 0) {
							valueBefore = "0";
							caretPosStart++;
						}

						//ok, aber als Dot
						value = valueBefore + "." + valueAfter;
						caretPosStart++;
					}
					break;
				
				//default:
				case 45:
				//minus
				case 189:
					if (caretPosStart !== 0)
						break;
					if (removeSelection){
						value = "-";
						valueBefore = "";
						valueAfter = "";
						caretPosStart = 1;
						caretPosEnd = 1;
					}
					else {
						if (value.length)
							if (value.charAt(0) !== "-")
								value = "-" + value;
						caretPosStart++;
					}
					break;
				
				//1..9
				case 48:
				case 49:
				case 50:
				case 51:
				case 52:
				case 53:
				case 54:
				case 55:
				case 56:
				case 57:
					if (removeSelection){
						value = char;
						valueBefore = "";
						valueAfter = "";
						caretPosStart = 1;
						caretPosEnd = 1;
					}
					else {
						value = valueBefore + char + valueAfter;
						caretPosStart++;
					}
					break;
				
				case 96:
				case 97:
				case 98:
				case 99:
				case 100:
				case 101:
				case 102:
				case 103:
				case 104:
				case 105:
					char = String.fromCharCode(key - 48);
					if (removeSelection){
						value = char;
						valueBefore = "";
						valueAfter = "";
						caretPosStart = 1;
						caretPosEnd = 1;
					}
					else {
						value = valueBefore + char + valueAfter;
						caretPosStart++;
					}
					break;
			}
			
			$(that).val(value);
			
			caretPosStart = Math.max(caretPosStart, 0);
			caretPosStart = Math.min(caretPosStart, value.length);
			if (!e.shiftKey)
				caretPosEnd = caretPosStart;
			else {
				if (caretPosEnd < caretPosStart) {
					var t = caretPosStart;
					caretPosStart = caretPosEnd;
					caretPosEnd = t;
				}
			}
			if (that.createTextRange) {
				var range = that.createTextRange();
				range.move('character', caretPosStart);
				range.select();
			}
			else {
				if (that.selectionStart >= 0) {
					that.focus();
					that.setSelectionRange(caretPosStart, caretPosEnd);
				}
				else
					that.focus();
			}
		},
	
		//nach Verlassen Werte korrigieren
		handleFocusOut: function(e, that, definitionObj, thatFormatManager) {

			var val = null;
			switch (definitionObj.type) {
				case LocationField.FIELD_TYPE_INT:
					val = parseInt($(that).val());
					break;
				case LocationField.FIELD_TYPE_FLOAT:
					val = parseFloat($(that).val());
					break;
			}
			if (isNaN(val))
				return;
			
			switch (definitionObj.type) {
				case LocationField.FIELD_TYPE_INT:
				case LocationField.FIELD_TYPE_FLOAT:
					var newVal = val;
					if (definitionObj.minVal && definitionObj.minVal !== -1)
						newVal = Math.max(newVal, definitionObj.minVal);
					if (definitionObj.maxVal && definitionObj.maxVal !== -1)
						newVal = Math.min(newVal, definitionObj.maxVal);
					if (definitionObj.step && definitionObj.step !== 0)
						newVal = pg.roundStep(newVal, definitionObj.step);
					
					//certain number of digits?
					if (definitionObj.options && definitionObj.options.length === 1) {
						var digits = parseInt(definitionObj.options[0]);
						newVal = pg.round(newVal, digits);
					}
					
					if (newVal !== val)
						$(that).val(newVal);
					break;
			}
		}
	};
	
	window.formatManager = formatManager;
	
}());

