(function () {
	
	//----------------------------------------------------------------
	//Anhang
	//----------------------------------------------------------------
	
    /*global constants:true*/

    'use strict';

    var Attachment = {

        attachmentPrototype: {

			fromObj: function (o) {
				this.id = parseInt(o.id || -1, 10);
				this.createdOn = pg.parseDate(o.createdOn || new Date());
				this.createdBy = parseInt(o.createdBy, 10);
				this.changedOn = pg.parseDate(o.changedOn);
				this.changedBy = parseInt(o.changedBy, 10);

				this.clientId = parseInt(o.clientId, 10);
				this.entityType = parseInt(o.entityType, 10);
				this.entityId = parseInt(o.entityId, 10);
				this.status = parseInt(o.status, 10);

				this.file = o.file;
				this.fileType = o.fileType;
				this.name = o.name;
				this.fileToken = o.fileToken ? o.fileToken : this.fileToken;
				this.size = o.size;
				this.width = parseInt(o.width, 10);
				this.height = parseInt(o.height, 10);

				return this;
			},

            serialize: function () {

                var s = "";

                //Version
                s += "#3" + ";;;";

                s += this.id + ";;;";
                s += this.name + ";;;";
                s += this.size + ";;;";

                //legacy
				var fakeUrl = this.file;

                if (this.url || fakeUrl) {
                	//weg mit der Domain!
	                var url = this.url || fakeUrl;
                	var pos = url.indexOf("data/");
                	if (pos >= 0)
                		url = url.substring(pos);
	                s += url + ";;;";
                }
                else
                    s += ";;;";
                s += this.width + ";;;";
                s += this.height + ";;;";

                //file: nur, wenn nicht schon URL vorhanden
                if (!(this.url || fakeUrl)) {
                    if (this.file)
                        s += this.file + ";;;";
                    else
                        s += ";;;";
                }
                else
                    s += ";;;";

	            //pending
	            s += this.pendingUploadFile + ";;;";
	            
                return s;
            },
	        
	        //----------------------------------------------------------------
	        
	        //Dateigröße
            setSize: function (bytes) {
            	this.sizeBytes = bytes;
                this.size = pg.getAbbrevFileSize(bytes);
            },
	
	        //----------------------------------------------------------------
	        
            isImage: function () {
				
				//var ft = pg.getFiletype(this.url).toLowerCase();
	            var ft = this.fileType;
				if (!ft)
					ft =  pg.getFiletype(this.url || this.name || "");
				return ((ft === "jpeg") || (ft === "jpg") || (ft === "png") || (ft === "gif"));
            },
	
	        //----------------------------------------------------------------

            getPreviewImage: function (containerObject, rootDir) {
	            if (this.isImage()) {
                    //thumb liefern
                    if (this.isDownloaded)
                        return rootDir + this.getLocalUrl(false);
                    else {

						//wurde das Gesamtobjekt bereits gespeichert?
						//nur dann liegt das Attachment bereits in der zentralen DB vor
						/*if (containerObject && containerObject.id > 0){
							return this.getAccessUrlByToken(false);
						}
						else{*/
							
	                    if (this.file)
	                        return Attachment.getAccessUrlByPath(this.file, false);
	                    else{
	                    	//legacy
		                    return Attachment.getAccessUrlByPath(this.url, false);
	                    }
						//}
                    }
                }
                return "img/attachment.png";
            },
	
	        //----------------------------------------------------------------
	        
            getUrl: function (download) {
            	
            	if (download === undefined)
		            download = true;

                /*if (this.file) {
	                return this.file;
                }
                if (this.url) {*/
	            if (this.fileToken)
	                return this.getAccessUrlByToken(download);
	            else
		            return Attachment.getAccessUrlByPath(this.url, download);
                /*}
                return "";*/
            },
	
	        //----------------------------------------------------------------
			//auf Device (App v1)

	        //Anhänge versch. Typen unterscheiden
            getLocalPrefix: function () {
                if (this.type === constants.ENTITY_TYPE_LOCATION)
                    return "loc_";
                return "";
            },
	
	        //auf Device (App)
            getLocalUrl: function (getMaster) {

                if (getMaster === undefined)
                    getMaster = true;

                if (this.isImage()) {
                    //wurde heruntergeladen, lokale Benennung finden
                    var folder = model.curClientId + "/attachments/" + this.getLocalPrefix() + this.objId + "_" + this.id;
                    if (!getMaster)
                        folder += "_t";
                    folder += "." + pg.getFiletype(this.url);
                    return folder;

                }
                //PDF
                return model.curClientId + "/attachments/" + this.getLocalPrefix() + this.objId + "_" + this.id + "." + pg.getFiletype(this.url);
            },

			//---------------------------------------------------

			getAccessUrlByToken: function(isDownload){
				//Zugriff nur noch mit Session-Prüfung
				var st = model.curSessionToken;
				var isDl = isDownload ? 1 : 0;
				return constants.API_BASE + '/attachments/download?sessionToken=' + st + "&fileToken=" + this.fileToken + "&dl=" + isDl;
			}
        },

		//---------------------------------------------------
	
	    getAccessUrlByPath: function(localPath, isDownload){
		    //Zugriff nur noch mit Session-Prüfung
		    var st = model.curSessionToken;
		    var isDl = isDownload ? 1 : 0;
		    return constants.API_BASE + '/attachments/download?sessionToken=' + st + "&file=" + localPath + "&dl=" + isDl;
	    },
	    
	    //----------------------------------------------------------------

        serializeAttachments: function (a) {

            var s = "";
            for (var i = 0; i < a.length; i++) {
                var att = a[i];
                s += att.serialize() + "###";
            }
            return s;
        },

		//xxx legacy
        deserializeAttachments: function (attType, objId, att) {
            var attObj = [];

            if (!att)
            	return attObj;

            var a = att.split("###");
            if (a.length === 0)
                return attObj;
            if (a.length > 1) {
                if (a[a.length - 1] === "")
                    a.splice(a.length - 1, 1);
            }
            attObj = [];
            for (var i = 0; i < a.length; i++)
                attObj.push(Attachment.createAttachment(attType, objId, a[i], i));
            return attObj;
        },

		getAttachments: function(entityType, entityId){
			var attObj = [];
			for (var i = 0; i < model.attachments.length; i++) {
				var att = model.attachments[i];
				if ((att.entityType === entityType) && (att.entityId === entityId)) {
					attObj.push(att);
				}
			}
			return attObj;
		},

		//ein Attachment aus Liste löschen
		deleteAttachment: function(attDelete){
			for (var i = 0; i < model.attachments.length; i++) {
				var att = model.attachments[i];
				if ((att.entityType === attDelete.entityType) && (att.entityId === attDelete.entityId) && (att.fileToken === attDelete.fileToken)) {
					model.attachments.splice(i, 1);
					break;
				}
			}
		},
		//mehrere Attachments aus Liste löschen
		deleteAttachments: function(entityType, entityId){
			for (var i = model.attachments.length-1; i>0; i--) {
				var att = model.attachments[i];
				if ((att.entityType === entityType) && (att.entityId === entityId)) {
					model.attachments.splice(i, 1);
					break;
				}
			}
		},

		//----------------------------------------------------------------
		//zusammenführen von Attachments via fileToken

		getIdIndex: function (arr, objId) {
			for (var i = 0; i < arr.length; i++)
				if (arr[i].fileToken === objId)
					return i;
			return -1;
		},
		replaceOrPushObj: function (arr, obj) {
			var index = this.getIdIndex(arr, obj.fileToken);
			if (index < 0)
				arr.push(obj);
			else {
				arr[index] = obj;
			}
		},

	    //----------------------------------------------------------------

		//muss Attachments innerhalb einer Entity eindeutig identifizieren
		generateToken: function(){

			var token = "" + (new Date().getTime() + Math.floor(100000*Math.random()));

			//aber nicht plain wegen Sonderzeichen
			token = pg.getSha512(token, constants.CRYPT_SEED);

			//non-alphanum. entfernen
			token = token.replace(/\W/g, '');

			return token;
		},


		//xxx legacy
        createAttachment: function (attType, objId, attProp, index) {
            var att = Object.create(Attachment.attachmentPrototype);

            var a = attProp.split(";;;");
            att.type = attType;
            att.objId = objId;

            //version?
            var version = 1;
            var a0 = a[0];
            if (a0)
                if (a0.indexOf("#") === 0)
                    version = parseInt(a0.substring(1));

            var aIndex = 0;
            if (version > 1)
                aIndex = 1;

            att.id = parseInt(a[aIndex++]);
            att.name = a[aIndex++];
            att.setSize(a[aIndex++]);
            var value = a[aIndex++];
            att.url = value ? value : null;
            att.width = parseInt(a[aIndex++]);
            att.height = parseInt(a[aIndex++]);
            if (version > 1) {
                value = a[aIndex++];
                att.file = value ? value : null;
            }
	        if (version > 2) {
		        value = a[aIndex++];
		        att.pendingUploadFile = value ? value : null;
	        }
            att.index = index;

            //default
            att.isDownloaded = false;

            return att;
        },
	
	    //----------------------------------------------------------------

        createAttachmentRaw: function () {
            var o = Object.create(Attachment.attachmentPrototype);
            o.fileToken = Attachment.generateToken();
            return o;
        }
    };

    window.Attachment = Attachment;
}());
