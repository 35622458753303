(function () {
	
	//------------------------------------------------------------------------
	//verschiedene Datenbank-Verbindungen verwalten (Test, QS, Live)
	//------------------------------------------------------------------------
	
    'use strict';

    var connectionManager = {

    	//Quelle setzen
        setDataSource: function (dataSource) {

            if (dataSource <= 0)
                dataSource = constants.DATASOURCE_PRODUCTION;

            switch (dataSource) {
                /*case constants.DATASOURCE_TEST_COLOGIC:
                    constants.API_RAW = constants.API_RAW_TEST_COLOGIC;
                    break;*/
	            /*case constants.DATASOURCE_LOCALHOST:
		            constants.API_RAW = constants.API_RAW_LOCALHOST;
		            break;*/
				case constants.DATASOURCE_DEV:
					constants.API_RAW = constants.API_RAW_DEV;
					break;
                case constants.DATASOURCE_PRODUCTION:
                    constants.API_RAW = constants.API_RAW_PRODUCTION;
                    break;
                case constants.DATASOURCE_QS:
                    constants.API_RAW = constants.API_RAW_QS;
                    break;
            }

            //remember
            model.dataSource = dataSource;
            constants.API_BASE = constants.API_RAW + "/v" + constants.API_VERSION;
        },

	    //Name holen
        getDataSourceName: function () {
            switch (model.dataSource) {
                /*case constants.DATASOURCE_TEST_COLOGIC:
                    return "Dev Cologic";*/
	            /*case constants.DATASOURCE_LOCALHOST:
		            return "Dev Remote";*/
				case constants.DATASOURCE_DEV:
					return "Dev";
                case constants.DATASOURCE_PRODUCTION:
                    return "Live";
                case constants.DATASOURCE_QS:
                    return "QS";
            }
            return "";
        },

		//---------------------------------------------------

		//reservierte QueryString-Characters ersetzen
		hardenUrl: function(url){

			//https://tools.ietf.org/html/rfc3986
			//https://stackoverflow.com/questions/2366260/whats-valid-and-whats-not-in-a-uri-query
			var reservedChars = [":", "/", "?", "#", "[", "]", "@", "!" , "$" , "&" , "'" , "(" , ")" , "*" , "+" , "," , ";" , "="];
			var replacement = "-";

			for (var i=reservedChars.length-1 ; i>=0; i--) {
				url = pg.replace(url, reservedChars[i], replacement);
			}
			return url;
		}
    };

    window.connectionManager = connectionManager;
}());
