(function () {
	
	//-------------------------------------------
	//Manager für UserGroups (Details)
	//-------------------------------------------
	
	/*global
	 constants:true,
	 dataManager:true,
	 viewManager:true,
	 UserRole:true,
	 UserGroup:true,
	 Task:true,
	 authManager:true,
	 successorManager:true
	 */
	
	'use strict';
	
	var userGroupDetailManager = {
		
		//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
		isCurObjOutdated: false,
		checkModelChanged: function(data, oldModel){
			
			var that = userGroupDetailManager;
			
			//aktuelles Team verändert?
			var i,
				tsOld,
				tsNew;
			
			if (data.userGroups) {
				for(i = 0; i < data.userGroups.length; i++) {
					var u = data.userGroups[i];
					
					if (parseInt(u.id) === oldModel.curUserGroup.id){
						
						that.isCurObjOutdated = true;
						
						tsOld = oldModel.curUserGroup.changedOn.getTime();
						//noinspection JSUnresolvedVariable
						tsNew = pg.parseDate(u.changedOn).getTime();
						if (tsNew !== tsOld)
							return true;
					}
				}
			}
			
			return false;
			
		},
		updateCurObj: function(o){
			
			o = o || UserGroup.getUserGroup(model.curUserGroup.id);
			model.curUserGroup = o;
			this.isCurObjOutdated = false;
		},
		
		//---------------------------------------------------------------------------------
		
		//Bearbeitung starten
		editUserGroup: function (userGroupId, editMode, callbackComplete) {
			
			var that = this;
			
			if (!authManager.hasPermission(UserRole.PERMISSION_TEAM_DETAILS)) {
				viewManager.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
				return false;
			}

			viewManager.setScrollTop(0);
			
			if (editMode === undefined)
				editMode = false;
			
			var ug = UserGroup.getUserGroup(userGroupId);
			that.updateCurObj(ug);
			
			$("#content").html(window.templates['userGroupEdit.html']);
			setTimeout(function () {
				
				that.onUserGroupEditLoaded(ug, editMode);
				
				if (authManager.hasPermission(UserRole.PERMISSION_TEAM_DELETE)) {
					$("#btnDeleteUserGroup").show().off("click").on("click", $.proxy(that.setUserGroupStatusDeleted, userGroupDetailManager));
				}
				else
					$("#btnDeleteUserGroup").hide();
				
				//update components
				viewManager.updateLayout();

				if (callbackComplete)
					callbackComplete();
				
			}, constants.TEMPLATE_DELAY);
			
			return false;
		},

		setUserGroupStatus: function(status){
			var state = (status === constants.STATUS_OBJECT_ACTIVE);
			$("#chkUserGroupStatus").bootstrapSwitch('state', state, false);
		},
		getUserGroupStatus: function(){
			var val = $('#chkUserGroupStatus').bootstrapSwitch('state');
			if (val)
				return constants.STATUS_OBJECT_ACTIVE;
			else
				return constants.STATUS_OBJECT_INACTIVE;
		},
		
		//Editor füllen
		onUserGroupEditLoaded: function (ug, showEditor) {
			
			var that = this;
			var i,
				u,
				sel = "";
			
			if (showEditor) {
				
				if (ug.id > 0) {
					$("#userGroupTitle").html(ug.name);
					$("#breadcrumbUserGroup").html(ug.name);
				}
				else {
					$("#userGroupTitle").html("Neues Team");
					$("#breadcrumbUserGroup").html("Neues Team");
				}
				
				$("#userGroupName").val(ug.name);

				$("#chkUserGroupStatus").bootstrapSwitch({
					onText: '<i class="fa fa-fw fa-check"></i>',
					offText: '<i class="fa fa-fw fa-times"></i>',
					size: "mini",
					onSwitchChange: function (event, newState) {
						viewManager.setFormDirty();
					}
				});
				this.setUserGroupStatus(ug.status);
				
				model.users.sort(function (a, b) {
					var aName = (a.lastName + " " + a.firstName).toLowerCase();
					var bName = (b.lastName + " " + b.firstName).toLowerCase();
					return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
				});
				
				sel = "";
				for(i = 0; i < model.users.length; i++) {
					
					u = model.users[i];
					if (u.status === constants.STATUS_OBJECT_DELETED)
						continue;
					//System-Admins nie irgendwo anzeigen
					if (u.isSystemAdmin())
						continue;
					
					sel += "<option value='" + u.id + "' ";
					if (ug.contains(u.id))
						sel += " selected ";
					var name = u.getName();
					if (u.status === constants.STATUS_OBJECT_INACTIVE)
						name += " (inaktiv)";
					sel += ">" + name + "</option>";
				}
				$("#selectUsers").html(sel);
				
				//select2
				//noinspection JSUnresolvedVariable
				$.fn.select2.defaults.set("theme", "bootstrap");
				$(".select2, .select2-multiple").select2({
					placeholder: 'Bitte auswählen ...'
				});

				$("#btnCancelUg").on("click", function () {
					if (model.curUserGroup.id < 0) {
                        viewManager.showPage(constants.PAGE_USERGROUP_LIST);
                    }
                    else {
                        //viewManager.showPage(constants.PAGE_USERGROUP_DETAILS);
                        window.history.back();
                    }
					return false;
				});
				$("#btnSaveUg").on("click", userGroupDetailManager.initSaveUserGroup);
				
				viewManager.updateButtons();
				
				$("#btnEditUserGroup").hide();
				$("#userGroupViewBox").hide();
				$("#userGroupEditorBox").show();
			}
			else {
				
				//-----------------------------------------------------------------
				
				$("#userGroupTitle").html(ug.name);
				$("#breadcrumbUserGroup").html(ug.name);
				
				var s = "";

                s += '<h5 class="text-primary">Informationen</h5>';
                s += '<table class="table"><tbody>';
                s += '<tr><td class="col-xs-5 col-sm-4 col-md-3">Name</td><td class="font-semibold">' + ug.name + '</td></tr>';

				var status = "";
				switch (ug.status) {
					case constants.STATUS_OBJECT_ACTIVE:
						status = '<span class="badge bg-green-jungle">aktiv</span>';
						break;
					case constants.STATUS_OBJECT_INACTIVE:
						status = '<span class="badge badge-default">inaktiv</span>';
						break;
				}
                s += '<tr><td>Status</td><td class="font-semibold">' + status + '</td></tr>';

				sel = "";
				for(i = 0; i < model.users.length; i++) {
					
					u = model.users[i];
					if (u.status === constants.STATUS_OBJECT_DELETED)
						continue;
					
					if (ug.contains(u.id)) {
						sel += u.getName(true);
						if (u.status === constants.STATUS_OBJECT_INACTIVE)
							sel += " (inaktiv)";
						sel += "<br>";
					}
				}
                s += '<tr><td>Mitglieder</td><td class="font-semibold">' + sel + '</td></tr>';

                s += '</tbody></table>';

                $("#userGroupViewTable").html(s);
				
				if (authManager.hasPermission(UserRole.PERMISSION_TEAM_EDIT)) {
					$("#btnEditUserGroup").show().off("click").on("click", function () {
						//that.onUserGroupEditLoaded(ug, true);
						viewManager.showPage(constants.PAGE_USERGROUP_EDIT, {
							userGroupId: ug.id
						});
					});
				}
				else
					$("#btnEditUserGroup").hide();

				$("#userGroupViewBox").show();
				$("#userGroupEditorBox").hide();
			}
			
			$("#btnBackUg").off("click").on("click", function () {
				model.curUserGroupListView.preserveCurPage = true;
				window.history.back();
			});
		},
		
		//-------------------------------------------
		
		//Löschen
		setUserGroupStatusDeleted: function () {
			
			var that = this;
			
			if (that.isCurObjOutdated)
				that.updateCurObj();
			
			pg.confirmMsg("L&ouml;schen", "Möchten Sie dieses Team wirklich l&ouml;schen?", function (e) {
				if (e) {
					
					//hat diese UserGroup Aufträge?
					var ug = _.cloneDeep(model.curUserGroup);
					ug.status = constants.STATUS_OBJECT_DELETED;
					
					var userTasks = Task.getExclusiveUserGroupTasks(ug.id);
					if (userTasks.length > 0) {
						
						//User muss angeben, wer die Aufträge der UserGroup übernimmt
						successorManager.getTaskExecutorSuccessor(userTasks, ug, true, function (ugResp) {
							
							if (ugResp) {
								//ok, speichern
								dataManager.saveUserGroup(ugResp, that.onUserGroupSaved);
							}
						});
					}
					else {
						
						//ok, hat keine Aufträge
						dataManager.saveUserGroup(ug, that.onUserGroupSaved);
					}
					
					
				}
				else {
					viewManager.updateButtons();
				}
			});
			return false;
		},
		
		//-------------------------------------------
		
		//Validierung für Speichern
		initSaveUserGroup: function () {
			
			var ug = _.cloneDeep(model.curUserGroup);
			var that = userGroupDetailManager;
			
			if (that.isCurObjOutdated)
				that.updateCurObj();
			
			//blocking
			if (!viewManager.setButtonBlocking($("#btnSaveUg")))
				return false;
			
			//etwas geändert?
			if (!model.isCurFormDirty) {
				if (ug.id < 0)
					pg.showMsg("Bitte füllen Sie das Formular vollständig aus.");
				else
					viewManager.showNotification("Es wurden keine Änderungen festgestellt.");
				viewManager.updateButtons();
				return false;
			}
			
			//validate
			var formEl = $('#formUserGroupEdit');
			var error1 = $('.alert-danger', formEl);
			
			formEl.validate({
				errorElement: 'span', //default input error message container
				errorClass: 'help-block help-block-error', // default input error message class
				focusInvalid: true, // do not focus the last invalid input
				ignore: "",  // validate all fields including form hidden input
				messages: {},
				rules: {
					userGroupName: {
						required: true,
						minlength: 3
					},
					selectUsers: {
						required: true
					}
				},
				
				invalidHandler: function (event, validator) { //display error alert on form submit
					//success1.hide();
					error1.show();
					viewManager.updateButtons();
					viewManager.scrollToElement(error1, -200);
				},
				
				highlight: function (element) { // hightlight error inputs
					$(element)
						.closest('.form-group').addClass('has-error'); // set error class to the control group
				},
				
				unhighlight: function (element) { // revert the change done by hightlight
					$(element)
						.closest('.form-group').removeClass('has-error'); // set error class to the control group
				},
				
				success: function (label) {
					label
						.closest('.form-group').removeClass('has-error'); // set success class to the control group
				}
			});
			
			if (formEl.valid())
				that.execSaveUserGroup(ug, formEl, error1);
		},
		
		execSaveUserGroup: function (ug, form, error1) {

			var that = userGroupDetailManager;

			error1.hide();

			//Name ok?
			var uGroup = UserGroup.getUserGroupByName(pg.validateInput($("#userGroupName").val(), 255));
			if (uGroup) {
				if (ug.id < 0) {
					pg.showMsg("Es existiert bereits ein Team dieses Namens.");
					viewManager.updateButtons();
					return;
				}
				if (ug.id !== uGroup.id) {
					pg.showMsg("Es existiert bereits ein Team dieses Namens.");
					viewManager.updateButtons();
					return;
				}
			}

			//leere Benutzerliste?
			var userList = "";
			$('#selectUsers :selected').each(function (i, selected) {
				userList += $(selected).val() + "#";
			});
			if (userList.length === 0) {
				pg.showMsg("Es muss mindestens ein Benutzer zugeordnet sein.");
				viewManager.updateButtons();
				return;
			}

			//gravierende Status-Änderung?
			var newStatus = this.getUserGroupStatus();
			if ($("#userGroupStatusDeleted").val() === "1")
				newStatus = constants.STATUS_OBJECT_DELETED;

			//Aufträge dieser UserGroup
			var userGroupTasks = Task.getExclusiveUserGroupTasks(ug.id),
				i;

			ug.name = pg.validateInput($("#userGroupName").val(), 255);
			ug.status = newStatus;

			ug.users = "";
			$('#selectUsers :selected').each(function (index, selected) {
				ug.users += $(selected).val() + "#";
			});
			ug.updateUsers(ug.users);

			//inaktiv/deleted
			if (newStatus !== constants.STATUS_OBJECT_ACTIVE) {

				//User muss angeben, wer die Aufträge der UserGroup übernimmt
				if (userGroupTasks.length > 0) {

					//User muss angeben, wer die Aufträge der UserGroup übernimmt
					successorManager.getTaskExecutorSuccessor(userGroupTasks, ug, true, function (ugResp) {

						//ok, speichern
						if (ugResp) {
							dataManager.saveUserGroup(ugResp, that.onUserGroupSaved);
						}
						else{
							//Status wiederherstellen
							that.setUserGroupStatus(constants.STATUS_OBJECT_ACTIVE);
						}
					});

					return;
				}
				else {
					dataManager.saveUserGroup(ug, that.onUserGroupSaved);
				}
			}
			else {

				//mit den neuen Änderungen, sind alle Aufträge immer noch ausführbar?
				if (userGroupTasks.length > 0) {

					var unexecuteableTasks = [];
					for(i = 0; i < userGroupTasks.length; i++) {
						var t = userGroupTasks[i];
						if (!ug.canExecuteTask(t))
							unexecuteableTasks.push(t);
					}

					if (unexecuteableTasks.length) {

						//User muss angeben, wer die Aufträge der UserGroup übernimmt
						successorManager.getTaskExecutorSuccessor(unexecuteableTasks, ug, true, function (ugResp) {

							//erst jetzt speichern
							if (ugResp) {
								dataManager.saveUserGroup(ugResp, that.onUserGroupSaved);
							}
						});
						return;
					}
					else {
						//keine unbearbeitbaren Aufträge, direkt speichern
						dataManager.saveUserGroup(ug, that.onUserGroupSaved);
					}
				}
				else {

					//keine Aufträge, direkt speichern
					dataManager.saveUserGroup(ug, that.onUserGroupSaved);
				}
			}


		},
		
		//Speichern komplett
		onUserGroupSaved: function (ug, isNew) {
			
			viewManager.updateButtons();
			
			model.curUserGroup = ug;
			if (isNew) {
				
				viewManager.showPage(constants.PAGE_USERGROUP_LIST, null, true, false, true);
				viewManager.showNotification("Neues Team erfolgreich angelegt.", constants.NOTIFICATION_SUCCESS);
			}
			else {
				
				if (ug.status !== constants.STATUS_OBJECT_DELETED) {
					
					viewManager.showPage(constants.PAGE_USERGROUP_LIST, null, true, false, true);
					viewManager.showNotification("Die Änderungen wurden gespeichert.", constants.NOTIFICATION_SUCCESS);
				}
				else {
					viewManager.showPage(constants.PAGE_USERGROUP_LIST, null, true, false, true);
					viewManager.showNotification("Das Team wurde gel&ouml;scht.", constants.NOTIFICATION_SUCCESS);
				}
			}
			
		},
		
		//-------------------------------------------
		
		//neue UserGroup hinzufügen
		addUserGroup: function (callbackComplete) {
			
			var that = this;
			
			var ug = UserGroup.createUserGroup();
			ug.id = -1;
			ug.clientId = model.curClientId;
			ug.name = "";
			ug.status = constants.STATUS_OBJECT_ACTIVE;
			ug.isSingleUser = 0;
			
			model.curUserGroup = ug;
			
			$("#content").html(window.templates['userGroupEdit.html']);
			setTimeout(function () {
				
				that.onUserGroupEditLoaded(ug, true);
				
				$("#userGroupStatusBox").hide();
				$("#btnDeleteUserGroup").hide();
				
				//update components
				viewManager.updateLayout();

				if (callbackComplete)
					callbackComplete();
				
			}, constants.TEMPLATE_DELAY);
			
			return false;
		}
		
	};
	
	window.userGroupDetailManager = userGroupDetailManager;
}());
