(function () {
	
	//------------------------------------------------------------------------
	//High-Level-Objekt für Backend
	//------------------------------------------------------------------------
	
	/*global App:true, viewManager:true, authManager:true, dataManager:true, constants:true, User:true, UserRole:true, connectionManager:true*/
	
	'use strict';
	
	var appManager = {
		
		initUpkeepr: function (callback) {
			
			//-------------------------------------------------------------------
			//Singleton: core data
			//-------------------------------------------------------------------
			window.model = {
				
				prevFetchTimestamp: 0,
				curPageId: "undefined",
				
				client: null,
				accounts: [],
				attachments: [],
				clientModules: [],
				tasks: [],
				taskTypes: [],
				taskTypeFields: [],
				taskLabels: [],
				locations: [],
				locationTypes: [],
				locationFields: [],
				locationFormFields: [],
				locationTree: {},
				users: [],
				userRoles: [],
				signals: [],
				incidents: [],
				userGroups: [],
				protocolForms: [],
				protocolFormFields: [],
				requests: [],
				
				curClientId: -1,
				curUserId: -1,
				
				curSessionToken: null,
				
				curTask: null,
				curLocation: null,
				
				reset: function () {
					
					this.prevFetchTimestamp = 0;
					
					this.client = null;
					this.attachments = [];
					this.clientModules = [];
					this.tasks = [];
					this.taskTypes = [];
					this.taskTypeFields = [];
					this.taskLabels = [];
					this.locations = [];
					this.locationTypes = [];
					this.locationFields = [];
					this.locationFormFields = [];
					this.locationTree = {};
					this.users = [];
					this.userRoles = [];
					this.signals = [];
					this.userGroups = [];
					this.protocolForms = [];
					this.protocolFormFields = [];
					this.requests = [];
					this.curTask = null;
					this.curLocation = null;
				},
				
				curTaskListView: {
					filter: {},
					sortDirection: constants.SORTING_NONE,
					sortField: null,
					pageCount: 0,
					entriesPerPage: 25,
					curPage: 0,
					scrollPos: 0,
					preserveCurPage: false
				},
				curRequestListView: {
					filter: {},
					sortDirection: constants.SORTING_NONE,
					sortField: null,
					pageCount: 0,
					entriesPerPage: 25,
					curPage: 0,
					scrollPos: 0,
					preserveCurPage: false
				},
				curUserListView: {
					scrollPos: 0,
					preserveCurPage: false
				},
				curUserGroupListView: {
					scrollPos: 0,
					preserveCurPage: false
				},
				curLocationFilter: {
					locationTypes: null,
					taskIncidentType: -1
				},
				curIncidentListView: {
					filter: {
						"signalDefect": 1
					},
					sortDirection: constants.SORTING_NONE,
					sortField: null,
					pageCount: 0,
					entriesPerPage: 25,
					curPage: 0,
					scrollPos: 0,
					preserveCurPage: false
				},
				
				resetLogin: function (resetAccounts) {
					
					if (resetAccounts === undefined)
						resetAccounts = false;
					
					this.curUserId = -1;
					this.curSessionToken = null;
					
					if (resetAccounts) {
						model.accounts = [];
						model.loginData = null;
					}
				}
				
			};
			
			//init data source
			connectionManager.setDataSource(constants.DATASOURCE_PRODUCTION);
			if (window.location.href.indexOf("debug-dev") >= 0)
				connectionManager.setDataSource(constants.DATASOURCE_DEV);
			if (window.location.href.indexOf("debug-qs") >= 0)
				connectionManager.setDataSource(constants.DATASOURCE_QS);
			if (window.location.href.indexOf("upkeeperapp.com") >= 0)
				connectionManager.setDataSource(constants.DATASOURCE_QS);

			//--------------------------------------------------------------------
			//Infos über Version

			$("#releaseVersion").html("<b>Version " + constants.RELEASE_VERSION + "</b> (" + constants.RELEASE_BUILD + ")");
			$("#releaseVersion").click(function(){

				var versionInfo = "<b>System</b><br/><br/>" +
					"Version: " + constants.RELEASE_VERSION + "<br/>" +
					"Build: " + constants.RELEASE_BUILD + "<br/>" +
					"Lib: " + $("#vendorLibScript").attr("src");
				pg.showMsg(versionInfo);
			});

			viewManager.updateButtons();

			//--------------------------------------------------------
			//wie geht es weiter?

			var proceed = true,
				urlNew,
				url;

			//pwd reset?
			var pwdResetToken = pg.getUrlParameter("reset-password");
			if (pwdResetToken) {

				//Token ok?
				dataManager.validatePwdResetToken(pwdResetToken, function (success) {
					
					//Parameter entfernen
					url = pg.removeUrlParameter(window.location.href, "reset-password=" + pwdResetToken);
					viewManager.originalHref = pg.removeUrlParameter(viewManager.originalHref, "reset-password=" + pwdResetToken);
					window.history.replaceState(window.history.state, "upkeepr", url);
					
					authManager.showPasswordReset(success, pwdResetToken);

					if (callback)
						callback();
				});
				proceed = false;
			}

			//Einladung?
			var invitationToken = pg.getUrlParameter("accept-invitation");
			if (invitationToken) {

				//Token ok?
				dataManager.validateInvitationToken(invitationToken, function (response) {

					//Parameter entfernen
					url = pg.removeUrlParameter(window.location.href, "accept-invitation=" + invitationToken);
					viewManager.originalHref = pg.removeUrlParameter(viewManager.originalHref, "accept-invitation=" + invitationToken);
					window.history.replaceState(window.history.state, "upkeepr", url);

					authManager.showAcceptInvitation(response, invitationToken);

					if (callback)
						callback();
				});
				proceed = false;
			}

			//normal ins System?
			if (proceed) {
				setTimeout(function () {

					//bestimmte Kunden-Vorgabe?
					var argClientId = pg.getUrlParameter("clientId");

					authManager.checkValidToken(argClientId, function (verificationResult) {

						//erfolgreich?
						if (verificationResult.success === 1) {

							if (argClientId) {
								urlNew = pg.removeUrlParameter(window.location.href, "clientId=" + argClientId);
								viewManager.originalHref = pg.removeUrlParameter(viewManager.originalHref, "clientId=" + argClientId);
								window.history.replaceState(window.history.state, "upkeepr", urlNew);
							}

							appManager.initView(callback);
						}
						else{
							
							if (verificationResult.errorId > 0){
								
								switch (verificationResult.errorId){
									
									//Trial abgelaufen
									case constants.ERROR_LOGIN_TRIAL_EXPIRED:
										authManager.showTrialExpired(verificationResult.clientName, verificationResult.clientOwner);
										break;
								}
								
							}
							else {
								
								authManager.showLogin();
								
								if (verificationResult.error) {
									$('#formLogin .errMsg').html(verificationResult.error).show();
								} else {
									
									//bestimmte Aktion?
									var argAction = pg.getUrlParameter("action");
									if (argAction) {
										
										//entfernen
										urlNew = pg.removeUrlParameter(window.location.href, "action=" + argAction);
										viewManager.originalHref = pg.removeUrlParameter(viewManager.originalHref, "action=" + argAction);
										window.history.replaceState(window.history.state, "upkeepr", urlNew);
										
										//anwenden
										argAction = parseInt(argAction);
										switch (argAction) {
											case constants.LOGOUT_SESSION_EXPIRED:
												$('#formLogin .errMsg').html("Die verwendete Session ist nicht mehr g&uuml;ltig. Bitte melden Sie sich neu an.").show();
												break;
											case constants.LOGOUT_MANUALLY:
												$('#formLogin .successMsg').html("Sie wurden erfolgreich abgemeldet.").show().delay(3000).fadeOut(200);
												break;
											case constants.LOGOUT_DELETED:
												$('#formLogin .successMsg').html("Ihr Account wurde erfolgreich gel&ouml;scht.").delay(3000).fadeOut(200);
												break;
										}
									}
								}
							}

							if (callback)
								callback();
						}
					});
				}, 50);
			}

			//--------------------------------------------------------
			
		},
		
		//Starten des Backends
		startUpkeepr: function () {
			
			//nicht aus ionic heraus!
			if (typeof App === 'undefined')
				return;
			
			$(window).on("beforeunload", function () {
				
				//überhaupt anzeigen?
				if (viewManager.isFormPage())
					if (model.isCurFormDirty)
						return "Wollen Sie die Formulareingabe wirklich beenden?";
			});

			//remember URL
			viewManager.init();

			// update components
			viewManager.updateLayout();
			viewManager.updateButtons();
			
			// init Metronic
			App.init();
			
			//init high-level
			var that = this;
			this.initUpkeepr(function(){
				that.hidePreloader();
			});

		},

		hidePreloader: function(){
			//preloader weg
			setTimeout(function () {
				$(".preloader").removeClass("preloader");
				$(".loading-overlay").remove();
			}, 100);
		},
		
		//once logged in
		initView: function (callback) {
			var that = this;

			viewManager.setButtonBlocking($("#btnLogin"), true);
			
			//fetch from DB
			dataManager.loadData(true, function (newCount) {
				
				if (newCount === null) {
					pg.showMsg("Es gab ein Problem während der Server-Kommunikation. Bitte versuchen Sie es später noch einmal.");
					viewManager.updateButtons();
					return;
				}
				
				viewManager.updateDataLoadedDisplay(0);
				
				//umschalten
				if ($("#loginDialog").is(":visible"))
					$("#loginDialog").fadeOut();
				if ($("#accountSelectionDialog").is(":visible"))
					$("#accountSelectionDialog").fadeOut();
				$("#pageBox").fadeIn();
				$("#pageHeader").fadeIn();
				$("#navBarBox").fadeIn();
                $("#pageFooter").fadeIn();
				
				//Client
				viewManager.updateClientDisplay();
				viewManager.updateAccountDisplay();
				
				//User
				var u = User.getUser(model.curUserId);
				var role = UserRole.getUserRole(u.role);
                var initials = (u.firstName.substring(0, 1) + u.lastName.substring(0, 1)).toUpperCase();
                $(".initialsCircle").html(initials);
                $("#loggedInUserName").html(u.getName());
                $("#loggedInUserRole").html(role.name);

				//--------------------------------------------------------------------
				
				//Ansicht beschränken
				
				var hasSettings = false;
				if (!authManager.hasPermission(UserRole.PERMISSION_SYSTEM)) {
					$("#menuAdmin").hide();
				}
				else {
					$("#menuAdmin").show();
					hasSettings = true;
				}
				
				if (!authManager.hasPermission(UserRole.PERMISSION_CLIENT_DETAILS)) {
					$("#menuClient").hide();
				}
				else {
					$("#menuClient").show();
					hasSettings = true;
				}
				
				if (!authManager.hasPermission(UserRole.PERMISSION_CLIENT_EDIT)) {
					$("#menuGeneralSettings").hide();
				}
				else {
					$("#menuGeneralSettings").show();
					hasSettings = true;
				}
				
				$("#menuRequests").show();
				if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_ALL,
						UserRole.PERMISSION_REQUEST_LIST_OWN,
						UserRole.PERMISSION_REQUEST_LIST_ASSIGNED_DECIDER,
						UserRole.PERMISSION_REQUEST_LIST_RELEASED,
						UserRole.PERMISSION_REQUEST_LIST_BY_TASK_TYPE)) {
					$("#menuRequests").hide();
				}
				if (!model.client.hasModule(constants.MODULE_ID_REQUESTS)) {
					$("#menuRequests").hide();
				}
				
				if (!authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_ALL,
						UserRole.PERMISSION_TASK_LIST_OWN,
						UserRole.PERMISSION_TASK_LIST_BY_TASK_TYPE)) {
					$("#menuTasks").hide();
				}
				else {
					$("#menuTasks").show();
				}
				
				$("#menuLocations").show();
				if (!authManager.hasPermission(UserRole.PERMISSION_LOCATION_LIST_ALL,
						UserRole.PERMISSION_LOCATION_LIST_BY_LOCATION_TYPE)) {
					$("#menuLocations").hide();
				}
				//keine Objekttypen -> kein Menü!
				if (model.locationTypes.length === 0) {
					$("#menuLocations").hide();
				}
				
				$("#menuIncidents").show();
				if (!authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_ALL,
					UserRole.PERMISSION_INCIDENT_LIST_OWN,
					UserRole.PERMISSION_INCIDENT_LIST_BY_TASK_TYPE)) {
					$("#menuIncidents").hide();
				}
				
				//bei LIST_SELF -> nur via Profile Shortcut oben
				if (!authManager.hasPermission(UserRole.PERMISSION_USER_LIST_ALL)) {
					$("#menuUsers").hide();
				}
				else {
					hasSettings = true;
					$("#menuUsers").show();
				}
				
				if (!authManager.hasPermission(UserRole.PERMISSION_TEAM_LIST)) {
					$("#menuUserGroups").hide();
				}
				else {
					hasSettings = true;
					$("#menuUserGroups").show();
				}
				
				if (!hasSettings) {
					$("#menuSettings").hide();
				}
				else
					$("#menuSettings").show();
				
				if (!authManager.hasPermission(UserRole.PERMISSION_USER_LIST_ALL, UserRole.PERMISSION_USER_LIST_SELF)) {
					$("#btnEditProfile").hide();
				}
				else
					$("#btnEditProfile").show();
				
				//------------------------------------
				//Klick auf primäre Menüs behandeln
				$("#sideMenu > li.nav-item > .nav-link.nav-toggle").click(function(){
					
					var parent = $(this).parent();
					
					setTimeout(function(){
						//suche ersten sichtbaren Unterpunkt
						var ch = $(".nav-item", parent);
						$.each(ch, function(i, o){
							if ($(o).is(":visible")) {
								
								//Klick auslösen
								$("a", o).trigger( "click" );
								
								//break!
								return false;
							}
						});
					}, 100);
				});

				//--------------------------------------------------------------------
				
				window.onpopstate = viewManager.onPopState;
				
				//default (oder übergeben)
				var trgPageId = model.showPageId || undefined;
				var argsObj = model.showPageArgsObj || undefined;
				viewManager.showPage(trgPageId, argsObj, undefined, false, true);
				model.showPageId = null;
				model.showPageArgsObj = null;

				//wenn Browser-Tab inaktiv: UpdateTiming deutlich verringern
				that.appHasFocus = true;
				$(window).on("blur focus", function(e) {
					switch (e.type) {
						case "blur":
							that.appHasFocus = false;
							break;
						case "focus":
							that.appHasFocus = true;
							break;
					}
				});

				//Hintergrund-Aktualisierung starten
				that.initNextAutoDataRefresh();

				$(window).on("resize", that.onResize);
				
				setTimeout(function () {
					viewManager.updateButtons();
					
					$(".btnSelectAccount").removeClass("isSelected");
					authManager.startSessionTracking();
					
				}, 500);

				if (callback)
					callback(true);
			});
			
		},
		
		execRefreshData: function () {
			if (viewManager.curRefreshMode === viewManager.REFRESH_MODE_LOAD) {
				appManager.refreshData(true);
			}
			else {
				
				//neu rendern (ggf. State wiederherstellen)
				var urlArgs = (model.curPageArgs) ? model.curPageArgs.urlArgs : {};
				var args = $.extend(model.curPageArgs, urlArgs);
				viewManager.showPage(model.curPageId, args, false, false, true, true);
				
			}
		},
		
		//load newest changes
		refreshData: function (verbose) {
			var that = this;

			if (verbose === undefined)
				verbose = true;

			dataManager.loadData(verbose, function complete(newCount){
				
				//Account-Namen aktualisieren
				viewManager.updateClientDisplay();
				
				viewManager.updateDataLoadedDisplay(newCount);
				if (newCount)
					viewManager.updateAccountDisplay();
				
				//weiter
				that.initNextAutoDataRefresh();
				
			}, function newData(data, oldModel) {
				
				//Daten für aktuellen Screen auswerten
				that.checkModelChanged(data, oldModel);
				
			});
			
		},
		
		//nächste Ausführung
		initNextAutoDataRefresh: function () {

			var that = appManager;

			//Timeout ggf. stoppen
			that.stopAutoDataRefresh();

			//neu starten
			model.curRefreshTimeout = setTimeout(function () {

				var doRefresh = false;
				if (that.appHasFocus){
					//sollte die Anwendung in den Hintergrund geraten, wird nur gelegentlich auf Updates geprüft
					that.autoDataRefreshCounter = 0;
					doRefresh = true;
				}
				else{
					that.autoDataRefreshCounter++;
					if (that.autoDataRefreshCounter >= constants.REFRESH_MULTIPLIER_IDLE){
						that.autoDataRefreshCounter = 0;
						doRefresh = true;
					}
					else{
						//im Hintergrund aber weitermachen
						that.initNextAutoDataRefresh();
					}
				}

				if (doRefresh)
					that.refreshData(false);

			}, constants.REFRESH_INTERVAL);
		},
		
		stopAutoDataRefresh: function () {
			if (model.curRefreshTimeout)
				clearTimeout(model.curRefreshTimeout);
		},
		
		//----------------------------------------------------------------
		
		//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
		checkModelChanged: function (data, oldModel) {
			
			if (!viewManager.curViewManager)
				return;
			var analysisFunction = (viewManager.curViewManager).checkModelChanged;
			var hasModelChanged = false;
			if (analysisFunction) {
				hasModelChanged = analysisFunction(data, oldModel);
			}
			
			if (hasModelChanged)
				viewManager.setRefreshMode(viewManager.REFRESH_MODE_RENDER, true);
			else
				viewManager.setRefreshMode(viewManager.REFRESH_MODE_LOAD, false);
		},
		
		//--------------------------------------------------------------
		
		//Resize-Handler
		onResize: function(){
			if (!viewManager.curViewManager)
				return;
			var resizeFunction = (viewManager.curViewManager).onResize;
			if (resizeFunction)
				resizeFunction();
		}
	};
	
	window.appManager = appManager;

	//------------------------------------------------------------------------

	//Validator Rule-Addon
	if ($.validator) {
		//noinspection JSUnresolvedFunction
		$.validator.addMethod("valueNotEquals", function (value, element, arg) {
			return arg !== element.value;
		}, "Bitte einen gültigen Wert auswählen.");
	}

}());
