(function () {
	
	//----------------------------------------------------------------
	//Kommentar zu Auftrag/Request
	//----------------------------------------------------------------
	
	'use strict';
	
	var Comment = {
		
		commentPrototype: {
			
			fromObj: function (o) {
				this.id = parseInt(o.id || -1, 10);
				this.createdOn = pg.parseDate(o.createdOn || new Date());
				this.createdBy = parseInt(o.createdBy, 10);
				this.changedOn = pg.parseDate(o.changedOn);
				this.changedBy = parseInt(o.changedBy, 10);

				this.clientId = parseInt(o.clientId, 10);
				this.userId = parseInt(o.userId, 10);
				this.entityType = parseInt(o.entityType, 10);
				this.entityId = parseInt(o.entityId, 10);
				this.comment = o.comment;
				 
				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.clientId = this.clientId;
				o.userId = this.userId;
				o.entityType = this.entityType;
				o.entityId = this.entityId;
				o.comment = this.comment;
				
				return o;
			},
			
			//-----------------------------------------------------
			
			//sich selbst zuordnen
			assign: function(){
				
				var i;
				
				switch (this.entityType){
					case constants.ENTITY_TYPE_TASK:
						for(i = 0; i < model.tasks.length; i++) {
							var t = model.tasks[i];
							if (!t.isCurrentVersion)
								continue;
							if (this.entityId === t.taskId){
								t.addComment(this);
								break;
							}
						}
						break;
					
					case constants.ENTITY_TYPE_REQUEST:
						if (model.requests) {
							for(i = 0; i < model.requests.length; i++) {
								var r = model.requests[i];
								if (!r.isCurrentVersion)
									continue;
								if (this.entityId === r.requestId) {
									r.addComment(this);
									break;
								}
							}
						}
						break;
				}
			}
		},
		
		createComment: function () {
			return Object.create(Comment.commentPrototype);
		}
	};
	
	window.Comment = Comment;
}());

