(function () {
	
	//------------------------------------------------------------
	// Protokolle verwalten
	//------------------------------------------------------------
	
	/*global
	 constants:true,
	 viewManager:true,
	 dataManager:true,
	 User:true,
	 ProtocolForm:true,
	 Location:true,
	 Task:true,
	 taskDetailManager:true,
	 ProtocolFormField:true,
	 taskRequestEditorManager:true,
	 Signal:true,
	 authManager:true,
	 UserRole:true,
	 TaskType:true,
	 Incident:true,
	 UserGroup:true*/
	
	'use strict';
	
	var protocolManager = {

		//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
		checkModelChanged: function (data, oldModel) {

			//aktuelles Protokoll verändert?
			var i,
				tsOld,
				tsNew,
				hasMatch;
			
			if (data.protocols) {
				for(i = 0; i < data.protocols.length; i++) {
					if (parseInt(data.protocols[i].id) === oldModel.curProtocol.id){
						tsOld = oldModel.curProtocol.changedOn.getTime();
						tsNew = pg.parseDate(data.protocols[i].changedOn).getTime();
						if (tsNew !== tsOld)
							return true;
					}
				}
			}
			if (data.incidents) {
				for(i = 0; i < data.incidents.length; i++) {
					
					if (parseInt(data.incidents[i].protocolId) === oldModel.curProtocol.id){
						
						hasMatch = false;
						var exInc = oldModel.curProtocol.getIncident(data.incidents[i].id);
						if (exInc) {
							hasMatch = true;
							tsOld = exInc.changedOn.getTime();
							tsNew = pg.parseDate(data.incidents[i].changedOn).getTime();
							if (tsNew !== tsOld)
								return true;
						}
						//neues Objekt
						if (!hasMatch)
							return true;
					}
				}
			}
			if (data.signals) {
				for(i = 0; i < data.signals.length; i++) {
					if (parseInt(data.signals[i].protocolId) === oldModel.curProtocol.id){
						
						hasMatch = false;
						var exSig = oldModel.curProtocol.getSignal(data.signals[i].id);
						if (exSig) {
							hasMatch = true;
							
							tsOld = exSig.changedOn.getTime();
							tsNew = pg.parseDate(data.signals[i].changedOn).getTime();
							if (tsNew !== tsOld)
								return true;
						}
						//neues Objekt
						if (!hasMatch)
							return true;
					}
				}
			}
			
			return false;
		},
		
		//------------------------------------------------------------------------------------------------------------------------------------------------
		
		//Protokoll-Liste anzeigen
		//return: has protocols
		showProtocols: function (protocols, trgId, showLocation, sortAsc) {

			if (sortAsc === undefined)
				sortAsc = true;
			
			var i,
				s = '',
				inc,
				count = 0;

			//sortieren
			protocols.sort(function (a, b) {

				var valA, valB;

				//Auftragsansicht -> sortieren nach Objekt, Formularname
				if (showLocation) {
					if (a.locationId !== b.locationId) {
						var locA = Location.getLocation(a.locationId, null, true, true, true);
						var locB = Location.getLocation(b.locationId, null, true, true, true);
						valA = locA.getName(true);
						valB = locB.getName(true);
					}
					else{
						var formA = ProtocolForm.getProtocolForm(a.formId);
						var formB = ProtocolForm.getProtocolForm(b.formId);
						valA = formA.name;
						valB = formB.name;
					}
				}
				else {

					//Objektansicht -> sortieren nach Datum
					valA = a.createdOn.getTime();
					valB = b.createdOn.getTime();
				}

				if (sortAsc)
					return ((valA < valB) ? -1 : ((valA > valB) ? 1 : 0));
				else
					return ((valB < valA) ? -1 : ((valB > valA) ? 1 : 0));
			});

			var maySeeTaskDetails = authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS);

			for(i = 0; i < protocols.length; i++) {
				
				var p = protocols[i];
				var task = Task.getTask(p.taskId);
				
				//--------------------------------------------------------------------------
				//permission?
				var mayShowProtocols = false;
				if (authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_LIST_ALL)) {
					mayShowProtocols = true;
				}
				else {
					if (authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_LIST_OWN)) {
						if (task.ownerId === model.curUserId) {
							mayShowProtocols = true;
						}
						var ts = task.getCurrentState();
						if (ts.responsibleId === model.curUserId) {
							mayShowProtocols = true;
						}
						if (ts.isAssignedToUser(model.curUserId))
							mayShowProtocols = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_LIST_BY_TASK_TYPE)) {
							if (model.curUser.hasTaskType(task.taskTypeId))
								mayShowProtocols = true;
						}
					}
				}
				if (!mayShowProtocols)
					continue;
				//------------------------------------------------------------------------
				
				inc = p.getIncidentCount();
				
				if (i % 2 === 0)
					s += '<tr class="gradeX even" role="row">';
				else
					s += '<tr class="gradeX odd" role="row">';

				//Details
				if (!p.isCompleted()){

					//Permissions prüfen
					var mayExecuteAssignment = false;
					if (!task.isArchived()) {
						if (model.curUser.canExecuteTask(task))
							mayExecuteAssignment = true;
					}
					if (mayExecuteAssignment){
						s += '<td><a data-taskId="' + p.taskId + '" data-locationId="' + p.locationId + '" data-formId="' + p.formId + '" class="btn grey-mint btn-xs btnEditLocationProtocol">Bearbeiten</a></td>';
					} else {
						s += '<td>&nbsp;</td>';
					}
				}
				else {
					if (authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_DETAILS)) {
						s += '<td><a data-taskId="' + p.taskId + '" data-protocolId="' + p.id + '" class="btn grey-mint btn-xs btnLocationProtocol">Details</a></td>';
					} else {
						s += '<td>&nbsp;</td>';
					}
				}

				//ID
				s += '<td>' + (p.id > 0 ? p.id : "" ) + '</td>';

				//Status
				switch (p.status) {
					case constants.STATUS_NEW:
						s += '<td><span class="badge badge-status statusColorNew">Offen</span></td>';
						break;
					case constants.STATUS_COMPLETED:
						s += '<td><span class="badge badge-status statusColorCompleted">Erledigt</span></td>';
						break;
					case constants.STATUS_PROGRESS:
						s += '<td><span class="badge badge-status statusColorProgress">In Bearbeitung</span></td>';
						break;
				}

                //Location/TaskId
                if ((p.locationId > 0)) {
                    if (showLocation)
                        s += '<td>' + Location.getLocation(p.locationId, undefined, true, true, true).getName(true) + '</td>';
                    else {
						//Details
						if (task.visibilityType === Task.VISIBILITY_TYPE_HIDDEN){
							s += '<td>-</td>';
						}
						else {
							if (maySeeTaskDetails) {
								//Hotfix für Daniel 14.07.2020
								//s += '<td><a data-taskId="' + task.taskId + '" class="btnTaskLink">#' + task.taskId + '</a></td>';
								s += '<td><a data-taskId="' + task.taskId + '" class="btnTaskLink">#' + task.taskId + ' (' + task.title + ')</a></td>';
							} else {
								s += '<td>#' + task.taskId + '</td>';
							}
						}
					}
                }
                else{
                    s += '<td></td>';
                }

				//Protokoll
                s += '<td>' + ProtocolForm.getProtocolForm(p.formId).name + '</td>';

				//Datum/Bearbeiter
				if (p.status === constants.STATUS_NEW) {
					s += '<td>&nbsp;</td>';
					s += '<td>&nbsp;</td>';
				}
				else {
					s += '<td>' + pg.formatDate(p.submittedOn || p.changedOn) + '</td>';
					s += '<td><span class="text-nowrap">' + User.getUserName(p.userId, true) + '</span></td>';
				}

				//Vorkommnisse
				if (p.status === constants.STATUS_NEW) {
					s += '<td>&nbsp;</td>';
					s += '<td>&nbsp;</td>';
					s += '<td>&nbsp;</td>';
				}
				else {
                    if (inc.incidentCount > 0) {
                        var incTotalCount = inc.incidentCount;
						var incCompletedCount = inc.incidentCount - inc.defectCount;
						if (inc.defectCount === 0)
							s += '<td class="text-center"><span class="badge"><i class="fa fa-fw fa-bolt"></i> ' + incCompletedCount + '/' + incTotalCount + ' </span></td>';
						else
							s += '<td class="text-center"><span class="badge badge-danger"><i class="fa fa-fw fa-bolt"></i> ' + incCompletedCount + '/' + incTotalCount + '</span></td>';
                    }
                    else
                        s += '<td>&nbsp;</td>';

					if (inc.notificationCount === 0)
						s += '<td>&nbsp;</td>';
					else
						s += '<td class="text-center"><span class="badge badge-danger"><i class="fa fa-fw fa-flag"></i></span></td>';

					//Fotos
					if (p.attObj.length === 0)
						s += '<td>&nbsp;</td>';
					else
						s += '<td class="text-center"><span class="badge default"><i class="fa fa-fw fa-camera"></i> ' + p.attObj.length + '</span></td>';


				}

				s += '</tr>';
				count++;
			}

			if (count === 0){
				s += "<tr><td colspan='8'>Keine Protokolle vorhanden.</td></tr>";
			}

			//remove previous
			//$(trgId + " tbody").find("tr:gt(0)").remove();
			//add new
			//$(trgId + " tbody").append(s);
			$(trgId + " tbody").html(s);
			//$(trgId + " tbody tr:first").after(s);

			if (maySeeTaskDetails){
				$(".btnTaskLink").off("click").on("click", function(e){

					var el = $(this);
					var taskId = parseInt(el.attr("data-taskId"));

					viewManager.showPage(constants.PAGE_TASK_DETAILS, {
						taskId: taskId
					});
					return false;
				});
			}

			return count;
		},
		
		//------------------------------------------------------------------------

		updateProtocolOverview: function(t, p){

			//------------------
			//Vorkommnisse etc.

			var el = $("#protocolOverview");

			if (el.length) {
				$("#lblRemarks").html("");
				$("#lblDefects").html("");
				$("#lblNotifications").html("");
				$("#lblAttachments").html("");

				if (p.status !== constants.STATUS_NEW) {

					var inc = p.getIncidentCount();

					var fixedObservationsCount = p.getIncidentTypeCount(Incident.INCIDENT_TYPE_FIXED_OBSERVATION);
					var unfixedObservationsCount = p.getIncidentTypeCount(Incident.INCIDENT_TYPE_UNFIXED_OBSERVATION);
					var totalObservationsCount = fixedObservationsCount + unfixedObservationsCount;
					if (totalObservationsCount > 0) {
						if (fixedObservationsCount === totalObservationsCount)
							$("#lblRemarks").html('<span class="badge"><i class="fa fa-fw fa-eye"></i> ' + fixedObservationsCount + '/' + totalObservationsCount + ' </span>');
						else
							$("#lblRemarks").html('<span class="badge badge-warning"><i class="fa fa-fw fa-eye"></i> ' + fixedObservationsCount + '/' + totalObservationsCount + ' </span>');
					}

					var fixedDefectsCount = p.getIncidentTypeCount(Incident.INCIDENT_TYPE_FIXED_DEFECT);
					var unfixedDefectsCount = p.getIncidentTypeCount(Incident.INCIDENT_TYPE_UNFIXED_DEFECT);
					var totalDefectsCount = fixedDefectsCount + unfixedDefectsCount;
					if (totalDefectsCount > 0) {
						if (fixedDefectsCount === totalDefectsCount)
							$("#lblDefects").html('<span class="badge"><i class="fa fa-fw fa-bolt"></i> ' + fixedDefectsCount + '/' + totalDefectsCount + ' </span>');
						else
							$("#lblDefects").html('<span class="badge badge-danger"><i class="fa fa-fw fa-bolt"></i> ' + fixedDefectsCount + '/' + totalDefectsCount + ' </span>');
					}

					if (inc.notificationCount > 0)
						$("#lblNotifications").html('<span class="badge badge-danger"><i class="fa fa-fw fa-flag"></i> ' + inc.notificationCount + '</span>');

					//Fotos
					if (p.attObj.length > 0)
						$("#lblAttachments").html('<span class="badge default"><i class="fa fa-fw fa-camera"></i> ' + p.attObj.length + '</span>');
				}
			}

			//-----------------------------
			//Auftragsinformationen

			if (t.visibilityType === Task.VISIBILITY_TYPE_HIDDEN){
				$("#taskInfoBox").hide();
			}
			else {

				$("#taskInfoBox").show();

				$("#lblTaskId").html('<a href="/#pageId=taskDetails&clientId=' + t.clientId + '&taskId=' + t.taskId + '">' + t.taskId + '</a>');
				$("#lblTaskId a").off("click").on("click", function () {
					viewManager.showPage(constants.PAGE_TASK_DETAILS, {
						taskId: t.taskId
					});
					return false;
				});

				$("#lblTaskType").html(TaskType.getTaskType(t.taskTypeId).name);
				$("#lblTaskTitle").html(t.title);
			}
		},

		//ausgefülltes Protokoll anzeigen
		showTaskProtocol: function (taskId, protocolId, callbackComplete) {

			var that = this;

			if (!authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_DETAILS)) {
				viewManager.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
				return false;
			}
			
			var t = Task.getTask(taskId);
			if (!t) {
				viewManager.showPage(constants.PAGE_TASK_LIST, {status: -1}, null, null, null, null, callbackComplete);
				return;
			}
			
			//neueste Version verwenden!
			while (t.nextVersion)
				t = t.nextVersion;
			model.curProtocolTask = t;
			model.curProtocol = t.getProtocolById(protocolId);
			if (!model.curProtocol) {
				viewManager.showPage(constants.PAGE_TASK_LIST, {status: -1}, null, null, null, null, callbackComplete);
				return;
			}
			
			$("#content").html(window.templates['taskProtocol.html']);
			setTimeout(function () {
				
				var p = model.curProtocol;
				var f = ProtocolForm.getProtocolForm(p.formId);
				var l = Location.getLocation(p.locationId, undefined, true, true, true);

				//var title = 'Auftrag #' + t.taskId;
				var title = 'Protokoll #' + protocolId;
				if (l) {

					if (t.visibilityType === Task.VISIBILITY_TYPE_HIDDEN){

						//Objekte -> {Objekt-Langbezeichnung}

						$("#taskTitle").html(title + " - " + f.name + " (" + l.getName() + ")");
						$("#taskTitle0Tasks").hide();
						$("#taskTitle0Locations").show();
						$("#taskTitle1Task").hide();
						$("#taskTitle1Location").html(l.getName(true)).show();
						$("#taskTitle2").html(title + " - " + f.name + " (" + l.getName() + ")");

					}
					else {

						$("#taskTitle").html(title + " - " + f.name + " (" + l.getName() + ")");
						$("#taskTitle0Tasks").show();
						$("#taskTitle0Locations").hide();
						$("#taskTitle1Task").html("Auftrag #" + taskId).show();
						$("#taskTitle1Location").hide();
						$("#taskTitle2").html(title + " - " + f.name + " (" + l.getName() + ")");
					}
				}
				else{
					$("#taskTitle").html(title + " - " + f.name);
					$("#taskTitle0Tasks").show();
					$("#taskTitle0Locations").hide();
					$("#taskTitle1Task").html("Auftrag #" + taskId).show();
					$("#taskTitle1Location").hide();
					$("#taskTitle2").html(title + " - " + f.name);
				}

				//Menü
				if (t.visibilityType === Task.VISIBILITY_TYPE_HIDDEN){
					viewManager.setSelectedMenuItem('menuLocations');
				}
				else {
					if (t.isArchived()) {
						$(".breadcrumbArchive").removeClass("hidden");
						viewManager.setSelectedMenuItem('menuTasks', 'menuTasksArchive');
					} else {
						$(".breadcrumbActive").removeClass("hidden");
						viewManager.setSelectedMenuItem('menuTasks', 'menuTasksActive');
					}
				}

				//-----------------------------------------------

				//Übersicht
				$("#lblProtocolId").html(protocolId);
				$("#lblProtocolType").html(f.name);
				if (l)
					$("#lblLocation").html(l.getName(true));

				//Status
				var s = "";
				switch (p.status) {
					case constants.STATUS_NEW:
						s += '<td><span class="badge badge-status statusColorNew">Offen</span></td>';
						break;
					case constants.STATUS_COMPLETED:
						s += '<td><span class="badge badge-status statusColorCompleted">Erledigt</span></td>';
						break;
					case constants.STATUS_PROGRESS:
						s += '<td><span class="badge badge-status statusColorProgress">In Bearbeitung</span></td>';
						break;
				}
				$("#lblProtocolStatus").html(s);

				var d = new Date();
				d.setTime(p.createdOn);
				$("#lblCompletedTs").html(pg.formatDate(d));

				var un = User.getUserName(p.createdBy, false);
				$("#lblUser").html(un);

				//Vorkommnisse etc.
				that.updateProtocolOverview(t, p);

				//-----------------------------------------------

				//Werte
				protocolManager.showProtocolData(p, "#tblProtocolData");
				
				//-----------------------------------------------
				//Incidents
				
				var showTabIncidents = false;
				if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_ALL, UserRole.PERMISSION_INCIDENT_LIST_OWN, UserRole.PERMISSION_INCIDENT_LIST_BY_TASK_TYPE)) {
					showTabIncidents = true;
					taskDetailManager.showIncidents(p.incidents, "#protocolIncidentsTable", true);
				}
				if (!showTabIncidents)
					$("#tabProtocolIncidents").hide();
				
				$("#btnBackProtocol").off("click").on("click", function () {

					model.curIncidentListView.preserveCurPage = true;

					window.history.back();
				});

				//-----------------------------------------------
				//PDF

				if (model.curProtocol.locationId < 0){
					$("#btnPrintProtocolLocationDetails").hide();
				}
				else{
					$("#btnPrintProtocolLocationDetails").show();
				}

				
				//-----------------------------------------------
				//Anhänge
				
				p.updateAttachments();
				if (p.isTransferredCompletely === 1)
					taskRequestEditorManager.showTaskAttachments("#protocolAttachmentTable", p.getAttachments(), false);
				else{
					$("#protocolAttachmentTable").html("<div class='w100'>Die Daten sind noch nicht komplett &uuml;bertragen worden.</div>");
				}
				
				//-----------------------------------------------
				
				//update components
				viewManager.updateLayout();

				if (callbackComplete)
					callbackComplete();
				
			}, constants.TEMPLATE_DELAY);
			
			return false;
		},

		//Protokoll-Werte anzeigen
		showProtocolData: function (p, trgEl) {

			var that = this;

			var form = ProtocolForm.getProtocolForm(p.formId);
			var curProtocolData = form.getProtocolData(p);
			var task = Task.getTask(p.taskId);

			//n.a.?
			var s = "";
			if (curProtocolData.length === 0) {
				s = "<tr><td colspan='7'>Keine Daten</td></tr>";
			}

			//Rechte
			var mayEditIncident = false;
			if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_ALL)) {
				mayEditIncident = true;
			}
			else {
				if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_OWN)) {
					if (task.ownerId === model.curUserId) {
						mayEditIncident = true;
					}
					var ts = task.getCurrentState();
					if (ts.responsibleId === model.curUserId) {
						mayEditIncident = true;
					}
					if (ts.isAssignedToUser(model.curUserId))
						mayEditIncident = true;
				}
				if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_BY_TASK_TYPE)) {
					if (model.curUser.hasTaskType(task.taskTypeId))
						mayEditIncident = true;
				}
			}

			//in der Originalreihenfolge zeigen
			curProtocolData.sort(function (a, b) {
				//Bemerkungen nach unten
				if (a.formFieldId < 0)
					return 1;
				if (b.formFieldId < 0)
					return -1;
				var fa = form.getFormField(a.formFieldId);
				var fb = form.getFormField(b.formFieldId);
				return (fa.orderIndex - fb.orderIndex);
			});

			var j,
				curCategory="";
			var signals = [];
			for(var i = 0; i < curProtocolData.length; i++) {
				var data = curProtocolData[i];

				//fix
				if (data.value)
					if (data.value === "null")
						data.value = "";

				//ggf. ausblenden
				if ((data.value === null) || (data.value === undefined) || (data.value === ""))
					data.value = "";

				var formField = null;
				if (data.formFieldId)
					formField = form.getFormField(data.formFieldId);

				//Kategorie?
				if (formField) {
					if ((formField.category) && (formField.category !== curCategory)) {
						curCategory = formField.category;
						if (curCategory.length)
							s += '<tr><td colspan="6"><h5 class="text-primary">' + curCategory + '</h5></td></tr>';
					}
				}
				else{
					if (data.type === 2){
						s += '<tr><td colspan="6"><h5 class="text-primary">Bemerkungen</h5></td></tr>';
					}
				}

				s += '<tr><td>' + data.name;

				s += "</td><td>";

				//Wert/Farbe
				if (data.colorClass && data.value)
					s += '<div class="label ' + data.colorClass + '">' + pg.nl2br(data.value) + '</div>';
				else
					s += pg.nl2br(data.value);

				s += "</td>";

				//incident
				var h = p.getIncidentByField(data.formFieldId);
				var guid;
				if (h) {

					s += "<td>";
					s += h.getType();
					s += "</td><td>";

					//Kommentar
					s += pg.nl2br(h.description || "");
					s += "</td><td>";

					//-----------------------------------------
					//DEFECT = Status
					var sig = null;
					var hasSignal = false;
					for(j = 0; j < h.signals.length; j++) {
						sig = h.signals[j];

						if (!sig.isNewestSignal(h.signals, j))
							continue;

						if (sig.type === Signal.SIGNAL_DEFECT) {
							hasSignal = true;
							break;
						}
					}
					if (!hasSignal) {
						sig = Signal.createSignalRaw();
						guid = Math.floor(Math.random() * constants.MAX_SAFE_INTEGER_MYSQL);
						sig.id = guid * -1;
						sig.type = Signal.SIGNAL_DEFECT;
						sig.value = Signal.VALUE_OK_DEFAULT;
					}
					signals.push(sig);

					//Anzeige via Switch
					s += '<input type="checkbox" data-signal-id="' + sig.id + '" data-task-id="' + h.taskId + '" data-incident-id="' + h.id + '" data-signal-type="' + sig.type + '" class="incidentSignal signalDefect" ';
					if (!mayEditIncident)
						s += ' disabled ';
					s += sig.getCheckboxValue();
					s += '/>';
					s += '</td><td>';

					//-----------------------------------------
					//NOTIFY = Meldung
					hasSignal = false;
					for(j = 0; j < h.signals.length; j++) {

						sig = h.signals[j];
						if (!sig.isNewestSignal(h.signals, j))
							continue;

						if (sig.type === Signal.SIGNAL_NOTIFY) {
							hasSignal = true;
							break;
						}
					}
					if (!hasSignal) {
						sig = Signal.createSignalRaw();
						guid = Math.floor(Math.random() * constants.MAX_SAFE_INTEGER_MYSQL);
						sig.id = guid * -1;
						sig.type = Signal.SIGNAL_NOTIFY;
						sig.value = 0;
					}
					signals.push(sig);

					//Anzeige via Switch
					s += '<input type="checkbox" data-signal-id="' + sig.id + '" data-task-id="' + h.taskId + '" data-incident-id="' + h.id + '" data-signal-type="' + sig.type + '" class="incidentSignal signalNotification" ';
					if (!mayEditIncident)
						s += ' disabled ';
					s += sig.getCheckboxValue();
					s += '/>';

					//-----------------------------------------
				}
				else {
					s += "<td colspan='4'>&nbsp;";
				}

				s += "</td></tr>";
			}

			$(trgEl + " tbody").html(s);

			//--------------------------------------------

			//Switches setzen und behandeln
			$(".incidentSignal.signalDefect").bootstrapSwitch({
				onText: '<i class="fa fa-fw fa-wrench"></i>',
				onColor: 'green',
				offText: '<i class="fa fa-fw fa-wrench"></i>',
				offColor: 'red',
				size: "mini",
				onSwitchChange: function (event, state) {
					that.toggleSignal(this, p);
				}
			});
			$(".incidentSignal.signalNotification").bootstrapSwitch({
				onText: '<i class="fa fa-fw fa-flag"></i>',
				onColor: 'gray',
				offText: '<i class="fa fa-fw fa-flag"></i>',
				offColor: 'red',
				size: "mini",
				onSwitchChange: function (event, state) {
					that.toggleSignal(this, p);
				}
			});
		},

		//Switch aktiviert
		toggleSignal: function(trgEl, p){

			var that = protocolManager;

			var taskId = parseInt($(trgEl).attr("data-task-id"));
			var task = Task.getTask(taskId);

			var sigId = parseInt($(trgEl).attr("data-signal-id"));
			var sig = task.getSignal(sigId);

			//Rechte
			if (!authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_ALL, UserRole.PERMISSION_INCIDENT_EDIT_OWN, UserRole.PERMISSION_INCIDENT_EDIT_BY_TASK_TYPE)) {
				return false;
			}
			var mayEditIncident = false;
			if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_ALL)) {
				mayEditIncident = true;
			}
			else {
				if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_OWN)) {
					if (task.ownerId === model.curUserId) {
						mayEditIncident = true;
					}
					var tsCur = task.getCurrentState();
					if (tsCur.responsibleId === model.curUserId) {
						mayEditIncident = true;
					}
					if (tsCur.isAssignedToUser(model.curUserId))
						mayEditIncident = true;
				}
				if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_BY_TASK_TYPE)) {
					if (model.curUser.hasTaskType(task.taskTypeId))
						mayEditIncident = true;
				}
			}
			if (!mayEditIncident)
				return false;

			//noch kein Signal vorhanden?
			if (!sig) {

				var incident = model.curProtocolTask.getIncident(parseInt($(trgEl).attr("data-incident-id")));
				var type = parseInt($(trgEl).attr("data-signal-type"));
				var val = Signal.VALUE_NOT_OK;

				sig = Signal.createSignal(incident.clientId, incident.userId, incident.taskId, incident.locationId, incident.protocolId, incident.formId, incident.id, incident.fieldId, type, val);
			}
			else {
				//umkehren
				sig.toggle();
			}

			//in DB merken
			dataManager.storeSignal(sig, function (dataResp) {

				sig.id = parseInt(dataResp.id);
				sig.changedOn = pg.parseDate(dataResp.changedOn);

				//Anzeige aktualisieren
				$("tbody .incidentSignal[data-incident-id='" + sig.incidentId + "'][data-signal-id='" + sigId + "'][data-signal-type='" + sig.type + "']")
					//.removeClass(sig.getButtonCssDefault())
					//.addClass(sig.getButtonCss())
					//.html(sig.getValue())
					.attr("data-signal-id", sig.id);

				//neu
				if (parseInt(dataResp.isNew) === 1) {
					var incident2 = model.curProtocolTask.getIncident(sig.incidentId);
					incident2.addSignal(sig);
				}

				//Ansicht aktualisieren
				taskDetailManager.updateTaskIncidents(model.curProtocolTask);
				taskDetailManager.updateTaskProtocols(model.curProtocolTask);
				that.updateProtocolOverview(task, p);

				//auch Protokollvorkommnisse
				taskDetailManager.showIncidents(p.incidents, "#protocolIncidentsTable", true);

				viewManager.showNotification("Vorkommnis erfolgreich aktualisiert.", constants.NOTIFICATION_SUCCESS);
			});

		},

		//-------------------------------------------------------------

		//zu Auftrag springen
		gotoTask: function () {
			viewManager.showPage(constants.PAGE_TASK_DETAILS, {
				taskId: model.curProtocolTask.taskId
			});
		},

		//zu Objekte springen
		gotoLocation: function () {
			viewManager.showPage(constants.PAGE_LOCATION_LIST, {
				locationId: model.curProtocol.locationId
			});
		},

		//----------------------------------------

		//PDF erzeugen
		generateProtocolPdf: function(showLocation){

			var locName = "";
			var locId = -1;
			if (model.curProtocol.locationId > 0) {
				var loc = Location.getLocation(model.curProtocol.locationId);
				locName = loc.nameShort;
				locId = loc.locationId;
			}
			var form = ProtocolForm.getProtocolForm(model.curProtocol.formId);

			window.open(dataManager.getProtocolPdfUrl(
				locName,
				locId,
				form.name,
				model.curProtocol.id,
				model.curProtocol.createdOn,
				showLocation
			), "_blank");
		}

	};
	
	window.protocolManager = protocolManager;
}());
