(function () {
	
	//----------------------------------------------------------------
	//Zustand eines Antrags
	//----------------------------------------------------------------
	
	'use strict';
	
	var RequestState = {

		//um Stati für Request und Task zusammenführen zu können, werden die aus der Datenbank geholten Werte um einen fixen Wert (100000000) ergänzt
		//dies geschieht beim Laden aus der DB sowie in der API vor dem Speichern (dort: Abzug der Konstante)
		STATUS_ADDON: 100000000,

		//neue Stati
		STATUS_NEW: 		100000000,
		STATUS_APPROVED: 	100000001,
		STATUS_REJECTED: 	100000002,
		STATUS_INQUIRY: 	100000003,
		STATUS_CLOSED: 		100000004,
		STATUS_COMPLETED: 	100000005,
		STATUS_REOPENED: 	100000006,
		
		/*- offen, geschlossen (gleiche Permission "edit")
		 - freigegeben (Kommentar ist optional)
		 - abgelehnt/Rückfrage (Kommentar ist Pflichtfeld)
		 - erledigt (wenn Auftrag erstellt)*/
		
		requestStatePrototype: {
			
			fromObj: function (s) {

				this.id = parseInt(s.id, 10);
				this.createdOn = pg.parseDate(s.createdOn);
				this.createdBy = parseInt(s.createdBy, 10);
				this.changedOn = pg.parseDate(s.changedOn);
				this.changedBy = parseInt(s.changedBy, 10);

				this.clientId = parseInt(s.clientId, 10);
				this.status = parseInt(s.status, 10);
				if (this.status < RequestState.STATUS_ADDON)
					this.status += RequestState.STATUS_ADDON;
				this.requestId = parseInt(s.requestId, 10);
				this.version = parseInt(s.version, 10);
				this.deciderId = parseInt(s.deciderId, 10);
				this.responsibleId = parseInt(s.responsibleId, 10);
				this.comment = s.comment;
				
				//Eingruppierung (Archiv etc.)
				this.groupId = parseInt(s.groupId || 0);
				
				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.clientId = this.clientId;
				o.status = this.status;
				o.requestId = this.requestId;
				o.version = this.version;
				o.deciderId = this.deciderId;
				o.responsibleId = this.responsibleId;
				o.comment = this.comment;
				o.groupId = this.groupId;
				
				return o;
			}
		},
		
		createRequestState: function () {
			return Object.create(RequestState.requestStatePrototype);
		}
	};
	
	window.RequestState = RequestState;
}());