(function () {
	
	//----------------------------------------------------------------
	//Benutzerrolle
	//----------------------------------------------------------------
	
	'use strict';
	
	var UserRole = {
		
		//permissions
		PERMISSION_LOGIN_APP: 1,
		PERMISSION_LOGIN_BACKEND: 2,
		PERMISSION_TASK_LIST_ALL: 3,
		PERMISSION_TASK_LIST_OWN: 4,
		PERMISSION_TASK_LIST_BY_TASK_TYPE: 5,
		PERMISSION_TASK_DETAILS: 6,
		PERMISSION_TASK_CREATE_ALL: 7,
		PERMISSION_TASK_CREATE_BY_TASK_TYPE: 8,
		PERMISSION_TASK_EDIT_ALL: 9,
		PERMISSION_TASK_EDIT_OWN: 10,
		PERMISSION_TASK_EDIT_BY_TASK_TYPE: 11,
		PERMISSION_TASK_DELETE_ALL: 12,
		PERMISSION_TASK_DELETE_OWN: 13,
		PERMISSION_TASK_ASSIGN_ALL: 14,
		PERMISSION_TASK_ASSIGN_OWN: 15,
		PERMISSION_TASK_EXECUTE_ALL: 16,
		PERMISSION_TASK_EXECUTE_OWN: 17,
		PERMISSION_TASK_EXECUTE_BY_TASK_TYPE: 18,
		PERMISSION_PROTOCOL_LIST_ALL: 19,
		PERMISSION_PROTOCOL_LIST_OWN: 20,
		PERMISSION_PROTOCOL_LIST_BY_TASK_TYPE: 21,
		PERMISSION_PROTOCOL_DETAILS: 22,
		PERMISSION_INCIDENT_LIST_ALL: 23,
		PERMISSION_INCIDENT_LIST_OWN: 24,
		PERMISSION_INCIDENT_LIST_BY_TASK_TYPE: 25,
		PERMISSION_INCIDENT_DETAILS: 26,
		PERMISSION_INCIDENT_EDIT_ALL: 27,
		PERMISSION_INCIDENT_EDIT_OWN: 28,
		PERMISSION_INCIDENT_EDIT_BY_TASK_TYPE: 29,
		PERMISSION_LOCATION_LIST_ALL: 30,
		PERMISSION_LOCATION_LIST_BY_LOCATION_TYPE: 31,
		PERMISSION_LOCATION_DETAILS: 32,
		PERMISSION_LOCATION_CREATE_ALL: 33,
		PERMISSION_LOCATION_CREATE_BY_LOCATION_TYPE: 34,
		PERMISSION_LOCATION_EDIT_ALL: 35,
		PERMISSION_LOCATION_EDIT_BY_LOCATION_TYPE: 36,
		PERMISSION_LOCATION_DELETE_ALL: 37,
		PERMISSION_LOCATION_DELETE_BY_LOCATION_TYPE: 38,
		PERMISSION_LOCATION_FORM_FIELDS_DETAILS: 39,
		PERMISSION_LOCATION_FORM_FIELDS_EDIT_ALL: 40,
		PERMISSION_LOCATION_FORM_FIELDS_EDIT_BY_LOCATION_TYPE: 41,
		PERMISSION_USER_LIST_ALL: 42,
		PERMISSION_USER_LIST_SELF: 43,
		PERMISSION_USER_DETAILS: 44,
		PERMISSION_USER_CREATE: 45,
		PERMISSION_USER_EDIT_ALL: 46,
		PERMISSION_USER_EDIT_SELF: 47,
		PERMISSION_USER_EDIT_SUBSET_ONLY: 48,
		PERMISSION_USER_DELETE: 49,
		PERMISSION_TEAM_LIST: 50,
		PERMISSION_TEAM_DETAILS: 51,
		PERMISSION_TEAM_CREATE: 52,
		PERMISSION_TEAM_EDIT: 53,
		PERMISSION_TEAM_DELETE: 54,
		PERMISSION_REQUEST_LIST_ALL: 55,
		PERMISSION_REQUEST_LIST_OWN: 56,
		PERMISSION_REQUEST_LIST_ASSIGNED_DECIDER: 68,
		PERMISSION_REQUEST_LIST_RELEASED: 57,
		PERMISSION_REQUEST_DETAILS: 58,
		PERMISSION_REQUEST_CREATE: 59,
		PERMISSION_REQUEST_EDIT_ALL: 60,
		PERMISSION_REQUEST_EDIT_OWN: 61,
		PERMISSION_REQUEST_DELETE: 62,
		PERMISSION_REQUEST_SET_STATUS_INQUIRY_ALL: 69,
		PERMISSION_REQUEST_SET_STATUS_INQUIRY_ASSIGNED: 70,
		PERMISSION_REQUEST_EXECUTE_ALL: 63,
		PERMISSION_REQUEST_EXECUTE_ASSIGNED: 71,
		PERMISSION_REQUEST_CONVERT_RELEASED_TO_TASK_ALL: 64,
		PERMISSION_REQUEST_CONVERT_RELEASED_TO_TASK_ASSIGNED: 72,
		PERMISSION_CLIENT_DETAILS: 65,
		PERMISSION_CLIENT_EDIT: 66,
		PERMISSION_SYSTEM: 67,
		PERMISSION_REQUEST_ARCHIVE_ALL: 73,
		PERMISSION_REQUEST_ARCHIVE_OWN: 74,
		PERMISSION_TASK_ARCHIVE_ALL: 75,
		PERMISSION_TASK_ARCHIVE_OWN: 76,
		PERMISSION_TASK_ARCHIVE_BY_TASK_TYPE: 77,
		PERMISSION_REQUEST_LIST_BY_TASK_TYPE: 78,
		PERMISSION_REQUEST_CREATE_BY_TASK_TYPE: 79,
		PERMISSION_REQUEST_EDIT_BY_TASK_TYPE: 80,
		PERMISSION_REQUEST_ARCHIVE_BY_TASK_TYPE: 81,

		MAX_PERMISSION_INDEX: 81,

		//-------------------------------------------------------------------
		
		userRolePrototype: {
			
			fromObj: function (t) {
				
				var i;
				
				this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				this.name = pg.restoreDbString(t.name);
				this.abbrev = pg.restoreDbString(t.abbrev);
				this.clientId = parseInt(t.clientId, 10);

				this.permissions = [];
				if (t.permissions){
					for (i=0; i<t.permissions.length; i++)
						this.permissions.push(t.permissions[i]);
				}
				else {
					for (i=1; i<=UserRole.MAX_PERMISSION_INDEX; i++)
						this.permissions.push({
							id: i,
							value: parseInt(t['p_' + i])
						});
				}
				
				this.defaultLocationType = -1;
				if (t.defaultLocationType)
					this.defaultLocationType = parseInt(t.defaultLocationType);
				
				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.name = this.name;
				o.abbrev = this.abbrev;
				o.clientId = this.clientId;

				o.permissions = [];
				if (this.permissions)
					for (var i = 0; i < this.permissions.length; i++)
						o.permissions.push(this.permissions[i]);
				
				o.defaultLocationType = this.defaultLocationType;
				
				return o;
			}
			
		},
		createUserRole: function () {
			return Object.create(UserRole.userRolePrototype);
		},
		
		//-------------------------------------------------------------------
		
		getUserRole: function (id) {
			id = parseInt(id);
			for (var i = 0; i < model.userRoles.length; i++)
				if (model.userRoles[i].id === id)
					return model.userRoles[i];
			return null;
		}
	};
	
	window.UserRole = UserRole;
}());