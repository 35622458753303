(function () {
	
	//-------------------------------------------
	//Manager für Users
	//-------------------------------------------
	
	/*global
	 constants:true,
	 viewManager:true,
	 User:true,
	 UserRole:true,
	 Task:true,
	 UserGroup:true,
	 authManager:true
	 */
	
	'use strict';
	
	var userListManager = {
		
		//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
		checkModelChanged: function(data, oldModel){
			
			var i,
				j,
				tsOld,
				tsNew,
				hasMatch;
			
			//Benutzer verändert?
			if (data.users) {
				for(i = 0; i < data.users.length; i++) {
					var u = data.users[i];
					
					hasMatch = false;
					for (j=0; j<oldModel.users.length; j++) {
						var uOld = oldModel.users[j];
						
						if (parseInt(u.id) === uOld.id) {
							hasMatch = true;
							tsOld = uOld.changedOn.getTime();
							//noinspection JSUnresolvedVariable
							tsNew = pg.parseDate(u.changedOn).getTime();
							if (tsNew !== tsOld)
								return true;
						}
					}
					
					//neues Objekt
					if (!hasMatch)
						return true;
				}
			}
			
			return false;
		},
		
		//----------------------------------------------------------------------------------------------
		
		//Vorbereitung zur Listen-Anzeige
		showUsers: function (callbackComplete) {

			var that = this;
			
			if (!authManager.hasPermission(UserRole.PERMISSION_USER_LIST_ALL)) {
				viewManager.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
				return false;
			}
			
			viewManager.setSelectedMenuItem('menuSettings', 'menuUsers');

			var restoreScrollPos = 0;
			if (model.curUserListView.preserveCurPage) {
				model.curUserListView.preserveCurPage = false;
				restoreScrollPos = model.curUserListView.scrollPos || 0;
			}

			$("#content").html(window.templates['userList.html']);
			setTimeout(function () {
				
				that.showUsersView();

				viewManager.setScrollTop(restoreScrollPos);

				//Zustand
				if (!model.curStateDetails) {
					//state merken
					model.curStateDetails = {
						curTabIndex: 0
					};
				}
				viewManager.activateNavTab("#userBox", model.curStateDetails.curTabIndex);

				//update components
				viewManager.updateLayout();

				if (callbackComplete)
					callbackComplete();
				
			}, constants.TEMPLATE_DELAY);
		},
		
		//Benutzerliste rendern
		showUsersView: function () {

			//sortieren nach name ASC
			model.users.sort(function (a, b) {
				var aName = (a.lastName + " " + a.firstName).toLowerCase();
				var bName = (b.lastName + " " + b.firstName).toLowerCase();
				return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
			});
			
			//Inhalte
			var s = '';
			var s2 = '';
			var count1 = 0;
			var count2 = 0;
			var mayViewUser;
			for(var i = 0; i < model.users.length; i++) {
				var u = model.users[i];

				//verstecke alle mit ROLE_SYSTEM
				//(nur für selbst ggf. sichtbar)
				if (model.curUserId !== u.id) {
					if (u.isSystemAdmin())
						continue;
				}

				if (u.status === constants.STATUS_OBJECT_DELETED)
					continue;

				if (u.status === constants.STATUS_OBJECT_UNREGISTERED){

					//--------------------------------
					//Invited

					count2++;

					if (count2 % 2 === 0)
						s2 += '<tr class="gradeX even">';
					else
						s2 += '<tr class="gradeX odd">';

					//----------
					//Details

					s2 += '<td>';

					mayViewUser = false;
					if (authManager.hasPermission(UserRole.PERMISSION_USER_DETAILS)) {
						mayViewUser = true;
					}
					if (authManager.hasPermission(UserRole.PERMISSION_USER_EDIT_SELF)) {
						if (u.id === model.curUserId)
							mayViewUser = true;
					}
					if (mayViewUser)
						s2 += '  <a data-userId="' + u.id + '" class="btn grey-mint btn-xs btnUserEdit">Details</a>';
					s2 += '</td>';

					//----------

					s2 += '<td>' + u.firstName + ' <span class="font-semibold">' + u.lastName + '</span></td>';
					//s2 += '<td>' + u.firstName + '</td>';
					s2 += '<td>' + u.email + '</td>';
					s2 += '<td>' + u.department + '</td>';
					s2 += '<td>' + u.getRoleName() + '</td>';

					s2 += '</tr>';

				}
				else{

					//--------------------------------
					//Alle

					count1++;

					if (count1 % 2 === 0)
						s += '<tr class="gradeX even">';
					else
						s += '<tr class="gradeX odd">';

					//-------------
					//Details
					s += '<td>';

					mayViewUser = false;
					if (authManager.hasPermission(UserRole.PERMISSION_USER_DETAILS)) {
						mayViewUser = true;
					}
					if (authManager.hasPermission(UserRole.PERMISSION_USER_EDIT_SELF)) {
						if (u.id === model.curUserId)
							mayViewUser = true;
					}
					if (mayViewUser)
						s += '  <a data-userId="' + u.id + '" class="btn grey-mint btn-xs btnUserEdit">Details</a>';
					s += '</td>';

					//-------------

					//s += '<td>' + u.id + '</td>';
					s += '<td>' + u.firstName + ' <span class="font-semibold">' + u.lastName + '</span>';
					if (u.id === model.client.ownerId) {
						s += '<span class="badge badge-primary ml5">Accountinhaber</span>';
					}
					s += '</td>';
					//s += '<td>' + u.firstName + '</td>';
					s += '<td>' + u.email + '</td>';
					s += '<td>' + u.department + '</td>';
					s += '<td>' + u.getRoleName() + '</td>';
					s += '<td>';
					var taskCount = Task.getUserTasks(u.id).length;
					if (taskCount > 0) {
						if (authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_ALL, UserRole.PERMISSION_TASK_LIST_OWN, UserRole.PERMISSION_TASK_LIST_BY_TASK_TYPE))
							s += '  <a data-userId="' + u.id + '" class="btnUserTasks">' + pg.getStemmedName(taskCount, "Auftrag", "Aufträge") + '</a>';
					}
					s += '</td>';

					s += '</tr>';
				}

			}

			$("#userTableAll tbody").append(s);
			$("#userTableInvited tbody").append(s2);

			//Anzeige Tabs
			$("#linkUserListAll").html('Aktiv (' + count1 + ')');
			$("#linkUserListInvited").html('Ausstehend (' + count2 + ')');

			//handler
			
			if (authManager.hasPermission(UserRole.PERMISSION_USER_CREATE)) {
				$("#btnAddUser").show().on("click", function () {
					viewManager.showPage(constants.PAGE_USER_ADD);
				});
			}
			else
				$("#btnAddUser").hide();
			
			$(".btnUserEdit").on("click", function (e) {
				
				if (e) {

					//scroll-Position merken
					model.curUserListView.scrollPos = viewManager.getScrollTop();

					var link = $(e.currentTarget);
					if (link.attr("data-userId")) {
						
						var trgUserId = parseInt(link.attr("data-userId"), 10);
						
						viewManager.showPage(constants.PAGE_USER_DETAILS, {
							userId: trgUserId
						});
					}
				}
			});
			$(".btnUserTasks").on("click", this.showUserTasks);
		},
		
		//-------------------------------------------
		
		//Sprung zu Aufträgen eines Benutzers
		showUserTasks: function (e) {
			
			if (e) {

				//scroll-Position merken
				model.curUserListView.scrollPos = viewManager.getScrollTop();

				var link = $(e.currentTarget);
				if (link.attr("data-userId")) {
					var userId = parseInt(link.attr("data-userId"), 10);
					var u = User.getUser(userId);
					
					viewManager.showPage(constants.PAGE_TASK_LIST, {
						userGroupIds: u.getUserGroups(),
						userGroup: UserGroup.getUserGroup(u.singleUserGroupId)
					});
				}
			}
			return false;
		},
		
		//---------------------------------------------------------------------------------

		//tab changed
		onUserListTabChange: function (index) {
			model.curStateDetails.curTabIndex = index;
			userListManager.setUserListStateDetails();
		},

		setUserListStateDetails: function () {
			model.curStateDetails.scrollPosition = viewManager.getScrollTop();
			viewManager.setStateDetails(model.curStateDetails);
		}
		
	};
	
	window.userListManager = userListManager;
}());
