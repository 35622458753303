(function () {

    'use strict';

	//Timezone Handling
	Date.prototype.stdTimezoneOffset = function() {
		var jan = new Date(this.getFullYear(), 0, 1);
		var jul = new Date(this.getFullYear(), 6, 1);
		return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
	};
	Date.prototype.dst = function() {
		return this.getTimezoneOffset() < this.stdTimezoneOffset();
	};
	
    var pgDate = {

		getMonday: function(d) {
		  d = new Date(d);
		  var day = d.getDay(),
			  diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
		  return new Date(d.setDate(diff));
		},
		
		//https://stackoverflow.com/questions/1184334/get-number-days-in-a-specified-month-using-javascript
		daysInMonth: function(month, year){
			return new Date(year, month, 0).getDate();
		},
	
        roundQuarterHours: function (date) {
            //quadrant
            var m = Math.floor(date.getMinutes() / 4) * 15;
            date.setMinutes(m);

            return date;
        },


        formatDateExt: function (d) {
            if (!d)
            	return d;
            	
        	var human = this.formatDateHuman(d, true);
            var numeric = this.formatDate(d);
            if (human === numeric) {
                return numeric;
            }
            else {
                //return human + " (" + numeric + ")";
                return numeric + " (" + human + ")";
            }
        },

		LANG_DE: 0,
		LANG_EN: 1,
		formatDateHuman: function (d, skipMinutes, verbose, exactOlderDate, language) {
            if (skipMinutes === undefined)
                skipMinutes = false;
            if (verbose === undefined)
				verbose = false;
			if (exactOlderDate === undefined)
				exactOlderDate = false;
			if (language === undefined)
				language = pgDate.LANG_DE;

            var now = new Date();
            var delta = ((now.getTime()) - d.getTime()) / 1000;
			var d2;

            //zunächst Minuten
            var deltaMin = delta / 60;
            var deltaHrs = deltaMin / 60;
            var deltaDays = deltaHrs / 24;

            if (delta > 0) {

                if (deltaMin <= 10) {
					switch (language){
						case pgDate.LANG_DE:
							return "gerade eben";
						default:
							return "today";
					}
                }

                //heute? (anhand des Tages)
                if (deltaMin < 60) {
                    if (skipMinutes) {
                        if (now.getDate() === d.getDate()){
							switch (language){
								case pgDate.LANG_DE:
									return "heute";
								default:
									return "today";
							}
						}
                        else{
							switch (language){
								case pgDate.LANG_DE:
									return "gestern";
								default:
									return "yesterday";
							}
						}
                    }
                    else{
						switch (language){
							case pgDate.LANG_DE:
								return "vor " + pg.getStemmedName(Math.round(deltaMin), "Minute", "Minuten");
							default:
								return pg.getStemmedName(Math.round(deltaMin), "minute", "minutes") + " ago";
						}
					}
                }

                if (deltaDays <= 1){
                    if (now.getDate() === d.getDate()) {
                        if (skipMinutes){
							switch (language){
								case pgDate.LANG_DE:
									return "heute";
								default:
									return "today";
							}
						}
                        else{
							if (verbose){
								switch (language){
									case pgDate.LANG_DE:
										return "heute um " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2) + " Uhr";
									default:
										return "today at " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2) + "";
								}
							}
							else{
								switch (language){
									case pgDate.LANG_DE:
										return "heute, " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2);
									default:
										return "today, " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2);
								}
							}
						}
                    }
                    else {
                        if (skipMinutes){
							switch (language){
								case pgDate.LANG_DE:
									return "gestern";
								default:
									return "yesterday";
							}
						}
                        else{
							if (verbose){
								switch (language){
									case pgDate.LANG_DE:
										return "gestern um " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2) + " Uhr";
									default:
										return "yesterday at " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2) + "";
								}
							}
							else{
								switch (language){
									case pgDate.LANG_DE:
										return "gestern, " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2);
									default:
										return "yesterday, " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2);
								}
							}
						}
                    }
				}

                //alles ältere: Uhrzeit ist egal, auf heute Nacht 00:00 normalisieren
                now = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                d2 = new Date(d.getFullYear(), d.getMonth(), d.getDate());
                delta = ((now.getTime()) - d2.getTime()) / 1000;

                //zunächst Minuten
                deltaMin = delta / 60;
                deltaHrs = deltaMin / 60;
                deltaDays = deltaHrs / 24;

                //gestern (zwischen 0 Uhr und der aktuellen Zeit)
                if (deltaDays <= 2){
                    if (deltaMin <= (now.getHours() * 60 + now.getMinutes() + 60 * 24)){
                        if (skipMinutes){
							switch (language){
								case pgDate.LANG_DE:
									return "gestern";
								default:
									return "yesterday";
							}
						}
                        else{
							if (verbose){
								switch (language){
									case pgDate.LANG_DE:
										return "gestern um " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2) + " Uhr";
									default:
										return "yesterday at " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2) + "";
								}
							}
							else{
								switch (language){
									case pgDate.LANG_DE:
										return "gestern, " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2);
									default:
										return "yesterday, " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2);
								}
							}
						}
					}
				}

                //vorgestern (zwischen 0 Uhr und der aktuellen Zeit)
                if (deltaDays <= 3){
                    if (deltaMin <= (now.getHours() * 60 + now.getMinutes() + 60 * 24 * 2)){
                        if (skipMinutes){
							switch (language){
								case pgDate.LANG_DE:
									return "vorgestern";
								default:
									return "2 days ago";
							}
						}
                        else{
							if (verbose){
								switch (language){
									case pgDate.LANG_DE:
										return "vorgestern um " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2) + " Uhr";
									default:
										return "2 days ago at " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2) + "";
								}
							}
							else{
								switch (language){
									case pgDate.LANG_DE:
										return "vorgestern, " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2);
									default:
										return "2 days ago, " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2);
								}
							}
						}
					}
				}

                //älter
				if (exactOlderDate){
					if (skipMinutes){
						switch (language){
							case pgDate.LANG_DE:
								return "am " + pg.formatDateMonth(d, undefined, undefined, language);
							default:
								return pg.formatDateMonth(d, undefined, undefined, language);
						}
					}
					else{
						switch (language){
							case pgDate.LANG_DE:
								return "am " + pg.formatDateMonth(d) + " um " + pg.formatTime(d) + " Uhr";
							default:
								return pg.formatDateMonth(d) + " at " + pg.formatTime(d);
						}
					}
				}
				else{
					
					if (deltaDays < 10){
						switch (language){
							case pgDate.LANG_DE:
								return "vor " + pg.getStemmedName(Math.round(deltaDays), "Tag", "Tagen");
							default:
								return pg.getStemmedName(Math.round(deltaDays), "day", "days") + " ago";
						}
					}
					
					if (deltaDays < 7 * 4){
						switch (language){
							case pgDate.LANG_DE:
								return "vor " + pg.getStemmedName(Math.round(deltaDays / 7), "Woche", "Wochen");
							default:
								return pg.getStemmedName(Math.round(deltaDays / 7), "week", "weeks") + " ago";
						}
					}
					if (deltaDays < 7 * 4 * 12){
						switch (language){
							case pgDate.LANG_DE:
								return "vor " + pg.getStemmedName(Math.round(deltaDays / (7 * 4)), "Monat", "Monaten");
							default:
								return pg.getStemmedName(Math.round(deltaDays / (7 * 4)), "month", "months") + " ago";
						}
					}
				}
            } 
            else {
                //heute? (anhand des Tages, max 24h)
                if (deltaDays >= -1){
                    if (now.getDate() === d.getDate()){
						switch (language){
							case pgDate.LANG_DE:
								return "heute";
							default:
								return "today";
						}
					}
                    else{
						switch (language){
							case pgDate.LANG_DE:
								return "morgen";
							default:
								return "tomorrow";
						}
					}
				}
				//TODO: englische Variante
				
                //alles neuere: Uhrzeit ist egal, auf heute Nacht 00:00 normalisieren
                now = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                d2 = new Date(d.getFullYear(), d.getMonth(), d.getDate());
                delta = ((d2.getTime()) - now.getTime()) / 1000;

                //zunächst Minuten
                deltaMin = delta / 60;
                deltaHrs = deltaMin / 60;
                deltaDays = deltaHrs / 24;
                //console.log(deltaDays, deltaHrs, deltaMin);
                //morgen (zwischen 0 Uhr und der aktuellen Zeit)
                if (deltaDays <= 1)
                //if (deltaMin <= (now.getHours()*60 + now.getMinutes() + 60*24))
                    if (skipMinutes)
                        return "morgen";
                    else{
						if (verbose)
							return "morgen um " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2) + " Uhr";
						else
							return "morgen, " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2);
					}

                //übermorgen (zwischen 0 Uhr und der aktuellen Zeit)
                if (deltaDays <= 2)
                //if (deltaMin <= (now.getHours()*60 + now.getMinutes() + 60*24*2))
                    if (skipMinutes)
                        return "übermorgen";
                    else
						if (verbose)
							return "übermorgen um " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2) + " Uhr";
						else
							return "übermorgen, " + d.getHours() + ":" + pg.forceLength(d.getMinutes(), 2);

                //älter
                if (deltaDays < 10)
                    return "in " + pg.getStemmedName(Math.round(deltaDays), "Tag", "Tagen");
                if (deltaDays < 7 * 4)
                    return "in " + pg.getStemmedName(Math.round(deltaDays / 7), "Woche", "Wochen");
                if (deltaDays < 7 * 4 * 12)
                    return "in " + pg.getStemmedName(Math.round(deltaDays / (7 * 4)), "Monat", "Monaten");
            }

            //ansonsten: Datum, Uhrzeit
            //return formatDateTime(d);
            //ansonsten: Datum
            return this.formatDate(d);
        },

        formatDateTime: function (d) {
			
			if (!d)
                return "";
			
            var niceDay = pg.forceLength(d.getDate(), 2);
            var niceMonth = pg.forceLength(d.getMonth() + 1, 2);
            var niceYear = pg.forceLength(d.getFullYear(), 4);
            var niceHours = pg.forceLength(d.getHours(), 2);
            var niceMinutes = pg.forceLength(d.getMinutes(), 2);
			return niceDay + "." + niceMonth + "." + niceYear + ", " + niceHours + ":" + niceMinutes + " Uhr";
        },

        formatDate: function (d, useFullYear) {
            
        	if (useFullYear === undefined)
                useFullYear = false;

        	if (!d)
        		return "";
        	
            var niceDay = pg.forceLength(d.getDate(), 2);
            var niceMonth = pg.forceLength(d.getMonth() + 1, 2);
            //var niceYear = forceLength(d.getFullYear(), 4);
            var y = "" + d.getFullYear();
            var niceYear = pg.forceLength(y.substring(2), 2);
            if (useFullYear)
                niceYear = y;
            return niceDay + "." + niceMonth + "." + niceYear;
        },

        formatDateMonth: function (d, shortMonth, skipYear, language) {
            if (skipYear === undefined)
				skipYear = false;
			if (shortMonth === undefined)
				shortMonth = false;
			if (language === undefined)
				language = pgDate.LANG_DE;
			
			var niceDay = pg.forceLength(d.getDate(), 2);
			switch (language){
				case pgDate.LANG_DE:
					var months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
					if (shortMonth)
						months = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];
					break;
				default:
					var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
					if (shortMonth)
						months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
					break;
			}
            var niceMonth = months[d.getMonth()];
            
			switch (language){
				case pgDate.LANG_DE:
					if (skipYear)
						return niceDay + ". " + niceMonth;
					return niceDay + ". " + niceMonth + " " + d.getFullYear();

				default:
					if (skipYear)
						return niceMonth + " / " + niceDay;
					var th = "th";
					var date = d.getDate();
					if (date === 1)
						th = "st";
					if (date === 2)
						th = "nd";
					return niceMonth + " " + niceDay + th + " " + d.getFullYear();
			}
			
			return "";
        },

        formatTime: function (d) {
            var niceHours = pg.forceLength(d.getHours(), 2);
            var niceMinutes = pg.forceLength(d.getMinutes(), 2);
            return niceHours + ":" + niceMinutes + " Uhr";
        },

        // parse a date in yyyy-mm-dd hh:mm:ss format
        parseDate: function (input, isGermanLocale) {

            if (input === null)
                return null;
			if (isGermanLocale === undefined)
				isGermanLocale = false;

			var result = null;
			
			//input = "2017-07-19 16:00:00";
			
            if ($.type(input) === "string") {
				
				if (input.indexOf("z") > 0){
					return new Date(Date.parse(input));
				}
				else{
				
					input = pg.replace(input, " ", "-");
					input = pg.replace(input, ":", "-");
					input = pg.replace(input, ".", "-");
					var parts = input.split('-');
					
					for (var i=0; i<parts.length; i++)
						parts[i] = parseInt(parts[i]);
					
					if (isGermanLocale){
						if (parts.length >= 6)
							return new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4], parts[5]);
						return new Date(parts[2], parts[1] - 1, parts[0]);
					}
					else{
						if (parts.length >= 6)
							return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
						return new Date(parts[0], parts[1] - 1, parts[2]);
					}
				}
            }
            else if ($.type(input) === "date") {
                result = input;
            }
            else if ($.type(input) === "number") {
                var d = new Date();
                d.setTime(input);
                result = d;
            }
			
			return result;
        },
		fixTimezone: function(d){
			
			//force Zulu Timezone (UTC) from local
			var tzOffset = d.getTimezoneOffset();
			var trgOffset = 0;
			
			//var hasDst = d.dst();
			//if (!hasDst)
			//	trgOffset = -60;
		
			var deltaOffset = trgOffset - tzOffset;
			var dOut = new Date(d.getTime() + deltaOffset*60000);
			
			/*var test = pg.convertDateToUTC(d);
			console.log(d + " / " + pg.formatDateTime(d));
			console.log(test + " / " + pg.formatDateTime(test));
			console.log(dOut + " / " + pg.formatDateTime(dOut));*/
			return dOut;
			
		},
		convertDateToUTC: function(date) { 
			return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
		},

        //yyyy-mm-dd hh:mm:ss
        buildDate: function (d) {
			if (!d)
                return null;
            return pg.forceLength(d.getFullYear(), 4) + "-" + pg.forceLength(d.getMonth() + 1, 2) + "-" + pg.forceLength(d.getDate(), 2) + " " + pg.forceLength(d.getHours(), 2) + ":" + pg.forceLength(d.getMinutes(), 2) + ":" + pg.forceLength(d.getSeconds(), 2);
        },
		
		getDaysDiff: function (date1, date2) {
			var timeDiff = Math.abs(date2.getTime() - date1.getTime());
			return Math.ceil(timeDiff / (1000 * 3600 * 24));
		},
		
		isGreaterDateThan: function (ts, dCompare) {
			if (!ts)
				return false;
			
			if ($.type(ts) === "date")
				return (ts >= dCompare);
			
			var d = pg.replace(ts, " ", "-");
			d = pg.replace(d, ":", "-");
			var parts = d.split('-');
			var dateReg = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4]);
			
			//var regDateString = pg.forceLength(dateReg.getDate(), 2) + "." + pg.forceLength((dateReg.getMonth() + 1), 2) + "." + dateReg.getFullYear() + " " + pg.forceLength(dateReg.getHours(), 2) + ":" + pg.forceLength(dateReg.getMinutes(), 2);
			return (dateReg >= dCompare);
		}
    };

    //gemeinsam verfügbar machen
    if (typeof(pg) === 'undefined')
        window.pg = {};
    $.extend(pg, pgDate);
}());
(function () {

    'use strict';

    var pgFileUpload = {

        //media types
        MEDIA_TYPE_PDF: 0,
        MEDIA_TYPE_IMAGE: 1,
        MEDIA_TYPE_VIDEO: 2,
        MEDIA_TYPE_IMAGE_PDF: 3,
		MEDIA_TYPE_CSV: 4,

        callbackSuccess: {},
        callbackError: {},
        progressBar: {},

        setupFileUploader: function (trgBoxEl, uploadScriptUrl, contentType, multiple, id, callbackSuccess, callbackError) {

            if (multiple === undefined)
                multiple = false;

            var that = this;

            //---------------------------------------------------------

            //DOM ergänzen
            var r = Math.round(10000 * Math.random());
            var s = '';

            s += '<span class="btn btn-primary fileinput-button pull-right">';
            s += '    <i class="glyphicon glyphicon-plus fa fa-fw fa-plus-circle"></i>';

            switch (contentType) {
                case this.MEDIA_TYPE_IMAGE:
                    s += '    <span>Bild hinzuf&uuml;gen</span>';
                    break;
                case this.MEDIA_TYPE_IMAGE_PDF:
                case this.MEDIA_TYPE_PDF:
				case this.MEDIA_TYPE_CSV:
                    s += '    <span>Datei hinzuf&uuml;gen</span>';
                    break;
                case this.MEDIA_TYPE_VIDEO:
                    s += '    <span>Video hinzuf&uuml;gen</span>';
                    break;
            }
            s += '    <div class="fileUploadBox">';

            s += '          <input id="fileupload_' + r + '" type="file" name="files_' + r + '[]" ';
            if (multiple)
                s += 'multiple';
            s += ' />';
            s += '              <div id="files_' + r + '" class="files_' + r + '"></div>';
            s += '      </div>';
            s += '</span>';

            s += '<br>';
            s += '<br>';

            //progress bar
            s += '<div id="fileUploadProgressBar_' + r + '" class="uploadProgress progress" style="display:none;">';
            s += '    <div progressbar class="progress-bar" type="primary"></div>';
            s += '</div>';
            trgBoxEl.html(s);

            //--------------------------------------------------------

            that.callbackSuccess[id] = callbackSuccess;
            that.callbackError[id] = callbackError;
            that.progressBar[id] = trgBoxEl.find("#fileUploadProgressBar_" + r);

            //Ziel ist eingefügtes Objekt
            var trgEl = trgBoxEl.find("#fileupload_" + r);
            //$(".uploadProgress").hide();

            var acceptFileTypes, acceptFileTypesExt;
            switch (contentType) {
                case this.MEDIA_TYPE_PDF:
                    acceptFileTypes = /^(pdf)$/i;
                    acceptFileTypesExt = /(\.|\/)(pdf)$/i;
                    break;
                case this.MEDIA_TYPE_IMAGE:
                    acceptFileTypes = /^(gif|jpe?g|png)$/i;
                    acceptFileTypesExt = /(\.|\/)(gif|jpe?g|png)$/i;
                    break;
                case this.MEDIA_TYPE_IMAGE_PDF:
                    acceptFileTypes = /^(gif|jpe?g|png|pdf)$/i;
                    acceptFileTypesExt = /(\.|\/)(gif|jpe?g|png|pdf)$/i;
                    break;
                case this.MEDIA_TYPE_VIDEO:
                    acceptFileTypes = /^(mp4)$/i;
                    acceptFileTypesExt = /(\.|\/)(mp4)$/i;
                    break;
				case this.MEDIA_TYPE_CSV:
                    acceptFileTypes = /^(csv)$/i;
                    acceptFileTypesExt = /(\.|\/)(csv)$/i;
                    break;
            }

            //https://github.com/blueimp/jQuery-File-Upload/wiki/Options
            trgEl.params = {};
            trgEl.addParam = function (key, val) {
                this.params[key] = val;
                var uploadOptions = JSON.stringify(this.params);
                trgEl.attr("data-form-data", uploadOptions);
            };
            trgEl.addParam("filesId", "files_" + r);
            trgEl.addParam("id", id);

            //go
            //noinspection JSUnresolvedFunction,JSUnresolvedVariable
            trgEl.fileupload({
                add: function (e, data) {

                    var uploadErrors = [];
                    //noinspection JSUnresolvedVariable
                    var ext = data.originalFiles[0].name.substring(data.originalFiles[0].name.lastIndexOf(".") + 1).toLowerCase();
                    if (!acceptFileTypes.test(ext)) {
                        uploadErrors.push('Kein g&uuml;ltiger Dateityp.');
                    }
                    //noinspection JSUnresolvedVariable
                    if (data.originalFiles[0].size.length && data.originalFiles[0].size > 1999000) {
                        uploadErrors.push('Datei ist zu gro&szlig;.');
                    }

                    if (uploadErrors.length > 0) {
                        if (that.callbackError[id])
                            that.callbackError[id](uploadErrors.join("\n"));
                    } else {
                        data.submit();
                    }
                },
                url: uploadScriptUrl,
                dataType: 'json',
                autoUpload: true,
                acceptFileTypes: acceptFileTypesExt,
                maxFileSize: 1999000,
                disableImageResize: true,
                previewMaxWidth: 150,
                previewMaxHeight: 150,
                previewCrop: false
            })
                .on('fileuploadsubmit', function (e, data) {
                    //test extension
                    var filename = data.files[0].name;
                    return (acceptFileTypesExt.test(filename));
                })
                .on('fileuploadprogressall', function (e, data) {
                    that.progressBar[id].show();
                    var progress = parseInt(data.loaded / data.total * 100, 10);
                    //noinspection JSUnresolvedFunction
                    that.progressBar[id].find('.progress-bar').css('width', progress + '%');
                })
                .on('fileuploaddone', function (e, data) {

                    $.each(data.result.files, function (index, file) {
                        that.progressBar[id].hide();

                        if (file.url) {
                            if (that.callbackSuccess[id])
                                that.callbackSuccess[id](file);
                        }
                        else if (file.error)
                            if (that.callbackError[id])
                                that.callbackError[id](file.error);
                    });
                    //noinspection JSUnresolvedFunction
                    e.preventDefault();
                })
                .on('fileuploadfail', function (e, data) {
                    $.each(data.files, function (index) {
                        that.progressBar[id].hide();
                        if (that.callbackError[id])
                            that.callbackError[id]('Error uploading data.');
                    });
                }).prop('disabled', !$.support.fileInput)
                .parent().addClass($.support.fileInput ? undefined : 'disabled');

            return trgEl;
        }

    };

    //gemeinsam verfügbar machen
    if (typeof(pg) === 'undefined')
        window.pg = {};
    $.extend(pg, pgFileUpload);

}());
(function () {

    'use strict';

    var pgMain = {

        //update handlers etc.
        update: function () {

            setTimeout(function () {
                //bind paste handler
                $("input.pg, textarea.pg").off("paste").on("paste", pg.filterPasteText);
                //$("input.pg, textarea.pg").bind ("input propertychange", pg.filterPasteText);
            }, 500);

        }
    };

    if (typeof(pg) === 'undefined')
        window.pg = {};
    $.extend(pg, pgMain);
}());
(function () {

    /*global bootbox:true*/

    'use strict';

    var pgNonAngular = {

		fieldTypeForceError: -1,
		
        fieldTypeStringShort: 1,
        fieldTypeStringLong: 2,
        fieldTypeStringAreaCode: 3,
        fieldTypeStringEmail: 4,
        fieldTypeStringBoolean: 5,
        fieldTypeStringYear: 6,
        fieldTypeInt: 7,
        fieldTypePositiveInt: 8,
		fieldTypeStringPassword: 9,

        validateFormField: function (box, field, type) {
            var val = null,
				valInt,
				isRequired = true;
				
			if (field){
				val = field.val();
				isRequired = (field.attr("required"));
			}

			var successClass = "has-success";
			var errorClass = "has-error error";
			
			if (box){
				box.removeClass(successClass);
				box.removeClass(errorClass);
				//if (val)
				if (isRequired)
					box.addClass("has-feedback");
				/*else
					box.removeClass("has-feedback");*/
			}
			
            switch (type) {
				
				case pg.fieldTypeForceError:
					if (box && isRequired)
						box.addClass(errorClass);
                    return false;
					break;
				
                case pg.fieldTypeInt:
                case pg.fieldTypePositiveInt:
                    if (!val) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }

                    try {
                        valInt = parseInt(val, 10);
                    }
                    catch (e) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }
                    if (isNaN(valInt)) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }
                    if (type === pg.fieldTypePositiveInt) {
                        if (valInt <= 0) {
							if (box && isRequired)
								box.addClass(errorClass);
                            return false;
                        }
                    }
                    break;
                case pg.fieldTypeStringShort:
                    if (!val || val.length < 2) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }
                    break;
                case pg.fieldTypeStringLong:
                    if (!val || val.length < 5) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }
                    break;
                case pg.fieldTypeStringAreaCode:
                    if (!val || Number(val) < 1001 || val.length < 4 || val.length > 8) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }
                    break;
                case pg.fieldTypeStringEmail:
                    if (val.indexOf("@") === -1 || val.indexOf(".") === -1 || val.length < 7) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }
                    else if (!pg.validateEmail(val)) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }
                    break;
                case pg.fieldTypeStringBoolean:
                    try {
						//checkbox
						if (val === "on"){
							var valChecked = field.prop("checked");
							val = valChecked ? 1 : 0;
						}
                        valInt = parseInt(val, 10);
                    }
                    catch (e) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }
                    if (isNaN(valInt) || (val === 0)) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }
                    break;
                case pg.fieldTypeStringYear:
                    val = parseInt(val);
                    if (isNaN(val) || (val === 0)) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }
                    if ((val < 1900) || (val > (new Date().getFullYear()))) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }
                    break;
				case pg.fieldTypeStringPassword:
					if (!val || val.length < 8) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }
                    else if (!pg.validatePassword(val)) {
						if (box && isRequired)
							box.addClass(errorClass);
                        return false;
                    }
					break;
            }

			if (box)
				box.addClass(successClass);
            return true;
        },

        //-----------------------------------------------------------------
        //bootbox!

        //Anzeige von Nachrichten via bootbox
        showMsg: function (msg, callback) {

            msg = decodeURI(msg || "");//unescape
			
            /*var htmlMsg = '<div class="alertDialog">';
            htmlMsg += '<div id="msgText"><h4 class="fs18p">' + msg + '</h4></div>';
            htmlMsg += '</div>';*/
			var htmlMsg = msg;

			//remove previous
			$(".bootbox.modal").remove();
			$(".modal-backdrop").remove();
			
            var box = bootbox.alert(htmlMsg, callback);
            box.css({
                'top': '50%',
                'margin-top': function () {
                    return -(box.height() / 2);
                }
            });
			
			//tauschen
			//$(".bootbox .modal-header .modal-title").insertBefore(".bootbox .modal-header button");
        },

		confirmMsg: function(title, msg, callback, yes, no){
			
			yes = yes || "Ja";
			no = no || "Nein";
			
			 msg = decodeURI(msg);
			 
			 bootbox.dialog({
				 message: msg,
				 title: title,
				 buttons: {
					danger: {
						label: no,
						className: "btn default",
						callback: function() {
							if (callback)
								callback(false);
						}
					},
					success: {
						label: yes,
						className: "btn btn-primary",
						callback: function() {
							if (callback)
								callback(true);
						}
					}
				 },
				onEscape: function () {
					if (callback)
						callback(null);
				}
			});
			//tauschen
			//$(".bootbox .modal-header .modal-title").insertBefore(".bootbox .modal-header button"); 
		 },
		
		promptMsg: function (title, msg, callback, initialValue, inputType) {
			
			inputType = inputType || "text";
			
			var prompt = bootbox.prompt({
				title: title,
				value: initialValue, 
				inputType: inputType,
				buttons: {
					cancel: {
						label: "Abbrechen",
						className: "btn default",
						callback: function() {
							if (callback)
								callback();
						}
					},
					confirm: {
						label: "OK",
						className: "btn btn-primary",
						callback: function() {
							if (callback)
								callback(result);
						}
					}
				 },
				callback: function (result) {
					if (callback)
						callback(result);
				}
			});
			
			if (msg) {
                prompt.on('shown.bs.modal', function() {
                    prompt.find('.bootbox-body').prepend('<div class="mb15">' + msg + '</div>')
                });
            }
		}

    };

    //gemeinsam verfügbar machen
    if (typeof(pg) === 'undefined')
        window.pg = {};
    $.extend(pg, pgNonAngular);
}());
(function () {

    'use strict';

    var pgString = {

        forceLength: function (val, letterCount) {
            var s = String(val);
            while (s.length < letterCount) {
                s = "0" + s;
            }
            return s;
        },

        replace: function (s, find, repl) {
            if (s === null)
				return null;
			if (s === undefined)
				return null;
			if (s.length === 0)
				return s;
			var pos = s.indexOf(find);
            while (pos >= 0) {
                s = s.replace(find, repl);
                pos = s.indexOf(find);
            }

            return s;
        },

        reverseString: function (s) {
            return s.split("").reverse().join("");
        },

        invertString: function (s) {

            if (!s)
                return "ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ";

            var out = "";
            for (var i = 0; i < s.length; i++) {
                var code = s.charCodeAt(i);
                if (code < 128)
                    out += String.fromCharCode(128 - code);
            }
            return out;
        },

        nl2br: function (str) {
            if (!str)
                return "";
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2');
        },

        filterNullString: function (s) {
            if (s === "null")
                return null;
            return s;
        },
        handleNullString: function (s) {
            s = pg.filterNullString(s);
            if (!s)
                return "";
            return s;
        },

        //split & remove emptry entries
        splitNonEmpty: function (str, delim) {
            if (!str)
                return [];
            var a = str.split(delim);
            for (var i = a.length - 1; i >= 0; i--)
                if (!a[i])
                    a.splice(i, 1);
            return a;
        },

        //restore HTML characters (&uuml;)
        htmlDecode: function (input) {
            var e = document.createElement('div');
            e.innerHTML = input;
            return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
        },

        jsonSafe: function (s) {
            if (!s)
                return "";
            s = this.replace(s, "{", "(");
            s = this.replace(s, "}", ")");
            //s = replace(s, "[", "(");
            //s = replace(s, "]", ")");
            return s;
        },
        setObjectJsonSafe: function (oRaw) {
            if (!oRaw)
                return null;

            var o = _.cloneDeep(oRaw);
            for (var property in o) {
                if (o.hasOwnProperty(property)) {

                    if (_.isFunction(o[property]))
                        delete o[property];
                    if (_.isUndefined(o[property]))
                        o[property] = null;
                    if (_.isNaN(o[property]))
                        o[property] = null;
                    if (_.isString(o.property))
                        o[property] = this.jsonSafe(o[property]);
                }
            }

            return o;
        },
        //javascript equivalent to htmlspecialchars()
        escapeHtml: function (text) {
            if (text === null)
				return null;
			if (text === undefined)
				return null;
			if (text.length === 0)
				return text;

            var map = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#039;'
            };

            return text.replace(/[&<>"']/g, function (m) {
                return map[m];
            });
        },
        //javascript equivalent to htmlspecialchars_decode()
        unescapeHtml: function (s, quoteStyle) {
            /*jshint -W016 */

            if (s === null)
				return null;
			if (s === undefined)
				return null;
			if (s.length === 0)
				return s;

            var optTemp = 0;
            var i = 0;
            var noquotes = false;

            if (typeof quoteStyle === 'undefined') {
                quoteStyle = 2;
            }
            s = s.toString()
                .replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>');
            var OPTS = {
                'ENT_NOQUOTES': 0,
                'ENT_HTML_QUOTE_SINGLE': 1,
                'ENT_HTML_QUOTE_DOUBLE': 2,
                'ENT_COMPAT': 2,
                'ENT_QUOTES': 3,
                'ENT_IGNORE': 4
            };
            if (quoteStyle === 0) {
                noquotes = true;
            }
            if (typeof quoteStyle !== 'number') {
                // Allow for a single string or an array of string flags
                quoteStyle = [].concat(quoteStyle);
                for (i = 0; i < quoteStyle.length; i++) {
                    // Resolve string input to bitwise e.g. 'PATHINFO_EXTENSION' becomes 4
                    if (OPTS[quoteStyle[i]] === 0) {
                        noquotes = true;
                    } else if (OPTS[quoteStyle[i]]) {
                        optTemp = optTemp | OPTS[quoteStyle[i]];
                    }
                }
                quoteStyle = optTemp;
            }
            if (quoteStyle & OPTS.ENT_HTML_QUOTE_SINGLE) {
                // PHP doesn't currently escape if more than one 0, but it should:
                s = s.replace(/&#0*39;/g, "'");
                // This would also be useful here, but not a part of PHP:
                // s = s.replace(/&apos;|&#x0*27;/g, "'");
            }
            if (!noquotes) {
                s = s.replace(/&quot;/g, '"');
            }
            // Put this in last place to avoid escape being double-decoded
            s = s.replace(/&amp;/g, '&');

            return s;
        },

        breakItems: function (s) {
            s = this.replace(s, ", ", "<br/>");
            s = this.replace(s, ",", "<br/>");
            return s;
        },

        //Woche vs. Wochen
        getStemmedName: function (val, unitSingular, unitPlural) {
            if (val === 1)
                return val + " " + unitSingular;
            else
                return val + " " + unitPlural;
        },
		
		//--------------------------------------------------------------
    
		//Output des Angular-Text korrigieren
		filterParagraphs: function(s){
			
			if (!s)
				return s;
			
			s = this.replace(s, "<p>", "");
			s = this.replace(s, "</p>", "<br/>");
			
			//anhängendes Break entfernen
			while (s.lastIndexOf("<br/>") === s.length-5)
				s = s.substring(0, s.length-5);
			return s;
		},
		
		outputJsonAsHtml: function (json) {
			if (!json)
				return "";
			var o = json;
			var j = JSON.stringify(o, null, "<br/>");
			return j;
		},
		
		//nur Text beim Pasting!
		filterText: function (e) {
			e.preventDefault();
			var pasteText = "";
			
			if ((e.originalEvent || e).clipboardData) {
				pasteText = (e.originalEvent || e).clipboardData.getData('text/plain');
			}
			else if (window.clipboardData) {
				pasteText = window.clipboardData.getData('Text');
			}
			
			$(e.currentTarget).val(pasteText);
		},
		
		containsOnlyCharsOf: function (s, chars) {
			if (s)
				for(var i = 0; i < s.length; i++) {
					var c = s.charAt(i).toLowerCase();
					var index = chars.indexOf(c);
					if (index < 0)
						return false;
				}
			return true;
		}
    };

    //gemeinsam verfügbar machen
    if (typeof(pg) === 'undefined')
        window.pg = {};
    $.extend(pg, pgString);
}());
(function () {

    /*global sha512:true*/

    'use strict';

    var pgTools = {

        //get query string arg
        getUrlParameter: function (name) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(location.search);
            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        },
		removeUrlParameter: function (url, pattern) {
            url = pg.replace(url, "?" + pattern, "");
			url = pg.replace(url, "&" + pattern, "");
			return url;
        },

        removeFromArray: function (arr, obj) {
            if (!(arr))
                return;
            if (!(obj))
                return;

            for (var i = 0; i < arr.length; i++)
                if (arr[i] === obj) {
                    arr.splice(i, 1);
                    return;
                }
        },

        //-------------------------------------------------------------
        //http://www.quirksmode.org/js/cookies.html

        createCookie: function (name, value, days) {
            var domain = window.location.hostname;
			var pos = domain.indexOf(".");
			if (pos > 0)
				domain = domain.substr(pos+1);
			domain = "; domain=" + domain;
			
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime()+(days*24*60*60*1000));
				expires = "; expires="+date.toUTCString();
			}
			document.cookie = name + "=" + value + expires + domain + "; path=/";
        },

        readCookie: function (name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ')
                    c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0)
                    return c.substring(nameEQ.length, c.length);
            }
            return null;
        },

        eraseCookie: function (name) {
            this.createCookie(name, "", -1);
        },

        //---------------------------------------------------------------
        /*
         round(51,  20, 10) // 70
         round(70,  20, 10) // 70
         round(99,  20, 10) // 110
         round(100, 20, 10) // 110
         */
        /*function roundStep(number, increment, offset) {
         return Math.ceil((number - offset) / increment ) * increment + offset;
         }*/
        roundStep: function (number, increment) {
            return Math.ceil(number / increment) * increment;
        },

        //-----------------------------------------------------

        getIdIndex: function (arr, objId) {
            for (var i = 0; i < arr.length; i++)
                if (arr[i].id === objId)
                    return i;
            return -1;
        },
        replaceOrPushObj: function (arr, obj, fnMerge) {
            var index = this.getIdIndex(arr, obj.id);
            if (index < 0)
                arr.push(obj);
            else {
                if (fnMerge)
                    arr[index] = fnMerge(arr[index], obj);
                else
                    arr[index] = obj;
            }
        },


        //------------------------------------------------------

        isTouchDevice: function () {
            //noinspection JSUnresolvedVariable
            return (typeof(window.ontouchstart) !== 'undefined');
        },
        isIphone: function () {
            var m = navigator.userAgent.match(/iPhone/i);
            return (m === "iPhone");
        },
        isIpad: function () {
            var m = navigator.userAgent.match(/iPad/i);
            return (m === "iPad");
        },

        getFiletype: function (s) {
            var pos = s.lastIndexOf(".");
            return s.substring(pos + 1);
        },

        /*createPath: function(fs, path, callback) {
         var dirs = path.split("/").reverse();
         var root = fs.root;

         var createDir = function(dir) {
         if (dir.trim()!=="") {
         root.getDirectory(dir, {
         create: true,
         exclusive: false
         }, success, function(dir) {
         error("failed to create dir " + dir);
         });
         } else {
         callback();
         }
         };

         var success = function(entry) {
         root = entry;
         if (dirs.length > 0) {
         createDir(dirs.pop());
         } else {
         callback();
         }
         };

         createDir(dirs.pop());
         },*/

        isRunningOnDevice: function () {
            var is = false;
            var agent = navigator.userAgent.toLowerCase();
            var path = window.location.href;
            if ((path.indexOf("file://") !== -1) || (path.indexOf("localhost:8080") !== -1)) {
                if (agent.indexOf("android") !== -1) {
                    is = "android";
                } else if (agent.indexOf("iphone") !== -1 || agent.indexOf("ipad") !== -1 || agent.indexOf("ipod") !== -1) {
                    is = "ios";
                } else if (agent.indexOf("iemobile") !== -1) {
                    is = "wp";
                }
            }
            return is;
        },

        getAbbrevFileSize: function (bytes) {

            if (!$.isNumeric(bytes))
                return bytes;

            var unit = "B";
            if (bytes > 1024) {
                bytes /= 1024;
                unit = "KB";
                if (bytes > 1024) {
                    bytes /= 1024;
                    unit = "MB";
                    if (bytes > 1024) {
                        bytes /= 1024;
                        unit = "GB";
                    }
                }
            }

            //round
            bytes = pg.round(bytes, 1);
            //bytes = bytes.toFixed(1);

            return bytes + " " + unit;
        },

        round: function (value, exp) {
            if (typeof exp === 'undefined' || +exp === 0)
                return Math.round(value);

            value = +value;
            exp = +exp;

            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
                return NaN;

            // Shift
            value = value.toString().split('e');
            value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

            // Shift back
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
        },

        //
        toDataUrl: function (url, callback, outputFormat) {

            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function () {
                var canvas = document.createElement('CANVAS');

                var ctx = canvas.getContext('2d');
                canvas.height = this.height;
                canvas.width = this.width;
                ctx.drawImage(this, 0, 0);

                var dataURL = canvas.toDataURL();
                callback(dataURL);
                canvas = null;
            };
            img.src = url;
        },

        //---------------------------------------

        getSha512: function (s, salt) {
            if (sha512) {
				if (!salt)
					salt = "";
                var result = sha512(s + salt);
                return result;
            }
            return "";
        },

        getJson: function (data) {
            if (!data)
                return "";
            if ($.type(data) === "string") {
                var start = data.indexOf("{");
                var end = data.lastIndexOf("}");
                var result = data.substring(start, end + 1);
                var json = $.parseJSON(result);
                return json;
            }
            else
                return data;
            //return data;
        },

        //---------------------------------------

        //filter to unique elements
        getUnique: function (array) {
            return $.grep(array, function (el, index) {
                return index === $.inArray(el, array);
            });
        },

        //---------------------------------------

        scrollToDiv: function (trg, animated) {
            var timing = 200;
            if (!animated)
                timing = 0;

            setTimeout(function () {
				if ($(trg).length === 0)
					return;
                var trgScrollTop = $(trg).offset().top - 100;
                $("html, body").animate({scrollTop: trgScrollTop}, timing);
            }, timing);
        },


        isCordova: function () {
			//UiWebView
            var isCordovaApp = (document.URL.indexOf('http://') === -1) && (document.URL.indexOf('https://') === -1);
			//WkWebView
			if (!isCordovaApp)
				isCordovaApp = (document.URL.indexOf('localhost:8080') >= 0);
            return isCordovaApp;
        },
		
		isObjEqual: function (o1, o2) {
			var s1 = JSON.stringify(o1);
			var s2 = JSON.stringify(o2);
			return (s1 === s2);
		},
		
		hexc: function (colorval) {
			var parts = colorval.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
			delete(parts[0]);
			for(var i = 1; i <= 3; ++i) {
				parts[i] = parseInt(parts[i]).toString(16);
				if (parts[i].length === 1) parts[i] = '0' + parts[i];
			}
			return '#' + parts.join('');
		},
		
		//----------------------------------
		//Lodash replacement
		
		cloneDeep: function(obj){ 
			return JSON.parse(JSON.stringify(obj)); 
		}

    };

    if (typeof(pg) === 'undefined')
        window.pg = {};
    $.extend(pg, pgTools);
}());
(function () {

    'use strict';

    var pgValidation = {

		//Validierung: on-the-fly UND gesamtheitlich (btnEl-Click)
		//addValidation: function(formEl, btnEl, rules, callbackSuccess, callbackError, showIcons){
		addValidation: function(args){
			
			if (args === undefined)
				args = {};
			
			var formEl = args.formEl ? args.formEl : null,
				btnEl = args.btnEl ? args.btnEl : null,
				rules = args.rules ? args.rules : null,
				callbackSuccess = args.callbackSuccess ? args.callbackSuccess : null,
				callbackError = args.callbackError ? args.callbackError : null,
				showIcons = (args.showIcons !== undefined) ? args.showIcons : false;
			
			//alles prüfen, mit Callbacks
			if (btnEl){
				btnEl.click(function(){
					pg.checkValidation(rules, null, showIcons, function(result){
						if (callbackSuccess)
							callbackSuccess(result);
					}, function(){
						if (callbackError)
							callbackError();
					});
				});
			}
			
			//nur ein Element prüfen, keine Callbacks
			formEl.on("blur", function(){
				pg.checkValidation(rules, $(this), showIcons); 
			});
			
			//initial nichts markiert
			for (var i=0; i<rules.length; i++){
				var rule = rules[i];
				if (rule.formBoxEl){
					rule.formBoxEl.removeClass("has-feedback has-success has-error error");
					$(".glyphicon.glyphicon-ok.form-control-feedback", rule.formBoxEl).remove();
					$(".glyphicon.glyphicon-remove.form-control-feedback", rule.formBoxEl).remove();
				}
			}
			
			return {
				btnEl: btnEl,
				formEl: formEl
			};
		},
		
		removeValidation: function(validationSet){
			if (validationSet.btnEl)
				validationSet.btnEl.off("click");
			validationSet.formEl.off("blur");
		},
		
		//Validierung ausführen
		checkValidation: function(rules, formEl, showIcons, callbackSuccess, callbackError){
			
			var isSingleValidation = (formEl);
			
			//Parameter holen
			var result = {};
			
			//nur eine oder alle?
			var i,
				rule;
			if (isSingleValidation){
				for (i=0; i<rules.length; i++){
					rule = rules[i];
					if (formEl[0].id === rule.formEl[0].id) {
						rule.formBoxEl.removeClass("error");
						pg.getFormValue(result, rule.propertyName, rule.formEl, rule.formBoxEl, rule.fieldType, rule.maxLength, showIcons, rule.minLength);
						break;
					}
				}
			}
			else{
				for (i=0; i<rules.length; i++){
					rule = rules[i];
					pg.getFormValue(result, rule.propertyName, rule.formEl, rule.formBoxEl, rule.fieldType, rule.maxLength, showIcons, rule.minLength);
				}
			}
			
			if (result.hasErrors){
				if (callbackError)
					callbackError();
				return false;
			}
			if (callbackSuccess)
				callbackSuccess(result);
			return true;
		},
		
		getFormValue: function(trg, propertyName, formEl, formBoxEl, fieldType, maxLength, showIcons, minLength){
			
			var val = formEl.val();
			
			var iconSuccess = '<span class="glyphicon glyphicon-ok form-control-feedback" aria-hidden="true"></span>';
			var iconError = '<span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>';
			
			if (formBoxEl){
				formBoxEl.removeClass("error");
				$(".form-control-feedback", formBoxEl).remove();
			}
			var isValid = pg.validateFormField(formBoxEl, formEl, fieldType);
			if (!isValid){
				val = null;
				
				if (formEl.attr("required")){
					if (showIcons && formBoxEl)
						formBoxEl.append(iconError);
					trg.hasErrors = true;
				}
			}
			else {
				
				if (maxLength === undefined)
					maxLength = -1;
				if (minLength === undefined)
					minLength = -1;
				var isValid = true;
				if ((minLength > 0) && (val.length < minLength)){
					isValid = false;
				}
				if ((maxLength > 0) && (val.length > maxLength)){
					isValid = false;
				}
				if (isValid){
				
					if (showIcons && formBoxEl)
						formBoxEl.append(iconSuccess);
					
					switch (fieldType){
						case pg.fieldTypeStringShort:
						case pg.fieldTypeStringLong:
						case pg.fieldTypeStringEmail:
						case pg.fieldTypeStringPassword:
							val = pg.validateInput(val, maxLength || -1, minLength || -1);
							break;
						case pg.fieldTypeStringBoolean:
							//checkbox
							if (val === "on"){
								var valChecked = formEl.prop("checked");
								val = valChecked ? 1 : 0;
							}
					}
				}
				else{
					if (formEl.attr("required")){
						formBoxEl.removeClass("has-success").addClass("has-error error");
						if (showIcons && formBoxEl)
							formBoxEl.append(iconError);
						trg.hasErrors = true;
					}
				}
			}
			
			trg[propertyName] = val;
		},
		
		//----------------------------------------------------------------------------------------------------
	
        validateInput: function (val, max) {
            if (!val)
                return null;
            if (val.length < 1)
                return null;
			
            //var s = encodeURIComponent(val);
            var s = val;

            //remove script tags
            //s = s.replace(/<\/?[^>]+(>|$)/g, "");
            s = pg.replace(s, "<script>", "");

            //strip html tags
            var div = document.createElement("div");
            div.innerHTML = s;
            s = div.textContent || div.innerText || "";

            s = pg.replace(s, "\"", "'");
            s = pg.replace(s, "\\", "/");
            s = pg.replace(s, "%0A", "\\n");
            s = pg.replace(s, "%0D", "\\n");
            s = pg.replace(s, "%22", "'");
            s = pg.replace(s, "%09", " ");
            s = pg.replace(s, "%5C", "/");

            //control commands
            s = pg.replace(s, "%00", "");
            s = pg.replace(s, "%01", "");
            s = pg.replace(s, "%02", "");
            s = pg.replace(s, "%03", "");
            s = pg.replace(s, "%04", "");
            s = pg.replace(s, "%05", "");
            s = pg.replace(s, "%06", "");
            s = pg.replace(s, "%07", "");
            s = pg.replace(s, "%08", "");
            s = pg.replace(s, "%09", "");
            s = pg.replace(s, "%0B", "");
            s = pg.replace(s, "%0C", "");
            s = pg.replace(s, "%0E", "");
            s = pg.replace(s, "%0F", "");
            s = pg.replace(s, "%10", "");
            s = pg.replace(s, "%11", "");
            s = pg.replace(s, "%12", "");
            s = pg.replace(s, "%13", "");
            s = pg.replace(s, "%14", "");
            s = pg.replace(s, "%15", "");

            var len = s.length || max;
            return s.substring(0, len);
        },

        //certain words are filtered by the API for security reasons
        //put them back into place for the client side (where they won't do harm)
        restoreApiFiltering: function (s) {
            if (!s)
                return s;

            s = pg.replace(s, " u-pdate ", " update ");
            s = pg.replace(s, " i-nsert ", " insert ");
            s = pg.replace(s, " a-lter ", " alter ");
            s = pg.replace(s, " d-rop ", " drop ");
            s = pg.replace(s, " d-elete ", " delete ");
            s = pg.replace(s, " s-elect ", " select ");

            return s;
        },
		
		//combine multiple steps to restore string content after DB retrieval
		restoreDbString: function(s){
			return pg.filterNullString(pg.unescapeHtml(pg.restoreApiFiltering(s)));
		},

        filterPasteText: function (e) {

            e.preventDefault();
            var pasteText = "";

            if ((e.originalEvent || e).clipboardData) {
                pasteText = (e.originalEvent || e).clipboardData.getData('text/plain');
            }
            else if (window.clipboardData) {
                pasteText = window.clipboardData.getData('Text');
            }
	
            //existing text
            var trg = $(e.currentTarget);
	        var cursorPosStart = trg.prop('selectionStart');
	        var cursorPosEnd = trg.prop('selectionEnd');
	        var v = trg.val();
	        var textBefore = v.substring(0,  cursorPosStart);
	        var textAfter  = v.substring(cursorPosEnd, v.length);
	
	        //insert
	        trg.val(textBefore + pasteText + textAfter);
            
            //allow model update
            trg.trigger('input');

        },

        validateEmail: function (email) {

            if (email === null)
                return false;
            if (email === "")
                return false;
            if ((email.indexOf("@") < 0) || (email.indexOf(".") < 0) || (email.length < 7))
                return false;

            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
		
		validatePassword: function(s){
			if (s === null)
                return false;
            if (s === "")
                return false;
			
			var re = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,40})/;
			/*(           # Start of group
			  (?=.*\d)      #   must contains one digit from 0-9
			  (?=.*[a-z])       #   must contains one lowercase characters
			  (?=.*[A-Z])       #   must contains one uppercase characters
			  (?=.*[@#$%])      #   must contains one special symbols in the list "@#$%"
						  .     #     match anything with previous condition checking
							{8,40}  #        length at least 8 characters and maximum of 40 
			) */ 
			return re.test(s);
		}
    };

    //gemeinsam verfügbar machen
    if (typeof(pg) === 'undefined')
        window.pg = {};
    $.extend(pg, pgValidation);
}());