(function () {

		/*global UserGroup:true*/

		//----------------------------------------------------------------
		//Zustand eines Auftrages
		//----------------------------------------------------------------

		'use strict';

		var TaskState = {

			taskStatePrototype: {

				fromObj: function (s) {

					this.id = parseInt(s.id, 10);
					this.createdOn = pg.parseDate(s.createdOn);
					this.createdBy = parseInt(s.createdBy, 10);
					this.changedOn = pg.parseDate(s.changedOn);
					this.changedBy = parseInt(s.changedBy, 10);

					if (s.clientId)
						this.clientId = parseInt(s.clientId, 10);
					else
						this.clientId = model.curClientId;
					this.status = parseInt(s.status, 10);
					this.taskId = parseInt(s.taskId, 10);
					this.taskStateId = parseInt(s.taskStateId, 10);
					//legacy support
					if (s.userGroupIds)
						this.userGroupIds = s.userGroupIds;
					else
						this.userGroupIds = s.userGroupId + "#";
					this.version = parseInt(s.version, 10);
					this.responsibleId = parseInt(s.responsibleId, 10);

					this.labelId = null;
					if (s.labelId)
						this.labelId = parseInt(s.labelId);
					this.comment = s.comment || null;

					//aktuell bekannter Bearbeitungs-Status des Auftrags (für TaskLabels in Historie)
					this.taskStatus = null;
					if ((s.taskStatus !== null) && (s.taskStatus !== undefined))
						this.taskStatus = parseInt(s.taskStatus);

					//Eingruppierung (Archiv etc.)
					this.groupId = parseInt(s.groupId || 0);

					return this;
				},

				serialize: function () {

					var o = {};

					o.id = this.id;
					o.createdOn = pg.buildDate(this.createdOn);
					o.createdBy = this.createdBy;
					o.changedOn = pg.buildDate(this.changedOn);
					o.changedBy = this.changedBy;

					o.clientId = this.clientId;
					o.status = this.status;
					o.taskId = this.taskId;
					o.taskStateId = this.taskStateId;
					o.userGroupIds = this.userGroupIds;
					o.version = this.version;
					o.responsibleId = this.responsibleId;

					o.labelId = this.labelId;
					o.comment = this.comment;
					o.taskStatus = this.taskStatus;

					o.groupId = this.groupId;

					return o;
				},

				serializeCompact: function(){
					var o = {};

					o.id = this.id;
					o.status = this.status;
					o.taskId = this.taskId;
					o.taskStateId = this.taskStateId;
					o.version = this.version;
					o.taskStatus = this.taskStatus;
					o.groupId = this.groupId;

					return o;
				},

				//ist aktuell (u.a.) einem User zugeordnet?
				isAssignedToUser: function (userId) {
					userId = parseInt(userId);
					var users = this.getAssignedUsers();
					return _.some(users, function (u) {
						return u.id === userId;
					});
				},
				//dto für Group
				isAssignedToUserGroup: function(userGroupId){
					var isAssigned = false;
					$.each(this.getUserGroups(), function(index, ug){
						if (ug.id === userGroupId){
							isAssigned = true;
						}
					});
					return isAssigned;
				},

				//alle zugeordneten User-IDs holen
				getAssignedUsers: function () {

					var userGroups = this.getUserGroups();
					var userIds = [];
					for (var k = 0; k < userGroups.length; k++) {
						var ug = userGroups[k];
						userIds = userIds.concat(ug.getUsers());
					}

					return userIds;
				},

				//zugeordnete Gruppen liefern
				getUserGroups: function(){

					//force string
					this.userGroupIds = ""+this.userGroupIds;

					var allUserGroups = this.userGroupIds.split("#");
					var userGroups = [];
					for (var k = 0; k < allUserGroups.length; k++) {
						if (allUserGroups[k] === '')
							continue;
						var ug = UserGroup.getUserGroup(allUserGroups[k]);
						userGroups.push(ug);
					}

					return userGroups;
				},

				//zugeordnete Gruppen-Namen liefern
				getUserGroupNames: function(shortVersion){

					if (shortVersion === undefined)
						shortVersion = false;

					var ugName = "";
					$.each(this.getUserGroups(), function(index, ug){
						if (index > 0)
							ugName += "<br/>";
						ugName += '<span class="text-nowrap">' + ug.getName(shortVersion) + '</span>';
					});
					return ugName;
				},

				//genau einer UG zuordnen
				assignToUserGroup: function(userGroupId){
					this.userGroupIds = userGroupId + "#";
				},

				assignToUserGroups: function(userGroupIds){
					this.userGroupIds = _.join(userGroupIds, "#");
				},

				isAssignedToSingleUser: function(){
					var ug = this.getUserGroups();
					if (ug.length === 1)
						return (ug[0].isSingleUser === 1);
					return false;
				}
			},

			createTaskState: function () {
				return Object.create(TaskState.taskStatePrototype);
			}
		};

		window.TaskState = TaskState;
	}()
);