(function () {
	
	//----------------------------------------------------------------
	//Offline-Cache
	//----------------------------------------------------------------
	
    'use strict';

    var OfflineCache = {
    
        offlineCachePrototype: {

            type: 0,
            id: 0,
            data: null,

            fromObj: function (t) {
                this.dataSource = parseInt(t.dataSource);
                this.clientId = parseInt(t.clientId);
                this.userId = parseInt(t.userId);
                this.data = t.data;
                return this;
            }
        },

        createOfflineCache: function (dataSource, clientId, userId, data) {
            return $.extend(Object.create(OfflineCache.offlineCachePrototype), {
                dataSource: dataSource,
                clientId: clientId,
                userId: userId,
                data: data
            });
        }
    };

    window.OfflineCache = OfflineCache;
}());
