(function () {
	
	//-------------------------------------------------------------
	//Liste der Aufträge verwalten
	//-------------------------------------------------------------
	
	/*global
	constants:true,
	Attachment:true,
	dataManager:true,
	Task:true
	*/
	
	'use strict';
	
	var taskServiceManager = {

		//ein Protokoll absenden
		sendProtocol: function(task, p, sendCompleteCallback){
			
			var that = this;
			//attachments korrekt benennen
			if (p.attObj.length) {
				for(var i = 0; i < p.attObj.length; i++) {
					var att = p.attObj[i];
					var ext = pg.getFiletype(att.url);
					var newName = p.taskId + "_" + p.formId + "_" + p.locationId + "_" + att.id + "." + ext;
					att.url = "data/" + model.curClientId + "/masters/" + newName;
				}
				//p.attObj = attachments;
				p.attachments = Attachment.serializeAttachments(p.attObj);
			}

			//store
			dataManager.saveProtocol(task, p, function success(pNew) {
			
				if (pNew) {
					//Daten übernehmen (aber Attachments retten)
					if (p !== pNew) {
						var a = p.attObj;
						p = p.fromObj(pNew);
						p.attObj = a;
					}
				}

				task.updateProtocolCount();
				task.updateStatus();
				pg.replaceOrPushObj(model.tasks, task, Task.mergeTasks);
				
				if (sendCompleteCallback)
					sendCompleteCallback(p);
				
			});
			
		},
		
		//Protokoll (auch Attachments) wurden komplett übertragen
		finalizeSendProtocol: function(task, p, sendCompleteCallback){
			
			//store
			dataManager.setProtocolTransmitted(p, function (protocol) {
				
				if (protocol) {
					//Daten übernehmen (aber Protokolldaten erhalten)
					var pData = p.protocolData;
					var incData = p.incidents;
					
					var pNew = p.fromObj(protocol);
					pNew.protocolData = pData;
					pNew.incidents = incData;
					
					if (sendCompleteCallback)
						sendCompleteCallback(pNew);
				}
				else{
					
					//wie soll das hier möglich sein?
					console.log("Das hier sollte nie angezeigt werden.");
					if (sendCompleteCallback)
						sendCompleteCallback(p);
				}
				
			}, function error(){
				if (sendCompleteCallback)
					sendCompleteCallback();
			});
		}
	};
	
	window.taskServiceManager = taskServiceManager;
}());
