(function () {
	
	//----------------------------------------------------------------
	//Vorkommnis
	//----------------------------------------------------------------
	
	/*global
	Signal:true,
	ProtocolForm:true,
	Location:true
	*/
	
	'use strict';
	
	var Incident = {
		
		INCIDENT_NONE: 0,
		INCIDENT_DEFECT: 1,
		INCIDENT_OBSERVATION: 2,

		//zwecks Filterung
		INCIDENT_TYPE_ANY: 0,
		INCIDENT_TYPE_DEFECT: 1,
		INCIDENT_TYPE_OBSERVATION: 2,
		INCIDENT_TYPE_UNFIXED_ANY: 3,
		INCIDENT_TYPE_UNFIXED_DEFECT: 4,
		INCIDENT_TYPE_UNFIXED_OBSERVATION: 5,
		INCIDENT_TYPE_FIXED_ANY: 6,
		INCIDENT_TYPE_FIXED_DEFECT: 7,
		INCIDENT_TYPE_FIXED_OBSERVATION: 8,

		
		incidentPrototype: {
			
			fromObj: function (t) {
				
				this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				if (t.clientId)
					this.clientId = parseInt(t.clientId, 10);
				else
					this.clientId = model.curClientId;

				this.userId = parseInt(t.userId, 10);
				this.taskId = parseInt(t.taskId, 10);
				this.locationId = parseInt(t.locationId, 10);
				this.protocolId = parseInt(t.protocolId, 10);
				this.formId = parseInt(t.formId, 10);
				this.fieldId = parseInt(t.fieldId, 10);
				this.description = pg.restoreDbString(t.description);
				this.fieldValue = t.fieldValue;
				this.type = parseInt(t.type, 10);
				
				this.signals = [];
				if (t.signals)
					for (var i = 0; i < t.signals.length; i++) {
						var s = Signal.createSignalRaw();
						s.fromObj(t.signals[i]);
						this.addSignal(s);
					}
				
				return this;
			},
			
			//----------------------------------------------------------------
			
			addSignal: function (o) {
				
				if (this.signals === undefined)
					this.signals = [];
				
				//shallow copy!
				var a = [];
				for (var i = 0; i < this.signals.length; i++) {
					if (o.id > 0)
						if (this.signals[i].id === o.id)
							continue;
					a.push($.extend({}, this.signals[i]));
				}
				a.push(o);
				this.signals = a;
			},

			hasSignal: function(type, value){
				if (this.signals) {
					for (var i = 0; i < this.signals.length; i++) {
						var sig = this.signals[i];

						if (!sig.isNewestSignal(this.signals, i))
							continue;

						if (sig.type === type) {
							//Wert wichtig?
							//nein
							if (value === undefined)
								return true;
							//doch!
							if (sig.value === value)
								return true;
						}
					}
				}

				return false;
			},
			hasOnlySignalsOfType: function(type, value){
				if (this.signals) {

					var sig;

					//mind. eines vorhanden
					var hasSignal = (this.signals.length > 0);
					if (!hasSignal){
						sig = Signal.createSignalRaw();
						sig.id = -1;
						sig.type = Signal.SIGNAL_DEFECT;
						sig.value = Signal.VALUE_OK_DEFAULT;
						if (sig.type === type) {
							//Wert wichtig?
							if (value !== undefined)
								if (sig.value !== value)
									return false;

							//ansonsten ok
							return true;
						}
					}

					//alle ok, d.h. keines nicht passend?
					var hasSignalType = false;
					for (var i = 0; i < this.signals.length; i++) {
						sig = this.signals[i];

						if (!sig.isNewestSignal(this.signals, i))
							continue;

						if (sig.type === type) {

							hasSignalType = true;

							//Wert wichtig?
							if (value !== undefined)
								if (sig.value !== value)
									return false;

							//ansonsten weiterhin ok
						}
					}

					if (!hasSignalType)
						return false;

					return true;
				}

				return false;
			},

			//----------------------------------------------------------------
			
			getType: function () {
				switch (this.type) {
					case Incident.INCIDENT_DEFECT:
						return "Mangel";
					case Incident.INCIDENT_OBSERVATION:
						return "Feststellung";
				}
				return "";
			},
			
			//----------------------------------------------------------------
			
			serialize: function () {
				
				var o = {};
				
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.clientId = this.clientId;
				o.userId = this.userId;
				o.taskId = this.taskId;
				o.locationId = this.locationId;
				o.protocolId = this.protocolId;
				o.formId = this.formId;
				o.fieldId = this.fieldId;
				o.description = this.description;
				o.fieldValue = this.fieldValue;
				o.type = this.type;
				
				o.signals = [];
				if (this.signals)
					for (var i = 0; i < this.signals.length; i++)
						o.signals.push(this.signals[i].serialize());
				
				return o;
			},

			//--------------------------------------------------------------

			//----------------------------------------------

			//Filter anwenden
			matchesFilter: function (filterObj) {

				/*jshint -W089 */
				for(var field in filterObj) {

					var val = filterObj[field],
						isValid,
						i,
						j,
						arr,
						loc,
						d,
						dd,
						now,
						locResult,
						sig,
						hasSignal,
						signalCount,
						l,
						lv,
						form,
						valInt;

					if ((val === undefined) || (val === null))
						continue;

					switch (field) {

						case "name":
							form = ProtocolForm.getProtocolForm(this.formId);
							var formField = form.getFormField(this.fieldId);
							if (!formField.name)
								return false;
							if (formField.name.toLowerCase().indexOf(val.toLowerCase()) < 0)
								return false;
							break;

						case "fieldValue":
							if (!this.fieldValue)
								return false;
							if (this.fieldValue.toLowerCase().indexOf(val.toLowerCase()) < 0)
								return false;
							break;

						case "type":
							valInt = +val;
							if (valInt < 0)
								continue;
							if (this.type !== valInt)
								return false;
							break;

						case "description":
							if (!this.description)
								return false;
							if (this.description.toLowerCase().indexOf(val.toLowerCase()) < 0)
								return false;
							break;

						case "dateFrom":
							arr = val.split(".");
							if (arr.length === 3)
								if (parseInt(arr[2]) < 100)
									arr[2] = 2000 + parseInt(arr[2]);
							d = new Date(arr[2], arr[1] - 1, arr[0]);
							//schon vorher?
							dd = this.changedOn;
							if (dd) {
								//nur ohne Fälligkeit
								if (val === "-")
									return false;
								if (dd < d)
									return false;
							}
							else {
								//ohne Fälligkeit bei Filterung ausblenden (es sei denn, genau das wäre gewünscht -> "-")
								if (val !== "-")
									return false;
							}
							break;

						case "dateTo":
							arr = val.split(".");
							if (arr.length === 3)
								if (parseInt(arr[2]) < 100)
									arr[2] = 2000 + parseInt(arr[2]);
							d = new Date(arr[2], arr[1] - 1, arr[0], 23, 59, 59);
							//erst danach?
							dd = this.changedOn;
							if (dd) {
								//nur ohne Fälligkeit
								if (val === "-")
									return false;
								if (d < dd)
									return false;
							}
							else {
								//ohne Fälligkeit bei Filterung ausblenden (es sei denn, genau das wäre gewünscht -> "-")
								if (val !== "-")
									return false;
							}
							break;

						case "locationType":
							//multiple!
							if ($.isArray(val)) {
								isValid = false;
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									switch (valInt) {
										case -1:
											//alle
											isValid = true;
											break;
										default:
											loc = Location.getLocation(this.locationId, undefined, true, true, true);
											if ((loc) && (loc.type === valInt))
												isValid = true;
											break;
									}
									if (isValid)
										break;
								}

								if (!isValid)
									return false;
							}
							else {
								valInt = parseInt(val);
								if (valInt < 0)
									continue;
								loc = Location.getLocation(this.locationId, undefined, false, false);
								if (loc) {
									if (loc.type !== valInt)
										return false;
								} else {
									return false;
								}
							}
							break;
						case "locationId":
							if ($.isArray(val)) {
								isValid = false;

								//id, nicht name!
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									if (valInt < 0) {
										isValid = true;
										break;
									}

									if (this.locationId === valInt){
										isValid = true;
										break;
									}
								}

								if (!isValid)
									return false;
							}
							break;

						case "protocolId":
							var tId = "" + this.protocolId;
							if (tId.indexOf(val) < 0)
								return false;
							break;

						case "protocolFormId":
							valInt = +val;
							if (valInt < 0)
								continue;
							form = ProtocolForm.getProtocolForm(this.formId);
							if (form.id !== valInt)
								return false;
							break;

						case "signalDefect":
							valInt = +val;
							if (valInt < 0)
								continue;

							sig = null;
							hasSignal = false;
							signalCount = 0;
							for (j = 0; j < this.signals.length; j++) {
								sig = this.signals[j];

								if (sig.type === Signal.SIGNAL_DEFECT) {

									if (!sig.isNewestSignal(this.signals, j))
										continue;
									signalCount++;

									if (sig.value === valInt) {
										hasSignal = true;
										break;
									}
								}
							}
							if (signalCount === 0){
								if (valInt === Signal.VALUE_OK_DEFAULT)
									hasSignal = true;
							}

							if (!hasSignal) {
								return false;
							}
							break;

						case "signalNotification":
							valInt = +val;
							if (valInt < 0)
								continue;

							sig = null;
							hasSignal = false;
							signalCount = 0;
							for (j = 0; j < this.signals.length; j++) {
								sig = this.signals[j];

								if (sig.type === Signal.SIGNAL_NOTIFY) {

									if (!sig.isNewestSignal(this.signals, j))
										continue;
									signalCount++;

									if (sig.value === valInt) {
										hasSignal = true;
										break;
									}
								}
							}
							if (signalCount === 0){
								if (valInt === Signal.VALUE_OK_DEFAULT)
									hasSignal = true;
							}
							if (!hasSignal) {
								return false;
							}
							break;
					}
				}

				return true;
			},

			//----------------------------------------------

			//Sortierung anwenden
			updateSortValue: function (sortField, sortDirection) {

				if (sortDirection === constants.SORTING_NONE) {
					this.sortVal = 0;
					return;
				}

				this.sortVal = "";
				var form,
					sig,
					j,
					hasSignal;
				switch (sortField) {

					case "name":
						form = ProtocolForm.getProtocolForm(this.formId);
						var formField = form.getFormField(this.fieldId);
						this.sortVal = formField.name || "";
						break;
					case "fieldValue":
						this.sortVal = this.fieldValue.toLowerCase();
						break;
					case "type":
						this.sortVal = this.type;
						break;
					case "description":
						this.sortVal = this.description;
						break;
					case "date":
						this.sortVal = this.changedOn || "";
						break;
					case "object":
						var loc = Location.getLocation(this.locationId, undefined, false, false);
						if (loc)
							if (loc.isAbstract !== 1)
								this.sortVal = loc.getName(true);
						break;
					case "protocolId":
						this.sortVal = this.protocolId;
						break;
					case "protocolName":
						form = ProtocolForm.getProtocolForm(this.formId);
						this.sortVal = form.name;
						break;

					case "status":
						sig = null;
						hasSignal = false;
						for(j = 0; j < this.signals.length; j++) {
							sig = this.signals[j];

							if (!sig.isNewestSignal(this.signals, j))
								continue;

							if (sig.type === Signal.SIGNAL_DEFECT) {
								hasSignal = true;
								break;
							}
						}
						if (hasSignal)
							this.sortVal = sig.value;
						break;

					case "notification":
						sig = null;
						hasSignal = false;
						for(j = 0; j < this.signals.length; j++) {
							sig = this.signals[j];

							if (!sig.isNewestSignal(this.signals, j))
								continue;

							if (sig.type === Signal.SIGNAL_NOTIFY) {
								hasSignal = true;
								break;
							}
						}
						if (hasSignal)
							this.sortVal = sig.value;
						break;

				}
			},
		},

		//-------------------------------------------------------------------------------------

		//alle Vorkommnisse holen
		getIncidentsOf: function(tasks){
			var a = [];

			for (var i=0; i<tasks.length; i++) {
				var b = tasks[i].getIncidents();
				for (var j=0; j<b.length; j++) {
					a.push(b[j]);
				}
			}

			return a;
		},

		//-------------------------------------------------------------------------------------

		createIncident: function(clientId, userId, taskId, locationId, protocolId, formId, fieldId, type, description, fieldValue) {
			var o = Object.create(Incident.incidentPrototype);
			
			o.id = -1;
			o.clientId = clientId;
			o.userId = userId;
			o.taskId = taskId;
			o.locationId = locationId;
			o.protocolId = protocolId;
			o.formId = formId;
			o.fieldId = fieldId;
			o.type = type;
			o.description = description;
			o.fieldValue = fieldValue;

			o.createdOn = new Date();
			o.createdBy = -1;
			o.changedOn = new Date();
			o.changedBy = -1;
			
			return o;
		},
		createIncidentRaw: function () {
			return Object.create(Incident.incidentPrototype);
		}
		
	};
	
	window.Incident = Incident;
}());
