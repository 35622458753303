(function () {
	
	//------------------------------------------------------------------------
	//Authentifierung und Sicherheit
	//------------------------------------------------------------------------
	
	/*global
	appManager:true,
	Q: true,
	viewManager:true,
	localStorageManager:true,
	dataManager:true,
	UserRole:true,
	User:true,
	taskListManager:true,
	requestListManager:true,
	Account:true*/
	
	'use strict';
	
	var authManager = {
		
		//Abmelden
		logout: function (trigger, callback) {
			
			if (trigger === undefined)
				trigger = constants.LOGOUT_MANUALLY;
			
			dataManager.logout(function(){

				if (trigger === constants.LOGOUT_CHANGE_ACCOUNT) {
					if (callback)
						callback();
				}
				else{
					model.resetLogin(true);
					model.reset();
					authManager.reset();
					appManager.stopAutoDataRefresh();

                    $("#pageBox").hide();
                    $("#pageHeader").hide();
					$("#navBarBox").hide();
                    $("#pageFooter").hide();

                    appManager.initUpkeepr();

					var newHref = viewManager.originalHref;
					if (newHref.indexOf("?") < 0)
						newHref += "?action=" + trigger;
					else
						newHref += "&action=" + trigger;
					window.location.href = newHref;
				}
				
			});
		},
		
		//Reset der Zugriffstokens etc.
		reset: function () {
			this.stopSessionTracking();
			this.storeLoginData(null);
		},
		
		//low-level Access
		storeLoginData: function (o) {
			localStorageManager.setObject("upkeepr_login", o);
		},
		loadLoginData: function () {
			return localStorageManager.getObject("upkeepr_login");
		},
		
		//-------------------------------------------------------------------------------------

		hideDialogs: function(){
			$("#loginDialog").hide();
			$("#passwordForgottenDialog").hide();
			$('#passwordResetDialog').hide();
			$('#acceptInvitationDialog').hide();
			$('#tokenInvalidDialog').hide();
		},

		//-------------------------------------------------------------------------------------

		//Login-Dialog zeigen
		showLogin: function () {
			
			viewManager.updateButtons();
			
			$('#formLogin .alert').hide();
			$("#formLogin .has-error").removeClass("has-error");

			this.hideDialogs();
			$("#loginDialog").show();
		},
		
		//Credentials prüfen
		verifyCredentials: function () {
			
			$('#formLogin .alert').hide();
			$("#formLogin .has-error").removeClass("has-error");
			
			var that = this;
			
			that.curEmail = $("#loginEmail").val();
			var password = $("#loginPassword").val();
			
			if ((that.curEmail.length === 0) || (password.length === 0)) {
				$('#loginEmailBox').addClass("has-error");
				$('#loginPasswordBox').addClass("has-error");
				$('#formLogin .errMsg').html("Bitte E-Mail und Passwort eingeben.").show();
				viewManager.updateButtons();
				return false;
			}
			
			if (!pg.validateEmail(that.curEmail)) {
				$('#loginEmailBox').addClass("has-error");
				$('#formLogin .errMsg').html("Bitte geben Sie eine g&uuml;ltige E-Mail ein.").show();
				viewManager.updateButtons();
				return false;
			}
			
			//blocking
			if (!viewManager.setButtonBlocking($("#btnLogin")))
				return false;
			
			//special: ClientId in Link mit übergeben?
			var argClientId = pg.getUrlParameter("clientId");
			var clientId = argClientId ? parseInt(argClientId) : -1;

			//Parameter entfernen
			if (argClientId) {
				var url = pg.removeUrlParameter(window.location.href, "clientId=" + argClientId);
				viewManager.originalHref = pg.removeUrlParameter(viewManager.originalHref, "clientId=" + argClientId);
				window.history.replaceState(window.history.state, "upkeepr", url);
			}

			//anmelden
			that.curPassword = pg.getSha512(password, constants.CRYPT_SEED);
			dataManager.verifyCredentials(that.curEmail, that.curPassword, clientId, this.onVerificationComplete);
		},
		
		onVerificationComplete: function (loginResult, callback) {
			
			var that = authManager;
			
			var success = 0;
			if (loginResult)
				success = loginResult.success;

			//store!
			if (success === 1) {
				
				//direct login
				if (loginResult.loginId) {
					
					that.storeLoginData({
						loginId: loginResult.loginId,
						loginToken: loginResult.loginToken,
						email: loginResult.email,
						password: loginResult.password,
						clientId: model.curClientId
					});
					
					appManager.initView(callback);
				}
				else {
					
					//Kunden auswählen
					that.showAccountSelection(loginResult.firstName, loginResult.lastName);
				}
			}
			else {
				viewManager.updateButtons();
				$(".btnSelectAccount").removeClass("isSelected");
				$(".btnSelectAccount").removeClass("selected");

				if (loginResult) {
					if (loginResult.error || loginResult.errorId>0) {
						
						if (loginResult.errorId <= 0){
							loginResult.errorId = constants.ERROR_UNKNOWN;
						}
						
						switch (loginResult.errorId){
							
							//Trial abgelaufen
							case constants.ERROR_LOGIN_TRIAL_EXPIRED:
								authManager.showTrialExpired(loginResult.clientName, loginResult.clientOwner);
								break;

							case constants.ERROR_UNKNOWN:
								that.showLogin();
								appManager.hidePreloader();
								$('#formLogin .errMsg').html("Ein unbekannter Fehler ist aufgetreten.").show();
								break;
								
							case constants.ERROR_LOGIN_INVALID_PASSWORD:
							default:
								that.showLogin();
								appManager.hidePreloader();
								$('#formLogin .errMsg').html("Zu diesen Eingaben konnte kein Benutzer identifiziert werden.").show();
								break;
								
						}
						
						appManager.hidePreloader();
					}
					else {
						that.showLogin();
						appManager.hidePreloader();
						$('#formLogin .errMsg').html("Zu diesen Eingaben konnte kein Benutzer identifiziert werden.").show();
					}
				}
				else{
					pg.showMsg("Es gab ein Problem während der Server-Kommunikation. Bitte versuchen Sie es später noch einmal.");
				}
			}
			
			viewManager.updateAccountDisplay();
		},
		
		//-------------------------------------------------------------------------------
		
		//TrialExpired-Dialog zeigen
		showTrialExpired: function (clientName, clientOwner) {
			
			viewManager.updateButtons();

			//Mailto-Link anpassen
			var mailtoTemplate = $("#trialExpiredDialog a").attr("href");
			mailtoTemplate = pg.replace(mailtoTemplate, "client.name", clientName);
			mailtoTemplate = pg.replace(mailtoTemplate, "user.firstName", clientOwner.firstName || "");
			mailtoTemplate = pg.replace(mailtoTemplate, "user.lastName", clientOwner.lastName || "");
			mailtoTemplate = pg.replace(mailtoTemplate, "user.fon", clientOwner.fon || "");
			$("#trialExpiredDialog a").attr("href", mailtoTemplate);

			this.hideDialogs();
			$("#trialExpiredDialog").show();
		},

		//-----------------------------------------------------------------------------
		// Benutzer muss sein aktuelles Passwort eingeben

		verifyPassword: function(title, msg){

			var def = Q.defer();

			pg.promptMsg(title, msg, function(result){

				if (result){

					//Abfrage durchführen
					var passwordHash = pg.getSha512(result, constants.CRYPT_SEED);
					var email = model.curUser.email;

					//don't reset session (Argument "false" unten)
					dataManager.verifyCredentials(email, passwordHash, model.curClientId, function(loginResult){

						var success = 0;
						if (loginResult)
							success = loginResult.success;

						//store!
						if (success === 1) {
							def.resolve();
						}
						else{
							def.reject(true);
						}

					}, false);

				}
				else{
					def.reject(false);
				}

			}, "", "password");

			return def.promise;
		},

		//-----------------------------------------------------------------------------
		
		//automatisch wechseln
		switchAccount: function(clientId, callback){

			var that = this;

			var o = this.loadLoginData();
			if (!o)
				return;
			
			model.resetLogin();
			model.reset();
			dataManager.verifyCredentials(o.email, o.password, clientId, function(loginResult){
				that.onVerificationComplete(loginResult, callback);
			});
		},
		
		//manuell auswählen
		changeAccount: function(){

			var that = this;

			var o = this.loadLoginData();
			if (!o) {
				authManager.logout(constants.LOGOUT_SESSION_EXPIRED);
				return;
			}

			authManager.logout(constants.LOGOUT_CHANGE_ACCOUNT, function(){

				taskListManager.resetFilters(false);
				requestListManager.resetFilters(false);

				model.resetLogin();
				model.reset();
				that.curEmail = o.email;
				that.curPassword = o.password;
				that.showAccountSelection(model.curUser.firstName, model.curUser.lastName);

			});
		},
		
		//Kunden auswählen
		showAccountSelection: function(firstName, lastName){

			//Auswahl des Kunden
			var dialog = $("#accountSelectionDialog");
			var initials = (firstName.substring(0, 1) + lastName.substring(0, 1)).toUpperCase();
			$(".initialsCircle", dialog).html(initials);
			$(".userName", dialog).html(firstName + " " + lastName);
			//Kundenliste
			
			//sortieren nach Name (ignore case)
			model.accounts.sort(function (a, b) {
				return ((a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0));
			});

			var s = "";
			for (var i=0; i<model.accounts.length; i++){
				var c = model.accounts[i];
				var latestActivity = (c.latestActivity) ? pg.formatDate(c.latestActivity, true) : '-';
				var clientType = c.type || constants.CLIENT_TYPE_CUSTOMER;
				//s += "<a href='javascript:authManager.selectAccount(" + c.id + ")' data-id='" + c.id + "' class='list-group-item btnHasSpinner ladda-button btnSelectAccount' data-style='zoom-in'>" + c.name.toUpperCase() + "</a>";
				s += "<a href='javascript:authManager.selectAccount(" + c.id + ")' data-id='" + c.id + "' class='list-group-item btnHasSpinner ladda-button btnSelectAccount' data-style='zoom-in'>";
				s += '<span class="ladda-label" data-style="zoom-in"><div class="row"><div class="col-sm-8">' + c.name.toUpperCase() + '<br><small class="text-muted font-weight-normal" style="font-weight: normal;">Letzte Aktivität ' + latestActivity + '</small></div><div class="col-sm-4 text-right">';
				switch (clientType) {
					case constants.CLIENT_TYPE_CUSTOMER:
						s += '<span class="badge badge-success">Kunde</span>';
						break;
					case constants.CLIENT_TYPE_TESTING:
						s += '<span class="badge badge-default">Testing</span>';
						break;
					case constants.CLIENT_TYPE_TRIAL:
						s += '<span class="badge badge-warning">Testkunde</span>';
						break;
					case constants.CLIENT_TYPE_TEMPLATE:
						s += '<span class="badge badge-danger">Demodaten</span>';
						break;
					case constants.CLIENT_TYPE_DEMO:
						s += '<span class="badge badge-primary">Demo</span>';
						break;
				}
				s += '</div></div></span>';
				s += "</a>";

			}
			$(".accountSelectionBox", dialog).html(s);

            $("#pageBox").hide();
            $("#pageHeader").hide();
            $("#navBarBox").hide();
            $("#pageFooter").hide();

			appManager.stopAutoDataRefresh();
			this.stopSessionTracking();
			
			//umschalten
			this.hideDialogs();
			appManager.hidePreloader();
			dialog.show();

			viewManager.updateButtons();
		},
		
		selectAccount: function(clientId){
			var el = $(".btnSelectAccount[data-id='" + clientId + "']");

			//blocking?
			var isSelectingAccount = ($(".btnSelectAccount.isSelected").length > 0);
			if (isSelectingAccount) {
				//viewManager.setButtonBlocking(el, false);
				viewManager.updateButtons();
				return;
			}
			el.addClass("isSelected");
			
			$(".accountSelectionBox .list-group-item[data-id='" + clientId + "']").addClass("selected");
			dataManager.verifyCredentials(this.curEmail, this.curPassword, clientId, this.onVerificationComplete);
		},
		
		cancelAccountSelection: function(){
			this.logout();
		},
		
		//Token noch gültig?
		//via localStorage
		checkValidToken: function (forceClientId, callback) {
			
			var that = this;
			
			var o = that.loadLoginData();
			if (!o) {
				callback({
					success: 0
				});
				return;
			}

			//bestimmer Kunde gewünscht?
			if (forceClientId) {
				forceClientId = parseInt(forceClientId);

				if (!o.clientId)
					o.clientId = -1;
				if (o.clientId === forceClientId){
					forceClientId = null;
				}
			}

			//alles ok, mit Token anmelden!
			viewManager.setButtonBlocking($("#btnLogin"), true);

			dataManager.verifyToken(o.loginId, o.loginToken, function (loginResult) {

				if (loginResult.error) {
					that.reset();
					viewManager.updateButtons();
					callback({
						success: 0,
						error: loginResult.error,
						errorId: loginResult.errorId || 0,
						clientName: loginResult.clientName,
						clientOwner: loginResult.clientOwner
					});
				}
				else{

					//automatisch ummelden?
					if (forceClientId){

						var url = pg.removeUrlParameter(window.location.href, "clientId=" + forceClientId);
						viewManager.originalHref = pg.removeUrlParameter(viewManager.originalHref, "clientId=" + forceClientId);
						window.history.replaceState(window.history.state, "upkeepr", url);

						var account = Account.getAccount(forceClientId);
						if (account) {
							that.switchAccount(forceClientId, callback);
						}
						else {
							//Hinweis
							callback({
								success: 0,
								error: "Der aufgerufene Link passt nicht zu Ihrem Benutzerprofil."
							});
						}

					}
					else {
						that.storeLoginData({
							loginId: loginResult.loginId,
							loginToken: loginResult.loginToken,
							email: o.email || "",
							password: o.password || "",
							clientId: model.curClientId
						});

						callback({
							success: 1
						});
					}
				}
			});
		},
		
		//-----------------------------------------------------
		
		//periodisch Session prüfen
		sessionTrackingIntervalId: -1,
		startSessionTracking: function () {
			
			var that = this;
			that.sessionTrackingIntervalId = setInterval(function () {
				
				dataManager.verifyValidSession(model.curSessionToken, function (success) {
					
					if (!success) {
						//force logout
						that.onInvalidSession();
					}
				});
			}, constants.SESSION_TRACKING_INTERVAL);
		},
		stopSessionTracking: function () {
			clearInterval(this.sessionTrackingIntervalId);
		},
		
		//Session nicht mehr gültig
		onInvalidSession: function(){
			var that = this;
			setTimeout(function () {
				that.logout(constants.LOGOUT_SESSION_EXPIRED);
			}, 20);
		},
		
		//------------------------------------------------------------
		
		//Keyhandler für Login-Dialog
		onLoginEmailKeyPress: function (e) {
			if (e.which === 13) {
				$("#loginPassword").focus();
				return false;
			}
		},
		onLoginPasswordKeyPress: function (e) {
			if (e.which === 13) {
				setTimeout(function () {
					authManager.verifyCredentials();
				}, 10);
				return false;
			}
		},
		
		//-----------------------------------------------------------
		
		//Berechtigungen für aktuellen User vorhanden? (OR-verknüpft)
		hasPermission: function (requiredPermissionId, requiredPermissionId2, requiredPermissionId3, requiredPermissionId4, requiredPermissionId5) {
			return this.hasUserPermission(User.getUser(model.curUserId), requiredPermissionId, requiredPermissionId2, requiredPermissionId3, requiredPermissionId4, requiredPermissionId5);
		},
		
		//dto. für einen bestimmten User
		hasUserPermission: function (user, requiredPermissionId, requiredPermissionId2, requiredPermissionId3, requiredPermissionId4, requiredPermissionId5) {
			if (!user)
				return false;
			
			var userRole = UserRole.getUserRole(user.role);
			if (userRole) {
				for(var i = 0; i < userRole.permissions.length; i++) {
					if (userRole.permissions[i].id === requiredPermissionId)
						if (userRole.permissions[i].value === 1)
							return true;
					if (requiredPermissionId2 && userRole.permissions[i].id === requiredPermissionId2)
						if (userRole.permissions[i].value === 1)
							return true;
					if (requiredPermissionId3 && userRole.permissions[i].id === requiredPermissionId3)
						if (userRole.permissions[i].value === 1)
							return true;
					if (requiredPermissionId4 && userRole.permissions[i].id === requiredPermissionId4)
						if (userRole.permissions[i].value === 1)
							return true;
					if (requiredPermissionId5 && userRole.permissions[i].id === requiredPermissionId5)
						if (userRole.permissions[i].value === 1)
							return true;
				}
			}
			return false;
		},
		
		//-------------------------------------------------------------------------------------
		
		//Passwort-vergessen-Dialog anzeigen
		showPasswordForgotten: function () {
			
			$('#formPasswortForgotten .alert').hide();
			$("#formPasswortForgotten .has-error").removeClass("has-error");
			
			$('#passwordForgottenDialog .dialogIntro').show();
			$('#passwordForgottenEmailBox').show();
			$('#btnPwdForgotten').show();

			this.hideDialogs();
			$("#passwordForgottenDialog").show();
			
		},
		
		//Key-Handler
		onPasswordForgottenEmailKeyPress: function (e) {
			
			if (e.which === 13) {
				setTimeout(function () {
					authManager.verifyPasswordForgotten();
				}, 10);
				return false;
			}
		},
		
		//Überprüfen der Angaben
		verifyPasswordForgotten: function () {
			
			//var that = this;
			
			$('#formPasswortForgotten .alert').hide();
			$("#formPasswortForgotten .has-error").removeClass("has-error");
			
			var email = $("#passwordForgottenEmail").val();
			if (email.length === 0) {
				$('#passwordForgottenEmailBox').addClass("has-error");
				$('#formPasswortForgotten .errMsg').html("Bitte geben Sie eine g&uuml;ltige E-Mail ein.").show();
				
				viewManager.updateButtons();
				return false;
			}
			if (!pg.validateEmail(email)) {
				$('#passwordForgottenEmailBox').addClass("has-error");
				$('#formPasswortForgotten .errMsg').html("Bitte geben Sie eine g&uuml;ltige E-Mail ein.").show();
				viewManager.updateButtons();
				return false;
			}
			
			//blocking
			if (!viewManager.setButtonBlocking($("#btnPwdForgotten")))
				return false;
			
			$("#passwordForgottenButton").hide();
			dataManager.verifyPasswordForgotten(email, function (success) {
				
				$("#passwordForgottenButton").show();
				
				//store!
				if (success) {
					
					viewManager.updateButtons();
					$('#passwordForgottenDialog .dialogIntro').hide();
					$('#passwordForgottenEmailBox').hide();
					$('#btnPwdForgotten').hide();
					
					$('#formPasswortForgotten .successMsg').html("Wir haben Ihnen eine E-Mail mit einem Best&auml;tigungslink zum Zur&uuml;cksetzen Ihres Passwortes zugeschickt.").show();
				}
				else {
					viewManager.updateButtons();
					$('#formPasswortForgotten .errMsg').html("Zu diesen Eingaben konnte kein Benutzer identifiziert werden.").show();
				}
			});
		},
		
		//-------------------------------------------------------------------------------------

		//Passwort zurücksetzen-Dialog anzeigen
		curPwdResetToken: null,
		showPasswordReset: function (isValidToken, token) {

			this.hideDialogs();

			if (isValidToken) {
				this.curPwdResetToken = token;
				$('#passwordResetDialog').show();
				$('#passwordResetDialog .alert').hide();
			}
			else{
				$('#tokenInvalidDialog .dialogTitle').html("Passwort zur&uuml;cksetzen").show();
				$('#tokenInvalidDialog .errMsg').html("Der aufgerufene Link zum Zur&uuml;cksetzen des Passwortes ist nicht oder nicht mehr g&uuml;ltig.").show();
				$('#tokenInvalidDialog').show();
			}
			
		},

		//Key-Handler
		onSetPassword1KeyPress: function (e, dialogType) {

			if (e.which === 13) {
				setTimeout(function () {
					authManager.verifyNewPassword(1, dialogType);
				}, 10);
				return false;
			}
		},
		onSetPassword2KeyPress: function (e, dialogType) {

			if (e.which === 13) {
				setTimeout(function () {
					authManager.verifyNewPassword(2, dialogType);
				}, 10);
				return false;
			}
		},

		//Überprüfen der Angaben
		verifyNewPassword: function (step, dialogType) {

			var formEl = $('#formSetNewPassword');
			if (dialogType === 2)
				formEl = $('#formAcceptInvitation');

			$('.alert', formEl).hide();
			$(".has-error", formEl).removeClass("has-error");

			var hasError = false;

			//Vor + Nachname
			var firstName,
				lastName;
			if (dialogType === 2){
				firstName = $(".invitationFirstName", formEl).val();
				if (firstName.length < 3){
					$('.invitationFirstNameBox', formEl).addClass("has-error");
					hasError = true;
				}

				lastName = $(".invitationLastName", formEl).val();
				if (lastName.length < 3){
					$('.invitationLastNameBox', formEl).addClass("has-error");
					hasError = true;
				}
			}

			//Passwort prüfen
			var pwd1 = $(".passwordSetEmail1", formEl).val();
			if (!this.validatePwdStrength(pwd1)){
				$('.passwordSetEmailBox1', formEl).addClass("has-error");
				$('.errMsg', formEl).show();
				hasError = true;
			}

			//Nutzungsbedingungen akzeptiert?
			if (dialogType === 2) {
				var hasAcceptedTerms = $(".acceptTerms", formEl).prop("checked");
				if (!hasAcceptedTerms) {
					$('.acceptTermsBox', formEl).addClass("has-error");
					hasError = true;
				}
			}

			var pwd2;
			if (step > 1) {

				pwd2 = $(".passwordSetEmail2", formEl).val();
				if (pwd1 !== pwd2) {
					$('.passwordSetEmailBox2', formEl).addClass("has-error");
					$('.errMsg2', formEl).show();
					hasError = true;
				}
			}

			if (hasError){
				viewManager.updateButtons();
				return false;
			}

			if (step > 1) {

				pwd2 = $(".passwordSetEmail2", formEl).val();
				if (pwd1 !== pwd2){
					$('.passwordSetEmailBox2', formEl).addClass("has-error");
					$('.errMsg2', formEl).show();

					viewManager.updateButtons();
					return false;
				}

				//blocking
				if (!viewManager.setButtonBlocking($(".btnSetPwd")))
					return false;

				//alles ok, jetzt speichern!
				var pwdHash = pg.getSha512(pwd1, constants.CRYPT_SEED);

				if (dialogType === 1) {

					//Passwort setzen
					dataManager.execUpdatePwd(this.curPwdResetToken, pwdHash, function (success) {

						//ok!
						if (success) {

							viewManager.updateButtons();
							$('.dialogIntro', formEl).hide();
							$('.passwordSetEmailBox1', formEl).hide();
							$('.passwordSetEmailBox2', formEl).hide();
							$('.btnSetPwd').hide();
							$(".btnCancelSetNewPassword").html("Zum Login");

							$('.successMsg', formEl).html("Ihr Passwort wurde erfolgreich aktualisiert.").show();
						}
						else {
							viewManager.updateButtons();
							$('.errMsg', formEl).html("Beim Speichern des neuen Passwortes ist ein Fehler aufgetreten.").show();
						}

					});
				}
				else{

					//Einladung annehmen
					dataManager.acceptInvitation(this.curInvitationToken, pwdHash, firstName, lastName, function (success) {

						//ok!
						if (success) {

							viewManager.updateButtons();
							$('.dialogIntro', formEl).hide();
							$('.userInfo', formEl).hide();
							$('.passwordSetEmailBox1', formEl).hide();
							$('.passwordSetEmailBox2', formEl).hide();
							$('.btnSetPwd').hide();
							$('.invitationAccepted', formEl).show();
							$(".btnCancelSetNewPassword").html("Zum Login");
							$(".acceptTermsBox").hide();

							$('.successMsg', formEl).html("Ihr Benutzerkonto wurde erfolgreich erstellt. Sie k&ouml;nnen sich nun mit Ihrer E-Mail Adresse und Ihrem Passwort anmelden.").show();
						}
						else {
							viewManager.updateButtons();
							$('.errMsg', formEl).html("Beim Anlegen Ihres Benutzerkontos ist ein Fehler aufgetreten.").show();
						}

					});
				}

			}
			else{
				$(".passwordSetEmail2", formEl).focus();
			}
		},

		//Passwort-Stärke prüfen
		validatePwdStrength: function(password){
			/*
			-	Länge: Mind. 8 Zeichen
			-	Mögliche Zeichen:
				o	Kleinbuchstaben
				o	Großbuchstaben
				o	Zahlen
				o	Sonderzeichen: !?$%&@(){}[]^=+*#;,:.-_~
			-	Mind. 3 der genannten Optionen müssen verwendet werden

			 */
			if (password.length < 8)
				return false;
			var hasUpperCase = /[A-Z]/.test(password);
			var hasLowerCase = /[a-z]/.test(password);
			var hasNumbers = /\d/.test(password);

			//!?$%&@(){}[]^=+*#;,:.-_~
			var hasNonalphas = /([\!\?\$\%\&\@\(\)\{\}\[\]\^\=\+\*\#\;\,\:\.\-\_\~])+/.test(password);
			return (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas >= 3);
		},

		//-------------------------------------------------------------------------------------

		//Einladung annehmen-Dialog anzeigen
		curInvitationToken: null,
		showAcceptInvitation: function (data, token) {

			this.hideDialogs();

			if (data.isValidToken) {
				this.curInvitationToken = token;

				var dialogEl = $('#acceptInvitationDialog');
				$(".clientName", dialogEl).html(data.clientName);
				$(".invitationFirstName", dialogEl).val(data.firstName);
				$(".invitationLastName", dialogEl).val(data.lastName);
				$(".invitationEmail", dialogEl).val(data.email);
				dialogEl.show();
				$('.alert', dialogEl).hide();
			}
			else{
				$('#tokenInvalidDialog .dialogTitle').html("Annehmen einer Einladung").show();
				$('#tokenInvalidDialog .errMsg').html("Der aufgerufene Link zum Annehmen einer Einladung ist nicht oder nicht mehr g&uuml;ltig.").show();
				$('#tokenInvalidDialog').show();
			}

		},
		
		//-------------------------------------------------------------------------------------
		
		//ist ein bestimmtes Modul diesem Kunden zugeordnet?
		hasModule: function(moduleId){
			for (var i=0; i<model.clientModules.length; i++) {
				if (model.clientModules[i].moduleId === moduleId)
					return true;
			}
			
			return false;
		}
		
	};
	
	window.authManager = authManager;
}());
