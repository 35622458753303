(function () {
	
	//----------------------------------------------------------------
	//Daten-Wert in Protokoll
	//----------------------------------------------------------------
	
    'use strict';

    /*global LocationField:true*/

    var ProtocolDataItem = {

        protocolDataItemPrototype: {

            fromObj: function (t) {
                this.id = parseInt(t.id, 10);

				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

                this.protocolId = parseInt(t.protocolId, 10);
                this.clientId = parseInt(t.clientId, 10);
				this.taskId = parseInt(t.taskId, 10);
				this.locationId = parseInt(t.locationId, 10);
				this.formFieldId = parseInt(t.formFieldId, 10);

				if (t.valueInt) {
					this.valueInt = parseInt(t.valueInt);
					if (isNaN(this.valueInt))
						this.valueInt = null;
				}
				else
					this.valueInt = null;

				if (t.valueFloat) {
					this.valueFloat = parseFloat(t.valueFloat);
					if (isNaN(this.valueFloat))
						this.valueFloat = null;
				}
				else
					this.valueFloat = null;

				if (t.valueString)
					this.valueString = pg.restoreDbString(t.valueString);
				else
					this.valueString = null;

				if (t.valueDate) {
					this.valueDate = pg.parseDate(t.valueDate);
					if (isNaN(this.valueDate))
						this.valueDate = null;
				}
				else
					this.valueDate = null;

                return this;
            },

            serialize: function () {

                var o = {};

                o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

                o.protocolId = this.protocolId;
                o.clientId = this.clientId;
                o.taskId = this.taskId;
                o.locationId = this.locationId;
				o.formFieldId = this.formFieldId;

				o.valueInt = this.valueInt;
				o.valueFloat = this.valueFloat;
				o.valueString = this.valueString;
				o.valueDate = pg.buildDate(this.valueDate);

                return o;
            },

			serializeCompact: function(){
				var o = {};

				o.id = this.id;
				o.formFieldId = this.formFieldId;
				o.value = this.valueInt || this.valueFloat || this.valueString || this.valueDate || "";

				return o;
			}
	
	        //----------------------------------------------
	        
        },
	
	    //----------------------------------------------

        createProtocolDataItem: function () {
            return Object.create(ProtocolDataItem.protocolDataItemPrototype);
        }
    };

    window.ProtocolDataItem = ProtocolDataItem;
}());