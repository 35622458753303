(function () {
		
		//---------------------------------------------------------------
		//einzelnen Antrag anzeigen
		// ---------------------------------------------------------------
		
		/*global
		 constants:true,
		 viewManager:true,
		 TaskType:true,
		 Location:true,
		 User:true,
		 authManager:true,
		 UserRole:true,
		 UserGroup:true,
		 LocationType:true,
		 RequestState:true,
		 Request:true,
		 taskRequestEditorManager:true,
		 Task:true,
		 TaskTypeField:true,
		 dataManager:true
		 */
		
		'use strict';
		
		var requestDetailManager = {
			
			//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
			isCurObjOutdated: false,
			checkModelChanged: function (data, oldModel) {
				
				var that = requestDetailManager;
				
				//aktueller Request verändert?
				var i,
					j,
					tsOld,
					tsNew,
					hasMatch;

				if (data.requests) {
					for(i = 0; i < data.requests.length; i++) {
						
						if (parseInt(data.requests[i].requestId) === oldModel.curRequest.requestId){
							
							that.isCurObjOutdated = true;
							
							tsOld = oldModel.curRequest.changedOn.getTime();
							tsNew = pg.parseDate(data.requests[i].changedOn).getTime();
							if (tsNew !== tsOld)
								return true;
						}
					}
				}
				//noinspection JSUnresolvedVariable
				if (data.requestStates) {
					//noinspection JSUnresolvedVariable
					for(i = 0; i < data.requestStates.length; i++) {

						//noinspection JSUnresolvedVariable
						if (parseInt(data.requestStates[i].requestId) === oldModel.curRequest.requestId){
							
							//betrifft aktuelles Objekt
							hasMatch = false;
							for(j = 0; j < oldModel.curRequest.states.length; j++) {
								
								var rs = oldModel.curRequest.states[j];
								//noinspection JSUnresolvedVariable
								if (rs.id === parseInt(data.requestStates[i].id)) {
									
									that.isCurObjOutdated = true;
									
									hasMatch = true;
									tsOld = rs.changedOn.getTime();
									//noinspection JSUnresolvedVariable
									tsNew = pg.parseDate(data.requestStates[i].changedOn).getTime();
									if (tsNew !== tsOld)
										return true;
								}
							}
							//neues Objekt
							if (!hasMatch) {
								that.isCurObjOutdated = true;
								return true;
							}
						}
					}
				}
				if (data.comments) {
					for(i = 0; i < data.comments.length; i++) {
						
						if (parseInt(data.comments[i].entityType) === constants.ENTITY_TYPE_REQUEST) {
							if (parseInt(data.comments[i].entityId) === oldModel.curRequest.requestId) {
								
								hasMatch = false;
								for(j = 0; j < oldModel.curRequest.comments.length; j++) {
									
									if (oldModel.curRequest.comments[j].id === parseInt(data.comments[i].id)) {
										
										that.isCurObjOutdated = true;
										
										hasMatch = true;
										tsOld = oldModel.curRequest.comments[j].changedOn.getTime();
										tsNew = pg.parseDate(data.comments[i].changedOn).getTime();
										if (tsNew !== tsOld)
											return true;
									}
								}
								//neues Objekt
								if (!hasMatch) {
									that.isCurObjOutdated = true;
									return true;
								}
							}
						}
					}
				}
				
				return false;
			},
			updateCurObj: function(o){
				
				o = o || Request.getRequest(model.curRequest.requestId);
				model.curRequest = o;
				this.isCurObjOutdated = false;
			},
			
			//------------------------------------------------------------------------------------------------------------------------------------------------
			
			//Request anzeigen
			showRequest: function (requestId, isNew, callbackComplete) {

				var that = requestDetailManager;
				
				if (isNew === undefined)
					isNew = false;
				
				if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_DETAILS)) {
					viewManager.execShowPage(constants.PAGE_OVERVIEW);
					return false;
				}
				
				if (!model.client.hasModule(constants.MODULE_ID_REQUESTS)) {
					viewManager.execShowPage(constants.PAGE_OVERVIEW);
					return false;
				}

				var t = Request.getRequest(requestId);

				if (!t) {
					viewManager.showPage(constants.PAGE_REQUEST_LIST);
					return;
				}
				
				//neueste Version verwenden!
				while (t.nextVersion)
					t = t.nextVersion;

				viewManager.setScrollTop(0);

				//------------------------------------------
				//darf diesen konkreten Auftrag sehen?
				
				var owned = false;
				var deciding = false;
				var responsible = false;
				var tt = -1;
				if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_ALL)) {
					if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_OWN))
						owned = true;
					if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_ASSIGNED_DECIDER))
						deciding = true;
					if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_RELEASED))
						responsible = true;
					if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_BY_TASK_TYPE))
						tt = t.taskTypeId;

					//jetzt prüfen!
					if (!t.canBeSeenByUser(owned, deciding, responsible, -1, null, tt)) {
						viewManager.showPage(constants.PAGE_REQUEST_LIST);
						return;
					}
				}
				
				//------------------------------------------
				
				that.updateCurObj(t);
				
				$("#content").html(window.templates['requestDetails.html']);
				setTimeout(function () {
					
					tt = TaskType.getTaskType(t.taskTypeId);
					var ts = t.getCurrentState();
					
					if (t.isArchived()) {
						$(".breadcrumbArchive").removeClass("hidden");
						viewManager.setSelectedMenuItem('menuRequests', 'menuRequestsArchive');
					}
					else {
						$(".breadcrumbActive").removeClass("hidden");
						viewManager.setSelectedMenuItem('menuRequests', 'menuRequestsActive');
					}
					
					//title
                    $("#requestHeader").html('#' + t.requestId + " - " + t.title);

                    $("#lblRequestId").html('#' + t.requestId);

                    $("#lblTitle").html(t.title);

                    var s = "";
                    $("#lblStatus").html('<td>' + t.getStatusLabel() + '</td>');
					
					//erste Version holen
					var requestHistory = t.getHistory();
					var d = new Date();
					d.setTime(requestHistory[0].timestamp);
					$("#lblTimestamp").html(pg.formatDate(d, true));

					//aktuell
					$("#lblOwner").html(User.getUserName(t.ownerId));

					//historisch
					var requestOriginal = t.getOriginalRequest();
					$("#lblCreator").html(User.getUserName(requestOriginal.ownerId));

					$("#lblDecider").html(User.getUserName(ts.deciderId));
					if (ts.responsibleId)
						$("#lblResponsible").html(User.getUserName(ts.responsibleId));
					
					if (ts.status === constants.STATUS_COMPLETED) {
						$("#lblCompletedTs").html(pg.formatDate(ts.changedOn, true));
						$("#rowCompletedTs").show();
					}
					else {
						$("#rowCompletedTs").hide();
					}
					
					$("#lblTaskType").html(TaskType.getTaskType(t.taskTypeId).name);
					if (t.locationType > 0) {
						var lt = LocationType.getLocationType(t.locationType);
						$("#lblLocationType").html(lt.name + " (" + lt.abbrev + ")");
						$("#requestDetailsOverview #rowLocationType").show();
					}
					else
						$("#requestDetailsOverview #rowLocationType").hide();
					$("#lblInterval").html(t.getIntervalName());
					
					//Intervall
					$("#btnEditRequest").prop('disabled', false);
					if (t.isInterval()) {
						if (t.endDate)
							$("#lblEndDate").html(pg.formatDate(t.endDate, true));
						else
							$("#lblEndDate").html("Ohne Enddatum");
						$("#requestDetailsEndDate").show();
					}
					else
						$("#requestDetailsEndDate").hide();

                    var dueDate = t.getDueDate();
                    if(dueDate)
                        $("#lblDueDate").html(pg.formatDate(dueDate, true));
                    else
                        $("#lblDueDate").html('Ohne');

					$("#lblDesc").html(pg.nl2br(t.description));
					
					//inaktive/gelöschte Objekte anzeigen
					//noinspection JSUnresolvedVariable
                    if (t.locationList.length) {
                        var locList = '<div class="task-location-list text-muted">' + Location.getLocationList(t.locationList, undefined, undefined, undefined, undefined, true, undefined, true) + '</div>';
                        $("#lblLocations").html(t.locationList.length + ' Objekt(e)<br>' + locList);
                        $("#requestDetailsOverview #rowLocations").show();
                    }
                    else {
                        $("#requestDetailsOverview #rowLocations").hide();
                    }

					//------------------------------------------------
					//dynamische Felder
					s = "";
					var ttFields = TaskTypeField.getTaskTypeFields(t.taskTypeId);
					for(var i = 0; i < ttFields.length; i++) {
						
						var ttf = ttFields[i];
						
						var dd = t.getDynDataObj(ttf.id);
						var ddValue = (dd) ? ttf.getFormattedValue(dd.value) : "";
						
						s += "<tr><td>" + ttf.name + "</td><td class=\"font-semibold\">" + ddValue + "</td></tr>";
					}
					$("#requestDetailsOverview table tbody tr.beforeDynData").after(s);
					//------------------------------------------------
					
					//History
					requestDetailManager.showRequestHistory(requestHistory, "#requestHistoryTable");
					
					//---------------------------------
					//zugehörige Aufträge
					
					$("#requestDetailsTabLinks").hide();
					
					//überhaupt Recht vorhanden?
					if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS)) {
						
						//neuesten Task holen
						var task = Task.getTaskByRequest(t.requestId);
						
						if (task) {
							var taskLinks = [];
							if (task.intervalId > 0)
								taskLinks = Task.getTasksByIntervalId(task.intervalId);
							else
								taskLinks.push(task);
							
							if (taskLinks.length > 0) {
								
								s = "";
								
								for(i = 0; i < taskLinks.length; i++) {
									var l = taskLinks[i];
									
									s += "<tr>";
									s += '<td><a data-taskId="' + l.taskId + '" class="btn grey-mint btn-xs btnTaskLink">Details</a></td>';
                                    s += '<td class="font-semibold">#' + l.taskId + '</td>';
									
									switch (l.getMasterStatus()) {
										/*case constants.STATUS_OBJECT_INACTIVE:
											s += '<td><span class="badge statusColorInactive">inaktiv</span></td>';
											break;*/
										
										case constants.TASK_STATUS_ACTIVE:
											switch (l.getStatus()) {
												case constants.STATUS_NEW:
													s += '<td><span class="badge badge-status statusColorNew">Offen</span></td>';
													break;
												case constants.STATUS_COMPLETED:
													s += '<td><span class="badge badge-status bgGreen">Erledigt</span></td>';
													break;
												case constants.STATUS_PROGRESS:
													s += '<td><span class="badge badge-status bgOrange">In Bearbeitung</span></td>';
													break;
												default:
													//Label
													var tl = l.getTaskLabel();
													s += '<td><span class="badge badge-status" style="background-color: ' + tl.colorBg + '; color: ' + tl.colorFg + ';">' + tl.name + '</span></td>';
													break;
											}
											break;
									}


                                    var lType = TaskType.getTaskType(l.taskTypeId).name;
                                    s += '<td>';
                                    s += '<span class="taskTitle">' + l.title + '</span><br/>';
                                    s += '<span class="taskType text-muted">' + lType + '</span>';
                                    s += '</td>';

									s += "<td>" + User.getUserName(l.getCurrentState().responsibleId) + "</td>";
									s += "<td>" + '<span style="color:' + l.getDueDateColor() + '">' + pg.formatDate(l.getDueDate()) + '</span></td>';

									s += "</tr>";
								}
								
								$("#requestDetailLinksTable tbody").html(s);
								
								$("#requestDetailsTabLinks").show();
							}
						}
					}
					
					//---------------------------------
					
					//handlers
					$("#btnBackRequest").on("click", function () {
						model.curRequestListView.preserveCurPage = true;
						if (model.curRequest.isArchived())
							viewManager.showPage(constants.PAGE_REQUEST_ARCHIVE);
						else
							viewManager.showPage(constants.PAGE_REQUEST_LIST);
					});
					
					$(".btnTaskLink").on("click", that.showTaskDetails);
					
					//--------------------------------------
					
					//Archivieren

					var mayArchive = false;
					if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_ARCHIVE_ALL)) {
						mayArchive = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_ARCHIVE_OWN)) {
							if (t.ownerId === model.curUserId)
								mayArchive = true;
						}
						else {
							if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_ARCHIVE_BY_TASK_TYPE)) {
								if (model.curUser.hasTaskType(t.taskTypeId))
									mayArchive = true;
							}
						}
					}
					
					//ist überhaupt im richtigen Zustand?
					if (mayArchive)
						mayArchive = t.canBeArchived();
					
					if (mayArchive) {
						if (t.isArchived()) {
							$("#btnRemoveRequestArchive").show().click(function () {
								that.setRequestStateGroup(model.curRequest, constants.GROUP_DEFAULT);
							});
							$("#btnSetRequestArchive").hide();
						}
						else{
							$("#btnRemoveRequestArchive").hide();
							$("#btnSetRequestArchive").show().click(function () {
								that.setRequestStateGroup(model.curRequest, constants.GROUP_ARCHIVE);
							});
						}
					}
					else{
						$("#btnRemoveRequestArchive").hide();
						$("#btnSetRequestArchive").hide();
					}
					
					//---------------------------------------

					var mayEdit = false;
					if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_ALL)) {
						mayEdit = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_OWN)) {
							if (t.ownerId === model.curUserId)
								mayEdit = true;
						}
						
						if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_BY_TASK_TYPE)) {
							if (model.curUser.hasTaskType(t.taskTypeId))
								mayEdit = true;
						}
					}
					
					$("#btnEditRequest").hide();
					if (mayEdit) {
						//Status?
						switch (ts.status) {
							
							//ja: solange noch keine Freigabe durch Entscheider, darf Antragsteller noch ändern!
							case RequestState.STATUS_NEW:
							case RequestState.STATUS_REOPENED:
							case RequestState.STATUS_REJECTED:
							case RequestState.STATUS_INQUIRY:
							case RequestState.STATUS_CLOSED:
								$("#btnEditRequest").show().off("click").on("click", function () {
									viewManager.showPage(constants.PAGE_REQUEST_EDIT, {
										requestId: requestId
									});
								});
								break;
						}
					}

					//--------------------------------------

					var mayClone = false;
					if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_CREATE)) {
						mayClone = true;
					}
					else {
						//nur wenn A-Typ stimmt
						if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_CREATE_BY_TASK_TYPE)) {
							if (model.curUser.hasTaskType(model.curRequest.taskTypeId))
								mayClone = true;
						}
					}

					if (!mayClone) {
						$("#btnCloneRequest").hide();
					}
					else {
						$("#btnCloneRequest").off("click").on("click", function () {
							viewManager.showPage(constants.PAGE_REQUEST_CLONE);
						});
					}
					
					//--------------------------------------
					//je nach Status und Permissions verschiedene Interaktionen anbieten

                    $("#btnGroupRequestActions").hide();
                    $("#btnReleaseRequest").hide();
					$("#btnDenyRequest").hide();
					$("#btnCloseRequest").hide();
					$("#btnFeedbackRequest").hide();
					$("#btnCompleteRequest").hide();
					$("#btnReOpenRequest").hide();
					$("#btnReOpenInquiryRequest").hide();

					var mayFeedback = false;
					
					var mayClose = false;
					switch (ts.status) {
						
						//zur "Ausführung": Freigabe, Ablehnung oder Feedback
						case RequestState.STATUS_NEW:
						case RequestState.STATUS_REOPENED:
							
							var mayExecute = false;
							if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EXECUTE_ALL)) {
								mayExecute = true;
							}
							else {
								if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EXECUTE_ASSIGNED)) {
									if (ts.deciderId === model.curUserId)
										mayExecute = true;
								}
							}
							if (mayExecute) {
                                $("#btnGroupRequestActions").show();
                                $("#btnReleaseRequest").show().off("click").on("click", function () {
									that.releaseRequest();
									return false;
								});
								$("#btnDenyRequest").show().off("click").on("click", function () {
									that.denyRequest();
									return false;
								});
							}
							
							mayFeedback = false;
							if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_SET_STATUS_INQUIRY_ALL)) {
								mayFeedback = true;
							}
							else {
								if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_SET_STATUS_INQUIRY_ASSIGNED)) {
									if (ts.deciderId === model.curUserId)
										mayFeedback = true;
								}
							}
							if (mayFeedback) {
                                $("#btnGroupRequestActions").show();
                                $("#btnFeedbackRequest").show().off("click").on("click", function () {
									that.feedbackRequest();
									return false;
								});
							}
							
							//Sonderfall: auch Antragsteller darf noch eingreifen
							mayClose = false;
							if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_ALL)) {
								mayClose = true;
							}
							else {
								if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_OWN)) {
									if (t.ownerId === model.curUserId)
										mayClose = true;
								}
								else{
									if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_BY_TASK_TYPE)) {
										if (model.curUser.hasTaskType(t.taskTypeId))
											mayClose = true;
									}
								}
							}
							if (mayClose) {
                                $("#btnGroupRequestActions").show();
                                $("#btnCloseRequest").show().off("click").on("click", function () {
									that.closeRequest();
									return false;
								});
							}
							
							break;
						
						//freigegeben, beim Verantwortlichen
						case RequestState.STATUS_APPROVED:
							
							var mayComplete = false;
							if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_CONVERT_RELEASED_TO_TASK_ALL)) {
								mayComplete = true;
							}
							else {
								if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_CONVERT_RELEASED_TO_TASK_ASSIGNED)) {
									if (ts.responsibleId === model.curUserId)
										mayComplete = true;
								}
							}
							if (mayComplete) {
                                $("#btnGroupRequestActions").show();
                                $("#btnCompleteRequest").show().off("click").on("click", function () {
									that.completeRequest();
									return false;
								});
							}
							
							mayFeedback = false;
							if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_SET_STATUS_INQUIRY_ALL)) {
								mayFeedback = true;
							}
							else {
								if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_SET_STATUS_INQUIRY_ASSIGNED)) {
									if (ts.responsibleId === model.curUserId)
										mayFeedback = true;
								}
							}
							if (mayFeedback) {
                                $("#btnGroupRequestActions").show();
                                $("#btnFeedbackRequest").show().off("click").on("click", function () {
									that.feedbackRequest();
									return false;
								});
							}
							
							break;
						
						//zurück beim Antragsteller
						case RequestState.STATUS_REJECTED:

							if (mayEdit) {
                                $("#btnGroupRequestActions").show();
                                $("#btnCloseRequest").show().off("click").on("click", function () {
									that.closeRequest();
									return false;
								});
								$("#btnReOpenRequest").show().off("click").on("click", function () {
									that.reOpenRequest(ts);
									return false;
								});
							}
							break;

						//separates Wording, falls gerade Rückfrage
						case RequestState.STATUS_INQUIRY:

							if (mayEdit) {
								$("#btnGroupRequestActions").show();
								$("#btnCloseRequest").show().off("click").on("click", function () {
									that.closeRequest();
									return false;
								});
								$("#btnReOpenInquiryRequest").show().off("click").on("click", function () {
									that.reOpenRequest(ts);
									return false;
								});
							}
							break;
						
						case RequestState.STATUS_CLOSED:
							if (mayEdit) {
                                $("#btnGroupRequestActions").show();
                                $("#btnReOpenRequest").show().off("click").on("click", function () {
									that.reOpenRequest(ts);
									return false;
								});
							}
							break;
						
						case RequestState.STATUS_COMPLETED:
							break;
					}
					
					//Schließen geht prinzipiell (fast) immer, durch Antragsteller und Admins
					if ((ts.status !== RequestState.STATUS_COMPLETED) && (ts.status !== RequestState.STATUS_CLOSED)) {
						mayClose = false;
						if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_ALL)) {
							mayClose = true;
						}
						else {
							if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_OWN)) {
								if (t.ownerId === model.curUserId)
									mayClose = true;
							}
							else{
								if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_BY_TASK_TYPE)) {
									if (model.curUser.hasTaskType(t.taskTypeId))
										mayClose = true;
								}
							}
						}
						if (mayClose) {
							$("#btnCloseRequest").show().off("click").on("click", function () {
								that.closeRequest();
								return false;
							});
						}
					}
					
					//--------------------------------------
					
					/*if (isNew)
						$("#btnBackRequest").hide();
					else*/
						$("#btnBackRequest").show();
					
					//--------------------------------------
					
					t.updateAttachments();
					requestDetailManager.showRequestAttachments("#requestDetailAttachmentTableBox", t.getAttachments(), false);
					
					//-----------------------------
					
					//Zustand
					if (!model.curStateDetails) {
						//state merken
						model.curStateDetails = {
							curTabIndex: 0
						};
					}
					
					//anwenden
					viewManager.activateNavTab("#requestDetailsBox", model.curStateDetails.curTabIndex);

					//update components
					viewManager.updateLayout();

					if (callbackComplete)
						callbackComplete();
					
				}, constants.TEMPLATE_DELAY);
				
				return false;
			},
			
			//Historie anzeigen
			showRequestHistory: function (requestHistory, trgEl) {
				var s = "";
				
				for(var i = 0; i < requestHistory.length; i++) {
					var index = requestHistory.length-1-i;
					var h = requestHistory[index];
					
					s += "<tr><td>";
					s += Request.getRequestHistoryName(h, requestHistory, index);
					s += "</td><td>";
					s += User.getUserName(h.actorId, true);
					s += "</td><td>";
					
					var d = new Date();
					d.setTime(h.timestamp);
					//s += pg.formatDateTime(d);
					s += pg.formatDate(d, true);
					s += "</td></tr>";
				}
				
				$(trgEl + " tbody").html(s);
				
				$("#btnAddRequestComment").off("click").on("click", this.onAddComment);
			},
			
			//----------------------------------------------------------------------------------------
			
			//Kommentar speichern
			onAddComment: function(){
				
				var that = requestDetailManager;
				
				var comment = $("#requestComment").val();
				if (!comment) {
					viewManager.updateButtons();
					return false;
				}
				
				//blocking
				if (!viewManager.setButtonBlocking($("#btnAddRequestComment"))) {
					viewManager.updateButtons();
					return false;
				}
				
				var c = Comment.createComment().fromObj({
					clientId: model.curClientId,
					userId: model.curUserId,
					entityType: constants.ENTITY_TYPE_REQUEST,
					entityId: model.curRequest.requestId,
					comment: comment
				});
				
				if (that.isCurObjOutdated)
					that.updateCurObj();
				
				dataManager.saveComment(model.curRequest, c, function(cNew){

					that.showRequest(model.curRequest.requestId);
					viewManager.updateButtons();
				});
				
				return false;
			},
			
			//-------------------------------------------------------------------------
			
			//Anhänge anzeigen
			showRequestAttachments: function (trgTableId, attachments, allowEditing) {
				
				var s = "";
				var proceed = true;
				if (!attachments) {
					proceed = false;
				}
				else if (attachments.length === 0) {
					proceed = false;
				}
				
				if (proceed) {
					
					//Inhalte
					for(var i = 0; i < attachments.length; i++) {
						var att = attachments[i];
						
						s += "<div class='vCenterJustifyBox w100'>";
						
						//Image/Icon
						//s += '<div class="vCenterLeftBox"><a href="' + constants.API_RAW + "/" + att.url + '"';
						s += '<div class="vCenterLeftBox"><a href="' + att.getUrl(false) + '"';
						if (att.isImage())
							s += ' class="fancybox fancybox-type-image noUnderline" rel="group1">';
						else
							s += ' data-fancybox-type="iframe" class="fancybox fancybox-type-iframe noUnderline">';
						
						s += '<div class="mr10">';
						if (att.isImage()) {
							s += '<div class="previewImage" style="background-image:url(' + att.getPreviewImage(model.curRequest) + ');" />';
						}
						else {
							s += '<div class="previewImage vCenterBox bgLightestGray"><i class="icon fa fa-file-pdf-o bigIcon"></i></div>';
						}
						s += '</div>';
						s += '</a>';
						
						//Name
						s += '<div>';
						s += '<span class="fgDarkGray bold">' + att.name + '</span><br/><span class="fgLightGray noUnderline">' + att.size + '</span></a>';
						s += '</div>';
						s += '</div>';
						
						s += '<div class="vCenterRightBox">';
						
						//download
						//s += '<div class="mr30"><a target="_blank" href="' + constants.API_RAW + "/" + att.url + '?fdl=1" class="fgDarkGray"><i class="fa fa-download" aria-hidden="true"></i> Download</a></div>';
						s += '<div class="mr30"><a target="_blank" href="' + att.getUrl(true) + '" class="fgDarkGray"><i class="fa fa-download" aria-hidden="true"></i> Download</a></div>';
						
						//delete: not here
						s += '<div class="">';
						s += '&nbsp;';
						s += '</div>';
						
						s += '</div>';
						
						s += '</div>';
					}
					
					$(trgTableId).html(s);
					
					//Typ für Bilder und PDFs verschieden setzen
					//http://www.fancyapps.com/fancybox/
					$(trgTableId + " .fancybox-type-image").fancybox({
						openEffect: 'none',
						closeEffect: 'none',
						type : 'image'
					});
					$(trgTableId + " .fancybox-type-iframe").fancybox({
						openEffect: 'none',
						closeEffect: 'none',
						type : 'iframe',
						iframe: {
							preload: false
						}
					});
					
					$("#requestAttachmentTableBox").show();
				}
				else {
					$("#requestAttachmentTableBox").hide();
				}
				
				
			},
			
			//Sprung zu Auftrag
			showTaskDetails: function (e) {
				
				if (e) {
					var link = $(e.currentTarget);
					if (link.attr("data-taskId")) {
						var taskId = parseInt(link.attr("data-taskId"), 10);
						
						viewManager.showPage(constants.PAGE_TASK_DETAILS, {
							taskId: taskId,
							backFunction: constants.BACK_BROWSER
						}, true, true, true);
					}
				}
				return false;
			},
			
			//-----------------------------------------------------
			//Status hinzufügen (Kommentar ggf. verpflichtend)
			
			releaseRequest: function () {
				this.showStatusDialog(RequestState.STATUS_APPROVED, "Antrag freigeben", false);
			},
			denyRequest: function () {
				this.showStatusDialog(RequestState.STATUS_REJECTED, "Antrag ablehnen", true);
			},
			feedbackRequest: function () {
				this.showStatusDialog(RequestState.STATUS_INQUIRY, "R&uuml;ckfrage stellen", true);
			},
			closeRequest: function () {
				this.showStatusDialog(RequestState.STATUS_CLOSED, "Antrag schlie&szlig;en", true);
			},
			reOpenRequest: function (curTs) {
				var title = "Antrag wiederer&ouml;ffnen";
				if (curTs.status === RequestState.STATUS_INQUIRY)
					title = "R&uuml;ckfrage beantworten";
				this.showStatusDialog(RequestState.STATUS_REOPENED, title, true);
			},
			completeRequest: function () {
				
				//neuer Task aus Request
				var task = Task.createTask().fromObj({
					clientId: model.curClientId,
					id: -1,
					taskId: -1,
					requestId: model.curRequest.requestId,
					
					description: model.curRequest.description,
					generationId: 0,
					intervalType: model.curRequest.intervalType,
					locations: model.curRequest.locations,
					ownerId: model.curUserId,
					intervalStatus: constants.STATUS_OBJECT_ACTIVE,
					taskType: model.curRequest.taskTypeId,
					version: 1,
					createdOn: null,
					createdBy: -1,
					changedOn: null,
					changedBy: -1,
					dueDate: model.curRequest.dueDate,
					endDate: model.curRequest.endDate,
					attachments: model.curRequest.attachments,
					locationType: model.curRequest.locationType,
					title: model.curRequest.title,
					dynData: model.curRequest.dynData
				});
				
				taskRequestEditorManager.editTaskObject(task);
			},
			
			//Dialog anzeigen
			showStatusDialog: function (newStatus, title, mustComment) {
				
				var that = this;

				//Dialog anzeigen mit Frage + Textfeld
				$("#requestStatusModalTitle").html(title);
				$("#requestStatusModal .modal-body").removeClass("has-error");
				$("#requestStatusComment").val("");
				
				if (mustComment)
					$("#requestStatusCommentLabel").html("Kommentar <span class='required'>* </span>");
				else
					$("#requestStatusCommentLabel").html("Kommentar");

				var rs = model.curRequest.getCurrentState();

				//bei Freigabe Verantwortlichen verpflichtend editierbar machen
				var sel,
					i,
					u;
				if (newStatus === RequestState.STATUS_APPROVED) {
					
					sel = "";
					sel += '<label id="lblRequestModalResponsibleSelect" for="requestModalResponsibleSelect" class="control-label">Verantwortlicher:&nbsp;<span class="required">* </span></label>';
					sel += '	<select id="requestModalResponsibleSelect">';
					sel += '<option value="">Bitte w&auml;hlen...</option>';
					var uGroups = UserGroup.getActiveUserGroups(null, null, true, model.curRequest.taskTypeId, [], null, model.curRequest.locationType, true);
					for(i = 0; i < uGroups.length; i++) {
						if (uGroups[i].isSingleUser) {
							u = uGroups[i].getSingleUser();
							
							//muss überführen dürfen
							var mayContinue = true;
							if (!authManager.hasUserPermission(u, UserRole.PERMISSION_REQUEST_CONVERT_RELEASED_TO_TASK_ALL, UserRole.PERMISSION_REQUEST_CONVERT_RELEASED_TO_TASK_ASSIGNED))
								mayContinue = false;
							if (!authManager.hasUserPermission(u, UserRole.PERMISSION_TASK_EDIT_ALL, UserRole.PERMISSION_TASK_EDIT_OWN, UserRole.PERMISSION_TASK_EDIT_BY_TASK_TYPE))
								mayContinue = false;
							if (mayContinue)
								sel += "<option value='" + u.id + "'>" + u.getName() + "</option>";
						}
					}
					sel += '</select>';
					
					$("#requestModalResponsibleBox").html(sel).show();
					$("#requestModalResponsibleSelect").select2();
					
					if (rs.responsibleId)
						$("#requestModalResponsibleSelect").val(rs.responsibleId).trigger("change");
				}
				else {
					$("#requestModalResponsibleBox").hide();
				}

				//kein Entscheider? je nach Auto-Freigabe weiter
				if (rs.deciderId < 0){
					if (model.client.autoReleaseRequests === 1){
						$("#requestModalDeciderBox").hide();
					}
					else{

						//abfragen
						//bei Freigabe Verantwortlichen verpflichtend editierbar machen
						sel = "";
						sel += '<label id="lblRequestModalDeciderSelect" for="requestModalDeciderSelect" class="control-label">Entscheider:&nbsp;<span class="required">* </span></label>';
						sel += '	<select id="requestModalDeciderSelect">';
						sel += '<option value="">Bitte w&auml;hlen...</option>';

						var users = User.getActiveUsers();
						for (i = 0; i < users.length; i++) {
							u = users[i];
							if (u.id !== model.curUserId)
								if (authManager.hasUserPermission(u, UserRole.PERMISSION_REQUEST_EXECUTE_ALL, UserRole.PERMISSION_REQUEST_EXECUTE_ASSIGNED))
									sel += "<option value='" + u.id + "'>" + u.getName() + "</option>";
						}
						sel += '</select>';

						$("#requestModalDeciderBox").html(sel).show();
						$("#requestModalDeciderSelect").select2();
					}
				}
				else{
					$("#requestModalDeciderBox").hide();
				}
				
				viewManager.updateButtons();
				
				//Anzeige Modal
				$("#requestStatusModal").modal({
					backdrop: "static",
					keyboard: false
				});
				
				$("#btnRequestStatusModalCancel").off("click").on("click", function () {
					viewManager.updateButtons();
					$("#requestStatusModal").modal('hide');
				});
				$("#btnRequestStatusModalOk").off("click").on("click", function () {

					$("#requestStatusModal .modal-body .has-error").removeClass("has-error");
					
					//Kommentar merken
					var hasError = false;
					var comment = $("#requestStatusComment").val();
					if (!comment && mustComment) {
						viewManager.updateButtons();
						$("#requestModalCommentBox").addClass("has-error");
						hasError = true;
					}
					
					//Verantwortlicher
					var prevRs = model.curRequest.getCurrentState();
					var responsibleId = prevRs.responsibleId;
					if (newStatus === RequestState.STATUS_APPROVED) {
						responsibleId = $("#requestModalResponsibleSelect").val();
						if (!responsibleId) {
							viewManager.updateButtons();
							$("#requestModalResponsibleBox").addClass("has-error");
							hasError = true;
						}
					}

					//Entscheider
					var deciderId = prevRs.deciderId;
					if ((deciderId < 0) && (model.client.autoReleaseRequests === 0)){
						deciderId = $("#requestModalDeciderSelect").val();
						if (!deciderId) {
							viewManager.updateButtons();
							$("#requestModalDeciderBox").addClass("has-error");
							hasError = true;
						}
					}

					if (hasError)
						return;

					//blocking
					if (!viewManager.setButtonBlocking($("#btnRequestStatusModalOk")))
						return false;

					//dataManager für Versand verwenden
					that.addRequestState(newStatus, comment, responsibleId, deciderId, function () {

						$("#requestStatusModal").modal('hide');

						//Ansicht aktualisieren
						that.showRequest(model.curRequest.requestId, false);
					});

				});
				
			},
			
			//neuen Status speichern
			addRequestState: function (newStatus, comment, responsibleId, deciderId, callback) {
				
				var that = this;

				//frisch holen
				model.curRequest = Request.getRequest(model.curRequest.requestId);

				//automatische Freigabe
				if ((model.client.autoReleaseRequests === 1) && (newStatus <= RequestState.STATUS_NEW))
					newStatus = RequestState.STATUS_APPROVED;

				//erzeugen & ergänzen
				var rs = model.curRequest.addRequestState();
				rs.status = newStatus;
				if (responsibleId)
					rs.responsibleId = responsibleId;
				if (deciderId)
					rs.deciderId = deciderId;
				rs.comment = comment;

				//Wiedereröffnen löscht Archiv-Flag
				if (newStatus === RequestState.STATUS_REOPENED)
					rs.groupId = constants.GROUP_DEFAULT;

				if (that.isCurObjOutdated)
					that.updateCurObj();
				
				dataManager.addRequestState(model.curRequest, rs, function (rsNew) {
					
					viewManager.updateButtons();

					//aktualisieren
					if (rsNew){
						model.curRequest = Request.getRequest(model.curRequest.requestId);
					}

					if (callback)
						callback(rsNew);
					
				});
				
			},
			
			//neue Group speichern
			setRequestStateGroup: function (r, groupId) {
				
				var that = requestDetailManager;
				
				//frisch holen
				model.curRequest = Request.getRequest(r.requestId);
				
				//erzeugen & ergänzen
				var rs = model.curRequest.addRequestState();
				rs.groupId = groupId;
				
				if (that.isCurObjOutdated)
					that.updateCurObj();
				
				dataManager.addRequestState(model.curRequest, rs, function (rsNew) {
					
					viewManager.updateButtons();
					
					if (rsNew) {
						//update view
						that.showRequest(model.curRequest.requestId);
					}
					
					if (groupId === constants.GROUP_ARCHIVE)
						viewManager.showNotification("Antrag #" + r.requestId + " wurde erfolgreich archiviert.", constants.NOTIFICATION_SUCCESS);
					else
						viewManager.showNotification("Antrag #" + r.requestId + " wurde erfolgreich wiederhergestellt.", constants.NOTIFICATION_SUCCESS);
					
				});
				
			},
			
			//------------------------------------------------------------------------
			
			//tab changed
			onRequestDetailsTabChange: function (index) {
				model.curStateDetails.curTabIndex = index;
				model.curStateDetails.scrollPosition = viewManager.getScrollTop();
				viewManager.setStateDetails(model.curStateDetails);
			}
			
			//------------------------------------------
			
			
		};
		
		window.requestDetailManager = requestDetailManager;
	}()
);
