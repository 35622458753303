(function () {
	
	//----------------------------------------------------------------
	//Feld in Protokoll-Formular (Prüfungskonfiguration)
	//----------------------------------------------------------------
	
    'use strict';

    /*global
    LocationField:true,
    ProtocolForm:true,
    formatManager:true
    */

    var ProtocolFormField = {

	    //bestimmte Felder waren früh implizit vordefiniert, jetzt explizit wie alle anderen
	    ORDER_INDEX_FIELD_FOTOS: 100000,
	    ORDER_INDEX_FIELD_NOTES: 100001,

		DISPLAY_MODE_ALWAYS: 0,
		DISPLAY_MODE_DEPENDING_ON_LOCATION_FORM_FIELD: 1,

        protocolFormFieldPrototype: {

            type: LocationField.FIELD_TYPE_LIST_BUTTONS,

            fromObj: function (t) {
                this.id = parseInt(t.id, 10);

				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

                this.formId = parseInt(t.formId, 10);
                this.clientId = parseInt(t.clientId, 10);
	
	            this.hint = t.hint;
	            this.placeholder = t.placeholder;

                this.orderIndex = parseInt(t.orderIndex, 10);
                this.category = t.category;
                this.name = t.name || t.fieldName;
                this.options = t.options;
				this.colorOptions = t.colorOptions;
				this.incidentOptions = t.incidentOptions;
                this.isRequired = parseInt(t.isRequired, 10);
                if (t.type) {
                    this.type = parseInt(t.type);
                    this.suffix = t.suffix;
                }
                this.defaultValue = t.defaultValue ? t.defaultValue : null;
				this.displayMode = t.displayMode ? parseInt(t.displayMode) : 0;
				this.okValue = t.okValue ? t.okValue : null;
	
	            this.minVal = parseFloat(t.minVal);
	            this.maxVal = parseFloat(t.maxVal);
	            this.step = parseFloat(t.step);
				
                return this;
            },

            serialize: function () {

                var o = {};

                o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

                o.formId = this.formId;
                o.clientId = this.clientId;
	
	            o.hint = this.hint;
	            o.placeholder = this.placeholder;
                
                o.orderIndex = this.orderIndex;
                o.category = this.category;
                o.name = this.name;
                o.options = this.options;
				o.colorOptions = this.colorOptions;
				o.incidentOptions = this.incidentOptions;
                o.isRequired = this.isRequired;

                o.type = this.type;
                o.suffix = this.suffix;
                
                o.defaultValue = this.defaultValue;
                o.displayMode = this.displayMode;
				o.okValue = this.okValue;
	
	            o.minVal = this.minVal;
	            o.maxVal = this.maxVal;
	            o.step = this.step;

                return o;
            },
	
	        //----------------------------------------------
	        
	        //Werte gemäß Feld-Typ formatieren
            getFormattedValue: function(val){
            	return formatManager.getFormattedValue(this.type, val, this.options);
            },

			//Farbwerte ab nun via DB-Einträge holen
			getColorData: function(fieldValue){
				if (!fieldValue)
					return "";
				if (!this.options)
					return "";
				if (!this.colorOptions)
					return "";
				var index = this.getOptionIndex(fieldValue);
				if (index < 0)
					return "";

				var colorOptions = this.colorOptions.split("#");
				return colorOptions[index];
			},

			//Incidents ab nun via DB-Einträge holen
			getIncidentType: function(fieldValue){
            	if (!fieldValue)
            		return 0;
				if (!this.options)
					return 0;
				if (!this.incidentOptions)
					return 0;
				var index = this.getOptionIndex(fieldValue);
				if (index < 0)
					return 0;

				var incOptions = this.incidentOptions.split("#");
				return parseInt(incOptions[index]);
			},

			getOptionIndex: function(optionName){
				if (!this.options)
					return false;

				optionName = optionName.toLowerCase();
				var op = this.getOptions();
				for (var i=0; i<op.length; i++){
					if (op[i].toLowerCase() === optionName)
						return i;
				}
				return -1;
			},
			hasOption: function(optionName){
				return (this.getOptionIndex(optionName) >= 0);
			},
			getOptions: function(){
				if (!this.options)
					return [];

				return ProtocolForm.decodeHtml(this.options).split("#");
			}
        },
	
	    //----------------------------------------------

        createProtocolFormField: function () {
            return Object.create(ProtocolFormField.protocolFormFieldPrototype);
        }
    };

    window.ProtocolFormField = ProtocolFormField;
}());
