(function () {

    'use strict';

    var overviewManager = {

        showOverview: function(callbackComplete){

            $("#content").html(window.templates['overview.html']);
            setTimeout(function(){

				if (callbackComplete)
					callbackComplete();

            }, constants.TEMPLATE_DELAY);

        }

    };

    window.overviewManager = overviewManager;
}());