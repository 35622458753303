(function () {
	
	'use strict';
	
	//------------------------------------------------------------------------
	//Kundendaten ändern
	//------------------------------------------------------------------------
	
	/* global viewManager:true, setupFileUploader:true, dataManager:true, constants:true, authManager:true, UserRole:true, Attachment:true */
	
	var clientManager = {
		
		//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
		checkModelChanged: function(data, oldModel){
			
			if (data.client){
				
				//keine eigenen Änderungen berücksichtigen!
				var tsOld = oldModel.client.changedOn.getTime();
				var tsNew = pg.parseDate(data.client.changedOn).getTime();
				return (tsNew !== tsOld);
			}
			
			return false;
		},
		
		//----------------------------------------------------------------------------------------------
		
		//Anzeige der Kundendaten vorbereiten
		showClient: function (callbackComplete) {
			
			var that = this;
			viewManager.setSelectedMenuItem('menuSettings', 'menuClient');
			
			$("#content").html(window.templates['clientEdit.html']);
			setTimeout(function () {
				
				//fill in data
				that.showClientInformation(false);

				if (callbackComplete)
					callbackComplete();

			}, constants.TEMPLATE_DELAY);
		},
		
		editClient: function(callbackComplete){
			var that = this;
			viewManager.setSelectedMenuItem('menuSettings', 'menuClient');
			
			$("#content").html(window.templates['clientEdit.html']);
			setTimeout(function () {
				
				//fill in data
				that.showClientInformation(true);

				if (callbackComplete)
					callbackComplete();

			}, constants.TEMPLATE_DELAY);
		},
		
		//Kundendaten rendern
		showClientInformation: function(showEditor) {
		
			var that = this;
			that.deleteFiles = [];

			$("#trialNotice").hide();

			//-----------------------------------------------------------------------
			
			if (authManager.hasPermission(UserRole.PERMISSION_CLIENT_EDIT) && showEditor) {
				
				$("#btnEditClient").hide();
				
				$("#clientBox #name").val(model.client.name);
				$("#clientBox #company").val(model.client.company);
				$("#clientBox #vatId").val(model.client.vatId);
				$("#clientBox #street").val(model.client.street);
				$("#clientBox #areaCode").val(model.client.areaCode);
				$("#clientBox #city").val(model.client.city);
				$("#clientBox #fon").val(model.client.fon);
				$("#clientBox #countryCode").val(model.client.countryCode);
				that.logoUrl = model.client.logoUrl;
				
				setupFileUploader($("#clientBox .fileuploadBox"), that.onClientLogoUploaded, constants.MEDIA_TYPE_IMAGE, false);
				
				$("#btnCancelClient").off("click").on("click", function () {
					
					that.cleanUploads(false);
					
					that.showClientInformation(false);
				});
				
				$("#btnSaveClient").off("click").on("click", function () {
					that.initSaveClient();
				});
				
				$("#btnRemoveLogo").off("click").on("click", function () {
					
					//vorheriges später löschen
					that.deleteFiles.push(that.logoUrl);
					
					that.logoUrl = "";
					that.updateLogoDisplay(null);
					viewManager.setFormDirty();
				});
				
				$("#clientViewBox").hide();
				$("#clientEditorBox").show();
				
				viewManager.updateButtons();
			}
			else{
				
				if (authManager.hasPermission(UserRole.PERMISSION_CLIENT_EDIT)) {
					$("#btnEditClient").show().off("click").on("click", function () {
						viewManager.showPage(constants.PAGE_CLIENT_EDIT, null, true, true, true);
					});
				}
				else
					$("#btnEditClient").hide();

				//-------------------------------------

				if (model.client.type === constants.CLIENT_TYPE_TRIAL){
					var deltaSec = model.client.validUntil.getTime() - new Date().getTime();
					var remainingDays = Math.floor(deltaSec/(1000*60*60*24));
					$("#trialNotice").html("<strong>Hinweis</strong><br>Ihre Testphase endet in " + remainingDays + " Tagen.").show();
				}

				var s = "";

                s += '<h5 class="text-primary">Branding</h5>';
                s += '<table class="table"><tbody>';
                s += '<tr><td class="col-xs-5 col-sm-4 col-md-3">Kontoname</td><td class="font-semibold">' + model.client.name + '</td></tr>';
				s += '<tr><td>Logo</td><td><img class="clientLogoPreview"></td></tr>';
                s += '</tbody></table>';

                s += '<h5 class="text-primary">Rechnungsadresse</h5>';
                s += '<table class="table"><tbody>';
                s += '<tr><td class="col-xs-5 col-sm-4 col-md-3">Firma</td><td class="font-semibold">' + model.client.company + '</td></tr>';
				s += '<tr><td>USt-IdNr.</td><td class="font-semibold">' + pg.handleNullString(model.client.vatId) + '</td></tr>';
				s += '<tr><td>Stra&szlig;e</td><td class="font-semibold">' + model.client.street + '</td></tr>';
				s += '<tr><td>Postleitzahl</td><td class="font-semibold">' + model.client.areaCode + '</td></tr>';
				s += '<tr><td>Ort</td><td class="font-semibold">' + model.client.city + '</td></tr>';
				s += '<tr><td>Telefon</td><td class="font-semibold">' + model.client.fon + '</td></tr>';
				s += '<tr><td>Land</td><td class="font-semibold">' + model.client.countryCode + '</td></tr>';
                s += '</tbody></table>';

				$("#clientViewTable").html(s);
				
				$("#clientViewBox").show();
				$("#clientEditorBox").hide();
			}

            $(".select2, .select2-multiple").select2();

            setTimeout(function(){
				that.updateLogoDisplay();
			}, 20);
		},
		
		//Logo-Anzeige aktualisieren
		updateLogoDisplay: function(logoData) {
			
			if (logoData === undefined)
				logoData = model.client.logoData || constants.UPKEEPR_LOGO;
			
			if (logoData) {
				$("#clientBox #uploadButtonBox").hide();
				$("#clientBox .clientLogoPreview").attr("src", logoData);
				$("#clientBox #btnRemoveLogo").show();
			}
			else {
				$("#clientBox #uploadButtonBox").show();
				$("#clientBox .clientLogoPreview").attr("src", constants.UPKEEPR_LOGO);
				$("#clientBox #btnRemoveLogo").hide();
			}
			
		},
		
		//Speichern vorbereiten
		initSaveClient: function() {
			
			var that = this;
			
			var formClient = $('#formClientEdit');
			var errorClient = $('.alert-danger', formClient);
			
			//validation rules
			formClient.validate({
				errorElement: 'span', //default input error message container
				errorClass: 'help-block help-block-error', // default input error message class
				focusInvalid: true, // do not focus the last invalid input
				ignore: "",  // validate all fields including form hidden input
				messages: {},
				rules: {
					name: {
						required: true,
						minlength: 3
					},
					company: {
						required: true,
						minlength: 3
					},
					street: {
						required: true,
						minlength: 3
					},
					areaCode: {
						required: true,
						minlength: 3
					},
					city: {
						required: true,
						minlength: 3
					},
					fon: {
						required: true,
						minlength: 3
					},
					countryCode: {
						required: true
					}
				},
				
				invalidHandler: function (event, validator) { //display error alert on form submit
					//success1.hide();
					errorClient.show();
					viewManager.scrollToElement(errorClient, -200);
					viewManager.updateButtons();
				},
				
				highlight: function (element) { // hightlight error inputs
					$(element)
						.closest('.form-group').addClass('has-error'); // set error class to the control group
				},
				
				unhighlight: function (element) { // revert the change done by hightlight
					$(element)
						.closest('.form-group').removeClass('has-error'); // set error class to the control group
				},
				
				success: function (label) {
					label
						.closest('.form-group').removeClass('has-error'); // set success class to the control group
				}
			});
			
			//Validierung ausführen
			if (formClient.valid())
				that.execSaveClient(formClient, errorClient);
			
			return false;
		},
		execSaveClient: function(formClient, errorClient){
			
			var that = clientManager;
			
			//etwas geändert?
			if (!model.isCurFormDirty){
				viewManager.showNotification("Es wurden keine Änderungen festgestellt.");
				viewManager.updateButtons();
				return false;
			}
			
			//check: logo?
			var logoData = $("#clientBox .clientLogoPreview").attr("src");
			if (!logoData) {
				$("#logoEditBox").addClass("has-error");
				viewManager.updateButtons();
				return;
			}
			
			//blocking
			if (!viewManager.setButtonBlocking($("#btnSaveClient")))
				return false;
			
			//all good
			errorClient.hide();
			
			var c = _.cloneDeep(model.client);
			c.logoData = logoData;
			c.logoUrl = that.logoUrl;
			c.name = pg.validateInput($("#clientBox #name").val(), 255);
			c.company = pg.validateInput($("#clientBox #company").val(), 255);
			c.vatId = pg.validateInput($("#clientBox #vatId").val(), 255);
			c.street = pg.validateInput($("#clientBox #street").val(), 255);
			c.areaCode = pg.validateInput($("#clientBox #areaCode").val(), 255);
			c.city = pg.validateInput($("#clientBox #city").val(), 255);
			c.fon = pg.validateInput($("#clientBox #fon").val(), 255);
			c.countryCode = $("#clientBox #countryCode").val();
			
			//save
			dataManager.saveClient(c, function (cNew) {
				
				model.client = cNew;
				
				viewManager.showNotification("Konto erfolgreich aktualisiert.", constants.NOTIFICATION_SUCCESS);
				
				//auch im Menü aktualisieren!
				viewManager.updateClientDisplay();
				viewManager.updateButtons();
				viewManager.clearFormDirty();
				
				that.cleanUploads(true);
			});
			
		},
		
		//-------------------------------------------------------------
		
		//Upload komplett
		onClientLogoUploaded: function(file) {
			
			var that = clientManager;
			
			//use thumb
			var url = pg.replace(file.url, "/masters/", "/thumbs/");
			that.logoUrl = url;
			
			//vorheriges später löschen
			that.deleteFiles.push(that.logoUrl);
			
			//base64 ermitteln
			//https://stackoverflow.com/questions/1095102/how-do-i-load-binary-image-data-using-javascript-and-xmlhttprequest
			//https://stackoverflow.com/questions/246801/how-can-you-encode-a-string-to-base64-in-javascript
			//pg.toDataUrl(constants.API_RAW + "/" + url, function (base64Img) {
			pg.toDataUrl(Attachment.getAccessUrlByPath(url, true), function (base64Img) {
				that.updateLogoDisplay(base64Img);
				viewManager.setFormDirty();
			});
		},

		cleanUploads: function(isSaveOperation) {
			
			var that = this,
				i;
			
			if (isSaveOperation){
				for (i=0; i<that.deleteFiles.length; i++)
					//nicht das aktuelle!
					if (that.deleteFiles[i] !== that.logoUrl)
						dataManager.deleteAttachment(that.deleteFiles[i]);
			}
			else{
				//das erste doch behalten
				for (i=1; i<that.deleteFiles.length; i++)
					dataManager.deleteAttachment(that.deleteFiles[i]);
			}
			
			that.deleteFiles = [];
			
		}
	};
	
	window.clientManager = clientManager;
}());