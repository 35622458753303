(function (){
	
	//--------------------------------------------------------------
	//Einstellungen verwalten
	//--------------------------------------------------------------
	
	/*global viewManager:true, dataManager:true, UserRole:true, authManager:true*/
	
	'use strict';
	
	var adminSettingsManager = {
		
		//Anzeige
		showSettings: function (callbackComplete) {
			
			var that = this;
			
			viewManager.setSelectedMenuItem('menuSettings', 'menuAdmin');
			
			$("#content").html(window.templates['adminSettings.html']);
			setTimeout(function () {
				
				var mayChangeProject = false;
				if (authManager.hasPermission(UserRole.PERMISSION_SYSTEM)){
					mayChangeProject = true;
				}
				
				if (!mayChangeProject){
					$("#adminSettingsBox .adminFeature").hide();
				}
				else {
					$("#adminSettingsBox .adminFeature").show();
					$("#btnResetProject").on("click", that.resetProject);
					$("#btnCloneProjectStructureOnly").on("click", that.cloneProjectStructureOnly);

					//Demo-Vorlage?
					if (model.client.type === constants.CLIENT_TYPE_TEMPLATE) {
						$("#cloneAsDemoBox").show();
						$("#btnCloneProjectAllData").on("click", that.cloneProjectAllData);
					}
					else{
						$("#cloneAsDemoBox").hide();
					}
				}
				
				//handler
				/*$("#btnBackSettings").off("click").on("click", function () {
					window.history.back();
				});*/

				if (callbackComplete)
					callbackComplete();

			}, constants.TEMPLATE_DELAY);
		},
		
		//Projekt zurücksetzen
		resetProject: function(){
			
			pg.confirmMsg("Reset", "Möchten Sie wirklich die Datenbank für dieses Projekt zurücksetzen?", function(e) {
				
				if (e) {
					dataManager.resetProject(function(){
						$("#btnResetProject").hide();
						$("#lblResetProjectComplete").show();
					});
				}
			});
		},
		
		//Projekt duplizieren (nur Struktur)
		cloneProjectStructureOnly: function(){
			
			pg.promptMsg("Account-Struktur duplizieren", "Bitte geben Sie den Namen des neuen Accounts an", function(result) {
				
				if (result) {
					dataManager.cloneProject(result, constants.CLIENT_TYPE_CUSTOMER, function(newAccount){
						pg.showMsg("Das Projekt wurde dupliziert -> " + newAccount.name + " (ID " + newAccount.id + ")");
					});
				}
			});
		},

		//Projekt duplizieren
		cloneProjectAllData: function(){

			pg.promptMsg("Account komplett duplizieren", "Bitte geben Sie den Namen des neuen Accounts an", function(result) {

				if (result) {
					dataManager.cloneProject(result, constants.CLIENT_TYPE_DEMO, function(newAccount){
						pg.showMsg("Das Projekt wurde dupliziert -> " + newAccount.name + " (ID " + newAccount.id + ")");
					});
				}
			});
		}
	};
	
	window.adminSettingsManager = adminSettingsManager;
}());