(function () {
		
		//--------------------------------------------------------------
		//Nachfolger finden (infolge geänderter Berechtigungen, etc.)
		//--------------------------------------------------------------
	
		/*global
		 constants:true,
		 UserGroup:true,
		 Task:true,
		 Request:true,
		 RequestState:true,
		 User:true,
		 viewManager:true,
		 Q:true
		 */
		
		'use strict';
		
		var successorManager = {
			
			ACTION_TYPE_REQUEST_OWNER: 0,
			ACTION_TYPE_REQUEST_DECIDER: 1,
			ACTION_TYPE_REQUEST_RESPONSIBLE: 2,
			
			//high-level Funktion, prüft alles
			checkSuccessor: function (u, callback) {
				
				var that = this;
				
				//------------------------------------------------------------------------------------------------------------------

				//vom Benutzer verantwortete Aufträge
				var responsibleTasks = Task.getResponsibleTasks(u.id);

				//hole Aufträge, die bisher dem User/Usergroup zugeordnet waren und die ohne ihn/sie nicht mehr auführbar sind
				var userTasks = Task.getExclusiveUserGroupTasks(u.singleUserGroupId);

				var ownedRequests = Request.getRequestsByUser({
					userId: u.id,
					ownerRequests: true,
					deciderRequests: false
				});
				var deciderRequests = Request.getRequestsByUser({
					userId: u.id,
					ownerRequests: false,
					deciderRequests: true
				});
				var responsibleRequests = Request.getResponsibleRequestsByUser(u.id);
				
				//erledigte Requests ignorieren
				ownedRequests = _.filter(ownedRequests, function(r){
					var rs = r.getCurrentState();
					return (rs.status !== RequestState.STATUS_COMPLETED);
				});
				deciderRequests = _.filter(deciderRequests, function(r){
					var rs = r.getCurrentState();
					return (rs.status !== RequestState.STATUS_COMPLETED);
				});
				responsibleRequests = _.filter(responsibleRequests, function(r){
					var rs = r.getCurrentState();
					return (rs.status !== RequestState.STATUS_COMPLETED);
				});
				
				//über Promises lösen
				var promises = [];

				//---------------------------
				//Verantwortung für Aufträge
				if (responsibleTasks.length) {
					
					var def1 = Q.defer();
					promises.push(def1.promise);
					
					//User muss angeben, wer die Aufträge des Users übernimmt
					that.getTaskResponsibilitySuccessor(responsibleTasks, u, function (uNew) {
						if (uNew)
							def1.resolve();
						else
							def1.reject();
					});
				}
				
				//---------------------------
				//Bearbeitung von Aufträgen
				if (userTasks.length) {
					
					var def2 = Q.defer();
					promises.push(def2.promise);
					
					//User muss angeben, wer die Aufträge des Users übernimmt
					that.getTaskExecutorSuccessor(userTasks, u, false, function (uNew) {
						if (uNew)
							def2.resolve();
						else
							def2.reject();
					});
				}
				
				//---------------------------
				//Antragsteller von Anträgen
				if (ownedRequests.length) {
					
					var def3 = Q.defer();
					promises.push(def3.promise);
					
					//User muss angeben, wer die Aufträge des Users übernimmt
					that.getRequestSuccessor(ownedRequests, u, successorManager.ACTION_TYPE_REQUEST_OWNER, function (uNew) {
						if (uNew)
							def3.resolve();
						else
							def3.reject();
					});
				}
				
				//---------------------------
				//Entscheider von Anträgen
				if (deciderRequests.length) {
					
					var def4 = Q.defer();
					promises.push(def4.promise);
					
					//User muss angeben, wer die Aufträge des Users übernimmt
					that.getRequestSuccessor(deciderRequests, u, successorManager.ACTION_TYPE_REQUEST_DECIDER, function (uNew) {
						if (uNew)
							def4.resolve();
						else
							def4.reject();
					});
				}
				
				//---------------------------
				//Verantwortung für Anträge
				if (responsibleRequests.length) {
					
					var def5 = Q.defer();
					promises.push(def5.promise);
					
					//User muss angeben, wer die Anträge des Users übernimmt
					that.getRequestSuccessor(responsibleRequests, u, successorManager.ACTION_TYPE_REQUEST_RESPONSIBLE, function (uNew) {
						if (uNew)
							def5.resolve();
						else
							def5.reject();
					});
				}
				
				//---------------------------
				//fertig
				if (promises.length) {
					Q.all(promises).then(function () {

						//nur beim Löschen #1188
						if (u.status === constants.STATUS_OBJECT_DELETED) {
							//jetzt diejenigen Aufträge markieren, die nicht-exklusiv bzgl. des Benutzers sind
							u.cleanedUpTasks = _.map(Task.getNonExclusiveUserGroupTasks(u.singleUserGroupId), function (t) {
								return t.taskId;
							});
						}

						if (callback)
							callback(u);
					});
				}
				else {
					//nix zu tun
					if (callback)
						callback(u);
				}
				
			},
			
			//------------------------------------------------------------------------------------------------------------------
			
			//Dialog laden
			createSuccessorDialog: function () {
				
				$("body").append(window.templates['successorDialog.html']);
				
			},
			
			//neue Instanz des Dialogs holen
			//Vorsicht: mehrere parallele Instanzen möglich
			getSuccessorDialog: function () {
				
				var result = null;
				$(".userGroupModal").each(function (i, o) {
					var el = $(o);
					if (!el.attr("id")) {
						el.attr("id", "successor_" + Math.floor(10000 * Math.random()));
						result = el;
						return false;
					}
				});
				return result;
			},
			
			//Instanz schließen
			closeSuccessorDialog: function (el) {
				el.modal("hide");
				setTimeout(function () {
					el.remove();
				}, 500);
			},
			
			//------------------------------------------------------------------------------------------------------------------
			
			//Nachfolger für Auftrags-Verantwortlichen finden
			getTaskResponsibilitySuccessor: function (tasks, responsibleUser, callback) {
				
				var that = this;
				
				var i,
					t;
				
				//wenn inaktiv/gelöscht -> alle übergeben
				if (responsibleUser.status === constants.STATUS_OBJECT_ACTIVE){
					
					var problematicTasks = [];
					
					//gibt es überhaupt Aufträge, die nun nicht mehr verantwortet werden können?
					for(i = 0; i<tasks.length; i++) {
						t = tasks[i];
						
						if (!responsibleUser.canBeResponsibleForTask(t))
							problematicTasks.push(t);
					}
					
					if (problematicTasks.length === 0) {
						if (callback)
							callback(responsibleUser);
						return;
					}
					
					tasks = problematicTasks;
				}
					
				//welche Benutzer haben das Recht, alle Aufträge zu bearbeiten
				var users = User.getActiveUsers();
				for(i = 0; i < tasks.length; i++) {
					
					t = tasks[i];
					for(var j = users.length - 1; j >= 0; j--) {
						var u = users[j];
						if (u.id === responsibleUser.id) {
							users.splice(j, 1);
							continue;
						}
						if (!u.canEditTask(t)) {
							users.splice(j, 1);
						}
					}
				}
				
				if (users.length === 0) {
					var tName = pg.getStemmedName(tasks.length, "Auftrag", "Aufträge");
					pg.showMsg("Durch die Änderungen müssen für " + tasks.length + " " + tName + " neue Verantwortliche gefunden werden. Es gibt aber keine möglichen Verantwortlichen, die über die erforderlichen Berechtigungen verfügen. Die Änderungen können daher nicht durchgeführt werden.");
					viewManager.updateButtons();
					if (callback)
						callback(null);
					return false;
				}
				
				that.createSuccessorDialog();
				setTimeout(function () {
					
					//Vorsicht: mehrere parallele Instanzen möglich, eine unverwendete holen
					var el = that.getSuccessorDialog();
					
					//Beschreibung
					var desc = "Bitte geben Sie an, wer diejenigen " + tasks.length + " Aufträge verantworten soll, welche bisher von " + responsibleUser.getName() + " verantwortet wurden";
					if (tasks.length === 1)
						desc = "Bitte geben Sie an, wer denjenigen Auftrag verantworten soll, der bisher von " + responsibleUser.getName() + " verantwortet wurde";
					$(".modal-title", el).html("Aufträge übergeben");
					$(".btnUserGroupModalOk", el).html("Aufträge übergeben");
					$(".userGroupModalDesc", el).html(desc);
					$(".lblUserGroupModalSelect", el).html("Benutzer:");
					
					var s = "";
					for(i = 0; i < users.length; i++)
						s += '<option value="' + users[i].id + '">' + users[i].getName() + '</option>';
					$(".userGroupModalSelect", el).html(s).select2();
					
					//Anzeige Modal
					el.modal({
						backdrop: "static",
						keyboard: false
					});
					$(".btnUserGroupModalCancel", el).off("click").on("click", function () {
						viewManager.updateButtons();
						$(".userGroupModal", el).modal('hide');
						
						that.closeSuccessorDialog(el);
						if (callback)
							callback(null);
					});
					$(".btnUserGroupModalOk", el).off("click").on("click", function () {
						
						$(".userGroupModal", el).modal('hide');
						
						//merken
						var successorUserId = parseInt($(".userGroupModalSelect", el).val());
						
						//all good!
						responsibleUser.successorResponsibleUserId = successorUserId;
						//betroffene Gruppen merken
						responsibleUser.abandonedResponsibleTasks = [];
						for(i = 0; i < tasks.length; i++) {
							//merken
							responsibleUser.abandonedResponsibleTasks.push(tasks[i].taskId);
							//und lokal aktualisieren (überschreiben)
							var ts = tasks[i].getCurrentState();
							ts.responsibleId = successorUserId;
						}
						
						that.closeSuccessorDialog(el);
						if (callback)
							callback(responsibleUser);
					});
				}, constants.TEMPLATE_DELAY);
				
			},
			
			//------------------------------------------------------------------------------------------------------------------
			
			//Nachfolger für "assigned to..." (Bearbeiter)
			getTaskExecutorSuccessor: function (tasks, assignedUser, assignedUserIsUserGroup, callback) {
				
				var that = this;
				
				var i,
					j,
					t;
				
				//initial keine Simulation annehmen, sondern mit dem realen Datenmodell arbeiten
				var simUser = null;

				//falls für einzelnen aktiven User durchgeführt:
				if (!assignedUserIsUserGroup && assignedUser.status === constants.STATUS_OBJECT_ACTIVE) {
					
					//Ergebnis simulieren (weil der veränderte Benutzer sonst in den Checks in seiner ursprünglichen Form verwendet würde):
					simUser = assignedUser;
					
					//gibt es überhaupt Aufträge, die nun nicht mehr ausführbar sind?
					var unexecuteableTasks = [];
					var uGroups = assignedUser.getUserGroups();
					for(i = 0; i < tasks.length; i++) {
						t = tasks[i];
						var hasGroup = false;
						for(j = 0; j < uGroups.length; j++) {
							
							//simulate!
							if (uGroups[j].canExecuteTask(t, simUser)) {
								hasGroup = true;
								break;
							}
						}
						if (!hasGroup)
							unexecuteableTasks.push(t);
					}
					
					if (unexecuteableTasks.length === 0) {
						if (callback)
							callback(assignedUser);
						return;
					}
					else {
						
						//nur noch über diese Aufträge sprechen!
						tasks = unexecuteableTasks;
					}
				}
				
				//andere Gruppen
				var otherUg;
				if (assignedUserIsUserGroup)
					otherUg = UserGroup.getActiveUserGroups(null, assignedUser.id, true, -1, [], simUser);
				else
					otherUg = UserGroup.getActiveUserGroups(assignedUser.id, null, true, -1, [], simUser);
				
				//filtern: Alle Usergroups müssen für jeden Auftrag in der Lage sein diesen auszuführen
				for(i = otherUg.length - 1; i >= 0; i--) {
					
					var ug2 = otherUg[i];
					
					var hasRequiredPermissions = true;
					for(j = 0; j < tasks.length; j++) {
						t = tasks[j];
						
						if (!ug2.canExecuteTask(t, simUser)) {
							hasRequiredPermissions = false;
							break;
						}
					}
					
					if (!hasRequiredPermissions) {
						otherUg.splice(i, 1);
					}
				}
				
				//------------------------------
				
				if (otherUg.length === 0) {
					var tName = pg.getStemmedName(tasks.length, "Auftrag", "Aufträge");
					pg.showMsg("Durch die Änderungen müssen für " + tasks.length + " " + tName + " neue Bearbeitungs-Verantwortliche gefunden werden. Es gibt aber keine möglichen Verantwortlichen, die über die erforderlichen Berechtigungen verfügen. Die Änderungen können daher nicht durchgeführt werden.");
					viewManager.updateButtons();
					if (callback)
						callback(null);
					return false;
				}
				
				that.createSuccessorDialog();
				setTimeout(function () {
					
					//Vorsicht: mehrere parallele Instanzen möglich, eine unverwendete holen
					var el = that.getSuccessorDialog();
					
					//Beschreibung
					var desc = "Bitte geben Sie an, wer diejenigen " + tasks.length + " Aufträge übernehmen soll, welche bisher " + assignedUser.getName() + " zugeordnet waren";
					if (tasks.length === 1)
						desc = "Bitte geben Sie an, wer denjenigen Auftrag übernehmen soll, der bisher " + assignedUser.getName() + " zugeordnet war";
					$(".modal-title", el).html("Aufträge übergeben");
					$(".btnUserGroupModalOk", el).html("Aufträge übergeben");
					$(".userGroupModalDesc", el).html(desc);
					$(".lblUserGroupModalSelect", el).html("Benutzer/Team:");
					
					var s = "";
					for(i = 0; i < otherUg.length; i++)
						s += '<option value="' + otherUg[i].id + '">' + otherUg[i].name + '</option>';
					$(".userGroupModalSelect", el).html(s).select2();
					
					//Anzeige Modal
					el.modal({
						backdrop: "static",
						keyboard: false
					});
					$(".btnUserGroupModalCancel", el).off("click").on("click", function () {
						viewManager.updateButtons();
						$(".userGroupModal", el).modal('hide');
						that.closeSuccessorDialog(el);
						if (callback)
							callback(null);
					});
					$(".btnUserGroupModalOk", el).off("click").on("click", function () {
						
						$(".userGroupModal", el).modal('hide');
						
						//merken
						var successorUserGroupId = parseInt($(".userGroupModalSelect", el).val());
						
						//all good!
						assignedUser.successorUserGroupId = successorUserGroupId;
						//betroffene Gruppen merken
						assignedUser.abandonedTasks = [];
						for(i = 0; i < tasks.length; i++) {
							//merken
							assignedUser.abandonedTasks.push(tasks[i].taskId);
							//und lokal aktualisieren (überschreiben)
							var ts = tasks[i].getCurrentState();
							ts.assignToUserGroup(successorUserGroupId);
						}

						//jetzt diejenigen Aufträge markieren, die nicht-exklusiv bzgl. des Benutzers sind
						assignedUser.cleanedUpTasks = _.map(Task.getNonExclusiveUserGroupTasks(assignedUser.singleUserGroupId), function(tNew){
							return tNew.taskId;
						});

						that.closeSuccessorDialog(el);
						if (callback)
							callback(assignedUser);
					});
				}, constants.TEMPLATE_DELAY);
			},
			
			//------------------------------------------------------------------------------------------------------------------
			//Nachfolge für Antragsteller/Entscheider/Verantwortlicher von Anträgen (je nach ActionType)
			
			getRequestSuccessor: function (requests, responsibleUser, actionType, callback) {
				
				var that = this;
				
				var i,
					t;

				//wenn inaktiv/gelöscht -> alle übergeben
				if (responsibleUser.status === constants.STATUS_OBJECT_ACTIVE){
					
					var problematicTasks = [];
					
					//gibt es überhaupt Aufträge, die nun nicht mehr verantwortet werden können?
					for(i = 0; i<requests.length; i++) {
						t = requests[i];
						
						switch (actionType) {
							case successorManager.ACTION_TYPE_REQUEST_OWNER:
								if (!responsibleUser.canEditRequest(t))
									problematicTasks.push(t);
								break;
							case successorManager.ACTION_TYPE_REQUEST_DECIDER:
								if (!responsibleUser.canExecuteRequest(t))
									problematicTasks.push(t);
								break;
							case successorManager.ACTION_TYPE_REQUEST_RESPONSIBLE:
								if (!responsibleUser.canConvertRequest(t))
									problematicTasks.push(t);
								break;
						}
					}
					
					if (problematicTasks.length === 0) {
						if (callback)
							callback(responsibleUser);
						return;
					}
					requests = problematicTasks;
					
				}
				
				//welche Benutzer haben das Recht für alle Anträge
				var users = User.getActiveUsers();
				for(i = 0; i < requests.length; i++) {
					
					t = requests[i];
					for(var j = users.length - 1; j >= 0; j--) {
						var u = users[j];
						//nicht der bisherige
						if (u.id === responsibleUser.id) {
							users.splice(j, 1);
							continue;
						}
						
						switch (actionType) {
							case successorManager.ACTION_TYPE_REQUEST_OWNER:
								if (!u.canEditRequest(t)) {
									users.splice(j, 1);
								}
								break;
							case successorManager.ACTION_TYPE_REQUEST_DECIDER:
								if (!u.canExecuteRequest(t)) {
									users.splice(j, 1);
								}
								break;
							case successorManager.ACTION_TYPE_REQUEST_RESPONSIBLE:
								if (!u.canConvertRequest(t)) {
									users.splice(j, 1);
								}
								break;
						}
					}
				}
				
				if (users.length === 0) {
					var tName = pg.getStemmedName(requests.length, "Antrag", "Anträge");
					var msg = "";
					switch (actionType) {
						case successorManager.ACTION_TYPE_REQUEST_OWNER:
							msg = "Durch die Änderungen muss für " + requests.length + " " + tName + " ein neuer Antragsteller gefunden werden. Es gibt aber keine möglichen Personen, die über die erforderlichen Berechtigungen verfügen. Die Änderungen können daher nicht durchgeführt werden.";
							break;
						case successorManager.ACTION_TYPE_REQUEST_DECIDER:
							msg = "Durch die Änderungen muss für " + requests.length + " " + tName + " einer neuer Entscheider gefunden werden. Es gibt aber keine möglichen Personen, die über die erforderlichen Berechtigungen verfügen. Die Änderungen können daher nicht durchgeführt werden.";
							break;
						case successorManager.ACTION_TYPE_REQUEST_RESPONSIBLE:
							msg = "Durch die Änderungen muss für " + requests.length + " " + tName + " einer neuer Verantwortlicher gefunden werden. Es gibt aber keine möglichen Personen, die über die erforderlichen Berechtigungen verfügen. Die Änderungen können daher nicht durchgeführt werden.";
							break;
					}
					pg.showMsg(msg);
					viewManager.updateButtons();
					if (callback)
						callback(null);
					return false;
				}
				
				that.createSuccessorDialog();
				setTimeout(function () {
					
					//Vorsicht: mehrere parallele Instanzen möglich, eine unverwendete holen
					var el = that.getSuccessorDialog();
					
					//Beschreibung
					var desc = "";
					switch (actionType) {
						case successorManager.ACTION_TYPE_REQUEST_OWNER:
							desc = "Bitte geben Sie an, wer diejenigen " + requests.length + " Anträge übernehmen soll, welche von " + responsibleUser.getName() + " gestellt wurden";
							if (requests.length === 1)
								desc = "Bitte geben Sie an, wer denjenigen Antrag übernehmen soll, der von " + responsibleUser.getName() + " gestellt wurde";
							break;
						case successorManager.ACTION_TYPE_REQUEST_DECIDER:
							desc = "Bitte geben Sie an, wer diejenigen " + requests.length + " Anträge entscheiden soll, welche bisher von " + responsibleUser.getName() + " hätten entschieden werden sollen";
							if (requests.length === 1)
								desc = "Bitte geben Sie an, wer denjenigen Antrag übernehmen soll, der bisher von " + responsibleUser.getName() + " hätte entschieden werden sollen";
							break;
						case successorManager.ACTION_TYPE_REQUEST_RESPONSIBLE:
							desc = "Bitte geben Sie an, wer diejenigen " + requests.length + " Anträge verantworten soll, welche bisher von " + responsibleUser.getName() + " verantwortet wurden";
							if (requests.length === 1)
								desc = "Bitte geben Sie an, wer denjenigen Antrag übernehmen soll, der bisher von " + responsibleUser.getName() + " verantwortet wurde";
							break;
					}
					
					$(".modal-title", el).html("Anträge übergeben");
					$(".btnUserGroupModalOk", el).html("Anträge übergeben");
					$(".userGroupModalDesc", el).html(desc);
					$(".lblUserGroupModalSelect", el).html("Benutzer:");
					
					var s = "";
					for(i = 0; i < users.length; i++)
						s += '<option value="' + users[i].id + '">' + users[i].getName() + '</option>';
					$(".userGroupModalSelect", el).html(s).select2();
					
					//Anzeige Modal
					el.modal({
						backdrop: "static",
						keyboard: false
					});
					$(".btnUserGroupModalCancel", el).off("click").on("click", function () {
						viewManager.updateButtons();
						$(".userGroupModal", el).modal('hide');
						that.closeSuccessorDialog(el);
						if (callback)
							callback(null);
					});
					$(".btnUserGroupModalOk", el).off("click").on("click", function () {
						
						$(".userGroupModal", el).modal('hide');
						
						//merken
						var successorUserId = parseInt($(".userGroupModalSelect", el).val());
						
						//all good!
						var ts;
						switch (actionType) {
							case successorManager.ACTION_TYPE_REQUEST_OWNER:
								responsibleUser.successorRequestOwnerUserId = successorUserId;
								//betroffene Gruppen merken
								responsibleUser.abandonedOwnerRequests = [];
								for(i = 0; i < requests.length; i++) {
									//merken
									responsibleUser.abandonedOwnerRequests.push(requests[i].requestId);
									//und lokal aktualisieren (überschreiben)
									requests[i].ownerId = successorUserId;
								}
								break;
								
							case successorManager.ACTION_TYPE_REQUEST_DECIDER:
								responsibleUser.successorRequestDeciderUserId = successorUserId;
								//betroffene Gruppen merken
								responsibleUser.abandonedDeciderRequests = [];
								for(i = 0; i < requests.length; i++) {
									//merken
									responsibleUser.abandonedDeciderRequests.push(requests[i].requestId);
									//und lokal aktualisieren (überschreiben)
									ts = requests[i].getCurrentState();
									ts.deciderId = successorUserId;
								}
								break;
							
							case successorManager.ACTION_TYPE_REQUEST_RESPONSIBLE:
								responsibleUser.successorRequestResponsibleUserId = successorUserId;
								//betroffene Gruppen merken
								responsibleUser.abandonedResponsibleRequests = [];
								for(i = 0; i < requests.length; i++) {
									//merken
									responsibleUser.abandonedResponsibleRequests.push(requests[i].requestId);
									//und lokal aktualisieren (überschreiben)
									ts = requests[i].getCurrentState();
									ts.responsibleId = successorUserId;
								}
								break;
						}
						
						that.closeSuccessorDialog(el);
						if (callback)
							callback(responsibleUser);
					});
					
				}, constants.TEMPLATE_DELAY);
				
			},
			
			//-----------------------------------------------------------------------------------------
			
			//Client-Owner
			
			requestClientOwner: function(exceptUserId, callback){
				
				var that = this;
				
				//Admins anzeigen (nur temporär via Rollen-Namen!)
				var adminUsers = User.getActiveUsers("ADMIN");
				if (exceptUserId){
					adminUsers = adminUsers.filter(function(u){
						return (u.id !== exceptUserId);
					});
				}
				//sortieren nach Name (ignore case)
				adminUsers.sort(function (a, b) {
					var an = a.getName().toLowerCase();
					var bn = b.getName().toLowerCase();
					return ((an < bn) ? -1 : ((an > bn) ? 1 : 0));
				});
				
				that.createSuccessorDialog();
				setTimeout(function () {
					
					//Vorsicht: mehrere parallele Instanzen möglich, eine unverwendete holen
					var el = that.getSuccessorDialog();
					$(".modal-title", el).html("Accountinhaberschaft übergeben");
					$(".btnUserGroupModalOk", el).html("Accountinhaberschaft übergeben");
					$(".userGroupModalDesc", el).html("Bitte wählen Sie einen Benutzer als neuen Accountinhaber aus.");
					$(".lblUserGroupModalSelect", el).html("Benutzer:");
					
					var s = "";
					for(var i = 0; i < adminUsers.length; i++)
						s += '<option value="' + adminUsers[i].id + '">' + adminUsers[i].getName() + '</option>';
					$(".userGroupModalSelect", el).html(s).select2();
					
					//Anzeige Modal
					el.modal({
						backdrop: "static",
						keyboard: false
					});
					$(".btnUserGroupModalCancel", el).off("click").on("click", function () {
						viewManager.updateButtons();
						$(".userGroupModal", el).modal('hide');
						that.closeSuccessorDialog(el);
						if (callback)
							callback(null);
					});
					$(".btnUserGroupModalOk", el).off("click").on("click", function () {
						
						$(".userGroupModal", el).modal('hide');
						
						//merken
						var successorUserId = parseInt($(".userGroupModalSelect", el).val());
						
						//all good!
						that.closeSuccessorDialog(el);
						if (callback)
							callback(successorUserId);
					});
					
				}, constants.TEMPLATE_DELAY);
				
			}
			
		};
		
		window.successorManager = successorManager;
	}()
)
;


