(function () {
	
	//----------------------------------------------------------------
	//Label eines Auftrages
	//----------------------------------------------------------------
	
	'use strict';
	
	var TaskLabel = {
		
		taskLabelPrototype: {
			
			fromObj: function (o) {
				this.id = parseInt(o.id, 10);
				this.createdOn = pg.parseDate(o.createdOn || new Date());
				this.createdBy = parseInt(o.createdBy, 10);
				this.changedOn = pg.parseDate(o.changedOn || new Date());
				this.changedBy = parseInt(o.changedBy, 10);

				this.clientId = parseInt(o.clientId, 10);
				this.name = o.name;
				//noinspection JSUnresolvedVariable
				this.commentRequired = parseInt(o.comment_required || o.commentRequired, 10);
				this.colorFg = o.colorFg;
				this.colorBg = o.colorBg;
				this.pattern = o.pattern;

				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.clientId = this.clientId;
				o.name = this.name;
				o.commentRequired = this.commentRequired;
				o.colorFg = this.colorFg;
				o.colorBg = this.colorBg;
				o.pattern = this.pattern;

				
				return o;
			}
		},
		
		createTaskLabel: function () {
			return Object.create(TaskLabel.taskLabelPrototype);
		}
	};
	
	window.TaskLabel = TaskLabel;
}());