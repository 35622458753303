(function () {
	
	//----------------------------------------------------------------
	//Objekttyp
	//----------------------------------------------------------------
	
    'use strict';

    var LocationType = {

        LOCATION_TYPE_ALL: 0,

        locationTypePrototype: {

            fromObj: function (t) {
                this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				this.clientId = parseInt(t.clientId, 10);
                this.name = t.name;
                this.abbrev = t.abbrev;
                return this;
            },

            serialize: function () {

                var o = {};

                o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.clientId = this.clientId;
                o.name = this.name;

                return o;
            }

        },
	
	    //----------------------------------------------------------------

	    //Typ "Alle" erstellen
        getLocationTypeObjAll: function(){
            return LocationType.createLocationType().fromObj({
                id: LocationType.LOCATION_TYPE_ALL,
                clientId: model.curClientId,
                name: "Alle",
                abbrev: "Alle"
            });
        },
	
	    //----------------------------------------------------------------

	    //einen bestimmten Typ holen
        getLocationType: function (type) {
            type = parseInt(type);

            if (type === LocationType.LOCATION_TYPE_ALL)
                return LocationType.getLocationTypeObjAll();

            for (var i = 0; i < model.locationTypes.length; i++)
                if (model.locationTypes[i].id === type) {
                    return model.locationTypes[i];
                }
            return null;
        },
	
	    //----------------------------------------------------------------
	    
	    //alle holen
        getAllLocationTypes: function(includeAll){

            var arr = _.cloneDeep(model.locationTypes);
			//sortieren nach name ASC
			arr.sort(function (a, b) {
				var aName = a.name.toLowerCase();
				var bName = b.name.toLowerCase();
				return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
			});
            if (includeAll)
                arr.unshift(LocationType.getLocationTypeObjAll());
            return arr;
        },
	
	    //----------------------------------------------------------------

        createLocationType: function () {
            return Object.create(LocationType.locationTypePrototype);
        }
    };

    window.LocationType = LocationType;
}());

