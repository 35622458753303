(function () {
	
	//----------------------------------------------------------------
	//Kunde
	//----------------------------------------------------------------
	
	'use strict';
	
	var Client = {
		
		clientPrototype: {
			
			fromObj: function (t) {

				this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				this.logoData = t.logoData;
				this.logoUrl = t.logoUrl || "";
				this.name = pg.restoreDbString(t.name);

				this.company = pg.restoreDbString(t.company);
				this.vatId = pg.restoreDbString(t.vatId);
				this.street = pg.restoreDbString(t.street);
				this.areaCode = pg.restoreDbString(t.areaCode);
				this.city = pg.restoreDbString(t.city);
				this.fon = pg.restoreDbString(t.fon);
				this.countryCode = t.countryCode;

				//default: upkeepr logo
				if (!this.logoData)
					this.logoData = constants.UPKEEPR_LOGO;
				
				this.hasAutoArchive = parseInt(t.hasAutoArchive || 0);
				this.autoArchiveTasksPattern = pg.restoreDbString(t.autoArchiveTasksPattern || "");
				this.autoArchiveRequestsPattern = pg.restoreDbString(t.autoArchiveRequestsPattern || "");
				this.autoReleaseRequests = parseInt(t.autoReleaseRequests || 0);

				this.hasEmailReports = parseInt(t.hasEmailReports || 0);
				this.emailReportsPattern = pg.restoreDbString(t.emailReportsPattern || "");
				this.emailReportsRecipients = pg.restoreDbString(t.emailReportsRecipients || "");

				this.status = t.status ? +t.status : constants.STATUS_OBJECT_ACTIVE;
				this.ownerId = t.ownerId ? +t.ownerId : -1;
				this.validUntil = pg.parseDate(t.validUntil);
				this.type = t.type ? +t.type : constants.CLIENT_TYPE_CUSTOMER;
				this.latestActivity = t.latestActivity ? pg.parseDate(t.latestActivity) : null;
				
				return this;
			},
			
			serialize: function (skipLogo) {
				
				if (skipLogo === undefined)
					skipLogo = false;
				
				var o = {};
				
				if (!skipLogo)
					o.logoData = this.logoData;
				o.logoUrl = this.logoUrl;
				o.name = this.name;
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.company = this.company;
				o.vatId = this.vatId;
				o.street = this.street;
				o.areaCode = this.areaCode;
				o.city = this.city;
				o.fon = this.fon;
				o.countryCode = this.countryCode;
				
				o.hasAutoArchive = this.hasAutoArchive;
				o.autoArchiveTasksPattern = this.autoArchiveTasksPattern;
				o.autoArchiveRequestsPattern = this.autoArchiveRequestsPattern;
				o.autoReleaseRequests = this.autoReleaseRequests;

				o.hasEmailReports = this.hasEmailReports;
				o.emailReportsPattern = this.emailReportsPattern;
				o.emailReportsRecipients = this.emailReportsRecipients;

				o.type = this.type;
				o.latestActivity = this.latestActivity;
				o.status = this.status;
				o.ownerId = this.ownerId;
				o.validUntil = this.validUntil;
				
				return o;
			},
			
			//----------------------------------------------------------------
			
			hasModule: function(moduleId){
				if (!moduleId)
					return true;
				if (moduleId <= 0)
					return true;
				
				for (var i=0; i<model.clientModules.length; i++){
					var cm = model.clientModules[i];
					if (cm.moduleId === moduleId)
						return true;
				}
				
				return false;
			}
		},
		createClient: function () {
			return Object.create(Client.clientPrototype);
		}
	};
	
	window.Client = Client;
}());