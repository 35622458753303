(function () {
	
	//----------------------------------------------------------------
	//Benutzer
	//----------------------------------------------------------------
	
	/*global constants:true, UserRole:true, TaskType:true, LocationType:true, Location:true, authManager:true*/
	
	'use strict';
	
	var User = {
		
		userPrototype: {
			
			fromObj: function (t) {
				
				this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				this.clientId = parseInt(t.clientId, 10);
				this.firstName = pg.restoreDbString(t.firstName);
				this.lastName = pg.restoreDbString(t.lastName);
				this.status = parseInt(t.status, 10);
				this.singleUserGroupId = parseInt(t.singleUserGroupId, 10);
				this.email = pg.restoreDbString(t.email);
				this.role = parseInt(t.role, 10);
				this.isNew = (t.isNew) ? t.isNew : 0;
				
				this.locationTypes = "";
				if (t.locationTypes)
					this.locationTypes = pg.restoreDbString(t.locationTypes);
				this.updateLocationTypeIds();
				
				this.fon = "";
				if (t.fon)
					this.fon = pg.restoreDbString(t.fon);
				
				this.mustClearCache = 0;
				if (t.mustClearCache)
					this.mustClearCache = parseInt(t.mustClearCache);
				
				this.mustClearPending = 0;
				if (t.mustClearPending)
					this.mustClearPending = parseInt(t.mustClearPending);

				this.commands = t.commands ? t.commands : null;

				this.debugStatus = 0;
				if (t.debugStatus)
					this.debugStatus = parseInt(t.debugStatus);

				this.department = t.department || "";

				this.notificationTypes = [];
				this.type = t.type ? +t.type : constants.USER_TYPE_REGULAR;

				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.clientId = this.clientId;
				o.firstName = this.firstName;
				o.lastName = this.lastName;
				o.status = this.status;
				o.singleUserGroupId = this.singleUserGroupId;
				o.email = this.email;
				o.role = this.role;
				o.locationTypes = this.locationTypes;
				o.fon = this.fon;
				o.mustClearCache = this.mustClearCache;
				o.mustClearPending = this.mustClearPending;
				o.debugStatus = this.debugStatus;
				o.department = this.department;
				o.type = this.type;
				
				return o;
			},
			
			//----------------------------------------------

			//Aktivierung durchgeführt?
			isValid: function(){
				return this.status >= 0;
			},

			//----------------------------------------------

			isSystemAdmin: function(){
				var role = UserRole.getUserRole(this.role);
				return (role.name === "ROLE_SYSTEM");
			},
			
			//----------------------------------------------
			
			//zugeordnete Objekttypen deserialisieren
			updateLocationTypeIds: function(){
				this.locationTypeIds = [];
				var tt = pg.splitNonEmpty(this.locationTypes, "#");
				for (var i=0; i<tt.length; i++)
					this.locationTypeIds.push(parseInt(tt[i]));
			},
			
			
			//----------------------------------------------
			
			//möglichst vollen Usernamen bilden
			getName: function (shortVersion) {
				
				if (shortVersion === undefined)
					shortVersion = false;
				
				if (this.firstName && this.lastName) {
					if (shortVersion)
						return this.firstName.substring(0, 1) + ". " + this.lastName;
					else
						return this.firstName + " " + this.lastName;
				}
				else {
					if (this.lastName)
						return this.lastName;
					else
						return this.firstName;
				}
			},
			
			//----------------------------------------------
			
			//überhaupt ein Objekttyp zugeordnet?
			//mind. 1
			hasAnyLocationType: function(){
				return (this.locationTypeIds.length > 0);
			},
			//bestimmer Objekttyp zugeordnet?
			hasLocationType: function(locationTypeId){
				
				locationTypeId = parseInt(locationTypeId);
				for (var i=0; i<this.locationTypeIds.length; i++) {
					
					//all?
					if (this.locationTypeIds[i] === LocationType.LOCATION_TYPE_ALL)
						return true;
					
					if (this.locationTypeIds[i] === locationTypeId)
						return true;
				}
				return false;
			},
			//Berechtigung für alle?
			hasAllLocationTypes: function(){
				for (var i=0; i<this.locationTypeIds.length; i++) {
					if (this.locationTypeIds[i] === LocationType.LOCATION_TYPE_ALL)
						return true;
				}
				return false;
			},
			
			//----------------------------------------------
			
			//bestimmter Auftragstyp zugeordnet?
			hasTaskType: function(taskTypeId){
				taskTypeId = parseInt(taskTypeId);

				//ist es ein TaskType, der keinen Objekttyp erfordert?
				var tt = TaskType.getTaskType(taskTypeId);
				if (tt.locationTypeIds.length === 0)
					return true;

				for (var i=0; i<this.locationTypeIds.length; i++) {
					
					//special: 0 == "Alle"
					if (parseInt(this.locationTypeIds[i]) === 0)
						return true;
					
					var taskTypes = TaskType.getTaskTypesByLocationType(this.locationTypeIds[i]);
					for (var j=0; j<taskTypes.length; j++)
						if (taskTypes[j] === taskTypeId)
							return true;
				}
				return false;
			},
			
			//----------------------------------------------
			
			getRoleName: function () {
				return UserRole.getUserRole(this.role).name;
			},
			
			//----------------------------------------------
			
			getUserGroups: function () {
				var a = [];
				for (var i = 0; i < model.userGroups.length; i++) {
					var ug = model.userGroups[i];
					if (ug.status === constants.STATUS_OBJECT_ACTIVE)
						if (ug.contains(this.id))
							a.push(model.userGroups[i]);
				}
				return a;
			},
			
			//----------------------------------------------
			
			//darf dieser User bestimmte Locations resp. einen bestimmten Auftragstyp bearbeiten?
			hasEditPermissionsForLocations: function(taskTypeId, locationIds){
				//jedes Objekt
				for (var i=0; i<locationIds.length; i++) {
					
					var loc = Location.getLocation(locationIds[i]);
					
					var hasUserWithPermissions = false;
					
					//Rechte?
					var hasPermissions = authManager.hasUserPermission(this, UserRole.PERMISSION_TASK_EDIT_ALL, UserRole.PERMISSION_TASK_EDIT_OWN);
					if (!hasPermissions){
						if (authManager.hasUserPermission(this, UserRole.PERMISSION_TASK_EDIT_BY_TASK_TYPE)){
							if (this.hasTaskType(taskTypeId))
								hasPermissions = true;
						}
					}
					
					//Objekttyp?
					if (hasPermissions) {
						if (this.hasLocationType(loc.type)) {
							hasUserWithPermissions = true;
							break;
						}
					}
					
					if (!hasUserWithPermissions)
						return false;
				}
				
				return true;
			},
			
			//----------------------------------------------
			
			//Test: hat für jedes Objekt der User die entsprechenden Rechten zu bearbeiten?
			canEditTask: function(task){
				
				var locationIds = task.getLocationIds();
				return (this.hasEditPermissionsForLocations(task.taskTypeId, locationIds));
			},
			canExecuteTask: function(task){

				var hasPermissionAll = authManager.hasUserPermission(this, UserRole.PERMISSION_TASK_EXECUTE_ALL);
				var hasPermissionOwn = authManager.hasUserPermission(this, UserRole.PERMISSION_TASK_EXECUTE_OWN);
				var hasPermissionTaskType = false;

				var mayExecuteAssignment = hasPermissionAll || hasPermissionOwn;
				if (!mayExecuteAssignment) {
					if (authManager.hasUserPermission(this, UserRole.PERMISSION_TASK_EXECUTE_BY_TASK_TYPE))
						if (task)
							if (this.hasTaskType(task.taskTypeId)) {
								hasPermissionTaskType = true;
								mayExecuteAssignment = true;
							}
				}

				//aber selbst wenn Permissions ok...
				if (mayExecuteAssignment)
					if (task){

						//falls nur eigene: eigen sollte der Task dann schon sein!
						if (!(hasPermissionAll || hasPermissionTaskType)){

							//Ersteller oder Bearbeiter oder Verantwortlicher?
							var ts = task.getCurrentState();
							if ((task.createdBy !== this.id) && (!ts.isAssignedToUser(this.id)) && (ts.responsibleId !== this.id)){

								//nein? dann raus!
								return false;
							}
						}

						//passt der Objekttyp?
						if (task.locationType && (task.locationType > 0))
							if (!this.hasLocationType(task.locationType))
								return false;
					}

				return mayExecuteAssignment;
			},
			canExecuteTaskType: function(taskTypeId) {
				var hasPermissions = authManager.hasUserPermission(this, UserRole.PERMISSION_TASK_EXECUTE_ALL, UserRole.PERMISSION_TASK_EXECUTE_OWN);
				if (!hasPermissions) {
					if (authManager.hasUserPermission(this, UserRole.PERMISSION_TASK_EXECUTE_BY_TASK_TYPE)) {
						if (this.hasTaskType(taskTypeId))
							hasPermissions = true;
					}
				}
				return hasPermissions;
			},

			//bestimmter DebugStatus gesetzt?
			hasDebugStatus: function(status){
				return this.debugStatus & status === status;
			},
			
			//----------------------------------------------
			//darf dieser User überhaupt bestimmten Funktionen übernehmen?
			
			canBeResponsibleForTask: function(task){
				
				//muss bearbeiten können
				
				var mayEdit = false;
				if (authManager.hasUserPermission(this, UserRole.PERMISSION_TASK_EDIT_ALL)) {
					mayEdit = true;
				}
				else {
					if (authManager.hasUserPermission(this, UserRole.PERMISSION_TASK_EDIT_OWN)) {
						if (task.ownerId === this.id)
							mayEdit = true;
					}
					if (authManager.hasUserPermission(this, UserRole.PERMISSION_TASK_EDIT_BY_TASK_TYPE)) {
						if (model.curUser.hasTaskType(task.taskTypeId))
							mayEdit = true;
					}
				}

				//aber selbst wenn Permissions ok: passt der Objekttyp?
				if (mayEdit)
					if (task){
						if (task.locationType && (task.locationType > 0))
							if (!this.hasLocationType(task.locationType))
								return false;
					}

				return mayEdit;
				
			},
			canCreateRequest: function(request){
				if (!authManager.hasUserPermission(this, UserRole.PERMISSION_REQUEST_CREATE, UserRole.PERMISSION_REQUEST_CREATE_BY_TASK_TYPE))
					return false;

				//passt auch der Objekttyp?
				if (request){
					if (request.locationType && (request.locationType > 0))
						if (!this.hasLocationType(request.locationType))
							return false;
				}

				return true;
			},
			canEditRequest: function(request){
				if (!authManager.hasUserPermission(this, UserRole.PERMISSION_REQUEST_EDIT_ALL, UserRole.PERMISSION_REQUEST_EDIT_OWN, UserRole.PERMISSION_REQUEST_EDIT_BY_TASK_TYPE))
					return false;

				//passt auch der Objekttyp?
				if (request){
					if (request.locationType && (request.locationType > 0))
						if (!this.hasLocationType(request.locationType))
							return false;
				}

				return true;
			},
			canExecuteRequest: function(request){
				if (!authManager.hasUserPermission(this, UserRole.PERMISSION_REQUEST_EXECUTE_ALL, UserRole.PERMISSION_REQUEST_EXECUTE_ASSIGNED))
					return false;

				//passt auch der Objekttyp?
				if (request){
					if (request.locationType && (request.locationType > 0))
						if (!this.hasLocationType(request.locationType))
							return false;
				}

				return true;
			},
			canConvertRequest: function(request){
				if (!authManager.hasUserPermission(this, UserRole.PERMISSION_REQUEST_CONVERT_RELEASED_TO_TASK_ALL, UserRole.PERMISSION_REQUEST_CONVERT_RELEASED_TO_TASK_ASSIGNED))
					return false;
				if (!authManager.hasUserPermission(this, UserRole.PERMISSION_TASK_EDIT_ALL, UserRole.PERMISSION_TASK_EDIT_OWN, UserRole.PERMISSION_TASK_EDIT_BY_TASK_TYPE))
					return false;

				//passt auch der Objekttyp?
				if (request){
					if (request.locationType && (request.locationType > 0))
						if (!this.hasLocationType(request.locationType))
							return false;
				}

				return true;
			},

			//----------------------------------------------

			//TODO: nur temporär nutzen, da eigentlich Permissions (und nicht Rollen!) als Kriterium gedacht
			hasRole: function(roleNameAbbrev){
				if (!this.role)
					return false;
				var role = UserRole.getUserRole(this.role);
				return (role.abbrev.toLowerCase() === roleNameAbbrev.toLowerCase());
			},

			//----------------------------------------------

			clearNotificationTypes: function(){
				this.notificationTypes = [];
			},

			addNotificationType: function(un){
				//duplikate vermeiden
				this.notificationTypes = this.notificationTypes.filter(function(o){
					return (o !== un);
				});
				this.notificationTypes.push(un);
			},

			//aktive Benachrichtigungen
			getNotificationTypes: function(){
				this.notificationTypes = this.notificationTypes || [];
				return this.notificationTypes;
			},

			hasNotificationType: function(type){
				var match = this.notificationTypes.find(function(o){
					return (o === type);
				});
				return (match);
			}

		},
		
		//----------------------------------------------
		
		createUser: function () {
			return Object.create(User.userPrototype);
		},
		
		getUser: function (userId) {
			userId = parseInt(userId);
			
			for (var i = 0; i < model.users.length; i++)
				if (model.users[i].id === userId)
					return model.users[i];
			return null;
		},
		
		//----------------------------------------------
		
		//alle aktiven User holen
		//ggf. mit bestimmten User-Rollen
		getActiveUsers: function(requiredUserRoleName, doIgnoreStatus){

			if (requiredUserRoleName === undefined)
				requiredUserRoleName = null;
			if (doIgnoreStatus === undefined)
				doIgnoreStatus = false;

			var a = [];
			
			for (var i = 0; i < model.users.length; i++) {
				
				//System-Admins nie irgendwo anzeigen
				if (model.users[i].isSystemAdmin())
					continue;

				if (requiredUserRoleName){
					if (!model.users[i].hasRole(requiredUserRoleName))
						continue;
				}

				if (model.users[i].status === constants.STATUS_OBJECT_ACTIVE/*xxx || doIgnoreStatus*/)
					a.push(model.users[i]);
			}
			
			return a;
		},
		
		//----------------------------------------------
		
		//für bestimmten User den Namen holen
		getUserName: function(userId, shortVersion, addFormatting){

			if (addFormatting === undefined)
				addFormatting = true;

			if (userId === -1) {
				if (addFormatting)
					return '<span class="text-muted">System</span>';
				return 'System';
			}
			else{
				var u = User.getUser(userId);
				if (u)
					return u.getName(shortVersion);
			}
			return "gelöschter Benutzer";
		}

	};
	
	window.User = User;
}());