(function () {

	//----------------------------------------------------------------
	//Filterklasse für Aufträge (Status, Objekte, ...)
	//----------------------------------------------------------------

	'use strict';

	var TaskFilterType = {

		taskFilterTypePrototype: {

			isDirty: false,
			isMultiSelect: false,

			getClass: function () {

				if (this.isDirty)
					return "fgBlue";
				return "fgLightGray";
			},

			getValue: function () {
				if (this.isDirty)
					return this.value;
				return "Alle";
			}
		},

		create: function (name, options, isMultiSelect) {
			return $.extend(
				Object.create(TaskFilterType.taskFilterTypePrototype), {
					name: name,
					options: options,
					isMultiSelect: isMultiSelect
				});
		}
	};

	window.TaskFilterType = TaskFilterType;
}());