(function () {
	
	//-------------------------------------------
	//Manager für UserGroups (Liste)
	//-------------------------------------------
	
	/*global
	 constants:true,
	 viewManager:true,
	 UserRole:true,
	 UserGroup:true,
	 Task:true,
	 authManager:true
	 */
	
	'use strict';
	
	var userGroupListManager = {
		
		//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
		checkModelChanged: function(data, oldModel){
			var i,
				j,
				tsOld,
				tsNew,
				hasMatch;
			
			//Benutzergruppe verändert?
			if (data.users) {
				for(i = 0; i < data.userGroups.length; i++) {
					var u = data.userGroups[i];
					
					hasMatch = false;
					for (j=0; j<oldModel.userGroups.length; j++) {
						var uOld = oldModel.userGroups[j];
						
						if (parseInt(u.id) === uOld.id) {
							hasMatch = true;
							tsOld = uOld.changedOn.getTime();
							//noinspection JSUnresolvedVariable
							tsNew = pg.parseDate(u.changedOn).getTime();
							if (tsNew !== tsOld)
								return true;
						}
					}
					
					//neues Objekt
					if (!hasMatch)
						return true;
				}
			}
			
			return false;
		},
		
		//----------------------------------------------------------------------------------------------
		
		//Liste anzeigen: Vorbereitung
		showUserGroups: function (callbackComplete) {
			
			var that = this;
			
			if (!authManager.hasPermission(UserRole.PERMISSION_TEAM_LIST)) {
				viewManager.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
				return false;
			}

			var restoreScrollPos = 0;
			if (model.curUserGroupListView.preserveCurPage) {
				model.curUserGroupListView.preserveCurPage = false;
				restoreScrollPos = model.curUserGroupListView.scrollPos || 0;
			}

			viewManager.setSelectedMenuItem('menuSettings', 'menuUserGroups');
			
			$("#content").html(window.templates['userGroupList.html']);
			setTimeout(function () {
				
				that.showUserGroupsView();

				viewManager.setScrollTop(restoreScrollPos);

				if (callbackComplete)
					callbackComplete();

			}, constants.TEMPLATE_DELAY);
		},
		
		//Liste anzeigen: Rendering
		showUserGroupsView: function () {

			//sortieren nach name ASC
			model.userGroups.sort(function (a, b) {
				var aName = a.name.toLowerCase();
				var bName = b.name.toLowerCase();
				return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
			});
			
			//Inhalte
			var s = '';
			var index = 0;
			for(var i = 0; i < model.userGroups.length; i++) {
				var ug = model.userGroups[i];
				
				if (ug.isSingleUser)
					continue;
				
				if (ug.status === constants.STATUS_OBJECT_DELETED)
					continue;
				
				if (++index % 2 === 0)
					s += '<tr class="gradeX even" role="row">';
				else
					s += '<tr class="gradeX odd" role="row">';

				s += '<td>';
				if (authManager.hasPermission(UserRole.PERMISSION_TEAM_DETAILS))
					s += '  <a data-userGroupId="' + ug.id + '" class="btn grey-mint btn-xs btnUserGroupEdit">Details</a>';
				s += '</td>';

				s += '<td>' + ug.name + '</td>';
				
				var members = "";
				for(var j = 0; j < ug.users.length; j++) {
					
					//deleted
					if (ug.users[j] === null)
						continue;
					
					if (ug.users[j].status === constants.STATUS_OBJECT_ACTIVE) {
						if (members.length)
							members += "<br>";
						members += ug.users[j].getName();
					}
				}
				s += '<td>' + members + '</td>';
				
				switch (ug.status) {
					case constants.STATUS_OBJECT_ACTIVE:
						s += '<td><span class="badge bg-green-jungle">aktiv</span></td>';
						break;
					case constants.STATUS_OBJECT_INACTIVE:
						s += '<td><span class="badge badge-default">inaktiv</span></td>';
						break;
				}
				
				s += '<td>';
				var taskCount = Task.getUserGroupTasks(ug.id).length;
				if (taskCount > 0) {
					if (authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_ALL, UserRole.PERMISSION_TASK_LIST_OWN, UserRole.PERMISSION_TASK_LIST_BY_TASK_TYPE))
						s += '  <a data-userGroupId="' + ug.id + '" class="btnUserGroupTasks">' + pg.getStemmedName(taskCount, "Auftrag", "Aufträge") + '</a>';
				}
				s += '</td>';
				
				s += '</tr>';
			}
			$("#userGroupTable tbody").append(s);
			
			//handler
			if (authManager.hasPermission(UserRole.PERMISSION_TEAM_CREATE)) {
				$("#btnAddUserGroup").on("click", function () {
					viewManager.showPage(constants.PAGE_USERGROUP_ADD);
				});
			}
			else
				$("#btnAddUserGroup").hide();
			
			$(".btnUserGroupEdit").on("click", function (e) {
				
				if (e) {

					//scroll-Position merken
					model.curUserGroupListView.scrollPos = viewManager.getScrollTop();

					var link = $(e.currentTarget);
					if (link.attr("data-userGroupId")) {
						viewManager.showPage(constants.PAGE_USERGROUP_DETAILS, {
							userGroupId: parseInt(link.attr("data-userGroupId"), 10)
						});
					}
				}
			});
			$(".btnUserGroupTasks").on("click", this.showUserGroupTasks);
			
		},
		
		//Sprung: Aufträge einer Usergroup anzeigen
		showUserGroupTasks: function (e) {
			if (e) {

				//scroll-Position merken
				model.curUserGroupListView.scrollPos = viewManager.getScrollTop();

				var link = $(e.currentTarget);
				if (link.attr("data-userGroupId")) {
					var userGroupId = parseInt(link.attr("data-userGroupId"), 10);
					var a = [];
					a.push(UserGroup.getUserGroup(userGroupId));
					
					viewManager.showPage(constants.PAGE_TASK_LIST, {
						status: -1,
						userGroupIds: a,
						userGroup: UserGroup.getUserGroup(userGroupId)
					});
				}
			}
			return false;
		}
		
	};
	
	window.userGroupListManager = userGroupListManager;
}());