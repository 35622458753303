(function () {
	
	//------------------------------------------------------------------------
	//Objekte verwalten
	//------------------------------------------------------------------------
	
	/*global
	viewManager:true,
	dataManager:true,
	constants:true,
	setupFileUploader:true,
	Location:true,
	protocolManager:true,
	LocationField:true,
	ProtocolForm:true,
	Task:true,
	LocationFormField:true,
	Attachment:true,
	authManager:true,
	formatManager:true,
	UserRole:true,
	ProtocolFormField:true,
	Incident:true,
	LocationType:true
	*/
	
	'use strict';
	
	var locationManager = {

		isEditingLocation: false,
		isEditingFormFields: false,

		//die Jstree-Instanz
		jstree: null,
		treeIsReady: false,

		//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
		isCurObjOutdated: false,
		checkModelChanged: function (data, oldModel) {
			
			var that = locationManager;
			
			var i, l, j, lOld, tsOld, tsNew, hasMatch, curProtocols;

			//Veränderungen erzeugen komplett neues Location-Objekt (daher nicht LocationData prüfen!)
			if (data.locations) {
				if (data.locations.length > 0) {
					for (i = 0; i < data.locations.length; i++) {
						//noinspection JSUnresolvedVariable
						l = data.locations[i];

						hasMatch = false;
						for (j = 0; j < oldModel.locations.length; j++) {
							lOld = oldModel.locations[j];
							if (parseInt(l.id) === lOld.id) {

								that.isCurObjOutdated = true;

								hasMatch = true;

								tsOld = lOld.changedOn.getTime();
								tsNew = pg.parseDate(l.changedOn).getTime();
								if (tsNew !== tsOld)
									return true;
								break;
							}
						}

						//kein Match (neues Objekt)
						if (!hasMatch) {
							that.isCurObjOutdated = true;
							return true;
						}
					}
				}
			}
			
			//Falls aktuell ein Objekt ausgewählt
			if (oldModel.curLocation) {
				
				curProtocols = oldModel.curLocation.getProtocols();
				
				if (data.protocols) {
					
					for(i = 0; i < data.protocols.length; i++) {
						
						if (parseInt(data.protocols[i].locationId) === oldModel.curLocation.locationId) {
							
							hasMatch = false;
							for(j = 0; j < curProtocols.length; j++) {
								if (parseInt(data.protocols[i].id) === curProtocols[j].id) {
									
									that.isCurObjOutdated = true;
									
									hasMatch = true;
									tsOld = curProtocols[j].changedOn.getTime();
									tsNew = pg.parseDate(data.protocols[i].changedOn).getTime();
									if (tsNew !== tsOld)
										return true;
								}
							}
							//neues Objekt
							if (!hasMatch) {
								that.isCurObjOutdated = true;
								return true;
							}
						}
					}
				}
				if (data.incidents) {
					for(i = 0; i < data.incidents.length; i++) {
						
						if (parseInt(data.incidents[i].locationId) === oldModel.curLocation.locationId) {
							
							hasMatch = false;
							for(j = 0; j < curProtocols.length; j++) {
								
								if (parseInt(data.incidents[i].protocolId) === curProtocols[j].id) {
									
									var exInc = curProtocols[j].getIncident(data.incidents[i].id);
									if (exInc) {
										
										that.isCurObjOutdated = true;
										
										hasMatch = true;
										tsOld = exInc.changedOn.getTime();
										tsNew = pg.parseDate(data.incidents[i].changedOn).getTime();
										if (tsNew !== tsOld)
											return true;
									}
								}
							}
							//neues Objekt
							if (!hasMatch) {
								that.isCurObjOutdated = true;
								return true;
							}
						}
					}
				}
				if (data.signals) {

					for(i = 0; i < data.signals.length; i++) {

						var sig = data.signals[i];

						//irgendein Objekt im Baum?
						//das passende Objekt suchen
						var loc = Location.getLocation(parseInt(sig.locationId), null, true);
						if (loc){

							hasMatch = false;
							curProtocols = loc.getProtocols();
							for (j = 0; j < curProtocols.length; j++) {

								if (parseInt(sig.protocolId) === curProtocols[j].id) {

									var exSig = curProtocols[j].getSignal(sig.id);
									if (exSig) {

										if (oldModel.curLocation.locationId === loc.locationId)
											that.isCurObjOutdated = true;

										hasMatch = true;

										tsOld = exSig.changedOn.getTime();
										tsNew = pg.parseDate(data.signals[i].changedOn).getTime();
										if (tsNew !== tsOld)
											return true;
									}
								}
							}
							//neues Objekt
							if (!hasMatch) {
								if (oldModel.curLocation.locationId === loc.locationId)
									that.isCurObjOutdated = true;
								return true;
							}

						}

					}
				}
				
			}
			
			return false;
		},
		updateCurObj: function(o){
			
			o = o || Location.getLocation(model.curLocation.locationId, null, true, false);
			model.curLocation = o;
			this.isCurObjOutdated = false;
		},
		
		//----------------------------------------------------------------------------------------------

		onBeforeLeave: function(){
			this.saveTreeState();
		},

		//----------------------------------------------------------------------------------------------

		//Anzeige vorbereiten: Tree etc.
		prevShownLocation: null,
		prevShownEditor: false,
		showLocationView: function (locationId, restoreState, showEditor, callbackComplete) {
			
			var that = this;
			if (locationId === undefined)
				locationId = -1;
			if (showEditor === undefined)
				showEditor = false;
			if (restoreState === undefined)
				restoreState = false;

			this.jstree = null;
			this.treeIsReady = false;

			//merken
			if (restoreState) {
				
				if (!model.curLocation)
					return;
				
				this.prevShownLocation = Location.getLocation(model.curLocation.locationId, null, true, false);
				this.prevShownEditor = model.curStateDetails.showEditor || showEditor;
			}
			else {
				//eine bestimmte Location anzeigen?
				if (locationId > 0){
					this.prevShownLocation = Location.getLocation(locationId, null, true, false);
					this.prevShownEditor = showEditor;
				}
				else {
					this.prevShownLocation = null;
					this.prevShownEditor = false;
				}
			}
			
			viewManager.setSelectedMenuItem('menuLocations');
			
			$("#content").html(window.templates['locationList.html']);
			setTimeout(function () {
				
				$("#locationFormError").addClass("display-hide");
				
				//get tree model
				//var treeData = model.locationTree.getJstreeData(true, true);
				var treeData = model.locationTree.getJstreeData(true, true, model.curLocationFilter.locationTypes, false, true, model.curLocationFilter.taskIncidentType);

				$('#treeLocation').jstree({
					'plugins': ["dnd", "types", "search", "sort", "node_customize"],
					'core': {
						"multiple": false,
						"themes": {
							"responsive": false
						},
						'data': treeData.data,
						
						'check_callback': function (operation, node, parent, position, more) {
							
							if (operation === "create_node")
								return true;
							if (operation === "delete_node")
								return true;
							
							// operation can be 'create_node', 'rename_node', 'delete_node', 'move_node' or 'copy_node'
							// in case of 'rename_node' node_position is filled with the new node name
							if (operation !== "move_node")
								return false;
							
							//moving nodes: Ziel ok?
							var parentId = parent.data;
							if (!parentId)
								parentId = parseInt(parent.id);
							if (parentId === Location.LOCATION_ROOT_ID)
								return true;
							var trgId = parseInt(parentId);
							var trgObj = Location.getLocation(trgId, null, true, false);
							if (!trgObj)
								return false;
							//nur auf Folder
							if (trgObj.isAbstract === 0)
								return false;
							
							return true;
						}
					},
					"types": {
						"folder": {
							"icon": "fa fa-folder icon-state-darkgrey"
						},
                        "folder-inactive": {
                            "icon": "fa fa-folder-o icon-state-default"
                        },
						"file": {
							"icon": "fa fa-map-marker icon-state-primary"
						},
						"file-inactive": {
							"icon": "fa fa-map-marker icon-state-default"
						}
					},
					"dnd": {
						"is_draggable": function (data) {
							
							if (data.length !== 1)
								return false;
							
							var locationId2 = parseInt(data[0].id);
							if (locationId2 === Location.LOCATION_ROOT_ID)
								return false;
							if (locationId2 <= 0)
								return false;
							
							return true;
						}
					},
					'sort' : function(a, b) {
						var a1 = this.get_node(a);
						var b1 = this.get_node(b);
						//if (a1.icon === b1.icon) {
							return (a1.text.toLowerCase() > b1.text.toLowerCase()) ? 1 : -1;
						/*} else {
							return (a1.icon > b1.icon) ? 1 : -1;
						}*/
					},
					"node_customize": {

						//Vorkommnis-Icons einblenden
						default: function(elem, node) {

							var el = $(elem);
							//el.find(".additional-icons").remove();

							//dynamisch Icons hinzufügen
							var locationId3 = parseInt(elem.id);
							var location = Location.getLocation(locationId3);

							if (location) {

								var isGroup = (location.isAbstract === 1);
								var isOpenedGroup = isGroup && that.jstree.is_open(locationId3);

								//Werte ermitteln
								var notificationCount,
									unfixedDefectsCount,
									unfixedObservationsCount;

								if ((!isGroup) || ((isGroup) && (!isOpenedGroup))){
									//Node: normal, Group summieren
									notificationCount = location.getIncidentCount().notificationCount;
									unfixedDefectsCount = location.getIncidentTypeCount(Incident.INCIDENT_TYPE_UNFIXED_DEFECT);
									unfixedObservationsCount = location.getIncidentTypeCount(Incident.INCIDENT_TYPE_UNFIXED_OBSERVATION);
								}

								if (!isOpenedGroup) {

									//etwas anzeigen?
									if (notificationCount + unfixedDefectsCount + unfixedObservationsCount > 0) {

										var newIcon = '<span data-id="' + locationId3 + '" class="pull-right pl5 additional-icons">';
										var title = "";

										if (notificationCount > 0) {
											title = pg.getStemmedName(notificationCount, "gemeldetes Vorkommnis", "gemeldete Vorkommnisse");
											newIcon += '<span class="badge badge-danger badge-circled" data-toggle="tooltip" data-placement="top" title="' + title + '">' +
												'<i class="fa fa-flag"></i>' +
												'</span>';
										}
										if (unfixedDefectsCount > 0) {
											title = pg.getStemmedName(unfixedDefectsCount, "nicht behobener Mangel", "nicht behobene Mängel");
											newIcon += '<span class="badge badge-danger badge-circled" data-toggle="tooltip" data-placement="top" title="' + title + '">' +
												'<i class="fa fa-bolt"></i>' +
												'</span>';
										}
										if (unfixedObservationsCount > 0) {
											title = pg.getStemmedName(unfixedObservationsCount, "nicht behobene Feststellung", "nicht behobene Feststellungen");
											newIcon += '<span class="badge badge-warning badge-circled" data-toggle="tooltip" data-placement="top" title="' + title + '">' +
												'<i class="fa fa-eye"></i>' +
												'</span>';
										}
										newIcon += '</span>';

										el.find('i.jstree-themeicon').after(newIcon);
									}
								}
								else{
									var trg = el.find(".additional-icons[data-id='" + locationId3 + "']");
									trg.remove();
								}
							}
						}
					}
				});
				that.jstree = $('#treeLocation').jstree(true);
				
				//selection
				$('#treeLocation').on('activate_node.jstree', function (e, data) {
					locationManager.checkLocationFormDirty(function () {
						var locationId2 = parseInt(data.node.id);
						locationManager.onLocationNodeSelected(locationId2);
					});
				});
				
				//loading complete
				$('#treeLocation').on('ready.jstree', function (e, data) {

					//$('#treeLocation').jstree("open_all");
					setTimeout(function(){
						that.loadTreeState();
						that.treeIsReady = true;
					}, 100);
					that.minimizeAll();
					
					if (model.curStateDetails.curSelectedLocationId) {
						//vorigen
						$("#treeLocation").jstree("select_node", "#" + model.curStateDetails.curSelectedLocationId);
						locationManager.onLocationNodeSelected(model.curStateDetails.curSelectedLocationId, model.curStateDetails.showEditor);
					}
					else {
						//Root auswählen (ID 0 ging nicht, wurde ignoriert)
						$("#treeLocation").jstree("select_node", "#" + Location.LOCATION_ROOT_ID);
					}
					
					viewManager.activateNavTab("#locationDetails", model.curStateDetails.curTabIndex);
				});

				//öffnen -> redraw
				$('#treeLocation').on('after_open.jstree', function (e, data) {
					if (that.treeIsReady) {
						that.jstree.redraw_node("#" + data.node.id, false, false, false);
					}
				});
				$('#treeLocation').on('after_close.jstree', function (e, data) {
					if (that.treeIsReady) {
						that.jstree.redraw_node("#" + data.node.id, false, false, false);
					}
				});

				//dnd
				$(document)
					.on('dnd_stop.vakata', function (e, data) {
						//http://stackoverflow.com/questions/22892906/jstree-v3-0-drag-and-drop-plugin-reference-target-node-upon-dropping
						
						//nur einmal pro Sekunde möglich!
						var now = new Date().getTime();
						if (!model.curDropTime)
							model.curDropTime = 0;
						var delta = now - model.curDropTime;
						model.curDropTime = now;
						if (delta < 1000)
							return;
						
						setTimeout(function () {
							//dragged
							var draggedLocationId = parseInt(data.data.nodes[0]);
							
							//auf:
							var t = $(data.event.target);
							var targetnode = t.closest('.jstree-node');
							var trgLocationId = parseInt(targetnode.attr("id"));
							
							//kein korrekter Knoten
							if (isNaN(trgLocationId))
								return;
							
							//nicht auf self
							if (draggedLocationId === trgLocationId)
								return;
							
							//ist Ziel ein Folder?
							var isTrgValidFolder = false;
							var trgLocName = "Alle";
							if (trgLocationId === Location.LOCATION_ROOT_ID) {
								trgLocationId = 0;
								isTrgValidFolder = true;
							}
							else {
								var trgLoc = Location.getLocation(trgLocationId, null, true, false);
								if (!trgLoc)
									return;
								trgLocName = trgLoc.getName();
								isTrgValidFolder = (trgLoc.isAbstract === 1);
								
								//special: Ziel darf nicht unterhalb der Quelle liegen (in eigenen Nachfahren verschieben)
								if (trgLoc.isEqualOrBelow(draggedLocationId))
									return;
							}
							
							var loc,
								pId;
							if (isTrgValidFolder) {
								
								//auch inaktive
								var srcLoc = Location.getLocation(draggedLocationId, null, true, false);
								if (!srcLoc)
									return;
								
								//nicht auf gleicher Ebene verschieben
								if (srcLoc.parentLocationId !== trgLocationId) {
									
									pg.confirmMsg("Verschieben", "Möchten Sie <b>" + encodeURIComponent(srcLoc.getName()) + "</b> nach <b>" + encodeURIComponent(trgLocName) + "</b> verschieben?", function (e2) {
										
										if (e2) {
											
											srcLoc = _.cloneDeep(srcLoc);
											
											//verschieben
											srcLoc.parentLocationId = trgLocationId;
											dataManager.addLocationData(srcLoc, false, function (loc2) {

												//im Datenbestand merken
												loc2.data = srcLoc.data;
												model.locations.push(loc2);
												var locIds = [];
												locIds.push(loc2.locationId);
												Location.updateLocations(null, locIds);
												Location.buildLocationTree();
												
												//in Baum aktualisieren
												pId = loc2.parentLocationId;
												if (pId === 0)
													pId = Location.LOCATION_ROOT_ID;
												
												//anzeigen und ausklappen
												setTimeout(function () {
													//noinspection JSUnresolvedFunction
													that.jstree.set_type("#" + loc2.locationId, loc2.getJsTreeIcon());
													//noinspection JSUnresolvedFunction
													that.jstree.move_node("#" + loc2.locationId, "#" + pId, "last");
													//noinspection JSUnresolvedFunction
													that.jstree.open_node("#" + pId);
												}, 50);
												
												viewManager.showNotification("Die &Auml;nderung wurde gespeichert.", constants.NOTIFICATION_SUCCESS);
											});
											
										}
										else {
											
											//reset
											pId = srcLoc.parentLocationId;
											if (pId === 0)
												pId = Location.LOCATION_ROOT_ID;
											//noinspection JSUnresolvedFunction
											that.jstree.move_node("#" + srcLoc.locationId, "#" + pId, "last");
											
										}
										
									});
									
								}
								else {
									
									//reset
									pId = srcLoc.parentLocationId;
									if (pId === 0)
										pId = Location.LOCATION_ROOT_ID;
									//noinspection JSUnresolvedFunction
									that.jstree.move_node("#" + srcLoc.locationId, "#" + pId, "last");
								}
							}
							else {
								//reset
								loc = Location.getLocation(draggedLocationId, null, true, false);
								pId = loc.parentLocationId;
								if (pId === 0)
									pId = Location.LOCATION_ROOT_ID;
								//noinspection JSUnresolvedFunction
								that.jstree.move_node("#" + loc.locationId, "#" + pId, "last");
							}
							
						}, 200);
						
					})
					.on('dnd_move.vakata', function (e, data) {
						//data.helper.find('.jstree-icon').removeClass('jstree-er').removeClass('jstree-ok');
						$('.jstree-icon', data.helper).removeClass('jstree-er').removeClass('jstree-ok');
					});
				
				//------------------------------------------
				//handler
				
				var mayCreateLocation = false;
				if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_CREATE_ALL)) {
					mayCreateLocation = true;
				}
				else {
					if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_CREATE_BY_LOCATION_TYPE)) {
						if (model.curUser.hasAnyLocationType())
							mayCreateLocation = true;
					}
				}
				
				if (mayCreateLocation) {
					$("#btnAddLocation").on("click", function () {
						locationManager.checkLocationFormDirty(function () {
							locationManager.addLocation();
						});
					});
					$("#btnAddLocationGroup").on("click", function () {
						locationManager.checkLocationFormDirty(function () {
							locationManager.addLocationGroup();
						});
					});
				}
				else {
                    $("#btnGroupLocationListActions").hide();
                    $("#btnAddLocation").hide();
					$("#btnAddLocationGroup").hide();
				}
				
				$("#btnBackLocation").off("click").on("click", function () {
					locationManager.checkLocationFormDirty(function () {
						window.history.back();
					});
				});

				//----------------------------
				//Suchen
				$("#locationListFilterName").keyup(function(){
					var searchString = $(this).val();
					$('#treeLocation').jstree('search', searchString);
				});

				//----------------------------
				//Filter und Events dazu

				var s = "";
				//s += '<option value="-1">Alle</option>';
				for (var i = 0; i < model.locationTypes.length; i++) {

					var l = model.locationTypes[i];

					//darf?
					if (model.curUser.hasLocationType(LocationType.LOCATION_TYPE_ALL) || model.curUser.hasLocationType(l.id)) {
						s += '<option value="' + model.locationTypes[i].id + '">' + model.locationTypes[i].name + '</option>';
					}
				}
				$(".select2, .select2-multiple").select2();
				$("#locationListFilterType").html(s).val(model.curLocationFilter.locationTypes).trigger("change").change(function(){
					that.onTreeFilterApplied();
				});
				$("#locationListFilterIncidents").val(model.curLocationFilter.taskIncidentType).trigger("change").change(function(){
					that.onTreeFilterApplied();
				});
				that.applyTreeFilter(model.curLocationFilter);

				//-----------------------------
				
				//Zustand
				if (!model.curStateDetails) {
					//state merken
					model.curStateDetails = {
						curTabIndex: 0,
						showEditor: false
					};
				}
				
				//anwenden
				viewManager.activateNavTab("#locationDetails", model.curStateDetails.curTabIndex);
				
				//gemerkte Location?
				//reset tree
				if (that.prevShownLocation) {
					model.curLocation = that.prevShownLocation;
					var locId = model.curLocation.locationId;
					if (locId === 0)
						locId = Location.LOCATION_ROOT_ID;
					$('#treeLocation').jstree("deselect_all");
					$("#treeLocation").jstree("select_node", "#" + locId);
					
					that.showLocationDetails(model.curLocation, that.prevShownEditor);
					model.curStateDetails.curSelectedLocationId = locId;
				}

				if (callbackComplete)
					callbackComplete();
				
			}, constants.TEMPLATE_DELAY);
		},
		
		//------------------------------------------------------------
		//filterung

		isTreeFiltered: false,
		isApplyingTreeFilter: false,

		onTreeFilterApplied: function(){

			if (this.isApplyingTreeFilter)
				return;

			var locationTypes = null;
			if ($("#locationListFilterType").val()) {
				locationTypes = $("#locationListFilterType").val().map(function (o) {
					return parseInt(o);
				});
			}
			var taskIncidentType = parseInt($("#locationListFilterIncidents").val());
			model.curLocationFilter = {
				locationTypes: locationTypes,
				taskIncidentType: taskIncidentType
			};

			this.applyTreeFilter(model.curLocationFilter);
		},
		applyTreeFilter: function() {

			var isApplied = false;
			if (model.curLocationFilter.locationTypes)
				isApplied = true;
			if (model.curLocationFilter.taskIncidentType >= 0)
				isApplied = true;

			this.isTreeFiltered = isApplied;

			if (this.treeIsReady || isApplied)
				this.forceTreeUpdate();

			this.updateTreeFilterView();
		},

		//ggf. zeitverzögert aktualisieren
		forceTreeUpdate: function(){
			var that = this;
			if (this.treeIsReady)
				this.updateTreeData();
			else{
				setTimeout(function(){
					that.forceTreeUpdate();
				}, 100);
			}
		},
		resetLocationFilter: function(){

			model.curLocationFilter = {
				locationTypes: null,
				taskIncidentType: -1
			};

			this.applyTreeFilter();

			//Eingabefelder zurücksetzen
			this.isApplyingTreeFilter = true;
			$("#locationListFilterType").val([]).trigger("change");
			$("#locationListFilterIncidents").val(-1).trigger("change");
			this.isApplyingTreeFilter = false;
		},
		updateTreeFilterView: function(){
			if (this.isTreeFiltered){
				$("#btnLocationFilterInactive").hide();
				$("#btnLocationFilterActive").show();
			}
			else{
				$("#btnLocationFilterInactive").show();
				$("#btnLocationFilterActive").hide();
			}
		},
		updateTreeData: function(){

			var that = this;

			var treeData = model.locationTree.getJstreeData(true, true, model.curLocationFilter.locationTypes, false, true, model.curLocationFilter.taskIncidentType);
			if (this.isTreeFiltered)
				treeData.data = this.pruneEmptyGroups(treeData.data);
			that.jstree.settings.core.data = treeData.data;
			that.jstree.refresh();

			//keine Treffer?
			if (!treeData.data)
				$('#treeLocationEmpty').show();
			else
				$('#treeLocationEmpty').hide();

			setTimeout(function(){
				that.maximizeAll();
			}, 100);
		},

		//leere Gruppe rekursiv entfernen
		pruneEmptyGroups: function(loc){

			if (!loc)
				return "";

			//Gruppe: leere Kinder?
			if (!loc.children)
				return loc;

			//erst Tiefensuche über alle Kinder
			var childCount = 0;
			for (var i=loc.children.length-1; i>=0; i--){
				var retLoc = this.pruneEmptyGroups(loc.children[i]);
				//leer? -> abschneiden!
				if (!retLoc)
					loc.children.splice(i, 1);
				else
					childCount++;
			}

			if (childCount > 0)
				return loc;
			return null;
		},

		//------------------------------------------------------------

		//wurde etwas geändert?
		checkLocationFormDirty: function (callbackProceed, callbackHalt, resetTree) {

			if (resetTree === undefined)
				resetTree = true;

			if (model.isCurFormDirty) {
				pg.confirmMsg("Achtung", "<strong>Möchten Sie die Formulareingabe wirklich beenden?</strong><br>Nicht gespeicherte Änderungen gehen verloren.", function (e) {
					if (e) {
						callbackProceed();
						return false;
					}
					else {
						//reset tree
						if (resetTree) {
							var locId = model.curLocation.locationId;
							if (locId === 0)
								locId = Location.LOCATION_ROOT_ID;
							$('#treeLocation').jstree("deselect_all");
							$("#treeLocation").jstree("select_node", "#" + locId);
						}
						if (callbackHalt)
							callbackHalt();
					}
				});
				return false;
			}
			else
				callbackProceed();
		},

		//Knoten im Baum ausgewählt
		onLocationNodeSelected: function (locationId, showEditor) {
			
			var that = locationManager;
			
			if (showEditor === undefined)
				showEditor = false;
			
			var loc = Location.getLocation(locationId, undefined, true, true);
			
			viewManager.updateDirtyHandler();
			
			if (isNaN(locationId) || (locationId <= 0) || (loc === null)) {
				loc = model.locationTree;
				locationId = 0;
			}
			model.curStateDetails.curSelectedLocationId = locationId;
			model.curStateDetails.scrollPosition = viewManager.getScrollTop();
			viewManager.setStateDetails(model.curStateDetails);
			that.setLocationStateDetails();
			
			if (loc.locationId > 0) {
				
				if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_DETAILS))
					that.showLocationDetails(loc, showEditor);
			}
			else {
				that.prevShownLocationDetail = null;
				$("#locationDetails").hide();
			}
		},
		
		//Daten eines Objektes anzeigen
		prevShownLocationDetail: null,
		showLocationDetails: function (loc, showEditor) {

			var i, l,
				that = this;
			
			if (!loc) {
				$("#locationDetails").hide();
				return;
			}
			
			this.resetLocationError();
			if (loc.id > 0)
				this.prevShownLocationDetail = loc;
			
			model.curLocation = loc;

			var mayEditLocation = false;
			if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_EDIT_ALL)) {
				mayEditLocation = true;
			}
			else {
				if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_EDIT_BY_LOCATION_TYPE)) {
					if (model.curUser.hasLocationType(loc.type))
						mayEditLocation = true;
				}
			}

			//darf User editieren?
			if (showEditor){
				showEditor = mayEditLocation;
			}
			that.isEditingLocation = showEditor;

			if (showEditor) {
				$("#locationViewInfo").hide();
				$("#formLocationEdit").show();
			}
			else {
				$("#locationViewInfo").show();
				$("#formLocationEdit").hide();
			}
			
			//Daten für View
			var s = "";
			var lv = "";
			//s += '<tr class="rowHeader first"><td colspan="2"><b>Bezeichnung</b></td></tr>';

			if (showEditor) {
				if (loc.isAbstract === 1) {
					$("#locationNameTitle").html("Gruppenbezeichnung");
					$("#locationNameShortTitle").html("Gruppenkurzbezeichnung");
				}
				else {
					$("#locationNameTitle").html("Objektbezeichnung");
					$("#locationNameShortTitle").html("Objektkurzbezeichnung");
				}
			}
			
			//Parent
			var h = loc.getHierarchy(false);
			var hierarchy = h.reverse().map(function(l2){
				return l2.name;
			}).join(" &raquo; ");
			if (showEditor)
				$("#locationParent").html(hierarchy);
			else
				s += '<tr><td class="col-xs-5">Gruppe</td><td class="col-xs-7 font-semibold">' + hierarchy + '</td></tr>';
			
			//Form vorbereiten
			var sel = "";
			if (showEditor) {
				
				//Typen
				sel += "<option value='0' disabled selected style='display:none;'>Bitte w&auml;hlen...</option>";
				var locationTypes = model.locationTypes;
				//sortieren nach name
				locationTypes.sort(function (a, b) {
					return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
				});
				for(i = 0; i < locationTypes.length; i++) {
					l = locationTypes[i];
					
					//darf?
					if (model.curUser.hasLocationType(LocationType.LOCATION_TYPE_ALL) || model.curUser.hasLocationType(l.id))
						sel += "<option value='" + l.id + "'>" + l.name + "</option>";
				}
				$("#selectLocationType").html(sel);
				if ((loc.isAbstract === 0) && (loc.id <= 0)) {
					if (locationTypes.length === 1) {
						$("#selectLocationType").val(locationTypes[0].id);
						setTimeout(function () {
							locationManager.onLocationTypeChanged();
						}, 20);
					}
				}
				//warum auch immer erst mit Timeout?!
				setTimeout(function () {
					$("#selectLocationType").on("change", $.proxy(locationManager.onLocationTypeChanged, locationManager));
				}, 500);
			}
			
			$("#locationFormFieldsBox").hide();
			$(".btnSaveLocationFormFields").hide();
            //$(".locationFormFieldActions").hide();
			
			var showTabProtocols = true;
			var showTabAttachments = true;
			var showTabFormFields = true;
			
			//neu vs bekannt
			if (loc.id > 0) {

                if (loc.isAbstract === 1) {
                    $("#locationContentHeader").html(loc.getName(true));
                }
                else {
                    $("#locationContentHeader").html(loc.getName(true) + '<span class="text-muted text-uppercase smaller ml10">' + LocationType.getLocationType(loc.type).name + '</span>');
                }

				if (showEditor) {
					$("#locationDetailsTitle").html(' Objekt bearbeiten');
					//Status
					locationManager.updateLocationStatus(loc);
					$("#g-status").show();
					
					$("#locationName").val(loc.name);
					$("#locationNameShort").val(loc.nameShort);
					
					if (loc.isAbstract === 1) {
						$("#selectLocationType").val(-1);
						$("#locationHeader").nextAll().remove();
					}
					else {
						$("#selectLocationType").val(loc.type);
						locationManager.onLocationTypeChanged();
					}
					
					$("#btnEditLocation").hide();
				}
				else {

					if (!mayEditLocation)
						$("#btnEditLocation").hide();
					else
						$("#btnEditLocation").show();
					
					s += '<tr><td>Bezeichnung</td><td class="font-semibold">' + loc.name + '</td></tr>';
					s += '<tr><td>Kurzbezeichnung</td><td class="font-semibold">' + loc.nameShort + '</td></tr>';
					if (loc.isAbstract !== 1) {
						s += '<tr><td>Typ</td><td class="font-semibold">' + LocationType.getLocationType(loc.type).name + '</td></tr>';
					}

					if (loc.status === constants.STATUS_OBJECT_ACTIVE)
						s += '<tr><td>Status</td><td class="font-semibold">aktiv</td></tr>';
					else
						s += '<tr><td>Status</td><td class="font-semibold">inaktiv</td></tr>';
					
					if (loc.isAbstract === 1) {
						var locCount = Location.getActiveLocationDescendants(loc, true, true).length;
						s += '<tr><td>Anzahl Objekte</td><td class="font-semibold">' + locCount + '</td></tr>';
					}

					//-----------------
					//Clonen
					var mayCloneLocation = false;
					if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_CREATE_ALL)) {
						mayCloneLocation = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_CREATE_BY_LOCATION_TYPE)) {
							if (model.curUser.hasLocationType(loc.type))
								mayCloneLocation = true;
						}
					}

					if (loc.id < 0)
						mayCloneLocation = false;

					if (mayCloneLocation && (loc.isAbstract === 0)) {
						$("#btnCloneLocation")
							.show()
							.off("click")
							.on("click", $.proxy(function () {
							that.checkLocationFormDirty(function () {
								that.cloneLocation();
							});
							return false;
						}, that));
					}
					else
						$("#btnCloneLocation").hide();

					//pdf
					if (loc.isAbstract === 0){
						$("#btnPrintLocationActions").show();
					}
					else{
						$("#btnPrintLocationActions").hide();
					}


                }
				
				//Werte eintragen
				lv += this.populateLocationValues(loc, false, showEditor);

				//--------------------------------------------------
				//Protokolle
				showTabProtocols = false;
				if (authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_LIST_ALL, UserRole.PERMISSION_PROTOCOL_LIST_OWN, UserRole.PERMISSION_PROTOCOL_LIST_BY_TASK_TYPE)) {
					
					var p = loc.getProtocols();
					if (p.length > 0) {
						protocolManager.showProtocols(p, "#locationProtocolTable", false, false);
						showTabProtocols = true;
					}				
				}
				
				//--------------------------------------------------
				//Attachments
				loc.updateAttachments();
				locationManager.showLocationAttachments("#locationAttachmentTable", loc.getAttachments(), false);
				
				//im Baum markieren
				$('#treeLocation').jstree("deselect_all");
				$("#treeLocation").jstree("select_node", "#" + loc.locationId);
			}
			else {
				
				//----------------------------------------------------------------------------------------------------
				
				//Gruppe
				if (loc.isAbstract === 1)
					$("#locationContentHeader").html("Neue Gruppe");
				else
					$("#locationContentHeader").html("Neues Objekt");
				
				showTabProtocols = false;
				$("#btnEditLocation").hide();
				$("#btnCloneLocation").hide();
				$("#btnLocationDelete").hide();
				$("#g-status").hide();
				$("#locationDetailsTitle").html(' Neues Objekt');
				
				$("#locationName").val("");
				$("#locationNameShort").val("");
				
				//Protokolle
				//protocolManager.showProtocols([], "#locationProtocolTable", false, false);
				
				//Attachments
				locationManager.showLocationAttachments("#locationAttachmentTable", [], false);
				
				$("#locationHeader").nextAll().remove();
			}
			
			if (showEditor) {
				$(".btnSaveLocation").show();
				$(".btnCancelLocation").show();

				//Gruppe: nur oben
				/*
				if (loc.isAbstract === 1) {
					$("#btnSaveLocation").hide();
					$("#btnCancelLocation").hide();
					$(".btnSaveLocation").html("Speichern");
				}
				else {
					$(".btnSaveLocation").html("Speichern");
				}
				*/

				viewManager.updateButtons();
			}
			
			//Stammdaten anzeigen
			viewManager.activateNavTab("#locationDetails", 0);

			//Form Fields
			if (!authManager.hasPermission(UserRole.PERMISSION_LOCATION_FORM_FIELDS_DETAILS)) {
				showTabFormFields = false;
				$("#tabLocationFormFields").attr("data-hasPermission", 0);
			}
			else
				$("#tabLocationFormFields").attr("data-hasPermission", 1);
			var hasFormFields = this.updateLocationFormFields(loc, loc.type);
			if (!hasFormFields)
				showTabFormFields = false;
			
			if ((loc.isAbstract === 1) || (loc.id < 0)) {
				showTabAttachments = false;
				showTabProtocols = false;
				showTabFormFields = false;
			}

			if (showTabAttachments)
				$("#tabLocationAttachments").show();
			else
				$("#tabLocationAttachments").hide();
			if (showTabProtocols)
				$("#tabLocationProtocols").show();
			else
				$("#tabLocationProtocols").hide();
			if (showTabFormFields)
				$("#tabLocationFormFields").show();
			else
				$("#tabLocationFormFields").hide();
			
			$("#locationDetails").show();

			$("#locationViewTable tbody").html(s);
			$("#locationViewFields").html(lv);

			//attachments
			//if (showEditor) {
			if (mayEditLocation){
				//file upload
				setupFileUploader($("#locationAttachments .fileuploadBox"), this.onLocationAttachmentUploaded, constants.MEDIA_TYPE_IMAGE_PDF);
				$("#locationAttachmentUploadBox").show();
			}
			else{
				$("#locationAttachmentUploadBox").hide();
			}

			loc.updateAttachments();
			model.curAttachments = loc.getAttachments() || [];
			locationManager.showLocationAttachments("#locationAttachmentTable", model.curAttachments, showEditor);
			
			//Eventhandlers
			if (showEditor) {
				$("#formLocationEdit .btnSaveLocation").off("click").on("click", $.proxy(locationManager.verifyLocation, locationManager));
				$("#formLocationEdit .btnCancelLocation").off("click").on("click", $.proxy(locationManager.cancelLocation, locationManager));
				//$("#btnClone").off("click").on("click", $.proxy(locationManager.cloneLocation, locationManager));
			}
			else {
				$("#btnEditLocation").off("click").on("click", $.proxy(locationManager.editLocation, locationManager));
			}
			
			$(".btnLocationProtocol").off("click").on("click", function (e) {
				
				var link = $(e.currentTarget);
				if (link.attr("data-taskId") && link.attr("data-protocolId")) {
					
					viewManager.showPage(constants.PAGE_TASK_PROTOCOL, {
						taskId: parseInt(link.attr("data-taskId"), 10),
						protocolId: parseInt(link.attr("data-protocolId"), 10)
					});
				}
			});

			setTimeout(function () {
				$("#locationFormFieldsFormSelect").change(function () {
					var formId = parseInt($("#locationFormFieldsFormSelect").val());
					if (!isNaN(formId))
						locationManager.showLocationFormFields(formId, that.isEditingFormFields);
					that.updateFormFieldsEditing();
				});
			}, 500);
			this.updateFormFieldsEditing();
			
			if (showEditor) {
				setTimeout(function () {
					
					if (loc.isAbstract === 1) {
						$("#g-type").hide();
						if (loc.id <= 0)
							locationManager.execOnLocationTypeChanged(-1);
					}
					else {
						
						$("#g-type").show();
						if (loc.id > 0) {
							$("#selectLocationType").prop("disabled", true);
						}
						else {
							//nur einer? dann ausblenden
							if (locationTypes.length === 1)
								$("#selectLocationType").prop("disabled", true);
							else
								$("#selectLocationType").prop("disabled", false);
						}
					}
					
				}, 50);
			}
			
            $(".select2, .select2-multiple").select2();

            viewManager.updateDirtyHandler();
			viewManager.updateButtons();
			
			//remember
			model.curStateDetails.curLocation = loc;
			model.curStateDetails.showEditor = showEditor;
			model.curStateDetails.scrollPosition = viewManager.getScrollTop();
			viewManager.setStateDetails(model.curStateDetails);
			
			//URL aktualisieren
			if (loc.id > 0) {
				var url = "locationId=" + loc.locationId;
				if (showEditor)
					url += "&edit=1";
				viewManager.replaceUrl(url);
			}
			
		},
		
		//------------------------------------------------------------------
		
		//Objekt bearbeiten
		editLocation: function () {
			this.showLocationDetails(model.curLocation, true);
		},
		
		//------------------------------------------------------------------
		
		//Prüfungskonfiguration neu anzeigen: Übersicht
		updateLocationFormFields: function (loc, locationType) {

			var sel = "";
			sel += "<option value='0' disabled selected style='display:none;'>Bitte w&auml;hlen...</option>";
			var count = 0;
			for(var i = 0; i < model.protocolForms.length; i++) {
				var pf = model.protocolForms[i];
				var tt = pf.getTaskTypes();
				for(var j = 0; j < tt.length; j++) {
					if ((tt[j].hasLocationType(locationType)) && pf.isCurrentVersion && pf.hasFormFields(true)) {
						sel += "<option value='" + pf.formId + "'>" + pf.name + "</option>";
						count++;
					}
				}
			}

			var hasPermission = parseInt($("#tabLocationFormFields").attr("data-hasPermission"));
			if (count > 0) {
				if (hasPermission === 1) {
					//schon gespeichert?
					if (loc.id > 0)
						$("#tabLocationFormFields").show();
				}
				$("#locationFormFieldsFormSelect").html(sel);
				return true;
			}
			else {
				$("#tabLocationFormFields").hide();
				return false;
			}
		},
		
		//Prüfungskonfiguration anzeigen: Werte
		showLocationFormFields: function (formId, showEditor) {
			
			var form = ProtocolForm.getProtocolForm(formId);

			var fields = form.fields;
			fields.sort(function (a, b) {
				return (a.orderIndex - b.orderIndex);
			});

			var s = "";

			if (showEditor) {
				//Alle auswählen
				s += '<label class="mt-checkbox mt-checkbox-single mt-checkbox-outline">';
				s += '	<input class="form-control form-control-slim" id="chkFF_All" type="checkbox">';
				s += '  Alle an/abwählen';
				s += '	<span></span>';
				s += '</label><br/><br/>';
			}

			var curCategory = "";
			for(var i = 0; i < fields.length; i++) {
				
				var ff = fields[i];

				//nur manche Felder
				/*
				if (ff.displayMode !== ProtocolFormField.DISPLAY_MODE_DEPENDING_ON_LOCATION_FORM_FIELD)
					continue;
				*/

				if ((ff.category) && (ff.category !== curCategory)) {
					curCategory = ff.category;
					if (curCategory.length) {

						s += '<hr>';

                        //Read-Only: kommt da ein Wert in dieser Kategorie?
						if (!showEditor) {
							/*
							var containsValue = false;
							for(var j = i; j < fields.length; j++) {
								var ff2 = fields[j];
								//nächste Kategorie erreicht?
								if ((ff2.category) && (ff2.category !== curCategory))
									break;
								if (model.curLocation.hasFormField(ff2.id)) {
									containsValue = true;
									break;
								}
							}
							if (!containsValue)
								continue;
							*/

                            s += '<h5 class="text-primary">' + curCategory + '</h5>';
						}
						else
							s += '<h4 class="text-primary mb20">' + curCategory + '</h4>';
					}
				}

				var hasValue = model.curLocation.hasFormField(ff.id);

				//Werte: bei echten neuen Locations immer setzen
				var isNewLocation = (model.curLocation.id < 0);
				if (model.curLocation.isClone)
					isNewLocation = false;

				if (showEditor) {
					if (ff.displayMode !== ProtocolFormField.DISPLAY_MODE_DEPENDING_ON_LOCATION_FORM_FIELD)
						s += '<label class="mt-checkbox-readonly">';
					else
                    	s += '<label class="mt-checkbox mt-checkbox-outline">';

                    s += ff.name;

					if (ff.displayMode !== ProtocolFormField.DISPLAY_MODE_DEPENDING_ON_LOCATION_FORM_FIELD) {
						// Nicht als <input>!
						s += '<span><i class="fa fa-check"></i></span>';
					} else {
						s += '<input class="form-control form-control-slim" id="chkFF_' + ff.id + '" data-id="' + ff.id + '" type="checkbox"';
						if (isNewLocation || hasValue)
							s += ' checked';
						s += '>';
						// mt-checkbox
						s += '<span></span>';
					}

					s += '</label>';
				}
				else {
					s += '<span>';

					if (ff.displayMode !== ProtocolFormField.DISPLAY_MODE_DEPENDING_ON_LOCATION_FORM_FIELD) {
						s += '<i class="fa fa-fw fa-lock text-primary mr5"></i>' + ff.name;
					}
					else {
						if (!hasValue)
							s += '<i class="fa fa-fw fa-times text-muted mr5"></i><span class="text-muted">' + ff.name + '</span>';
						else
							s += '<i class="fa fa-fw fa-check text-primary mr5"></i>' + ff.name;
					}

					s += '</span>';
				}

                s += '<br>';
			}

            //$("#locationFormFieldsBox tbody").html(s);
            $("#locationFormFieldsTable").html(s);
			$("#locationFormFieldsBox").show();

			this.updateFormFieldsEditing();

			//Checkbox "alle"
			setTimeout(function(){

				$("#chkFF_All").change(function(){
					var checked = $(this).prop("checked");
					if (checked){
						$("#locationFormFieldsTable input[type='checkbox']").prop("checked", true);
					}
					else{
						$("#locationFormFieldsTable input[type='checkbox']").prop("checked", false);
					}
				});

			}, 500);

			viewManager.updateDirtyHandler();
		},

		//dynamische Bearbeitbarkeit der Prüfungskonfiguration
		updateFormFieldsEditing: function(){

			var that = this;

			//Berechtigung
			var mayEditFormFields = false;
			if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_FORM_FIELDS_EDIT_ALL)) {
				mayEditFormFields = true;
			}
			else {
				if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_FORM_FIELDS_EDIT_BY_LOCATION_TYPE)) {
					if (model.curUser.hasLocationType(model.curLocation.type))
						mayEditFormFields = true;
				}
			}

			//Form ausgewählt?
			var formId = parseInt($("#locationFormFieldsFormSelect").val());
			var hasFormSelected = (!isNaN(formId));

			//auswerten / anwenden
			if (mayEditFormFields) {

				//Status: im Bearbeitungsmodus?
				if (that.isEditingFormFields){
					$("#btnEditLocationFormFields").hide();

					$(".btnCancelLocationFormFields")
						.show()
						.off("click")
						.on("click", function(){
							that.checkLocationFormDirty(function(){
								that.isEditingFormFields = false;
								locationManager.showLocationFormFields(formId, that.isEditingFormFields);
							}, null, false);
						});

					$(".btnSaveLocationFormFields").show();
				}
				else{
					$("#btnEditLocationFormFields").show();

					if (hasFormSelected) {
						$("#btnEditLocationFormFields")
							.prop("disabled", false)
							.removeClass("disabled")
							.off("click")
							.on("click", function(){
								that.isEditingFormFields = true;
								locationManager.showLocationFormFields(formId, that.isEditingFormFields);
							});
					}
					else{
						$("#btnEditLocationFormFields").prop("disabled", true).addClass("disabled");
					}

					$(".btnCancelLocationFormFields").hide();
					$(".btnSaveLocationFormFields").hide();
				}
			}
			else{
				//keine Berechtigung
				$("#btnEditLocationFormFields").hide();
				$(".btnCancelLocationFormFields").hide();
				$(".btnSaveLocationFormFields").hide();
			}
		},
		
		//Prüfungskonfiguration speichern
		saveLocationFields: function () {
			
			var that = locationManager;
			
			//blocking
			if (!viewManager.setButtonBlocking($(".btnSaveLocationFormFields"), true))
				return false;
			
			if (model.curLocation.id < 0) {
				pg.showMsg("Bitte speichern Sie zun&auml;chst die Stammdaten des Objektes.");
				viewManager.updateButtons();
				return false;
			}
			
			//etwas geändert?
			if (!model.isCurFormDirty) {
				viewManager.showNotification("Es wurden keine Änderungen festgestellt.");
				viewManager.updateButtons();
				return false;
			}
			
			var formId = parseInt($("#locationFormFieldsFormSelect").val());
			
			var o = {
				locationId: model.curLocation.locationId,
				clientId: model.curLocation.clientId,
				formId: formId,
				formFields: this.getLocationFormFields()
			};
			
			//mind 1 Haken gesetzt?
			if (o.formFields.length === 0) {
				pg.showMsg("Bitte mindestens einen Eintrag anw&auml;hlen.");
				viewManager.updateButtons();
				return false;
			}
			
			if (that.isCurObjOutdated)
				that.updateCurObj();
			
			//speichern
			dataManager.setLocationFormFields(o, function (success) {
				viewManager.updateButtons();
				
				if (success) {
					//übernehmen
					model.curLocation.setFormFields(formId, o.formFields);
					
					viewManager.clearFormDirty();

					//zurück in View-Ansicht
					that.showLocationFormFields(formId, false);
					that.isEditingFormFields = false;
					that.updateFormFieldsEditing();

					viewManager.showNotification("Speichern erfolgreich", constants.NOTIFICATION_SUCCESS);
				}
				else
					pg.showMsg("Beim Speichern ist ein Problem aufgetreten.");
			});
		},
		
		//Werte zusammensuchen (für Speichern)
		getLocationFormFields: function () {
			
			var a = [];
			
			//Daten sammeln
			var formId = parseInt($("#locationFormFieldsFormSelect").val());
			var form = ProtocolForm.getProtocolForm(formId);
			for(var i = 0; i < form.fields.length; i++) {
				
				var ff = form.fields[i];
				
				//auslesen
				if ($("#locationFormFieldsTable input[data-id='" + ff.id + "']").prop("checked"))
					a.push({
						formFieldId: ff.id,
						formId: formId
					});
			}
			
			return a;
		},
		
		//-----------------------------------------------------------
		
		//Status-Anzeige aktualisieren
		updateLocationStatus: function (loc) {

			var that = this;

			var showCheckbox = true;
			if ((loc.status === constants.STATUS_OBJECT_ACTIVE) && (!loc.isActive()))
				showCheckbox = false;

			if (showCheckbox) {
				$("#chkLocationStatus").bootstrapSwitch({
					onText: '<i class="fa fa-fw fa-check"></i>',
					offText: '<i class="fa fa-fw fa-times"></i>',
					size: "mini",
					onSwitchChange: function (event, newState) {
						viewManager.setFormDirty();
						if (newState)
							that.activateLocation();
						else
							that.deactivateLocation();
					}
				});

				$("#locationStatusIndirect").hide();
				$("#chkLocationStatus").show();
			}
			else{
				$("#locationStatusIndirect").show();
				$("#chkLocationStatus").hide();
			}

			//update Switch
			var state = (loc.status === constants.STATUS_OBJECT_ACTIVE);
			this.setLocationStatus(state);

			//---------------------------------------------
			//Löschen

			var mayDeleteLocation = false;
			if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_DELETE_ALL)) {
				mayDeleteLocation = true;
			}
			else {
				if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_DELETE_BY_LOCATION_TYPE)) {
					if (model.curUser.hasLocationType(loc.type))
						mayDeleteLocation = true;
				}
			}
			
			if (loc.id < 0)
				mayDeleteLocation = false;
			
			if (mayDeleteLocation) {
				$("#btnLocationDelete").show().off("click").on("click", $.proxy(locationManager.deleteLocation, locationManager));
			}
			else
				$("#btnLocationDelete").hide();

			viewManager.updateButtons();

		},

		//Button-Handler
		cancelLocation: function () {
			
			var that = locationManager;
			
			if (model.isCurFormDirty) {
				pg.confirmMsg("Achtung", "<strong>Möchten Sie die Formulareingabe wirklich beenden?</strong><br>Nicht gespeicherte Änderungen gehen verloren.", function (e) {

					if (e) {

						viewManager.clearFormDirty();

						//für clone/new!
						if (model.curLocation.id < 0) {
							var parentLoc = Location.getLocation(model.curLocation.parentLocationId);
							that.showLocationDetails(parentLoc, false);
						}
						else{
							//normales Objekt
							that.showLocationDetails(model.curLocation, false);
						}
						return false;
					}
				});
				return false;
			}
			else {
				//nur für clone/new!
				viewManager.clearFormDirty();
				var trgLocation = that.prevShownLocationDetail;
				if (trgLocation)
					that.showLocationDetails(trgLocation, false);
				else
					$("#locationDetails").hide();
				return false;
			}
		},
		
		//------------------

		hasBootstrapSwitch: function(){
			return ($("#locationStatusBox .bootstrap-switch-mini").length > 0);
		},
		setLocationStatus: function(state){
			if (this.hasBootstrapSwitch())
				$("#chkLocationStatus").bootstrapSwitch('state', state, false);
		},
		getLocationStatus: function(){
			if (this.hasBootstrapSwitch()) {
				var val = $('#chkLocationStatus').bootstrapSwitch('state');
				if (val)
					return constants.STATUS_OBJECT_ACTIVE;
				else
					return constants.STATUS_OBJECT_INACTIVE;
			}
			else{
				return constants.STATUS_OBJECT_ACTIVE;
			}
		},

		//Objekt deaktivieren
		deactivateLocation: function () {
			this.checkSetLocationStatus(constants.STATUS_OBJECT_INACTIVE);
			return false;
		},
		
		//Objekt aktivieren
		activateLocation: function () {
			
			//parent aktiv (nicht Root)?
			if (model.curLocation.parentLocationId !== 0) {
				var parent = model.curLocation.getParent();
				if (!parent) {
					viewManager.updateButtons();
					this.setLocationStatus(false);
					return false;
				}
				if (parent.status !== constants.STATUS_OBJECT_ACTIVE) {
					pg.showMsg("Es können nur Objekte/Gruppen aktiviert werden, die sich in einer aktiven Gruppe befinden.");
					viewManager.updateButtons();
					this.setLocationStatus(false);
					return false;
				}
			}
			
			//this.execUpdateLocationStatus(model.curLocation, constants.STATUS_OBJECT_ACTIVE);
			return false;
		},
		
		//Objekt löschen
		deleteLocation: function () {
			this.checkSetLocationStatus(constants.STATUS_OBJECT_DELETED, $.proxy(this.onLocationDeleted, this));
			return false;
		},
		
		//Objekt gelöscht
		onLocationDeleted: function (location) {
			
			var that = locationManager;
			
			if (that.isCurObjOutdated)
				that.updateCurObj();
			
			//Attachments löschen
			for(var i = 0; i < location.attObj.length; i++) {
				dataManager.deleteAttachment(location.attObj[i].file, model.curClientId, location.attObj[i].fileToken);
			}
			
			//Gruppe selektieren
			model.curStateDetails.curSelectedLocationId = location.parentLocationId;
			model.curStateDetails.scrollPosition = viewManager.getScrollTop();
			viewManager.setStateDetails(model.curStateDetails);
			$("#treeLocation").jstree("select_node", "#" + model.curStateDetails.curSelectedLocationId);
			this.onLocationNodeSelected(model.curStateDetails.curSelectedLocationId);
		},
		
		//check: wird Location aktuell genutzt?
		checkSetLocationStatus: function (status, callback) {
			var t = [];

			if (model.curLocation.isAbstract === 1) {
				//ganze Gruppe mit alle Unterelementen
				var loc = Location.getActiveLocationDescendants(model.curLocation);
				for(var i = 0; i < loc.length; i++) {
					if (loc[i].isAbstract === 0) {
						var t2 = Task.getActiveTasksContainingLocation(loc[i]);
						for(var j = 0; j < t2.length; j++)
							t.push(t2[j]);
					}
				}
				t = $.unique(t);
				if (t.length) {
					if (status === constants.STATUS_OBJECT_INACTIVE) {
						pg.showMsg("Mindestens ein Objekt in dieser Gruppe wird aktuell in Aufträgen verwendet. Diese Gruppe kann daher nicht deaktiviert werden.");
						this.setLocationStatus(true);
					}
					if (status === constants.STATUS_OBJECT_DELETED)
						pg.showMsg("Mindestens ein Objekt in dieser Gruppe wird aktuell in Aufträgen verwendet. Diese Gruppe kann daher nicht gelöscht werden.");
					viewManager.updateButtons();
					return false;
				}
			}
			else {
				//nur ein einzelnes Objekt
				t = Task.getActiveTasksContainingLocation(model.curLocation);
				if (t.length) {
					if (status === constants.STATUS_OBJECT_INACTIVE) {
						pg.showMsg("Dieses Objekt wird aktuell in Aufträgen verwendet und kann daher nicht deaktiviert werden.");
						this.setLocationStatus(true);
					}
					if (status === constants.STATUS_OBJECT_DELETED)
						pg.showMsg("Dieses Objekt wird aktuell in Aufträgen verwendet und kann daher nicht gelöscht werden.");
					viewManager.updateButtons();
					return false;
				}
			}
			
			//alles ok, ausführen!
			return this.execUpdateLocationStatus(model.curLocation, status, callback);
		},
		
		//Status-Änderung durchführen (Schritt 1)
		execUpdateLocationStatus: function (location, newStatus, callback) {
			
			var that = this;
			
			switch (newStatus) {
				/*case constants.STATUS_OBJECT_ACTIVE:
				case constants.STATUS_OBJECT_INACTIVE:
					return this.execUpdateLocationStatus2(location, newStatus, callback);*/
				
				case constants.STATUS_OBJECT_DELETED:
					//Rückfrage bei Löschen
					pg.confirmMsg("L&ouml;schen", "Möchten Sie das Objekt \"" + encodeURIComponent(location.getName()) + "\" und alle darunter befindlichen Objekte wirklich l&ouml;schen?", function (e) {
						if (e) {
							return that.execUpdateLocationStatus2(location, newStatus, callback);
						}
						else
							viewManager.updateButtons();
					});
					break;
			}
		},
		
		//Status-Änderung durchführen (Schritt 2)
		execUpdateLocationStatus2: function (location, newStatus, callback) {
			
			var that = this;
			
			if (that.isCurObjOutdated)
				that.updateCurObj();
			
			location = _.cloneDeep(location);
			
			//rekursiv
			var descendents = Location.getDescendants(location);
			dataManager.setLocationStatus(location, descendents, newStatus, function (l, results) {
				
				that.afterUpdateLocationStatus(newStatus, descendents, l, results, callback);
			});
			
			return true;
		},

		//Statusänderungen im System bekanntmachen
		afterUpdateLocationStatus: function(newStatus, descendents, l, results, callback){

			var that = this;

			//neue Timestamps setzen
			for (var i=0; i<results.length; i++){
				var xl = Location.getLocation(parseInt(results[i].locationId), null, true, true, true);
				if (xl)
					xl.changedOn = pg.parseDate(results[i].changedOn);
			}

			viewManager.updateButtons();
			model.curLocation = l;

			//Kinder an mehreren Stellen aktualisieren
			var j;
			for(i = 0; i < descendents.length; i++) {
				var dl = Location.getLocation(descendents[i].locationId, null, true, true, true);
				dl.status = newStatus;

				//auch in den rohen Daten ändern
				for(j = 0; j < model.locations.length; j++) {
					if (model.locations[j].id === dl.id) {
						model.locations[j].status = dl.status;
						break;
					}
				}
			}
			l.updateStatus(newStatus);
			that.updateLocationStatus(l);

			//auch in den rohen Daten ändern
			for(j = 0; j < model.locations.length; j++) {
				if (model.locations[j].id === l.id) {
					model.locations[j].status = l.status;
					break;
				}
			}

			//Baum neu erzeugen
			var locIds = [];
			locIds.push(l.locationId);
			Location.updateLocations(null, locIds);
			Location.buildLocationTree();

			//Baum (ggf. für alle) aktualisieren, ist rekursiv
			switch (newStatus) {

				case constants.STATUS_OBJECT_ACTIVE:
				case constants.STATUS_OBJECT_INACTIVE:
					l.updateJstree(that.jstree);
					break;

				case constants.STATUS_OBJECT_DELETED:
					//noinspection JSUnresolvedFunction
					that.jstree.delete_node("#" + l.locationId);
					break;
			}

			if (callback)
				callback(l);

			switch (newStatus) {
				/*case constants.STATUS_OBJECT_ACTIVE:
					viewManager.showNotification("\"" + l.getName() + "\" wurde erfolgreich aktiviert.", constants.NOTIFICATION_SUCCESS);
					break;
				case constants.STATUS_OBJECT_INACTIVE:
					viewManager.showNotification("\"" + l.getName() + "\" wurde erfolgreich deaktiviert.", constants.NOTIFICATION_SUCCESS);
					break;*/
				case constants.STATUS_OBJECT_DELETED:
					viewManager.showNotification("\"" + l.getName() + "\" wurde erfolgreich gelöscht.", constants.NOTIFICATION_SUCCESS);
					break;
			}

		},
		
		//------------------------------------------------------------------------------------
		
		//Eigenschaften eines Objektes dynamisch anzeigen
		populateLocationValues: function (loc, skipName, showEditor) {
			
			var result = "",
				curGroup = null;

			//var fields = LocationField.getLocationFields(locType);
			loc.data.sort(function (a, b) {
				var fa = LocationField.getLocationField(a.locationFieldId);
				var fb = LocationField.getLocationField(b.locationFieldId);
				return (fa.orderIndex - fb.orderIndex);
			});

			var i,
				d,
				f,
				s,
				fd,
				digits,
				a,
				p;

			if (showEditor){

				//Werte eintragen
				for(i = 0; i < loc.data.length; i++) {
					d = loc.data[i];
					f = LocationField.getLocationField(d.locationFieldId);

					if (!f)
						continue;

					var dataEl = $("[data-id=" + f.id + "]");
					switch (f.type) {

						case LocationField.FIELD_TYPE_STRING:
						case LocationField.FIELD_TYPE_LONG_TEXT:
							dataEl.val(formatManager.getFormattedValue(f.type, d.valueString, f.options));
							break;

						case LocationField.FIELD_TYPE_INT:
							dataEl.val(formatManager.getFormattedValue(f.type, d.valueInt, f.options));
							break;

						case LocationField.FIELD_TYPE_FLOAT:
							dataEl.val(formatManager.getFormattedValue(f.type, d.valueFloat, f.options));
							break;

						case LocationField.FIELD_TYPE_DATE:

							//unbefristet: 1.1.1970 === "unbefristet"
							if ((d.valueDate) && (d.valueDate.getFullYear() === 1970)){
								$(".checkbox[data-id=" + f.id + "]").prop("checked", true);
								$("#date-picker-" + f.id).datepicker("clearDates");
							}
							else{
								fd = pg.formatDate(d.valueDate, true);
								$("[data-id=" + f.id + "]").val(fd);
							}
							break;

						case LocationField.FIELD_TYPE_LIST:

							/*jshint -W083 */
							$("[data-id=" + f.id + "] option").filter(function () {
								return ($(this).text() === d.valueString);
							}).prop('selected', true);
							break;

						case LocationField.FIELD_TYPE_LIST_OR_STRING:

							$("[data-id=" + f.id + "] option").filter(function () {
								return ($(this).text() === d.valueString);
							}).prop('selected', true);

							if (!$(".locationField[data-id=" + f.id + "]").val()) {

								if (d.valueString) {
									$(".locationField[data-id=" + f.id + "]").val(-1);
								}
								else {
									$(".locationField[data-id=" + f.id + "]").val(0);
								}
								$("#lf" + f.id).val(d.valueString);
							}
							break;

						case LocationField.FIELD_TYPE_BOOL:
							dataEl.prop("checked", (d.valueInt === 1));
							break;
					}
				}

			}
			else{

				//-------------------------------------------------------------

				//nur anzeigen

				var fields = LocationField.getLocationFields(loc.type);
				fields.sort(function (a2, b) {
					return (a2.orderIndex - b.orderIndex);
				});

				var j;
				for(i = 0; i < fields.length; i++) {
					f = fields[i];

					//Divider
					if (f.group !== curGroup) {

						// Vorherige Gruppe schließen?
						if (curGroup !== null) {
							result += '</tbody>';
							result += '</table>';
							result += '</div>';
							result += '</div>';
							result += '</div>';
						}

						curGroup = f.group;

						result += '<div class="accordion">';
						result += '<div class="panel panel-default">';
						result += '    <div class="panel-heading">';
						result += '        <h4 class="panel-title">';
						result += '            <a class="accordion-toggle accordion-toggle-styled collapsed" data-toggle="collapse" href="#collapse_' + i + '">';
						result +=                 curGroup;
						result += '            </a>';
						result += '        </h4>';
						result += '    </div>';
						result += '    <div id="collapse_' + i + '" class="panel-collapse collapse fade">';
						result += '        <table class="table">';
						result += '            <tbody>';
					}

					result += '<tr><td class="col-xs-5">' + f.name + '</td><td class="font-semibold">';

                    //Daten suchen
					var curValue = "";
                    d = loc.getData(f.id);
                    if (d){

                        s = "";
                        switch (f.type) {
                            case LocationField.FIELD_TYPE_STRING:
                            case LocationField.FIELD_TYPE_LONG_TEXT:
	                        case LocationField.FIELD_TYPE_LIST:
	                        case LocationField.FIELD_TYPE_LIST_OR_STRING:
								curValue = formatManager.getFormattedValue(f.type, d.valueString, f.options);
                                break;

                            case LocationField.FIELD_TYPE_INT:
	                            curValue = formatManager.getFormattedValue(f.type, d.valueInt, f.options);
                                break;

                            case LocationField.FIELD_TYPE_FLOAT:
	                            curValue = formatManager.getFormattedValue(f.type, d.valueFloat, f.options);
                                break;

                            case LocationField.FIELD_TYPE_DATE:

                                //unbefristet: 1.1.1970 === "unbefristet"
                                if ((d.valueDate) && (d.valueDate.getFullYear() === 1970)) {
									curValue = "unbefristet";
                                }
                                else {
                                    fd = pg.formatDate(d.valueDate, true);
									curValue = pg.handleNullString(fd);
                                }
                                break;

                            case LocationField.FIELD_TYPE_BOOL:
								curValue = ((d.valueInt === 1) ? "ja" : "nein");
                                break;
                        }
                    }

					result += curValue;
					if ((curValue !== null) && ((curValue !== "")) && f.suffix)
						result += " " + f.suffix;

                    result += "</td></tr>";

                    if (i === (fields.length - 1)) {
                        result += '</tbody>';
                        result += '</table>';
                        result += '</div>';
                        result += '</div>';
                        result += '</div>';
                    }

                }

            }

			return result;
		},
		
		//---------------------------------
		
		//Objekttyp wurde geändert: Schritt 1
		onLocationTypeChanged: function () {
			
			var locType = $("#selectLocationType").val();
			if (!locType) {
				this.updateLocationFormFields(model.curLocation, -1);
				return;
			}
			else {
				locType = parseInt(locType);
				this.updateLocationFormFields(model.curLocation, locType);
			}
			locationManager.execOnLocationTypeChanged(locType);
		},
		
		//Objekttyp wurde geändert: Schritt 2
		execOnLocationTypeChanged: function (locType) {
			
			//Gruppe?
			if (locType === -1) {
				$("#locationName").val("");
				$("#locationNameShort").val("");
				$("#locationHeader").nextAll().remove();
				return;
			}
			
			var fields = LocationField.getLocationFields(locType);
			fields.sort(function (a, b) {
				return (a.orderIndex - b.orderIndex);
			});
			
			var s = "";
			
			var j;
			var curGroup = null;
			for(var i = 0; i < fields.length; i++) {
				var f = fields[i];
				
				var defaultValue = f.defaultValue || "";
				var placeholder = f.placeholder || "";

				//Spezialfall: unrestricted date
				var isUnrestrictedDate = ((f.type === LocationField.FIELD_TYPE_DATE)  && (f.flags) && (f.flags.toLowerCase().indexOf("unrestricted") >= 0));

				//Divider
				if (f.group !== curGroup) {
					curGroup = f.group;
                    s += '<hr>';
                    s += '<h4 class="text-primary mb20">' + curGroup + '</h4>';
                }

				s += '<div id="g' + f.id + '" class="form-group locationFormBox locationFormBox-' + f.type + '">';

				var name = f.name;
				if (f.suffix)
					name += ' <span class="text-muted">(' + f.suffix + ')</span>';
				if (f.isRequired === 1)
					name += ' <span class="required" aria-required="true">*</span>';

				//boolean wird inline eingefügt
				if (f.type !== LocationField.FIELD_TYPE_BOOL) {
					s += '   <label for="locationField_' + f.id + '" class="control-label">' + name + '</label>';
				}

				switch (f.type) {
					
					case LocationField.FIELD_TYPE_STRING:
						s += '<input data-id="' + f.id + '" type="text" maxlength="255" class="form-control locationField" placeholder="' + placeholder + '" value="' + defaultValue + '">';
						break;
					
					case LocationField.FIELD_TYPE_INT:
					case LocationField.FIELD_TYPE_FLOAT:
						s += '<input data-id="' + f.id + '" type="text" maxlength="255" class="form-control locationField numeric" ';
						if (f.minVal > -1)
							s += 'min="' + f.minVal + '" ';
						if (f.maxVal > -1)
							s += 'max="' + f.maxVal + '" ';
						if (f.step > 0)
							s += 'step="' + f.step + '" ';
						s += 'placeholder="' + placeholder + '" value="' + defaultValue + '">';
						break;
					
					case LocationField.FIELD_TYPE_DATE:
						if (isUnrestrictedDate) {
                            s += '<div class="row">';
                            s += '<div class="col-xs-6">';
                            s += '<div id="date-picker-' + f.id + '" data-id="' + f.id + '" class="input-group date date-picker" data-date-format="dd.mm.yyyy">';
                        }
						else
							s += '<div class="input-group date date-picker" data-date-format="dd.mm.yyyy">';

						s += '  <input data-id="' + f.id + '" type="text" class="form-control locationField" readonly placeholder="' + placeholder + '">';
						s += '  <span class="input-group-btn">';
						s += '      <button class="btn btn-default" type="button"><i class="fa fa-calendar"></i></button>';
                        s += '  </span>';
                        s += '  </div>';

						//Checkbox "unbefristet"
						if (isUnrestrictedDate){
                            s += '</div>';
                            s += '<div class="col-xs-6">';

                            s += '<div class="location-date-field-unrestricted">';
                            s += '<label class="mt-checkbox mt-checkbox-outline">';
                            s += 'unbefristet';
                            s += '<input id="chk_' + f.id + '" data-id="' + f.id + '" type="checkbox" class="checkbox form-control2" placeholder="' + placeholder + '">';
                            s += '<span></span>';
							s += '</label>';
                            s += '</div>';

                            s += '</div>';
                        }

						s += '</div>';
						break;
					
					case LocationField.FIELD_TYPE_LIST:
						s += '<select data-id="' + f.id + '" class="form-control select2 locationField" placeholder="' + placeholder + '">';
						s += "  <option value='0' disabled selected>Bitte w&auml;hlen...</option>";
						for(j = 0; j < f.options.length; j++)
							s += "<option>" + f.options[j] + "</option>";
						s += '</select>';
						break;
					
					case LocationField.FIELD_TYPE_LIST_OR_STRING:
						s += '<select data-id="' + f.id + '" class="form-control select2 locationField" placeholder="' + placeholder + '">';
						s += "  <option value='0' disabled selected>Bitte w&auml;hlen...</option>";
						for(j = 0; j < f.options.length; j++)
							s += "<option>" + f.options[j] + "</option>";
						s += "  <option value='-1'>Eigene Eingabe</option>";
						s += '</select>';
						s += '<input data-id="' + f.id + '" id="lf' + f.id + '" type="text" maxlength="255" class="form-control mt5 inputOtherValueField" placeholder="Eigene Eingabe">';
						break;
					
					case LocationField.FIELD_TYPE_LONG_TEXT:
						s += '<textarea data-id="' + f.id + '" maxlength="4096" onpaste="pg.filterPasteText(event)" class="form-control locationField" placeholder="' + placeholder + '"></textarea>';
						break;
					
					case LocationField.FIELD_TYPE_BOOL:
						s += '<label class="mt-checkbox mt-checkbox-outline">';
                        s += name;
						s += '<input id="chk_' + f.id + '" data-id="' + f.id + '" type="checkbox" class="locationField checkbox form-control2"';
						if (defaultValue === "1")
							s += ' checked';
						s += '>';
						s += '<span></span>';
						s += '</label>';
						break;
				}

				//Help Block
                if (f.hint)
                    s += '<div class="help-block">' + f.hint + '</div>';

				s += '</div>';

            }

			//$("#locationFieldBox").html(s);
			$("#locationHeader").nextAll().remove();
			$("#locationHeader").after(s);
			
			if (s.length > 0) {
				
				//init datepickers
				setTimeout(function () {
					
					$('.date-picker').datepicker({
						language: "de",
						weekStart: 1,
						maxViewMode: 2,
						todayBtn: "linked",
						daysOfWeekHighlighted: "0,6",
						calendarWeeks: true,
						todayHighlight: true,
						autoclose: true
					}).on("change", function(){
						var dataId = parseInt($(this).attr("data-id"));
						var val = $(".locationField[data-id='" + dataId + "']").val();
						if (val){
							//reset flag
							$(".checkbox[data-id=" + dataId + "]").prop("checked", false);
						}
					});
					
					//Textfeld für eigene Eingabe nur bei entsprechender Anwahl in Liste anzeigen
					$("select.locationField").change(function () {
						var val = $(this).val();
						var dataId = parseInt($(this).attr("data-id"));
						if (val === "-1")
							$(".inputOtherValueField[data-id='" + dataId + "']").show();
						else
							$(".inputOtherValueField[data-id='" + dataId + "']").hide();
					});

					//unbefristete Felder
					$(".location-date-field-unrestricted .checkbox").change(function(){
						var checked = $(this).prop("checked");
						if (checked){
							var dataId = parseInt($(this).attr("data-id"));

							//reset date
							$("#date-picker-" + dataId).datepicker("clearDates");
						}
					});

				}, 500);
				//auf bestehende direkt anwenden
				setTimeout(function () {
					
					$("select.locationField").each(function (index, obj) {
						var val = $(this).val();
						var dataId = parseInt($(this).attr("data-id"));
						
						if (val === "-1")
							$(".inputOtherValueField[data-id='" + dataId + "']").show();
						else
							$(".inputOtherValueField[data-id='" + dataId + "']").hide();
					});
				}, 50);
				
				//--------------------------------
				//global key-input handling

				$("input.numeric")
					.keydown(function (e) {
						var fId = parseInt($(this).attr("data-id"));
						var f2 = LocationField.getLocationField(fId);
						formatManager.handleKeyDown(e, this, f2, formatManager);
					})
					.keyup(function (e) {
						var fId = parseInt($(this).attr("data-id"));
						var f2 = LocationField.getLocationField(fId);
						formatManager.handleKeyUp(e, this, f2, formatManager);
					})

					//nach Verlassen Werte korrigieren
					.focusout(function (e) {

						var fId = parseInt($(this).attr("data-id"));
						var f2 = LocationField.getLocationField(fId);

						formatManager.handleFocusOut(e, this, f2, formatManager);
					});
			}

        },
		
		//--------------------------------------------------
		
		//ausgewählten Knoten duplizieren
		cloneLocation: function () {
			
			var that = locationManager;
			
			//etwas ausgewählt?
			var locId = model.curStateDetails.curSelectedLocationId;
			if (!locId)
				return false;
			if (locId < 0)
				return false;
			
			//Root?
			if (locId === Location.LOCATION_ROOT_ID)
				return false;
			
			//Vorlage
			var oClone = Location.getLocation(parseInt(locId), null, true, false);
			
			//merken
			var loc = Location.createLocation();
			loc.clientId = model.curClientId;
			loc.parentLocationId = oClone.parentLocationId;
			loc.depth = oClone.depth;
			loc.path = oClone.path;
			loc.status = constants.STATUS_OBJECT_ACTIVE;
			loc.isAbstract = oClone.isAbstract;
			loc.attachments = oClone.attachments;
			loc.type = oClone.type;
			model.curLocation = loc;
			
			//formFields übernehmen
			model.curLocation.resetFormFields();
			model.curLocation.isClone = true;
			for(var i = 0; i < oClone.formFields.length; i++) {
				var ff = LocationFormField.createLocationFormField().fromObj(oClone.formFields[i]);
				ff.id = -1;
				
				model.curLocation.addFormField(ff);
			}

			that.showLocationDetails(model.curLocation, true);

			//Vorlage generieren
			$("#selectLocationType").val(oClone.type).trigger("change");
			that.onLocationTypeChanged();

			$("#locationContentHeader").html("Objekt duplizieren");

			$("#formLocationEdit .btnSaveLocation").show();
			$("#formLocationEdit .btnCancelLocation").show();

			$("#locationName").val("");
			$("#locationNameShort").val("");

			//alle Werte übernehmen
			that.populateLocationValues(oClone, true, true);

			//Protokolle
			$("#tabLocationProtocols").hide();

			//Attachments
			that.showLocationAttachments("#locationAttachmentTable", [], false);

			that.updateLocationStatus(loc);

			return false;
		},
		
		//----------------------------------------------------------------------------------
		
		//Fehlermeldungen entfernen
		resetLocationError: function () {
			$(".form-group").removeClass("has-error");
			$("#locationFormError").addClass("display-hide");
		},
		
		//----------------------------------------------------------------------------------
		
		//vor Speichern
		verifyLocation: function () {
			
			//etwas geändert?
			if (!model.isCurFormDirty) {
				if (model.curLocation.id < 0)
					pg.showMsg("Bitte füllen Sie das Formular vollständig aus.");
				else
					viewManager.showNotification("Es wurden keine Änderungen festgestellt.");
				viewManager.updateButtons();
				return false;
			}
			
			//blocking
			if (!viewManager.setButtonBlocking($(".btnSaveLocation"), true))
				return false;
			
			this.resetLocationError();
			
			var hasError = false;
			
			//type
			if (model.curLocation.isAbstract !== 1) {
				var locType = parseInt($("#selectLocationType").val());
				if (isNaN(locType)) {
					hasError = true;
					$(".locationTypeBox").addClass("has-error");
				}
			}
			
			//Name u Short
			if (!$("#locationName").val()) {
				hasError = true;
				$(".locationNameBox").addClass("has-error");
			}
			if (!$("#locationNameShort").val()) {
				hasError = true;
				$(".locationNameShortBox").addClass("has-error");
			}
			
			//Falls keine Gruppe: sind beide Namen unique ggü. anderen Non-Gruppen-Objekten?
			if (model.curLocation.isAbstract !== 1) {
				var locIds = Location.findLocationsByName($("#locationName").val(), false, 0);
				var i;
				if (locIds.length > 0) {
					for(i = 0; i < locIds.length; i++) {
						if (locIds[i] !== model.curLocation.locationId) {
							hasError = true;
							$(".locationNameBox").addClass("has-error");
							pg.showMsg("Dieser Name ist bereits vergeben.");
							viewManager.updateButtons();
							return false;
						}
					}
				}
				locIds = Location.findLocationsByName($("#locationNameShort").val(), true, 0);
				if (locIds.length > 0) {
					for(i = 0; i < locIds.length; i++) {
						if (locIds[i] !== model.curLocation.locationId) {
							hasError = true;
							$(".locationNameShortBox").addClass("has-error");
							pg.showMsg("Dieser Name ist bereits vergeben.");
							viewManager.updateButtons();
							return false;
						}
					}
				}
			}
			
			//generierte
			$(".locationField").each(function (index, obj) {
				
				var fId = parseInt($(this).attr("data-id"));
				var f;
				
				var val = $(this).val();
				if (!val) {

					if (isNaN(fId)) {
						var id = $(this).attr("data-xid");
						
						//special
						switch (id) {
							case "type":
								if (model.curLocation.isAbstract === 1)
									return;
								break;
							case "name":
								//nur dann anmeckern, falls Gruppe/Folder
								if (model.curLocation.isAbstract === 0)
									return;
								break;
						}
						
						fId = "-" + id;
					}
					else {

						f = LocationField.getLocationField(fId);

						//unbefristet?
						var isUnrestrictedDate = ((f.type === LocationField.FIELD_TYPE_DATE)  && (f.flags) && (f.flags.toLowerCase().indexOf("unrestricted") >= 0));
						var isChecked = $(".checkbox[data-id=" + fId + "]").prop("checked");
						if (isUnrestrictedDate && isChecked)
							return;

						if (f.isRequired === 0)
							return;
					}
					
					//Fehler markieren
					$("#g" + fId).addClass("has-error");
					hasError = true;
				}
				else {
					
					if (!isNaN(fId)) {
						f = LocationField.getLocationField(fId);
						if (f.isRequired === 0)
							return;
						
						//special: eigene Eingabe
						switch (f.type) {
							
							case LocationField.FIELD_TYPE_LIST_OR_STRING:
								if (parseInt(val) === -1) {
									val = $("#lf" + fId).val();
									if (!val) {
										//Fehler markieren
										$("#g" + fId).addClass("has-error");
										hasError = true;
									}
								}
								break;
						}
					}
				}
			});
			
			if (hasError) {
				$("#locationFormError").removeClass("display-hide");
				window.scrollTo(0, 0);
				viewManager.updateButtons();
				return false;
			}
			
			//-----------
			
			//gemerkte Location clonen!
			var curLoc = Location.createLocation().fromObj(model.curLocation);
			if (curLoc.locationId < 0) {
				curLoc.ownerId = model.curUserId;
				curLoc.status = constants.STATUS_OBJECT_ACTIVE;
			}
			curLoc.data = [];

			curLoc.name = $("#locationName").val();
			curLoc.nameShort = $("#locationNameShort").val();

			//merken für danach
			this.prevLocationStatus = model.curLocation ? model.curLocation.status : constants.STATUS_OBJECT_ACTIVE;
			this.nextLocationStatus = this.getLocationStatus();
			if (curLoc.locationId < 0) {
				this.nextLocationStatus = this.prevLocationStatus;
			}

			if (curLoc.isAbstract !== 1) {
				
				curLoc.type = parseInt($("#selectLocationType").val());
				curLoc.isAbstract = (curLoc.type === -1) ? 1 : 0;
				
				$(".locationField").each(function (index, obj) {
					var fId = parseInt($(this).attr("data-id"));
					var val = $(this).val();
					
					//die Spezialfälle wurden schon oben behandelt
					if (!isNaN(fId)) {
						
						try {
							var f = LocationField.getLocationField(fId);
							
							//merken
							var d = {
								clientId: curLoc.clientId,
								locationId: curLoc.locationId,
								locationFieldId: fId,
								version: parseInt(curLoc.version) + 1,
								valueInt: null,
								valueFloat: null,
								valueString: null,
								valueDate: null
							};
							switch (f.type) {
								case LocationField.FIELD_TYPE_LIST_OR_STRING:
									//special: select: eigene Eingabe
									if (parseInt(val) === -1)
										val = $("#lf" + fId).val();
									if (val === "")
										d.valueString = "";
									else
										d.valueString = pg.validateInput(val, 255);
									break;
								
								case LocationField.FIELD_TYPE_STRING:
								case LocationField.FIELD_TYPE_LIST:
									d.valueString = pg.validateInput(val, 255);
									break;
								case LocationField.FIELD_TYPE_LONG_TEXT:
									d.valueString = pg.validateInput(val, 4096);
									break;
								case LocationField.FIELD_TYPE_INT:
									d.valueInt = parseInt(val);
									if (isNaN(d.valueInt))
										d.valueInt = null;
									else
										d.valueInt = Math.max(-1000000000000, Math.min(d.valueInt, 1000000000000));
									break;
								case LocationField.FIELD_TYPE_FLOAT:
									d.valueFloat = parseFloat(val);
									if (isNaN(d.valueFloat))
										d.valueFloat = null;
									else
										d.valueFloat = Math.max(-1000000000000, Math.min(d.valueFloat, 1000000000000));
									break;
								case LocationField.FIELD_TYPE_DATE:

									//Spezialfall: unbefristet
									var dateRaw = null;
									if (!val){
										var isUnrestrictedDate = ((f.type === LocationField.FIELD_TYPE_DATE)  && (f.flags) && (f.flags.toLowerCase().indexOf("unrestricted") >= 0));
										var isChecked = $(".checkbox[data-id=" + fId + "]").prop("checked");
										if (isUnrestrictedDate && isChecked){
											dateRaw = new Date(1970, 0, 1);
										}
									}
									if (!dateRaw){
										var dRaw = pg.splitNonEmpty(val, ".");
										dateRaw = new Date(parseInt(dRaw[2]), parseInt(dRaw[1]) - 1, parseInt(dRaw[0]));
									}
									d.valueDate = dateRaw.getTime();

									break;
								case LocationField.FIELD_TYPE_BOOL:
									d.valueInt = ($(this).prop('checked') === true) ? 1 : 0;
									break;
							}
							curLoc.data.push(d);
						}
						catch (e) {
							$("#g" + fId).addClass("has-error");
							$("#locationFormError").removeClass("display-hide");
							viewManager.updateButtons();
							return false;
						}
					}
					
				});
				
			}

			if (!model.curAttachments)
				model.curAttachments = [];
			curLoc.attachments = Attachment.serializeAttachments(model.curAttachments);
			curLoc.attachmentsArray = model.curAttachments;
			
			//speichern!
			curLoc.formFields = model.curLocation.formFields;
			model.curLocation = curLoc;
			dataManager.addLocationData(curLoc, true, this.onLocationStored);
			
			return false;
		},
		
		//-------------------------------
		
		//Objektgruppe hinzufügen
		addLocationGroup: function () {
			locationManager.addLocation(true);
		},
		
		//einzelnes Objekt hinzufügen
		addLocation: function (isGroup) {
			
			if (isGroup === undefined)
				isGroup = false;
			
			var loc = Location.createLocation();
			loc.name = "";
			loc.nameShort = "";
			loc.clientId = model.curClientId;
			loc.status = constants.STATUS_OBJECT_ACTIVE;
			loc.parentLocationId = Math.max(0, model.curStateDetails.curSelectedLocationId);
			if (!loc.parentLocationId)
				loc.parentLocationId = 0;
			var parent = Location.getLocation(loc.parentLocationId, undefined, true);
			if (parent) {
				if (parent.isAbstract === 0) {
					parent = parent.getParent();
					if (parent) {
						loc.parentLocationId = parent.locationId;
						loc.depth = parent.depth + 1;
						if (parent.path === "")
							parent.path = "#";
						loc.path = parent.path + parent.locationId + "#";
					}
					else {
						loc.parentLocationId = 0;
						loc.depth = 1;
						loc.path = "#";
					}
				}
				else {
					loc.depth = parent.depth + 1;

					//kommt man hier hin?
					parent = parent.getParent();
					if (parent) {
						if (parent.path === "")
							parent.path = "#";
						loc.path = parent.path + parent.locationId + "#";
					}
				}
			}
			else {
				loc.depth = 0;
				loc.path = "#";
			}
			loc.isAbstract = isGroup ? 1 : 0;
			loc.type = -1;
			
			this.showLocationDetails(loc, true);
			
		},
		
		//-------------------------------
		
		//Speichern fertig (Schritt 1)
		onLocationStored: function (l) {
			
			var i,
				ff;

			//bisheriger Stand?
			var isNewLocation = (model.curLocation.id <= 0);
			
			model.curLocation.version = l.version;
			model.curLocation.id = l.id;
			model.curLocation.locationId = l.locationId;
			model.curLocation.changedOn = l.changedOn;
			model.curLocation.data = l.data;
			
			//temp. merken
			if (!model.curLocation.formFields)
				model.curLocation.resetFormFields();
			var formFields = model.curLocation.formFields;

			//im Datenbestand merken
			model.locations.push(model.curLocation);
			var locIds = [];
			locIds.push(l.locationId);
			Location.updateLocations(null, locIds);
			Location.buildLocationTree();
			
			if (isNewLocation) {

				//nur für echte Locations, nicht Gruppen
				//Fix für DB:
				//UPDATE location_formfields f INNER JOIN locations l ON (f.locationId=l.locationId AND f.clientId=l.clientId) SET f.clientId=f.clientId+1000 WHERE l.isAbstract=1;
				if (l.isAbstract === 0) {

					//cloning
					if (formFields)
						if (formFields.length && (!model.curLocation.formFields.length))
							model.curLocation.formFields = formFields;

					//formFields
					//formId -1 -> alle setzen
					var o = {
						locationId: model.curLocation.locationId,
						clientId: model.curLocation.clientId,
						formId: -1
					};

					//frisch zusammensuchen
					if (!model.curLocation.formFields.length) {

						//nur bei echten neuen (also nicht geklont): alle verwendbaren generieren
						var a = [];
						for (var j = 0; j < model.protocolForms.length; j++) {
							var pf = model.protocolForms[j];
							if (pf.isCurrentVersion)
								if (pf.hasFormFields(true)) {

									//überhaupt PK für diesen Objekttyp?
									var hasPk = false;
									var tt = pf.getTaskTypes();
									for(var jj = 0; jj < tt.length; jj++) {
										if (tt[jj].hasLocationType(l.type)) {
											hasPk = true;
											break;
										}
									}

									if (hasPk) {
										for (i = 0; i < pf.fields.length; i++) {

											ff = pf.fields[i];
											a.push({
												formFieldId: ff.id,
												formId: pf.formId
											});
										}
									}

								}
						}

						//merken
						o.formFields = a;
						model.curLocation.formFields = a;
					}
					else {
						//Clone-Daten übernehmen
						o.formFields = model.curLocation.formFields;
					}

					//locationId anpassen
					for (i = 0; i < o.formFields.length; i++) {
						ff = o.formFields[i];
						ff.locationId = l.locationId;
					}

					//speichern
					dataManager.setLocationFormFields(o, function (success) {
						locationManager.onLocationStored2(isNewLocation, l);
					});
				}
				else{
					locationManager.onLocationStored2(isNewLocation, l);
				}
				
			}
			else
				locationManager.onLocationStored2(isNewLocation, l);
		},
		
		//Speichern fertig (Schritt 2)
		onLocationStored2: function (isNewLocation, l) {

			var that = locationManager;

			if (!isNewLocation){
				that.jstree.delete_node("#" + l.locationId);
			}

			//falls Status geändert, bekannt machen!
			if ((that.prevLocationStatus >= 0) && (that.prevLocationStatus !== that.nextLocationStatus)){
				that.execUpdateLocationStatus2(model.curLocation, that.nextLocationStatus, function(l2){
					that.onLocationStored3(isNewLocation, l2);
				});
			}
			else {
				this.onLocationStored3(isNewLocation, l);
			}
		},

		onLocationStored3: function (isNewLocation, l) {

			var that = this;

			//Baum aktualisieren: neuen Eintrag hinzufügen
			var parentId = model.curLocation.parentLocationId;
			if (parentId === 0)
				parentId = Location.LOCATION_ROOT_ID;
			var data = model.curLocation.getJstreeData(true, true);
			that.jstree.create_node("#" + parentId, data.data, 'last', function () {
				//noinspection JSUnresolvedFunction
				that.jstree.open_node("#" + parentId);
			});

			this.onLocationNodeSelected(model.curLocation.locationId);

			viewManager.clearFormDirty();
			viewManager.updateButtons();
			viewManager.showNotification("Die &Auml;nderungen wurden gespeichert.", constants.NOTIFICATION_SUCCESS);

		},
		
		//----------------------------------------
		
		//Anhang hochgeladen
		onLocationAttachmentUploaded: function (file) {
			
			var that = locationManager;
			
			//anhängen an Task
			var att = Attachment.createAttachmentRaw().fromObj({
				entityType: constants.ENTITY_TYPE_LOCATION,
				entityId: model.curLocation.locationId,

				name: file.name,
				size: file.size,
				file: file.url,
				width: file.width,
				height: file.height,

				clientId: model.curClientId,
				status: constants.ATTACHMENT_STATUS_ON_SERVER,
				fileType: pg.getFiletype(file.url),
				locationId: model.curLocation.locationId
			});
			Attachment.replaceOrPushObj(model.curAttachments, att);
			Attachment.replaceOrPushObj(model.attachments, att);

			locationManager.showLocationAttachments("#locationAttachmentTable", model.curAttachments, true);
			
			if (!model.curAttachments)
				model.curAttachments = [];
			
			if (that.isCurObjOutdated)
				that.updateCurObj();
			
			model.curLocation.attachments = Attachment.serializeAttachments(model.curAttachments);
			model.curLocation.attachmentsArray = model.curAttachments;
			dataManager.setLocationAttachments(model.curLocation);
		},
		
		//-----------------------------------------
		
		//Anhänge anzeigen
		showLocationAttachments: function (trgTableId, attachments, allowEditing) {

			//ist jetzt unabhängig vom EditModus
			allowEditing = true;

			var s = "";
			var proceed = true;
			if (!attachments) {
				s += '<tr><td colspan="5">Es sind keine Dateien zugeordnet.</td></tr>';
				proceed = false;
			}
			if (proceed)
				if (attachments.length === 0) {
					s += '<tr><td colspan="5">Es sind keine Dateien zugeordnet.</td></tr>';
					proceed = false;
				}
			
			if (proceed) {
				
				//Inhalte
				for(var i = 0; i < attachments.length; i++) {
					var att = attachments[i];
					
					if (i % 2 === 0)
						s += '<tr class="gradeX even" role="row">';
					else
						s += '<tr class="gradeX odd" role="row">';
					
					//Thumbnail
					if (att.isImage()) {
						s += '<td><a href="' + att.getUrl(false) + '" class="fancybox fancybox-type-image" rel="group1">';
						s += '<img src="' + att.getPreviewImage(model.curLocation) + '" width="50" />';
					}
					else {
						s += '<td><a href="' + att.getUrl(false) + '" data-fancybox-type="iframe" class="fancybox fancybox-type-iframe">';
						s += '<div class="file-icon-wrapper"><i class="fa fa-file-pdf-o fa-2x"></i></div>';
					}
					s += '</a></td>';
					
					s += '<td>' + att.name + '</td>';
					if (att.isImage())
						s += '<td>Bild</td>';
					else
						s += '<td>PDF</td>';
					s += '<td>' + pg.getAbbrevFileSize(att.size) + '</td>';
					
					//Aktionen
					s += '<td class="text-right">';

                    if (att.isImage())
                        s += '<a href="' + att.getUrl(false) + '" class="fancybox fancybox-type-image btn grey-mint btn-xs m5"><i class="fa fa-fw fa-eye"></i></a>';
                    else
                        s += '<a href="' + att.getUrl(false) + '" data-fancybox-type="iframe" class="fancybox fancybox-type-iframe btn grey-mint btn-xs m5"><i class="fa fa-fw fa-eye"></i></a>';

                    s += '<a href="' + att.getUrl(true) + '" class="btn grey-mint btn-xs m5"><i class="fa fa-fw fa-download"></i></a>';
					
					if (allowEditing) {
						
						var mayEditLocation = false;
						if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_EDIT_ALL)) {
							mayEditLocation = true;
						}
						else {
							if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_EDIT_BY_LOCATION_TYPE)) {
								if (model.curUser.hasLocationType(model.curLocation.type))
									mayEditLocation = true;
							}
						}
						
						if (mayEditLocation)
							s += '<a data-attId="' + att.id + '" class="btn btn-danger btn-xs m5 btnDeleteAttachment"><i class="fa fa-fw fa-trash"></i></a>';
					}
					s += '</td>';
					s += '</tr>';
				}
			}
			
			$(trgTableId + " tr:gt(0)").detach();
			$(trgTableId + " tbody").append(s);
			
			//Typ für Bilder und PDFs verschieden setzen
			//http://www.fancyapps.com/fancybox/
			$(trgTableId + " tbody .fancybox-type-image").fancybox({
				openEffect: 'none',
				closeEffect: 'none',
				type : 'image'
			});
			$(trgTableId + " tbody .fancybox-type-iframe").fancybox({
				openEffect: 'none',
				closeEffect: 'none',
				type : 'iframe',
				iframe: {
					preload: false
				}
			});
			
			$(".btnDeleteAttachment").off("click").on("click", $.proxy(locationManager.deleteLocationAttachment, locationManager));
		},
		
		//Anhang löschen
		deleteLocationAttachment: function (e) {
			
			var that = this;
			
			if (e) {
				
				pg.confirmMsg("L&ouml;schen", "Möchten Sie dieses Dokument wirklich l&ouml;schen?", function (result) {
					
					if (result) {
						
						var link = $(e.currentTarget);
						if (link.attr("data-attId")) {
							var attId = parseInt(link.attr("data-attId"), 10);
							for(var i = 0; i < model.curAttachments.length; i++) {
								var att = model.curAttachments[i];
								if (Number(att.id) === attId) {
									model.curAttachments.splice(i, 1);
									
									if (that.isCurObjOutdated)
										that.updateCurObj();
									
									//persist
									model.curLocation.attachments = Attachment.serializeAttachments(model.curAttachments);
									model.curLocation.attachmentsArray = model.curAttachments;
									Attachment.deleteAttachment(att);
									dataManager.deleteAttachment(att.url, model.curClientId, att.fileToken);
									if (model.curLocation.id > 0)
										dataManager.setLocationAttachments(model.curLocation);
									
									break;
								}
							}
						}
						locationManager.showLocationAttachments("#locationAttachmentTable", model.curAttachments, true);
					}
				});
			}
			e.preventDefault();
		},
		
		//-----------------------------------------------------------------------------------------

		resetLocationDetailView: function(){

			//form fields: wieder auf View setzen
			this.isEditingFormFields = false;
			this.updateFormFieldsEditing();
		},

		//tab changed
		onLocationTabChange: function (index) {

			var that = locationManager;

			//ggf. nachfragen und Edit-Modus beenden
			var doSave = true;
			switch (model.curStateDetails.curTabIndex){
				case 0:

					if (that.isEditingLocation){
						doSave = false;
						that.checkLocationFormDirty(function () {

							//ok, raus hier...aufräumen
							that.showLocationDetails(model.curLocation, false);
							//Ziel erneut ansteuern
							viewManager.activateNavTab("#locationDetails", index);

							doSave = true;

						}, function halt() {
							//restore tab
							//that.activateTab(model.curStateDetails.curTabIndex);
							viewManager.activateNavTab("#locationDetails", model.curStateDetails.curTabIndex);
						}, false);
					}

					break;

				case 2:
					//Prüfungen
					if (that.isEditingFormFields) {
						doSave = false;
						that.checkLocationFormDirty(function () {

							//ok, raus hier...aufräumen
							that.resetLocationDetailView();

							var formId = parseInt($("#locationFormFieldsFormSelect").val());
							if (!isNaN(formId))
								locationManager.showLocationFormFields(formId, false);

							doSave = true;

						}, function halt() {
							//restore tab
							//that.activateTab(model.curStateDetails.curTabIndex);
							viewManager.activateNavTab("#locationDetails", model.curStateDetails.curTabIndex);
						}, false);
					}

					break;
			}

			if (doSave){
				model.curStateDetails.curTabIndex = index;
				that.setLocationStateDetails();
			}

		},
		setLocationStateDetails: function () {
			model.curStateDetails.scrollPosition = viewManager.getScrollTop();
			viewManager.setStateDetails(model.curStateDetails);
		},
		/*activateTab: function(index){

			//reiter oben
			$("#locationDetails ul.nav > li.active").removeClass("active");
			$("#locationDetails ul.nav > li[data-index='" + index + "']").addClass("active");

			//content unten
			$("#locationDetails .tab-pane").removeClass("active in");
			$("#locationDetails .tab-pane[data-index='" + index + "']").addClass("active in");
		},*/

		//-----------------------------------------------------------------------------------------

		//1. Hierarchie-Ebene einklappen
		minimizeAll: function(){
			//$('#treeLocation').jstree("close_all");

			var jstree = $('#treeLocation').jstree(true);
			var rootNode = jstree.get_node(Location.LOCATION_ROOT_ID);
			if (!rootNode)
				return;
			jstree.open_node("#" + Location.LOCATION_ROOT_ID);
			var children = rootNode.children;
			for(var i=0; i<children.length; i++){
				jstree.close_node("#" + children[i]);
			}
		},

		//NEIN: alles(!) ausklappen
		//Root und 1. Hierarchie-Ebene ausklappen
		maximizeAll: function(){
			$('#treeLocation').jstree("open_all");

			/*var jstree = $('#treeLocation').jstree(true);
			var rootNode = jstree.get_node(Location.LOCATION_ROOT_ID);
			jstree.open_node("#" + Location.LOCATION_ROOT_ID);
			var children = rootNode.children;
			if (children) {
				for (var i = 0; i < children.length; i++) {
					jstree.open_node("#" + children[i]);
				}
			}*/
		},

		//---------------------------------------------------------------------------------------

		//Zustand (offen, selected) verwalten
		prevTreeState: null,
		saveTreeState: function(callback){

			var that = this;
			var tree = $("#treeLocation");

			this.prevTreeState = {
				open: [],
				selected: -1
			};
			$.each(tree.jstree('full').find("li"), function (index, element){
				var locId = parseInt($(element).attr("id"));
				if (that.jstree) {
					if (that.jstree.is_selected(locId))
						that.prevTreeState.selected = locId;
					if (that.jstree.is_open(locId))
						that.prevTreeState.open.push(locId);
				}
			});

		},
		loadTreeState: function(){

			if (this.prevTreeState){

				for (var i=0; i<this.prevTreeState.open.length; i++){
					var openId = this.prevTreeState.open[i];
					this.jstree.open_node("#" + openId);
				}
			}
		},

		//---------------------------------------------------------------------------------------

		generateLocationPdf: function(){
			window.open(dataManager.getLocationPdfUrl(
				model.curLocation.nameShort,
				model.curLocation.locationId
			), "_blank");
		}

	};
	
	window.locationManager = locationManager;
}());
