(function () {
	
	//----------------------------------------------------------------
	//Kunden-Modul
	//----------------------------------------------------------------
	
	'use strict';
	
	var ClientModule = {
		
		clientModulePrototype: {
			fromObj: function (t) {
				this.id = parseInt(t.id, 10);

				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				this.clientId = parseInt(t.clientId, 10);
				this.moduleId = parseInt(t.moduleId, 10);
				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.id = this.id;
				o.clientId = this.clientId;
				o.moduleId = this.moduleId;
				
				return o;
			}
		},
		createClientModule: function () {
			return Object.create(ClientModule.clientModulePrototype);
		}
	};
	
	window.ClientModule = ClientModule;
}());