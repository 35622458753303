(function () {
	
	/*global constants:true, StackTrace:true*/
	
	'use strict';
	
	//------------------------------------------------------------------------
	//Error logging
	//------------------------------------------------------------------------
	
	var logManager = {
		
		LOGGING_URL: "https://logging.creational.de/log",
		LOGGING_ID: "54673895",
		
		prevLogErrors: [],
		
		//high-level function
		logError: function(exception, stack) {
			
			var verbose = true;
			
			//post
			try {
				
				var errorMessage = "";
				var stackTrace = "";
				var st = "";
				
				if ($.type(exception) === "string"){
					errorMessage = exception;
					stackTrace = stack || "";
					st = stackTrace;
				}
				else{
					errorMessage = JSON.stringify(exception.toString());
					st = StackTrace.getSync().join("\r\n");
					stackTrace = st;
				}
				
				//identisch? (gleicher Fehler innerhalb der letzten 30s)
				var isNewError = true;
				var chkSum = pg.getSha512(errorMessage + stackTrace, constants.CRYPT_SEED);
				var now = new Date().getTime();
				for (var i=0; i<this.prevLogErrors.length; i++) {
					var prevError = this.prevLogErrors[i];
					if (chkSum === prevError.chkSum) {
						var delta = now - prevError.timestamp;
						if (delta <= 30*1000) {
							isNewError = false;
							break;
						}
					}
				}

				console.log(errorMessage);
				console.log(st);

				//nicht, wenn lokal
				if (isNewError) {
					if ((window.location.href.indexOf("196.100.100.") < 0) && (window.location.href.indexOf("localhost") < 0)) {
						logManager.sendLog(pg.jsonSafe(errorMessage), /*pg.jsonSafe(*/stackTrace/*)*/, null);
					}
					
					//merken
					this.prevLogErrors.push({
						chkSum: chkSum,
						timestamp: now
					});
				}
				
				if (verbose) {
					if (!pg.isCordova()) {
						if (window.bootbox)
							pg.showMsg(this.getDefaultErrorMsg());
					}
				}
			}
			catch(e){console.log("logging failed: " + e);}
		},
		
		//Log senden
		sendLog: function(msg, stacktrace, dataObj, callback, extraDataObj){

			var system = 'Backend';

			var isApp = pg.isCordova();
			if (window.location.href.indexOf(":8100") > 0)
				isApp = true;
			if (msg.indexOf("forceapp") > 0)
				isApp = true;
			if (isApp){
				system = "App";
				if (!dataObj)
					dataObj = this.getEssentialAppLogData();
			}
			else{
				if (!dataObj)
					dataObj = this.getEssentialBackendLogData();
			}

			//nicht, wenn keine Daten vorliegen
			if (model){
				if (model.prevFetchTimestamp !== undefined)
					if (model.prevFetchTimestamp === 0) {
						if (callback)
							callback(false);
					}
			}

			if (extraDataObj)
				$.extend(dataObj, extraDataObj);

			//circular vermeiden
			var filteredKeys = ["children", "locationObjects", "history"];
			var dataObjJson = JSON.stringify(dataObj, function(key, value){
				if (filteredKeys.includes(key))
					return undefined;
				return value;
			});
			//keine Umbrüche
			dataObjJson = pg.replace(dataObjJson, "\\r\\n", " ");

			//wichtige Infos
			msg = model.curClientId + "/" + model.curUserId + " -> " + msg;

			var data = {
				'projectId':    this.LOGGING_ID,
				'version':      constants.RELEASE_VERSION + " / " + constants.RELEASE_BUILD,
				'system':       system,
				'device':       navigator.userAgent,
				'platform':     navigator.platform,
				'dataObj':      dataObjJson,
				'url':          window.location.href,
				'message':      msg,
				'stacktrace':   stacktrace
			};

			$.ajax({
				type:   'POST',
				url:    this.LOGGING_URL,
				data:   JSON.stringify(data)
			}).then(function success(){
				if (callback)
					callback(true);
			}, function error(a,b,c){
				if (callback)
					callback(false);
			});
			
		},
		
		getDefaultErrorMsg: function(){
			return "<strong>Es ist ein Fehler aufgetreten</strong><br>Wir wurden bereits über das Problem informiert und arbeiten an einer Lösung. Bitte versuchen Sie es in Kürze erneut.";
		},
		
		//wichtige Daten (Backend) zu Log hinzufügen
		getEssentialBackendLogData: function(){
			
			var i;
			var o = {};
			
			o.curStateDetails = model.curStateDetails;
			
			o.curTask = null;
			if (model.curTaskOrRequest)
				o.curTask = model.curTaskOrRequest.serialize();
			
			o.curCloneTaskOrRequest = null;
			if (model.curCloneTaskOrRequest)
				o.curCloneTaskOrRequest = model.curCloneTaskOrRequest.serialize();
			
			o.curLocation = null;
			if (model.curLocation)
				o.curLocation = model.curLocation.serialize();
			
			o.curProtocol = null;
			if (model.curProtocol)
				o.curProtocol = model.curProtocol.serialize();
			
			o.curTasks = [];
			if (model.curTasks)
				for (i=0; i<model.curTasks.length; i++)
					o.curTasks.push(model.curTasks[i].serialize());
			
			o.prevFetchTimestamp = model.prevFetchTimestamp;
			o.dataSource = model.dataSource;
			
			o.client = null;
			if (model.client){
				o.client = model.client.serialize();
				o.client.logoData = null;
			}
			
			o.curUser = null;
			if (model.curEditedUser)
				o.curEditedUser = model.curEditedUser.serialize();
			o.curUserGroup = null;
			if (model.curUserGroup)
				o.curUserGroup = model.curUserGroup.serialize();
			
			/*o.curAttachments = [];
			if (model.curAttachments)
				for (i=0; i<model.curAttachments.length; i++)
					o.curAttachments.push(model.curAttachments[i].serialize());*/
			
			o.curClientId = model.curClientId;
			o.curUserId = model.curUserId;
			o.curPageId = model.curPageId;
			
			o.curTaskListView = model.curTaskListView;
			o.curRequestListView = model.curRequestListView;
			
			return o;
		},
		
		//wichtige Daten (App) zu Log hinzufügen
		getEssentialAppLogData: function(){
			
			var i,
				o = {},
				x;

			//wegen großer Datenmengen nur noch solche zentralen Objekte übertragen, die aktuell sind
			var ts = new Date().getTime();
			//letzte 7 Tage
			var days = 7;
			ts -= 1000*60*60*24*days;
			var dateThreshold = new Date();
			dateThreshold.setTime(ts);
			
			o.curClientId = model.curClientId;
			o.curUserId = model.curUserId;

			//aktuelle Systemzeit
			o.now = pg.formatDateTime(new Date());
			
			o.prevFetchTimestamp = model.prevFetchTimestamp;
			o.dataSource = model.dataSource;
			try {
				//noinspection JSUnresolvedVariable
				o.networkType = navigator.connection.type;
			}
			catch (e){}
			
			//offline sync data
			o.pendingData = [];
			//noinspection JSUnresolvedVariable
			if (window.pendingData) {
				//noinspection JSUnresolvedVariable
				for(i = 0; i < window.pendingData.length; i++) {
					try {
						//noinspection JSUnresolvedVariable
						o.pendingData.push(window.pendingData[i].serialize(true));
					}
					catch (e) {
						o.pendingData.push(window.pendingData[i]);
					}
				}
			}
			
			o.client = null;
			if (model.client)
				o.client = model.client.serialize(true);
			/*o.clientModules = [];
			for(i = 0; i < model.clientModules.length; i++)
				o.clientModules.push(model.clientModules[i].serialize());*/

			//Aufträge
			o.tasksOpen = [];
			o.tasksCompleted = [];
			for(i = 0; i < model.tasks.length; i++) {
				x = model.tasks[i];
				x.updateStatus();
				//if (x.isRecent(dateThreshold))
				//	o.tasks.push(x.serialize());
				if (x.status === constants.STATUS_COMPLETED) {
					o.tasksCompleted.push(x.serializeCompact());
				}
				else{
					o.tasksOpen.push(x.serializeCompact());
				}
			}

			//Filter
			o.curTaskArchiveFilter = model.curTaskArchiveFilter;
			o.curTaskListFilter = model.curTaskListFilter;

			/*o.taskTypes = [];
			for(i = 0; i < model.taskTypes.length; i++)
				o.taskTypes.push(model.taskTypes[i].serialize());
			o.taskTypeFields = [];
			for(i = 0; i < model.taskTypeFields.length; i++)
				o.taskTypeFields.push(model.taskTypeFields[i].serialize());
			o.taskLabels = [];
			for(i = 0; i < model.taskLabels.length; i++)
				o.taskLabels.push(model.taskLabels[i].serialize());*/
			/*o.locations = [];
			for(i = 0; i < model.locations.length; i++) {
				x = model.locations[i];
				if (x.isRecent(dateThreshold))
					o.locations.push(x.serialize());
			}
			o.locationTypes = [];
			for(i = 0; i < model.locationTypes.length; i++)
				o.locationTypes.push(model.locationTypes[i].serialize());
			o.locationFields = [];
			for(i = 0; i < model.locationFields.length; i++)
				o.locationFields.push(model.locationFields[i].serialize());
			o.locationFormFields = [];*/
			/*for(i = 0; i < model.locationFormFields.length; i++)
				o.locationFormFields.push(model.locationFormFields[i].serialize());*/
			/*o.users = [];
			for(i = 0; i < model.users.length; i++)
				o.users.push(model.users[i].serialize());
			o.userRoles = [];
			for(i = 0; i < model.userRoles.length; i++)
				o.userRoles.push(model.userRoles[i].serialize());
			o.userGroups = [];
			for(i = 0; i < model.userGroups.length; i++)
				o.userGroups.push(model.userGroups[i].serialize());*/
			/*o.protocolForms = [];
			 for(i = 0; i < model.protocolForms.length; i++)
			 o.protocolForms.push(model.protocolForms[i].serialize());*/
			/*o.protocolFormFields = [];
			for(i = 0; i < model.protocolFormFields.length; i++)
				o.protocolFormFields.push(model.protocolFormFields[i].serialize());*/
			o.accounts = [];
			for(i = 0; i < model.accounts.length; i++)
				o.accounts.push(model.accounts[i].serialize());
			/*o.requests = [];
			if (model.requests) {
				for(i = 0; i < model.requests.length; i++) {
					x = model.requests[i];
					if (x.isRecent(dateThreshold))
						o.requests.push(x.serialize());
				}
			}*/
			
			return o;
		}
	};
	
	//catch uncatched
	window.onerror = function(msg, file, line, col, error) {
		logManager.logError("Global error: " + msg + " in " + file + " @" + line + "/" + col, error.stack);
	};
	
	window.logManager = logManager;
	
}());



