(function () {
	
	//------------------------------------------------------------
	// Protokolle bearbeiten
	//------------------------------------------------------------
	
	/*global
	 constants:true,
	 viewManager:true,
	 dataManager:true,
	 autosize: true,
	 User:true,
	 ProtocolForm:true,
	 moment: true,
	 Location:true,
	 Task:true,
	 taskDetailManager:true,
	 formatManager:true,
	 LocationField:true,
	 ProtocolFormField:true,
	 taskServiceManager:true,
	 Attachment:true,
	 setupFileUploader:true,
	 taskRequestEditorManager:true,
	 Signal:true,
	 authManager:true,
	 UserRole:true,
	 Incident:true,
	 UserGroup:true*/
	
	'use strict';
	
	var protocolEditManager = {

		//-------------------------------------------------------------
		//Protokoll via Backend ausfüllen
		
		//Objekte
		curTask: null,
		curLocation: null,
		curForm: null,

		//vorheriges später löschen
		deleteFiles: [],

		//-----------------------------

		isProtocolDirty: false,
		setProtocolDirty: function(val){
			this.isProtocolDirty = val;

			//weiterreichen
			if (val)
				viewManager.setFormDirty();
			else
				viewManager.clearFormDirty();
		},
		
		editTaskProtocol: function(taskId, locationId, formId, callbackComplete){

			var that = this;

			that.curTask = Task.getTask(taskId);
			if (!that.curTask) {
				viewManager.showPage(constants.PAGE_TASK_LIST, {status: -1}, null, null, null, null, callbackComplete);
				return;
			}

			//neueste Version verwenden!
			while (that.curTask.nextVersion)
				that.curTask = that.curTask.nextVersion;

			//Permissions ok?
			var mayExecuteAssignment = false;
			if (!that.curTask.isArchived()) {
				if (model.curUser.canExecuteTask(that.curTask))
					mayExecuteAssignment = true;
			}
			if (!mayExecuteAssignment){
				viewManager.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
				return false;
			}

			model.curProtocolTask = that.curTask;
			
			that.curForm = ProtocolForm.getProtocolForm(formId);
			that.curLocation = Location.getLocation(locationId, undefined, true, true, true);

			//vorheriges später löschen
			that.deleteFiles = [];

			$("#content").html(window.templates['taskProtocolEdit.html']);
			setTimeout(function () {

				//-----------------------------------------------
				
				//Aktionen zulassen/blockieren
				that.actionsAllowed = true;
				
				//Betitelungen
				$("#breadcrumbProtocolEdit1").html("Auftrag #" + that.curTask.taskId);

				if (that.curLocation) {
					$("#breadcrumbProtocolEdit2").html(that.curLocation.getName(false) + " - " + that.curForm.name);
					$("#protocolHeader").html(that.curLocation.getName(true));
				}
				else{
					$("#breadcrumbProtocolEdit2").html(that.curForm.name);
					$("#protocolHeader").html("");
				}
				$("#protocolContentHeader").html(that.curForm.name);
				
				var p = that.curTask.getProtocol(model.curUserId,
					that.curForm.formId,
					locationId);

				//-----------------------------------

				that.curForm.initProtocol(that, p, that.curTask, that.curLocation);
				that.onProtocolInitComplete();
				
				//-------------------------------------

				//nur wenn noch neu
				var isNew = true;
				if (p)
					if (p.status !== constants.STATUS_NEW)
						isNew = false;
				if (isNew) {
					//Intervall: zu früh aufgerufen?
					if (that.curTask.intervalId > 0) {
						var dueDate = that.curTask.getIntervalDates();
						if (dueDate.from) {
							var now = new Date();
							if (now < dueDate.from) {
								that.getOverlayService().showMessageBox("Bitte beachten Sie, dass dieses Protokoll erst ab " + pg.formatDate(dueDate.from) + " ausgef&uuml;llt werden sollte.", null, 'fa fa-warning');
							}
						}
					}
				}

				//-----------------------------------------------

				//Übersicht
				$("#btnBackProtocol").off("click").on("click", function () {
					that.checkFormDirty(function(){
						that.cleanUploads();
						window.history.back();
					});
				});

				//-----------------------------------------------

				//update components
				viewManager.updateLayout();

				if (callbackComplete)
					callbackComplete();

			}, constants.TEMPLATE_DELAY);

			return false;

		},

		//Backend-spezifische Anpassungen
		onProtocolInitComplete: function(){

			var that = this;

			//oben anfangen!
			window.scrollTo(0, 0);

			//autosizing
			autosize($('textarea'));

			//Select2, übernehmen Sie!
			$(".select2").select2();

			//init Datepicker
			//https://bootstrap-datepicker.readthedocs.io/en/v1.5.0/methods.html
			$('.date-picker').datepicker({
				autoclose: true,
				format: "dd.mm.yyyy",
				weekStart: 1,
				maxViewMode: 2,
				todayBtn: "linked",
				language: "de",
				daysOfWeekHighlighted: "0,6",
				calendarWeeks: true,
				todayHighlight: true,
				clearBtn: true,
				orientation: "auto bottom"
			});

			//init timePicker
			//http://eonasdan.github.io/bootstrap-datetimepicker
			$('.time-picker').datetimepicker({
				format: 'HH:mm',
				ignoreReadonly: true,
				allowInputToggle: true,
				showClear:true,
				widgetPositioning: {
					horizontal: 'auto',
					vertical: 'bottom'
				}
			});

			//Edit
			$(".btnCancelProtocol").off("click").on("click", function () {
				that.checkFormDirty(function () {
					that.cleanUploads();
					window.history.back();
				});
			});

			//Uploader
			//noinspection JSUnresolvedFunction
			setupFileUploader($("#protocolBox .fileuploadBox"), that.onImageUploaded, constants.MEDIA_TYPE_IMAGE, true);
			that.updateImages();

			//Keyboard-Input verarbeiten
			$("input.numeric", that.curForm.formEl)
				.keydown(function (e) {

					var fId = parseInt($(this).attr("data-formFieldId"));
					var f2 = that.curForm.getFormField(fId);

					formatManager.handleKeyDown(e, this, f2, formatManager);
				})
				.keyup(function (e) {
					var fId = parseInt($(this).attr("data-id"));
					var f2 = LocationField.getLocationField(fId);
					formatManager.handleKeyUp(e, this, f2, formatManager);
				})

				//nach Verlassen Werte korrigieren
				.focusout(function (e) {

					var fId = parseInt($(this).attr("data-formFieldId"));
					var f2 = that.curForm.getFormField(fId);

					formatManager.handleFocusOut(e, this, f2, formatManager);

					var index = that.curForm.getElementIndex(fId);
					that.curForm.elementData[index] = $(this).val();
					that.curForm.setProtocolDirty();
				});

			//Datum zuücksetzen
			$(".btnResetDate", that.curForm.formEl).off("click").on("click", function(){

				var fId = parseInt($(this).attr("data-formFieldId"));
				var index = that.curForm.getElementIndex(fId);

				that.curForm.elementData[index] = null;
				$("#datebox_" + index, that.curForm.formEl).datepicker('update', '');

			});

			//Zeit zuücksetzen
			$(".btnResetTime", that.curForm.formEl).off("click").on("click", function(e){

				var fId = parseInt($(this).attr("data-formFieldId"));
				var index = that.curForm.getElementIndex(fId);

				console.log("was " + that.curForm.elementData[index]);
				that.curForm.elementData[index] = null;

				//dont trigger!
				that.curForm.prevTimeBoxTs = new Date().getTime();

				//reset
				var timeEl = $("#timebox_" + index);
				timeEl.data("DateTimePicker").date(moment());
				timeEl.prop("resetDatePicker", true).find(':input').val('');

				e.preventDefault();
				return false;

			});
		},

		//wurde etwas geändert?
		checkFormDirty: function (callbackProceed, callbackHalt) {

			if (model.isCurFormDirty || this.isProtocolDirty) {
				pg.confirmMsg("Achtung", "<strong>Möchten Sie die Formulareingabe wirklich beenden?</strong><br>Nicht gespeicherte Änderungen gehen verloren.", function (e) {
					if (e) {

						//nicht nochmal nachfragen
						viewManager.clearFormDirty();

						callbackProceed();
						return false;
					}
					else {
						if (callbackHalt)
							callbackHalt();
					}
				});
				return false;
			}
			else
				callbackProceed();
		},

		//ggf. aufräumen
		onBeforeLeave: function(){
			this.cleanUploads();
		},
		
		//---------------------------------------------------------------------------
		//Interface-Funktionen für ProtocolForm

		isApp: function(){
			return false;
		},

		getOs: function () {
			
			return constants.OS_DESKTOP;
		},

		scrollTo: function (id) {
			viewManager.scrollToElement($("#" + id));
		},

		//----------------------------------------------------------------------------------

		//API: called when the form is saved or submitted
		submitProtocol: function (sendToDb, status, protocolData, attachments, incidents, saveCompleteCallback) {

			var that = this;
			
			//auto-submit unterdrücken
			if (sendToDb === undefined)
				return;
			//kein Speichern!
			sendToDb = true;
			
			//andere Aktionen währenddessen unterdrücken (Speichern, ...)
			if (!that.actionsAllowed)
				return;
			that.actionsAllowed = false;

			//special: wenn nur speichern, kann niemals COMPLETE sein!
			if ((!sendToDb) && (status === constants.STATUS_COMPLETED))
				status = constants.STATUS_PROGRESS;

			//Dubletten via Doppelklick auch hier verhindern
			var isNewProtocol = true;
			var curLocationId = that.curLocation ? that.curLocation.locationId : -1;
			for(var i = 0; i < that.curTask.protocols.length; i++) {
				var pOld = that.curTask.protocols[i];
				if ((pOld.locationId === curLocationId) && (pOld.formId === that.curForm.formId)) {

					//ist es in den vorigen Sekunden erstellt worden?
					if (pOld.createdOn) {
						var delta = (new Date()).getTime() - pOld.createdOn.getTime();
						if (delta < 3000)
							isNewProtocol = false;
					}
					break;
				}
			}
			if (!isNewProtocol){
				that.actionsAllowed = true;
				return;
			}

			//Protokoll erzeugen und an Task anhängen
			var p = that.curTask.addProtocol(
				status,
				that.curForm.formId,
				curLocationId,
				protocolData,
				attachments,
				incidents
			);

			that.curForm.clearProtocolDirty();

			//schon mal abgeschickt?
			if (p.id > 0) {
				that.actionsAllowed = true;
				return;
			}

			//-----------------------
			//Validierung
			
			//alle FormFields prüfen
			var formFields = that.curForm.getFormFields();
			if (formFields.length === 0){

				that.getOverlayService().showMessageBox("Die Formularstruktur ist defekt (FormFields leer). Bitte speichern Sie das Protokoll ab. Falls eine Interverbindung vorliegt, wurde der Betreiber bereits informiert.", function () {
					if (saveCompleteCallback)
						saveCompleteCallback(p);
				});

				that.actionsAllowed = true;
				return;
			}

			//ist vorhanden in Ergebnismenge?
			var missingFormFieldIds = [];
			var loc = Location.getLocation(curLocationId, null, true, true, true);
			if (loc) {
				for (i = 0; i < formFields.length; i++) {
					var ff = formFields[i];

					//falls nicht anzuzeigen gemäß PK, überspringen!
					if (ff.displayMode === ProtocolFormField.DISPLAY_MODE_DEPENDING_ON_LOCATION_FORM_FIELD) {
						if (!loc.hasFormField(ff.id))
							continue;
					}

					var hasFf = false;
					for (var j = 0; j < protocolData.length; j++) {
						var pd = protocolData[j];
						if (ff.id === pd.formFieldId) {
							hasFf = true;
							break;
						}
					}
					if (!hasFf) {
						missingFormFieldIds.push(ff.id);
					}
				}
				if (missingFormFieldIds.length > 0) {

					that.getOverlayService().showMessageBox("Die Formularstruktur ist defekt (FormField(s): " + missingFormFieldIds.join(",") + "). Bitte speichern Sie das Protokoll ab. Falls eine Interverbindung vorliegt, wurde der Betreiber bereits informiert.", function () {
						if (saveCompleteCallback)
							saveCompleteCallback(p);
					});

					that.actionsAllowed = true;
					return;
				}
			}

			//--------------------------------------------------------

			//Fotos übernehmen
			p.attachments = Attachment.serializeAttachments(that.curForm.attachments);
			p.attachmentsArray = that.curForm.attachments;

			//Die hochgeladenen Bilder müssen umbenannt werden
			p.renameUploads = 1;

			//alle Uploads behalten!
			that.deleteFiles = [];

			//los!
			that.sendProtocol(p, status, saveCompleteCallback);
		},

		//Protokoll absenden
		sendProtocol: function (p, status, saveCompleteCallback) {

			var that = this;

			//Protokoll samt Fotos und Flag in die Pipeline packen
			taskServiceManager.sendProtocol(this.curTask, p, function (pNew) {

				if (status === constants.STATUS_COMPLETED) {

					//damit gesamter Task abgeschlossen?
					that.curTask.updateProtocolCount();
					that.curTask.updateStatus();

					that.onSendComplete(pNew, function(pNew2) {

						viewManager.showNotification("Das Protokoll wurde erfolgreich abgeschlossen.", constants.NOTIFICATION_SUCCESS);

						that.actionsAllowed = true;

						//vergessen, dass wir hier waren
						viewManager.replaceCurrentHistoryState(constants.PAGE_TASK_DETAILS, {
							taskId: that.curTask.taskId,
							curStateDetails: {
								curTabIndex: 4
							}
						});

						//Task-Details/Protokoll-Tab anzeigen
						viewManager.showPage(constants.PAGE_TASK_DETAILS, {
							taskId: that.curTask.taskId,
							curStateDetails: {
								curTabIndex: 4
							}
						});

						saveCompleteCallback(pNew2);
					});

				}
			});
		},

		//Protokoll gesendet (ob nun lokal oder zum Server)
		onSendComplete: function (p, saveCompleteCallback) {

			var that = this;

			//direkt
			taskServiceManager.finalizeSendProtocol(that.curTask, p, function () {

				if (saveCompleteCallback)
					saveCompleteCallback(p);
			});
		},

		showValidation: function(vis){
			this.curForm.showValidation(vis);
		},

		resetValidation: function(){
			this.showValidation(false);

			//sticky header weg (issue #691)
			$("#protocolContainer > .item.item-divider.categoryItem").remove();
		},
		
		getOverlayService: function(){
			return {
				showConfirm: function(msg, callback){
					return pg.confirmMsg("Hinweis", msg, callback);
				},
				showMessageBox: function(msg, callback){
					return pg.showMsg(msg, callback);
				},
				showNotification: function(msg, level){
					return viewManager.showNotification(msg, level);
				}
			};
		},
		
		//------------------------------------------------------------------------------
		//Bild hochladen

		//Anhang hochgeladen
		onImageUploaded: function (file) {

			var that = protocolEditManager;

			//anhängen an Task
			/*var att = Attachment.createAttachmentRaw();
			att.type = constants.ENTITY_TYPE_PROTOCOL;
			att.objId = that.curTask.taskId;
			//att.id = Math.round(Math.random() * 10000);
			att.id = file.id;
				att.name = file.name;
			att.file = file;
			att.size = file.size;
			att.url = file.url;
			att.width = file.width;
			att.height = file.height;
			//att.sourceType = imgSrc;
			att.index = that.curForm.attachments.length;
			att.isDownloaded = false;*/
			var att = Attachment.createAttachmentRaw().fromObj({
				entityType: constants.ENTITY_TYPE_PROTOCOL,
				entityId: that.curProtocol ? that.curProtocol.id : -1,

				name: file.name,
				size: file.size,
				file: file.url,
				width: file.width,
				height: file.height,

				clientId: model.curClientId,
				status: constants.ATTACHMENT_STATUS_ON_SERVER,
				fileType: pg.getFiletype(file.url)
			});
			that.curForm.attachments.push(att);

			//vorheriges später löschen
			that.deleteFiles.push(att);

			that.updateImages();
		},

		updateImages: function(){
			this.showProtocolEditAttachments("#protocolAttachmentTable", this.curForm.attachments, true);
		},

		//-----------------------------------------

		//Anhänge anzeigen
		showProtocolEditAttachments: function (trgTableId, attachments, allowEditing) {

			//ist jetzt unabhängig vom EditModus
			allowEditing = true;

			var s = "";
			var proceed = true;
			if (!attachments) {
				$(trgTableId).parent().hide();
				//s += '<tr><td colspan="5">Es sind keine Dateien zugeordnet.</td></tr>';
				proceed = false;
			}
			if (proceed)
				if (attachments.length === 0) {
					$(trgTableId).parent().hide();
					//s += '<tr><td colspan="5">Es sind keine Dateien zugeordnet.</td></tr>';
					proceed = false;
				}

			if (proceed) {

				$(trgTableId).parent().show();

				//Inhalte
				for(var i = 0; i < attachments.length; i++) {
					var att = attachments[i];

					if (i % 2 === 0)
						s += '<tr class="gradeX even" role="row">';
					else
						s += '<tr class="gradeX odd" role="row">';

					var linkUrl = Attachment.getAccessUrlByPath(att.file, false);
					var downloadUrl = Attachment.getAccessUrlByPath(att.file, true);

					//Thumbnail
					if (att.isImage()) {
						s += '<td><a href="' + linkUrl + '" class="fancybox fancybox-type-image" rel="group1">';
						s += '<img src="' + att.getPreviewImage(this.curProtocol) + '" width="50" />';
					}
					else {
						s += '<td><a href="' + linkUrl + '" data-fancybox-type="iframe" class="fancybox fancybox-type-iframe">';
						s += '<div class="file-icon-wrapper"><i class="fa fa-file-pdf-o fa-2x"></i></div>';
					}
					s += '</a></td>';

					s += '<td>' + att.name + '</td>';
					if (att.isImage())
						s += '<td>Bild</td>';
					else
						s += '<td>PDF</td>';
					s += '<td>' + pg.getAbbrevFileSize(att.size) + '</td>';

					//Aktionen
					s += '<td class="text-right">';

					if (att.isImage())
						s += '<a href="' + linkUrl + '" class="fancybox fancybox-type-image btn grey-mint btn-xs m5"><i class="fa fa-fw fa-eye"></i></a>';
					else
						s += '<a href="' + linkUrl + '" data-fancybox-type="iframe" class="fancybox fancybox-type-iframe btn grey-mint btn-xs m5"><i class="fa fa-fw fa-eye"></i></a>';

					s += '<a href="' +downloadUrl + '" class="btn grey-mint btn-xs m5"><i class="fa fa-fw fa-download"></i></a>';
					s += '<a data-attId="' + att.id + '" class="btn btn-danger btn-xs m5 btnDeleteAttachment"><i class="fa fa-fw fa-trash"></i></a>';

					s += '</td>';
					s += '</tr>';
				}
			}

			$(trgTableId + " tr:gt(0)").detach();
			$(trgTableId + " tbody").append(s);

			//Typ für Bilder und PDFs verschieden setzen
			//http://www.fancyapps.com/fancybox/
			$(trgTableId + " tbody .fancybox-type-image").fancybox({
				openEffect: 'none',
				closeEffect: 'none',
				type : 'image'
			});
			$(trgTableId + " tbody .fancybox-type-iframe").fancybox({
				openEffect: 'none',
				closeEffect: 'none',
				type : 'iframe',
				iframe: {
					preload: false
				}
			});

			$(".btnDeleteAttachment").off("click").on("click", $.proxy(protocolEditManager.deleteProtocolEditAttachment, protocolEditManager));
		},

		//Anhang löschen
		deleteProtocolEditAttachment: function (e) {

			var that = this;

			if (e) {

				pg.confirmMsg("L&ouml;schen", "Möchten Sie dieses Bild wirklich l&ouml;schen?", function (result) {

					if (result) {

						var link = $(e.currentTarget);
						if (link.attr("data-attId")) {
							var attId = parseInt(link.attr("data-attId"), 10);
							for(var i = 0; i < that.curForm.attachments.length; i++) {
								var att = that.curForm.attachments[i];
								if (Number(att.id) === attId) {

									//Datei vom Server entfernen
									that.cleanUploads(att);

									that.curForm.attachments.splice(i, 1);
									break;
								}
							}
						}
						that.updateImages();
					}
				});
			}

			e.preventDefault();
		},

		getImagePath: function(path){
			return path;
		},

		//--------------------------------------------------------------------------

		//bisherige Uploads vom Server entfernen
		//entweder explizit (name) oder alle
		cleanUploads: function(singleAttachment) {

			var that = this,
				i;

			//ein einzelnes
			if (singleAttachment){
				for (i=0; i<that.deleteFiles.length; i++)

					//suche das betroffene
					if (that.deleteFiles[i] === singleAttachment) {

						dataManager.deleteAttachment(that.deleteFiles[i].url);

						//ok, das hier nicht mehr
						that.deleteFiles.splice(i, 1);
						break;
					}
			}
			else{
				//alle weg
				for (i=0; i<that.deleteFiles.length; i++) {
					dataManager.deleteAttachment(that.deleteFiles[i].url);
				}
				that.deleteFiles = [];
			}

		}
		
	};
	
	window.protocolEditManager = protocolEditManager;
}());
