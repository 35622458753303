(function () {
	
	//----------------------------------------------------------------
	//Objekt
	//Es gibt zwei Datenstrukturen: Eine Liste (model.locations) und einen Baum (model.locationRoot)
	//----------------------------------------------------------------
	
	/*global
	 constants:true,
	 LocationField:true,
	 LocationFormField:true,
	 LocationData:true,
	 Attachment:true,
	 authManager:true,
	 UserRole:true,
	 Protocol:true,
	 Task:true,
	 logManager:true
	 */
	
	'use strict';
	
	var Location = {
		
		LOCATION_ROOT_ID: 123456789,
		
		LOCATION_RESULT_NOT_PERMITTED: -2,
		
		locationPrototype: {

			id: -1,
			createdOn: null,
			createdBy: -1,
			changedOn: null,
			changedBy: -1,

			parent: null,
			children: [],
			
			clientId: -1,
			isAbstract: 1,
			locationId: -1,
			name: null,
			nameShort: null,
			parentLocationId: 0,
			version: 1,
			depth: 0,
			path: null,
			type: -1,
			data: [],
			ownerId: -1,
			isNew: 0,
			formFields: [],
			
			//----------------------------------------------------------------
			
			isActive: function () {
				
				//selbst
				if (this.status > constants.STATUS_OBJECT_ACTIVE)
					return false;
				
				//Eltern?
				/*var hierarchy = this.getHierarchy();
				 for (var k=0; k < hierarchy.length; k++) {
				 var l = hierarchy[k];
				 if (l.status > constants.STATUS_OBJECT_ACTIVE)
				 return false;
				 }*/
				
				return true;
			},
			isDeleted: function () {
				
				//selbst
				if (this.status === constants.STATUS_OBJECT_DELETED)
					return true;
				
				//Eltern?
				/*var hierarchy = this.getHierarchy();
				 for (var k=0; k < hierarchy.length; k++) {
				 var l = hierarchy[k];
				 if (l.status === constants.STATUS_OBJECT_DELETED)
				 return true;
				 }*/
				
				return false;
			},
			
			//----------------------------------------------------------------
			
			addChild: function (o) {
				//this.children.push(o);
				//shallow copy!
				var a = [];
				for(var i = 0; i < this.children.length; i++)
					a.push($.extend({}, this.children[i]));
				a.push(o);
				this.children = a;
			},
			
			//----------------------------------------------------------------
			
			fromObj: function (t) {
				
				var i;
				
				if (t.clientId)
					this.clientId = parseInt(t.clientId, 10);
				else
					this.clientId = model.curClientId;
				this.id = parseInt(t.id, 10);
				this.isAbstract = parseInt(t.isAbstract, 10);
				this.locationId = parseInt(t.locationId, 10);
				this.name = pg.restoreDbString(t.name);
				this.nameShort = pg.restoreDbString(t.nameShort);
				this.parentLocationId = parseInt(t.parentLocationId, 10);
				if (t.changedOn){
					this.createdOn = pg.parseDate(t.createdOn);
					this.createdBy = parseInt(t.createdBy, 10);
					this.changedOn = pg.parseDate(t.changedOn);
					this.changedBy = parseInt(t.changedBy, 10);
				}
				else{
					this.createdOn = new Date();
					this.createdBy = -1;
					this.changedOn = new Date();
					this.changedBy = -1;
				}
				this.version = parseInt(t.version, 10);
				this.depth = parseInt(t.depth, 10);
				this.path = t.path ? t.path : "";
				this.type = parseInt(t.type, 10);
				this.ownerId = parseInt(t.ownerId, 10);
				this.status = parseInt(t.status, 10);
				this.attachments = t.attachments ? t.attachments : "";
				
				this.isNew = (t.isNew) ? t.isNew : 0;
				
				this.data = [];
				if (t.data)
					for(i = 0; i < t.data.length; i++)
						this.data.push(LocationData.createLocationData().fromObj(t.data[i]));
				
				this.resetFormFields();
				if (t.formFields) {
					for(i = 0; i < t.formFields.length; i++) {
						this.addFormField(LocationFormField.createLocationFormField().fromObj(t.formFields[i]));
					}
				}
				
				return this;
			},
			serialize: function () {
				
				var o = {},
					i;
				
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.clientId = this.clientId;
				o.isAbstract = this.isAbstract;
				o.locationId = this.locationId;
				o.name = this.name;
				o.nameShort = this.nameShort;
				o.parentLocationId = this.parentLocationId;
				o.version = this.version;
				o.depth = this.depth;
				o.path = this.path;
				o.type = this.type;
				o.ownerId = this.ownerId;
				o.status = this.status;
				o.attachments = this.attachments;
				
				if (this.data) {
					o.data = [];
					for(i = 0; i < this.data.length; i++) {
						o.data.push(this.data[i].serialize());
					}
				}
				
				if (this.formFields){
					o.formFields = [];
					for(i = 0; i < this.formFields.length; i++){
						o.formFields.push(this.formFields[i].serialize());
					}
				}
				
				
				return o;
			},
			
			//---------------------------------------------------------------------
			
			//logging: Nur aktuelle Datensätze berücksichtigen
			isRecent: function(dateThreshold){
				if (this.changedOn > dateThreshold)
					return true;
				
				return false;
			},

			//----------------------------------------------

			//Filter anwenden
			matchesFilter: function (filterObj) {

				/*jshint -W089 */
				for(var field in filterObj) {

					var val = filterObj[field],
						isValid,
						i,
						valInt;

					if ((val === undefined) || (val === null))
						continue;

					switch (field) {
						case "locationId":
							var tId = "" + this.locationId;
							if (tId.indexOf(val) < 0)
								return false;
							break;
						case "name":
							if (this.name)
								if (this.name.toLowerCase().indexOf(val.toLowerCase()) < 0)
									return false;
							break;
						case "nameShort":
							if (this.nameShort)
								if (this.nameShort.toLowerCase().indexOf(val.toLowerCase()) < 0)
									return false;
							break;
						case "nameAny":
							isValid = false;
							if (this.name)
								if (this.name.toLowerCase().indexOf(val.toLowerCase()) >= 0)
									isValid = true;
							if (this.nameShort)
								if (this.nameShort.toLowerCase().indexOf(val.toLowerCase()) >= 0)
									isValid = true;
							if (!isValid)
								return false;
							break;

						case "locationType":
							//multiple!
							if ($.isArray(val)) {
								isValid = false;
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									switch (valInt) {
										case -1:
											//alle
											isValid = true;
											break;
										default:
											if (this.type === valInt)
												isValid = true;
											break;
									}
									if (isValid)
										break;
								}

								if (!isValid)
									return false;
							}
							break;
					}
				}

				return true;
			},
			
			//----------------------------------------------------------
			
			//caching
			updateProtocolData: function () {
				
				var a, k, l;
				
				var completedCount = 0;
				var totalCount = 0;
				
				//-------------------------
				//locations complete
				if (this.isAbstract === 1) {
					
					a = Location.getActiveLocationDescendants(this);
					
					for(k = 0; k < a.length; k++) {
						
						l = a[k];
						if (l === this)
							continue;
						if (l.isAbstract === 1)
							continue;
						
						var locCompletedCount = Protocol.getCompletedProtocolsByLocation(l.locationId, true, true).length;
						var locUnCompletedCount = Protocol.getUncompletedProtocolsByLocation(l.locationId, -1, true).length;
						
						if ((locCompletedCount > 0) && (locUnCompletedCount === 0)) {
							completedCount++;
						}
						if ((locCompletedCount > 0) || (locUnCompletedCount > 0)) {
							totalCount++;
						}
						
					}
				}
				this.locationsCompletedCount = completedCount;
				this.locationsTotalCount = totalCount;
				
				//-------------------------
				//protocols
				if (this.isAbstract === 0) {
					completedCount = Protocol.getCompletedProtocolsByLocation(this.locationId, true, true).length;
					totalCount = completedCount + Protocol.getUncompletedProtocolsByLocation(this.locationId, -1, true).length;
				}
				else {
					
					completedCount = 0;
					totalCount = 0;
					
					for(k = 0; k < a.length; k++) {
						
						l = a[k];
						if (l === this)
							continue;
						
						var protCompletedCount = Protocol.getCompletedProtocolsByLocation(l.locationId, true, true).length;
						var protUnCompletedCount = Protocol.getUncompletedProtocolsByLocation(l.locationId, -1, true).length;
						
						completedCount += protCompletedCount;
						totalCount += protCompletedCount + protUnCompletedCount;
					}
				}
				
				this.protocolsCompletedCount = completedCount;
				this.protocolsTotalCount = totalCount;
				
				
			},
			
			//----------------------------------------------------------
			//Prüfungskonfiguration

			resetFormFields: function(){
				this.formFields = [];
			},

			addFormField: function (o) {
				if (this.formFields === undefined) {
					this.resetFormFields();
				}
				
				//shallow copy!
				var a = [];
				for(var i = 0; i < this.formFields.length; i++) {
					//keine Duplikate!
					if (o.id > 0)
						if (o.id === this.formFields[i].id)
							continue;
					//a.push($.extend({}, this.formFields[i]));
					a.push(this.formFields[i]);//müsste auch reichen (*KEINE* bitweise Copy, sondern nur umhängen - da keine Mehrfachnutzung!)
				}
				a.push(o);
				this.formFields = a;
			},
			clearFormFields: function(formId){
				formId = parseInt(formId);
				for (var i=this.formFields.length-1; i>0; i--){
					if (this.formFields[i].formId === formId)
						this.formFields.splice(i, 1);
				}
			},

			hasFormFields: function () {
				if (this.formFields === undefined)
					return false;
				return (this.formFields.length > 0);
			},
			hasFormField: function (fieldId) {
				if (!this.formFields)
					return false;
				
				for(var i = 0; i < this.formFields.length; i++)
					if (this.formFields[i].formFieldId === fieldId)
						return true;
				return false;
			},
			//die entsprechenden Referenz-Objekte der Form
			getFormFieldObjects: function (form) {
				
				var a = [];
				for(var i = 0; i < this.formFields.length; i++)
					if (this.formFields[i].formId === form.formId) {
						var o = form.getFormField(this.formFields[i].formFieldId);
						if (o)
							a.push(o);
						else {
							//für Issue 253
							logManager.sendLog("getFormFieldObjects NULL: " + this.locationId + "/" + form.formId + "/" + this.formFields[i].formFieldId, "", null);
						}
					}
				return a;
			},
			setFormFields: function (formId, fields) {
				
				//clear
				for(var i = this.formFields.length - 1; i >= 0; i--) {
					if (this.formFields[i].formId === formId)
						this.formFields.splice(i, 1);
				}
				
				//add
				for(i = 0; i < fields.length; i++) {
					var f = LocationFormField.createLocationFormField();
					f.clientId = model.curClientId;
					f.locationId = this.locationId;
					f.formId = formId;
					f.formFieldId = fields[i].formFieldId;
					f.createdOn = new Date();
					f.createdBy = -1;
					f.changedOn = new Date();
					f.changedBy = -1;
					//this.formFields.push(f);
					this.addFormField(f);
				}
			},
			
			//----------------------------------------------------------
			//Anhänge
			
			updateAttachments: function () {
				this.attObj = Attachment.getAttachments(constants.ENTITY_TYPE_LOCATION, this.locationId);
				if (this.attObj.length === 0 && this.attachments){
					//legacy
					this.attObj = Attachment.deserializeAttachments(constants.ENTITY_TYPE_LOCATION, this.locationId, this.attachments);
				}
				return this.attObj;
			},
			
			getAttachments: function () {
				if ((this.attachments) && (!this.attObj))
					this.updateAttachments();
				return this.attObj;
			},
			getAttachment: function (attId) {
				for(var i = 0; i < this.attObj.length; i++)
					if (this.attObj[i].id === attId)
						return this.attObj[i];
				return null;
			},
			
			getAttachmentByIndex: function (index) {
				if ((index >= 0) && (index <= this.attObj.length - 1))
					return this.attObj[index];
				return null;
			},
			
			//----------------------------------------------------------
			
			getName: function (forceLong) {
				if (forceLong === undefined)
					forceLong = false;
				if (forceLong)
					return this.name;
				return (this.nameShort || this.name);
			},
			
			//----------------------------------------------------------------
			
			//Aufbau des Teil-Baumes
			//recursive
			addLocationEntries: function () {
				this.children = [];
				
				var a = Location.getLocationChildren(this.locationId, false);
				for(var i = 0; i < a.length; i++) {
					
					var child = a[i];
					
					if (!child.isCurrentVersion)
						continue;
					
					//keine gelöschten
					if (child.isDeleted())
						continue;
					
					this.addChild(child);
					child.parent = this;
					child.addLocationEntries();
				}
			},
			
			//----------------------------------------------------------------
			
			//Status rekursiv übernehmen
			updateStatus: function (newStatus) {
				this.status = newStatus;
				
				if (this.isAbstract === 1) {
					for(var i = 0; i < this.children.length; i++) {
						this.children[i].updateStatus(newStatus);
					}
				}
			},
			
			//----------------------------------------------------------------
			
			//Permissions prüfen
			isVisibleForCurrentUser: function () {

				//noinspection JSUnresolvedVariable
				if (this === model.locationRoot)
					return true;
				
				if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_LIST_ALL))
					return true;
			
				//Objekt
				if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_LIST_BY_LOCATION_TYPE)) {
					if (this.isAbstract !== 1) {
						//anhand des dem User zugeordneten A-Typs die möglichen O-Typen suchen und vergleichen
						if (model.curUser.hasLocationType(this.type))
							return true;
					}
					else {
						
						if (typeof(this.childrenLocationTypes) === "undefined") {
							this.updateChildLocationTypes();
						}
						
						if (typeof(this.childrenLocationTypes) !== "undefined") {
							
							//wegen nur nicht-erlaubter Kinder ausgeblendete Gruppe?
							if (this.childrenLocationTypes.length === 0)
								return true;
							for(var i = 0; i < this.childrenLocationTypes.length; i++) {
								if (model.curUser.hasLocationType(this.childrenLocationTypes[i]))
									return true;
							}
						}
						
					}
				}
				
				return false;
			},
			
			//----------------------------------------------------------------
			
			//Teil-Baum-Darstellung holen
			getJstreeData: function (recurse, includeInactive, locationTypes, filterEmptyGroups, checkPermissions, taskIncidentType) {

				var j;

				var returnObj = {
					status: 1,
					data: null
				};

				if (recurse === undefined)
					recurse = true;
				if (includeInactive === undefined)
					includeInactive = true;
				if (locationTypes === undefined)
					locationTypes = null;
				if (filterEmptyGroups === undefined)
					filterEmptyGroups = false;
				if (checkPermissions === undefined)
					checkPermissions = true;
				if (taskIncidentType === undefined)
					taskIncidentType = -1;

				if (checkPermissions) {
					if (!this.isVisibleForCurrentUser()) {
						returnObj.status = Location.LOCATION_RESULT_NOT_PERMITTED;
						return returnObj;
					}
				}

				if (locationTypes) {
					if (this.isAbstract !== 1) {
						var hasLocationTypeMatch = false;
						for(j = 0; j < locationTypes.length; j++) {
							if (this.type === locationTypes[j]) {
								hasLocationTypeMatch = true;
								break;
							}
						}
						if (!hasLocationTypeMatch){
							returnObj.status = Location.LOCATION_RESULT_NOT_PERMITTED;
							return returnObj;
						}
					}
				}

				//muss über die Protokolle gehen, nicht in die Aufträge selber!
				if (taskIncidentType >= 0){

					if (this.isAbstract !== 1) {
						var hasTaskIncidentType = this.hasIncidentType(taskIncidentType);
						if (!hasTaskIncidentType) {
							returnObj.status = Location.LOCATION_RESULT_NOT_PERMITTED;
							return returnObj;
						}
					}
				}
				
				var o = {};
				o.text = this.name;
				o.data = this.locationId;
				o.id = this.locationId;
				if (o.id === 0)
					o.id = Location.LOCATION_ROOT_ID;
				o.type = this.getJsTreeIcon();
				
				if (this.isAbstract === 1) {
					
					if (recurse) {
						
						o.children = [];
						var hasOnlyNotPermittedChildren = true;
						if (this.children.length > 0) {
							for(var i = 0; i < this.children.length; i++) {
								
								var node = this.children[i];

								//inaktive?
								if (!includeInactive)
									if (!node.isActive())
										continue;
								//gelöschte immer weg!
								if (node.isDeleted())
									continue;
								
								var child = node.getJstreeData(recurse, includeInactive, locationTypes, filterEmptyGroups, checkPermissions, taskIncidentType);
								if (child.status === Location.LOCATION_RESULT_NOT_PERMITTED)
									continue;
								else
									hasOnlyNotPermittedChildren = false;
								if (child.data)
									o.children.push(child.data);
							}
							
							if (o.children.length > 0) {
								o.children.sort(function (a, b) {
									return ((a.text < b.text) ? -1 : ((a.text > b.text) ? 1 : 0));
								});
							}
							else {
								
								if (filterEmptyGroups) {
									return returnObj;
								}
								else {
									//leere Gruppen ausblenden
									if (hasOnlyNotPermittedChildren){
										returnObj.status = Location.LOCATION_RESULT_NOT_PERMITTED;
										return returnObj;
									}
								}
								
								return returnObj;
							}
						}
						else {
							
							if (filterEmptyGroups)
								return returnObj;
						}
					}
				}

				returnObj.data = o;
				return returnObj;
			},
				
			//------------------------------------------

			//die Aufträge aller zugeordneten Protokolle holen
			getTasks: function () {

				var a = [];
				var tasks = Task.getTasks({
					allowOlderChildren: true,
					allowInvisibleTasks: true
				});
				for(var i = 0; i < tasks.length; i++) {
					var t = tasks[i];

					//passt Status?
					if (t.getMasterStatus() !== constants.TASK_STATUS_ACTIVE)
						continue;

					var taskLocations = t.getLocationIds();
					for(var j = 0; j < taskLocations.length; j++) {
						if (taskLocations[j] === this.locationId) {
							a.push(t);
							break;
						}
					}

				}
				return a;
			},

			//alle zugeordneten Protokolle holen
			getProtocols: function () {
				
				var a = [];
				var tasks = Task.getTasks({
					allowOlderChildren: true,
					allowInvisibleTasks: true
				});

				for(var i = 0; i < tasks.length; i++) {
					var t = tasks[i];
					
					//passt Status?
					if (t.getMasterStatus() !== constants.TASK_STATUS_ACTIVE)
						continue;

					var p = t.getProtocolsByLocation(this.locationId);
					for(var j = 0; j < p.length; j++)
						a.push(p[j]);
					
				}
				return a;
			},
			
			//----------------------------------------------------------------

			//alle zählen
			getIncidentCount: function(){

				var totalCount = {
					incidentCount: 0,
					defectCount: 0,
					notificationCount: 0
				};

				var partialCount;
				if (this.isAbstract === 0){

					var protocols = this.getProtocols();
					for(var j = 0; j < protocols.length; j++) {
						var p = protocols[j];

						//zählen
						partialCount = p.getIncidentCount();
						totalCount.incidentCount += partialCount.incidentCount;
						totalCount.defectCount += partialCount.defectCount;
						totalCount.notificationCount += partialCount.notificationCount;
					}

				}
				else{

					//alle Kinder
					for (var i = 0; i < this.children.length; i++) {
						var ch = this.children[i];

						//zählen
						partialCount = ch.getIncidentCount();
						totalCount.incidentCount += partialCount.incidentCount;
						totalCount.defectCount += partialCount.defectCount;
						totalCount.notificationCount += partialCount.notificationCount;
					}

				}

				return totalCount;
			},

			//je Typ zählen
			getIncidentTypeCount: function(taskIncidentType){

				var filterObj = {
					incidentType: taskIncidentType
				};

				var count = 0;
				if (this.isAbstract === 0) {

					var protocols = this.getProtocols();
					for (var j = 0; j < protocols.length; j++) {
						var p = protocols[j];

						//if (p.matchesFilter(filterObj)) {

							//zählen
							count += p.getIncidentTypeCount(taskIncidentType);

						//}
					}
				}
				else{
					//alle Kinder
					for (var i = 0; i < this.children.length; i++) {
						var ch = this.children[i];

						//zählen
						count += ch.getIncidentTypeCount(taskIncidentType);
					}
				}

				return count;
			},

			//prüfen, ob mind. ein Protokoll mit Vorkommnis-Typ dieses Objekt nutzt
			hasIncidentType: function(taskIncidentType){

				if (this.isAbstract === 1)
					return false;

				var filterObj = {
					incidentType: taskIncidentType
				};

				var protocols = this.getProtocols();
				for(var j = 0; j < protocols.length; j++) {
					if (protocols[j].matchesFilter(filterObj)) {
						return true;
					}
				}
				return false;
			},

			//----------------------------------------------------------------
			
			//Icon holen
			getJsTreeIcon: function () {
				var s = "";
				if (this.isAbstract === 0)
					s = "file";
				else
					s = "folder";
				if (!this.isActive())
					s += "-inactive";
				
				return s;
			},
			
			//----------------------------------------------------------------
			
			//JsTree aktualisieren
			updateJstree: function (jstree) {
				
				var locData = this.getJstreeData(false);
				if (locData.status >= 0) {
					//noinspection JSUnresolvedFunction
					jstree.set_text("#" + this.locationId, locData.data.text);
					//noinspection JSUnresolvedFunction
					jstree.set_type("#" + this.locationId, locData.data.type);

					for (var i = 0; i < this.children.length; i++)
						this.children[i].updateJstree(jstree);
				}
			},
			
			//----------------------------------------------------------------
			
			//rekursiv enthaltene Objekttypen holen
			updateChildLocationTypes: function () {
				
				this.childrenLocationTypes = [];
				
				if (this.isAbstract !== 1) {
					var a = [];
					a.push(this.type);
					return a;
				}
				else {
					for(var i = 0; i < this.children.length; i++) {
						var child = this.children[i];
						var childLocationTypes = child.updateChildLocationTypes();
						for(var j = 0; j < childLocationTypes.length; j++)
							this.childrenLocationTypes.push(childLocationTypes[j]);
						this.childrenLocationTypes = pg.getUnique(this.childrenLocationTypes);
					}
				}
				
				return this.childrenLocationTypes;
			},
			
			//----------------------------------------------------------------
			
			//Baum von allem befreien, was User nicht sehen darf
			removeNonPermittedItemsFromTree: function (permittedLocationTypes) {
				
				if (this.isAbstract === 1) {
					
					if (this.children.length > 0) {
						var hasOnlyNonPermittedChildren = true;
						for(var i = this.children.length - 1; i >= 0; i--) {
							
							var child = this.children[i];
							if (child.isAbstract === 1) {
								var childHasOnlyNonPermittedChildren = child.removeNonPermittedItemsFromTree();
								if (childHasOnlyNonPermittedChildren)
									this.children.splice(i, 1);
								else
									hasOnlyNonPermittedChildren = false;
							}
							else {
								if (!child.isVisibleForCurrentUser())
									this.children.splice(i, 1);
								else
									hasOnlyNonPermittedChildren = false;
							}
						}
						
						return hasOnlyNonPermittedChildren;
					}
				}
				
				return false;
			},
			
			//----------------------------------------------------------------
			
			//im LocationTree suchen und nur die direkten Kinder liefern
			getVisibleLocationChildren: function (locationId) {
				
				//hole parent
				var locationTreeItem = Location.getLocationTreeItem(locationId);
				if (locationTreeItem) {
					var a = [];
					for(var i = 0; i < locationTreeItem.children.length; i++) {
						
						var child = locationTreeItem.children[i];
						
						if (!child.isActive())
							continue;
						
						a.push(child);
					}
					return a;
				}
				
				return null;
			},
			
			//----------------------------------------------------------------
			
			//einen Eintrag im Baum finden
			getLocationTreeItem: function (locationId) {
				
				if (this.locationId === locationId)
					return this;
				
				if (this.isAbstract === 1) {
					for(var i = 0; i < this.children.length; i++) {
						var result = this.children[i].getLocationTreeItem(locationId);
						if (result)
							return result;
					}
				}
				
				return null;
			},
			
			//----------------------------------------------------------------
			
			//einen Datensatz finden
			getData: function (locationFieldId) {
				for(var j = 0; j < this.data.length; j++) {
					var d = this.data[j];
					if (d.locationFieldId === locationFieldId)
						return d;
				}
				return null;
			},
			
			//----------------------------------------------------------------

			//Daten der gesammten Kategorie/Gruppe finen
			getDataByGroup: function (groupName) {
				var a = [];
				for(var j = 0; j < this.data.length; j++) {
					var d = this.data[j];
					var f = LocationField.getLocationField(d.locationFieldId);
					if (f)
						if (f.group === groupName) {
							d.orderIndex = f.orderIndex;
							a.push(d);
						}
				}
				
				//sort by orderIndex
				a.sort(function (x, y) {
					return x.orderIndex - y.orderIndex;
				});
				
				return a;
			},
			
			//----------------------------------------------------------------
			
			getParent: function () {
				if (this.parentLocationId <= 0)
					return null;
				return Location.getLocation(this.parentLocationId, undefined, true, true, true);
			},
			getParentName: function () {
				var p = this.getParent();
				if (p)
					return p.name;
				return model.locationTree.name;
			},
			
			//----------------------------------------------------------------
			
			//Datenbestand validieren
			validateData: function () {
				for(var j = 0; j < this.data.length; j++) {
					var d = this.data[j];
					var f = LocationField.getLocationField(d.locationFieldId);
					
					//ignore obsolete information
					if (!f)
						continue;
					
					//umwandeln timestamp in Date
					if (f.type === LocationField.FIELD_TYPE_DATE)
						if ($.isNumeric(d.valueDate)) {
							d.valueDate = new Date(d.valueDate);
						}
				}
			},
			
			//----------------------------------------------------------------
			
			//tree-wise: ist this unterhalb von locationId eingeordnet?
			isEqualOrBelow: function (locationId) {
				
				//alle sind unterhalt des Root
				if (locationId === 0)
					return true;
				
				var l = this;
				while (l.parentLocationId > 0) {
					if (l.locationId === locationId)
						return true;
					l = Location.getLocation(l.parentLocationId, undefined, true, true, true);
				}
				//selbst noch mal (oberste Ebene unterhalb von Root)
				return (l.locationId === locationId);
			},
			
			//----------------------------------------------------------------
			
			//übergeordnete Knoten finden
			getHierarchy: function (includeSelf) {

				if (includeSelf === undefined)
					includeSelf = true;

				var h = [];
				var l = this;

				if (includeSelf)
					h.push(l);
				
				while (l.parentLocationId > 0) {
					
					if (l.parentLocationId === l.locationId)
						break;
					/*if ($.inArray(l.parentLocationId, h) >= 0)
					 break;*/
					
					//console.log(l.id + "/" + l.parentLocationId);
					l = Location.getLocation(l.parentLocationId, null, true, true, true);
					if (l)
						h.push(l);
					else
						break;
				}
				return h;
			},
			
			//----------------------------------------------------------------
			
			//Suche
			getMatches: function (pattern) {
				
				var results = [];
				
				if (this.name.toLowerCase().indexOf(pattern) >= 0)
					results.push({
						field: "Name",
						value: this.name
					});
				//legacy check
				if (this.nameShort)
					if (this.nameShort.toLowerCase().indexOf(pattern) >= 0)
						results.push({
							field: "NameShort",
							value: this.nameShort
						});
				
				for(var i = 0; i < this.data.length; i++) {
					
					var d = this.data[i];
					var f = LocationField.getLocationField(d.locationFieldId);
					if (f) {
						if (/*(f.isName === 0) &&*/ (f.isSearchable === 1)) {
							
							var check = "";
							var value = "";
							switch (f.type) {
								case LocationField.FIELD_TYPE_STRING:
								case LocationField.FIELD_TYPE_LONG_TEXT:
								case LocationField.FIELD_TYPE_LIST:
								case LocationField.FIELD_TYPE_LIST_OR_STRING:
									value = d.valueString;
									if (value)
										check = value.toLowerCase();
									break;
								case LocationField.FIELD_TYPE_INT:
									value = "" + d.valueInt;
									check = value;
									break;
								case LocationField.FIELD_TYPE_FLOAT:
									value = "" + d.valueFloat;
									check = value;
									break;
								case LocationField.FIELD_TYPE_DATE:
									value = pg.formatDate(d.valueDate);
									check = value;
									break;
							}
							
							if (check.indexOf(pattern) >= 0)
								results.push({
									field: f.name,
									value: value
								});
						}
					}
				}
				
				return results;
			}
		},
		
		//----------------------------------------------------------------
		
		//verschiedene Datensichten harmonisieren
		mergeLocations: function (lOld, lNew) {
			var i;

			if (lOld.data) {
				if (!lNew.data)
					lNew.data = [];
				for(i = 0; i < lOld.data.length; i++)
					pg.replaceOrPushObj(lNew.data, lOld.data[i]);
			}
			if (lOld.formFields){
				if (!lNew.formFields)
					lNew.resetFormFields();
				for(i = 0; i < lOld.formFields.length; i++)
					lNew.addFormField(lOld.formFields[i]);
			}
			return lNew;
		},
		
		//----------------------------------------------------------------
		
		//alle Kinder holen
		getLocationChildren: function (locationId, removeNonPermittedChildren) {
			
			if (removeNonPermittedChildren === undefined)
				removeNonPermittedChildren = true;

			var a = [];
			for(var i = 0; i < model.locations.length; i++) {
				
				var l = model.locations[i];
				if (l.parentLocationId === locationId) {

					if (l.isCurrentVersion) {

						//keine gelöschten
						if (l.isDeleted())
							continue;

						if (removeNonPermittedChildren)
							if (!l.isVisibleForCurrentUser())
								continue;

						a.push(l);
					}
				}
			}

			return a;
		},
		
		//----------------------------------------------------------------

		//alles darunter
		getActiveLocationDescendants: function (loc, skipFolders, allowInactiveToo) {

			if (skipFolders === undefined)
				skipFolders = false;
			if (allowInactiveToo === undefined)
				allowInactiveToo = false;

			//self ok?
			if (loc !== model.locationRoot) {
				if (!allowInactiveToo) {
					if (loc.status !== constants.STATUS_OBJECT_ACTIVE)
						return [];
				}
			}
			
			if (!loc.isVisibleForCurrentUser())
				return [];
			
			//ok, continue
			var a = [];
			if (loc !== model.locationRoot) {
				if (!skipFolders || (loc.isAbstract === 0))
					a.push(loc);
			}
			
			//Gruppe: Kinder prüfen
			if (loc.isAbstract === 1) {
				var ch = Location.getLocationChildren(loc.locationId, !allowInactiveToo);
				//Kinder durchlaufen
				for(var i = 0; i < ch.length; i++) {
					var sub = Location.getActiveLocationDescendants(ch[i], skipFolders, allowInactiveToo);
					
					//Ergebnisse merken
					for(var j = 0; j < sub.length; j++)
						a.push(sub[j]);
				}
			}
			return a;
		},
		
		//----------------------------------------------------------------
		//Ids holen
		getDescendants: function (loc) {
			
			var a = [];
			//a.push(loc);
			
			//Gruppe
			if (loc.isAbstract === 1) {
				var ch = Location.getLocationChildren(loc.locationId, false);
				//Kinder durchlaufen
				for(var i = 0; i < ch.length; i++) {
					
					//Kind
					a.push(ch[i]);
					
					//und Enkel
					if (ch[i].isAbstract === 1) {
						var sub = Location.getDescendants(ch[i]);
						for(var j = 0; j < sub.length; j++)
							a.push(sub[j]);
					}
				}
			}
			return a;
		},
		
		//----------------------------------------------------------------
		
		//Suche anstoßen
		findLocations: function (pattern) {
			var a = [];
			
			for(var j = 0; j < model.locations.length; j++) {
				var location = model.locations[j];
				
				if (location.isCurrentVersion) {
					
					//keine gelöschten
					if (!location.isActive())
						continue;
					
					if (!location.isVisibleForCurrentUser())
						continue;
					
					var results = location.getMatches(pattern);
					if (results.length > 0) {
						a.push({
							locationId: location.locationId,
							matches: results
						});
					}
				}
			}
			
			return a;
		},
		
		//----------------------------------------------------------------
		
		//einzelnes Objekt suchen
		findLocationsByName: function (name, isShort, isAbstract) {
			var a = [];
			
			var matchName = name.toLowerCase().trim();
			
			for(var j = 0; j < model.locations.length; j++) {
				var location = model.locations[j];
				
				if (location.isCurrentVersion) {
					
					//keine gelöschten
					//if (!location.isActive())
					if (location.isDeleted())
						continue;
					
					//abstract muss passen
					if (isAbstract > -1){
						if (location.isAbstract !== isAbstract)
							continue;
					}
					
					if (!location.isVisibleForCurrentUser())
						continue;
					
					var isMatch = false;
					if (isShort) {
						if (location.nameShort)
							isMatch = (location.nameShort.toLowerCase().trim() === matchName);
					}
					else {
						if (location.name)
							isMatch = (location.name.toLowerCase().trim() === matchName);
					}
					if (isMatch) {
						a.push(location.locationId);
					}
				}
			}
			
			return a;
		},
		
		//----------------------------------------------------------------
		
		//u.a. Versionierung aktualisieren
		updateLocations: function (data, updateLocationIds) {
			
			var i,
				j,
				t,
				d,
				locId,
				prevVersion;

			//hashing
			var locationIds = {};
			var applyToUpdatesOnly = false;
			if (updateLocationIds) {
				applyToUpdatesOnly = true;
				for (i = 0; i < updateLocationIds.length; i++) {
					locId = updateLocationIds[i];
					locationIds[locId] = true;
				}
			}

			//Annahme: ist aktuelle Version
			for(i = 0; i < model.locations.length; i++) {

				//skip, falls nur Updates und diese Location nicht betroffen
				if (applyToUpdatesOnly){
					if (!locationIds[model.locations[i].locationId])
						continue;
				}

				model.locations[i].isCurrentVersion = true;
			}
			
			//welche Datenversion passt am besten?
			if (data) {
				for(i = 0; i < model.locations.length; i++) {
					t = model.locations[i];

					//skip, falls nur Updates und diese Location nicht betroffen
					if (applyToUpdatesOnly){
						if (!locationIds[t.locationId])
							continue;
					}

					if (t.isAbstract === 0) {
						t.bestVersion = -1;
						for(j = 0; j < data.length; j++) {
							d = data[j];
							if ((d.locationId === t.locationId) && (d.version > t.bestVersion) && (d.version <= t.version)) {
								t.bestVersion = d.version;
							}
						}
					}
				}
			}
			
			//jetzt zuweisen etc.
			for(i = 0; i < model.locations.length; i++) {

				t = model.locations[i];

				//skip, falls nur Updates und diese Location nicht betroffen
				if (applyToUpdatesOnly){
					if (!locationIds[t.locationId])
						continue;
				}

				//data hinzufügen
				if (data)
					for(j = 0; j < data.length; j++) {
						d = data[j];
						if ((d.locationId === t.locationId) && (d.version === t.bestVersion))
							pg.replaceOrPushObj(t.data, d);
					}
				
				//Datenbestand validieren
				t.validateData();

				//---------------------------

				//Prüfungs-Konfiguration aktualisieren
				//zunächst Fields aufräumen
				/*var ff;
				for(j = 0; j < model.locationFormFields.length; j++) {
					ff = model.locationFormFields[j];
					if (ff.locationId === t.locationId)
						t.clearFormFields(ff.formId);
				}
				//Fields neu zuordnen
				for(j = 0; j < model.locationFormFields.length; j++) {
					ff = model.locationFormFields[j];
					if (ff.locationId === t.locationId)
						t.addFormField(ff);
				}*/

				//---------------------------
				
				//Versionierung
				//passiert schon auf Server, hier nur noch für Konsolidierung Cache+Server-Locations
				if (t.version > 1) {
					//Vorgänger suchen
					prevVersion = t.version - 1;
					while (prevVersion > 0) {
						var tPrev = Location.getLocation(t.locationId, prevVersion, true, true, true);
						if (tPrev) {
							t.prevVersion = tPrev;
							tPrev.nextVersion = t;
							tPrev.isCurrentVersion = false;
							break;
						}
						else
							prevVersion--;
					}
				}
			}

			//LocationFormFields von alten auf neue Versionen übernehmen
			for (i = 0; i < updateLocationIds.length; i++) {
				locId = updateLocationIds[i];
				var loc = Location.getLocation(locId, null, true, true, true);
				if (loc.version > 1) {
					var lPrev = loc.prevVersion;
					if (lPrev){
						for (var k=0; k<lPrev.formFields.length; k++){
							var lff = lPrev.formFields[k];
							loc.addFormField(lff);
						}
					}
				}
			}
		},
		
		//----------------------------------------------------------------
		
		//enthaltene Protokolle aktualisieren
		updateLocationProtocols: function () {
			
			//jetzt zuweisen etc.
			for(var i = 0; i < model.locations.length; i++) {
				var t = model.locations[i];
				
				//Protokolle zählen
				t.updateProtocolData();
			}
		},
		
		//----------------------------------------------------------------
		
		//Liste aller Locations mit bestimmten Vorgaben holen
		getLocationList: function (locationList, showFolders, limitToFirstOnly, useAbbrev, limitCount, includeInActiveDeleted, breakBeforeAdditional, includeNonPermitted) {
			if (includeInActiveDeleted === undefined)
				includeInActiveDeleted = false;
			if (limitCount === undefined)
				limitCount = 1000000000;
			if (showFolders === undefined)
				showFolders = true;
			if (limitToFirstOnly === undefined)
				limitToFirstOnly = false;
			if (useAbbrev === undefined)
				useAbbrev = false;
			if (breakBeforeAdditional === undefined)
				breakBeforeAdditional = false;
			if (includeNonPermitted === undefined)
				includeNonPermitted = false;
			
			var s = "";
			var count = 0;
			var othersCount = 0;
			for(var j = 0; j < locationList.length; j++) {
				var loc = Location.getLocation(locationList[j], undefined, includeInActiveDeleted, includeInActiveDeleted, includeNonPermitted);
				
				if (loc === null)
					continue;
				
				if ((!showFolders) && (loc.isAbstract === 1))
					continue;
				
				count++;
				if (s.length > 0) {
					
					if (limitToFirstOnly) {
						othersCount++;
						continue;
					}
					
					if (count > limitCount)
						continue;
					
					s += "<br>";
				}
				
				if (useAbbrev)
					s += '<span class="text-nowrap">' + loc.getName() + '</span>';
				else
					s += '<span class="text-nowrap">' + loc.name + '</span>';
				
				
			}
			
			if (limitToFirstOnly)
				if (othersCount > 0)
					s += "<span class=\"text-muted\"><em> + " + othersCount + " weitere</em></span>";
			if (count > limitCount) {
				if (breakBeforeAdditional)
					s += "<br/>";
				s += "<span class=\"text-muted\"><em> + " + (count - limitCount) + " weitere</em></span>";
			}
			
			return s;
		},
		
		//----------------------------------------------------------------
		
		//einzelne Location holen
		getLocation: function (locationId, version, includeInactive, includeDeleted, includeNonPermitted) {
			
			if (includeInactive === undefined)
				includeInactive = false;
			if (includeDeleted === undefined)
				includeDeleted = false;
			if (includeNonPermitted === undefined)
				includeNonPermitted = false;
			
			for(var i = 0; i < model.locations.length; i++) {
				
				var l = model.locations[i];
				
				if (l.locationId === locationId) {
					
					//keine gelöschten
					if (!includeInactive)
						if (!l.isActive())
							continue;
					if (!includeDeleted)
						if (l.isDeleted())
							continue;
					
					if (version) {
						if (l.version !== version)
							continue;
					}
					else {
						if (l.isCurrentVersion !== undefined) {
							if (!l.isCurrentVersion)
								continue;
						}
					}
					
					if (!includeNonPermitted)
						if (!l.isVisibleForCurrentUser())
							continue;

					return l;
				}
			}
			return null;
		},

		//----------------------------------------------------------------
		//mind. eine Location vorhanden?

		hasLocationsOfType: function(locationType){
			for(var i = 0; i < model.locations.length; i++) {

				var l = model.locations[i];

				if (!l.isActive())
					continue;
				if (l.isDeleted())
					continue;

				if (l.type === locationType)
					return true;
			}

			return false;
		},

		//----------------------------------------------------------------
		//only used from App
		getLocations: function (includeInactive) {
			
			if (includeInactive === undefined)
				includeInactive = false;
			
			var a = [];
			for(var i = 0; i < model.locations.length; i++) {
				var l = model.locations[i];
				
				//keine gelöschten
				if (!includeInactive)
					if (!l.isActive())
						continue;
				if (l.isDeleted())
					continue;
				
				if (!l.isVisibleForCurrentUser())
					continue;
				
				a.push(l);
			}
			
			return a;
		},
		
		//----------------------------------------------------------------
		
		//Baum aufbauen
		buildLocationTree: function () {
			
			//Root
			model.locationTree = Location.createLocation();
			model.locationTree.name = "Alle";
			model.locationTree.isAbstract = 1;
			model.locationTree.locationId = 0;
			
			//add children
			model.locationTree.addLocationEntries();

			//markieren
			model.locationTree.updateChildLocationTypes();
			
			//aussieben
			if (!authManager.hasPermission(UserRole.PERMISSION_LOCATION_LIST_ALL)) {
				if (authManager.hasPermission(UserRole.PERMISSION_LOCATION_LIST_BY_LOCATION_TYPE)) {
					model.locationTree.removeNonPermittedItemsFromTree(model.curUser.locationTypeIds);
				}
			}
		},
		
		//----------------------------------------------------------------
		
		//sichtbare Knoten holen
		getVisibleLocationChildren: function (locationId) {
			return model.locationTree.getVisibleLocationChildren(locationId);
		},
		
		//----------------------------------------------------------------
		
		//Knoten holen
		getLocationTreeItem: function (locationId) {
			return model.locationTree.getLocationTreeItem(locationId);
		},
		
		//----------------------------------------------------------------
		
		//Liste aus serialisiertem String aufbauen
		buildLocationList: function (locations, includeInactive, includeDeleted, includeNonPermitted) {
			
			if (includeInactive === undefined)
				includeInactive = true;
			if (includeDeleted === undefined)
				includeDeleted = false;
			if (includeNonPermitted === undefined)
				includeNonPermitted = false;
			
			var a = locations.split("#");
			if (a.length > 0)
				if (a[a.length - 1].length === 0)
					a.splice(a.length - 1, 1);
			for(var i = 0; i < a.length; i++)
				a[i] = parseInt(a[i], 10);
			
			//validieren: Status
			for(i = a.length - 1; i >= 0; i--) {
				var loc = Location.getLocation(a[i], undefined, includeInactive, includeDeleted, includeNonPermitted);
				if (!loc)
					a.splice(i, 1);
			}
			
			return a;
		},
		
		createLocation: function () {
			return Object.create(Location.locationPrototype);
		}
	};
	
	window.Location = Location;
}());
