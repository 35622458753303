(function () {
	
	//----------------------------------------------------------------
	//User-Account
	//----------------------------------------------------------------
	
	'use strict';
	
	var Account = {
		
		accountPrototype: {
			
			fromObj: function (t) {
				this.name = pg.restoreDbString(t.name);
				this.id = parseInt(t.id, 10);
				this.type = t.type ? +t.type : constants.CLIENT_TYPE_CUSTOMER;
				this.latestActivity = t.latestActivity ? pg.parseDate(t.latestActivity) : null;
				
				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.name = this.name;
				o.id = this.id;
				
				return o;
			}
			
			//----------------------------------------------------------------
		},
		
		getAccount: function(clientId){
			clientId = parseInt(clientId);
			for (var i=0; i<model.accounts.length; i++){
				if (model.accounts[i].id === clientId)
					return model.accounts[i];
			}
			return null;
		},
		
		createAccount: function () {
			return Object.create(Account.accountPrototype);
		}
	};
	
	window.Account = Account;
}());