(function () {
	
	//----------------------------------------------------------------
	//Objekt-Daten
	//----------------------------------------------------------------
	
	'use strict';
	
	var LocationData = {
		
		locationDataPrototype: {
			
			clientId: -1,
			id: -1,
			locationId: -1,
			locationFieldId: -1,
			createdOn: null,
			createdBy: -1,
			changedOn: null,
			changedBy: -1,
			valueInt: null,
			valueFloat: null,
			valueString: null,
			version: 1,
			valueDate: null,
			
			fromObj: function (t) {
				
				if (t.clientId)
					this.clientId = parseInt(t.clientId, 10);
				else
					this.clientId = model.curClientId;

				if (t.createdOn) {
					this.createdOn = pg.parseDate(t.createdOn);
					this.createdBy = parseInt(t.createdBy, 10);
					this.changedOn = pg.parseDate(t.changedOn);
					this.changedBy = parseInt(t.changedBy, 10);
				}
				else{
					this.createdOn = new Date();
					this.createdBy = -1;
					this.changedOn = new Date();
					this.changedBy = -1;
				}

				this.id = parseInt(t.id, 10);
				this.locationId = parseInt(t.locationId, 10);
				this.locationFieldId = parseInt(t.locationFieldId, 10);
				this.version = parseInt(t.version, 10);
				
				if (t.valueInt) {
					this.valueInt = parseInt(t.valueInt);
					if (isNaN(this.valueInt))
						this.valueInt = null;
				}
				else
					this.valueInt = null;
				
				if (t.valueFloat) {
					this.valueFloat = parseFloat(t.valueFloat);
					if (isNaN(this.valueFloat))
						this.valueFloat = null;
				}
				else
					this.valueFloat = null;
				
				if (t.valueString)
					this.valueString = pg.restoreDbString(t.valueString);
				else
					this.valueString = null;
				
				if (t.valueDate) {
					this.valueDate = pg.parseDate(t.valueDate);
					if (isNaN(this.valueDate))
						this.valueDate = null;
				}
				else
					this.valueDate = null;
				
				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.clientId = this.clientId;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.id = this.id;
				o.locationId = this.locationId;
				o.locationFieldId = this.locationFieldId;
				o.version = this.version;
				
				o.valueInt = this.valueInt;
				o.valueFloat = this.valueFloat;
				o.valueString = this.valueString;
				o.valueDate = pg.buildDate(this.valueDate);
				
				return o;
			}
		},
		
		createLocationData: function () {
			return Object.create(LocationData.locationDataPrototype);
		}
	};
	
	window.LocationData = LocationData;
}());