(function () {
	
	//----------------------------------------------------------------
	//auf Client persistierte Objekte, die zum Server gesendet werden, sobald wieder Netz verfügbar ist (z.B. Uploads-Metadaten, Protokolle)
	//----------------------------------------------------------------
	 
    'use strict';

    var PendingObject = {
        
        pendingObjectPrototype: {

            type: 0,
            id: 0,
            data: null,
			attempts: 0,

            fromObj: function (t) {
                this.type = parseInt(t.type, 10);
                this.id = parseInt(t.id, 10);
                this.data = t.data;
                this.attempts = t.attempts ? parseInt(t.attempts) : 0;
                
                if (t.timestamp)
                	this.timestamp = pg.parseDate(t.timestamp);
                
                return this;
            },

            serialize: function (skipImageData) {
                if (skipImageData === undefined)
	                skipImageData = false;
            	
            	var o = {};

                o.type = this.type;
                o.id = this.id;
                //doch, immer verwenden - auch für Uploads
                //if ((this.type !== constants.COMMAND_TYPE_UPLOAD_ATTACHMENT) || (!skipImageData))
                    o.data = this.data;
				o.attempts = this.attempts;

				if ($.type(this.timestamp) !== "date")
					this.timestamp = pg.parseDate(this.timestamp);
                o.timestamp = pg.buildDate(this.timestamp);

                return o;
            }
        },

        createPendingObject: function (type, id, timestamp, data) {
            return $.extend(Object.create(PendingObject.pendingObjectPrototype), {
                type: type,
                id: id,
	            timestamp: timestamp,
                data: data,
				attempts: 0
            });
        }
    };

    window.PendingObject = PendingObject;
}());
