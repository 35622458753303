(function () {
	
	//----------------------------------------------------------------
	//Release-Version
	//----------------------------------------------------------------
	
	'use strict';
	
	var Version = {
		
		versionPrototype: {
			
			fromObj: function (t) {
				
				this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				this.version = t.version;
				this.mustUpdate = parseInt(t.mustUpdate, 10);
				this.mustClearCache = parseInt(t.mustClearCache, 10);
				this.description = t.description;
				this.build = t.build || null;
				return this;
			}
		},

		//---------------------------------------------------------

		//compute version value (deserialize)
		getVersionValue: function(verString){
			//assume x.y.z
			var curAppVersionItems = verString.split(".");
			var curAppVersionValue = 0;
			if (curAppVersionItems.length >= 1)
				curAppVersionValue += 100*100*parseInt(curAppVersionItems[0]);
			if (curAppVersionItems.length >= 2)
				curAppVersionValue += 100*parseInt(curAppVersionItems[1]);
			if (curAppVersionItems.length >= 3)
				curAppVersionValue += parseInt(curAppVersionItems[2]);
			return curAppVersionValue;
		},
		matchesRequiredVersion: function(curVersionString, minVersionString){
			var vCur = this.getVersionValue(curVersionString);
			var vMin = this.getVersionValue(minVersionString);
			return (vCur >= vMin);
		},

		//---------------------------------------------------------

		createVersion: function () {
			return Object.create(Version.versionPrototype);
		}
	};
	
	window.Version = Version;
}());