(function () {
		
		//------------------------------------------------------------------------
		//Anzeige der Auftrags-Details
		//------------------------------------------------------------------------
		
		/*global
		 constants:true,
		 viewManager:true,
		 protocolManager:true,
		 dataManager:true,
		 TaskType:true,
		 UserGroup:true,
		 Location:true,
		 ProtocolForm:true,
		 User:true,
		 Signal:true,
		 Task:true,
		 authManager:true,
		 Request:true,
		 UserRole:true,
		 LocationType:true,
		 TaskTypeField:true,
		 TaskState:true,
		 Comment: true,
		 ProtocolDataItem:true,
		 ProtocolFormField:true,
		 taskRequestEditorManager:true*/
		
		'use strict';
		
		var taskDetailManager = {
			
			hasIntervalEditorBeenShown: false,
			
			//------------------------------------------------------------------------------------------------------------------------------------------------
			
			//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
			isCurObjOutdated: false,
			checkModelChanged: function(data, oldModel){

				var that = taskDetailManager;
				
				var i,
					j,
					tsOld,
					tsNew,
					hasMatch;

				if (data.tasks) {
					for(i = 0; i < data.tasks.length; i++) {
						
						if (parseInt(data.tasks[i].taskId) === oldModel.curTask.taskId){
							
							that.isCurObjOutdated = true;
							
							tsOld = oldModel.curTask.changedOn.getTime();
							tsNew = pg.parseDate(data.tasks[i].changedOn).getTime();
							if (tsNew > tsOld)
								return true;
						}
					}
				}
				if (data.taskStates) {
					for(i = 0; i < data.taskStates.length; i++) {
						
						if (parseInt(data.taskStates[i].taskId) === oldModel.curTask.taskId){
							
							hasMatch = false;
							for (j=0; j<oldModel.curTask.states.length; j++) {
								
								if (oldModel.curTask.states[j].id === parseInt(data.taskStates[i].id)){
									
									that.isCurObjOutdated = true;
									
									hasMatch = true;
									tsOld = oldModel.curTask.states[j].changedOn.getTime();
									tsNew = pg.parseDate(data.taskStates[i].changedOn).getTime();
									if (tsNew !== tsOld)
										return true;
								}
							}
							//neues Objekt
							if (!hasMatch) {
								that.isCurObjOutdated = true;
								return true;
							}
						}
					}
				}
				if (data.protocols) {
					for(i = 0; i < data.protocols.length; i++) {
						
						if (parseInt(data.protocols[i].taskId) === oldModel.curTask.taskId){
							
							hasMatch = false;
							for (j=0; j<oldModel.curTask.protocols.length; j++) {
								
								if (oldModel.curTask.protocols[j].id === parseInt(data.protocols[i].id)){
									
									that.isCurObjOutdated = true;
									
									hasMatch = true;
									tsOld = oldModel.curTask.protocols[j].changedOn.getTime();
									tsNew = pg.parseDate(data.protocols[i].changedOn).getTime();
									if (tsNew !== tsOld)
										return true;
								}
							}
							//neues Objekt
							if (!hasMatch) {
								that.isCurObjOutdated = true;
								return true;
							}
						}
					}
				}
				if (data.incidents) {
					for(i = 0; i < data.incidents.length; i++) {
						
						if (parseInt(data.incidents[i].taskId) === oldModel.curTask.taskId){
							
							hasMatch = false;
							var incId = parseInt(data.incidents[i].id);
							var oldInc = oldModel.curTask.getIncident(incId);
							if (oldInc){
								
								that.isCurObjOutdated = true;
								
								hasMatch = true;
								tsOld = oldInc.changedOn.getTime();
								tsNew = pg.parseDate(data.incidents[i].changedOn).getTime();
								if (tsNew !== tsOld)
									return true;
							}
							//neues Objekt
							if (!hasMatch) {
								that.isCurObjOutdated = true;
								return true;
							}
						}
					}
				}
				if (data.signals) {
					for(i = 0; i < data.signals.length; i++) {
						
						if (parseInt(data.signals[i].taskId) === oldModel.curTask.taskId){
							
							hasMatch = false;
							var sigId = parseInt(data.signals[i].id);
							var oldSig = oldModel.curTask.getSignal(sigId);
							if (oldSig){
								
								that.isCurObjOutdated = true;
								
								hasMatch = true;
								tsOld = oldSig.changedOn.getTime();
								tsNew = pg.parseDate(data.signals[i].changedOn).getTime();
								if (tsNew !== tsOld)
									return true;
							}
							//neues Objekt
							if (!hasMatch) {
								that.isCurObjOutdated = true;
								return true;
							}
						}
					}
				}
				if (data.comments) {
					for(i = 0; i < data.comments.length; i++) {
						
						if (parseInt(data.comments[i].entityType) === constants.ENTITY_TYPE_TASK) {
							if (parseInt(data.comments[i].entityId) === oldModel.curTask.taskId) {
								
								hasMatch = false;
								for(j = 0; j < oldModel.curTask.comments.length; j++) {
									
									if (oldModel.curTask.comments[j].id === parseInt(data.comments[i].id)) {
										
										that.isCurObjOutdated = true;
										
										hasMatch = true;
										tsOld = oldModel.curTask.comments[j].changedOn.getTime();
										tsNew = pg.parseDate(data.comments[i].changedOn).getTime();
										if (tsNew !== tsOld)
											return true;
									}
								}
								//neues Objekt
								if (!hasMatch) {
									that.isCurObjOutdated = true;
									return true;
								}
							}
						}
					}
				}
				
				return false;
			},
			updateCurObj: function(o){
				o = o || Task.getTask(model.curTask.taskId);
				model.curTask = o;
				this.isCurObjOutdated = false;
			},
			
			//---------------------------------------------------------------------------------
			
			//Task anzeigen (Read-Only)
			showTask: function (taskId, isNew, args, callbackComplete) {
				
				var that = taskDetailManager;
				this.isRequest = false;

				args = args || {};

				if (isNew === undefined)
					isNew = false;
				
				if (!authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS)) {
					viewManager.execShowPage(constants.PAGE_OVERVIEW, null, null, null, callbackComplete);
					return false;
				}
				
				var i;
				var t = Task.getTask(taskId);
				
				if (!t) {
					viewManager.showPage(constants.PAGE_TASK_LIST, {status: -1}, null, null, null, null, callbackComplete);
					return;
				}

				//keine versteckten anzeigen
				if (t.visibilityType === Task.VISIBILITY_TYPE_HIDDEN) {
					viewManager.showPage(constants.PAGE_TASK_LIST, {status: -1}, null, null, null, null, callbackComplete);
					return;
				}

				viewManager.setScrollTop(0);
				
				//-------------------------------
				
				//neueste Version verwenden!
				while (t.nextVersion)
					t = t.nextVersion;
					
				//darf diesen konkreten Auftrag sehen?
				var tt = -1;
				var matchOwner = false;
				if (!authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_ALL)) {
					if (authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_OWN)) {
						matchOwner = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_BY_TASK_TYPE)) {
							tt = t.taskType;
						}
					}
					
					//jetzt prüfen
					if (!t.canBeSeenByUser(matchOwner, tt)) {
						viewManager.showPage(constants.PAGE_TASK_LIST, {status: -1}, null, null, null, null, callbackComplete);
						return;
					}
				}
				
				//-------------------------------
				
				//ok!
				that.updateCurObj(t);
				var taskIncidents = model.curTask.getIncidents();
				
				$("#content").html(window.templates['taskDetails.html']);
				setTimeout(function () {
					
					tt = TaskType.getTaskType(model.curTask.taskTypeId);
					var ts = model.curTask.getCurrentState();
					
					if (model.curTask.isArchived()) {
						$(".breadcrumbArchive").removeClass("hidden");
						viewManager.setSelectedMenuItem('menuTasks', 'menuTasksArchive');
					}
					else {
						$(".breadcrumbActive").removeClass("hidden");
						viewManager.setSelectedMenuItem('menuTasks', 'menuTasksActive');
					}
					
					//title
					$("#taskHeader").html('#' + model.curTask.taskId + " - " + model.curTask.title);

                    $("#lblTaskId").html('#' + model.curTask.taskId);

					$("#lblTitle").html(model.curTask.title);
					
					var s = "";
					switch (model.curTask.getStatus()) {
						case constants.STATUS_NEW:
							s += '<td><span class="badge badge-status statusColorNew">Offen</span></td>';
							break;
						case constants.STATUS_COMPLETED:
							s += '<td><span class="badge badge-status statusColorCompleted">Erledigt</span></td>';
							break;
						case constants.STATUS_PROGRESS:
							s += '<td><span class="badge badge-status statusColorProgress">In Bearbeitung</span></td>';
							break;
						default:
							//Label
							var tl = model.curTask.getTaskLabel();
							s += '<td><span class="badge badge-status" style="background-color: ' + tl.colorBg + '; color: ' + tl.colorFg + ';">' + tl.name + '</span></td>';
							break;
					}
					$("#lblStatus").html(s);
					
					//erste Version holen
					var taskHistory = model.curTask.getHistory();
					var d = new Date();
                    if (taskHistory.length > 0) {
                        d.setTime(taskHistory[0].timestamp);
                        $("#lblTimestamp").html(pg.formatDate(d, true));
                    }
                    else{
                        $("#lblTimestamp").html("");
                    }

					//Stand heute (2018/04) ist der Owner bei Aufträgen unveränderlich
					//var to = model.curTask.getOriginalTask();
					$("#lblOwner").html(User.getUserName(model.curTask.ownerId));
					$("#lblResponsible").html(User.getUserName(ts.responsibleId));
					
					if (model.curTask.isCompleted()) {
						
						//neuester Protokoll-Timestamp
						var maxDate = 0;
						for(i = 0; i < model.curTask.protocols.length; i++) {
							var dx = model.curTask.protocols[i].submittedOn ? model.curTask.protocols[i].submittedOn : model.curTask.protocols[i].changedOn;
							if (dx > maxDate)
								maxDate = dx;
						}
						$("#lblCompletedTs").html(pg.formatDate(maxDate, true));
						$("#rowCompletedTs").show();
					}
					else {
						$("#rowCompletedTs").hide();
					}
					
					$("#lblTaskType").html(TaskType.getTaskType(model.curTask.taskTypeId).name);
					if (model.curTask.locationType > 0) {
						var lt = LocationType.getLocationType(model.curTask.locationType);
						$("#lblLocationType").html(lt.name + " (" + lt.abbrev + ")");
						$("#taskDetailsOverview #rowLocationType").show();
					}
					else
						$("#taskDetailsOverview #rowLocationType").hide();
					$("#lblInterval").html(model.curTask.getIntervalName());
					
					//Intervall
					$("#btnEditTask").prop('disabled', false);
					if (model.curTask.isInterval()) {
						if (model.curTask.endDate)
							$("#lblEndDate").html(pg.formatDate(model.curTask.endDate, true));
						else
							$("#lblEndDate").html("Ohne Enddatum");
						$("#taskDetailsEndDate").show();
						
						//falls es einen neueren Auftrag gibt, diesen hier nicht mehr bearbeiten
						//if (model.curTask.nextGenerationTask)
						//	$("#btnEditTask").prop('disabled', true);
						
						$("#rowIntervalStatus").show();
					}
					else {
						$("#taskDetailsEndDate").hide();
						$("#rowIntervalStatus").hide();
					}
					
					//IntervalStatus
					switch (model.curTask.intervalStatus) {
						case constants.STATUS_OBJECT_ACTIVE:
                            //$("#lblIntervalStatus").html('<span class="badge badge-success badge-roundless">aktiv</span>');
                            $("#lblIntervalStatus").html('aktiv');
							break;
						case constants.STATUS_OBJECT_INACTIVE:
                            //$("#lblIntervalStatus").html('<span class="badge badge-danger badge-roundless">inaktiv</span>');
                            $("#lblIntervalStatus").html('<span class="text-muted"><i class="fa fa-fw fa-pause"></i> inaktiv</span>');
							break;
					}

					var userGroups = ts.getUserGroups();
					var group = "";
					$.each(userGroups, function(index, ug){

						if (index > 0)
							group += "<br/>";

						group += ug.name;

						if (ug.isSingleUser !== 1) {
							group += ": ";
							group += ug.users[0].getName();
							for(i = 1; i < ug.users.length; i++)
								group += ", " + ug.users[i].getName();
						}
					});
					$("#lblUser").html(group);

					var dueDate = model.curTask.getDueDate();
					if(dueDate)
						$("#lblDueDate").html('<span style="color:' + model.curTask.getDueDateColor() + '">' + pg.formatDate(dueDate, true) + '</span>');
					else
                        $("#lblDueDate").html('Ohne');

					$("#lblDesc").html(pg.nl2br(model.curTask.description));
					
					//inaktive/gelöschte Objekte anzeigen
					//noinspection JSUnresolvedVariable
					if (model.curTask.locationList.length) {

						model.curTask.locationList.sort(function (a, b) {

							var locA = Location.getLocation(a);
							var locB = Location.getLocation(b);

							if (!locA)
								return -1;
							if (!locB)
								return 1;

							var aN = locA.getName(true).toLowerCase();
							var bN = locB.getName(true).toLowerCase();

							return ((aN < bN) ? -1 : ((aN > bN) ? 1 : 0));
						});

						var locList = '<div class="task-location-list text-muted">' + Location.getLocationList(model.curTask.locationList, undefined, undefined, undefined, undefined, true, undefined, true) + '</div>';
						$("#lblLocations").html(model.curTask.locationList.length + ' Objekt(e)<br>' + locList);
						$("#taskDetailsOverview #rowLocations").show();
					}
					else {
						$("#taskDetailsOverview #rowLocations").hide();
					}
					
					//---------------------------------
					//dynamische Felder
					s = "";
					var ttFields = TaskTypeField.getTaskTypeFields(model.curTask.taskTypeId);
					for(i = 0; i < ttFields.length; i++) {
						
						var ttf = ttFields[i];
						
						var dd = model.curTask.getDynDataObj(ttf.id);
						var ddValue = (dd) ? ttf.getFormattedValue(dd.value) : "";
						
						s += '<tr><td>' + ttf.name + '</td><td class="font-semibold">' + ddValue + '</td></tr>';
					}
					//$("#taskDetailsOverview #rowLocations").after(s);
					$("#taskDetailsOverview table tbody tr.beforeDynData").after(s);
					
					//---------------------------------
					//Antrag
					
					if (model.curTask.requestId > 0) {
						
						var r = Request.getRequest(model.curTask.requestId);
						
						if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_DETAILS))
							s = '<td><a data-requestId="' + r.requestId + '" class="btnRequestDetails">#' + r.requestId + '</a></td>';
						else
							s = r.requestId;
						$("#lblRequestId").html(s);
						
						$("#lblRequestOwner").html(User.getUserName(r.ownerId));
						$("#taskDetailsOverview .rowRequest").show();
					}
					else {
						$("#taskDetailsOverview .rowRequest").hide();
					}
					
					//---------------------------------
					
					if (model.curTask.hasProtocols()) {

                        var proTotalCount = model.curTask.totalProtocolCount;
                        var proCompletedCount = model.curTask.protocolCount[constants.STATUS_COMPLETED];
                        $("#lblProtocols").html('<span class="badge"><i class="fa fa-fw fa-file-text"></i> ' + proCompletedCount + '/' + proTotalCount + '</span>');

						$("#taskDetailsOverview .rowProtocol").show();

						//Vorkommnisse
						taskDetailManager.updateTaskIncidents(t);

						taskDetailManager.showIncidents(model.curTask.getIncidents(), "#taskIncidentsTable", false);
						$("#taskDetailsTabIncidents").show();
						
						//Protokolle (auch Einträge für noch nicht existierende erzeugen)
						taskDetailManager.updateTaskProtocols(t);
						$("#taskDetailsTabProtocols").show();
					}
					else {
						$("#taskDetailsOverview .rowProtocol").hide();

						$("#taskDetailsTabProtocols").hide();
						$("#taskDetailsTabIncidents").hide();
					}
					
					//---------------------------------
					//zugehörige Aufträge
					
					var taskLinks = [];
					if (model.curTask.intervalId > 0)
						taskLinks = Task.getTasksByIntervalId(model.curTask.intervalId);
					
					if (taskLinks.length > 1) {
						
						s = "";
						
						for(i = 0; i < taskLinks.length; i++) {
							var l = taskLinks[i];
							
							if (l.taskId === model.curTask.taskId)
								continue;
							
							s += "<tr>";
							s += '<td><a data-taskId="' + l.taskId + '" class="btn grey-mint btn-xs btnTaskLink">Details</a></td>';
							s += '<td class="font-semibold">#' + l.taskId + '</td>';
							
							switch (l.getMasterStatus()) {
								/*case constants.STATUS_OBJECT_INACTIVE:
									s += '<td><span class="badge statusColorInactive">inaktiv</span></td>';
									break;*/
								
								case constants.TASK_STATUS_ACTIVE:
									
									switch (l.getStatus()) {
										case constants.STATUS_NEW:
											s += '<td><span class="badge badge-status statusColorNew">Offen</span></td>';
											break;
										case constants.STATUS_COMPLETED:
											s += '<td><span class="badge badge-status statusColorCompleted">Erledigt</span></td>';
											break;
										case constants.STATUS_PROGRESS:
											s += '<td><span class="badge badge-status statusColorProgress">In Bearbeitung</span></td>';
											break;
										default:
											//Label
											var tLabel = l.getTaskLabel();
											s += '<td><span class="badge badge-status" style="background-color: ' + tLabel.colorBg + '; color: ' + tLabel.colorFg + ';">' + tLabel.name + '</span></td>';
											break;
									}
									break;
							}

                            var lType = TaskType.getTaskType(l.taskTypeId).name;
							s += '<td>';
							s += '<span class="taskTitle">' + l.title + '</span><br/>';
                            s += '<span class="taskType text-muted">' + lType + '</span>';
                            s += '</td>';

							s += "<td>" + User.getUserName(l.getCurrentState().responsibleId) + "</td>";
							s += "<td>" + '<span style="color:' + l.getDueDateColor() + '">' + pg.formatDate(l.getDueDate()) + '</span></td>';

							s += "</tr>";
						}
						
						$("#taskDetailLinksTable tbody").html(s);
						
						$("#taskDetailsTabLinks").show();
					}
					else {
						$("#taskDetailsTabLinks").hide();
					}
					
					//----------------------------------
					
					//History
					taskDetailManager.showTaskHistory(taskHistory, "#taskHistoryTable");
					
					//----------------------------------
					
					//handlers
					$("#btnBackTask").on("click", function () {
						model.curTaskListView.preserveCurPage = true;

						//explizit gewünscht?
						if (args.backFunction && args.backFunction===constants.BACK_BROWSER){
							window.history.back();
							return;
						}

						//Berechtigung vorhanden?
						var maySeeList = authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_ALL, UserRole.PERMISSION_TASK_LIST_OWN, UserRole.PERMISSION_TASK_LIST_BY_TASK_TYPE);
						if (!maySeeList){
							window.history.back();
							return;
						}

						if (model.curTask.isArchived())
							viewManager.showPage(constants.PAGE_TASK_ARCHIVE);
						else
							viewManager.showPage(constants.PAGE_TASK_LIST);
					});
					
					$(".btnRequestDetails").on("click", that.showRequestDetails);
					
					$(".btnTaskLink").on("click", that.showTaskDetails);
					
					//---------------------------------------

					var mayEdit = false;
					if (!model.curTask.isArchived()) {
						if (authManager.hasPermission(UserRole.PERMISSION_TASK_EDIT_ALL)) {
							mayEdit = true;
						}
						else {
							if (authManager.hasPermission(UserRole.PERMISSION_TASK_EDIT_OWN)) {
								if (model.curTask.ownerId === model.curUserId)
									mayEdit = true;
								if (ts.responsibleId === model.curUserId)
									mayEdit = true;
							}
							if (authManager.hasPermission(UserRole.PERMISSION_TASK_EDIT_BY_TASK_TYPE)) {
								if (model.curUser.hasTaskType(model.curTask.taskTypeId))
									mayEdit = true;
							}
						}
					}
					var mayEditStatus = mayEdit;

					//Intervall nur dann bearbeitbar, falls neueste Ausführung und nicht erledigt!
					if (model.curTask.isInterval()){

						mayEdit = false;

						//schon erledigt?
						if (model.curTask.isCompleted()) {
							mayEditStatus = false;
						}
						else {
							if (model.curTask.isLatestActiveIntervalVersion()) {
								mayEdit = true;
							}
						}
					}
					else{

						//falls kein Intervall: Erledigte Aufträge dürfen nicht mehr editiert werden
						if (model.curTask.isCompleted()) {
							mayEdit = false;
							mayEditStatus = false;
						}
					}

					if (mayEdit) {
						$("#btnEditTask").off("click").on("click", function () {
							viewManager.showPage(constants.PAGE_TASK_EDIT, {
								taskId: taskId
							});
						});
					}
					else {
						$("#btnEditTask").hide();
					}
					
					//--------------------------------------
					
					var mayClone = false;
					if (authManager.hasPermission(UserRole.PERMISSION_TASK_CREATE_ALL)) {
						mayClone = true;
					}
					else {
						//nur wenn A-Typ stimmt
						if (authManager.hasPermission(UserRole.PERMISSION_TASK_CREATE_BY_TASK_TYPE)) {
							if (model.curUser.hasTaskType(model.curTask.taskTypeId))
								mayClone = true;
						}
					}
					
					if (!mayClone) {
						$("#btnCloneTask").hide();
					}
					else {
						$("#btnCloneTask").off("click").on("click", function () {
							viewManager.showPage(constants.PAGE_TASK_CLONE);
						});
					}
					
					//-------------------------------------
					
					/*if (isNew) {
						$("#btnBackTask").hide();
					}
					else {*/
						$("#btnBackTask").show();
					//}

					//Status ändern
					if (model.curTask.isArchived()) {
						$("#btnChangeTaskStatus").hide();
					}
					else {
						if (!mayEditStatus || model.curTask.isCompleted() || (model.taskLabels.length === 0)) {
							$("#btnChangeTaskStatus").hide();
						}
						else {
							$("#btnChangeTaskStatus").show().click(function () {
								that.showChangeStatusDialog(model.curTask);
							});
						}
					}
					
					//--------------------------------------
					
					//Archivieren
					
					var mayArchive = false;
					if (authManager.hasPermission(UserRole.PERMISSION_TASK_ARCHIVE_ALL)) {
						mayArchive = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_TASK_ARCHIVE_OWN)) {
							if (model.curTask.ownerId === model.curUserId)
								mayArchive = true;
							if (ts.responsibleId === model.curUserId)
								mayArchive = true;
						}
						else {
							if (authManager.hasPermission(UserRole.PERMISSION_TASK_ARCHIVE_BY_TASK_TYPE)) {
								if (model.curUser.hasTaskType(model.curTask.taskTypeId))
									mayArchive = true;
							}
						}
					}
					
					//ist überhaupt im richtigen Zustand?
					if (mayArchive)
						mayArchive = model.curTask.canBeArchived();
					
					if (mayArchive) {
						if (model.curTask.isArchived()) {
							$("#btnRemoveTaskArchive").show().click(function () {
								that.setTaskStateGroup(model.curTask, constants.GROUP_DEFAULT);
							});
							$("#btnSetTaskArchive").hide();
						}
						else{
							$("#btnRemoveTaskArchive").hide();
							$("#btnSetTaskArchive").show().click(function () {
								that.setTaskStateGroup(model.curTask, constants.GROUP_ARCHIVE);
							});
						}
					}
					else{
						$("#btnRemoveTaskArchive").hide();
						$("#btnSetTaskArchive").hide();
					}
					
					//--------------------------------------
					
					//Ausführen von objektlosen Aufträgen?
					var mayExecuteAssignment = false;
					if (!model.curTask.isArchived()) {
						if (model.curTask.isExecutable()) {
							if (model.curUser.canExecuteTask(model.curTask))
								mayExecuteAssignment = true;
						}
					}
					if (mayExecuteAssignment) {
						$("#btnExecuteTask").show();
						$("#btnExecuteTask").off("click").on("click", function () {
							that.onExecuteTask();
						});
					}
					else {
						$("#btnExecuteTask").hide();
					}
					
					
					//--------------------------------------
					
					$(".btnLocationProtocol").off("click").on("click", function (e) {
						var link = $(e.currentTarget);
						if (link.attr("data-taskId") && link.attr("data-protocolId")) {
							
							viewManager.showPage(constants.PAGE_TASK_PROTOCOL, {
								taskId: parseInt(link.attr("data-taskId"), 10),
								protocolId: parseInt(link.attr("data-protocolId"), 10)
							});
						}
					});

					$(".btnEditLocationProtocol").off("click").on("click", function (e) {
						var link = $(e.currentTarget);
						if (link.attr("data-taskId") && link.attr("data-locationId") && link.attr("data-formId")) {
 
							viewManager.showPage(constants.PAGE_TASK_PROTOCOL_EDIT, {
								taskId: parseInt(link.attr("data-taskId"), 10),
								locationId: parseInt(link.attr("data-locationId"), 10),
								formId: parseInt(link.attr("data-formId"), 10)
							});
						}
					});
					
					model.curTask.updateAttachments();
					taskRequestEditorManager.showTaskAttachments("#taskDetailAttachmentTableBox", model.curTask.getAttachments(), false);
					
					//-----------------------------
					
					//Zustand übernehmen bzw. neu erzeugen
					if (args.curStateDetails){
						model.curStateDetails = args.curStateDetails;
					}
					if (!model.curStateDetails) {
						//state merken
						model.curStateDetails = {
							curTabIndex: 0
						};
					}
					
					//anwenden
					viewManager.activateNavTab("#taskDetailsBox", model.curStateDetails.curTabIndex);

					viewManager.updateButtons();
					
					//update components
					viewManager.updateLayout();

					if (callbackComplete)
						callbackComplete();
					
				}, constants.TEMPLATE_DELAY);
				
				return false;
			},
			
			//---------------------------------------------------------------------------------------
			//Archivieren etc.
			
			setTaskStateGroup: function(task, groupId){
				
				var that = taskDetailManager;
				
				var ts = task.addTaskState();
				ts.groupId = groupId;
				
				dataManager.saveTaskState(task, ts, function (t, tsNew) {
					
					//update view
					that.showTask(task.taskId);
					
					if (groupId === constants.GROUP_ARCHIVE)
						viewManager.showNotification("Auftrag #" + task.taskId + " wurde erfolgreich archiviert.", constants.NOTIFICATION_SUCCESS);
					else
						viewManager.showNotification("Auftrag #" + task.taskId + " wurde erfolgreich wiederhergestellt.", constants.NOTIFICATION_SUCCESS);
				});
				
				return false;
			},
			
			//---------------------------------------------------------------------------------------
			
			//Dialog zum Ändern des Status anzeigen
			showChangeStatusDialog: function (task) {
				
				var that = this;
				
				var dialog = $("#taskStatusModal");
				
				//Dialog anzeigen mit Frage + Textfeld
				$(".has-error", dialog).removeClass("has-error");
				$(".comment", dialog).val("");
				
				//mögliche Stati ermitteln
				var sel = "";
				sel += '<label id="lblTaskStatusModalSelect" for="taskStatusModalSelect" class="control-label">Status <span class="required">*</span></label>';
				sel += '	<select id="taskStatusModalSelect">';
				sel += '<option value="-1">Bitte w&auml;hlen...</option>';
				
				var options = [];
				var curRawStatus = task.status;
				var curRawStatusName = task.getStatusName(curRawStatus);
				var curStatus = task.getStatus();
				
				if (curRawStatus !== curStatus) {
					sel += "<option value='" + curRawStatus + "'>" + curRawStatusName + "</option>";
					options.push(curRawStatus);
				}
				
				for(var i = 0; i < model.taskLabels.length; i++) {
					var tl = model.taskLabels[i];
					if (tl.id !== curStatus) {
						sel += "<option value='" + tl.id + "'>" + tl.name + "</option>";
						options.push(tl.id);
					}
				}
				sel += '</select>';
				
				$(".statusBox", dialog).html(sel).show();
				$("#taskStatusModalSelect").select2();
				
				//aktuellen Wert setzen, falls nur ein möglicher vorhanden!
				if (options.length === 1) {
					$("#taskStatusModalSelect").val(options[0]).trigger("change");
					//Kommentar-Pflicht anpassen
					that.updateStatusDialogComment(options[0]);
				}
				
				viewManager.updateButtons();
				
				//Anzeige Modal
				dialog.modal({
					backdrop: "static",
					keyboard: false
				});
				
				$("#taskStatusModalSelect").change(function () {
					that.updateStatusDialogComment(parseInt($(this).val()));
					$(".has-error", dialog).removeClass("has-error");
				});
				
				$(".btn-cancel", dialog).off("click").on("click", function () {
					viewManager.updateButtons();
					dialog.modal('hide');
				});
				$(".btn-ok", dialog).off("click").on("click", function () {
					
					//blocking
					if (!viewManager.setButtonBlocking($(".btn-ok", dialog)))
						return false;
					
					$(".has-error", dialog).removeClass("has-error");
					
					//Status
					var newStatus = parseInt($("#taskStatusModalSelect").val());
					if (newStatus < 0) {
						viewManager.updateButtons();
						$(".statusBox", dialog).addClass("has-error");
						return false;
					}
					
					//Ist Kommentar Pflicht?
					var curLabel = null;
					for(i = 0; i < model.taskLabels.length; i++) {
						tl = model.taskLabels[i];
						if (newStatus === tl.id) {
							curLabel = tl;
							break;
						}
					}
					var isCommentRequired = false;
					if (curLabel)
						isCommentRequired = (curLabel.commentRequired === 1);
					
					//Kommentar merken
					var comment = $(".comment", dialog).val();
					if (!comment && isCommentRequired) {
						viewManager.updateButtons();
						$(".commentBox", dialog).addClass("has-error");
						return false;
					}
					
					//dataManager für Versand verwenden
					if (that.isCurObjOutdated)
						that.updateCurObj();
					var ts = model.curTask.addTaskState();
					ts.labelId = curLabel ? curLabel.id : null;
					ts.comment = comment;
					
					dataManager.saveTaskState(model.curTask, ts, function () {
						dialog.modal('hide');
						
						//update view
						that.showTask(model.curTask.taskId);
						
						viewManager.showNotification("Auftragsstatus erfolgreich geändert.", constants.NOTIFICATION_SUCCESS);
					});
					
					return false;
				});
				
			},
			
			//Kommentar-Pflicht anpassen
			updateStatusDialogComment: function (status) {
				
				var dialog = $("#taskStatusModal");
				
				var curLabel = null;
				for(var i = 0; i < model.taskLabels.length; i++) {
					var tl = model.taskLabels[i];
					if (status === tl.id) {
						curLabel = tl;
						break;
					}
				}
				
				//Label: Kommentar-Pflicht?
				var isCommentRequired = false;
				if (curLabel)
					isCommentRequired = (curLabel.commentRequired === 1);
				if (isCommentRequired)
					$(".commentLabel", dialog).html("Kommentar <span class='required'>* </span>");
				else
					$(".commentLabel", dialog).html("Kommentar");
			},
			
			//---------------------------------------------------------------------------------------
			
			//Sprung zu: Request-Details anzeigen
			showRequestDetails: function (e) {
				
				if (e) {
					var link = $(e.currentTarget);
					if (link.attr("data-requestId")) {
						var requestId = parseInt(link.attr("data-requestId"), 10);
						
						viewManager.showPage(constants.PAGE_REQUEST_DETAILS, {
							requestId: requestId
						});
					}
				}
				return false;
			},
			
			//Sprung zu anderem Auftrag
			showTaskDetails: function (e) {

				if (e) {
					var link = $(e.currentTarget);
					if (link.attr("data-taskId")) {
						var taskId = parseInt(link.attr("data-taskId"), 10);
						
						viewManager.showPage(constants.PAGE_TASK_DETAILS, {
							taskId: taskId,
							backFunction: constants.BACK_BROWSER
						}, true, true, true);
					}
				}
				return false;
			},
			
			//Auftrag ausführen
			onExecuteTask: function () {
				
				var that = this;
				
				pg.confirmMsg("Auftrag erledigen", "Möchten Sie <strong>Auftrag #" + model.curTask.taskId + " - " + encodeURIComponent(model.curTask.title) + "</strong> als erledigt markieren?", function (result) {
					if (result) {
						
						//Problem: wenn *nur* hier Änderungen vorgenommen werden und das Objekt durch Hintergrund-Update outdated ist, gehen diese Änderungen lokal verloren
						//-> ggf. frisch holen
						if (that.isCurObjOutdated)
							that.updateCurObj();
							
						//senden!
						var tt = TaskType.getTaskType(model.curTask.taskTypeId);
						if (tt){
							if (tt.formIds.length > 0) {

								var formId = tt.formIds[0];
								var form = ProtocolForm.getProtocolForm(formId);

								//Array statt String
								var data = [];

								//alle Felder berücksichtigen
								const formFields = form.fields;
								for (let i=0; i<formFields.length; i++) {

									var formField = form.fields[i];

									var pdi = ProtocolDataItem.createProtocolDataItem();
									//noinspection JSUnresolvedVariable
									var guid = Math.floor(Math.random() * constants.MAX_SAFE_INTEGER_MYSQL);
									pdi.id = guid * -1;
									pdi.createdOn = new Date();
									pdi.createdBy = -1;
									pdi.changedOn = new Date();
									pdi.changedBy = -1;
									pdi.formFieldId = formField.id;

									switch (formField.orderIndex){
										case ProtocolFormField.ORDER_INDEX_FIELD_FOTOS:
											pdi.valueString = "";
											break;
										case ProtocolFormField.ORDER_INDEX_FIELD_NOTES:
											pdi.valueString = "";
											break;
										default:
											pdi.valueString = "ja";
											break;
									}

									data.push(pdi);
								}

								//Location
								var locationId = -1;
								if (model.curTask.getLocationCount() === 1)
									locationId = model.curTask.getLocationIds()[0];

								var p = model.curTask.addProtocol(constants.STATUS_COMPLETED, formId, locationId, data, [], []);
								
								//schon mal abgeschickt? (wie auch immer)
								if (p.id > 0)
									return;
								
								//es gibt hier keine Attachments
								p.isTransferredCompletely = 1;

								//keine Bilder
								p.attachmentsArray = [];
								dataManager.saveProtocol(model.curTask, p, function (pNew) {
									
									model.curTask.updateProtocolCount();
									model.curTask.updateStatus();
									
									//fertig
									viewManager.showNotification("Auftrag #" + model.curTask.taskId + " abgeschlossen.", constants.NOTIFICATION_SUCCESS);
									viewManager.showPage(constants.PAGE_TASK_LIST);
								});
							}
						}
					}
				});
			},
			
			//--------------------------------------------------
			
			//Anzeige der Protokolle aktualisieren
			updateTaskProtocols: function (t) {
				
				var mayListProtocols = false;
				if (authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_LIST_ALL)) {
					mayListProtocols = true;
				}
				else {
					if (authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_LIST_OWN)) {
						if (t.ownerId === model.curUserId) {
							mayListProtocols = true;
						}
						var ts = t.getCurrentState();
						if (ts.responsibleId === model.curUserId) {
							mayListProtocols = true;
						}
						if (ts.isAssignedToUser(model.curUserId))
							mayListProtocols = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_LIST_BY_TASK_TYPE)) {
							if (model.curUser.hasTaskType(t.taskTypeId))
								mayListProtocols = true;
						}
					}
				}
				
				if (!mayListProtocols) {
					//$("#taskDetailsTabProtocols").removeAttr("data-toggle").addClass("tabDisabled");
					$("#taskDetailsTabProtocols").hide();
					return;
				}

				var protocols = t.getAllProtocols();
				protocolManager.showProtocols(protocols, "#taskProtocolTable", true);
				
				$(".btnLocationProtocol").off("click").on("click", function (e) {
					var link = $(e.currentTarget);
					if (link.attr("data-taskId") && link.attr("data-protocolId")) {
						
						viewManager.showPage(constants.PAGE_TASK_PROTOCOL, {
							taskId: parseInt(link.attr("data-taskId"), 10),
							protocolId: parseInt(link.attr("data-protocolId"), 10)
						});
					}
				});

				$(".btnEditLocationProtocol").off("click").on("click", function (e) {
					var link = $(e.currentTarget);
					if (link.attr("data-taskId") && link.attr("data-locationId") && link.attr("data-formId")) {

						viewManager.showPage(constants.PAGE_TASK_PROTOCOL_EDIT, {
							taskId: parseInt(link.attr("data-taskId"), 10),
							locationId: parseInt(link.attr("data-locationId"), 10),
							formId: parseInt(link.attr("data-formId"), 10)
						});
					}
				});
			},
			
			//--------------------------------------------------
			
			//Anzeige der Vorkommnisse aktualisieren
			updateTaskIncidents: function (t) {
				
				var mayListIncidents = false;
				if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_ALL)) {
					mayListIncidents = true;
				}
				else {
					if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_OWN)) {
						if (t.ownerId === model.curUserId) {
							mayListIncidents = true;
						}
						var ts = t.getCurrentState();
						if (ts.responsibleId === model.curUserId) {
							mayListIncidents = true;
						}
						if (ts.isAssignedToUser(model.curUserId))
							mayListIncidents = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_BY_TASK_TYPE)) {
							if (model.curUser.hasTaskType(t.taskTypeId))
								mayListIncidents = true;
						}
					}
				}
				
				if (!mayListIncidents) {
					$("#taskDetailsTabIncidents").hide();
					return;
				}
				
				//update model
				t.updateIncidents();

                if (t.incCount > 0) {
                    var incTotalCount = t.incCount;
                    var incCompletedCount = t.incCount - t.defectCount;
                    if (t.defectCount === 0)
                        $("#lblIncidents").html('<span class="badge"><i class="fa fa-fw fa-bolt"></i> ' + incCompletedCount + '/' + incTotalCount + '</span>');
                    else
                        $("#lblIncidents").html('<span class="badge badge-danger"><i class="fa fa-fw fa-bolt"></i> ' + incCompletedCount + '/' + incTotalCount + '</span>');
                }

				if (t.notificationCount > 0)
                    $("#lblNotifications").html('<span class="badge badge-danger"><i class="fa fa-fw fa-flag"></i> ' + t.notificationCount + '</span>');

			},
			
			//--------------------------------------------------
			
			//Vorkommnisse auflisten
			showIncidents: function (incidents, trgEl, isProtocolView) {

				var that = this;

				var s = "";
				
				//n.a.?
				if (incidents.length === 0)
					s = '<tr><td colspan="10">Keine Vorkommnisse</td></tr>';
				
				//sortieren absteigend
				incidents.sort(function (a, b) {
					return b.id - a.id;
				});

				var j,
					count = 0,
					ug,
					guid,
					signals =  [];
				for(var i = 0; i < incidents.length; i++) {

					var h = incidents[i];
					var task = Task.getTask(h.taskId);
					var ts = task.getCurrentState();
					var form = ProtocolForm.getProtocolForm(h.formId);
					var formField = form.getFormField(h.fieldId);

					if (!formField)
						continue;

					//permission?
					var mayShowIncident = false;
					if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_ALL)) {
						mayShowIncident = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_OWN)) {
							if (task.ownerId === model.curUserId) {
								mayShowIncident = true;
							}
							if (ts.responsibleId === model.curUserId) {
								mayShowIncident = true;
							}
							if (ts.isAssignedToUser(model.curUserId))
								mayShowIncident = true;
						}
						else {
							if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_BY_TASK_TYPE)) {
								if (model.curUser.hasTaskType(task.taskTypeId))
									mayShowIncident = true;
							}
						}
					}
					if (!mayShowIncident)
						continue;
					
					//Rechte
					var mayEditIncident = false;
					if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_ALL)) {
						mayEditIncident = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_OWN)) {
							if (task.ownerId === model.curUserId) {
								mayEditIncident = true;
							}
							if (ts.responsibleId === model.curUserId) {
								mayEditIncident = true;
							}
							if (ts.isAssignedToUser(model.curUserId))
								mayEditIncident = true;
						}
						if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_BY_TASK_TYPE)) {
							if (model.curUser.hasTaskType(task.taskTypeId))
								mayEditIncident = true;
						}
					}
					
					s += '<tr><td data-incident-id="' + h.id + '"';
					if (!isProtocolView)
						s += ' class="timestamp"';
					s += '>';
					
					if (!isProtocolView) {
						var d = new Date();
						d.setTime(h.changedOn);
						s += pg.formatDate(d);
						s += "</td><td>";
						s += User.getUserName(h.userId, true);
						s += "</td><td>";
						s += Location.getLocation(h.locationId, undefined, true, true, true).getName(true);
						s += "</td><td>";

						//-------------------------------------
						//Verlinkung zu Protokoll

						//permission?

						var maySeeProtocolDetails = authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_DETAILS);
						if (maySeeProtocolDetails) {
							s += '<a data-taskId="' + h.taskId + '" data-protocolId="' + h.protocolId + '" class="btn grey-mint btn-xs btnLocationProtocol">Details</a></td><td>';
						}
						else{
							s += '</td><td>';
						}

						s += form.name;
						s += "</td><td>";
					}
					
					//Feld-Name
					s += formField.name;
					
					s += "</td><td>";
					
					if (isProtocolView) {
						
						//Wert/Farbe
						//neue Fassung? -> via DB
                        var color = form.getColorData2(h.fieldId, h.fieldValue) || "bgWhite";
                        s += '<div class="label labelBox ' + color + '">' + h.fieldValue + '</div>';
						s += "</td><td>";
					}
					
					s += h.getType();
					s += "</td><td>";
					
					//Kommentar
					s += pg.nl2br(h.description || "");
					s += '</td><td>';

					//-----------------------------
					//Status
					var sig = null;
					var hasSignal = false;
					for(j = 0; j < h.signals.length; j++) {
						sig = h.signals[j];

						if (!sig.isNewestSignal(h.signals, j))
							continue;

						if (sig.type === Signal.SIGNAL_DEFECT) {
							hasSignal = true;
							break;
						}
					}
					
					if (!hasSignal) {
						sig = Signal.createSignalRaw();
						guid = Math.floor(Math.random() * constants.MAX_SAFE_INTEGER_MYSQL);
						sig.id = guid * -1;
						sig.type = Signal.SIGNAL_DEFECT;
						sig.value = Signal.VALUE_OK_DEFAULT;
					}
					signals.push(sig);

					/*s += '<span data-signal-id="' + sig.id + '" data-task-id="' + h.taskId + '" data-incident-id="' + h.id + '" data-signal-type="' + sig.type + '" class="incidentSignal incidentSignal2 ';
					if (!mayEditIncident)
						s += 'incidentSignalDisabled ';
                    s += sig.getButtonCss() + '">' + sig.getValue() + '</span> ';*/

					//Anzeige via Switch
					s += '<input type="checkbox" data-signal-id="' + sig.id + '" data-task-id="' + h.taskId + '" data-incident-id="' + h.id + '" data-signal-type="' + sig.type + '" class="incidentSignal signalDefect" ';
					if (!mayEditIncident)
						s += ' disabled ';
					s += sig.getCheckboxValue();
					s += '/>';
					s += '</td><td>';

					//Signale: Meldung
					hasSignal = false;
					for(j = 0; j < h.signals.length; j++) {
						sig = h.signals[j];

						if (!sig.isNewestSignal(h.signals, j))
							continue;

						if (sig.type === Signal.SIGNAL_NOTIFY) {
							hasSignal = true;
							break;
						}
					}
					if (!hasSignal) {
						sig = Signal.createSignalRaw();
						guid = Math.floor(Math.random() * constants.MAX_SAFE_INTEGER_MYSQL);
						sig.id = guid * -1;
						sig.type = Signal.SIGNAL_NOTIFY;
						sig.value = 0;
					}
					signals.push(sig);

					/*s += '<span data-signal-id="' + sig.id + '" data-task-id="' + h.taskId + '" data-incident-id="' + h.id + '" data-signal-type="' + sig.type + '" class="incidentSignal incidentSignal2 ';
					if (!mayEditIncident)
						s += 'incidentSignalDisabled ';
					s += sig.getButtonCss() + '">' + sig.getValue() + '</span> ';*/

					//Anzeige via Switch
					s += '<input type="checkbox" data-signal-id="' + sig.id + '" data-task-id="' + h.taskId + '" data-incident-id="' + h.id + '" data-signal-type="' + sig.type + '" class="incidentSignal signalNotification" ';
					if (!mayEditIncident)
						s += ' disabled ';
					s += sig.getCheckboxValue();
					s += '/>';

					s += "</td></tr>";
					
					count++;
				}
				
				$(trgEl + " tbody").html(s);

				//---------------------------------------------

				//Switches setzen und behandeln
				$(".incidentSignal.signalDefect").bootstrapSwitch({
					onText: '<i class="fa fa-fw fa-wrench"></i>',
					onColor: 'green',
					offText: '<i class="fa fa-fw fa-wrench"></i>',
					offColor: 'red',
					size: "mini",
					onSwitchChange: function (event, state) {
						that.toggleSignal(this);
					}
				});
				$(".incidentSignal.signalNotification").bootstrapSwitch({
					onText: '<i class="fa fa-fw fa-flag"></i>',
					onColor: 'gray',
					offText: '<i class="fa fa-fw fa-flag"></i>',
					offColor: 'red',
					size: "mini",
					onSwitchChange: function (event, state) {
						that.toggleSignal(this);
					}
				});

				//click handler
				$(".btnLocationProtocol").off("click").on("click", function (e) {
					var link = $(e.currentTarget);
					if (link.attr("data-taskId") && link.attr("data-protocolId")) {

						viewManager.showPage(constants.PAGE_TASK_PROTOCOL, {
							taskId: parseInt(link.attr("data-taskId"), 10),
							protocolId: parseInt(link.attr("data-protocolId"), 10)
						});
					}
				});
				
				return count;
			},

			//Switch aktiviert
			toggleSignal: function(trgEl){

				var that = taskDetailManager;

				var taskId = parseInt($(trgEl).attr("data-task-id"));
				var task = Task.getTask(taskId);

				var sigId = parseInt($(trgEl).attr("data-signal-id"));
				var sig = task.getSignal(sigId);

				//Rechte
				if (!authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_ALL, UserRole.PERMISSION_INCIDENT_EDIT_OWN, UserRole.PERMISSION_INCIDENT_EDIT_BY_TASK_TYPE)) {
					return false;
				}
				var mayEditIncident = false;
				if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_ALL)) {
					mayEditIncident = true;
				}
				else {
					if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_OWN)) {
						if (task.ownerId === model.curUserId) {
							mayEditIncident = true;
						}
						var ts = task.getCurrentState();
						if (ts.responsibleId === model.curUserId) {
							mayEditIncident = true;
						}
						if (ts.isAssignedToUser(model.curUserId))
							mayEditIncident = true;
					}
					if (authManager.hasPermission(UserRole.PERMISSION_INCIDENT_EDIT_BY_TASK_TYPE)) {
						if (model.curUser.hasTaskType(task.taskTypeId))
							mayEditIncident = true;
					}
				}
				if (!mayEditIncident)
					return false;

				//noch kein Signal vorhanden?
				if (!sig) {

					var incident = task.getIncident(parseInt($(trgEl).attr("data-incident-id")));
					var type = parseInt($(trgEl).attr("data-signal-type"));
					var val = Signal.VALUE_NOT_OK;

					sig = Signal.createSignal(incident.clientId, incident.userId, incident.taskId, incident.locationId, incident.protocolId, incident.formId, incident.id, incident.fieldId, type, val);
				}
				else {
					//umkehren
					sig.toggle();
				}

				//in DB merken
				dataManager.storeSignal(sig, function (data) {

					sig.id = parseInt(data.id);
					sig.changedOn = pg.parseDate(data.changedOn);

					//Anzeige aktualisieren
					$("tbody .incidentSignal[data-incident-id='" + sig.incidentId + "'][data-signal-id='" + sigId + "'][data-signal-type='" + sig.type + "']")
						//.removeClass(sig.getButtonCssDefault())
						//.addClass(sig.getButtonCss())
						//.html(sig.getValue())
						.attr("data-signal-id", sig.id);
					$("tbody .timestamp[data-incident-id='" + sig.incidentId + "']").html(pg.formatDate(sig.changedOn));

					//neu
					if (parseInt(data.isNew) === 1) {
						var incident2 = task.getIncident(sig.incidentId);
						incident2.addSignal(sig);
					}

					//Ansicht aktualisieren
					that.updateTaskIncidents(task);
					that.updateTaskProtocols(task);

					//auch Protokollübersicht
					var p = task.getProtocolById(sig.protocolId);
					protocolManager.showProtocolData(p, "#tblProtocolData");
					protocolManager.updateProtocolOverview(task, p);

					viewManager.showNotification("Vorkommnis erfolgreich aktualisiert.", constants.NOTIFICATION_SUCCESS);
				});

			},

			//-------------------------------------------

			//Historie anzeigen
			showTaskHistory: function (taskHistory, trgEl) {
				var s = "";

				for(var i = 0; i < taskHistory.length; i++) {
					var h = taskHistory[taskHistory.length-1-i];
					var txt = Task.getTaskHistoryDescription(h).text;
					if (!txt)
						continue;

					s += "<tr><td>";
					s += txt;
					s += "</td><td>";
					s += User.getUserName(h.actorId, true);
					s += "</td><td>";
					
					var d = new Date();
					d.setTime(h.timestamp);
					//s += pg.formatDateTime(d);
					s += pg.formatDate(d, true);
					s += "</td></tr>";
				}
				
				$(trgEl + " tbody").html(s);
				
				$("#btnAddTaskComment").off("click").on("click", this.onAddComment);
			},
			
			//----------------------------------------------------------------------------------------
			
			//tab changed
			onTaskDetailsTabChange: function (index) {
				model.curStateDetails.curTabIndex = index;
				model.curStateDetails.scrollPosition = viewManager.getScrollTop();
				viewManager.setStateDetails(model.curStateDetails);
			},
			
			//----------------------------------------------------------------------------------------
			
			//Kommentar speichern
			onAddComment: function(){
				
				var that = taskDetailManager;
				
				var comment = $("#taskComment").val();
				if (!comment) {
					viewManager.updateButtons();
					return false;
				}
				
				//blocking
				if (!viewManager.setButtonBlocking($("#btnAddTaskComment"))) {
					viewManager.updateButtons();
					return false;
				}
				
				var c = Comment.createComment().fromObj({
					clientId: model.curClientId,
					userId: model.curUserId,
					entityType: constants.ENTITY_TYPE_TASK,
					entityId: model.curTask.taskId,
					comment: comment
				});
				
				if (that.isCurObjOutdated)
					that.updateCurObj();
				
				dataManager.saveComment(model.curTask, c, function(cNew){
					//that.showTask(model.curTask.taskId);
					var taskHistory = model.curTask.getHistory();
					that.showTaskHistory(taskHistory, "#taskHistoryTable");
					viewManager.updateButtons();
					$("#taskComment").val("");
				});
				
				return false;
			},

			//-----------------------------------------------------------------------------------------

			showTaskList: function(e, isArchive){

				//Berechtigung vorhanden?
				var maySeeList = authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_ALL, UserRole.PERMISSION_TASK_LIST_OWN, UserRole.PERMISSION_TASK_LIST_BY_TASK_TYPE);
				if (maySeeList) {

					if (isArchive)
						viewManager.showPage(constants.PAGE_TASK_ARCHIVE, {status: -1});
					else
						viewManager.showPage(constants.PAGE_TASK_LIST, {status: -1});
				}

				e.preventDefault();
				return false;
			}
			
		};
		
		window.taskDetailManager = taskDetailManager;
	}()
);
