(function () {
	
	//-------------------------------------------
	//Manager für einzelnen User
	//-------------------------------------------
	
	/*global
	 constants:true,
	 dataManager:true,
	 viewManager:true,
	 User:true,
	 UserRole:true,
	 Task:true,
	 UserGroup:true,
	 authManager:true,
	 LocationType:true,
	 successorManager:true,
	 UserNotificationType:true
	 */
	
	'use strict';
	
	var userDetailManager = {
		
		//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
		isCurObjOutdated: false,
		checkModelChanged: function(data, oldModel){
			
			var that = userDetailManager;
			
			var i,
				tsOld,
				tsNew;
			
			//aktueller Benutzer verändert?
			if (data.users) {
				for(i = 0; i < data.users.length; i++) {
					var u = data.users[i];
					
					if (parseInt(u.id) === oldModel.curEditedUser.id){
						
						that.isCurObjOutdated = true;
						
						tsOld = oldModel.curEditedUser.changedOn.getTime();
						//noinspection JSUnresolvedVariable
						tsNew = pg.parseDate(u.changedOn).getTime();
						if (tsNew !== tsOld)
							return true;
					}
				}
			}
			
			return false;
		},
		updateCurObj: function(o){

			if (model.curEditedUser)
				o = o || User.getUser(model.curEditedUser.id);
			model.curEditedUser = o;
			this.isCurObjOutdated = false;
		},
		
		//---------------------------------------------------------------------------------
		
		showCurUserProfile: function(){
			viewManager.showPage(constants.PAGE_USER_DETAILS, {userId: constants.USER_ID_SELF}, undefined, undefined, true);
		},
		
		//---------------------------------------------------------------------------------
		
		//Bearbeitung starten
		editUser: function (userId, editMode, callbackComplete) {
			
			var that = this;

			if (!authManager.hasPermission(UserRole.PERMISSION_USER_LIST_SELF, UserRole.PERMISSION_USER_DETAILS)) {
				viewManager.execShowPage(constants.PAGE_OVERVIEW, null, true);
				return false;
			}
			
			if (editMode === undefined)
				editMode = false;
			
			//special
			if (userId === constants.USER_ID_SELF)
				userId = model.curUserId;
			
			var u = User.getUser(userId);

			//ist es ein Readonly-User?
			if (editMode && (u.type === constants.USER_TYPE_READONLY)) {
				viewManager.execShowPage(constants.PAGE_OVERVIEW, null, true);
				return false;
			}

			viewManager.setScrollTop(0);

			viewManager.setSelectedMenuItem('menuSettings', 'menuUsers');
			
			that.updateCurObj(u);
			
			$("#content").html(window.templates['userEdit.html']);
			setTimeout(function () {
				
				if (!authManager.hasPermission(UserRole.PERMISSION_USER_LIST_ALL)) {
					$("#breadcrumbUsers").hide();
				}
				
				$("#breadcrumbUser").html(u.getName());

				//Daten eintragen
				that.onUserEditLoaded(u, editMode);
				
				//Löschen
				if (authManager.hasPermission(UserRole.PERMISSION_USER_DELETE)) {
					$("#btnDeleteUser").show().on("click", $.proxy(that.setUserStatusDeleted, userDetailManager));
				}
				else
					$("#btnDeleteUser").hide();

				//Einladung
				if (u.isValid() || (u.id < 0))
					$("#btnInviteUser").hide();
				else
					$("#btnInviteUser").show().on("click", $.proxy(that.resendUserInvitation, userDetailManager));
				
				//Zustand
				if (!model.curStateDetails) {
					//state merken
					model.curStateDetails = {
						curTabIndex: 0,
						showEditor: false
					};
				}
				viewManager.activateNavTab("#userEditorBox", model.curStateDetails.curTabIndex);
				
				//update components
				viewManager.updateLayout();

				if (callbackComplete)
					callbackComplete();
				
			}, constants.TEMPLATE_DELAY);
			return false;
		},
		
		//-------------------------------------------
		
		//vorherige LocationTypes jeder Rolle für Reversierbarkeit (UPK-1244)
		prevLocationTypes: null,
		
		//Editor füllen
		onUserEditLoaded: function (u, showEditor) {
			
			var that = this;
			
			var i,
				ug,
				sel = "";
			var uGroups = UserGroup.getNormalUserGroups();
			
			if (showEditor) {
				
				//Merkliste leeren
				that.prevLocationTypes = null;
				
				if (u.id > 0) {
					$("#userTitle").html(u.getName() + " bearbeiten");
					$(".showIfNew").hide();
					$(".showIfNotNew").show();
				}
				else {
					$("#userTitle").html("Neuer Benutzer");
					$(".showIfNew").show();
					$(".showIfNotNew").hide();
				}
				
				$("#firstName").val(u.firstName);
				$("#lastName").val(u.lastName);
				$("#email").val(u.email);
				$("#fon").val(u.fon);
				$("#department").val(u.department);
				
				//status
				sel = "";
				sel += "<option disabled value='" + constants.STATUS_OBJECT_UNREGISTERED + "'>eingeladen</option>";
				sel += "<option value='" + constants.STATUS_OBJECT_ACTIVE + "'>aktiv</option>";
				//sel += "<option value='" + constants.STATUS_OBJECT_INACTIVE + "'>inaktiv</option>";
				//sel += "<option value='" + constants.STATUS_OBJECT_DELETED + "'>gel&ouml;scht</option>";
				$("#selectStatus").html(sel);
				$("#selectStatus").val(u.status);

				if (!u.isValid())
					$("#userStatusBox").hide();

				//--------------

				//role
				var arr = [];
				var uRole;
				for(i = 0; i < model.userRoles.length; i++) {
					uRole = model.userRoles[i];

					//nicht ROLE_SYSTEM
					//nur, wenn Rolle zugewiesen!
					if (uRole.name === "ROLE_SYSTEM")
						if (uRole.id !== u.role)
							continue;

					arr.push(uRole);
				}
				
				//---------------

				//Admin-Owner
				if (u.hasRole("ADMIN")){
					$("#accountOwnerBox").show();
					
					that.isInitialSetup = true;
					
					//keine Änderung anzeigen
					u.newClientOwnerId = -1;
					
					$("#chkAccountOwner").bootstrapSwitch({
						onText: '<i class="fa fa-fw fa-check"></i>',
						offText: '<i class="fa fa-fw fa-times"></i>',
						size: "mini",
						onSwitchChange: function (event, state) {

							if (!that.isInitialSetup) {
								
								if (state) {
									
									//Ownership auf diesen Benutzer umschalten
									u.newClientOwnerId = u.id;
									viewManager.setFormDirty();
									
								} else {
								
									//nicht mehr Owner - wer dann?
									successorManager.requestClientOwner(u.id, function (uId) {
										
										viewManager.updateButtons();
										
										//merken
										if (uId){
											//Ownership auf diesen Benutzer umschalten
											u.newClientOwnerId = uId;
											viewManager.setFormDirty();
										}
										else{
											//reset Switch
											that.isInitialSetup = true;
											$("#chkAccountOwner").bootstrapSwitch('state', true, false);
											that.isInitialSetup = false;
										}
										
									});
								}
							}
						}
					});
					
					//update Switch
					$("#chkAccountOwner").bootstrapSwitch('state', (model.client.ownerId === u.id), false);
					that.isInitialSetup = false;
					
					//self: disabled
					if (u.id === model.curUserId){
						$("#chkAccountOwner").bootstrapSwitch('disabled',true);
					}
				}
				else{
					$("#accountOwnerBox").hide();
				}
				
				//---------------

				//sortieren nach name ASC
				arr.sort(function (a, b) {
					var aName = a.name.toLowerCase();
					var bName = b.name.toLowerCase();
					return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
				});

				sel = "";
				sel += '<option value="-1" disabled="disabled" selected>Bitte wählen ...</option>';
				for(i = 0; i < arr.length; i++) {
					uRole = arr[i];

					if (!model.client.hasModule(constants.MODULE_ID_REQUESTS) && (uRole.abbrev === 'REQUESTER' || uRole.abbrev === 'DECIDER'))
						continue;

					sel += "<option value='" + uRole.id + "'";
					sel += ">" + uRole.name + "</option>";
				}
				$("#selectRole").html(sel).select2();
                if (u.id > 0)
                    $("#selectRole").val(u.role).trigger('change');

				//--------------

				if (u.id === model.curUserId) {
					$("#selectRole").prop("disabled", true);
					$("#btnDeleteUser").addClass("disabled").prop('disabled', true);
					$("#selectStatus").prop("disabled", true);
				}

				//nicht aktivierter Benutzer
				if (!u.isValid()){
					$("#selectStatus").prop("disabled", true);
				}
				
				/*if (u.status === constants.STATUS_OBJECT_INACTIVE) {
					$("#btnResendCredentials").addClass("disabled").prop('disabled', true);
				}*/
				
				//LocationTypes
				sel = "";
				var locationTypes = LocationType.getAllLocationTypes(true);
				var hasLocationType = false;
				for(i = 0; i < locationTypes.length; i++) {
					var lType = locationTypes[i];
					sel += "<option value='" + lType.id + "' ";
					
					if (u.hasLocationType(LocationType.LOCATION_TYPE_ALL)) {
						if (lType.id === LocationType.LOCATION_TYPE_ALL) {
							sel += " selected ";
							hasLocationType = true;
						}
					}
					else {
						if (u.hasLocationType(lType.id)) {
							sel += " selected ";
							hasLocationType = true;
						}
					}
					sel += ">" + lType.name + "</option>";
				}
				$("#selectLocationTypes").html(sel);
				$("#selectLocationTypes").select2({
					placeholder: 'Bitte auswählen ...',
					width: 400
				});
				if (!hasLocationType)
					$("#selectLocationTypes").val("").trigger("change");
				$("#selectLocationTypes").on("select2:close", function (e) {
					//merken
					that.updateLocationTypesVault();
					userDetailManager.validatePermissions();
				});
				//initial merken
				that.updateLocationTypesVault();
				
				that.updateLocationTypeDisabled(true);
				
				//teams
				sel = "";
				if (uGroups.length === 0) {
					sel = "Keine Teams vorhanden.";
				}
				for(i = 0; i < uGroups.length; i++) {
					ug = uGroups[i];
					sel += "<option value='" + ug.id + "' ";
					if (ug.contains(u.id))
						sel += " selected ";
					sel += ">" + ug.name + "</option>";
				}
				$("#selectGroups").html(sel);
				//select2
				$.fn.select2.defaults.set("theme", "bootstrap");
				$("#selectGroups").select2({
					placeholder: 'Bitte wählen ...',
					width: 400
				});

				//notifications
				sel = "";
				var allNotificationTypes = UserNotificationType.getAllNotificationTypes();
				for (i=0; i<allNotificationTypes.length; i++){
					var nt = allNotificationTypes[i];
					sel += '<label class="mt-checkbox mt-checkbox-outline">';
					sel += nt.name;
					sel += '<input data-id="' + nt.type + '" type="checkbox" class="checkbox chkNotificationType form-control2" ';
					if (u.hasNotificationType(nt.type))
						sel += 'checked="checked" ';
					sel += '>';
					sel += '<span></span>';
					sel += '</label><br/>';
				}
				$("#userNotificationBox").html(sel);

				//defaultLocationType
				setTimeout(function () {
					$("#selectRole").change(function () {
						var val = $(this).val();
						if (val) {
							uRole = UserRole.getUserRole(val);
							var prevVal = that.prevLocationTypes;
							switch (uRole.defaultLocationType) {
								/*case -1:
									$("#selectLocationTypes").val("").trigger("change");
									break;*/
								case LocationType.LOCATION_TYPE_ALL:
									$("#selectLocationTypes").val(LocationType.LOCATION_TYPE_ALL).trigger("change");
									break;
								default:
									//ggf. zurücksetzen auf vorher verwendete Auswahl
									if (prevVal)
										$("#selectLocationTypes").val(prevVal).trigger("change");
										
									//wenn nicht bewusst "alle", dann...
									if (prevVal && (prevVal.length !== 1 || (prevVal.length === 1 && prevVal[0] !== 0))){
										
										//aber nicht, wenn zuvor "Alle" war
										var curVal = $("#selectLocationTypes").val() || [];
										if (curVal.length === 1 && curVal[0] === "0")
											$("#selectLocationTypes").val("").trigger("change");
									}
									break;
							}
							//merken
							//that.updateLocationTypesVault();
							//ggf. disablen
							that.updateLocationTypeDisabled();
						}
					});
				}, 500);
				
				var mayEditAllFields = false;
				if (authManager.hasPermission(UserRole.PERMISSION_USER_EDIT_ALL)) {
					mayEditAllFields = true;
				}
				/*else {
					if (authManager.hasPermission(UserRole.PERMISSION_USER_EDIT_SUBSET_ONLY)) {
						mayEditAllFields = false;
					}
				}*/
				if (!mayEditAllFields) {
					//$("#tabUserSecurity").hide();
					$("#userStatusBox").hide();
					$("#tabUserPermissions").hide();
					$("#tabUserTeams").hide();
				}

				//nur für self: Passwort
				if (model.curEditedUser)
					if (model.curEditedUser.id !== model.curUserId) {
						$("#tabUserSecurity").hide();
					}

				//Benachrichtigungen auch dann nur zeigen, wenn Permission
				if (!authManager.hasPermission(UserRole.PERMISSION_USER_EDIT_SELF) || (model.curEditedUser.id !== model.curUserId)) {
					$("#tabUserNotifications").hide();
				}
				
				viewManager.updateButtons();
				
				$("#btnCancelUser").on("click", function () {

					if ((!model.curEditedUser) || (model.curEditedUser.id < 0))
						viewManager.showPage(constants.PAGE_USER_LIST);
					else {
						/*viewManager.showPage(constants.PAGE_USER_DETAILS, {
						 userId: model.curEditedUser.id
						 });*/
						window.history.back();
					}
					//that.onUserEditLoaded(model.curEditedUser, false);
					return false;
				});
				$("#btnSaveUser").on("click", userDetailManager.initSaveUser);
				
				$("#btnEditUser").hide();
				$("#btnDeleteSelf").hide();
				$("#userViewBox").hide();
				$("#userEditorBox").show();
			}
			else {
				
				//-----------------------------------------------------------------
				
				var uName = u.getName();
				if (u.id === model.client.ownerId){
					uName += '<span class="badge badge-primary ml5">Accountinhaber</span>';
				}
				$("#userTitle").html(uName);

				//--------------

				var s = "";
                s += '<h5 class="text-primary">Informationen</h5>';
                s += '<table class="table"><tbody>';
				s += '<tr><td class="col-xs-5 col-sm-4 col-md-3">Nachname</td><td class="font-semibold">' + u.lastName + '</td></tr>';
				s += '<tr><td>Vorname</td><td class="font-semibold">' + u.firstName + '</td></tr>';
				s += '<tr><td>Email</td><td class="font-semibold">' + u.email + '</td></tr>';
				s += '<tr><td>Telefon mobil (Arbeit)</td><td class="font-semibold">' + u.fon + '</td></tr>';
				s += '<tr><td>Abteilung/Dienststelle</td><td class="font-semibold">' + u.department + '</td></tr>';
				var status = "";
				switch (u.status) {
					case constants.STATUS_OBJECT_UNREGISTERED:
						status = '<span class="badge badge-primary">ausstehend</span>';
						break;
					case constants.STATUS_OBJECT_ACTIVE:
						status = '<span class="badge bg-green-jungle">aktiv</span>';
						break;
					case constants.STATUS_OBJECT_INACTIVE:
						status = '<span class="badge badge-danger">inaktiv</span>';
						break;
				}
				s += '<tr><td>Status</td><td>' + status + '</td></tr>';
				s += '</tbody></table>';

				//--------------

                s += '<h5 class="text-primary">Berechtigungen</h5>';
                s += '<table class="table"><tbody>';
				var curRole = UserRole.getUserRole(u.role);
				s += '<tr><td class="col-xs-5 col-sm-4 col-md-3">Benutzerrolle</td><td class="font-semibold">' + curRole.name + '</td></tr>';
				var lTypes = "";
				for(i = 0; i < u.locationTypeIds.length; i++) {
					if (i !== 0)
						lTypes += "<br>";
					lTypes += LocationType.getLocationType(u.locationTypeIds[i]).name;
				}
				s += '<tr><td>Erlaubte Objekttypen</td><td class="font-semibold">' + lTypes + '</td></tr>';
                s += '</tbody></table>';

				//--------------

                s += '<h5 class="text-primary">Teams</h5>';
                s += '<table class="table"><tbody>';
				sel = "";
				for(i = 0; i < uGroups.length; i++) {
					ug = uGroups[i];
					if (ug.contains(u.id))
						sel += ug.name + "<br>";
				}
				s += '<tr><td class="col-xs-5 col-sm-4 col-md-3">Teamzuordnungen</td><td class="font-semibold">' + sel + '</td></tr>';
                s += '</tbody></table>';

				//--------------

				if (authManager.hasPermission(UserRole.PERMISSION_USER_EDIT_SELF)) {
					if (u.id === model.curUserId) {
						s += '<h5 class="text-primary">Benachrichtigungen</h5>';
						s += '<table class="table"><tbody>';
						sel = "";
						var activeNotifications = u.getNotificationTypes();
						for (i = 0; i < activeNotifications.length; i++) {
							if (sel)
								sel += ", ";
							sel += UserNotificationType.getNotificationType(activeNotifications[i]).name;
						}
						if (!sel)
							sel = "-";
						s += '<tr><td class="col-xs-5 col-sm-4 col-md-3">Ereignisse</td><td class="font-semibold">' + sel + '</td></tr>';
						s += '</tbody></table>';
					}
				}

				//--------------

				$("#userViewTable").html(s);
				
				$("#userViewBox").show();
				$("#userEditorBox").hide();
				
				var mayEditUser = false;
				if (authManager.hasPermission(UserRole.PERMISSION_USER_EDIT_ALL)) {
					mayEditUser = true;
				}
				else {
					if (authManager.hasPermission(UserRole.PERMISSION_USER_EDIT_SELF)) {
						if (u.id === model.curUserId)
							mayEditUser = true;
					}
				}

				//ist es ein Readonly-User?
				if (u.type === constants.USER_TYPE_READONLY)
					mayEditUser = false;
				
				if (mayEditUser) {
					$("#btnEditUser").show().off("click").on("click", function () {
						//that.onUserEditLoaded(u, true);
						viewManager.showPage(constants.PAGE_USER_EDIT, {
							userId: model.curEditedUser.id
						});
					});
				}
				else
					$("#btnEditUser").hide();

				//selbst löschen
				if (model.curUserId === u.id ) {
					$("#btnDeleteSelf").show().on("click", $.proxy(that.setUserStatusDeleted, userDetailManager));
				}
				else
					$("#btnDeleteSelf").hide();
			}
			
			$("#btnBackUser").off("click").on("click", function () {
				model.curUserListView.preserveCurPage = true;
				window.history.back();
			});
		},
		
		//LocationTypes merken
		updateLocationTypesVault: function() {
			var lt = $("#selectLocationTypes").val();
			if (lt) {
				lt = lt.map(function(x){
					return parseInt(x);
				});
			}
			this.prevLocationTypes = lt;
		},
		//-------------------------------------------
		
		//Löschen
		setUserStatusDeleted: function () {
			
			var that = this;

			var isSelf = (model.curEditedUser.id === model.curUserId);
			var question = "Möchten Sie diesen Benutzer wirklich l&ouml;schen?";
			if (isSelf)
				question = "Möchten Sie Ihren Benutzer-Account wirklich l&ouml;schen?";

			pg.confirmMsg("L&ouml;schen", question, function (e) {
				if (e) {
					
					if (isSelf){
						//nochmal nachfragen
						pg.confirmMsg("L&ouml;schen", "<b>Vorsicht</b>: Dieser Schritt ist unumkehrbar!<br/>Möchten Sie Ihren Account wirklich endgültig löschen? Sie werden danach automatisch abgemeldet.", function (e2) {
							if (e2) {
								that.execSetUserStatusDeleted();
							}
							else {
								viewManager.updateButtons();
							}
						});
					}
					else {
						that.execSetUserStatusDeleted();
					}
				}
				else {
					viewManager.updateButtons();
				}
			});
			
			return false;
		},

		execSetUserStatusDeleted: function(){

			var that = this;

			if (that.isCurObjOutdated)
				that.updateCurObj();

			//gibt es Aufträge, die *nur* diesem User zugeordnet sind (indirekt oder als Gruppe, in der nur er enthalten ist)
			var u = _.cloneDeep(model.curEditedUser);
			u.status = constants.STATUS_OBJECT_DELETED;

			//prüfen, ob Nachfolge-Regelungen getroffen werden müssen
			successorManager.checkSuccessor(u, function (uResp) {

				//merken
				if (uResp) {
					
					//nicht mehr Owner - wer dann?
					if (u.id === model.client.ownerId) {
						successorManager.requestClientOwner(u.id, function (uId) {
							
							//merken
							if (uId) {
								//Ownership auf diesen Benutzer umschalten
								uResp.newClientOwnerId = uId;
								
								//erst jetzt speichern
								dataManager.saveUser(uResp, that.onUserSaved);
							}
							else{
								viewManager.updateButtons();
							}
							
						});
					}
					else {
						
						//nicht Owner, normal weiter
						dataManager.saveUser(uResp, that.onUserSaved);
					}
				}
				else{
					viewManager.updateButtons();
				}
			});

		},

		//--------------------------------------------------------------------------------------------------------

		//Einladung erneut versenden
		resendUserInvitation: function(){
			dataManager.sendInvitation(model.curEditedUser.email, function(success){
				if (success)
					viewManager.showNotification("Die Einladung wurde erneut versendet.", constants.NOTIFICATION_SUCCESS);
				else
					viewManager.showNotification("Es gab ein Problem beim Versand der Einladung.", constants.NOTIFICATION_WARNING);
				viewManager.updateButtons();
			});
		},

		//--------------------------------------------------------------------------------------------------------
		
		//Validierungsfehler anzeigen
		showError: function (tabId, hasError, forceTabVisibleOnError, boxEl, errMsg) {
			
			if (hasError === undefined)
				hasError = true;
			if (forceTabVisibleOnError === undefined)
				forceTabVisibleOnError = false;
			if (boxEl === undefined)
				boxEl = null;

			var formEl = $('#formUserEdit');
			var trgTab = formEl.find("ul.nav li[data-tab-id='" + tabId + "']");
			var error = formEl.find(".alert[data-tab-id='" + tabId + "']");
			var error2 = formEl.find(".help-block[data-tab-id='" + tabId + "']");

			if (hasError) {
				trgTab.addClass("has-error");
				if (boxEl){
					boxEl.addClass("has-error");
					var help = $(".help-block", boxEl);
					help.html(errMsg).show();
				}
				else {
					error.show();
				}
				if (forceTabVisibleOnError)
					viewManager.activateNavTab("#userEditorBox", tabId);
			}
			else {
				trgTab.removeClass("has-error");
				error.hide();
				error2.hide();
			}
			
		},
		
		//Fehler verstecken
		clearErrors: function () {
			var formEl = $('#formUserEdit');
			$('.alert-danger', formEl).hide();
			$('.has-error', formEl).removeClass("has-error");
		},
		
		//Email validieren: Nur einmal vorhanden?
		validateUniqueEmail: function () {
			//email muss (innerhalb dieses Clients) unique sein!
			var email = $("#email").val().toLowerCase();
			for(var i = 0; i < model.users.length; i++) {
				var u2 = model.users[i];
				if ((u2.status !== constants.STATUS_OBJECT_DELETED) && (u2.id !== model.curEditedUser.id)) {
					if (u2.email.toLowerCase() === email) {
						pg.showMsg("Diese Email-Adresse wird bereits verwendet.");
						userDetailManager.showError(0);
						return false;
					}
				}
			}
			
			return true;
		},
		
		//Validierung: Rolle ok?
		validateRole: function () {
			
			//Rolle
			var uRole = $("#selectRole").val();
			if (!uRole) {
				userDetailManager.showError(2);
				$("#userRoleBox").addClass("has-error");
				return false;
			}
			else
				$("#userRoleBox").removeClass("has-error");
			
			return true;
		},
		
		//Valdierung: Objekttyp ok?
		validateLocationType: function () {
			
			//LocationType(s)
			var hasLocationType = false;
			$('#selectLocationTypes :selected').each(function (i, selected) {
				hasLocationType = true;
			});
			if (!hasLocationType) {
				$("#userLocationTypesBox").addClass("has-error");
				return false;
			}
			else
				$("#userLocationTypesBox").removeClass("has-error");
			
			return true;
		},
		
		//Validierung: Permissions ok?
		validatePermissions: function (forceTabVisibleOnError) {
			var that = userDetailManager;
			
			var isValidPermissions = true;
			isValidPermissions = that.validateRole() && isValidPermissions;
			isValidPermissions = that.validateLocationType() && isValidPermissions;
			that.showError(2, !isValidPermissions, forceTabVisibleOnError);
			
			return isValidPermissions;
		},

		//Validierung: Passwort ok?
		validatePassword: function (forceTabVisibleOnError) {
			var that = userDetailManager;

			var isValidPassword = true;

			//clear
			that.showError(1, false, false);

			if (that.hasEnteredNewPassword()){

				var pwdOld = $("#pwdOld").val();
				var pwdNew1 = $("#pwdNew1").val();
				var pwdNew2 = $("#pwdNew2").val();

				//ist auch das alte angegeben?
				if (pwdOld.length < 3) {
					isValidPassword = false;
					that.showError(1, true, true, $("#pwdOldBox"), "Geben Sie bitte Ihr bisheriges Passwort ein.");
				}

				//stimmt die Stärke?
				if (!authManager.validatePwdStrength(pwdNew1)){
					isValidPassword = false;
					that.showError(1, true, true, $("#pwdNew1Box"), "Bitte geben Sie ein gültiges Passwort ein. Mindestens 8 Zeichen, die mindestens 3 der folgenden Zeichen enthalten:<br/>\n" +
						"                                                <ul>\n" +
						"                                                    <li>Ziffer (0-9)</li>\n" +
						"                                                    <li>Kleinbuchstabe (a-z)</li>\n" +
						"                                                    <li>Großbuchstabe (A-Z)</li>\n" +
						"                                                    <li>Sonderzeichen (!?$%&@(){}[]^=+*#;,:.-_~)</li>\n" +
						"                                                </ul>");
				}

				//stimmen beide überein?
				if ((pwdNew1 !== pwdNew2) || (pwdNew2.length < 8)){
					isValidPassword = false;
					that.showError(1, true, true, $("#pwdNew2Box"), "Die eingegebenen Passwörter stimmen nicht überein.");
				}

				//immer weg (werden automatisch erzeugt)!
				$("#pwdOld-error").hide();
				$("#pwdNew1-error").hide();
				$("#pwdNew2-error").hide();

			}

			return isValidPassword;
		},
		
		//high-level: Verschiedene eigene Validierungen
		validateComplexFields: function (defaultValidationHasPassed) {
			
			if (defaultValidationHasPassed === undefined)
				defaultValidationHasPassed = false;
			
			var that = userDetailManager;
			var isValid = true;
			
			//Email
			isValid = isValid && that.validateUniqueEmail(defaultValidationHasPassed);
			
			//Berechtigungen
			isValid = isValid && that.validatePermissions(defaultValidationHasPassed);

			//Passwort
			isValid = isValid && that.validatePassword(defaultValidationHasPassed);

			return isValid;
		},

		hasEnteredNewPassword: function(){
			if (model.curEditedUser.id < 0)
				return false;

			var pwdOld = $("#pwdOld").val();
			var pwdNew1 = $("#pwdNew1").val();
			var pwdNew2 = $("#pwdNew2").val();

			return (/*(pwdOld.length > 0) ||*/ (pwdNew1.length > 0) || (pwdNew2.length > 0));
		},
		
		//-------------------------------------------
		
		//Speichern (Validierung starten)
		initSaveUser: function () {
			
			var that = userDetailManager;
			
			if (that.isCurObjOutdated)
				that.updateCurObj();
			
			var u = _.cloneDeep(model.curEditedUser);
			
			//blocking
			if (!viewManager.setButtonBlocking($("#btnSaveUser")))
				return false;
			
			//etwas geändert?
			if (!model.isCurFormDirty) {
				if (u.id < 0)
					pg.showMsg("Bitte füllen Sie das Formular vollständig aus.");
				else
					viewManager.showNotification("Es wurden keine Änderungen festgestellt.");
				viewManager.updateButtons();
				return false;
			}
			
			//validate
			var formEl = $('#formUserEdit');
			
			userDetailManager.clearErrors();
			
			formEl.validate({
				errorElement: 'span', //default input error message container
				errorClass: 'help-block help-block-error', // default input error message class
				focusInvalid: true, // do not focus the last invalid input
				ignore: "",  // validate all fields including form hidden input
				messages: {},
				rules: {
					firstName: {
						required: true,
						minlength: 3
					},
					lastName: {
						required: true,
						minlength: 3
					},
					email: {
						required: true,
						email: true
					}
				},
				
				invalidHandler: function (event, validator) {
					//noinspection JSUnresolvedVariable
					for(var i = 0; i < validator.errorList.length; i++) {
						//noinspection JSUnresolvedVariable
						var err = validator.errorList[i];
						var tabId = parseInt($(err.element).attr("data-tab-id"));
						userDetailManager.showError(tabId);
						viewManager.activateNavTab("#userEditorBox", tabId);
					}
					userDetailManager.validateComplexFields();
					viewManager.updateButtons();
				},
				
				highlight: function (element) { // hightlight error inputs
					$(element).closest('.form-group').addClass('has-error');
				},
				
				unhighlight: function (element) { // revert the change done by hightlight
					$(element).closest('.form-group').removeClass('has-error');
				},
				
				success: function (label) {
					label.closest('.form-group').removeClass('has-error');
				}
			});
			
			//Validierung ausführen
			if (formEl.valid())
				that.execSaveUser(u, formEl);
		},
		
		execSaveUser: function(u, formEl) {

			var that = userDetailManager;

			$('.alert-danger', formEl).hide();
			$('.has-error', formEl).removeClass("has-error");

			if (!userDetailManager.validateComplexFields(true)) {
				viewManager.updateButtons();
				return;
			}

			//#944: ggf. Passwort-Abfrage erforderlich?
			if (u.id === model.curUserId) {
				that.promptUserPassword(u);
			}
			else{
				that.execSaveUser2(u);
			}
		},

		//kann rekursiv aufgerufen werden
		promptUserPassword: function(u, hasError){
			var that = this;

			var title = "Änderung bestätigen";
			var msg = '<strong>Bitte bestätigen Sie die Änderung an Ihrem persönlichen Benutzerkonto "' + model.curUser.firstName + ' ' + model.curUser.lastName + '" durch die Eingabe Ihres Passworts.</strong><br><br>Falls es sich nicht um Ihr persönliches Benutzerkonto handelt, wenden Sie sich bitte an den Administrator.';
			if (hasError){
				msg += '<div class="alert alert-danger mt15">Das eingegebene Passwort ist nicht korrekt.</div>';
			}

			authManager.verifyPassword(title, msg).then(function(){
				that.execSaveUser2(u);
			}, function err(isWrongPwd){
				//try again
				if (isWrongPwd)
					that.promptUserPassword(u, true);
				viewManager.updateButtons();
			});
		},

		execSaveUser2: function(u){

			var that = this;
			
			//check & handle status
			var newStatus = parseInt($("#selectStatus").val(), 10);
			if (isNaN(newStatus))
				newStatus = constants.STATUS_OBJECT_UNREGISTERED;
			if ($("#userStatusDeleted").val() === "1")
				newStatus = constants.STATUS_OBJECT_DELETED;
			u.status = newStatus;
			
			u.firstName = pg.validateInput($("#firstName").val(), 255);
			u.lastName = pg.validateInput($("#lastName").val(), 255);
			u.fon = pg.validateInput($("#fon").val(), 255);
			u.department = pg.validateInput($("#department").val(), 255);
			u.email = pg.validateInput($("#email").val(), 255);
			
			u.locationTypes = "";
			var hasAll = false;
			$('#selectLocationTypes :selected').each(function (i, selected) {
				var lType = parseInt($(selected).val());
				
				u.locationTypes += lType + "#";
				
				if (lType === LocationType.LOCATION_TYPE_ALL)
					hasAll = true;
			});
			if (hasAll)
				u.locationTypes = LocationType.LOCATION_TYPE_ALL + "#";
			u.updateLocationTypeIds();
			u.role = parseInt($("#selectRole").val(), 10);

			//Passwort ändern?
			//Annahme: Validierung ist positiv verlaufen!
			if (that.hasEnteredNewPassword()) {
				var pwdOld = $("#pwdOld").val();
				var pwdNew = $("#pwdNew1").val();

				u.pwdHashOld = pg.getSha512(pwdOld, constants.CRYPT_SEED);
				u.pwdHashNew = pg.getSha512(pwdNew, constants.CRYPT_SEED);
			}

			u.groups = "";
			$('#selectGroups :selected').each(function (i, selected) {
				u.groups += $(selected).val() + "#";
			});
			
			//auch für virtuelle Gruppe übernehmen
			var ug = UserGroup.getUserGroup(u.singleUserGroupId);
			if (ug) {
				ug.name = u.getName();
				ug.status = u.status;
			}

			//Notifications
			if (authManager.hasPermission(UserRole.PERMISSION_USER_EDIT_SELF) && (model.curEditedUser.id === model.curUserId)) {
				u.clearNotificationTypes();
				$(".chkNotificationType").each(function () {
					var el = $(this);
					if (el.prop("checked")) {
						var nType = parseInt(el.attr("data-id"));
						u.addNotificationType(nType);
					}
				});
			}
			//bei Neuanlage bestimmte Notifications setzen
			if (u.id < 0){
				u.clearNotificationTypes();
				u.addNotificationType(UserNotificationType.UN_TYPE_CREATED);
				u.addNotificationType(UserNotificationType.UN_TYPE_UPDATED);
				u.addNotificationType(UserNotificationType.UN_TYPE_STATUS_CHANGED);
				u.addNotificationType(UserNotificationType.UN_TYPE_COMMENT);
				u.addNotificationType(UserNotificationType.UN_TYPE_DUE);
				u.addNotificationType(UserNotificationType.UN_TYPE_OVER_DUE);
			}

			//--------------------------
			//Nachfolger
			
			if (u.id > 0) {
				
				//prüfen, ob Nachfolge-Regelungen getroffen werden müssen
				successorManager.checkSuccessor(u, function (uResp) {
					
					//merken
					if (uResp)
						dataManager.saveUser(uResp, that.onUserSaved);
				});
			}
			else
				dataManager.saveUser(u, userDetailManager.onUserSaved);
		},
		
		//Speichern komplett (u: neuer User, userToBeStored: das wurde gesendet)
		onUserSaved: function (u, isNew, userToBeStored) {
			
			viewManager.updateButtons();
			viewManager.clearFormDirty();

			model.curEditedUser = u;

			if (isNew) {
				//neuer Benutzer (per Definition Sprung auf Liste ok)
				viewManager.showPage(constants.PAGE_USER_LIST, null, true, false);
				viewManager.showNotification("Neuer Benutzer erfolgreich angelegt.", constants.NOTIFICATION_SUCCESS);
			}
			else {
				//aktualisierte Gruppe
				if (u.status !== constants.STATUS_OBJECT_DELETED) {

					//eigene?
					var gotoList = true;
					if (u.id === model.curUserId){
						gotoList = false;
					}

					if (gotoList && authManager.hasPermission(UserRole.PERMISSION_USER_LIST_ALL)) {
						viewManager.showPage(constants.PAGE_USER_LIST, null, true, false, true);
					}
					else{
						viewManager.showPage(constants.PAGE_USER_DETAILS, {
							userId: u.id
						}, true, false, false, false, function(){
							$("#btnBackUser").hide();
						});
					}
					viewManager.showNotification("Die Änderungen wurden gespeichert.", constants.NOTIFICATION_SUCCESS);
				}
				else {

					//selbst gelöscht? -> abmelden!
					if (u.id === model.curUserId) {
						authManager.logout(constants.LOGOUT_DELETED);
					}
					else {

						if (authManager.hasPermission(UserRole.PERMISSION_USER_LIST_ALL)) {
							viewManager.showPage(constants.PAGE_USER_LIST, null, true, false, true);
						}
						else {
							viewManager.showPage(constants.PAGE_USER_DETAILS, {
								userId: u.id
							}, true, false, false, false, function () {
								$("#btnBackUser").hide();
							});
						}
						viewManager.showNotification("Der Benutzer wurde gel&ouml;scht.", constants.NOTIFICATION_SUCCESS);
					}
				}
				
				//hat Ownership gewechselt?
				if (userToBeStored.newClientOwnerId > 0){
					model.client.ownerId = userToBeStored.newClientOwnerId;
				}
			}
			
		},
		
		//-------------------------------------------
		
		//neuen User hinzufügen
		addUser: function (callbackComplete) {
			
			var that = this;
			
			model.curEditedUser = User.createUser().fromObj({
				id: -1,
				clientId: model.curClientId,
				firstName: "",
				lastName: "",
				status: constants.STATUS_OBJECT_ACTIVE,
				singleUserGroupId: -1,
				email: ""
			});
			
			$("#content").html(window.templates['userEdit.html']);
			setTimeout(function () {
				
				that.onUserEditLoaded(model.curEditedUser, true);

				$("#userStatusBox").hide();
				$("#pwdBox").hide();
				$("#btnDeleteUser").hide();
				$("#btnInviteUser").hide();
				
				//update components
				viewManager.updateLayout();

				if (callbackComplete)
					callbackComplete();
				
			}, constants.TEMPLATE_DELAY);
			
			return false;
		},
		
		//-------------------------------------------
		
		//je nach Rolle Auswahl des Objekttyps deaktivieren
		updateLocationTypeDisabled: function (useExistingRole) {
			
			if (useExistingRole === undefined)
				useExistingRole = false;
			
			var isDisabled = false;
			var roleId = -1;
			if (useExistingRole && model.curEditedUser.id > 0) {
				roleId = model.curEditedUser.role;
			}
			else {
				roleId = $("#selectRole").val();
			}
			if (roleId) {
				var uRole = UserRole.getUserRole(roleId);
				isDisabled = (uRole.defaultLocationType === LocationType.LOCATION_TYPE_ALL);
			}
			$("#selectLocationTypes").prop("disabled", isDisabled);
		},
		
		//============================================================================================
		
		//tab changed
		onLocationTabChange: function (index) {
			model.curStateDetails.curTabIndex = index;
			userDetailManager.setLocationStateDetails();
		},
		
		setLocationStateDetails: function () {
			model.curStateDetails.scrollPosition = viewManager.getScrollTop();
			viewManager.setStateDetails(model.curStateDetails);
		}
		
	};
	
	window.userDetailManager = userDetailManager;
}());
