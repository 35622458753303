(function () {
	
	//----------------------------------------------------------------
	//Signal (z.B. Zustand eines Vorkommnisses)
	//----------------------------------------------------------------
	
	'use strict';
	
	var Signal = {
		
		SIGNAL_DEFECT: 1,
		SIGNAL_NOTIFY: 2,
		
		VALUE_OK_DEFAULT: 0,
		VALUE_NOT_OK: 1,
		
		signalPrototype: {
			
			fromObj: function (t) {
				
				this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				if (t.clientId)
					this.clientId = parseInt(t.clientId, 10);
				else
					this.clientId = model.curClientId;
				this.userId = parseInt(t.userId, 10);
				this.taskId = parseInt(t.taskId, 10);
				this.locationId = parseInt(t.locationId, 10);
				this.protocolId = parseInt(t.protocolId, 10);
				this.formId = parseInt(t.formId, 10);
				this.incidentId = parseInt(t.incidentId, 10);
				this.fieldId = parseInt(t.fieldId, 10);
				this.type = parseInt(t.type, 10);
				this.value = parseInt(t.value, 10);
				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.clientId = this.clientId;
				o.userId = this.userId;
				o.taskId = this.taskId;
				o.locationId = this.locationId;
				o.protocolId = this.protocolId;
				o.formId = this.formId;
				o.incidentId = this.incidentId;
				o.fieldId = this.fieldId;
				o.type = this.type;
				o.value = this.value;
				
				return o;
				
			},
			
			//----------------------------------------------

			getButtonCssDefault: function(){
				return "btn default btn-sm btn-block";
			},
			getButtonCss: function(){
				switch (this.type) {
					case Signal.SIGNAL_DEFECT:
						if (this.value === Signal.VALUE_NOT_OK)
							return "btn btn-danger btn-sm btn-block";
						break;
					case Signal.SIGNAL_NOTIFY:
						if (this.value === Signal.VALUE_NOT_OK)
							return "btn btn-danger btn-sm btn-block";
						break;
				}
				return this.getButtonCssDefault();
			},

			getValue: function () {
				var s = "";
                switch (this.type) {
                    case Signal.SIGNAL_DEFECT:
                    	s += '<i class="fa fa-fw fa-wrench"></i>';
                        if (this.value === Signal.VALUE_NOT_OK)
                            s += ' Nicht behoben';
                        else
                            s += ' Behoben';
                        break;
                    case Signal.SIGNAL_NOTIFY:
                        s += '<i class="fa fa-fw fa-flag"></i>';
                        if (this.value === Signal.VALUE_NOT_OK)
                            s += ' Gemeldet';
                        else
                            s += ' Nicht gemeldet';
                        break;
                }
				return s;
			},
			getCheckboxValue: function () {
				if (this.value === Signal.VALUE_OK_DEFAULT)
					return 'checked';
				return "";
			},

			//----------------------------------------------
			
			//Signal umschalten
			toggle: function () {
				if (this.value === 1)
					this.value = 0;
				else
					this.value = 1;
			},

			//----------------------------------------------

			isNewestSignal: function(list, selfIndex){

				//keine alten Werte berücksichtigen -> auf überschriebene Werte prüfen
				var isNewestSignal = true;
				for (var l=selfIndex+1; l<list.length; l++){
					if ((list[l].formId === this.formId) && (list[l].fieldId === this.fieldId) && (list[l].type === this.type)) {
						isNewestSignal = false;
						break;
					}
				}
				return isNewestSignal;
			}
		},
		
		//----------------------------------------------
		
		createSignal: function (clientId, userId, taskId, locationId, protocolId, formId, incidentId, fieldId, type, value) {
			var signal = Object.create(Signal.signalPrototype);
			
			signal.id = -1;
			signal.clientId = clientId;
			signal.userId = userId;
			signal.taskId = taskId;
			signal.locationId = locationId;
			signal.protocolId = protocolId;
			signal.formId = formId;
			signal.incidentId = incidentId;
			signal.fieldId = fieldId;
			signal.type = type;
			signal.value = value;
			signal.createdOn = new Date();
			signal.createdBy = -1;
			signal.changedOn = new Date();
			signal.changedBy = -1;
			
			return signal;
		},
		createSignalRaw: function () {
			return Object.create(Signal.signalPrototype);
		}
	};
	
	window.Signal = Signal;
}());