(function () {
	
	//----------------------------------------------------------------
	//Benutzergruppe
	//es gibt auch Gruppen, die nur fix einen bestimmten User beinhalten. Jeder User hat so eine Gruppe
	//----------------------------------------------------------------
	
	/*global constants:true, User:true, UserRole:true, authManager:true, Location:true, TaskType:true*/
	
	'use strict';
	
	var UserGroup = {
		
		userGroupPrototype: {
			
			users: [],
			
			fromObj: function (t) {

				this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				this.clientId = parseInt(t.clientId, 10);
				this.status = parseInt(t.status, 10);
				this.name = pg.restoreDbString(t.name);
				this.isSingleUser = parseInt(t.isSingleUser, 10);
				this.isNew = (t.isNew) ? t.isNew : 0;
				
				this.updateUsers(t.userIds);
				
				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.clientId = this.clientId;
				o.status = this.status;
				o.name = this.name;
				o.isSingleUser = this.isSingleUser;
				o.userIds = "";
				for (var i = 0; i < this.users.length; i++)
					o.userIds += this.users[i].id + "#";
				
				return o;
			},
			
			//----------------------------------------------
			
			getName: function(shortVersion){

				if (shortVersion === undefined)
					shortVersion = false;

				if (this.isSingleUser){
					return this.getSingleUser().getName(shortVersion);
				}
				return this.name;
			},
			
			//----------------------------------------------
			
			//ist diese Gruppe eigentlich ein einzelner User?
			getSingleUser: function () {
				if (this.isSingleUser)
					return this.users[0];
				return null;
			},
			
			//----------------------------------------------
			
			//User deserialisieren
			updateUsers: function (userIds) {
				var ids = userIds.substring(0, userIds.length - 1).split("#");
				this.users = [];
				for (var i = 0; i < ids.length; i++) {
					var userId = parseInt(ids[i], 10);
					var user = User.getUser(userId);
					if (user)
						this.users.push(user);
				}
			},

			getUsers: function(){
				return this.users;
			},
			
			//----------------------------------------------
			
			//ist ein bestimmer User Mitglied?
			contains: function (userId) {
				for (var i = 0; i < this.users.length; i++)
					if (parseInt(this.users[i].id) === userId)
						return true;
				return false;
			},
			
			//----------------------------------------------
			//Rechte für Locations prüfen
			
			hasEditPermissionsForLocations: function(taskTypeId, locationIds, simulatedUser, requiredLocationType) {
				return this.hasPermissionsForLocations(false, taskTypeId, locationIds, simulatedUser, requiredLocationType);
			},
			hasExecutionPermissionsForLocations: function(taskTypeId, locationIds, simulatedUser, requiredLocationType) {
				return this.hasPermissionsForLocations(true, taskTypeId, locationIds, simulatedUser, requiredLocationType);
			},
			
			hasPermissionsForLocations: function(permissionToExecute, taskTypeId, locationIds, simulatedUser, requiredLocationType) {
				
				var j,
					u,
					hasUserWithPermissions,
					hasPermissions;
				
				if (requiredLocationType === undefined)
					requiredLocationType = -1;
				
				//Sonderfall: Auftragstyp hat keine Locations!
				var tt = TaskType.getTaskType(taskTypeId);
				if (tt.editLocations === constants.TASK_EDITOR_HIDDEN || ((tt.editLocations === constants.TASK_EDITOR_OPTIONAL && requiredLocationType < 0))){
					
					hasUserWithPermissions = false;
					for(j = 0; j < this.users.length; j++) {
						
						u = this.users[j];
						
						//Simulation überschreibt Realität
						if (simulatedUser)
							if (simulatedUser.id === u.id)
								u = simulatedUser;
						
						//Rechte?
						hasPermissions = false;
						if (permissionToExecute) {
							//exec
							hasPermissions = u.canExecuteTaskType(taskTypeId);
						}
						else {
							//edit
							hasPermissions = authManager.hasUserPermission(u, UserRole.PERMISSION_TASK_EDIT_ALL, UserRole.PERMISSION_TASK_EDIT_OWN);
							if (!hasPermissions) {
								if (authManager.hasUserPermission(u, UserRole.PERMISSION_TASK_EDIT_BY_TASK_TYPE)) {
									if (u.hasTaskType(taskTypeId))
										hasPermissions = true;
								}
							}
						}
						
						//Objekttyp?
						if (hasPermissions) {
							hasUserWithPermissions = true;
							break;
						}
					}
					
					if (!hasUserWithPermissions)
						return false;
					
				}
				else {
					//---------------------------------
					//mind. einer mit entsprechendem LocationType (abstrakter Check)
					if (requiredLocationType > 0) {
						hasUserWithPermissions = false;
						for(j = 0; j < this.users.length; j++) {
							
							u = this.users[j];
							
							//Simulation überschreibt Realität
							if (simulatedUser)
								if (simulatedUser.id === u.id)
									u = simulatedUser;
							
							//Rechte?
							hasPermissions = false;
							if (permissionToExecute) {
								//exec
								hasPermissions = u.canExecuteTaskType(taskTypeId);
							}
							else {
								//edit
								hasPermissions = authManager.hasUserPermission(u, UserRole.PERMISSION_TASK_EDIT_ALL, UserRole.PERMISSION_TASK_EDIT_OWN);
								if (!hasPermissions) {
									if (authManager.hasUserPermission(u, UserRole.PERMISSION_TASK_EDIT_BY_TASK_TYPE)) {
										if (u.hasTaskType(taskTypeId))
											hasPermissions = true;
									}
								}
							}
							
							//Objekttyp?
							if (hasPermissions) {
								if (u.hasLocationType(requiredLocationType)) {
									hasUserWithPermissions = true;
									break;
								}
							}
						}
						
						if (!hasUserWithPermissions)
							return false;
					}
					
					//---------------------------------
					//jedes Objekt von mind. einem
					for(var i = 0; i < locationIds.length; i++) {
						
						hasUserWithPermissions = false;
						for(j = 0; j < this.users.length; j++) {
							
							u = this.users[j];
							
							//Simulation überschreibt Realität
							if (simulatedUser)
								if (simulatedUser.id === u.id)
									u = simulatedUser;
							
							//Rechte?
							hasPermissions = false;
							if (permissionToExecute) {
								//exec
								hasPermissions = u.canExecuteTaskType(taskTypeId);
							}
							else {
								//edit
								hasPermissions = authManager.hasUserPermission(u, UserRole.PERMISSION_TASK_EDIT_ALL, UserRole.PERMISSION_TASK_EDIT_OWN);
								if (!hasPermissions) {
									if (authManager.hasUserPermission(u, UserRole.PERMISSION_TASK_EDIT_BY_TASK_TYPE)) {
										if (u.hasTaskType(taskTypeId))
											hasPermissions = true;
									}
								}
							}
							
							//Objekttyp?
							if (hasPermissions) {
								var loc = Location.getLocation(locationIds[i], null, true, true, true);
								if (u.hasLocationType(loc.type)) {
									hasUserWithPermissions = true;
									break;
								}
							}
						}
						
						if (!hasUserWithPermissions)
							return false;
					}
				}
				
				return true;
			},
			
			//----------------------------------------------
			
			//für jede Location muss mind. ein Benutzer ausführen dürfen
			canExecuteTask: function(task, simulatedUser){
				
				//Test: hat für jedes Objekt die ausgewählte Gruppe überhaupt mind. einen Benutzer mit entsprechenden Rechten?
				var locationIds = task.getLocationIds();
				var locType = task.locationType ? task.locationType : -1;
				if (!this.hasExecutionPermissionsForLocations(task.taskTypeId, locationIds, simulatedUser, locType))
					return false;

				return true;
			}
		},
		
		//----------------------------------------------
		
		getUserGroup: function (id) {
			id = parseInt(id);
			for (var i = 0; i < model.userGroups.length; i++)
				if (model.userGroups[i].id === id)
					return model.userGroups[i];
			return null;
		},
		
		//----------------------------------------------
		
		//alle aktiven holen
		getNormalUserGroups: function () {
			var a = [];
			for (var i = 0; i < model.userGroups.length; i++) {
				var ug = model.userGroups[i];
				if (ug.status === constants.STATUS_OBJECT_ACTIVE)
					if (!ug.isSingleUser)
						a.push(ug);
			}
			return a;
		},
		
		//komplexere Methode mit Kriterien
		//active and not exclusive to
		getActiveUserGroups: function (userIdNotExclusiveTo, userGroupIdNotExclusiveTo, filterEmpty, requiredTaskTypeIdToHaveExecutionPermissionFor, locationIds, simulatedUser, requiredLocationType, onlyEditPermissionRequired, doIgnoreStatus) {
			
			var j,
				hasUserWithPermissions;
			
			if (filterEmpty === undefined)
				filterEmpty = false;
			if (requiredTaskTypeIdToHaveExecutionPermissionFor === undefined)
				requiredTaskTypeIdToHaveExecutionPermissionFor = -1;
			if (locationIds === undefined)
				locationIds = [];
			if (requiredLocationType === undefined)
				requiredLocationType = -1;
			if (onlyEditPermissionRequired === undefined)
				onlyEditPermissionRequired = false;
			if (doIgnoreStatus === undefined)
				doIgnoreStatus = false;
			
			var a = [];
			for (var i = 0; i < model.userGroups.length; i++) {
				var ug = model.userGroups[i];
				
				if (userGroupIdNotExclusiveTo)
					if (ug.id === userGroupIdNotExclusiveTo)
						continue;
				
				if (filterEmpty) {
					if (ug.users.length === 0)
						continue;
				}
				
				if (ug.status === constants.STATUS_OBJECT_ACTIVE/*xxx || doIgnoreStatus*/) {
					
					//singleUserGroup: zugeh. User aktiv?
					var ugSingle = ug.getSingleUser();
					if (ugSingle) {
						//xxxif (!doIgnoreStatus)
							if (ugSingle.status !== constants.STATUS_OBJECT_ACTIVE)
								continue;
						
						//verstecke alle mit ROLE_SYSTEM
						if (ugSingle.isSystemAdmin())
							continue;
					}
					
					if (userIdNotExclusiveTo) {
						var hasOtherUsers = false;
						for (j = 0; j < ug.users.length; j++) {
							if (ug.users[j].id !== userIdNotExclusiveTo) {
								hasOtherUsers = true;
								break;
							}
						}
						if (!hasOtherUsers)
							continue;
					}
					
					//Rechte erforderlich?
					if (requiredTaskTypeIdToHaveExecutionPermissionFor > 0){
						
						if (onlyEditPermissionRequired){
							//view
							hasUserWithPermissions = ug.hasEditPermissionsForLocations(requiredTaskTypeIdToHaveExecutionPermissionFor, locationIds, simulatedUser, requiredLocationType);
						}
						else {
							//exec
							hasUserWithPermissions = ug.hasExecutionPermissionsForLocations(requiredTaskTypeIdToHaveExecutionPermissionFor, locationIds, simulatedUser, requiredLocationType);
						}
						if (!hasUserWithPermissions)
							continue;
					}
					
					a.push(ug);
				}
			}
			//nach Namen sortieren
			a.sort(function (a2, b) {
				var aName = a2.name.toLowerCase();
				var bName = b.name.toLowerCase();
				return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
			});
			return a;
		},
		
		//----------------------------------------------
		
		getUserGroupByName: function (name) {
			
			for (var i = 0; i < model.userGroups.length; i++) {
				var ug = model.userGroups[i];
				
				if (ug.status !== constants.STATUS_OBJECT_DELETED) {
					if (ug.name === name)
						return ug;
				}
			}
			
			return null;
		},

		//----------------------------------------------

		getUserGroupNames: function(userGroupIds, shortVersion){

			if (shortVersion === undefined)
				shortVersion = false;

			var allUserGroups = userGroupIds.split("#");
			var userGroups = [];
			for (var k = 0; k < allUserGroups.length; k++) {
				if (allUserGroups[k] === '')
					continue;
				userGroups.push(UserGroup.getUserGroup(allUserGroups[k]));
			}

			var ugName = "";
			$.each(userGroups, function(index, ug){
				if (index > 0)
					ugName += ", ";
				ugName += ug.getName(shortVersion);
			});
			return ugName;

		},

		//----------------------------------------------
		
		createUserGroup: function () {
			return Object.create(UserGroup.userGroupPrototype);
		}
	};
	
	window.UserGroup = UserGroup;
}());