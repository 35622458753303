(function () {

	//-----------------------------------------
	//Upload-Manager
	//-----------------------------------------
	
    'use strict';
	
    //diese, nicht die Version aus der PG-Lib verwenden!
    function setupFileUploader(trg, callback, contentType, isPrivate) {

    	if (isPrivate === undefined)
		    isPrivate = true;
    	
        //DOM ergänzen
        var r = Math.round(10000 * Math.random());
        var s = '';
        s += '<input id="fileupload_' + r + '" type="file" name="files_' + r + '[]" multiple>';
        s += '<div id="files_' + r + '" class="files_' + r + '"></div>';
        //noinspection JSUnresolvedFunction
        trg.html(s);
        //Ziel ist eingefügtes Objekt
        trg = trg.find("#fileupload_" + r);

        model.fileUploadCallback = callback;

        $(".uploadProgress").hide();
        $(".progress-error").hide();

        var acceptFileTypes, acceptFileTypesExt;
        switch (contentType) {
            case constants.MEDIA_TYPE_PDF:
                acceptFileTypes = /^(pdf)$/i;
                acceptFileTypesExt = /(\.|\/)(pdf)$/i;
                break;
            case constants.MEDIA_TYPE_IMAGE:
                acceptFileTypes = /^(gif|jpe?g|png)$/i;
                acceptFileTypesExt = /(\.|\/)(gif|jpe?g|png)$/i;
                break;
            case constants.MEDIA_TYPE_IMAGE_PDF:
                acceptFileTypes = /^(gif|jpe?g|png|pdf)$/i;
                acceptFileTypesExt = /(\.|\/)(gif|jpe?g|png|pdf)$/i;
                break;
            case constants.MEDIA_TYPE_VIDEO:
                acceptFileTypes = /^(mp4)$/i;
                acceptFileTypesExt = /(\.|\/)(mp4)$/i;
                break;
        }

        //https://github.com/blueimp/jQuery-File-Upload/wiki/Options
        var uploadOptions = '{';
	    uploadOptions += '"clientId": ' + model.curClientId + ', ';
        uploadOptions += '"filesId": "files_' + r + '", ';
	    uploadOptions += '"isPrivate": ' + (isPrivate ? 1 : 0);
	    uploadOptions += '}';
        //noinspection JSUnresolvedFunction
        trg.attr("data-form-data", uploadOptions);
        //noinspection JSUnresolvedVariable,JSUnresolvedFunction
        trg.fileupload({
            add: function (e, data) {
                var uploadErrors = [];
                //noinspection JSUnresolvedVariable
                var ext = data.originalFiles[0].name.substring(data.originalFiles[0].name.lastIndexOf(".") + 1).toLowerCase();
                if (!acceptFileTypes.test(ext)) {
                    uploadErrors.push('Kein g&uuml;ltiger Dateityp.');
                }

                if (data.originalFiles[0].name === "NotFoundError"){
					uploadErrors.push('Es gibt ein Problem mit dem Datei-Pfad.');
				}
				else {
					//noinspection JSUnresolvedVariable
					if (data.originalFiles[0].size.length && data.originalFiles[0].size > 1999000) {
						uploadErrors.push('Datei ist zu gro&szlig;.');
					}
				}

                if (uploadErrors.length > 0) {
                    pg.showMsg(uploadErrors.join("<br/>"));
                } else {
                    data.submit();
                }
            },
            url: constants.API_BASE + '/upload.php',
            dataType: 'json',
            autoUpload: true,
            //acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
            acceptFileTypes: acceptFileTypesExt,
            maxFileSize: 1999000,
            // Enable image resizing, except for Android and Opera,
            // which actually support image resizing, but fail to
            // send Blob objects via XHR requests:
            /*disableImageResize: /Android(?!.*Chrome)|Opera/
             .test(window.navigator.userAgent),*/
            disableImageResize: true,
            previewMaxWidth: 150,
            previewMaxHeight: 150,
            previewCrop: false
        })

        .on('fileuploadsubmit', function (e, data) {
            //test extension
            var filename = data.files[0].name;
            return (acceptFileTypesExt.test(filename));
        })
	        
        .on('fileuploadprogressall', function (e, data) {
            $(".uploadProgress").show();
            $(".progress-bar").show();
            var progress = parseInt(data.loaded / data.total * 100, 10);
            $('.uploadProgress .progress-bar').css('width', progress + '%');
        })
	        
        .on('fileuploaddone', function (e, data) {

            $.each(data.result.files, function (index, file) {
                $(".uploadProgress").hide();

                if (file.url) {
                    if (model.fileUploadCallback)
                        model.fileUploadCallback(file);
                }
                else if (file.error)
                    pg.showMsg(file.error);
            });
            //noinspection JSUnresolvedFunction
            e.preventDefault();
        })
	        
        .on('fileuploadfail', function (e, data) {
            $.each(data.files, function () {
                $(".uploadProgress").hide();
                pg.showMsg('Error uploading data.');
            });
        })
	        
	        .prop('disabled', !$.support.fileInput)
        .parent().addClass($.support.fileInput ? undefined : 'disabled');
    }

    window.setupFileUploader = setupFileUploader;
}());