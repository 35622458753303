(function () {
	
	//--------------------------------------------------------------
	//Einstellungen verwalten
	//--------------------------------------------------------------
	
	/*global
	 Task:true,
	 viewManager:true,
	 dataManager:true,
	 authManager:true,
	 UserRole:true,
	 User:true
	 */
	
	'use strict';
	
	var settingsManager = {
		
		//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
		checkModelChanged: function(data, oldModel){
			
			if (data.client){
				
				//keine eigenen Änderungen berücksichtigen!
				var tsOld = oldModel.client.changedOn.getTime();
				var tsNew = pg.parseDate(data.client.changedOn).getTime();
				return (tsNew !== tsOld);
			}
			
			return false;
		},
		
		//------------------------------------------------------------------------------------------
		
		//Anzeige
		curClient: null,
		showSettings: function (callbackComplete) {
			
			var that = this;
			
			that.curClient = _.cloneDeep(model.client);
			
			viewManager.setSelectedMenuItem('menuSettings', 'menuGeneralSettings');
			
			$("#content").html(window.templates['settings.html']);
			setTimeout(function () {
				
				if (authManager.hasPermission(UserRole.PERMISSION_CLIENT_EDIT)) {
					
					$(".clientEdit").show();

					//-----------------------------------------------
					//Auto-Archive

					//Anzeigen
					that.showAASettings();
					
					//Handler
					that.isInitalSetup = true;
					$("#chkAutoArchive").bootstrapSwitch({
						onText: '<i class="fa fa-fw fa-check"></i>',
						offText: '<i class="fa fa-fw fa-times"></i>',
						size: "mini",
						onSwitchChange: function (event, state) {
							
							$(".autoArchiveSetting").slideUp(200);
							if (state) {
								if (that.curClient.hasModule(constants.MODULE_ID_REQUESTS))
									$("#autoArchiveSettingsRequests").removeClass("hidden").slideDown(200);
								
								$("#autoArchiveSettingsTasks").removeClass("hidden").slideDown(200);

								if (!that.isInitalSetup) {
									//defaults zeigen
									that.curClient.autoArchiveTasksPattern = "W2";
									if (that.curClient.hasModule(constants.MODULE_ID_REQUESTS))
										that.curClient.autoArchiveRequestsPattern = "W2";
								}
									
								that.curClient.hasAutoArchive = 1;
							}
							else {
								that.curClient.hasAutoArchive = 0;
							}
							that.showAASettings();
							that.isInitalSetup = false;
						}
					});
					
					//update Switch
					$("#chkAutoArchive").bootstrapSwitch('state', (that.curClient.hasAutoArchive === 1), false);

					//------------------------------------------------
					//Auto-Release Requests

					if (authManager.hasModule(constants.MODULE_ID_REQUESTS)) {

						$("#autoReleaseRequestsBox").show();
						$("#chkAutoReleaseRequests").bootstrapSwitch({
                            onText: '<i class="fa fa-fw fa-check"></i>',
                            offText: '<i class="fa fa-fw fa-times"></i>',
							size: "mini",
							onSwitchChange: function (event, state) {
								that.curClient.autoReleaseRequests = (state) ? 1 : 0;
								that.showArrSettings();
							}
						});

						//Anzeigen
						that.showArrSettings();
					}
					else{
						$("#autoReleaseRequestsBox").hide();
					}

                    //-----------------------------------------------
                    //E-Mail Reports

                    $("#chkEmailReportsTaskOverdue").bootstrapSwitch({
                        onText: '<i class="fa fa-fw fa-check"></i>',
                        offText: '<i class="fa fa-fw fa-times"></i>',
                        size: "mini",
                        onSwitchChange: function (event, state) {
							viewManager.setFormDirty();
							that.curClient.hasEmailReports = (state) ? 1 : 0;
							that.showEmailReportsSettings();
                        }
                    });

					//Anzeigen
					that.showEmailReportsSettings();

                }
				else{
					$(".clientEdit").hide();
					$("#autoReleaseRequestsBox").hide();
				}
				
				//-------------------------------------------------------------------------------------------------
				//Auto-Archive

				//handler
				$("#btnSaveAASettings").off("click").on("click", function () {
					
					if (!that.validateAaSettings())
						return;
					
					that.curClient.autoArchiveTasksPattern = that.getAutoArchiveTasks();
					that.curClient.autoArchiveRequestsPattern = that.getAutoArchiveRequests();
					dataManager.setAutoArchive(that.curClient, function (c) {
						that.curClient = c;
						
						//aufräumen
						viewManager.updateButtons();
						viewManager.clearFormDirty();
						that.clearAaSettingsErrors();

						//Anzeigen
						that.showAASettings();
						viewManager.showNotification("Einstellungen erfolgreich aktualisiert.", constants.NOTIFICATION_SUCCESS);
					});
				});

				//-------------------------------------------------------------------------------------------------
				//Auto-Release

				//handler
				$("#btnSaveArrSettings").off("click").on("click", function () {

					dataManager.setAutoReleaseRequests(that.curClient, function (c) {
						that.curClient = c;

						//aufräumen
						viewManager.updateButtons();
						viewManager.clearFormDirty();
						$("#formSettingsArrEdit .has-error").removeClass("has-error");

						//Anzeigen
						that.showArrSettings();
						viewManager.showNotification("Einstellungen erfolgreich aktualisiert.", constants.NOTIFICATION_SUCCESS);
					});
				});

				//-------------------------------------------------------------------------------------------------
				//E-Mail Reports

				//save handler
				$("#btnSaveEmailReportsSettings").off("click").on("click", $.proxy(that.saveEmailReportSettings, that));
				
				viewManager.updateButtons();

				if (callbackComplete)
					callbackComplete();
				
			}, constants.TEMPLATE_DELAY);
		},

		//-------------------------------------------------------------------------------------------------
		//Auto-Archive

		clearAaSettingsErrors: function(){
			//Fehler-Hinweise entfernen
			$("#formSettingsEdit .has-error").removeClass("has-error");
		},

		validateAaSettings: function(){
			
			var that = this;
			var isValid = true;

			//Fehler-Hinweise entfernen
			this.clearAaSettingsErrors();

			//check: Faktor
			var factorTasks = $("#autoArchiveTasksFactor").val();
			if (!factorTasks) {
				$("#autoArchiveSettingsTasks").addClass("has-error");
				viewManager.updateButtons();
				isValid = false;
			}

			//Domain
			var domainTasks = $("#autoArchiveTasksEntity").val();
			if (!domainTasks) {
				$("#autoArchiveSettingsTasks").addClass("has-error");
				viewManager.updateButtons();
				isValid = false;
			}

			if (that.curClient.hasModule(constants.MODULE_ID_REQUESTS)){

				var factorRequests = $("#autoArchiveRequestsFactor").val();
				if (!factorRequests) {
					$("#autoArchiveSettingsRequests").addClass("has-error");
					viewManager.updateButtons();
					isValid = false;
				}

				//Domain
				var domainRequests = $("#autoArchiveRequestsEntity").val();
				if (!domainRequests) {
					$("#autoArchiveSettingsRequests").addClass("has-error");
					viewManager.updateButtons();
					isValid = false;
				}
			}

			return isValid;
		},
		
		showAASettings: function(){

			this.clearAaSettingsErrors();

			//Anzeigen
			var aaTasks = Task.getIntervalValues(this.curClient.autoArchiveTasksPattern);
			$("#autoArchiveTasksFactor").val(aaTasks.factor);
			$("#autoArchiveTasksEntity").val(aaTasks.intervalType);
			var aaRequests = Task.getIntervalValues(this.curClient.autoArchiveRequestsPattern);
			$("#autoArchiveRequestsFactor").val(aaRequests.factor);
			$("#autoArchiveRequestsEntity").val(aaRequests.intervalType);
		},
		
		getAutoArchiveTasks: function () {
			return $("#autoArchiveTasksEntity").val() + $("#autoArchiveTasksFactor").val();
		},
		getAutoArchiveRequests: function () {
			return $("#autoArchiveRequestsEntity").val() + $("#autoArchiveRequestsFactor").val();
		},
		
		maxLengthCheck: function(object) {
			var v = $(object).val();
			if (v.length > object.max.length)
				v = object.value.slice(0, object.max.length);
			
			//manuelle Eingaben müssen min/max respektieren
			var minVal = parseInt($(object).attr("min"));
			var maxVal = parseInt($(object).attr("max"));
			v = Math.max(minVal, Math.min(maxVal, v));
			
			$(object).val(v);
		},

		//-------------------------------------------------------------------------------------------------
		//Auto-Release

		showArrSettings: function(){
			//Anzeigen
			$("#chkAutoReleaseRequests").bootstrapSwitch('state', (this.curClient.autoReleaseRequests === 1), false);
		},


		//-------------------------------------------------------------------------------------------------
		//Email Reports

		showEmailReportsSettings: function(){

			//Anzeigen
			$("#chkEmailReportsTaskOverdue").bootstrapSwitch('state', (this.curClient.hasEmailReports === 1), false);

			//aktiviert?
			if (this.curClient.hasEmailReports === 1){

				//Pattern auswählen
				//Default
				if (!this.curClient.emailReportsPattern)
					this.curClient.emailReportsPattern = "W";
				$("#selectEmailReportsFrequency").val(this.curClient.emailReportsPattern);

				//Admins anzeigen (nur temporär via Rollen-Namen!)
				var adminUsers = User.getActiveUsers("ADMIN");
				//sortieren nach Name (ignore case)
				adminUsers.sort(function (a, b) {
					var an = a.getName().toLowerCase();
					var bn = b.getName().toLowerCase();
					return ((an < bn) ? -1 : ((an > bn) ? 1 : 0));
				});
				var s = "";
				for (var i = 0; i < adminUsers.length; i++) {
					s += "<option value='" + adminUsers[i].id + "'>" + adminUsers[i].getName() + "</option>";
				}
				$("#selectEmailReportsRecipients").html(s).select2();

				//Wert setzen
				var a = [];
				if (this.curClient.emailReportsRecipients) {
					a = this.curClient.emailReportsRecipients.split("#");
				}
				$("#selectEmailReportsRecipients").val(a).trigger("change");

				//anzeigen!
				$(".emailReportsSetting").removeClass("hidden").show();
			}
			else{
				$(".emailReportsSetting").hide();
			}
		},

		clearEmailReportSettingsErrors: function(){
			//Fehler-Hinweise entfernen
			$("#formSettingsEmailReportsEdit .has-error").removeClass("has-error");
		},

		validateEmailReportSettings: function(){

			var that = this;
			var isValid = true;

			//Fehler-Hinweise entfernen
			this.clearEmailReportSettingsErrors();

			//check: Empfänger
			var rcpList = that.getEmailReportReceipients();
			if (!rcpList) {
				$("#emailReportsRecipients").addClass("has-error");
				viewManager.updateButtons();
				isValid = false;
			}

			return isValid;
		},

		getEmailReportReceipients: function(){
			return $("#selectEmailReportsRecipients").val();
		},

		saveEmailReportSettings: function(){

			var that = this;

			//nur, falls angeschaltet
			if (that.curClient.hasEmailReports === 1) {
				if (!that.validateEmailReportSettings())
					return;

				that.curClient.emailReportsPattern = $("#selectEmailReportsFrequency").val();
				that.curClient.emailReportsRecipients = that.getEmailReportReceipients() ? that.getEmailReportReceipients().join("#") : "";
			}
			else{

				//zurücksetzen
				that.curClient.emailReportsPattern = null;
				that.curClient.emailReportsRecipients = null;
			}



			//aktualisieren
			dataManager.setClientEmailReportSettings(that.curClient, function (c) {
				that.curClient = c;

				//aufräumen
				viewManager.updateButtons();
				that.clearEmailReportSettingsErrors();

				//Anzeigen
				that.showEmailReportsSettings();
				viewManager.clearFormDirty();
				viewManager.showNotification("Einstellungen erfolgreich aktualisiert.", constants.NOTIFICATION_SUCCESS);
			});

		}
		
	};
	
	window.settingsManager = settingsManager;
}());
