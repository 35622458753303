(function () {
	
	//----------------------------------------------------------------
	//Antrag
	//----------------------------------------------------------------
	
	/*global
	constants:true,
	Attachment:true,
	RequestState:true,
	Location:true,
	Task:true,
	User:true,
	LocationType:true,
	TaskType:true,
	TaskTypeField:true,
	HistoryItem:true,
	JsDiff:true*/
	
	'use strict';
	
	var Request = {
		
		requestPrototype: {
			
			prevVersion: null,
			nextVersion: null,
			isCurrentVersion: true,
			
			comments: [],
			
			//----------------------------------------------
			
			fromObj: function (t, includeInactive) {
				
				if (includeInactive === undefined)
					includeInactive = true;
				
				var mil;
				
				this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				this.clientId = parseInt(t.clientId, 10);
				this.description = pg.restoreDbString(t.description);
				this.ownerId = parseInt(t.ownerId, 10);
				this.requestId = parseInt(t.requestId, 10);
				this.taskTypeId = parseInt(t.taskType, 10);
				this.version = parseInt(t.version, 10);

				this.intervalType = t.intervalType;
				this.locations = t.locations || "";
				//ja, sowohl inactive + deleted!
				this.locationList = Location.buildLocationList(this.locations, includeInactive, includeInactive, true);
				this.updateLocationObjects();
				this.locationType = parseInt(t.locationType || -1);
				
				if (t.dueDate) {
					mil = pg.parseDate(t.dueDate);
					this.dueDate = new Date();
					this.dueDate.setTime(mil);
				}
				else
					this.dueDate = null;
				if (t.endDate) {
					mil = pg.parseDate(t.endDate);
					this.endDate = new Date();
					this.endDate.setTime(mil);
				}
				else
					this.endDate = null;
				
				this.isNew = (t.isNew) ? t.isNew : 0;
				
				this.attachments = (t.attachments) ? t.attachments : "";
				
				this.dynData = (t.dynData) ? t.dynData : "";
				this.updateDynData();
				
				this.states = [];
				var i;
				if (t.states)
					for(i = 0; i < t.states.length; i++) {
						var o = RequestState.createRequestState().fromObj(t.states[i]);
						this.states.push(o);
					}
				
				this.title = t.title;
				
				this.comments = [];
				if (t.comments)
					for(i = 0; i < t.comments.length; i++) {
						var c = Comment.createComment().fromObj(t.comments[i]);
						this.addComment(c);
					}

				this.history = [];
				if (t.history) {
					for (i = 0; i < t.history.length; i++) {
						var h = HistoryItem.createHistoryItem().fromObj(t.history[i]);
						this.history.push(h);
					}
				}

				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.clientId = this.clientId;
				o.isNew = this.isNew;
				o.description = this.description;
				o.ownerId = this.ownerId;
				o.requestId = this.requestId;
				o.taskType = this.taskTypeId;
				o.version = this.version;
				if (this.dueDate)
					o.dueDate = pg.buildDate(this.dueDate);
				else
					o.dueDate = null;
				o.attachments = this.attachments;
				o.title = this.title;
				o.intervalType = this.intervalType;
				o.locations = this.locations;
				o.endDate = pg.buildDate(this.endDate);
				o.locationType = this.locationType;
				
				o.states = [];
				for(var i = 0; i < this.states.length; i++)
					o.states.push(this.states[i].serialize());
				
				o.comments = [];
				for(i = 0; i < this.comments.length; i++)
					o.comments.push(this.comments[i].serialize());

				o.history = [];
				for(i = 0; i < this.history.length; i++)
					o.history.push(this.history[i].serialize());
				
				return o;
			},
			
			//---------------------------------------------------------------------
			
			//logging: Nur aktuelle Datensätze berücksichtigen
			isRecent: function(dateThreshold){
				if (this.changedOn > dateThreshold)
					return true;
				
				for(var i = 0; i < this.states.length; i++)
					if (this.states[i].changedOn > dateThreshold)
						return true;
				
				for(i = 0; i < this.comments.length; i++)
					if (this.comments[i].changedOn > dateThreshold)
						return true;
				
				return false;
			},

			//---------------------------------------------------------------------

			clearHistory: function(){
				this.history = [];
			},

			//---------------------------------------------------------------------
			
			isArchived: function(){
				var ts = this.getCurrentState();
				if (ts)
					return ts.groupId === constants.GROUP_ARCHIVE;
				return false;
			},
			
			//nur, wenn erledigt oder geschlossen
			canBeArchived: function(){

				var ts = this.getCurrentState();
				if (ts){
					
					//nur, wenn erledigt
					if (ts.status === RequestState.STATUS_COMPLETED){
						var task = Task.getTaskByRequest(this.requestId);
						if (task)
							return task.canBeArchived();
					}
					
					if (ts.status === RequestState.STATUS_CLOSED)
						return true;
				}
				
				return false;
			},

			//auch ggf. Task berücksichtigen, falls Antrag erledigt
			getTotalStatus: function(){

				var ts = this.getCurrentState();
				if (ts){

					//nur, wenn erledigt
					if (ts.status === RequestState.STATUS_COMPLETED){
						var task = Task.getTaskByRequest(this.requestId);
						if (task)
							return task.getStatus();
					}

					return ts.status;
				}

				return -1;
			},

			addRequestState: function(){
				
				var prevState = this.getCurrentState();
				
				var rs = RequestState.createRequestState().fromObj({
					id: -1,
					clientId: model.curClientId,
					status: prevState.status,
					requestId: prevState.requestId,
					version: prevState.version + 1,
					deciderId: prevState.deciderId,
					responsibleId: prevState.responsibleId,
					groupId: prevState.groupId,
					comment: ""
				});
				
				return rs;
			},
			
			//---------------------------------------------------------------------
			
			//inhaltliche Gleichheit prüfen
			isRequestEqual: function (o, ots) {
				return this.getDiff(o, ots).length === 0;
			},

			//Unterschiede zu anderer Version ermitteln (ggf. unter Angabe eines expliziten State "ots")
			//falls keine ots: Unterschiede in zugehörigen States werden nicht geprüft - nur das Hauptobjekt selbst
			getDiff: function(o, ots){

				var diff = [];

				//einfache Eigenschaften
				if (o.description !== this.description)
					diff.push({
						name: "Beschreibung",
						isText: true,
						oldVal: o.description,
						newVal: this.description
					});
				if (o.intervalType !== this.intervalType) {

					var iv1Name = null;
					if (this.intervalType) {
						var iv1 = Task.getIntervalValues(this.intervalType);
						iv1Name = Task.createIntervalName(iv1);
					}
					var iv2Name = null;
					if (o.intervalType) {
						var iv2 = Task.getIntervalValues(o.intervalType);
						iv2Name = Task.createIntervalName(iv2);
					}

					diff.push({
						name: "Intervalltyp",
						oldVal: iv2Name,
						newVal: iv1Name
					});
				}
				if (o.locationType !== this.locationType) {

					if ((o.locationType && o.locationType>0) || (this.locationType && this.locationType>0)) {

						var nameA = null;
						var nameB = null;

						if (o.locationType)
							nameA = LocationType.getLocationType(o.locationType).name;
						if (this.locationType)
							nameB = LocationType.getLocationType(this.locationType).name;

						diff.push({
							name: "Objekttyp",
							oldVal: nameA,
							newVal: nameB
						});
					}
				}
				if (o.title !== this.title)
					diff.push({
						name: "Titel",
						oldVal: o.title,
						newVal: this.title
					});
				if (o.taskTypeId !== this.taskTypeId)
					diff.push({
						name: "Auftragstyp",
						oldVal: TaskType.getTaskType(o.taskTypeId).name,
						newVal: TaskType.getTaskType(this.taskTypeId).name
					});

				var d;
				if (this.dueDate) {
					d = o.dueDate;
					if (d)
						if (!$.isNumeric(d))
							d = d.getTime();
					if (d !== this.dueDate.getTime())
						diff.push({
							name: "Fälligkeitsdatum",
							oldVal: d ? pg.formatDate(pg.parseDate(d)) : null,
							newVal: this.dueDate ? pg.formatDate(this.dueDate) : null
						});
				}
				else if (o.dueDate !== null) {
					d = o.dueDate;
					if (d)
						if (!$.isNumeric(d))
							d = d.getTime();
					diff.push({
						name: "Fälligkeitsdatum",
						oldVal: o.dueDate ? pg.formatDate(pg.parseDate(d)) : null,
						newVal: this.dueDate ? pg.formatDate(this.dueDate) : null
					});
				}

				if (this.endDate) {

					d = o.endDate;
					if (d)
						if (!$.isNumeric(d))
							d = d.getTime();
					if (d !== this.endDate.getTime())
						diff.push({
							name: "Enddatum",
							oldVal: o.endDate ? pg.formatDate(pg.parseDate(d)) : null,
							newVal: this.endDate ? pg.formatDate(this.endDate) : null
						});
				}
				else if (o.endDate !== null) {
					d = o.endDate;
					if (!$.isNumeric(d))
						d = d.getTime();
					diff.push({
						name: "Enddatum",
						oldVal: o.endDate ? pg.formatDate(pg.parseDate(d)) : null,
						newVal: this.endDate ? pg.formatDate(this.endDate) : null
					});
				}

				if (o.requestId !== this.requestId)
					diff.push({
						name: "ID",
						oldVal: "#" + o.requestId,
						newVal: "#" + this.requestId
					});

				if (parseInt(o.ownerId) !== this.ownerId)
					diff.push({
						name: "Antragsteller",
						oldVal: User.getUserName(o.ownerId),
						newVal: User.getUserName(this.ownerId)
					});

				if (ots) {

					/*if (!ots) {
						//den zeitlich zugehörigen Zustand holen
						ots = o.getState();
					}*/
					var ts = this.getState();

					if (parseInt(ots.responsibleId) !== ts.responsibleId)
						diff.push({
							name: "Verantwortlich",
							oldVal: User.getUserName(ots.responsibleId),
							newVal: User.getUserName(ts.responsibleId)
						});
					if (parseInt(ots.deciderId) !== ts.deciderId)
						diff.push({
							name: "Entscheider",
							oldVal: User.getUserName(ots.deciderId),
							newVal: User.getUserName(ts.deciderId)
						});
				}

				//---------------------------------
				//Objekte/Arrays

				var a, b, onlyInA, onlyInB, namesA, namesB;

				//Locations
				if (o.locations !== this.locations) {
					a = o.locations.split("#").map(Number);
					b = this.locations.split("#").map(Number);

					onlyInA = _.filter(a, function (o2) {
						return b.indexOf(o2) === -1;
					});
					onlyInB = _.filter(b, function (o2) {
						return a.indexOf(o2) === -1;
					});

					if ((onlyInA.length > 0) || (onlyInB.length > 0)) {

						namesA = onlyInA.map(function (id) {
							return Location.getLocation(id).name;
						});
						namesB = onlyInB.map(function (id) {
							return Location.getLocation(id).name;
						});

						diff.push({
							name: "Objekte",
							isArray: true,
							oldVal: namesA,
							newVal: namesB
						});
					}
				}

				//anhänge
				var i;
				if (o.attachments !== this.attachments) {

					o.updateAttachments();
					this.updateAttachments();

					a = o.attObj;
					b = this.attObj;

					onlyInA = _.filter(a, function (o2) {
						for (i=0; i<b.length; i++){
							if (b[i].id === o2.id)
								return false;
						}
						return true;
					});
					onlyInB = _.filter(b, function (o2) {
						for (i=0; i<a.length; i++){
							if (a[i].id === o2.id)
								return false;
						}
						return true;
					});

					if ((onlyInA.length > 0) || (onlyInB.length > 0)) {

						namesA = onlyInA.map(function (o2) {
							return o2.name;
						});
						namesB = onlyInB.map(function (o2) {
							return o2.name;
						});

						diff.push({
							name: "Anhänge",
							isArray: true,
							oldVal: namesA,
							newVal: namesB
						});
					}
				}

				//Dynamische Felder
				if ((o.dynDataObj.length > 0) || (this.dynDataObj.length > 0)){

					var ttFields = TaskTypeField.getTaskTypeFields(this.taskTypeId);
					for(i = 0; i < ttFields.length; i++) {

						var ttf = ttFields[i];

						var dd1 = o.getDynDataObj(ttf.id);
						var ddValue1 = (dd1) ? ttf.getFormattedValue(dd1.value) : "";

						var dd2 = this.getDynDataObj(ttf.id);
						var ddValue2 = (dd2) ? ttf.getFormattedValue(dd2.value) : "";

						if (ddValue1 !== ddValue2){
							diff.push({
								name: ttf.name,
								oldVal: ddValue1,
								newVal: ddValue2
							});
						}
					}
				}

				return diff;
			},
			
			//------------------------------------------------------------
			
			canBeSeenByUser: function(ownerRequests, deciderRequests, responsibleRequests, groupId, reqState, taskTypeId){
				
				var userId = model.curUserId;
				var rs = reqState || this.getCurrentState();
				groupId = groupId || -1;
				taskTypeId = taskTypeId || -1;
				
				if (!rs)
					return false;
				
				if (groupId >= 0)
					if (rs.groupId !== groupId)
						return false;

				if (taskTypeId > 0)
					if (model.curUser.hasTaskType(taskTypeId))
						return true;
				
				var canBeSeen = true;
				//Bringschuld vorhanden?
				if (ownerRequests || deciderRequests || responsibleRequests) {
					canBeSeen = false;
					if (ownerRequests)
						if (this.ownerId === userId)
							canBeSeen = true;
					if (deciderRequests)
						if (rs.deciderId === userId)
							canBeSeen = true;
					if (responsibleRequests) {
						//passt der Status zur Anfrage? Muss irgendwann freigegeben (gewesen) sein
						if (this.isOrWasReleased()) {
							if (rs.responsibleId === userId)
								canBeSeen = true;
						}
					}
				}
				return canBeSeen;
			},
			
			//------------------------------------------------------------
			//dynamische Felder
			
			//deserialisieren
			updateDynData: function () {
				
				this.dynDataObj = [];
				var a = this.dynData.split(";;;");
				for(var i = 0; i < a.length; i++) {
					var raw = a[i].split("$$$");
					this.dynDataObj.push({
						id: parseInt(raw[0]),
						value: raw[1]
					});
				}
			},
			getDynDataObj: function (fieldId) {
				for(var i = 0; i < this.dynDataObj.length; i++) {
					if (this.dynDataObj[i].id === fieldId)
						return this.dynDataObj[i];
				}
				return null;
			},
			clearDynData: function () {
				this.dynData = "";
				this.dynDataObj = [];
			},
			addDynData: function (field, value) {
				var s = field.id + "$$$" + value;
				this.dynData += s + ";;;";
				this.updateDynData();
			},
			
			//---------------------------------------------------------------------
			
			addComment: function (o) {
				
				if (this.comments === undefined)
					this.comments = [];
				
				//shallow copy!
				var a = [];
				for (var i = 0; i < this.comments.length; i++) {
					
					//bekannt?
					if (o.id === this.comments[i].id)
						return;
					
					a.push($.extend({}, this.comments[i]));
				}
				a.push(o);
				this.comments = a;
			},
			
			//------------------------------------------------------------
			
			//zugeordnete Locations
			getLocationIds: function () {
				var a = pg.splitNonEmpty(this.locations, "#");
				for(var i = 0; i < a.length; i++)
					a[i] = parseInt(a[i]);
				return a;
			},
			
			//----------------------------------------------
			
			//immer die aktuellsten Location-Infos heranziehen
			updateLocationObjects: function () {
				this.locationObjects = [];
				
				for(var i = 0; i < this.locationList.length; i++) {
					var l = Location.getLocation(this.locationList[i], undefined, true, true, false);
					if (l)
						this.locationObjects.push(l);
				}
			},
			
			//----------------------------------------------
			
			//Sortierung anwenden
			updateSortValue: function (sortField, sortDirection) {
				
				if (sortDirection === constants.SORTING_NONE) {
					this.sortVal = 0;
					return;
				}
				
				var u;
				var rs = this.getCurrentState();
				var task;

				this.sortVal = "";
				switch (sortField) {
					case "requestId":
						this.sortVal = this.requestId;
						break;
					case "status":
						this.sortVal = this.getTotalStatus();
						break;
					case "taskType":
						this.sortVal = this.taskTypeId;
						break;
					case "owner":
						this.sortVal = User.getUserName(this.ownerId);
						break;
					case "decider":
						this.sortVal = User.getUserName(rs.deciderId);
						break;
					case "taskId":
						if (rs.status === RequestState.STATUS_COMPLETED) {
							//neuesten Task holen
							task = Task.getTaskByRequest(this.requestId);
							if (task) {
								this.sortVal = task.taskId;
							}
						}
						break;
					case "responsible":
						if (rs.status === RequestState.STATUS_COMPLETED) {
							//neuesten Task holen
							task = Task.getTaskByRequest(this.requestId);
							if (task) {
								u = User.getUser(task.getCurrentState().responsibleId);
								this.sortVal = u.getName();
							}
						}
						break;
				}
			},
			
			//---------------------------------------------------
			//Anhänge
			
			updateAttachments: function () {
				this.attObj = Attachment.getAttachments(constants.ENTITY_TYPE_REQUEST, this.requestId);
				if (this.attObj.length === 0 && this.attachments){
					//legacy
					this.attObj = Attachment.deserializeAttachments(constants.ENTITY_TYPE_REQUEST, this.requestId, this.attachments);
				}
				return this.attObj;
			},
			getAttachments: function () {
				if (!this.attObj)
					this.updateAttachments();
				return this.attObj;
			},
			getAttachment: function (attId) {
				for(var i = 0; i < this.attObj.length; i++)
					if (this.attObj[i].id === attId)
						return this.attObj[i];
				return null;
			},
			getAttachmentByIndex: function (index) {
				if ((index >= 0) && (index <= this.attObj.length - 1))
					return this.attObj[index];
				return null;
			},
			/*getDeletedAttachments: function(oldReqVersion){

				var a = oldReqVersion.updateAttachments();
				var b = this.updateAttachments() || [];

				return _.filter(a, function (o) {
					for (var i=0; i<b.length; i++){
						if (b[i].id === o.id)
							return false;
					}
					return true;
				});
			},*/
			
			//----------------------------------------------------
			
			//Filter anwenden
			matchesFilter: function (filterObj) {
				
				/*jshint -W089 */
				for(var field in filterObj) {
					
					var val = filterObj[field],
						isValid,
						i,
						task,
						loc,
						ts,
						valInt;
					
					var rs = this.getCurrentState();

					if ((val === undefined) || (val === null))
						continue;

					switch (field) {
						case "requestId":
							var rId = "" + this.requestId;
							if (rId.indexOf(val) < 0)
								return false;
							break;
						case "title":
							if (this.title)
								if (this.title.toLowerCase().indexOf(val.toLowerCase()) < 0)
									return false;
							break;
						case "status":
							//multiple!
							if ($.isArray(val)) {
								isValid = false;
								var totalStatus = this.getTotalStatus();
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									switch (valInt) {
										case -1:
											//alle
											isValid = true;
											break;
										default:
											//Sonderfälle
											switch (valInt){

												//erledigt: kann infolge gelöschter Aufträge mehrere Stati abdecken
												case RequestState.STATUS_COMPLETED:
													if (totalStatus === RequestState.STATUS_COMPLETED)
														return true;
													if (totalStatus === constants.STATUS_COMPLETED)
														return true;
													break;

												//neu + wiedereröffnet
												case RequestState.STATUS_NEW:
													if (totalStatus === RequestState.STATUS_NEW)
														return true;
													if (totalStatus === RequestState.STATUS_REOPENED)
														return true;
													break;

												default:
													if (totalStatus === valInt)
														isValid = true;
													break;
											}
											break;
									}
								}

								if (!isValid)
									return false;
							}
							break;
						case "taskTypeId":
							valInt = parseInt(val);
							if (valInt < 0)
								continue;
							if (this.taskTypeId !== valInt)
								return false;
							break;
						case "owner":
							//multiple!
							if ($.isArray(val)) {
								isValid = false;
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									if (valInt < 0) {
										isValid = true;
										break;
									}
									if (this.ownerId === valInt) {
										isValid = true;
										break;
									}
								}

								if (!isValid)
									return false;
							}
							break;
						case "decider":
							//multiple!
							if ($.isArray(val)) {
								isValid = false;
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									if (valInt < 0) {
										isValid = true;
										break;
									}
									if (rs.deciderId === valInt) {
										isValid = true;
										break;
									}
								}

								if (!isValid)
									return false;
							}
							break;

						case "taskId":
							if (rs.status === RequestState.STATUS_COMPLETED) {
								//neuesten Task holen
								task = Task.getTaskByRequest(this.requestId);
								if (task) {
									var tId = "" + task.taskId;
									if (tId.indexOf(val) < 0)
										return false;
								}
								else{
									return false;
								}
							}
							else
								return false;
							break;
						/*case "taskStatus":
							if (rs.status === RequestState.STATUS_COMPLETED) {

								//neuesten Task holen
								task = Task.getTaskByRequest(this.requestId);
								if (task) {
									if ($.isArray(val)) {
										isValid = false;
										for(i = 0; i < val.length; i++) {
											valInt = parseInt(val[i]);
											switch (valInt) {
												case -1:
													//alle
													isValid = true;
													break;
												default:
													//aktiv
													if (task.getStatus() === valInt)
														isValid = true;
													break;
											}
										}

										if (!isValid)
											return false;
									}
								}
								else{
									return false;
								}
							}
							else {
								if (valInt !== -1) {
									if ($.isArray(val)) {
										isValid = false;
										for(i = 0; i < val.length; i++) {
											valInt = parseInt(val[i]);
											switch (valInt) {
												case -1:
													//alle
													isValid = true;
													break;
											}
										}

										if (!isValid)
											return false;
									}
								}
							}
							break;*/
						case "responsible":
							if (rs.status === RequestState.STATUS_COMPLETED) {
								//neuesten Task holen
								task = Task.getTaskByRequest(this.requestId);
								if (task) {
									ts = task.getCurrentState();

									//multiple!
									if ($.isArray(val)) {
										isValid = false;
										for(i = 0; i < val.length; i++) {
											valInt = parseInt(val[i]);
											if (valInt < 0) {
												isValid = true;
												break;
											}
											if (ts.responsibleId === valInt) {
												isValid = true;
												break;
											}
										}

										if (!isValid)
											return false;
									}
								}
								else{
									return false;
								}
							}
							else {
								//multiple!
								if ($.isArray(val)) {
									isValid = false;
									for(i = 0; i < val.length; i++) {
										valInt = parseInt(val[i]);
										if (valInt < 0) {
											isValid = true;
											break;
										}
										if (rs.responsibleId === valInt) {
											isValid = true;
											break;
										}
									}

									if (!isValid)
										return false;
								}
							}
							break;

						case "locationType":
							//multiple!
							if ($.isArray(val)) {
								isValid = false;
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									switch (valInt) {
										case -1:
											//alle
											isValid = true;
											break;
										default:
											loc = Location.getLocation(this.locationList[0], undefined, false, false);
											if ((loc) && (loc.type === valInt))
												isValid = true;
											break;
									}
									if (isValid)
										break;
								}

								if (!isValid)
									return false;
							}
							else {
								valInt = parseInt(val);
								if (valInt < 0)
									continue;
								loc = Location.getLocation(this.locationList[0], undefined, false, false);
								if (loc) {
									if (loc.type !== valInt)
										return false;
								} else {
									return false;
								}
							}
							break;
						case "locationId":
							if ($.isArray(val)) {
								isValid = false;

								//id, nicht name!
								for(i = 0; i < val.length; i++) {
									valInt = parseInt(val[i]);
									if (valInt < 0) {
										isValid = true;
										break;
									}

									if (this.locationList) {
										for (var j=0; j<this.locationList.length; j++) {
											if (this.locationList[j] === valInt){
												isValid = true;
												break;
											}
										}
									}
									if (isValid)
										break;
								}

								if (!isValid)
									return false;
							}
							break;
					}
				}
				
				return true;
			},
			
			//----------------------------------------------
			//Intervall-Infos
			
			//Intervall-Aufträge haben von...bis-Zeiträume
			getIntervalDates: function () {
				
				var iv = Task.getIntervalValues(this.intervalType);
				
				return {
					from: Task.getIntervalDate(this.dueDate, iv.intervalType, iv.factor, iv.weekdays, false),
					to: this.dueDate
				};
			},
			
			getDueDate: function () {
				return this.dueDate;
			},
			
			isInterval: function () {
				if (!this.intervalType)
					return false;
				return (this.intervalType.length > 0);
			},
			
			getIntervalName: function () {
				
				if ((this.intervalType === null) || (this.intervalType === ""))
					return "Ohne";
				
				//custom?
				var intervalValues = Task.getIntervalValues(this.intervalType);
				return Task.createIntervalName(intervalValues);
			},
			
			//-----------------------------------------------------------------
			
			//aktuellen Zustand holen
			getCurrentState: function () {
				if (this.states.length > 0)
					return this.states[this.states.length - 1];
				return null;
			},

			//zu diesem Antrag zeitlich passenden State holen
			getState: function(){
				var tsThresholdEpoch = this.createdOn;
				for (var i=0; i<this.states.length; i++){
					var ts = this.states[i];
					if (ts.createdOn.getTime() >= tsThresholdEpoch)
						return ts;
				}
				return this.getCurrentState();
			},
			
			//war schon mal freigegeben?
			isOrWasReleased: function () {
				for(var i = 0; i < this.states.length; i++) {
					if (this.states[i].status === RequestState.STATUS_APPROVED)
						return true;
				}
				return false;
			},
			
			//----------------------------------------
			
			//Request aktualisieren
			update: function () {
				
				this.isCurrentVersion = true;
				
				//locations
				this.updateLocationObjects();
				
				//Versionierung
				if (this.version > 1) {
					//direkten Vorgänger suchen
					for (var k=this.version-1; k>0; k--) {
						var tPrev = Request.getRequest(this.requestId, k, true);
						if (tPrev) {
							this.prevVersion = tPrev;
							tPrev.nextVersion = this;
							tPrev.isCurrentVersion = false;
							break;
						}
					}
				}
				
				this.updateComments();
			},
			
			//---------------------------------------------------------------
			
			//Kommentare
			updateComments: function(){
				if (this.prevVersion){
					for (var i=0; i<this.prevVersion.comments.length; i++)
						this.addComment(this.prevVersion.comments[i]);
				}
			},

			getOriginalRequest: function(){
				var requestOriginal = this;
				while (requestOriginal.prevVersion)
					requestOriginal = requestOriginal.prevVersion;
				return requestOriginal;
			},

			//----------------------------------------------
			//HTML-Darstellung des aktuellen Status holen

			getStatusLabel: function(){

				var totalStatus = this.getTotalStatus();
				var ts = this.getCurrentState();

				switch (ts.status) {
					case RequestState.STATUS_NEW:
						return '<span class="badge badge-status statusColorNew">Offen</span>';
					case RequestState.STATUS_REOPENED:

						if (ts.version > 1) {

							//aktuellen Zustand suchen
							var index = -1;
							var h;
							for (var i=this.history.length-1; i>=0; i--){
								h = this.history[i];
								if ((h.type === constants.HISTORY_ROUTED) && (h.int1 === ts.status) && (h.timestamp.getTime() === ts.createdOn.getTime())){
									index = i;
									break;
								}
							}

							//Suche vorherigen Zustand
							var prevStateWasInquiry = false;
							if (index >= 0) {
								for (i = index - 1; i >= 0; i--) {
									h = this.history[i];
									if (h.type === constants.HISTORY_ROUTED) {
										prevStateWasInquiry = (h.int1 === RequestState.STATUS_INQUIRY);
										break;
									}
								}
							}
							if (prevStateWasInquiry)
								return '<span class="badge badge-status statusColorNew">Rückfrage beantwortet</span>';
						}
						return '<span class="badge badge-status statusColorNew">Offen</span>';
					case RequestState.STATUS_APPROVED:
						return '<span class="badge badge-status statusColorProgress">Freigegeben</span>';
					case RequestState.STATUS_REJECTED:
						return '<span class="badge badge-status statusColorDenied">Abgelehnt</span>';
					case RequestState.STATUS_INQUIRY:
						return '<span class="badge badge-status statusColorFeedback">R&uuml;ckfrage</span>';
					case RequestState.STATUS_CLOSED:
						return '<span class="badge badge-status statusColorInactive">Geschlossen</span>';
					case RequestState.STATUS_COMPLETED:

						//Auftrag begutachten
						var task = Task.getTaskByRequest(this.requestId);
						if (task) {

							if (task.getMasterStatus() === constants.TASK_STATUS_ACTIVE) {

								switch (task.getStatus()) {
									case constants.STATUS_NEW:
										return '<span class="badge badge-status statusColorNew">Auftrag offen</span>';
									case constants.STATUS_COMPLETED:
										return '<span class="badge badge-status statusColorCompleted">Erledigt</span>';
									case constants.STATUS_PROGRESS:
										return '<span class="badge badge-status statusColorProgress">Auftrag in Bearb.</span>';
									default:
										//Label
										var tl = task.getTaskLabel();
										return '<span class="badge badge-status" style="background-color: ' + tl.colorBg + '; color: ' + tl.colorFg + ';">Auftrag ' + tl.name + '</span>';
								}
							}
						}

						//fallback
						return '<span class="badge badge-status statusColorCompleted">Erledigt</span>';

				}

			},

			//----------------------------------------------
			
			//Verlauf
			getHistory: function () {
				return this.history;
			}
		},
		
		//Helper
		getRequestHistoryName: function (h, requestHistory, index) {
			
			var s = "";
			var skipComment = false;

			switch (h.type) {
				case constants.HISTORY_CREATED:
					s = "Der Antrag wurde erstellt.";
					break;
				case constants.HISTORY_CHANGED:
					s = "Der Antrag wurde ge&auml;ndert:<br/>";
					s += "<ul>";

					h.getChanges().forEach(function(o){

						if (o.isArray){
							s += "<li>" + o.name + ": ";
							var items = [];
							items = items.concat(o.oldVal.map(function(u){
								return "<i class=\"fa fa-minus-circle\" aria-hidden=\"true\"></i>" + u;
							}));
							items = items.concat(o.newVal.map(function(u){
								return "<i class=\"fa fa-plus-circle\" aria-hidden=\"true\"></i>" + u;
							}));
							s += items.join(", ");
							s += "</li>";
						}
						else {

							if (o.isText && o.oldVal && o.newVal){

								//noinspection JSUnresolvedFunction
								var diff = JsDiff.diffChars(o.oldVal, o.newVal);

								s += "<li>" + o.name + ": ";
								diff.forEach(function(part){
									var color = part.added ? 'fgGreen' :
										part.removed ? 'fgRed' : 'grey';
									s += "<span class='" + color + "'>" + pg.nl2br(part.value) + "</span>";
								});
								s += "</li>";

							}
							else {

								if (!o.oldVal) {
									s += "<li>" + o.name + ": <i class=\"fa fa-plus-circle\" aria-hidden=\"true\"></i> " + pg.nl2br(o.newVal) + "</li>";
								}
								else if (!o.newVal) {
									s += "<li>" + o.name + ": <i class=\"fa fa-minus-circle\" aria-hidden=\"true\"></i> " + pg.nl2br(o.oldVal) + "</li>";
								}
								else {
									s += "<li>" + o.name + ": " + o.oldVal + " → " + o.newVal + "</li>";
								}
							}
						}
					});
					s += "</ul>";
					break;
				case constants.HISTORY_ROUTED:
					switch (h.int1) {
						case RequestState.STATUS_NEW:
							s = "Der Antrag wurde zur Entscheidung weitergeleitet.";
							break;
						case RequestState.STATUS_REOPENED:

							//Suche vorherigen Zustand
							var prevStateWasInquiry = false;
							for (var i=index-1; i>=0; i--){
								var h2 = requestHistory[i];
								if (h2.type === constants.HISTORY_ROUTED){
									prevStateWasInquiry = (h2.int1 === RequestState.STATUS_INQUIRY);
									break;
								}
							}
							if (prevStateWasInquiry)
								s = "Die Rückfrage wurde beantwortet und der Antrag zur Entscheidung weitergeleitet.";
							else
								s = "Der Antrag wurde zur Entscheidung weitergeleitet.";
							break;

						case RequestState.STATUS_APPROVED:
							s = "Der Antrag wurde von <b>" + User.getUserName(h.actorId) + "</b> freigegeben.";
							break;
						case RequestState.STATUS_REJECTED:
							s = "Der Antrag wurde von <b>" + User.getUserName(h.actorId) + "</b> abgelehnt:";
							break;
						case RequestState.STATUS_INQUIRY:
							s = "Der Antrag wurde aufgrund einer R&uuml;ckfrage von <b>" + User.getUserName(h.actorId) + "</b> an den Antragsteller zur&uuml;ckgesendet.";
							break;
						case RequestState.STATUS_CLOSED:
							s = "Der Antrag wurde von <b>" + User.getUserName(h.actorId) + "</b> geschlossen:";
							break;
						case RequestState.STATUS_COMPLETED:
							s = "Der Antrag wurde von <b>" + User.getUserName(h.actorId) + "</b> in einen Auftrag &uuml;berf&uuml;hrt.";
							break;
						//Hier noch ein Eintrag, falls der zugehörige Auftrag erledigt wurde
						case constants.STATUS_COMPLETED:
							s = "Der zugehörige Auftrag #" + h.int2 + " wurde von <b>" + User.getUserName(h.int3) + "</b> erledigt.";
							break;
					}

					if (!skipComment)
						if (h.string1)
							s += "<br/><i>" + pg.nl2br(h.string1) + "</i>";

					break;
				case constants.HISTORY_RESPONSIBILITY:
					s = "Die Verantwortung f&uuml;r den Auftrag wurde <b>" + User.getUserName(h.int1) + "</b> &uuml;bertragen.";
					break;
				case constants.HISTORY_GROUPING:
					switch (h.int1){
						case constants.GROUP_ARCHIVE:
							s = "Der Antrag wurde archiviert.";
							break;
						default:
							s = "Der Antrag wurde wiederhergestellt.";

							if (h.string1)
								s += "<br/><i>" + pg.nl2br(h.string1) + "</i>";

							break;
					}
					break;
				case constants.HISTORY_DECIDER:
					if (h.actorId < 0)
						s = "Der Antrag wurde automatisch freigegeben.";
					else
						s = "Die Entscheidung f&uuml;r den Antrag wurde <b>" + User.getUserName(h.int1) + "</b> &uuml;bertragen.";
					break;
				case constants.HISTORY_COMMENT:
					s = "<i>" + pg.nl2br(h.string1) + "</i>";
					skipComment = true;
					break;
			}
			
			/*if (!skipComment)
				if (h.comment)
					s += "<br/><i>" + h.comment + "</i>";*/
			
			
			return s;
		},
		
		//----------------------------------------------------------------------------------
		
		//bestimmten Request holen
		getRequest: function (requestId, version) {
			
			if (!version)
				version = undefined;
			
			requestId = parseInt(requestId);
			for(var i = 0; i < model.requests.length; i++) {
				
				var t = model.requests[i];
				
				if (t.requestId === requestId) {
					
					//version?
					if (version !== undefined) {
						if (t.version !== parseInt(version, 10))
							continue;
					}
					else if (!t.isCurrentVersion)
						continue;
					
					return t;
				}
			}
			return null;
		},
		
		//----------------------------------------------
		//Requests gemäß Kriterien holen
		
		//aktueller User
		getRequests: function (args) {
			
			if (args === undefined)
				args = {};
			
			args.userId = model.curUserId;
			return Request.getRequestsByUser(args);
		},
		//ein bestimmer User
		getRequestsByUser: function (args) {
			
			if (args === undefined)
				args = {};
			
			var userId = args.userId ? args.userId : -1,
				ownerRequests = (args.ownerRequests !== undefined) ? args.ownerRequests : false,
				deciderRequests = (args.deciderRequests !== undefined) ? args.deciderRequests : false,
				responsibleRequests = (args.responsibleRequests !== undefined) ? args.responsibleRequests : false,
				allRequests = (args.allRequests !== undefined) ? args.allRequests : false,
				groupId = (args.groupId !== undefined) ? args.groupId : -1,
				matchTaskTypes = (args.matchTaskTypes !== undefined) ? args.matchTaskTypes : false;
			
			if (!(ownerRequests || deciderRequests || responsibleRequests || matchTaskTypes))
				return [];
			
			var a = [];
			//alle Requests durchlaufen
			for(var i = 0; i < model.requests.length; i++) {
				var r = model.requests[i];
				
				//nur aktuelle
				if (!r.isCurrentVersion)
					continue;
				
				var rs = r.getCurrentState();
				
				if (groupId >= 0)
					if (rs.groupId !== groupId)
						continue;

				if (!allRequests) {
					var hasMatch = false;
					if (ownerRequests) {
						if (r.ownerId === userId)
							hasMatch = true;
					}
					if (deciderRequests)
						if (rs.deciderId === userId)
							hasMatch = true;
					if (responsibleRequests)
						if (rs.responsibleId === userId) {
							//passt der Status zur Anfrage? Muss irgendwann freigegeben (gewesen) sein
							if (r.isOrWasReleased())
								hasMatch = true;
						}

					//TaskType und Objekttyp müssen passen
					if (matchTaskTypes) {
						if (!model.curUser.hasTaskType(r.taskTypeId))
							hasMatch = false;
					}

					//in jedem Fall muss der Objekttyp passen
					if (r.locationType && (r.locationType > 0))
						if (!model.curUser.hasLocationType(r.locationType))
							hasMatch = false;


					if (!hasMatch)
						continue;
				}
				
				a.push(r);
			}
			
			return a;
		},
		
		//Sonderfall
		getResponsibleRequestsByUser: function (userId) {
			
			var a = [];
			//alle Requests durchlaufen
			for(var i = 0; i < model.requests.length; i++) {
				var r = model.requests[i];
				
				//nur aktuelle
				if (!r.isCurrentVersion)
					continue;
				
				var rs = r.getCurrentState();
				
				var hasMatch = false;
				if (rs.responsibleId === userId) {
					//passt der Status zur Anfrage?
					switch (rs.status) {
						case RequestState.STATUS_NEW:
						case RequestState.STATUS_REOPENED:
						case RequestState.STATUS_APPROVED:
						case RequestState.STATUS_REJECTED:
						case RequestState.STATUS_INQUIRY:
							hasMatch = true;
							break;
					}
				}
				if (!hasMatch)
					continue;
				
				a.push(r);
			}
			
			return a;
		},

		//----------------------------------------------------

		//verschiedene Datensichten harmonisieren (z.B. Cache vs Live)
		mergeRequests: function (oOld, oNew) {

			var i,
				oldLength;
			if (oOld.states) {
				if (!oNew.states)
					oNew.states = [];
				oldLength = oOld.states.length;
				for(i = 0; i < oldLength; i++)
					pg.replaceOrPushObj(oNew.states, oOld.states[i]);
			}

			if (oOld.history) {
				if (!oNew.history)
					oNew.history = [];
				//alte ins neue übernehmen
				oldLength = oOld.history.length;
				for(i = 0; i < oldLength; i++)
					pg.replaceOrPushObj(oNew.history, oOld.history[i]);
			}
			if (oOld.comments) {
				if (!oNew.comments)
					oNew.comments = [];
				//alte ins neue übernehmen
				oldLength = oOld.comments.length;
				for(i = 0; i < oldLength; i++)
					pg.replaceOrPushObj(oNew.comments, oOld.comments[i]);
			}

			return oNew;
		},

		//----------------------------------------------
		
		//u.a. Versionierung aktualisieren
		updateRequests: function () {
			
			for(var i = 0; i < model.requests.length; i++)
				model.requests[i].update();
			
		},
		
		//-------------------------------------------------
		
		createRequest: function () {
			return Object.create(Request.requestPrototype);
		}
		
	};
	
	window.Request = Request;
}());
