(function () {
	
	//----------------------------------------------------------------
	//Feld in Prüfungskonfiguration
	//----------------------------------------------------------------
	
    'use strict';

    var LocationFormField = {

        locationFormFieldPrototype: {

            clientId: -1,
            id: -1,
            locationId: -1,
            formId: -1,
            formFieldId: -1,

			createdOn: null,
			createdBy: -1,
			changedOn: null,
			changedBy: -1,

            fromObj: function (t) {
                this.id = parseInt(t.id, 10);
                if (t.createdOn){
					this.createdOn = pg.parseDate(t.createdOn);
					this.createdBy = parseInt(t.createdBy, 10);
					this.changedOn = pg.parseDate(t.changedOn);
					this.changedBy = parseInt(t.changedBy, 10);
				}
				else{
					this.createdOn = new Date();
					this.createdBy = -1;
					this.changedOn = new Date();
					this.changedBy = -1;
				}

				if (t.clientId)
					this.clientId = parseInt(t.clientId, 10);
				else
					this.clientId = model.curClientId;
                this.locationId = parseInt(t.locationId, 10);
                this.formId = parseInt(t.formId, 10);
                this.formFieldId = parseInt(t.formFieldId, 10);

	            return this;
            },

            serialize: function () {

                var o = {};

                o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.clientId = this.clientId;
                o.locationId = this.locationId;
                o.formId = this.formId;
                o.formFieldId = this.formFieldId;

                return o;
            },
	        
	        isEqualTo: function(o){
            	
            	if (this.clientId !== o.clientId)
            		return false;
		        if (this.id !== o.id)
			        return false;
		        if (this.locationId !== o.locationId)
			        return false;
		        if (this.formId !== o.formId)
			        return false;
		        if (this.formFieldId !== o.formFieldId)
			        return false;
		        /*if (this.createdOn !== o.createdOn)
			        return false;
				if (this.createdBy !== o.createdBy)
					return false;*/
				if (this.changedOn !== o.changedOn)
					return false;
				if (this.changedBy !== o.changedBy)
					return false;
            	
            	return true;
            	
	        }
        },
	
        createLocationFormField: function () {
            return Object.create(LocationFormField.locationFormFieldPrototype);
        }
    };

    window.LocationFormField = LocationFormField;
}());

