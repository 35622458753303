(function () {
	
	//-----------------------------------------
	//Liste der Anträge verwalten
	//-----------------------------------------
	
	/*global
	 constants:true,
	 viewManager:true,
	 Request:true,
	 RequestState:true,
	 authManager:true,
	 UserRole:true,
	 Task:true,
	 TaskType:true,
	 Location:true,
	 LocationType: true,
	 User:true,
	 dataManager:true
	 */
	
	'use strict';
	
	var requestListManager = {
		
		//Aktualisierungs-Management: hat sich an der aktuellen Sicht etwas geändert?
		checkModelChanged: function (data, oldModel) {
			
			var that = requestListManager;
			
			//aktueller Request verändert?
			var i,
				j,
				k,
				r,
				rs,
				rNew,
				tsOld,
				tsNew,
				tt,
				hasMatch;
			
			//welche Daten prüfen?
			var owned = false;
			var deciding = false;
			var responsible = false;
			var matchTT = false;
			if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_ALL)) {
				if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_OWN))
					owned = true;
				if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_ASSIGNED_DECIDER))
					deciding = true;
				if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_RELEASED))
					responsible = true;
			}
			else{
				if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_BY_TASK_TYPE)){
					matchTT = true;
				}
			}
			
			if (data.requests) {
				for(i = 0; i < data.requests.length; i++) {
					
					//würde dieses Objekt überhaupt gesehen?
					rNew = Request.createRequest().fromObj(data.requests[i]);
					tt = -1;
					if (matchTT)
						tt = rNew.taskType;
					//State ergänzen
					if (rNew.isNew === 1){

						//noinspection JSUnresolvedVariable
						if (data.requestStates) {
							//noinspection JSUnresolvedVariable
							for (j = 0; j < data.requestStates.length; j++) {
								//noinspection JSUnresolvedVariable
								if (parseInt(data.requestStates[j].requestId) === rNew.requestId) {
									//noinspection JSUnresolvedVariable
									rs = RequestState.createRequestState().fromObj(data.requestStates[j]);
									pg.replaceOrPushObj(rNew.states, rs);
								}
							}
						}
					}
					if (!rNew.canBeSeenByUser(owned, deciding, responsible, that.curGroupId, null, tt))
						continue;
					//passen die aktuellen Filter?
					if (!rNew.matchesFilter(model.curRequestListView.filter))
						continue;
					
					hasMatch = false;
					
					for(k = 0; k < oldModel.requests.length; k++) {
						r = oldModel.requests[k];
						
						if (parseInt(data.requests[i].requestId) === r.requestId) {
							hasMatch = true;
							tsOld = r.changedOn.getTime();
							tsNew = pg.parseDate(data.requests[i].changedOn).getTime();
							if (tsNew !== tsOld)
								return true;
						}
					}
					//neues Objekt
					if (!hasMatch)
						return true;
				}
			}
			//noinspection JSUnresolvedVariable
			if (data.requestStates) {
				//noinspection JSUnresolvedVariable
				for(i = 0; i < data.requestStates.length; i++) {
					
					//immer das alte Objekt nehmen - oben wurde schon das neue getestet! (wir brauchen die alten States)
					//noinspection JSUnresolvedVariable
					rNew = Request.getRequest(data.requestStates[i].requestId);
					if (!rNew)
						continue;
					//würde dieses Objekt überhaupt gesehen?
					tt = -1;
					if (matchTT)
						tt = rNew.taskType;
					//noinspection JSUnresolvedVariable
					rs = RequestState.createRequestState().fromObj(data.requestStates[i]);
					if (!rNew.canBeSeenByUser(owned, deciding, responsible, that.curGroupId, rs, tt)) {
						//konnte vorher gesehen werden? (wurde archiviert/wiederhergestellt)
						if (rNew.canBeSeenByUser(owned, deciding, responsible, that.curGroupId, null, tt)) {
							return true;
						}
						
						continue;
					}
					//passen die aktuellen Filter?
					if (!rNew.matchesFilter(model.curRequestListView.filter))
						continue;
						
					hasMatch = false;
					
					for(k = 0; k < oldModel.requests.length; k++) {
						r = oldModel.requests[k];
						
						//noinspection JSUnresolvedVariable
						if (parseInt(data.requestStates[i].requestId) === r.requestId) {
							for(j = 0; j < r.states.length; j++) {
								
								rs = r.states[j];
								//noinspection JSUnresolvedVariable
								if (rs.id === parseInt(data.requestStates[i].id)) {
									hasMatch = true;
									tsOld = rs.changedOn.getTime();
									//noinspection JSUnresolvedVariable
									tsNew = pg.parseDate(data.requestStates[i].changedOn).getTime();
									if (tsNew !== tsOld)
										return true;
								}
							}
						}
					}
					//neues Objekt
					if (!hasMatch)
						return true;
				}
			}
			
			//Spezialfall: Neuer Auftrag aus Antrag erzeugt
			if (data.tasks) {
				for(i = 0; i < data.tasks.length; i++) {
					var t = data.tasks[i];
					if (parseInt(t.requestId) > 0){
						
						//ist der Auftrag neu?
						hasMatch = false;
						for(j = 0; j < oldModel.tasks.length; j++) {
							var tOld = oldModel.tasks[j];
							if (parseInt(t.id) === tOld.id){
								hasMatch = true;
								tsOld = tOld.changedOn.getTime();
								//noinspection JSUnresolvedVariable
								tsNew = pg.parseDate(t.changedOn).getTime();
								if (tsNew !== tsOld)
									return true;
							}
						}
						
						//neues Objekt
						if (!hasMatch)
							return true;
					}
				}
			}
			return false;
		},
		
		//----------------------------------------------------------------------------------------------
		
		//alle gemäß Status passenden Anträge zeigen
		showRequests: function (groupId, callbackComplete) {
			
			var that = this;
			
			if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_ALL,
					UserRole.PERMISSION_REQUEST_LIST_OWN,
					UserRole.PERMISSION_REQUEST_LIST_ASSIGNED_DECIDER,
					UserRole.PERMISSION_REQUEST_LIST_RELEASED,
					UserRole.PERMISSION_REQUEST_LIST_BY_TASK_TYPE)) {
				viewManager.execShowPage(constants.PAGE_OVERVIEW, null, true);
				return false;
			}
			if (!model.client.hasModule(constants.MODULE_ID_REQUESTS)) {
				viewManager.execShowPage(constants.PAGE_OVERVIEW, null, true);
				return false;
			}
			
			if (groupId === undefined)
				groupId = 0;
			else
				groupId = parseInt(groupId);
			this.curGroupId = groupId;
			viewManager.resetStateDetails();
			
			$("#content").html(window.templates['requestList.html']);
			setTimeout(function () {

				if (groupId === constants.GROUP_ARCHIVE) {
					$("#requestTitle").html("Archiv");
					viewManager.setSelectedMenuItem('menuRequests', 'menuRequestsArchive');
					$(".breadcrumbArchive").removeClass("hidden");
				}
				else {
					$("#requestTitle").html("Antr&auml;ge");
					viewManager.setSelectedMenuItem('menuRequests', 'menuRequestsActive');
					$(".breadcrumbActive").removeClass("hidden");
				}

				//welche Daten anzeigen?
				if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_ALL)) {
					//model.curRequests = Request.getRequests(true, true, true, true);
					model.curRequests = Request.getRequests({
						ownerRequests: true,
						deciderRequests: true,
						responsibleRequests: true,
						allRequests: true,
						groupId: groupId
					});
				}
				else {
					
					var owned = false;
					var deciding = false;
					var responsible = false;
					var matchTaskTypes = false;
					if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_OWN))
						owned = true;
					if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_ASSIGNED_DECIDER))
						deciding = true;
					if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_RELEASED))
						responsible = true;
					if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_BY_TASK_TYPE))
						matchTaskTypes = true;


					model.curRequests = Request.getRequests({
						ownerRequests: owned,
						deciderRequests: deciding,
						responsibleRequests: responsible,
						groupId: groupId,
						matchTaskTypes: matchTaskTypes
					});
				}

				//nur diejenigen gemäß LocationTypes
				if (!model.curUser.hasAllLocationTypes()) {
					var a = [];
					for (var j = 0; j < model.curRequests.length; j++) {
						var t = model.curRequests[j];

						//passt zu taskType?
						if (!model.curUser.hasLocationType(t.locationType))
							continue;
						a.push(t);
					}
					model.curRequests = a;
				}
				
				//sortieren nach requestId, DESC
				model.curRequests.sort(function (a2, b) {
					return b.requestId - a2.requestId;
				});

				var restoreScrollPos = 0;
				if (model.curRequestListView.preserveCurPage) {
					model.curRequestListView.preserveCurPage = false;
					restoreScrollPos = model.curRequestListView.scrollPos || 0;
				}
				else {
					model.curRequestListView.curPage = 0;
				}
				
				$("#requestPaginationBox .viewsPerPage").val(model.curRequestListView.entriesPerPage || 25);
				
				requestListManager.showRequestItems(model.curRequests, model.curRequestListView);
				
				//--------------------------------------------------------
				//filter
				
				var u;
				
				//taskType
				var s = '<option value="-1">Alle</option>';
				var tt = TaskType.getAllTaskTypes();
				//nur zugeordnete Typen anbieten
				tt = _.filter(tt, function(o){
					return (model.curUser.hasTaskType(o.typeId));
				});
				tt.sort(function (a2, b) {
					return ((a2.name < b.name) ? -1 : ((a2.name > b.name) ? 1 : 0));
				});
				//nur Antragstypen
				for(var i = 0; i < tt.length; i++)
					if (tt[i].isRequestable === 1)
						s += '<option value=' + tt[i].typeId + '>' + tt[i].name + '</option>';
				$("#filterTaskType").html(s).select2();

				//-------------

				//locationType
				s = '<option value="-1">Alle</option>';
				var lTypes = model.locationTypes;
				//nur zugeordnete Typen anbieten
				lTypes = _.filter(lTypes, function(o){
					return (model.curUser.hasLocationType(o.id));
				});
				lTypes.sort(function (a, b) {
					return ((a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0));
				});
				for (i=0; i<lTypes.length; i++){
					var lt = lTypes[i];
					s += '<option value=' + lt.id + '>' + lt.name + '</option>';
				}
				$("#filterTaskLocationType").html(s).select2();

				//Filter: Locations gemäß aktuellem Typ als Liste anbieten
				$("#filterTaskLocationType").on("change", function(){
					//reset LocationIds
					model.curRequestListView.filter.locationId = [-1];
					that.updateLocationFilter();
				});
				that.updateLocationFilter();

				//----------------------

				//owner
				s = '<option value="-1">Alle</option>';
				var users = User.getActiveUsers(null, true);
				users.sort(function (a3, b) {
					return ((a3.getName() < b.getName()) ? -1 : ((a3.getName() > b.getName()) ? 1 : 0));
				});
				for(i = 0; i < users.length; i++) {
					u = users[i];
					if (u.canCreateRequest())
						s += '<option value=' + u.id + '>' + u.getName() + '</option>';
				}
				$("#filterRequestOwner").html(s);

				//decider
				s = '<option value="-1">Alle</option>';
				for(i = 0; i < users.length; i++) {
					u = users[i];
					if (u.canExecuteRequest())
						s += '<option value=' + u.id + '>' + u.getName() + '</option>';
				}
				$("#filterRequestDecider").html(s);
				
				//responsible
				s = '<option value="-1">Alle</option>';
				for(i = 0; i < users.length; i++) {
					u = users[i];
					if (u.canConvertRequest())
						s += '<option value=' + u.id + '>' + u.getName() + '</option>';
				}
				$("#filterResponsible").html(s);

				//-------------------------

				//Request/Task Status
				var statusOptions = [];
				statusOptions.push({value: RequestState.STATUS_NEW, name: 'Offen'});
				statusOptions.push({value: RequestState.STATUS_APPROVED, name: 'Freigegeben'});
				statusOptions.push({value: RequestState.STATUS_REJECTED, name: 'Abgelehnt'});
				statusOptions.push({value: RequestState.STATUS_INQUIRY, name: 'R&uuml;ckfrage'});
				statusOptions.push({value: RequestState.STATUS_CLOSED, name: 'Geschlossen'});
				statusOptions.push({value: RequestState.STATUS_COMPLETED, name: 'Erledigt'});
				statusOptions.push({value: constants.STATUS_NEW, name: 'Auftrag Offen'});
				statusOptions.push({value: constants.STATUS_PROGRESS, name: 'Auftrag In Bearbeitung'});
				//statusOptions.push({value: constants.STATUS_COMPLETED, name: 'Erledigt'});
				for(i = 0; i < model.taskLabels.length; i++)
					statusOptions.push({value: model.taskLabels[i].id, name: "Auftrag " + model.taskLabels[i].name});
				//sortieren
				statusOptions.sort(function (a4, b) {
					return ((a4.name < b.name) ? -1 : ((a4.name > b.name) ? 1 : 0));
				});
				//Alle soll oben sein!
				statusOptions.unshift({value: -1, name: 'Alle'});
				s = '';
				for(i = 0; i < statusOptions.length; i++)
					s += '<option value="' + statusOptions[i].value + '">' + statusOptions[i].name + '</option>';
				$("#filterRequestStatus").html(s);

				//-------------------------

				//select2
				//noinspection JSUnresolvedVariable
                $.fn.select2.defaults.set("theme", "bootstrap");
				$(".select2, .select2-multiple").select2();
				
				
				$("#requestTable th.canSort").on("click", that.onRequestListSort);
				
				//--------------------------------------------------------
				
				//Auswahl-Operationen
				
				var hasAction = false;
				
				var mayArchive = authManager.hasPermission(UserRole.PERMISSION_REQUEST_ARCHIVE_ALL, UserRole.PERMISSION_REQUEST_ARCHIVE_OWN, UserRole.PERMISSION_REQUEST_ARCHIVE_BY_TASK_TYPE);
				if (mayArchive){
					hasAction = true;
					
					var o;
					if (that.curGroupId === constants.GROUP_DEFAULT) {
						o = new Option("Archivieren", "archive");
						$(o).html("Archivieren");
						$("#requestListSelectionBox .selectionActions").append(o);
					}
					if (that.curGroupId === constants.GROUP_ARCHIVE) {
						o = new Option("Wiederherstellen", "restore");
						$(o).html("Wiederherstellen");
						$("#requestListSelectionBox .selectionActions").append(o);
					}
				}
				
				if (hasAction) {
					$("#requestListSelectionBox").show();
					$("#requestListSelectionBox .btnExecSelectionAction").click(function(){
						switch($("#requestListSelectionBox .selectionActions").val()){
							case "archive":
								that.archiveSelection();
								break;
							case "restore":
								that.restoreSelection();
								break;
							default:
								viewManager.updateButtons();
								break;
						}
					});
				}
				else
					$("#requestListSelectionBox").hide();
				
				//Auswahl
				$("#chkRequestMaster").off("change").on("change", function(){
					
					that = requestListManager;
					
					if (this.checked)
						$(".chkSelectRequest").prop("checked", true);
					else
						$(".chkSelectRequest").prop("checked", false);
					
					that.updateSelectionDisplay();
				});
				$(".chkSelectRequest").off("change").on("change", function(){
					that = requestListManager;
					that.updateSelectionDisplay();
				});
				
				//--------------------------------------------------------
				
				//handler
				if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_CREATE, UserRole.PERMISSION_REQUEST_CREATE_BY_TASK_TYPE)) {
					$("#btnAddRequest").hide();
				}
				else {
					$("#btnAddRequest").on("click", function () {
						viewManager.showPage(constants.PAGE_REQUEST_ADD);
					});
				}
				viewManager.updateButtons();
				/*$("#btnBackTask").off("click").on("click", function () {
				 window.history.back();
				 });*/
				
				/*$("#btnSelectAll").off("click").on("click", function(){
					$(".chkSelectRequest").prop("checked", true);
				});*/
				
				//----------------------------------------------------------
				//Top-Scroller
				$("#requestTableScroller").scroll(function(){
					$("#requestTableBox")
						.scrollLeft($("#requestTableScroller").scrollLeft());
				});
				$("#requestTableBox").scroll(function(){
					$("#requestTableScroller")
						.scrollLeft($("#requestTableBox").scrollLeft());
				});
				that.updateTopScroller();
				
				//----------------------------------------------------------
				
				setTimeout(function () {

					viewManager.setScrollTop(restoreScrollPos);

					//restore filter
					that.restoreRequestListFilter(model.curRequestListView);
					
					//warum auch immer mit Timeout?!
					setTimeout(function () {
						$("#requestTable .form-filter").on("change", that.onRequestListFilter);
					}, 500);

					if (callbackComplete)
						callbackComplete();
					
				}, 100);
				
			}, constants.TEMPLATE_DELAY);
		},
		
		//------------------------------------------------------------------------------------------------------
		//Archivieren / Wiederherstellen
		
		archiveSelection: function(){
			this.setSelectionGroup(this.getSelectedRequestIds(), constants.GROUP_ARCHIVE);
			
		},
		restoreSelection: function(){
			this.setSelectionGroup(this.getSelectedRequestIds(), constants.GROUP_DEFAULT);
		},
		
		setSelectionGroup: function(requestIds, groupId){
			
			var that = this;
			var count = 0;
			var promises = [];
			
			for (var i=0; i<requestIds.length; i++) {
				
				var t = Request.getRequest(requestIds[i]);
				
				//schon gesetzt?
				if (groupId === constants.GROUP_DEFAULT)
					if (!t.isArchived())
						continue;
				if (groupId === constants.GROUP_ARCHIVE)
					if (t.isArchived())
						continue;
				
				if (t.canBeArchived()){
					
					//direkt zählen, aber erst nach Abschluss aller Aktionen weitermachen
					promises.push(this.setRequestGroup(t, groupId));
					count++;
				}
			}
			
			if (count > 0){
				
				Q.all(promises).then(function() {
					that.resetSelection();
					that.updateSelectionDisplay();
					viewManager.updateButtons();
					
					var msg = pg.getStemmedName(count, "Antrag", "Anträge") + " wurde(n) erfolgreich aktualisiert.";
					viewManager.showNotification(msg, constants.NOTIFICATION_SUCCESS);
				});
			}
			else{
				viewManager.showNotification("In der Auswahl befindet sich kein Antrag, der die Kriterien für diese Operation besitzt.", constants.NOTIFICATION_INFO);
				viewManager.updateButtons();
			}
		},
		
		setRequestGroup: function(t, groupId){
			
			var that = this;
			
			var def = Q.defer();
			
			var ts = t.addRequestState();
			ts.groupId = groupId;
			
			dataManager.addRequestState(t, ts, function(){
				def.resolve();
			});
			
			//weg damit
			this.removeRequestFromView(t.requestId, function(){
				that.updateSelectionDisplay();
			});
			
			return def.promise;
		},
		
		//selektierte Aufträge holen
		getSelectedRequestIds: function() {
			var requestIds = [];
			$(".chkSelectRequest:checked").each(function(index, o){
				var requestId = parseInt($(o).attr("data-requestId"));
				requestIds.push(requestId);
			});
			return requestIds;
		},
		
		//Auswahl zurücksetzen
		resetSelection: function(){
			$(".chkSelectRequest").prop("checked", false);
			$("#chkRequestMaster").prop("checked", false);
		},
		
		//selektierte Aufträge anzeigen
		updateSelectionDisplay: function(){
			//var count = $(".chkSelectRequest:checked").length;
			var count = 0;
			$(".chkSelectRequest:checked").each(function() {
				if ($(this).is(':visible'))
					count++;
			});
			
			var msg = "";
			if (count > 0)
				msg = "<strong>" + pg.getStemmedName(count, "Antrag", "Anträge") + "</strong> ausgewählt.";
			else
				msg = "Kein Antrag ausgewählt.";
			$("#requestListSelectionBox .selectionDescription").html(msg);
		},
		
		//------------------------------------------------------------------------------------------------------
		
		removeRequestFromView: function(requestId, callback){
			
			requestId = parseInt(requestId);
			for (var i=0; i<model.curRequests.length; i++){
				if (model.curRequests[i].requestId === requestId){
					model.curRequests.splice(i, 1);
					break;
				}
			}
			
			$("#requestTable tr.requestListItem[data-requestId='" + requestId + "']").fadeOut(400, callback);
		},
		
		//------------------------------------------------------------------------------------------------------
		
		//Filter wiederherstellen
		restoreRequestListFilter: function (filterDef) {
			
			//filter
			/*jshint -W089 */
			for(var key in filterDef.filter) {
				$("#requestTable .filter [data-filter-id='" + key + "']").val(filterDef.filter[key]);
				try {
					$("#requestTable .filter [data-filter-id='" + key + "']").select2();
				}
				catch (e) {
				}
			}
			
			//sorting
			switch (filterDef.sortDirection) {
				case constants.SORTING_ASC:
					$("[data-sort-id='" + filterDef.sortField + "']").addClass("constants.SORTING_ASC");
					break;
				case constants.SORTING_DESC:
					$("[data-sort-id='" + filterDef.sortField + "']").addClass("constants.SORTING_DESC");
					break;
			}
			
			//Filter anzeigen
			this.showRequestListFilters(filterDef);
		},
		
		//Handler: Filter wurde geändert
		onRequestListFilter: function () {
			var fieldId = $(this).attr("data-filter-id");
			
			//merken
			model.curRequestListView.filter[fieldId] = $(this).val();
			
			//anwenden
			model.curRequestListView.curPage = 0;
			requestListManager.showRequestItems(model.curRequests, model.curRequestListView);
		},
		
		//Filter setzen
		showRequestListFilters: function (filterDef) {

            var filterActive = false;

            //zurücksetzen
            $("#btnRemoveRequestListFilters").addClass("disabled");
			$("#requestTableBox .filter .isFiltered").removeClass("isFiltered");
			
			//einzeln aktivieren
			/*jshint -W089 */
			for(var key in filterDef.filter) {
				
				var val = filterDef.filter[key];
				if (val) {
					
					if ($.isArray(val)) {
						//Sonderfall -1
						var isSettingAll = false;
						for(var i = 0; i < val.length; i++)
							if (val[i] === "-1") {
								isSettingAll = true;
								break;
							}
						if (isSettingAll)
							continue;
					}
					else
					//Sonderfall -1
					if (val === "-1")
						continue;

                    filterActive = true;

                    $("#requestTableBox .filter *[data-filter-id='" + key + "']").addClass("isFiltered");
				}
			}

            if (filterActive)
                $("#btnRemoveRequestListFilters").removeClass("disabled");

		},
		
		//Reset der Filter
		resetFilters: function (showItems) {

			if (showItems === undefined)
				showItems = true;

			//Filter leeren
			model.curRequestListView.filter = {};
			this.restoreRequestListFilter(model.curRequestListView);
			
			//Anzeige zurücksetzen
			$("#requestTableBox .filter input").val("");
			$("#requestTableBox .filter select").val("-1");

            //Select2 zurücksetzen
            $("#requestTableBox .filter .select2").val("-1").trigger("change");
            $("#requestTableBox .filter .select2-multiple").val("").trigger("change");

            if ((showItems) && (model.curRequests))
				requestListManager.showRequestItems(model.curRequests, model.curRequestListView);
		},
		
		//------------------------------------------------------------------------------------------------------
		
		//Sortierung der Anträge
		onRequestListSort: function (e) {
			
			var that = requestListManager;
			
			//wer?
			var col = $(e.currentTarget);
			var sortField = col.attr("data-sort-id");
			var isSortingAsc = col.hasClass("sorting_asc");
			var isSortingDesc = col.hasClass("sorting_desc");
			
			$("#requestTable th.canSort").removeClass("sorting_asc sorting_desc");
			var sortDir = constants.SORTING_NONE;
			if (isSortingAsc) {
				sortDir = constants.SORTING_DESC;
				$(this).addClass("sorting_desc");
			}
			else if (!isSortingDesc) {
				sortDir = constants.SORTING_ASC;
				$(this).addClass("sorting_asc");
			}
			
			//merken
			model.curRequestListView.sortDirection = sortDir;
			if (model.curRequestListView.sortDirection === constants.SORTING_NONE)
				model.curRequestListView.sortField = null;
			else
				model.curRequestListView.sortField = sortField;
			
			//anwenden
			that.showRequestItems(model.curRequests, model.curRequestListView);
		},
		
		//----------------------------------------------------------------------------
		
		//Einträge anzeigen
		showRequestItems: function (requests, filterObj) {
			var that = this;
			
			//Filter anzeigen
			this.showRequestListFilters(filterObj);

			//filter
			var trgRequests = [];
			for(var i = 0; i < requests.length; i++) {
				var r = requests[i];
				
				if (r.isCurrentVersion) {
					
					if (r.matchesFilter(filterObj.filter)) {
						
						r.updateSortValue(filterObj.sortField, filterObj.sortDirection);
						trgRequests.push(r);
					}
				}
			}
			
			//sort
			if (filterObj.sortDirection !== constants.SORTING_NONE) {
				trgRequests.sort(function (a, b) {
					return ((a.sortVal < b.sortVal) ? -1 : ((a.sortVal > b.sortVal) ? 1 : 0));
				});
				//desc
				if (filterObj.sortDirection === constants.SORTING_DESC)
					trgRequests.reverse();
			}
			
			//--------------
			
			//pagination
			var startIndex, endIndex;
			model.curRequestListView.entriesPerPage = parseInt($("#requestPaginationBox .viewsPerPage").val() || 25);
			if (model.curRequestListView.entriesPerPage < 0) {
				model.curRequestListView.pageCount = 1;
				startIndex = 0;
				endIndex = trgRequests.length;
			}
			else {
				model.curRequestListView.pageCount = Math.ceil(trgRequests.length / model.curRequestListView.entriesPerPage);
				startIndex = model.curRequestListView.curPage * model.curRequestListView.entriesPerPage;
				endIndex = Math.min((model.curRequestListView.curPage + 1) * model.curRequestListView.entriesPerPage, trgRequests.length);
			}
			
			if (model.curRequestListView.curPage === 0)
				$("#requestPaginationBox .btn.prev").addClass("disabled");
			else
				$("#requestPaginationBox .btn.prev").removeClass("disabled");
			if (model.curRequestListView.curPage >= model.curRequestListView.pageCount - 1)
				$("#requestPaginationBox .btn.next").addClass("disabled");
			else
				$("#requestPaginationBox .btn.next").removeClass("disabled");
			
			//--------------
			
			//Inhalte
			var s = '';
			for(i = startIndex; i < endIndex; i++) {
				var t = trgRequests[i];
				
				if (t.isCurrentVersion) {
					
					var ts = t.getCurrentState();
					if (!ts)
						continue;
					
					if (i % 2 === 0)
						s += '<tr data-requestId="' + t.requestId + '" class="requestListItem gradeX even" role="row">';
					else
						s += '<tr data-requestId="' + t.requestId + '" class="requestListItem gradeX odd" role="row">';

                    //-------------------------
                    //Batch Action Checkbox
					s += '<td style="height: 56px;">';
					s += '<label class="mt-checkbox mt-checkbox-single mt-checkbox-outline">';
					s += '	<input data-requestId="' + t.requestId + '" type="checkbox" class="group-checkable chkSelectRequest">';
					s += '<span></span>';
					s += '</label>';
                    s += '</td>';

                    //-------------------------
					//Aktionen

					s += '<td>';
					s += '  <div class="btn-group">';
					if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_DETAILS))
						s += '  <button data-requestId="' + t.requestId + '" type="button" class="btn grey-mint btn-xs btnRequestDetails">Details</i></button>';
					else
						s += '  <button class="btn grey-mint btn-xs disabled" type="button">Details</i></button>';
					s += '      <button type="button" class="btn grey-mint btn-xs dropdown-toggle" data-toggle="dropdown"><i class="fa fa-angle-down"></i></button>';
					s += '	    <ul class="dropdown-menu">';

					var mayArchive = false;
					if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_ARCHIVE_ALL)) {
						mayArchive = true;
					}
					else {
						//nein, aktuell!
						//var requestOriginal = t.getOriginalRequest();
						if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_ARCHIVE_OWN)) {
							if (t.ownerId === model.curUserId)
								mayArchive = true;
							if (ts.responsibleId === model.curUserId)
								mayArchive = true;
						}
						else {
							if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_ARCHIVE_BY_TASK_TYPE)) {
								if (model.curUser.hasTaskType(t.taskTypeId))
									mayArchive = true;
							}
						}
					}

					//kann Auftrag überhaupt archiviert werden?
					if (mayArchive)
						mayArchive = t.canBeArchived();

					if (that.curGroupId === constants.GROUP_DEFAULT) {
						if (mayArchive)
							s += '	        <li><a data-requestId="' + t.requestId + '" class="btnRequestArchive"> Archivieren </a></li>';
						else
							s += '	        <li><a class="disabled"> Archivieren </a></li>';
					}
					if (that.curGroupId === constants.GROUP_ARCHIVE) {
						if (mayArchive)
							s += '	        <li><a data-requestId="' + t.requestId + '" class="btnRequestRestore"> Wiederherstellen </a></li>';
						else
							s += '	        <li><a class="disabled"> Wiederherstellen </a></li>';
					}

					s += '      </ul>';
					s += '  </div>';
					s += '</td>';

                    //----------------------------------
                    //ID

                    if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_DETAILS))
                        s += '<td class="btnRequestDetails cell-clickable font-semibold" data-requestId="' + t.requestId + '">#' + t.requestId + '</td>';
                    else
                        s += '<td class="font-semibold">#' + t.requestId + '</td>';
					
					//----------------------------------
                    //Status

                    if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_DETAILS))
                        s += '<td class="btnRequestDetails cell-clickable" data-requestId="' + t.requestId + '">';
                    else
                        s += '<td>';
					s += t.getStatusLabel();
                    s += '</td>';

					//----------------------------------
                    //Titel/Typ

                    if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_DETAILS))
                        s += '<td class="btnRequestDetails cell-clickable" data-requestId="' + t.requestId + '">';
                    else
                        s += '<td>';
					var tType = TaskType.getTaskType(t.taskTypeId).name;
					var tTitle = t.title;
					if (tTitle)
						s += '<span class="taskTitle">' + tTitle + '</span><br/>';
					s += '<span class="taskType text-muted">' + tType + '</span>';
                    s += '</td>';

					//----------------------------------
					//Objekte

					var loc = Location.getLocation(t.locationList[0], undefined, true, true, false);
					var lType = "";
					var hasLoc = false;
					if (loc) {
						if (loc.isAbstract !== 1) {
							lType = LocationType.getLocationType(loc.type).name;
							hasLoc = true;
						}
					}

					var ll = Location.getLocationList(t.locationList, false, false, false, 2, true, true);

					if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
						s += '<td class="btnRequestDetails cell-clickable" data-requestId="' + t.requestId + '">';
					else
						s += '<td>';

					s += '<span class="locationTitle">' + ll + '</span>';
					if(lType)
						s += '<br/><span class="locationType text-muted">' + lType + '</span>';
					s += '</td>';

                    //----------------------------------
                    //Antragsteller

                    //nein, aktueller Owner!
                    //var tOriginal = t.getOriginalRequest();
                    //var tsOriginal = tOriginal.getState();

                    if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_DETAILS))
                        s += '<td class="btnRequestDetails cell-clickable" data-requestId="' + t.requestId + '">';
                    else
                        s += '<td>';
                    s += User.getUserName(t.ownerId, true);
                    s += '</td>';

                    //----------------------------------
                    //Entscheider

                    if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_DETAILS))
                        s += '<td class="btnRequestDetails cell-clickable" data-requestId="' + t.requestId + '">';
                    else
                        s += '<td>';
					s += User.getUserName(ts.deciderId, true);
                    s += '</td>';

					//----------------------------------
					// Task ID

					var task = null;
					if (ts.status === RequestState.STATUS_COMPLETED) {

						//neuesten Task holen
						task = Task.getTaskByRequest(t.requestId);
						if (task) {
							
							if (authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS))
								s += '<td><a data-taskId="' + task.taskId + '" class="btn btn-link btnTaskDetails">#' + task.taskId + '</a></td>';
							else
								s += '<td>#' + task.taskId + '</td>';
						}
						else {
							s += '<td><span class="text-muted"><em>gel&ouml;scht</em></span></td>';
						}
					}
					else {
						s += '<td>&nbsp;</td>';
					}

                    //----------------------------------
                    // Verantwortlicher

                    if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_DETAILS))
                        s += '<td class="btnRequestDetails cell-clickable" data-requestId="' + t.requestId + '">';
                    else
                        s += '<td>';
                    //neuesten Task holen
					var hasResp = false;
					if (ts.status === RequestState.STATUS_COMPLETED) {
						task = Task.getTaskByRequest(t.requestId);
						if (task) {
							var tts = task.getCurrentState();
							if (tts && (tts.responsibleId > 0)) {
								var responsible = User.getUser(tts.responsibleId);
								s += responsible.getName(true);
								hasResp = true;
							}
						}
					}
                    s += '</td>';
					
					//------------------------
					
					
					s += '</tr>';
				}
			}
			
			//leer
			if (trgRequests.length === 0) {
				s = "<tr><td colspan='10'>Zur Anfrage wurden keine Antr&auml;ge gefunden.</td></tr>";
				
				$("#requestPaginationBox").hide();
			}
			else {
				$("#requestPaginationBox").show();
				
				if (trgRequests.length === 1)
					$("#requestPaginationBox .filterMatchCount").html("1 Antrag");
				else
					$("#requestPaginationBox .filterMatchCount").html(trgRequests.length + " Antr&auml;ge");
				
				$("#requestPaginationBox .pagination-panel-input").val((model.curRequestListView.curPage + 1));
				$("#requestPaginationBox .pagination-panel-total").html(""+model.curRequestListView.pageCount);
			}
			
			//anzeigen
			$("#requestTable tbody").html(s);
			
			//einmal so ausführen
			this.resetSelection();
			this.updateSelectionDisplay();
			
			//handlers
			//warum auch immer erst mit Timeout?!
			setTimeout(function () {
				
				$(".btnRequestDetails").off("click").on("click", function (e) {

					//scroll-Position merken
					model.curRequestListView.scrollPos = viewManager.getScrollTop();

					var link = $(e.currentTarget);
					if (link.attr("data-requestId")) {
						var requestId = parseInt(link.attr("data-requestId"), 10);
						viewManager.showPage(constants.PAGE_REQUEST_DETAILS, {
							requestId: requestId
						});
					}
				});
				
				//Archivieren/Wiederherstellen
				$(".btnRequestArchive").off("click").on("click", function(e){
					
					var link = $(e.currentTarget);
					if (link.attr("data-requestId")){
						var requestId = parseInt(link.attr("data-requestId"), 10);
						that.setRequestGroup(Request.getRequest(requestId), constants.GROUP_ARCHIVE).then(function(){
							viewManager.showNotification("Antrag #" + requestId + " wurde erfolgreich archiviert", constants.NOTIFICATION_SUCCESS);
						});
					}
				});
				$(".btnRequestRestore").off("click").on("click", function(e){
					var link = $(e.currentTarget);
					if (link.attr("data-requestId")){
						var requestId = parseInt(link.attr("data-requestId"), 10);
						that.setRequestGroup(Request.getRequest(requestId), constants.GROUP_DEFAULT).then(function(){
							viewManager.showNotification("Antrag #" + requestId + " wurde erfolgreich wiederhergestellt", constants.NOTIFICATION_SUCCESS);
						});
					}
				});
				
				$(".viewsPerPage").change(function () {
					requestListManager.showRequestItems(model.curRequests, model.curRequestListView);
				});
				$("#requestPaginationBox .pagination-panel-input").change(function () {
					var val = $("#requestPaginationBox .pagination-panel-input").val();
					if ($.isNumeric(val)) {
						val = parseInt(val);
						val = Math.max(val, 1);
						val = Math.min(val, model.curRequestListView.pageCount);
						model.curRequestListView.curPage = val - 1;
						requestListManager.showRequestItems(model.curRequests, model.curRequestListView);
					}
				});
				$("#requestPaginationBox .btn.prev").off("click").on("click", function () {
					if (model.curRequestListView.curPage > 0)
						model.curRequestListView.curPage--;
					requestListManager.showRequestItems(model.curRequests, model.curRequestListView);
				});
				$("#requestPaginationBox .btn.next").off("click").on("click", function () {
					if (model.curRequestListView.curPage < model.curRequestListView.pageCount - 1)
						model.curRequestListView.curPage++;
					requestListManager.showRequestItems(model.curRequests, model.curRequestListView);
				});
				
				$(".btnTaskDetails").on("click", that.showTaskDetails);
				
			}, 500);
		},
		
		//Sprung zu Auftrag
		showTaskDetails: function (e) {
			
			if (e) {

				//scroll-Position merken
				model.curRequestListView.scrollPos = viewManager.getScrollTop();
				model.curRequestListView.preserveCurPage = true;

				var link = $(e.currentTarget);
				if (link.attr("data-taskId")) {
					var taskId = parseInt(link.attr("data-taskId"), 10);
					
					viewManager.showPage(constants.PAGE_TASK_DETAILS, {
						taskId: taskId,
						backFunction: constants.BACK_BROWSER
					});
				}
			}
			return false;
		},
		
		//-------------------------------------------------
		
		onResize: function(){
			var that = requestListManager;
			that.updateTopScroller();
		},
		
		updateTopScroller: function(){
			var contentWidth = $("#requestTableBox > table").width();
			$("#requestTableScroller div").width(contentWidth);
		},

		//------------------------------------------------

		//anhand der derzeitigen Location-Filter-Einstellung alle Location-Namen ermitteln
		//und per Select2 anbieten
		updateLocationFilter: function(){

			//Typ holen
			var locationType = parseInt($("#filterTaskLocationType").val());

			//danach filtern
			var locations = Location.getLocations();
			var filteredLocations = [];
			var i;
			if (locationType === -1){
				//alle
				for(i=0; i<locations.length; i++) {
					if (locations[i].isAbstract === 0)
						filteredLocations.push(locations[i]);
				}
			}
			else {
				for(i=0; i<locations.length; i++) {
					if ((locations[i].type === locationType) && (locations[i].isAbstract === 0))
						filteredLocations.push(locations[i]);
				}
			}

			//sortieren
			filteredLocations.sort(function (a, b) {
				var na = a.getName(true);
				var nb = b.getName(true);
				return ((na < nb) ? -1 : ((na > nb) ? 1 : 0));
			});

			//zusammensetzen
			//var s = '<option value="-1">Alle</option>';
			var s = "";
			for (i=0; i<filteredLocations.length; i++){
				var loc = filteredLocations[i];
				s += '<option value=' + loc.locationId + '>' + loc.getName(true) + '</option>';
			}
			$("#filterTaskLocation").html(s).select2();

			setTimeout(function(){
				$("#filterTaskLocation.isFiltered").removeClass("isFiltered");
			}, 100);


		}
		
	};
	
	window.requestListManager = requestListManager;
}());
